import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import NewEmployeeRoleModal from './NewEmployeeRoleModal'
import EditEmployeeRoleModal from './EditEmployeeRoleModal'
import SortableTable from '../common/SortableTable'
import { loggedInUserVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import { PersonBadge, PlusCircle } from 'react-bootstrap-icons'
import Loading from '../common/Loading'

const EmployeeRoles = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutation = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const [editRole, setEditRole] = useState(null)
  const [showEditRoleModal, setShowEditRoleModal] = useState(false)
  const [showNewRoleModal, setShowNewRoleModal] = useState(false)
  const ROLES_QUERY = gql`
    query RolesQuery {
      roles(orderBy: "name", first: 250) {
        edges {
          node {
            id
            name
            abbreviatedName
            hourlyRate
          }
        }
      }
    }
  `
  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Role',
        accessor: 'node.name',
      },
      {
        Header: 'Abbreviated Name',
        accessor: 'node.abbreviatedName',
      },
    ],
    []
  )
  const { loading, error, data } = useQuery(ROLES_QUERY, {
    fetchPolicy: 'network-only',
  })
  const handleRolesQueryData = () => {
    const currentRoles = []
    data.roles.edges.forEach((role) => {
      currentRoles.push(role)
    })
    return currentRoles
  }
  const toggleNewRoleModal = () => {
    setShowNewRoleModal((prevState) => !prevState)
  }
  const toggleEditRoleModal = () => {
    setShowEditRoleModal((prevState) => !prevState)
  }
  const handleRoleClick = (row) => {
    if (canMutation) {
      setEditRole(row.original.node)
      toggleEditRoleModal()
    }
  }
  if (!data)
    return (
      <Row>
        <Col>
          <Loading message="Loading Employee Roles..." />
        </Col>
      </Row>
    )
  if (error) return <p>Error loading roles</p>
  const roles = handleRolesQueryData()
  return (
    <>
      <div className="mb-5">
        <Row>
          {canMutation && (
            <Col>
              <div>
                <Button variant="link" onClick={toggleNewRoleModal}>
                  <PlusCircle className="mr-2" />
                  New Employee Role
                </Button>
              </div>
            </Col>
          )}
        </Row>

        <Row className="mb-5 mt-1">
          <Col md={4}>
            <SortableTable
              columns={tableColumns}
              data={roles}
              onRowClick={handleRoleClick}
              searchWidth={12}
              rowPointer={canMutation}
            />
          </Col>
        </Row>
        <NewEmployeeRoleModal
          showModal={showNewRoleModal}
          toggleModal={toggleNewRoleModal}
        />
        <EditEmployeeRoleModal
          role={editRole}
          showModal={showEditRoleModal}
          toggleModal={toggleEditRoleModal}
        />
      </div>
    </>
  )
}

export default EmployeeRoles
