import React from 'react'
import { Modal } from 'react-bootstrap'
import SubjectForm from './SubjectForm'
import { Person } from 'react-bootstrap-icons'

export default function NewSubjectModal(props) {
  const {
    showSubjectModal,
    subjectModalShowChange,
    setShowSubjectModal,
    updateSubject,
    onCompleted,
    displayBilling,
    organization,
    subjectGroupId,
    subjectGroupName,
  } = props
  let header = 'New Subject'
  if (organization) {
    header = `New ${organization.name} Subject`
  } else if (updateSubject) {
    header = 'Update Subject'
  }
  return (
    <Modal show={showSubjectModal} onHide={subjectModalShowChange} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <Person className="mr-2" />
          {header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SubjectForm
          subjectModal={true}
          toggleModal={setShowSubjectModal}
          updateSubject={updateSubject}
          onCompleted={onCompleted}
          showFormBorder={false}
          displayBilling={displayBilling}
          organization={organization}
          subjectGroupId={subjectGroupId}
          subjectGroupName={subjectGroupName}
        />
      </Modal.Body>
    </Modal>
  )
}
