import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../libs/apollo'
import OrganizationContactLayout from '../components/layout/OrganizationContactLayout'

const OrganizationContactRoute = ({ component: Component, ...rest }) => {
  let user = useReactiveVar(loggedInUserVar)
  if (user) {
    if (
      user.groups.edges
        .map((group) => group.node.name)
        .includes('Organization Contact')
    ) {
      return (
        <OrganizationContactLayout>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </OrganizationContactLayout>
      )
    } else {
      return <Redirect to="/" />
    }
  }
  return <Redirect to="/" />
}

export default OrganizationContactRoute
