import React from 'react'
import Chart from 'react-apexcharts'

const PieChart = (props) => {
  const {
    labels,
    series,
    type,
    width,
    chartWidth,
    colors,
    tooltipLabel,
    dataLabelStyle,
    hideLedgend,
  } = props
  let options
  const width_ = width || '375'
  const chartWidth_ = chartWidth || 200
  if (type === 'percent') {
    options = {
      labels,
      colors,
      legend: {
        show: hideLedgend ? false : true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: chartWidth_,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }
  } else {
    options = {
      labels,
      colors,
      legend: {
        show: hideLedgend ? false : true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: chartWidth_,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex]
        },
        style: dataLabelStyle
          ? dataLabelStyle
          : {
              fontSize: '16px',
            },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return `${value} ${tooltipLabel ? tooltipLabel : ''}`
          },
        },
      },
    }
  }

  return <Chart options={options} series={series} type="pie" width={width_} />
}

export default PieChart
