import { gql } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Row, Col, Form, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import {
  GeoAlt,
  Trash,
  Funnel,
  Map as MapIcon,
  List,
  PlusCircle,
} from 'react-bootstrap-icons'
import LocationModal from './LocationModal'
import Page from '../layout/Page'
import Map from './Map'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import DeleteLocationModal from './DeleteLocationModal'

const Locations = (props) => {
  const { organizationId, subjectId } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const canModify = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const defaultOrderBy = 'name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [cursor, setCursor] = useState()

  const [hasMoreLocations, setHasMoreLocations] = useState(false)
  const [view, setView] = useState('list')
  const [showDeleteForm, setShowDeleteForm] = useState(false)
  const [locations, setLocations] = useState([])
  const [filterStudioLocations, setFilterStudioLocations] = useState(false)
  const [filterOrganizationLocations, setFilterOrganizationLocations] =
    useState(false)
  const [filterSubjectLocations, setFilterSubjectLocations] = useState(false)
  const [editLocation, setEditLocation] = useState()
  const [checkedLocationIds, setCheckedLocationIds] = useState([])
  console.log(settings)
  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Locations(
        $organization: ID
        $subject: ID
        $fullAddress: String
        $filterStudio: Boolean
        $filterOrganization: Boolean
        $filterSubject: Boolean
        $after: String
        $orderBy: String
      ) {
        locations(
          organization: $organization
          subject: $subject
          fullAddress_Icontains: $fullAddress
          studio: $filterStudio
          organizationIsNotNull: $filterOrganization
          subjectIsNotNull: $filterSubject
          after: $after
          first: 250
          orderBy: $orderBy
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              salesTax
              addressLineOne
              shippingAddress
              billingAddress
              addressLineTwo
              city
              state
              zipCode
              archived
              latitude
              mapDefault
              longitude
              studio
              contentType {
                model
                id
              }
              subject {
                id
                gaiaUser {
                  firstName
                  lastName
                }
                organization {
                  name
                }
              }
              organization {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (filterStudioLocations) {
      const variables = {
        filterStudio: true,
        after: null,
      }
      if (searchTerm) {
        variables.fullAddress = searchTerm
      }
      if (orderBy) {
        variables.orderBy = orderBy
      }
      query({
        variables,
      })
    } else if (filterOrganizationLocations) {
      const variables = {
        filterOrganization: true,
      }
      if (searchTerm) {
        variables.fullAddress = searchTerm
      }
      if (orderBy) {
        variables.orderBy = orderBy
      }
      query({
        variables,
      })
    } else if (filterSubjectLocations) {
      const variables = {
        filterSubject: true,
      }
      if (searchTerm) {
        variables.fullAddress = searchTerm
      }
      if (orderBy) {
        variables.orderBy = orderBy
      }
      query({
        variables,
      })
    } else {
      const variables = {}
      if (searchTerm) {
        variables.fullAddress = searchTerm
      }
      if (orderBy) {
        variables.orderBy = orderBy
      }
      query({
        variables,
      })
    }
  }, [
    filterStudioLocations,
    filterOrganizationLocations,
    filterSubjectLocations,
  ])

  useEffect(() => {
    if (hasMoreLocations) {
      fetchMore()
    } else if (loadingSearch) {
      setLoadingSearch(false)
    }
  }, [hasMoreLocations, locations])

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = { after: null }
      if (organizationId) {
        variables.organization = organizationId
      }
      if (subjectId) {
        variables.subject = subjectId
      }
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.locations) {
      setHasMoreLocations(queryData.locations.pageInfo.hasNextPage)
      setCursor(queryData.locations.pageInfo.endCursor)
      setLocations(queryData.locations.edges)
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = {
      after: cursor,
    }
    if (organizationId) {
      variables.organization = organizationId
    }
    if (subjectId) {
      variables.subject = subjectId
    }
    if (searchTerm) {
      variables.fullAddress = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    queryFetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult.locations) return prev
        const pageInfo = fetchMoreResult.locations.pageInfo
        setCursor(pageInfo.endCursor)
        setHasMoreLocations(pageInfo.hasNextPage)
        setLocations((prevState) => {
          const currentIds = new Set(
            fetchMoreResult.locations.edges.map((item) => item.node.id)
          )
          const filteredPrevState = prevState.filter(
            (item) => !currentIds.has(item.id)
          )
          return [...filteredPrevState, ...fetchMoreResult.locations.edges]
        })
      },
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = { after: null }
    if (organizationId) {
      variables.organization = organizationId
    }
    if (subjectId) {
      variables.subject = subjectId
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    variables.fullAddress = currentSearchTerm
    setLoadingSearch(true)
    query({ variables })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
      after: null,
    }
    if (organizationId) {
      variables.organization = organizationId
    }
    if (subjectId) {
      variables.subject = subjectId
    }
    if (searchTerm) {
      variables.fullAddress = searchTerm
    }
    query({ variables })
  }

  const onRowClick = (row) => {
    toggleModal(row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditLocation(node)
    } else {
      setEditLocation()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedLocationIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedLocationIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (row) => {
        return row.node.name
      },
      serverSort: true,
    },
    {
      Header: 'Address Line One',
      id: 'addressLineOne',
      accessor: (row) => {
        return row.node.addressLineOne
      },
      serverSort: true,
      orderBy: 'address_line_one',
    },
    {
      Header: 'Address Line Two',
      id: 'addressLineTwo',
      accessor: (row) => {
        return row.node.addressLineTwo
      },
      serverSort: true,
      orderBy: 'address_line_two',
    },
    {
      Header: 'City',
      id: 'city',
      accessor: (row) => {
        return row.node.city
      },
      serverSort: true,
    },
    {
      Header: settings?.locationFormat === 'US' ? 'State' : 'Province',
      id: 'state',
      accessor: (row) => {
        return row.node.state
      },
      serverSort: true,
    },
    {
      Header: 'Zip Code',
      id: 'zipCode',
      accessor: (row) => {
        return row.node.zipCode
      },
      serverSort: true,
      orderBy: 'zip_code',
    },
  ]

  let actions = []
  if (view === 'map') {
    actions.push({
      icon: <List />,
      text: 'List',
      onClick: () => setView('list'),
    })
  } else {
    actions.push({
      icon: <MapIcon />,
      text: 'Map',
      onClick: () => setView('map'),
    })
  }
  if (canModify) {
    actions.push({
      icon: <PlusCircle />,
      text: 'New Location',
      onClick: () => toggleModal(),
    })
  }

  if (!organizationId && !subjectId) {
    if (
      !filterOrganizationLocations &&
      !filterStudioLocations &&
      !filterSubjectLocations
    ) {
      actions.push({
        icon: <Funnel />,
        text: 'Studio Locations',
        onClick: () => setFilterStudioLocations(true),
      })
      actions.push({
        icon: <Funnel />,
        text: 'Organization Locations',
        onClick: () => setFilterOrganizationLocations(true),
      })
      actions.push({
        icon: <Funnel />,
        text: 'Subject Locations',
        onClick: () => setFilterSubjectLocations(true),
      })
    } else {
      actions.push({
        icon: <Funnel />,
        text: 'All Locations',
        onClick: () => {
          if (filterOrganizationLocations) {
            setFilterOrganizationLocations(false)
          }
          if (filterStudioLocations) {
            setFilterStudioLocations(false)
          }
          if (filterSubjectLocations) {
            setFilterSubjectLocations(false)
          }
        },
      })
    }
  }

  if (canModify && checkedLocationIds.length > 0) {
    actions.push({
      icon: <Trash />,
      text:
        checkedLocationIds.length === 1
          ? 'Delete Location'
          : 'Delete Locations',
      onClick: () => setShowDeleteForm(true),
    })
  }
  if (!organizationId && !subjectId) {
    tableColumns.splice(0, 0, {
      Header: 'Studio',
      id: 'studio',
      accessor: (row) => {
        if (row.node.studio) {
          return 'Yes'
        }
      },
      serverSort: true,
    })
    tableColumns.splice(1, 0, {
      Header: 'Organization',
      id: 'organization',
      accessor: (row) => {
        if (row.node.organization) {
          return row.node.organization.name
        }
      },
      serverSort: true,
      orderBy: 'organization__name',
    })
    tableColumns.splice(2, 0, {
      Header: 'Subject',
      id: 'subject',
      accessor: (row) => {
        if (row.node.subject) {
          let subject = null
          if (row.node.subject.gaiaUser.firstName) {
            subject = row.node.subject.gaiaUser.firstName
          }
          if (row.node.subject.gaiaUser.lastName) {
            if (subject) {
              subject = `${subject} ${row.node.subject.gaiaUser.lastName}`
            }
          }
          if (!subject) {
            subject = 'Unnamed'
          }
          return subject
        }
      },
      serverSort: true,
      orderBy: 'subject__gaia_user__full_name',
    })
    tableColumns.push({
      Header: 'Map Default',
      id: 'mapDefault',
      accessor: (row) => {
        return row.node.mapDefault ? 'Yes' : null
      },
      serverSort: true,
      orderBy: 'map_default',
    })
  }

  if (organizationId) {
    tableColumns.push({
      Header: 'Billing Location',
      id: 'billingAddress',
      accessor: (row) => {
        return row.node.billingAddress ? 'Yes' : 'No'
      },
      serverSort: true,
      orderBy: 'billing_address',
    })
    tableColumns.push({
      Header: 'Shipping Location',
      id: 'shippingAddress',
      accessor: (row) => {
        return row.node.shippingAddress ? 'Yes' : 'No'
      },
      serverSort: true,
      orderBy: 'shipping_address',
    })
  }

  if (canModify) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  locations.forEach((location) => {
                    if (!checkedLocationIds.includes(location.node.id)) {
                      appendIds.push(location.node.id)
                    }
                  })
                  setCheckedLocationIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedLocationIds([])
                }
              }}
            />
            {checkedLocationIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedLocationIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedLocationIds.includes(row.node.id)}
                onChange={(e) => handleCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }

  let title
  if (!organizationId && !subjectId) {
    title = 'Locations'
  }
  if (queryError) return <>Error loading</>
  return (
    <>
      <Page title={title} actions={actions} actionsNewRow>
        {view === 'list' && (
          <>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="searchTerm"
                    className="form-control-sm"
                    placeholder={'Search Locations'}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2 mb-3">
              <Col md="12">
                <SortableInfiniteTable
                  tableData={locations}
                  tableColumns={tableColumns}
                  fetchMoreTableData={fetchMore}
                  loadingMessage="Loading Locations..."
                  hasMoreTableData={hasMoreLocations}
                  loading={
                    loadingSearch ||
                    (!initialQueryRun && !queryData) ||
                    hasMoreLocations
                  }
                  onTdClicks={{
                    name: (cell) => {
                      onRowClick(cell.row)
                    },
                    type: (cell) => {
                      onRowClick(cell.row)
                    },
                    addressLineOne: (cell) => {
                      onRowClick(cell.row)
                    },
                    organization: (cell) => {
                      onRowClick(cell.row)
                    },
                    addressLineTwo: (cell) => {
                      onRowClick(cell.row)
                    },
                    city: (cell) => {
                      onRowClick(cell.row)
                    },
                    state: (cell) => {
                      onRowClick(cell.row)
                    },
                    zipCode: (cell) => {
                      onRowClick(cell.row)
                    },
                    subject: (cell) => {
                      onRowClick(cell.row)
                    },
                    mapDefault: (cell) => {
                      onRowClick(cell.row)
                    },
                  }}
                  tableHeight={700}
                  rowPointer
                  hideGlobalFilter
                  handleSortByChange={handleSortByChange}
                />
              </Col>
            </Row>
          </>
        )}
        {view === 'map' && (
          <>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="searchTerm"
                    placeholder={'Search Locations'}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Map
              locations={
                queryData?.locations?.edges ? queryData?.locations?.edges : []
              }
              organizationId={organizationId}
              subjectId={subjectId}
            />
          </>
        )}
      </Page>

      {showForm && (
        <LocationModal
          organizationId={organizationId}
          subjectId={subjectId}
          showModal={showForm}
          location={editLocation}
          toggleModal={toggleModal}
        />
      )}
      {showDeleteForm && (
        <DeleteLocationModal
          locationIds={
            checkedLocationIds.length > 1 ? checkedLocationIds : null
          }
          locationId={
            checkedLocationIds.length === 1 ? checkedLocationIds[0] : null
          }
          setCheckedLocationIds={setCheckedLocationIds}
          showModal={showDeleteForm}
          toggleModal={setShowDeleteForm}
        />
      )}
    </>
  )
}
export default Locations
