import React from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { ArrowLeft, Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'

const DisableStripeModal = ({ show, onHide, stripeConnectorId }) => {
  const [updateStripeConnector] = useMutation(
    gql`
      mutation UpdateStripeConnector($input: UpdateStripeConnectorInput!) {
        updateStripeConnector(input: $input) {
          stripeConnector {
            id
            connectedAccountId
          }
        }
      }
    `,
    {
      onCompleted: () => {
        toast.success('Stripe Disabled')
        onHide()
      },
      onError: () => {
        toast.error('An error occurred while disabling Stripe.')
      },
      refetchQueries: ['StripeConnectorQuery'],
    }
  )

  const handleDisable = () => {
    updateStripeConnector({
      variables: {
        input: {
          stripeConnectorInput: {
            id: stripeConnectorId,
            connectedAccountId: null,
          },
        },
      },
    })
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Disable Stripe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            Are you sure you want to disable Stripe? Disabling Stripe will block
            the ability to book sessions, transfer payments, and send invoices.
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button variant="outline-primary" onClick={onHide}>
              <ArrowLeft className="mr-2" />
              Cancel
            </Button>
            <Button variant="outline-danger" onClick={handleDisable}>
              <Trash className="mr-2" />
              Disable
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default DisableStripeModal
