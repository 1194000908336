import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Modal, Button } from 'react-bootstrap'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { DateTime } from 'luxon'
import { humanDate } from '../../libs/utils'
import { ExclamationTriangle, CheckSquare } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import './ConfirmEmployeesModal.css'

const ConfirmEmployeesModal = (props) => {
  const { showModal, toggleModal, startDate, endDate, employeeId } = props

  useEffect(() => {
    if (showModal) {
      const variables = {
        startDateTime: startDate.toISO(),
        endDateTime: endDate.toISO(),
      }
      if (employeeId) {
        variables.employees = [employeeId]
      }
      getJobs({
        variables,
      })
    }
  }, [showModal])

  const [updateEmployeeJob] = useMutation(
    gql`
      mutation UpdateEmployeeJob($input: UpdateEmployeeJobInput!) {
        updateEmployeeJob(input: $input) {
          employeeJob {
            id
          }
        }
      }
    `,
    {
      refetchQueries: ['EmployeeJobsQuery', 'ScheduleJobsQuery'],
    }
  )

  const [confirmAllEmployeeJobs] = useMutation(
    gql`
      mutation ConfirmEmployeeJobs($input: ConfirmEmployeeJobsInput!) {
        confirmEmployeeJobs(input: $input) {
          confirmed
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data.confirmEmployeeJobs.confirmed) {
          toast.success('Confirmed All Assignments and Sent Notifications')
        } else {
          toast.success('Unconfirmed All Assignments')
        }
      },
      refetchQueries: ['EmployeeJobsQuery', 'ScheduleJobsQuery'],
    }
  )

  const [getJobs, { data: jobs }] = useLazyQuery(
    gql`
      query EmployeeJobsQuery(
        $startDateTime: DateTime!
        $endDateTime: DateTime!
        $employees: [ID]
      ) {
        jobs(
          startDateTime_Gte: $startDateTime
          endDateTime_Lte: $endDateTime
          employees: $employees
          first: 250
          orderBy: "-start_date_time"
        ) {
          edges {
            node {
              id
              name
              location {
                name
                addressLineOne
              }
              startDateTime
              endDateTime
              employeeJobs {
                edges {
                  node {
                    id
                    startDateTime
                    endDateTime
                    employee {
                      id
                      gaiaUser {
                        id
                        abbreviatedName
                        fullName
                      }
                    }
                    confirmed
                    role {
                      id
                      abbreviatedName
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    }
  )

  if (!showModal || !jobs?.jobs || !startDate || !endDate) return <></>
  const employeeJobIds = Array.from(
    new Set(
      jobs.jobs.edges.flatMap((jobEdge) =>
        jobEdge.node.employeeJobs.edges
          .filter(
            (employeeJobEdge) =>
              employeeId === null ||
              (employeeJobEdge.node.employee !== null &&
                employeeJobEdge.node.employee.id === employeeId)
          )
          .map((employeeJobEdge) => employeeJobEdge.node.id)
      )
    )
  )
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="xl"
          show={showModal}
          onHide={toggleModal}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                Confirm Job Assignments From {humanDate(startDate, false)} to{' '}
                {humanDate(endDate, true)}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="mt-4 mb-3">
                <Col>
                  <SortableInfiniteTable
                    tableData={jobs?.jobs?.edges}
                    tableColumns={[
                      {
                        Header: 'Job',
                        id: 'job',
                        accessor: (row) => {
                          return row.node.name
                        },
                      },
                      {
                        Header: 'Location',
                        id: 'location',
                        accessor: (row) => {
                          return row.node.location?.name
                            ? row.node.location?.name
                            : row.node.location?.addressLineOne
                        },
                      },
                      {
                        Header: 'Job Date and Time',
                        id: 'dateAndTime',
                        accessor: (row) => {
                          const startDateTime = DateTime.fromISO(
                            row.node.startDateTime
                          )
                          const endDateTime = DateTime.fromISO(
                            row.node.endDateTime
                          )
                          return (
                            <>
                              {`${startDateTime.toFormat(
                                "MMM, dd'th' yyyy h:mma"
                              )} - ${endDateTime
                                .toFormat('h:mma')
                                .toLowerCase()}`}
                            </>
                          )
                        },
                      },
                      {
                        Header: employeeId ? 'Assignment' : 'Employees',
                        id: 'employees',
                        accessor: (row) => {
                          return (
                            <>
                              {row.node.employeeJobs.edges.map(
                                (employeeJob, i) => {
                                  if (
                                    !employeeId ||
                                    employeeJob.node?.employee?.id == employeeId
                                  ) {
                                    let employeeAssignment
                                    let employeeJobNode = employeeJob.node.role
                                    let roleName = 'Unassigned'
                                    if (employeeJobNode) {
                                      if (employeeJobNode.abbreviatedName) {
                                        roleName =
                                          employeeJobNode.abbreviatedName
                                      } else {
                                        roleName = employeeJobNode.name
                                      }
                                    }
                                    if (employeeJob.node.employee) {
                                      const gaiaUserNode =
                                        employeeJob.node.employee.gaiaUser
                                      let employeeName
                                      if (gaiaUserNode.abbreviatedName) {
                                        employeeName =
                                          gaiaUserNode.abbreviatedName
                                      } else {
                                        employeeName = gaiaUserNode.fullName
                                      }
                                      employeeAssignment = `${employeeName} (${roleName})`
                                    } else {
                                      if (roleName === 'Unassigned') {
                                        employeeAssignment =
                                          'Unassigned Employee and Role'
                                      } else {
                                        employeeAssignment = `Unassigned (${roleName})`
                                      }
                                    }
                                    let shift
                                    const startDateTime = employeeJob.node
                                      .startDateTime
                                      ? DateTime.fromISO(
                                          employeeJob.node.startDateTime
                                        ).toFormat('h:mma')
                                      : ''
                                    const endDateTime = employeeJob.node
                                      .endDateTime
                                      ? DateTime.fromISO(
                                          employeeJob.node.endDateTime
                                        ).toFormat('h:mma')
                                      : ''
                                    if (startDateTime) {
                                      shift = `${startDateTime}`
                                    }
                                    if (endDateTime) {
                                      if (shift) {
                                        shift = `${shift}-${endDateTime}`
                                      } else {
                                        shift = `End ${endDateTime}`
                                      }
                                    } else if (shift) {
                                      shift = `Start ${shift}`
                                    }
                                    return (
                                      <span key={i}>
                                        {employeeId
                                          ? `${roleName} (Shift ${shift})`
                                          : `${employeeAssignment} (Shift ${shift})`}
                                        {employeeJob.node.employee && (
                                          <>
                                            {employeeJob.node.confirmed && (
                                              <span
                                                className="ml-1"
                                                style={{
                                                  color: 'green',
                                                  fontSize: '14px',
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                  updateEmployeeJob({
                                                    variables: {
                                                      input: {
                                                        employeeJobInput: {
                                                          id: employeeJob.node
                                                            .id,
                                                          confirmed: false,
                                                        },
                                                      },
                                                    },
                                                  })
                                                  toast.success(
                                                    'Unconfirmed Assignment'
                                                  )
                                                }}
                                              >
                                                <CheckSquare />
                                              </span>
                                            )}
                                            {!employeeJob.node.confirmed && (
                                              <span
                                                className="ml-1 text-danger"
                                                style={{
                                                  fontSize: '17px',
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                  updateEmployeeJob({
                                                    variables: {
                                                      input: {
                                                        employeeJobInput: {
                                                          id: employeeJob.node
                                                            .id,
                                                          confirmed: true,
                                                        },
                                                      },
                                                    },
                                                  })
                                                  toast.success(
                                                    'Confirmed Assignment and Sent Notification'
                                                  )
                                                }}
                                              >
                                                <ExclamationTriangle
                                                  style={{
                                                    color: '#dc3545 !important',
                                                  }}
                                                  size={14}
                                                />
                                              </span>
                                            )}
                                          </>
                                        )}
                                        <br />
                                      </span>
                                    )
                                  }
                                }
                              )}
                            </>
                          )
                        },
                      },
                    ]}
                    tableHeight={350}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <Button
                    block
                    variant="outline-primary"
                    onClick={() => {
                      confirmAllEmployeeJobs({
                        variables: {
                          input: {
                            employeeJobIds,
                            confirmed: true,
                          },
                        },
                      })
                    }}
                  >
                    <CheckSquare className="mr-2" /> Confirm All
                  </Button>
                </Col>
                <Col md={2}>
                  <Button
                    block
                    variant="outline-danger"
                    onClick={() => {
                      confirmAllEmployeeJobs({
                        variables: {
                          input: {
                            employeeJobIds,
                            confirmed: false,
                          },
                        },
                      })
                    }}
                  >
                    <ExclamationTriangle className="mr-2" /> Unconfirm All
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default ConfirmEmployeesModal
