import React, { useState } from 'react'
import { Form, Col, Row, Table, Button } from 'react-bootstrap'
import { XCircle, Pencil, GeoAlt, PlusCircle } from 'react-bootstrap-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import Loading from '../common/Loading'

import AutocompleteAddress from './AddressAutocomplete'

export default function LocationField(props) {
  const { formik, md, canMutate, initialLocationData, onlySearchOneOff } = props

  const [displayLocationResults, setDisplayLocationResults] = useState(false)

  const [isOneOffSearch, setIsOneOffSearch] = useState(
    onlySearchOneOff ? true : false
  )

  const [orgStudioInputValue, setOrgStudioInputValue] = useState('')

  const [editableLocationFields, setEditableLocationFields] = useState({
    name: initialLocationData?.name ?? '',
    addressLineTwo: initialLocationData?.addressLineTwo ?? '',
  })
  const [updateLocationErrorMsg, setUpdateLocationErrorMsg] = useState('')
  const [displayAddressFields, setDisplayAddressFields] = useState(false)
  const [locationFields, setLocationFields] = useState({
    id: initialLocationData?.id ? initialLocationData.id : '',
    name: initialLocationData?.name ? initialLocationData.name : '',
    addressLineOne: initialLocationData?.addressLineOne
      ? initialLocationData.addressLineOne
      : '',
    addressLineTwo: initialLocationData?.addressLineTwo
      ? initialLocationData.addressLineTwo
      : '',
    city: initialLocationData?.city ? initialLocationData.city : '',
    state: initialLocationData?.state ? initialLocationData.state : '',
    zipCode: initialLocationData?.zipCode ? initialLocationData.zipCode : '',
    latitude: initialLocationData?.latitude ? initialLocationData.latitude : '',
    longitude: initialLocationData?.longitude
      ? initialLocationData.longitude
      : '',
  })

  const [salesTax] = useLazyQuery(
    gql`
      query SalesTax($city: String, $state: String, $zipCode: String!) {
        salesTax(city: $city, state: $state, zipCode: $zipCode) {
          salesTaxRate
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.salesTax?.salesTaxRate) {
          formik.setFieldValue(
            'locationStateSalexTax',
            data.salesTax.salesTaxRate
          )
        }
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    }
  )

  const GET_LOCATIONS = gql`
    query Locations($fullAddress: String, $after: String) {
      locations(
        archived: false
        fullAddress_Icontains: $fullAddress
        first: 50
        after: $after
      ) {
        edges {
          cursor
          node {
            id
            studio
            archived
            addressLineOne
            addressLineTwo
            city
            fullAddress
            state
            name
            zipCode
            salesTax
            organization {
              id
              name
            }
            subject {
              gaiaUser {
                fullName
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `

  const UPDATE_LOCATION_MUTATION = gql`
    mutation UpdateLocation($updateLocationInput: UpdateLocationInput!) {
      updateLocation(input: $updateLocationInput) {
        location {
          id
          archived
        }
      }
    }
  `

  const [
    searchLocations,
    { data: locationData, fetchMore: fetchMoreLocations },
  ] = useLazyQuery(GET_LOCATIONS, {
    fetchPolicy: 'network-only',
  })

  const [updateLocationMutation] = useMutation(UPDATE_LOCATION_MUTATION, {
    errorPolicy: 'all',
    onError: (error) => {
      const errorMsg = error.graphQLErrors
        .map((error) => error.message)
        .join(', ')

      setUpdateLocationErrorMsg(errorMsg)
      setDisplayAddressFields(true)
    },
  })

  const handleLocationChange = (e) => {
    const value = e.target.value
    setDisplayLocationResults(true)
    setOrgStudioInputValue(value)
    searchLocations({
      variables: {
        fullAddress: value,
      },
    })
  }

  const formatAddress = (locationObj) => {
    if (locationObj) {
      const { name, state, city, addressLineOne, addressLineTwo, zipCode } =
        locationObj

      const formattedAddress = `${name ? `${name}, ` : ''}${
        addressLineOne ? `${addressLineOne}, ` : ''
      }${addressLineTwo ? `${addressLineTwo}, ` : ''}${
        city ? `${city}, ` : ''
      }${state ? `${state}, ` : ''}${zipCode ? zipCode : ''}`

      return formattedAddress
    } else {
      return ''
    }
  }

  const handleLocationClick = (node) => {
    setOrgStudioInputValue('')
    const {
      id,
      addressLineTwo,
      name,
      addressLineOne,
      city,
      state,
      zipCode,
      salesTax,
    } = node

    // form fields
    formik.setFieldValue('locationId', id)
    formik.setFieldValue('locationStateSalexTax', salesTax)

    // internal display
    setLocationFields({
      id,
      name,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      zipCode,
    })
    setDisplayLocationResults(false)

    // editable fields
    setEditableLocationFields({
      name,
      addressLineTwo,
    })
  }

  const clearAddress = () => {
    formik.setFieldValue('locationId', '')
  }

  const clearManualAddress = () => {
    formik.setFieldValue('manualLocation.addressLineOne', '')
    formik.setFieldValue('manualLocation.addressLineTwo', '')
    formik.setFieldValue('manualLocation.city', '')
    formik.setFieldValue('manualLocation.state', '')
    formik.setFieldValue('manualLocation.zipCode', '')
    formik.setFieldValue('manualLocation.name', '')
    formik.setFieldValue('manualLocation.latitude', '')
    formik.setFieldValue('manualLocation.longitude', '')
  }

  const handleXClick = () => {
    // locationId should always be cleared because one-off locations on job edit will mean that formik.values.locationId will have  a value
    formik.setFieldValue('locationId', '')
    formik.setFieldValue('locationStateSalexTax', null)
    setLocationFields({
      id: '',
      name: '',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: '',
      zipCode: '',
    })

    if (isOneOffSearch) {
      clearManualAddress()
    } else {
      clearAddress()
    }
  }

  const handleFetchMoreLocations = () => {
    fetchMoreLocations({
      variables: {
        fullAddress: orgStudioInputValue,
        after: locationData.locations.pageInfo.endCursor,
      },
    })
  }

  const handleLocationBlur = () => {
    setDisplayLocationResults(false)
    formik.setFieldTouched('locationId', true)
  }

  const handleAddressSelect = (addressData) => {
    const data = addressData ? addressData.address : {}
    const {
      postal_code,
      locality,
      administrative_area_level_1,
      premise,
      street_number,
      route,
      subpremise,
    } = data
    const { location } = addressData

    const addressLineOne =
      street_number && route ? street_number + ' ' + route : ''
    const zipCode = postal_code ?? ''
    const state = administrative_area_level_1 ?? ''
    const city = locality ?? ''
    const name = premise ?? ''
    const addressLineTwo = subpremise ?? ''

    formik.setFieldValue('manualLocation.zipCode', zipCode)
    formik.setFieldValue('manualLocation.city', city)
    formik.setFieldValue('manualLocation.name', name)
    formik.setFieldValue('manualLocation.state', state)
    formik.setFieldValue('manualLocation.addressLineOne', addressLineOne)
    formik.setFieldValue('manualLocation.addressLineTwo', addressLineTwo)
    formik.setFieldValue('manualLocation.latitude', location.lat ?? '')
    formik.setFieldValue('manualLocation.longitude', location.lng ?? '')
    salesTax({
      variables: {
        city,
        state,
        zipCode,
      },
    })
    setLocationFields({
      name,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      zipCode,
    })

    setEditableLocationFields({
      name,
      addressLineTwo,
    })
  }

  const handleSaveClick = () => {
    setLocationFields((prevState) => ({
      ...prevState,
      name: editableLocationFields.name,
      addressLineTwo: editableLocationFields.addressLineTwo,
    }))

    // one-off or org/studio locations will have ids, though we will not have ids for newly searched one-off locations
    if (locationFields.id) {
      updateLocationMutation({
        variables: {
          updateLocationInput: {
            locationInput: {
              id: locationFields.id,
              name: editableLocationFields.name,
              addressLineTwo: editableLocationFields.addressLineTwo,
            },
          },
        },
      })
    } else {
      formik.setFieldValue(
        'manualLocation.addressLineTwo',
        editableLocationFields.addressLineTwo
      )
      formik.setFieldValue('manualLocation.name', editableLocationFields.name)
    }
    setDisplayAddressFields(false)
  }

  const addressDisplayValue = formatAddress(locationFields)

  // whether selecting from autocomplete or searching our database, when address is selected, render this input
  const addressDisplayInput = (
    <Form.Control
      size="sm"
      disabled
      className="border border-success"
      value={addressDisplayValue}
    />
  )

  // display when searching our database for locations
  const internalSearch = (
    <>
      <Form.Control
        onBlur={handleLocationBlur}
        disabled={Boolean(formik.values.locationId) || !canMutate}
        placeholder=""
        className={
          formik.values.locationId
            ? 'form-control-sm border border-success d-inline'
            : 'form-control-sm'
        }
        value={orgStudioInputValue}
        onChange={(e) => handleLocationChange(e)}
        isInvalid={formik.errors.locationId && formik.touched?.locationId}
      />
      <Form.Control.Feedback type="invalid">
        {formik.errors.locationId}
      </Form.Control.Feedback>
      {locationData && displayLocationResults ? (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            width: '100%',
            background: 'white',
            zIndex: 10,
          }}
        >
          <InfiniteScroll
            height={
              locationData.locations.edges.length < 5
                ? locationData.locations.edges.length == 1
                  ? 50
                  : 200
                : 200
            }
            dataLength={locationData.locations.edges.length} //This is important field to render the next data
            next={handleFetchMoreLocations}
            hasMore={locationData?.locations.pageInfo.hasNextPage}
            loader={<Loading message="Loading Locations..." />}
          >
            <Table size="sm" hover>
              <tbody>
                {locationData.locations.edges.map((location) => {
                  const { node } = location
                  let locationDesc
                  if (!node.name) {
                    locationDesc = node.fullAddress
                  } else {
                    locationDesc = node.fullAddress.includes(node.name)
                      ? node.fullAddress
                      : node.name
                  }
                  let type
                  if (node.organization) {
                    type = `${node.organization.name} | Organization`
                  } else if (node.studio) {
                    type = 'studio'
                  } else if (node.subject) {
                    type = `${node.subject.gaiaUser.fullName} | Subject`
                  }
                  return (
                    <tr
                      onMouseDown={() => handleLocationClick(node)}
                      key={node.id}
                      className="hover text-decoration-none"
                    >
                      <td className="small">
                        {locationDesc}
                        {type && (
                          <span className="ml-3 bg-secondary text-white rounded px-1">
                            {type}
                          </span>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>
      ) : null}
    </>
  )

  // name and address line 2 are editable
  const addressFields = (
    <div
      style={{
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        background: 'white',
        zIndex: 10,
      }}
    >
      <Form as="div" className="border border-secondary p-3 mt-3 rounded">
        <Form.Group as={Row}>
          <Form.Label column sm={3} className="d-flex align-items center">
            <small>Name</small>
          </Form.Label>
          <Col sm={8} className="d-flex align-items-center">
            <Form.Control
              onChange={(e) =>
                setEditableLocationFields((prevState) => ({
                  name: e.target.value,
                  addressLineTwo: prevState.addressLineTwo,
                }))
              }
              value={editableLocationFields.name || ''}
              size="sm"
              className="small"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mt-2">
          <Form.Label column sm={3} className="d-flex align-items center">
            <small>Address Line Two</small>
          </Form.Label>
          <Col sm={8} className="d-flex align-items-center">
            <Form.Control
              className="small"
              value={editableLocationFields.addressLineTwo || ''}
              size="sm"
              onChange={(e) =>
                setEditableLocationFields((prevState) => ({
                  name: prevState.name,
                  addressLineTwo: e.target.value,
                }))
              }
            />
          </Col>
        </Form.Group>
        <div className="mt-2">
          <Button
            onClick={handleSaveClick}
            variant="outline-primary"
            className="ms-0"
            size="sm"
          >
            Save
          </Button>
          <Button
            variant="outline-primary"
            className="ms-3"
            size="sm"
            onClick={() => setDisplayAddressFields(false)}
          >
            Cancel
          </Button>
          {updateLocationErrorMsg && (
            <div className="mt-3 text-danger">{updateLocationErrorMsg}</div>
          )}
        </div>
      </Form>
    </div>
  )

  return (
    <Form.Group as={Col} md={md ? md : 6}>
      <div className="w-100 position-relative">
        {onlySearchOneOff && <Form.Label>Location</Form.Label>}
        {onlySearchOneOff ? null : (
          <>
            <Form.Label>
              {isOneOffSearch && <>New Location</>}
              {!isOneOffSearch && <>Location</>}
            </Form.Label>
            <Form.Label
              className="btn-link ml-2"
              style={{ cursor: 'pointer', fontSize: '12px' }}
              onClick={() => {
                formik.setFieldValue('locationId', '')
                handleXClick()
                setIsOneOffSearch((prevState) => {
                  formik.setFieldValue('locationId', '')
                  if (prevState) {
                    clearManualAddress()
                  } else {
                    clearAddress()
                  }
                  return !prevState
                })
              }}
            >
              {isOneOffSearch && (
                <>
                  <XCircle className="mr-1" />
                  Existing Location
                </>
              )}
              {!isOneOffSearch && (
                <>
                  <PlusCircle className="mr-1" />
                  New Location
                </>
              )}
            </Form.Label>
          </>
        )}
        <span
          style={{ right: 0 }}
          className={addressDisplayValue ? 'position-absolute' : 'd-none'}
        >
          <Button
            variant="link"
            className="me-2 p-0"
            style={{ marginTop: '-3px' }}
            onClick={() => setDisplayAddressFields(true)}
          >
            <PlusCircle />
          </Button>
          <Button
            variant="link"
            className="p-0"
            style={{ marginTop: '-3px' }}
            onClick={() => {
              handleXClick()
              setDisplayAddressFields(false)
            }}
          >
            <XCircle />
          </Button>
        </span>
      </div>
      {formik.values.locationId ||
      (formik.values.manualLocation.latitude &&
        formik.values.manualLocation.longitude) ? (
        <>
          {addressDisplayInput}
          {displayAddressFields && <>{addressFields}</>}
        </>
      ) : (
        <>
          {isOneOffSearch ? (
            <AutocompleteAddress
              onSelect={handleAddressSelect}
              class="form-control form-control-sm"
            />
          ) : (
            <>{internalSearch}</>
          )}
        </>
      )}
    </Form.Group>
  )
}
