import React, { useState } from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import Loading from '../../common/Loading'

const DeleteItemTypeModal = (props) => {
  const { showModal, toggleModal, id, ids, setCheckedIds } = props
  const [submitting, setSubmitting] = useState(false)

  let _ids = [id]
  if (ids) {
    _ids = ids
  }

  const [mutation] = useMutation(
    gql`
      mutation DeleteItemTypeMutation(
        $deleteEquipmentItemTypeInput: DeleteEquipmentItemTypeInput!
      ) {
        deleteEquipmentItemType(input: $deleteEquipmentItemTypeInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)

        if (ids) {
          toast.success('Item Types Deleted')
        } else {
          toast.success('Item Type Deleted')
        }
        if (setCheckedIds) {
          setCheckedIds([])
        }
        toggleModal()
      },
      refetchQueries: ['EquipmentItemTypes'],
      errorPolicy: 'all',
    }
  )
  const handleDeleteClick = () => {
    setSubmitting(true)

    mutation({
      variables: {
        deleteEquipmentItemTypeInput: {
          equipmentItemTypeIds: _ids,
        },
      },
    })
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              Delete Item Type{_ids && <>s</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p style={{ textAlign: 'center', fontSize: '18px' }}>
                  Are you sure you want to delete the item type
                  {ids && <>s</>}?
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  disabled={submitting}
                  onClick={handleDeleteClick}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            </Row>
            {submitting && (
              <div className="mt-3">
                <Loading />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DeleteItemTypeModal
