import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import Loading from '../../common/Loading'
import {
  ExclamationCircleFill,
  Plug,
  Trash,
  ArrowRepeat,
} from 'react-bootstrap-icons'
import validator from 'validator'

const EditBlueprint = (props) => {
  const { showModal, toggleModal, blueprintConnector } = props

  const [submitting, setSubmitting] = useState(false)
  //   const [syncingClients, setSyncingClients] = useState(false)
  //   const [syncingOrders, setSyncingOrders] = useState(false)

  const [resetting, setResetting] = useState(false)
  const [update] = useMutation(
    gql`
      mutation UpdateImageQuixConnector(
        $updateImagequixConnectorInput: UpdateImageQuixConnectorInput!
      ) {
        updateImageQuixConnector(input: $updateImagequixConnectorInput) {
          imageQuixConnector {
            id
            authValid
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        if (resetting) {
          toast.success('Blueprint Integration Disabled')
          setResetting(false)
          // } else if (syncingClients) {
          //   setSyncingClients(false)
          //   toast.success('Fotomerchant Client Sync Started')
          // } else if (syncingOrders) {
          //   setSyncingOrders(false)
          //   toast.success('Fotomerchant Order Sync Started')
        } else if (data.updateImageQuixConnector.imageQuixConnector.authValid) {
          toast.success('Blueprint Integration Enabled')
        } else {
          toast.error('Invalid Blueprint API Credentials')
        }
        toggleModal()
      },
      refetchQueries: ['BlueprintConnector'],
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (showModal && blueprintConnector) {
      formik.setValues({
        clientId: blueprintConnector.clientId,
        clientSecret: blueprintConnector.clientSecret,
      })
    }
  }, [showModal, blueprintConnector])

  const innerToggleModel = () => {
    toggleModal()
    formik.resetForm()
  }

  const formik = useFormik({
    initialValues: {
      clientId: '',
      clientSecret: '',
    },
    validationSchema: Yup.object().shape({
      clientId: Yup.string().required('Required'),
      clientSecret: Yup.string().required('Required'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      // setSubmitting(true)
      update({
        variables: {
          updateImagequixConnectorInput: {
            imageQuixConnectorInput: {
              id: blueprintConnector.id,
              clientId: values.clientId,
              clientSecret: values.clientSecret,
            },
          },
        },
      })
    },
  })

  const resetToDefault = () => {
    setSubmitting(true)
    setResetting(true)
    update({
      variables: {
        updateImagequixConnectorInput: {
          imageQuixConnectorInput: {
            id: blueprintConnector.id,
            reset: true,
          },
        },
      },
    })
  }

  return (
    <>
      <div className="updateFotomerchantConnector">
        <Modal
          size={blueprintConnector.authValid ? 'lg' : 'md'}
          show={showModal}
          onHide={innerToggleModel}
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                <img
                  className="img-fluid mr-2"
                  src={window.location.origin + '/imagequix.png'}
                  style={{ height: '25px' }}
                  alt="Organization logo"
                />
                Configure Blueprint
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {blueprintConnector.authValid && (
                <>
                  <p>
                    <ExclamationCircleFill className="text-danger mr-2" />
                    Changing the API credentials is extremely destructive
                  </p>
                </>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Client ID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="clientId"
                    placeholder="Client ID"
                    value={formik.values.clientId}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.clientId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.clientId}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Client Secret
                </Form.Label>
                <Col>
                  <Form.Control
                    type="password"
                    name="clientSecret"
                    placeholder="Client Secret"
                    value={formik.values.clientSecret}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.clientSecret}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.clientSecret}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Row>
                <Col md={blueprintConnector.authValid ? 3 : 4}>
                  <Button
                    disabled={submitting}
                    type="submit"
                    block
                    size="sm"
                    variant={'outline-primary'}
                    className="mt-2"
                  >
                    <Plug className="mr-2" />
                    Save
                  </Button>
                </Col>
                {blueprintConnector.authValid && (
                  <>
                    {/* <Col md={3}>
                      <Button
                        disabled={submitting}
                        onClick={() => {
                          setSyncingClients(true)
                          update({
                            variables: {
                              updateFotomerchantConnectorInput: {
                                fotomerchantConnectorInput: {
                                  id: fotomerchantConnector.id,
                                  syncFotomerchantClients: true,
                                },
                              },
                            },
                          })
                        }}
                        block
                        size="sm"
                        variant={'outline-primary'}
                        className="mt-2"
                      >
                        <ArrowRepeat className="mr-2" />
                        Sync Clients
                      </Button>
                    </Col>
                    <Col md={3}>
                      <Button
                        disabled={submitting}
                        onClick={() => {
                          setSyncingOrders(true)
                          update({
                            variables: {
                              updateFotomerchantConnectorInput: {
                                fotomerchantConnectorInput: {
                                  id: fotomerchantConnector.id,
                                  syncFotomerchantOrders: true,
                                },
                              },
                            },
                          })
                        }}
                        block
                        size="sm"
                        variant={'outline-primary'}
                        className="mt-2"
                      >
                        <ArrowRepeat className="mr-2" />
                        Sync Orders
                      </Button>
                    </Col> */}
                    <Col md={3}>
                      <Button
                        disabled={submitting}
                        block
                        onClick={resetToDefault}
                        size="sm"
                        variant="outline-danger"
                        className="mt-2"
                      >
                        <Trash className="mr-2" />
                        Disable
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
              {submitting && (
                <Row className="mt-2">
                  <Col>
                    <Loading
                      message={!resetting && 'Validating Blueprint API key...'}
                    />
                  </Col>
                </Row>
              )}
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default EditBlueprint
