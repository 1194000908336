import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { ArrowLeftShort } from 'react-bootstrap-icons'
import SelectSession from './SelectSession'

const CreateUpdateSessionSelectSession = (props) => {
  const {
    subjectGroupId,
    setSessionPackage,
    sessionPackage,
    setBookingStage,
    setJobId,
    setSessionStartDateTime,
    previousSessionId,
    rescheduleSessionId,
    setLocationInfo,
    showModal,
    subjectId,
    resitSessionId,
    resitReasonId,
    reschedulingNoShow,
    changeSessionPackageSessionId,
  } = props
  const handleChangeSessionPackageClick = () => {
    setSessionPackage()
    setBookingStage('sessionPackage')
  }

  if (!sessionPackage) return <></>

  return (
    <>
      <div className="bookSessionSelectSession">
        <Row className="mb-3">
          <Col>
            <button
              type="button"
              onClick={() => handleChangeSessionPackageClick()}
              className="p-0 m-0 d-flex align-items-center btn-link"
            >
              <ArrowLeftShort /> <span>Back</span>
            </button>
          </Col>
        </Row>
        <SelectSession
          showModal={showModal}
          subjectId={subjectId}
          subjectGroupId={subjectGroupId}
          sessionPackageId={sessionPackage.id}
          setJobId={setJobId}
          setSessionStartDateTime={setSessionStartDateTime}
          setBookingStage={setBookingStage}
          previousSessionId={previousSessionId}
          rescheduleSessionId={rescheduleSessionId}
          changeSessionPackageSessionId={changeSessionPackageSessionId}
          resitSessionId={resitSessionId}
          resitReasonId={resitReasonId}
          setLocationInfo={setLocationInfo}
          reschedulingNoShow={reschedulingNoShow}
        />
      </div>
    </>
  )
}

export default CreateUpdateSessionSelectSession
