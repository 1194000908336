import React, { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Button, Row, Col, Badge } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import {
  CaretRight,
  CaretDown,
  CheckLg,
  SlashCircle,
  ArrowLeft,
  Trash,
  PlusCircle,
} from 'react-bootstrap-icons'
import validator from 'validator'
import AuditLog from '../../../audit_log/AuditLog'
import WebhookEvents from './WebhookEvents'

const WebhookForm = (props) => {
  const { webhook, setView, setEditWebhook } = props

  const [submitting, setSubmitting] = useState(false)
  const [webhook_, setWebhook] = useState()
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const [displayApiCalls, setDisplayApiCalls] = useState(false)

  const [deleteWebhook] = useMutation(
    gql`
      mutation DeleteWebhookKey($input: DeleteWebhookInput!) {
        deleteWebhook(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        setView('list')
        setWebhook()
        toast.success(`Webhook Deleted`)
      },
      refetchQueries: ['Webhooks'],
    }
  )

  const [createWebhook] = useMutation(
    gql`
      mutation CreateWebhook($input: CreateWebhookInput!) {
        createWebhook(input: $input) {
          webhook {
            id
            name
            webhookUrl
            enabled
            requestHeader
            contentType {
              model
            }
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        formik.setFieldValue(
          'requestHeader',
          data.createWebhook.webhook.requestHeader
        )
        setWebhook(data.createWebhook.webhook)
        toast.success(`Webhook Saved`)
      },
      refetchQueries: ['Webhooks'],
    }
  )

  const [updateWebhook] = useMutation(
    gql`
      mutation UpdateWebhook($input: UpdateWebhookInput!) {
        updateWebhook(input: $input) {
          webhook {
            id
            name
            webhookUrl
            enabled
            requestHeader
            contentType {
              model
            }
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        setWebhook(data.updateWebhook.webhook)
        toast.success(`Webhook Saved`)
      },
      refetchQueries: ['Webhooks'],
    }
  )

  const formik = useFormik({
    initialValues: {
      name: '',
      webhookUrl: '',
      requestSecret: '',
      enabled: null,
    },
    validationSchema: Yup.object().shape({
      requestSecret: Yup.string().nullable(),
      name: Yup.string().required('required'),
      webhookUrl: Yup.string()
        .required('required')
        .test('isURL', 'Invalid URL', (value) => {
          let valid = true
          if (
            value &&
            !validator.isURL(value) &&
            value !== 'http://localhost:8000/webhook/'
          ) {
            valid = false
          }
          return valid
        }),
      enabled: Yup.bool().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      if (webhook_) {
        updateWebhook({
          variables: {
            input: {
              webhookInput: {
                id: webhook_.id,
                webhookUrl: values.webhookUrl,
                name: values.name,
              },
            },
          },
        })
      } else {
        createWebhook({
          variables: {
            input: {
              webhookInput: {
                webhookUrl: values.webhookUrl,
                name: values.name,
              },
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (webhook) {
      formik.setFieldValue('name', webhook.name)
      formik.setFieldValue('webhookUrl', webhook.webhookUrl)
      formik.setFieldValue('requestHeader', webhook.requestHeader)
      setWebhook(webhook)
    }
  }, [webhook])

  const handleDelete = () => {
    setSubmitting(true)
    deleteWebhook({
      variables: {
        input: {
          webhookIds: [webhook.id],
        },
      },
    })
  }

  return (
    <>
      <div id="equipmentRepairForm">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group as={Row}>
            <Col md={2}>
              <Form.Label column sm="12" md="auto">
                Name
              </Form.Label>
            </Col>
            <Col sm="12" md={9}>
              <Form.Control
                as="input"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col md={2}>
              <Form.Label column sm="12" md="auto">
                URL
              </Form.Label>
            </Col>
            <Col sm="12" md={9}>
              <Form.Control
                as="input"
                name="webhookUrl"
                value={formik.values.webhookUrl}
                onChange={formik.handleChange}
                isInvalid={formik.errors.webhookUrl}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.webhookUrl}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {webhook_?.id && (
            <>
              <Form.Group as={Row} className="mt-4">
                <Col md={2}>
                  <Form.Label column sm="12" md="auto">
                    Request Header
                  </Form.Label>
                </Col>
                <Col md={9}>
                  <Form.Control
                    type="text"
                    name="requestHeader"
                    value={formik.values.requestHeader}
                    disabled={true}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="">
                <Col md={5} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setDisplayApiCalls(!displayApiCalls)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayApiCalls ? (
                        <CaretDown size={17} />
                      ) : (
                        <CaretRight size={17} />
                      )}
                    </>
                  </button>
                  <Form.Label column sm="12" md="auto">
                    Webhook Events
                  </Form.Label>
                </Col>
              </Form.Group>
              {displayApiCalls && (
                <Form.Group as={Row} className="">
                  <Col md={12}>
                    <WebhookEvents webhookId={webhook_?.id} />
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} className="">
                <Col md={5} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setDisplayAuditLog(!displayAuditLog)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayAuditLog ? (
                        <CaretDown size={17} />
                      ) : (
                        <CaretRight size={17} />
                      )}
                    </>
                  </button>
                  <Form.Label column sm="12" md="auto">
                    History
                  </Form.Label>
                </Col>
              </Form.Group>
              {displayAuditLog && (
                <Form.Group as={Row} className="">
                  <Col md={12}>
                    <AuditLog
                      contentType={webhook_.contentType.model}
                      id={webhook_.id}
                    />
                  </Col>
                </Form.Group>
              )}
            </>
          )}
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                block
                variant="outline-primary"
                onClick={() => {
                  setView('list')
                  setWebhook()
                  setEditWebhook()
                  formik.resetForm()
                }}
              >
                <ArrowLeft className="mr-2" />
                Back
              </Button>
            </Col>
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <PlusCircle className="mr-2" />
                Save
              </Button>
            </Col>

            {webhook_ && (
              <>
                <Col md={3}>
                  <Button
                    variant={
                      webhook_.enabled ? 'outline-danger' : 'outline-primary'
                    }
                    block
                    onClick={() => {
                      updateWebhook({
                        variables: {
                          input: {
                            webhookInput: {
                              id: webhook_.id,
                              enabled: !webhook_.enabled,
                            },
                          },
                        },
                      })
                    }}
                    disabled={submitting}
                  >
                    {webhook_.enabled ? (
                      <>
                        <SlashCircle className="mr-2" />
                      </>
                    ) : (
                      <>
                        <CheckLg className="mr-2" />
                      </>
                    )}
                    {webhook_.enabled ? <>Disable</> : <>Enable</>}
                  </Button>
                </Col>
                <Col md={3}>
                  <Button
                    variant="outline-danger"
                    block
                    onClick={handleDelete}
                    disabled={submitting}
                  >
                    <Trash className="mr-2" />
                    Delete
                  </Button>
                </Col>
              </>
            )}
          </Form.Row>
        </Form>
      </div>
    </>
  )
}

export default WebhookForm
