import React, { useState } from 'react'
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash, ExclamationCircle } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { useFormik } from 'formik'

const DeleteOrganizationModal = (props) => {
  const {
    showModal,
    toggleModal,
    organizationId,
    organizationIds,
    setCheckedOrganizationIds,
  } = props
  const history = useHistory()
  let _organizationIds = [organizationId]
  if (organizationIds) {
    _organizationIds = organizationIds
  }
  const [submitting, setSubmitting] = useState(false)

  const [deleteOrg] = useMutation(
    gql`
      mutation DeleteOrganization(
        $deleteOrganizationInput: DeleteOrganizationInput!
      ) {
        deleteOrganization(input: $deleteOrganizationInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        if (formik.values.recordType === 'subject') {
          toast.success('Deleting Subjects')
        } else if (formik.values.recordType === 'subjectGroup') {
          toast.success('Deleting Subject Groups')
        } else {
          if (organizationIds) {
            toast.success('Organizations Deleted')
          } else {
            toast.success('Organization Deleted')
          }
        }

        if (setCheckedOrganizationIds) {
          setCheckedOrganizationIds([])
        }
        toggleModal()
        formik.resetForm()
        if (formik.values.recordType === 'organization') {
          history.push('/organizations')
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['Organizations', 'SubjectGroupsQuery', 'Subjects'],
    }
  )

  const formik = useFormik({
    initialValues: {
      deleteSubjects: false,
      deleteSubjectGroups: false,
      deleteSessions: false,
      recordType: '- - -',
    },
    validationSchema: Yup.object().shape({
      recordType: Yup.string().test('isRequired', 'Required', (value) => {
        return !(value === '- - -')
      }),
      deleteSubjects: Yup.bool().nullable(),
      deleteSubjectGroups: Yup.bool().nullable(),
      deleteSessions: Yup.bool().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      deleteOrg({
        variables: {
          deleteOrganizationInput: {
            organizationInput: {
              ids: _organizationIds,
              recordType: values.recordType,
              deleteSubjects: values.deleteSubjects,
              deleteSubjectGroups: values.deleteSubjectGroups,
              deleteSessions: values.deleteSessions,
            },
          },
        },
      })
    },
  })

  if (!showModal) return null

  return (
    <div className="editSessionResitReasonModal">
      <Modal
        size="md"
        show={showModal}
        onHide={() => toggleModal()}
        aria-labelledby="newResitReason"
        className="invmodal detail"
      >
        <Modal.Header closeButton>
          <Modal.Title id="new-title">
            Delete Organization{organizationIds && <>s</>} Records
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md={6} className="mb-0 pb-0">
                <Form.Control
                  name="recordType"
                  as="select"
                  className="form-control form-control-sm"
                  value={formik.values.recordType}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.recordType}
                >
                  <option value="- - -">Select Record Type</option>
                  <option value="organization">Organization</option>
                  <option value="subjectGroup">Subject Groups</option>
                  <option value="subject">Subjects</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.recordType}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                {formik.values.recordType === 'organization' && (
                  <Form.Group className="mb-3">
                    <Form.Check
                      name="deleteSubjectGroups"
                      type="checkbox"
                      label="Delete Subject Groups"
                      checked={formik.values.deleteSubjectGroups}
                      onChange={(e) => {
                        formik.setFieldValue(
                          'deleteSubjectGroups',
                          e.target.checked
                        )
                      }}
                    />
                  </Form.Group>
                )}
                {['organization', 'subjectGroup'].includes(
                  formik.values.recordType
                ) && (
                  <Form.Group className="mb-3">
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <Form.Check
                          name="deleteSubjects"
                          type="checkbox"
                          label="Delete Subjects"
                          checked={formik.values.deleteSubjects}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'deleteSubjects',
                              e.target.checked
                            )
                          }}
                        />
                      </Col>
                      {formik.values.deleteSubjects && (
                        <Col className="d-flex align-items-center text-danger">
                          <ExclamationCircle
                            height={50}
                            width={50}
                            className="mr-1"
                          />
                          <span style={{ fontSize: '0.85rem' }}>
                            Subject records will be deleted. Uncheck this box to
                            delete the organization and retain subject records.
                          </span>
                        </Col>
                      )}
                    </Row>
                  </Form.Group>
                )}
                {formik.values.recordType !== '- - -' && (
                  <Form.Group>
                    <Form.Check
                      name="deleteSessions"
                      type="checkbox"
                      label="Delete Sessions"
                      checked={formik.values.deleteSessions}
                      onChange={(e) => {
                        formik.setFieldValue('deleteSessions', e.target.checked)
                      }}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  disabled={submitting}
                  type="submit"
                >
                  <Trash className="mr-2" />
                  Delete{' '}
                  {formik.values.recordType === 'subjectGroup' && (
                    <>Subject Groups</>
                  )}
                  {formik.values.recordType === 'subject' && <>Subjects</>}
                  {formik.values.recordType === 'organization' && (
                    <>Organization</>
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DeleteOrganizationModal
