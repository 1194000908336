import React, { useState } from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import Loading from '../common/Loading'
import SortableTable from '../common/SortableTable'

const DeleteFileModal = (props) => {
  const {
    showModal,
    toggleModal,
    id,
    ids,
    setCheckedNodeIds,
    cantDelete,
    setCantDelete,
  } = props
  const [submitting, setSubmitting] = useState(false)
  let _ids = [id]
  if (ids) {
    _ids = ids
  }
  console.log(cantDelete)
  const [mutation] = useMutation(
    gql`
      mutation DeleteFolderFile($input: DeleteFolderFileInput!) {
        deleteFolderFile(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        setCantDelete([])
        if (ids) {
          if (ids.length > 5) {
            toast.success('Deleting Folder Items')
          } else {
            toast.success('Folder Items Deleted')
          }
        } else {
          if (_ids.length === 1) {
            toast.success('Folder Item Deleted')
          } else {
            toast.success('Folder Items Deleted')
          }
        }
        if (setCheckedNodeIds) {
          setCheckedNodeIds([])
        }
        toggleModal()
      },
      refetchQueries: ['Folders'],
      errorPolicy: 'all',
    }
  )

  const handleDeleteClick = () => {
    setSubmitting(true)
    mutation({
      variables: {
        input: {
          folderFileInput: {
            items: _ids,
          },
        },
      },
    })
  }

  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              Delete Folder Item{_ids && <>s</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="text-center">
                <p>
                  Are you sure you want to delete the folder item
                  {ids && <>s</>}?
                </p>
              </Col>
            </Row>
            {cantDelete.length > 0 && (
              <Row className="mb-3">
                <Col className="text-center">
                  <p>
                    The following protected items will be skipped during
                    deletion
                  </p>

                  <SortableTable
                    columns={[
                      {
                        Header: 'Type',
                        accessor: (row) =>
                          row.type.charAt(0).toUpperCase() +
                          row.type.slice(1).toLowerCase(),
                      },
                      {
                        Header: 'Name',
                        accessor: 'name',
                      },
                    ]}
                    data={cantDelete}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  disabled={submitting}
                  onClick={handleDeleteClick}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            </Row>
            {submitting && (
              <div className="mt-2">
                <Loading />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DeleteFileModal
