import React from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'

const DeleteJobStageModal = (props) => {
  const { jobStage, showModal, toggleModal } = props

  const [deleteJobStage] = useMutation(
    gql`
      mutation DeleteJobStageMutations(
        $deleteJobStageInput: DeleteJobStageInput!
      ) {
        deleteJobStage(input: $deleteJobStageInput) {
          deleted
        }
      }
    `,
    {
      refetchQueries: ['JobStages'],
      onCompleted: () => {
        toast.success('Stage Deleted')
        toggleModal()
      },
    }
  )

  if (!showModal) return null

  return (
    <Modal show={showModal} onHide={toggleModal} size="sm">
      <Modal.Header closeButton>Delete Stage</Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>Are you sure you want to delete {jobStage.name} stage?</p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Button
              variant="link"
              size="sm"
              block
              style={{ color: 'red !important' }}
              onClick={() => {
                deleteJobStage({
                  variables: {
                    deleteJobStageInput: {
                      jobStageIds: [jobStage.id],
                    },
                  },
                })
              }}
            >
              <Trash className="mr-2" />
              Delete
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteJobStageModal
