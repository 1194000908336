import React from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'

const DeleteJobTypeModal = (props) => {
  const { jobType, showModal, toggleModal } = props

  const [deleteJobType] = useMutation(
    gql`
      mutation DeleteJobTypeMutations(
        $deleteJobTypeInput: DeleteJobTypeInput!
      ) {
        deleteJobType(input: $deleteJobTypeInput) {
          deleted
        }
      }
    `,
    {
      refetchQueries: ['JobTypes'],
      onCompleted: () => {
        toast.success('Job Type Deleted')
        toggleModal()
      },
    }
  )

  if (!showModal) return null

  return (
    <Modal show={showModal} onHide={toggleModal} size="sm">
      <Modal.Header closeButton>Delete Job Type</Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>Are you sure you want to delete {jobType.name} job type?</p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Button
              variant="link"
              size="sm"
              block
              style={{ color: 'red !important' }}
              onClick={() => {
                deleteJobType({
                  variables: {
                    deleteJobTypeInput: {
                      jobTypeIds: [jobType.id],
                    },
                  },
                })
              }}
            >
              <Trash className="mr-2" />
              Delete
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteJobTypeModal
