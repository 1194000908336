import React, { useEffect, useState } from 'react'
import { Form, Modal, Tab, Row, Col } from 'react-bootstrap'
import Tabs from '../../common/Tabs'
import { BarChartLine } from 'react-bootstrap-icons'
import EmployeeHours from './EmployeeHours'
import UnassignedEmployees from './UnassignedEmployees'
import EmployeeRoleAssignments from './EmployeeRoleAssignments'
import AvailableSessions from './AvailableSessions'
import SessionReport from '../../sessions/SessionReport'
import JobReport from './JobReport'
import EmployeeAssignmentChart from './EmployeeAssignmentChart'

const JobReportModal = (props) => {
  const { showModal, toggleModal, organizationId, subjectGroupId } = props

  const [activeTab, setActiveTab] = useState('employeeHours')

  useEffect(() => {
    if (!showModal) {
      setActiveTab('employeeHours')
    }
  }, [showModal])

  if (!showModal) return <></>

  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="xl"
          show={showModal}
          onHide={toggleModal}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                <BarChartLine className="mr-2" />
                Job Reports
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
                <Tab
                  key="employeeHours"
                  eventKey="employeeHours"
                  style={{ marginTop: '10px' }}
                  title={<b>Employee Hours</b>}
                >
                  <EmployeeHours showModal={showModal} />
                </Tab>
                <Tab
                  key="unassignedEmployees"
                  eventKey="unassignedEmployees"
                  style={{ marginTop: '10px' }}
                  title={<b>Unassigned Employees</b>}
                >
                  <UnassignedEmployees showModal={showModal} />
                </Tab>
                <Tab
                  key="roleAssignments"
                  eventKey="roleAssignments"
                  style={{ marginTop: '10px' }}
                  title={<b>Role Assignments</b>}
                >
                  <EmployeeRoleAssignments showModal={showModal} />
                </Tab>
                <Tab
                  key="assignmentsChart"
                  eventKey="assignmentsChart"
                  style={{ marginTop: '10px' }}
                  title={<b>Assignments Chart</b>}
                >
                  <EmployeeAssignmentChart
                    showModal={showModal}
                    organizationId={organizationId}
                    subjectGroupId={subjectGroupId}
                  />
                </Tab>
                <Tab
                  key="jobsChart"
                  eventKey="jobsChart"
                  style={{ marginTop: '10px' }}
                  title={<b>Jobs Chart</b>}
                >
                  <JobReport
                    showModal={showModal}
                    organizationId={organizationId}
                    subjectGroupId={subjectGroupId}
                  />
                </Tab>
                <Tab
                  key="sessionsChart"
                  eventKey="sessionsChart"
                  style={{ marginTop: '10px' }}
                  title={<b>Sessions Chart</b>}
                >
                  <SessionReport
                    jobReports
                    showModal={showModal}
                    organizationId={organizationId}
                    subjectGroupId={subjectGroupId}
                  />
                </Tab>
                <Tab
                  key="availableSessions"
                  eventKey="availableSessions"
                  style={{ marginTop: '10px' }}
                  title={<b>Available Sessions</b>}
                >
                  <Row>
                    <Col md={{ offset: 1, span: 10 }}>
                      <AvailableSessions showModal={showModal} />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default JobReportModal
