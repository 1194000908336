import React from 'react'
import { Row, Col } from 'react-bootstrap'
import SessionType from './SessionType'

export default function BookNewSession() {
  return (
    <>
      <Row style={{ marginTop: '18px' }}>
        <Col>
          <h1>Book Session</h1>
          <SessionType />
        </Col>
      </Row>
    </>
  )
}
