import React from 'react'
import { Modal } from 'react-bootstrap'
import PermissionTable from './PermissionTable'

const PermissionsModal = (props) => {
  const { showModal, hideModal } = props
  return (
    <Modal
      size="xl"
      show={showModal}
      onHide={hideModal}
      contentClassName="my-custom-modal"
      aria-labelledby="new-title"
      className="invmodal detail"
    >
      <Modal.Header closeButton>
        <Modal.Title>Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PermissionTable setShowPermissions={showModal} />
      </Modal.Body>
    </Modal>
  )
}

export default PermissionsModal
