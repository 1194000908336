import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Form } from 'react-bootstrap'
import StudentLogin from './StudentLogin'
import EmailLogin from './EmailLogin'
import { useReactiveVar } from '@apollo/client'
import { settingsVar } from '../../libs/apollo'

export default function LoginBox(props) {
  const { initialLoginView } = props
  const location = useLocation()
  const [loginOption, setLoginOption] = useState('student')
  const history = useHistory()
  const settings = useReactiveVar(settingsVar)
  let loginDisplay = <StudentLogin colMd={6} />
  let md = 6
  if (location.pathname === '/') {
    md = 12
  }

  useEffect(() => {
    if (initialLoginView) {
      setLoginOption(initialLoginView)
    }
  }, [initialLoginView])

  const handleLoggedInStudent = (user, subject) => {
    if (
      user.groups.edges
        .map((group) => group.node.name)
        .includes('Organization Contact')
    ) {
      if (user.organizationContacts.edges.length === 1) {
        const orgId = user.organizationContacts.edges[0].node.id
        history.push(`/organization/${orgId}/contact`)
      } else {
        history.push(`/organizations/contact`)
      }
    } else {
      history.push(`/subject/sessions`)
    }
  }

  const handlePostLogin = (user, subject) => {
    if (
      user.groups.edges
        .map((group) => group.node.name)
        .includes('Organization Contact')
    ) {
      if (user.organizationContacts.edges.length === 1) {
        const orgId = user.organizationContacts.edges[0].node.id
        history.push(`/organization/${orgId}/contact`)
      } else {
        history.push(`/organizations/contact`)
      }
    } else if (
      user.groups.edges.map((group) => group.node.name).includes('Subject')
    ) {
      history.push(`/subject/sessions`)
    } else if (
      user.groups.edges
        .map((group) => group.node.name)
        .includes('General Staff')
    ) {
      history.push(`/employee/${user.employee.id}`)
    } else {
      history.push('/schedule')
    }
  }
  if (loginOption === 'student' && settings?.showLastNameIdLogin) {
    loginDisplay = (
      <StudentLogin
        loginBox
        handleLoggedInStudent={handleLoggedInStudent}
        colMd={md}
      />
    )
  } else {
    loginDisplay = <EmailLogin handlePostLogin={handlePostLogin} colMd={md} />
  }

  const handleLoginChange = (loginOptionName) => {
    if (loginOptionName === 'student') {
      setLoginOption('student')
    } else if (loginOptionName === 'email') {
      setLoginOption('email')
    } else {
      setLoginOption('create')
    }
  }

  if (!settings) return <></>
  return (
    <>
      <Row>
        <Col md={md}>
          <div>
            {settings.showLastNameIdLogin && (
              <div className="p-3">
                <Form.Check
                  inline
                  type="radio"
                  className="small"
                  label="Student Login"
                  checked={loginOption === 'student'}
                  onChange={() => handleLoginChange('student')}
                />
                <Form.Check
                  inline
                  type="radio"
                  className="small"
                  label="Email and Password Login"
                  name="emailLogin"
                  checked={loginOption === 'email'}
                  onChange={() => handleLoginChange('email')}
                />
              </div>
            )}

            <div className="px-2">{loginDisplay}</div>
          </div>
        </Col>
      </Row>
    </>
  )
}
