import React from 'react'
import { Trash, PlusCircle } from 'react-bootstrap-icons'
import { Form, Table, Button } from 'react-bootstrap'

const MetaDataTable = (props) => {
  const { metaData, setMetaData, edit, noMessage, header, btnStyle } = props

  const addNewKeyPair = () => {
    let obj = { ...metaData }
    obj[''] = ''
    setMetaData(obj)
  }

  const deleteObjectPair = (key) => {
    let obj = { ...metaData }
    delete obj[key]
    setMetaData(obj)
  }

  const updateKey = (oldKey, newKey) => {
    let obj = { ...metaData }
    Object.defineProperty(
      obj,
      newKey,
      Object.getOwnPropertyDescriptor(obj, oldKey)
    )
    delete obj[oldKey]
    setMetaData(obj)
  }

  const updateValue = (key, value, index) => {
    let obj = { ...metaData }
    obj[key] = value
    setMetaData(obj)
  }

  return (
    <>
      {edit && (
        <Button variant="link" onClick={addNewKeyPair}>
          <PlusCircle style={{ cursor: 'pointer' }} className="mr-2" />
          <span style={btnStyle ? btnStyle : {}}>Add Metadata</span>
        </Button>
      )}
      {Object.keys(metaData).length > 0 && (
        <Table borderless>
          <tbody>
            {header && (
              <tr>
                <td>Key</td>
                <td>Value</td>
              </tr>
            )}
            {Object.keys(metaData).map((key, index) => {
              return (
                <tr key={index}>
                  <td>
                    {edit && (
                      <Form.Control
                        className="form-control-sm"
                        value={key}
                        placeholder="key"
                        onChange={(e) => updateKey(key, e.target.value)}
                      />
                    )}
                    {!edit && <p>{key}</p>}
                  </td>
                  <td>
                    {edit && (
                      <Form.Control
                        className="form-control-sm"
                        value={metaData[key]}
                        placeholder="value"
                        onChange={(e) =>
                          updateValue(key, e.target.value, index)
                        }
                      />
                    )}
                    {!edit && <p>{metaData[key]}</p>}
                  </td>
                  {edit && (
                    <td>
                      <Trash
                        className="btn-link"
                        onClick={() => {
                          deleteObjectPair(key)
                        }}
                      />
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </Table>
      )}
      {Object.keys(metaData).length === 0 && !edit && (
        <>{!noMessage ? <p>- - -</p> : <p>No Metadata</p>}</>
      )}
    </>
  )
}

export default MetaDataTable
