import React, { useRef, useState, useEffect } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import { gql, useMutation } from '@apollo/client'
import Loading from '../../common/Loading'

const ReviewESignRequestModal = (props) => {
  const { embedUrl, toggleModal, showModal, boxSignRequestId } = props

  const modalRef = useRef(null)
  const [iframeSize, setIframeSize] = useState({ width: 1000, height: 500 })
  const [syncing, setSyncing] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (modalRef.current) {
        const width = modalRef.current.offsetWidth
        const height = modalRef.current.offsetHeight
        setIframeSize({ width, height })
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const [updateBoxSignRequest] = useMutation(
    gql`
      mutation UpdateBoxSignRequest(
        $updateBoxSignRequestInput: UpdateBoxSignRequestInput!
      ) {
        updateBoxSignRequest(input: $updateBoxSignRequestInput) {
          boxSignRequest {
            id
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted: () => {
        setSyncing(false)
        toggleModal()
      },
      refetchQueries: ['BoxSignRequests'],
    }
  )

  if (!showModal) return <></>
  return (
    <>
      <div className="reviewEsignRequest">
        <Modal
          size="xl"
          show={showModal}
          onHide={() => {
            setSyncing(true)
            updateBoxSignRequest({
              variables: {
                updateBoxSignRequestInput: {
                  boxSignRequestInput: {
                    id: boxSignRequestId,
                    sync: true,
                  },
                },
              },
            })
          }}
          aria-labelledby="reviewEsignRequest"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">Review Sign Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {syncing && (
              <Row>
                <Col>
                  <Loading message="Syncing Sign Status" />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <iframe
                  className="full-width-iframe"
                  src={embedUrl}
                  width={iframeSize.width}
                  height={iframeSize.height}
                  allowfullscreen
                  webkitallowfullscreen
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default ReviewESignRequestModal
