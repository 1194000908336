import config from '../config'
import { gql, useQuery } from '@apollo/client'

export const fotomerchantAdminUrl = (route) => {
  let baseUrl
  if (['development', 'qa', 'qa2'].includes(config.ENVIRONMENT)) {
    baseUrl = 'https://admin.staging.fotomerchanthv.com/'
  } else {
    baseUrl = 'https://admin.fotomerchanthv.com/'
  }

  return `${baseUrl}${route}`
}

export const fotomerchantClientAdminUrl = (id) => {
  return fotomerchantAdminUrl(`clients/${id}`)
}

export const fotomerchantClientsAdminUrl = () => {
  return fotomerchantAdminUrl(`clients`)
}

export const fotomerchantOrderAdminUrl = (id) => {
  return fotomerchantAdminUrl(`orders/show/${id}`)
}

export const fotomerchantClientSessionTemplateAdminUrl = (id) => {
  return fotomerchantAdminUrl(`clientSessionTemplate/${id}`)
}

export const fotomerchantClientSessionTemplatesAdminUrl = () => {
  return fotomerchantAdminUrl(`clientSessionTemplate`)
}

export const fotomerchantClientSessionAdminUrl = (clientId, sessionId) => {
  return fotomerchantAdminUrl(`clients/${clientId}/clientSession/${sessionId}`)
}

export const fotomerchantSubjectAdminUrl = (clientId, sessionId, subjectId) => {
  return fotomerchantAdminUrl(
    `clients/${clientId}/clientSession/${sessionId}/subject/${subjectId}`
  )
}

export const useFotomerchantEnabled = () => {
  const { data } = useQuery(
    gql`
      query FotomerchantConnector {
        fotomerchantConnectors {
          edges {
            node {
              id
              apiKeyValid
              subjectGalleryUrl
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  if (!data) return
  return data.fotomerchantConnectors.edges[0].node.apiKeyValid
}

export const useFotomerchantSubjectGalleryUrl = () => {
  const { data } = useQuery(
    gql`
      query FotomerchantConnector {
        fotomerchantConnectors {
          edges {
            node {
              subjectGalleryUrl
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  if (!data) return
  return data.fotomerchantConnectors.edges[0].node.subjectGalleryUrl
}
