import React, { useEffect, useState } from 'react'
import { Row, Col, Modal, Button, Table, Badge } from 'react-bootstrap'
import { useLazyQuery, gql } from '@apollo/client'
import { formatDateTimeToString } from '../../libs/utils'
import { useImagequixEnabled } from '../../libs/imagequix'
import { useFotomerchantSubjectGalleryUrl } from '../../libs/fotomerchant'
import { DateTime } from 'luxon'
import { Key, CaretDown, CaretRight, Camera } from 'react-bootstrap-icons'
import { useParams } from 'react-router-dom'
import { useBoxEnabled } from '../../libs/box'
import { settingsVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import BoxFolder from '../files/box/BoxFolder'
import MapModal from '../location/MapModal'
import FilesTable from '../files/FilesTable'

const SessionDetailModal = (props) => {
  const { contact } = useParams()
  const [displayFiles, setDisplayFiles] = useState(false)
  const [showMapModal, setShowMapModal] = useState(false)
  const boxEnabled = useBoxEnabled()

  const { showModal, toggleModal, sessionId, setSessionListPollingInterval } =
    props
  const iqEnabled = useImagequixEnabled()
  const fotomerchantSubejctGalleryUrl = useFotomerchantSubjectGalleryUrl()
  const settings = useReactiveVar(settingsVar)

  const [
    sessionQuery,
    {
      loading: sessionQueryLoading,
      error: sessionQueryError,
      data: sessionQueryData,
    },
  ] = useLazyQuery(
    gql`
      query SessionQuery($sessionId: ID!) {
        session(id: $sessionId) {
          id
          endDateTime
          created
          sharedCanSeeFiles
          sharedCanCreateFiles
          sharedCanCreateFolders
          sessionPackage {
            id
            title
            price
            customPriceAndDuration
            durationLowMinutes
            durationHighMinutes
            description
          }
          fotomerchantSubject {
            fotomerchantId
            fotomerchantPassword
          }
          imagequixSubject {
            imagequixId
          }
          paid
          sharedBoxFolderId
          subject {
            gaiaUser {
              fullName
            }
          }
          subjectGroup {
            name
          }
          feeNoShow
          feeResit
          sharedFolder {
            id
          }
          feeReschedule
          startDateTime
          endDateTime
          job {
            subjectNotes
            location {
              name
              latitude
              longitude
              fullAddress
            }
          }
          resitSession {
            startDateTime
          }
          stripeInvoiceItems(
            stripeInvoice_Paid: false
            stripeInvoice_Voided: false
            orderBy: "-created"
            first: 1
          ) {
            nodeCount
            edges {
              node {
                stripePriceResource
                stripeInvoice {
                  paid
                }
              }
            }
          }
          stripePaymentIntents(
            orderBy: "-created"
            description: "Session Package Fee"
          ) {
            edges {
              node {
                id
                stripeId
                refunded
                description
                updated
              }
            }
          }
          refundAmount
          noShow
          cancelled
          resitScheduled
          rescheduled
          sessionPackageChanged
          completed
          notes
          paid
          price
          salesTax
          billSubject
          sessionPackagePrice
          couponSavings
          organization {
            category
            name
          }
          resitReason {
            reason
          }
          subjectGroup {
            name
            endDateTime
            subjectsPaying
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )
  useEffect(() => {
    if (sessionId) {
      sessionQuery({
        variables: {
          sessionId: sessionId,
        },
      })
    }
  }, [sessionId])

  const formatSessionStartTime = (sessionStartTime) => {
    const startDateTime = new Date(sessionStartTime)
    return formatDateTimeToString(startDateTime)
  }

  const constructSessionStartTimeLabel = (session) => {
    let message
    if (session.noShow) {
      message = 'Missed on'
    } else if (session.completed) {
      message = 'Complete on'
    } else {
      message = 'Upcoming on'
    }
    return message
  }

  const constructResitSessionDetail = () => {
    let resitSessionDetail = null
    if (session.resitScheduled && session.resitSession) {
      resitSessionDetail = (
        <>
          <Row>
            <Col md={6}>
              <h4>Resit</h4>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mt-1">
              <p>
                {formatSessionStartTime(session.resitSession.startDateTime)}{' '}
                because {session.resitReason.reason.toLowerCase()}
              </p>
            </Col>
          </Row>
        </>
      )
    }
    return resitSessionDetail
  }

  if (sessionQueryLoading || !sessionQueryData || !settings) return <></>
  if (sessionQueryError) return <>Error loading session</>
  const { session } = sessionQueryData
  let orgComponent
  if (session?.organization && session?.organization.category === 'School') {
    orgComponent = (
      <Row>
        <Col>
          <h5>School</h5>
          <p>{session?.organization.name}</p>
        </Col>
      </Row>
    )
  } else if (
    session?.organization &&
    session?.organization.category !== 'School'
  ) {
    orgComponent = (
      <Row>
        <Col>
          <h5>Organization</h5>
          <p>{session?.organization.name}</p>
        </Col>
      </Row>
    )
  }
  let subjectGroupComponent
  if (session?.subjectGroup) {
    subjectGroupComponent = (
      <Row>
        <Col>
          <h5>Session Type</h5>
          <p>{session?.subjectGroup.name}</p>
        </Col>
      </Row>
    )
  }
  let paymentIntent
  let refund
  let lastPaymentRefund
  if (session?.stripePaymentIntents?.edges.length > 0) {
    session.stripePaymentIntents.edges.forEach((edge) => {
      if (!paymentIntent) {
        paymentIntent = edge.node
        if (paymentIntent.refunded) {
          lastPaymentRefund = true
        }
      }
      if (!refund && session.refundAmount) {
        refund = session.refundAmount.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }
    })
  }

  let feeReschedule = session.feeReschedule ? session.feeReschedule : 0
  let feeNoShow = session.feeNoShow ? session.feeNoShow : 0
  let feeResit = session.feeResit ? session.feeResit : 0
  let sessionDuration
  if (session.sessionPackage.customPriceAndDuration) {
    const startDateTime = DateTime.fromISO(session.startDateTime)
    const endDateTime = DateTime.fromISO(session.endDateTime)
    sessionDuration = endDateTime
      .diff(startDateTime, 'minutes')
      .toObject().minutes
  }
  const sessionTotal =
    session.sessionPackagePrice -
    session.couponSavings +
    session.salesTax +
    feeResit +
    feeNoShow +
    feeReschedule
  let activeInvoice = session.stripeInvoiceItems.nodeCount > 0 ? true : false
  return (
    <>
      <MapModal
        showModal={showMapModal}
        location={session?.job?.location}
        toggleModal={setShowMapModal}
      />
      <div className="sessionDetail">
        <Modal
          size="lg"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="new-title"
        >
          <Modal.Header closeButton>
            <h3>
              <Camera className="mr-2" />
              Session Details
            </h3>
            {activeInvoice && contact && (
              <h6 style={{ marginTop: '10px' }}>
                <Badge
                  className="text-white ml-2"
                  style={{
                    backgroundColor: 'green',
                  }}
                >
                  Invoiced
                </Badge>
              </h6>
            )}
          </Modal.Header>
          <Modal.Body>
            {orgComponent && orgComponent}
            {subjectGroupComponent && subjectGroupComponent}
            <Row>
              <Col md={6}>
                <h5>Session Package</h5>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <p>
                  {session?.sessionPackage.title},{' '}
                  {sessionDuration && <>{sessionDuration} minutes</>}
                  {!sessionDuration && (
                    <>
                      {session?.sessionPackage.durationLowMinutes}-
                      {session?.sessionPackage.durationHighMinutes} minutes
                    </>
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <p>{session?.sessionPackage.description}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>Location</h5>
                <div
                  style={{ cursor: 'pointer' }}
                  className="btn-link"
                  onClick={() => setShowMapModal(true)}
                >
                  {session?.job.location.name && (
                    <p>
                      {session?.job.location.name},{' '}
                      {session?.job.location.fullAddress}
                    </p>
                  )}
                  {!session?.job.location.name && (
                    <p>{session?.job.location.fullAddress}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>Date and Time</h5>
                <p>
                  {constructSessionStartTimeLabel(session)}{' '}
                  {formatSessionStartTime(session.startDateTime)}
                </p>
              </Col>
            </Row>
            {contact && (
              <Row>
                <Col>
                  <h5>Booked On</h5>
                  <p>{formatSessionStartTime(session.created)}</p>
                </Col>
              </Row>
            )}
            {constructResitSessionDetail()}
            {sessionQueryData.session.fotomerchantSubject?.fotomerchantId && (
              <>
                <Row className="mb-2">
                  <Col>
                    <Button
                      variant="link"
                      style={{ marginLeft: '15px', marginTop: '1px' }}
                      onClick={() => {
                        window.open(fotomerchantSubejctGalleryUrl, '_blank')
                      }}
                    >
                      <span style={{ fontSize: '25px', marginLeft: '-17px' }}>
                        Gallery
                      </span>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <h4>Gallery Access Code</h4>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <Key className="mr-2" size={22} />
                    {
                      sessionQueryData.session.fotomerchantSubject
                        .fotomerchantPassword
                    }
                  </Col>
                </Row>
              </>
            )}
            {(session.billSubject || contact) && (
              <>
                <Row>
                  <Col md={6}>
                    <h5>Billing</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table size="sm" style={{ width: '250px' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '100px' }}>
                            <small>Session Package</small>
                          </td>
                          <td className="text-end" style={{ width: '100px' }}>
                            <small>
                              {session.sessionPackagePrice.toLocaleString(
                                'en-US',
                                {
                                  style: 'currency',
                                  currency: 'USD',
                                }
                              )}
                            </small>
                          </td>
                        </tr>
                        {session.couponSavings > 0 && (
                          <tr>
                            <td>
                              <small>Coupon</small>
                            </td>
                            <td className="text-end">
                              <small>
                                -{' '}
                                {session.couponSavings.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })}
                              </small>
                            </td>
                          </tr>
                        )}
                        {feeReschedule > 0 && (
                          <tr>
                            <td>
                              <small>Reschedule Fee</small>
                            </td>
                            <td className="text-end">
                              <small>
                                {feeReschedule.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })}
                              </small>
                            </td>
                          </tr>
                        )}
                        {feeNoShow > 0 && (
                          <tr>
                            <td>
                              <small>No Show Fee</small>
                            </td>
                            <td className="text-end">
                              <small>
                                {feeNoShow.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })}
                              </small>
                            </td>
                          </tr>
                        )}
                        {feeResit > 0 && (
                          <tr>
                            <td>
                              <small>Resit Fee</small>
                            </td>
                            <td className="text-end">
                              <small>
                                {feeResit.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })}
                              </small>
                            </td>
                          </tr>
                        )}
                        {settings.chargeStateSalesTax && (
                          <tr>
                            <td>
                              <small>Tax</small>
                            </td>
                            <td className="text-end">
                              <small>
                                {session.salesTax.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })}
                              </small>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className="table-success">
                            <small>Total</small>
                          </td>
                          <td className="text-end">
                            <small>
                              {sessionTotal.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </small>
                          </td>
                        </tr>

                        <tr>
                          <td className="table-success">
                            <small>
                              Paid{' '}
                              {contact && (
                                <>
                                  {session.billSubject ? (
                                    <>by Subject</>
                                  ) : (
                                    <>by Organization</>
                                  )}
                                </>
                              )}
                            </small>
                          </td>
                          <td className="text-end">
                            <small>
                              {session.paid.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </small>
                          </td>
                        </tr>
                        {refund && (
                          <tr>
                            <td className="table-success">
                              <small>Refunded</small>
                            </td>
                            <td className="text-end">
                              <small>{refund}</small>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </>
            )}
            {(session.job.subjectNotes || session.notes) && (
              <Row>
                <Col>
                  <h4>Notes</h4>
                  {session.job.subjectNotes && <>{session.job.subjectNotes}</>}
                  {session.notes && <>{session.notes}</>}
                </Col>
              </Row>
            )}
            {session.sharedBoxFolderId && boxEnabled && (
              <>
                <Row className="mb-2 mt-4">
                  <Col className="d-flex align-items-center">
                    <button
                      type="button"
                      onClick={() => setDisplayFiles(!displayFiles)}
                      className="px-0 btn-link mr-1"
                    >
                      <>
                        {displayFiles ? (
                          <>
                            <CaretDown size={18} />
                          </>
                        ) : (
                          <>
                            <CaretRight size={18} />
                          </>
                        )}
                      </>
                    </button>
                    <h5 className="mb-0">Files</h5>
                  </Col>
                </Row>
                {displayFiles && (
                  <BoxFolder rootBoxFolderId={session.sharedBoxFolderId} />
                )}
              </>
            )}
            {!boxEnabled &&
              session?.sharedFolder?.id &&
              session.sharedCanSeeFiles && (
                <>
                  <Row className="mb-2 mt-4">
                    <Col className="d-flex align-items-center">
                      <button
                        type="button"
                        onClick={() => setDisplayFiles(!displayFiles)}
                        className="px-0 btn-link mr-1"
                      >
                        <>
                          {displayFiles ? (
                            <>
                              <CaretDown size={18} />
                            </>
                          ) : (
                            <>
                              <CaretRight size={18} />
                            </>
                          )}
                        </>
                      </button>
                      <h5 className="mb-0">Files</h5>
                    </Col>
                  </Row>
                  {displayFiles && (
                    <div className="mt-3">
                      <FilesTable
                        sessionId={session.id}
                        tableHeight={300}
                        sharedCanCreateFiles={session.sharedCanCreateFiles}
                        sharedCanCreateFolders={session.sharedCanCreateFolders}
                        folderId={session.sharedFolder.id}
                      />
                    </div>
                  )}
                </>
              )}
            {iqEnabled && session.imagequixSubject?.imagequixId && (
              <>
                <Row className="mt-2">
                  <Col md={3}>
                    <Button
                      block
                      variant="outline-primary"
                      onClick={() => {
                        window.open(
                          `https://vando.imagequix.com/s${session.imagequixSubject?.imagequixId}`,
                          '_blank'
                        )
                      }}
                    >
                      <Camera className="mr-2" />
                      Gallery
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default SessionDetailModal
