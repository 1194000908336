import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Row, Col } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { PlusCircle } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import Loading from '../common/Loading'

const SubjectGroupSubjectImaqeQuixId = (props) => {
  const { sgsId, imagequixId, toggleModal, showModal, refetch } = props
  const [loading, setLoading] = useState(false)
  const [imagequixId_, setImageQuixId_] = useState()
  const [update] = useMutation(
    gql`
      mutation UpdateSubjectGroupSubject(
        $updateSubjectGroupSubjectInput: UpdateSubjectGroupSubjectInput!
      ) {
        updateSubjectGroupSubject(input: $updateSubjectGroupSubjectInput) {
          subjectGroupSubject {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setLoading(false)
        toast.success('Blueprint Online Code Saved')
        toggleModal()
      },
      errorPolicy: 'all',
      refetchQueries: [refetch],
    }
  )
  useEffect(() => {
    if (showModal) {
      setImageQuixId_(imagequixId ? imagequixId : '')
    }
  }, [showModal])
  const handleSubmit = () => {
    setLoading(true)
    update({
      variables: {
        updateSubjectGroupSubjectInput: {
          subjectGroupSubjectInput: {
            id: sgsId,
            imagequixId: imagequixId_,
          },
        },
      },
    })
  }
  if (showModal) {
    return (
      <>
        <div className="editSessionNoShowPolicyModal">
          <Modal
            id="editSessionNoShowPolicyModal"
            size="md"
            show={showModal}
            onHide={toggleModal}
            aria-labelledby="new-title"
            className="invmodal detail"
          >
            <Form>
              <Modal.Header>
                <Modal.Title>
                  <h5>
                    <img
                      className="img-fluid mr-2"
                      src={window.location.origin + '/imagequix.png'}
                      style={{ height: '25px' }}
                      alt="Organization logo"
                    />
                    <span className="ml-2">Blueprint Online Code</span>
                  </h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="imagequixId"
                        value={imagequixId_}
                        onChange={(e) => setImageQuixId_(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      disabled={loading}
                      variant="outline-primary"
                      className="mt-2"
                      onClick={handleSubmit}
                    >
                      <PlusCircle className="mr-2" />
                      Save
                    </Button>
                    {loading && <Loading />}
                  </Col>
                </Row>
              </Modal.Body>
            </Form>
          </Modal>
        </div>
      </>
    )
  }
  return <></>
}

export default SubjectGroupSubjectImaqeQuixId
