import React from 'react'
import Chart from 'react-apexcharts'

const LineChart = (props) => {
  const { series, categories, yAxisFormatter } = props
  const maxDataValue = series.reduce(
    (max, serie) => Math.max(max, ...serie.data),
    0
  )
  const yaxisMax = Math.ceil(maxDataValue + maxDataValue * 0.25)
  let tickAmount = 1
  if (0 < yaxisMax && yaxisMax < 3) {
    tickAmount = 2
  } else {
    tickAmount = 8
  }
  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={{
              chart: {
                id: 'basic-bar',
              },
              xaxis: {
                categories: categories,
              },
              yaxis: {
                max: yaxisMax,
                min: 0,
                tickAmount: tickAmount,
                labels: {
                  formatter: (value) => {
                    return yAxisFormatter
                      ? yAxisFormatter(parseFloat(value).toFixed(2))
                      : parseFloat(value).toFixed(2)
                  },
                },
              },
            }}
            series={series}
            type="line"
            width="1000"
          />
        </div>
      </div>
    </div>
  )
}

export default LineChart
