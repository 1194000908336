import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import SelectSessionPackage from './SelectSessionPackage'
import CreateSessionSelectSession from './CreateSessionSelectSession'
import SessionConfirmation from './SessionConfirmation'

const CreateSessionModal = (props) => {
  const {
    subjectId,
    subjectGroupName,
    subjectGroupId,
    latestSessionId,
    showModal,
    toggleModal,
    adminViewing,
    stripeCard,
    reschedulingNoShow,
    setReschedulingNoShow,
    setHideParentCardElement,
  } = props
  const [bookingStage, setBookingStage] = useState('sessionPackage')
  const [sessionPackage, setSessionPackage] = useState()
  const [jobId, setJobId] = useState()
  const [isUpsellSelected, setIsUpsellSelected] = useState(false)
  const [sessionStartDateTime, setSessionStartDateTime] = useState()

  useEffect(() => {
    if (showModal === false) {
      if (setHideParentCardElement) {
        setHideParentCardElement(false)
      }
      setSessionPackage()
      setJobId()
      setSessionStartDateTime()
      setBookingStage('sessionPackage')
    } else {
      if (setHideParentCardElement) {
        setHideParentCardElement(true)
      }
    }
  }, [showModal])
  let previousSessionRescheduled = false
  if (latestSessionId) {
    previousSessionRescheduled = true
  }

  useEffect(() => {
    if (props && props.setBookingStage) {
      setBookingStage(props.setBookingStage)
    }
  }, [props])

  const innerToggle = () => {
    toggleModal()
    setReschedulingNoShow(false)
  }

  if (showModal) {
    return (
      <>
        <div className="bookSessionModal">
          <Modal
            size="lg"
            show={showModal}
            onHide={innerToggle}
            aria-labelledby="new-title"
          >
            <Modal.Header closeButton>
              <Modal.Title>Book {subjectGroupName} Session</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {bookingStage === 'sessionPackage' ? (
                <SelectSessionPackage
                  subjectGroupId={subjectGroupId}
                  setIsUpsellSelected={setIsUpsellSelected}
                  isUpsellSelected={isUpsellSelected}
                  setSessionPackage={setSessionPackage}
                  sessionPackage={sessionPackage}
                  setBookingStage={setBookingStage}
                  subjectId={subjectId}
                  rescheduleSessionId={latestSessionId}
                  reschedulingNoShow={reschedulingNoShow}
                />
              ) : null}
              {bookingStage === 'session' ? (
                <CreateSessionSelectSession
                  showModal={showModal}
                  subjectGroupId={subjectGroupId}
                  subjectId={subjectId}
                  setSessionPackage={setSessionPackage}
                  previousSessionId={latestSessionId}
                  previousSessionRescheduled={previousSessionRescheduled}
                  sessionPackage={sessionPackage}
                  setBookingStage={setBookingStage}
                  setJobId={setJobId}
                  setSessionStartDateTime={setSessionStartDateTime}
                  toggleModal={toggleModal}
                  rescheduleSessionId={latestSessionId}
                  reschedulingNoShow={reschedulingNoShow}
                />
              ) : null}
              {bookingStage === 'confirmation' ? (
                <SessionConfirmation
                  showModal={showModal}
                  jobId={jobId}
                  isUpsellSelected={isUpsellSelected}
                  reschedulingNoShow={reschedulingNoShow}
                  previousSessionId={latestSessionId}
                  sessionStartDateTime={sessionStartDateTime}
                  sessionPackageId={sessionPackage.id}
                  setSessionStartDateTime={setSessionStartDateTime}
                  setSessionPackage={setSessionPackage}
                  setBookingStage={setBookingStage}
                  setJobId={setJobId}
                  subjectGroupId={subjectGroupId}
                  subjectId={subjectId}
                  toggleModal={toggleModal}
                  adminViewing={adminViewing}
                  stripeCard={stripeCard}
                />
              ) : null}
            </Modal.Body>
          </Modal>
        </div>
      </>
    )
  }
  return <></>
}

export default CreateSessionModal
