import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Loading from '../../components/common/Loading'
import { loggedInUserVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'

const BookInquiry = (props) => {
  const { showBookInquiry, handleBookInquiryChange } = props
  let loggedInUser = useReactiveVar(loggedInUserVar)

  const [emailSent, setEmailSent] = useState(false)

  const [bookingRequestMutation, { loading }] = useMutation(
    gql`
      mutation BookingRequestMutation(
        $bookingRequestInput: BookingRequestInput!
      ) {
        bookingRequest(input: $bookingRequestInput) {
          received
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted: (data) => {
        setEmailSent(true)
      },
    }
  )

  return (
    <Modal show={showBookInquiry} onHide={handleBookInquiryChange}>
      <Modal.Header closeButton>
        <Modal.Title>Booking Inquiry</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {emailSent ? (
          <>
            <p className="text-success mb-0">Booking Inquiry Sent!</p>
            <small>
              We will get back to you soon. If you need immediate assistant,
              call the studio directly.
            </small>
          </>
        ) : (
          <>
            {loading ? (
              <Loading />
            ) : (
              <Formik
                initialValues={{
                  bookingDetails: '',
                  email: '',
                  firstName: '',
                  lastName: '',
                }}
                validationSchema={Yup.object({
                  bookingDetails: Yup.string().required('required'),
                  email: Yup.string().nullable(),
                  firstName: Yup.string().nullable(),
                  lastName: Yup.string().nullable(),
                })}
                onSubmit={(values) => {
                  bookingRequestMutation({
                    variables: {
                      bookingRequestInput: {
                        bookingDetails: values.bookingDetails,
                        email: values.email,
                        firstName: values.firstName,
                        lastName: values.lastName,
                      },
                    },
                  })
                }}
              >
                <Form>
                  <label htmlFor="bookingDetails" className="mb-0">
                    What Are You Trying To Book?
                  </label>
                  <small className="d-block">
                    Provide details about what you are trying to book as well as
                    any other information you think we should know
                  </small>

                  <Field
                    as="textarea"
                    name="bookingDetails"
                    className="form-control mt-3"
                  />
                  <div className="text-danger">
                    <ErrorMessage
                      className="text-danger"
                      name="bookingDetails"
                    />
                  </div>

                  {!loggedInUser && (
                    <>
                      <Row>
                        <Col>
                          <p className="mt-3">First Name</p>
                          <Field
                            name="firstName"
                            className="form-control mt-3"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="firstName"
                            />
                          </div>
                        </Col>
                        <Col>
                          <p className="mt-3">Last Name</p>
                          <Field
                            name="lastName"
                            className="form-control mt-3"
                          />
                          <div className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="lastName"
                            />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3">Email</p>
                      <Field name="email" className="form-control mt-3" />
                      <div className="text-danger">
                        <ErrorMessage className="text-danger" name="email" />
                      </div>
                    </>
                  )}

                  <Button
                    variant="outline-primary"
                    type="submit"
                    className="mt-3 d-block ml-0"
                  >
                    Submit
                  </Button>
                </Form>
              </Formik>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default BookInquiry
