import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import SelectSession from './SelectSession'
import SessionConfirmation from './SessionConfirmation'

const RescheduleSessionModal = (props) => {
  const {
    subjectId,
    rescheduleSessionId,
    sessionPackageId,
    packageCategoryId,
    subjectGroupId,
    subjectGroupName,
    showModal,
    toggleModal,
    adminViewing,
    organizationId,
    stripeCard,
    setHideParentCardElement,
    setReschedulingNoShow,
    reschedulingNoShow,
  } = props
  const [bookingStage, setBookingStage] = useState('session')
  const [jobId, setJobId] = useState()
  const [sessionStartDateTime, setSessionStartDateTime] = useState()

  useEffect(() => {
    if (showModal === false && jobId && sessionStartDateTime) {
      setJobId()
      setSessionStartDateTime()
      setBookingStage('session')
    }
    if (showModal && setHideParentCardElement) {
      setHideParentCardElement(true)
    }
    if (!showModal && setHideParentCardElement) {
      setHideParentCardElement(false)
    }
  }, [showModal])

  const innerToggle = () => {
    setReschedulingNoShow(false)
    toggleModal()
  }

  if (showModal) {
    return (
      <>
        <div className="rescheduleSessionModal">
          <Modal
            size="lg"
            show={showModal}
            onHide={innerToggle}
            aria-labelledby="new-title"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {subjectGroupName ? (
                  <>Reschedule {subjectGroupName} Session</>
                ) : (
                  <>Reschedule Session</>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {bookingStage === 'session' ? (
                <SelectSession
                  showModal={showModal}
                  subjectGroupId={subjectGroupId}
                  sessionPackageId={sessionPackageId}
                  setJobId={setJobId}
                  subjectId={subjectId}
                  packageCategoryId={packageCategoryId}
                  setSessionStartDateTime={setSessionStartDateTime}
                  setBookingStage={setBookingStage}
                  rescheduleSessionId={rescheduleSessionId}
                  reschedulingNoShow={reschedulingNoShow}
                  reschedule
                />
              ) : null}
              {bookingStage === 'confirmation' ? (
                <SessionConfirmation
                  showModal={showModal}
                  jobId={jobId}
                  reschedulingNoShow={reschedulingNoShow}
                  sessionStartDateTime={sessionStartDateTime}
                  sessionPackageId={sessionPackageId}
                  setSessionStartDateTime={setSessionStartDateTime}
                  setBookingStage={setBookingStage}
                  setJobId={setJobId}
                  stripeCard={stripeCard}
                  subjectGroupId={subjectGroupId}
                  subjectId={subjectId}
                  toggleModal={innerToggle}
                  previousSessionId={rescheduleSessionId}
                  previousSessionRescheduled
                  adminViewing={adminViewing}
                  organizationId={organizationId}
                  packageCategoryId={packageCategoryId}
                />
              ) : null}
            </Modal.Body>
          </Modal>
        </div>
      </>
    )
  }
  return <></>
}

export default RescheduleSessionModal
