import React from 'react'
import { Route } from 'react-router-dom'
import AirstudioLayout from '../components/layout/AirstudioLayout'

const AirstudioRoute = ({ component: Component, ...rest }) => {
  return (
    <AirstudioLayout>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </AirstudioLayout>
  )
}

export default AirstudioRoute
