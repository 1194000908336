import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useBoxJwt } from '../../../libs/box'
import ContentExplorer from 'box-ui-elements/es/elements/content-explorer'
import messages from 'box-ui-elements/i18n/en-US'
import { gql, useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import { CloudArrowDown, CloudArrowUp } from 'react-bootstrap-icons'
import BulkUploadModal from './BoxBulkUploadModal'
import { Oval } from 'react-loader-spinner'
import { useDownloadFile } from '../../../libs/downloadFile'

const BoxFolder = (props) => {
  const { canDelete, canRename, rootBoxFolderId, hideUpload, hideDownload } =
    props
  const [currentBoxFolderId, setCurrentBoxFolderId] = useState(rootBoxFolderId)
  const [downloadingBoxFolderZip, setDownloadingBoxFolderZip] = useState()
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false)
  const [boxJwt, setGetBoxJwt] = useBoxJwt()
  const { downloadAndDeleteFile } = useDownloadFile()

  useEffect(() => {
    if (!boxJwt) {
      setGetBoxJwt(true)
    }
  }, [boxJwt])

  const [boxBulkDownload] = useMutation(
    gql`
      mutation BoxBulkDownload($input: BoxBulkDownloadInput!) {
        boxBulkDownload(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.boxBulkDownload.file.fileName,
          data.boxBulkDownload.file.displayName,
          data.boxBulkDownload.file.id,
          () => {
            toast.success(`Folder Downloaded`)
            setDownloadingBoxFolderZip(false)
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  if (!boxJwt) return <></>
  return (
    <>
      <Row className="mt-4">
        <Col>
          <div className="d-flex align-items-center">
            {!hideUpload && (
              <Button
                variant="outline-primary"
                disabled={showBulkUploadModal}
                onClick={() => setShowBulkUploadModal(true)}
              >
                <CloudArrowUp className="mr-2" />
                Bulk Upload
              </Button>
            )}
            {!downloadingBoxFolderZip && !hideDownload && (
              <Button
                variant="outline-primary"
                className="ml-2"
                disabled={downloadingBoxFolderZip}
                onClick={() => {
                  setDownloadingBoxFolderZip(true)
                  boxBulkDownload({
                    variables: {
                      input: {
                        boxFolderId: currentBoxFolderId,
                      },
                    },
                  })
                }}
              >
                <CloudArrowDown className="mr-2" />
                <>Bulk Download</>
              </Button>
            )}
            {downloadingBoxFolderZip && (
              <div
                className="d-flex flex-column justify-content-center align-items-center ml-2"
                style={{ minWidth: '25px', marginTop: '20px' }}
              >
                <Oval
                  visible={true}
                  height={25}
                  width={25}
                  color="#000000"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                  secondaryColor="#D3D3D3"
                  ariaLabel="oval-loading"
                />
                <p className="mt-2" style={{ fontSize: '0.8rem' }}>
                  Downloading Folder...
                </p>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row className="mt-3 mb-3" style={{ height: '600px', overflow: 'auto' }}>
        <Col md={12}>
          <div className="box-files" />
          {boxJwt && (
            <ContentExplorer
              logoUrl=""
              language="en-US"
              messages={messages}
              onNavigate={(e) => {
                setCurrentBoxFolderId(e.id)
                if (props.setCurrentBoxFolderId) {
                  props.setCurrentBoxFolderId(e.id)
                }
              }}
              canDelete={canDelete == false ? false : true}
              canRename={canRename == false ? false : true}
              rootFolderId={rootBoxFolderId}
              token={boxJwt}
            />
          )}
        </Col>
      </Row>

      <BulkUploadModal
        showModal={showBulkUploadModal}
        boxFolderId={currentBoxFolderId}
        toggleModal={() => {
          setShowBulkUploadModal(false)
        }}
      />
    </>
  )
}

export default BoxFolder
