import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const EmployeeSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    multiple,
    searchDescription,
    setAdditionalFields,
    error,
    query,
    formikMultipleValue,
    variables,
    dropdown,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="employee"
      searchDescription={searchDescription ? searchDescription : 'Employees'}
      nodeNamePlural="employees"
      formikMultipleValue={formikMultipleValue}
      formikValue={formikValue}
      multiple={multiple}
      disabled={disabled}
      error={error}
      setAdditionalFields={setAdditionalFields}
      variables={variables}
      dropdown={dropdown}
      gql={
        query
          ? query
          : gql`
              query Employees(
                $first: Int
                $after: String
                $search: String
                $jobId: String
              ) {
                employees(
                  first: $first
                  after: $after
                  orderBy: "gaia_user__full_name"
                  gaiaUser_FullName_Icontains: $search
                  employeeJobsJob: $jobId
                ) {
                  nodeCount
                  pageInfo {
                    hasNextPage
                    endCursor
                  }
                  edges {
                    node {
                      id
                      gaiaUser {
                        fullName
                      }
                    }
                  }
                }
              }
            `
      }
      formatDescription={(node) => {
        return node.gaiaUser.fullName
      }}
    />
  )
}

export default EmployeeSearchInput
