import React, { useState, useEffect, useRef } from 'react'
import LocationModal from './LocationModal'
import { gql, useLazyQuery } from '@apollo/client'
import { useGoogleMaps } from '../../libs/googleMaps'

function Map(props) {
  const { locations, location, organizationId, subjectId } = props
  const { googleMaps } = useGoogleMaps()
  const mapRef = useRef()
  const mapInstanceRef = useRef()
  const [editLocation, setEditLocation] = useState()
  const [showForm, setShowForm] = useState(false)

  const [getLocations, { data: locationData }] = useLazyQuery(
    gql`
      query DefaultLocation {
        locations(mapDefault: true) {
          nodeCount
          edges {
            node {
              id
              latitude
              longitude
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const toggleModal = (locationDetail = null) => {
    if (locationDetail) {
      setEditLocation(locationDetail)
    } else {
      setEditLocation()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  useEffect(() => {
    if (locations) {
      getLocations()
    }
  }, [locations])

  useEffect(() => {
    if (googleMaps && location && location.latitude && location.longitude) {
      const latLong = {
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      }
      const mapOptions = {
        center: latLong,
        zoom: 10,
      }
      mapInstanceRef.current = new googleMaps.Map(mapRef.current, mapOptions)
      new googleMaps.Marker({
        position: latLong,
        map: mapInstanceRef.current,
      })
    }
  }, [googleMaps, location])

  useEffect(() => {
    if (locationData && googleMaps && locations) {
      const mapOptions = {
        center: { lat: 41.739685, lng: -87.55442 },
        zoom: 10,
      }
      if (locationData && locationData.locations.edges.length > 0) {
        const defaultLocation = locationData.locations.edges[0].node
        if (defaultLocation.latitude && defaultLocation.longitude) {
          mapOptions.center.lat = parseFloat(defaultLocation.latitude)
          mapOptions.center.lng = parseFloat(defaultLocation.longitude)
        }
      }
      mapInstanceRef.current = new googleMaps.Map(mapRef.current, mapOptions)
      locations.forEach((location) => {
        if (location.node.latitude && location.node.longitude) {
          const marker = new googleMaps.Marker({
            position: {
              lat: parseFloat(location.node.latitude),
              lng: parseFloat(location.node.longitude),
            },
            map: mapInstanceRef.current,
          })
          marker.addListener('click', () => {
            toggleModal(location.node)
          })
        }
      })
    }
  }, [googleMaps, locations, locationData])

  return (
    <>
      <div ref={mapRef} style={{ height: '100vh', width: '100%' }} />
      {showForm && (
        <LocationModal
          showModal={showForm}
          location={editLocation}
          toggleModal={toggleModal}
          organizationId={organizationId}
          subjectId={subjectId}
        />
      )}
    </>
  )
}

export default Map
