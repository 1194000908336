import React, { useEffect, useRef } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import isEqual from 'lodash/isEqual'
import { Briefcase, PlusCircle } from 'react-bootstrap-icons'
import JobEquipmentBagTypeCard from './JobEquipmentBagTypeCard'

const JobEquipmentBagTypes = (props) => {
  const { formik } = props
  const prevJobEquipmentBagTypes = useRef()

  useEffect(() => {
    const bagTypeQuantities = formik.values.employeeJobs.reduce(
      (acc, currentJob) => {
        currentJob.equipmentBags.forEach((bag) => {
          if (bag?.equipmentBagType) {
            const { id } = bag.equipmentBagType
            if (!acc[id]) {
              acc[id] = 0
            }
            acc[id] += 1
          }
        })
        return acc
      },
      {}
    )

    const updatedJobEquipmentBagTypes = formik.values.jobEquipmentBagTypes.map(
      (bagType) => {
        const quantity = bagTypeQuantities[bagType.equipmentBagTypeId]
        return {
          ...bagType,
          includedQuantity: quantity !== undefined ? quantity : 0,
        }
      }
    )
    if (
      !isEqual(prevJobEquipmentBagTypes.current, updatedJobEquipmentBagTypes)
    ) {
      formik.setFieldValue('jobEquipmentBagTypes', updatedJobEquipmentBagTypes)
      prevJobEquipmentBagTypes.current = updatedJobEquipmentBagTypes
    }
  }, [formik?.values?.employeeJobs, formik.values.jobEquipmentBagTypes])

  return (
    <>
      {formik.values.jobEquipmentBagTypes.length > 0 && (
        <Form.Row className="mt-2" style={{ marginBottom: '-10px' }}>
          <Form.Group as={Col} md={8}>
            <h5>Required Bag Types</h5>
          </Form.Group>
        </Form.Row>
      )}
      <Row>
        <Col md={7} sm={12}>
          <div
            className={'px-3'}
            style={{
              maxHeight: '600px',
              overflowY: 'auto',
            }}
          >
            {formik.values.jobEquipmentBagTypes.map((_, index) => (
              <JobEquipmentBagTypeCard
                key={index}
                formik={formik}
                index={index}
              />
            ))}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            className={
              formik.values.jobEquipmentBagTypes.length > 0
                ? ' mt-3 ps-0'
                : 'ps-0 mt-1'
            }
            variant="link"
            size="sm"
            onClick={() => {
              formik.setFieldValue('jobEquipmentBagTypes', [
                ...formik.values.jobEquipmentBagTypes,
                {
                  requiredQuantity: 1,
                  includedQuantity: 0,
                  equipmentBagTypeId: null,
                  equipmentBagTypeName: null,
                },
              ])
            }}
          >
            <PlusCircle className="mr-1" /> Add Required Bag Type
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default JobEquipmentBagTypes
