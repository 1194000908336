import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col, Form, ButtonGroup } from 'react-bootstrap'
import { FixedSizeList } from 'react-window'
import Loading from '../common/Loading'
import './SendNotificationUsersTable.css'
import ProgressBar from 'react-bootstrap/ProgressBar'

const SendNotificationUsersTable = (props) => {
  const {
    selectedNotification,
    organizationId,
    subjectGroupId,
    jobId,
    notificationRecipientUsers,
    setNotificationRecipientUsers,
    showModal,
    setSubmitDisabled,
  } = props
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState(true)
  const [users, setUsers] = useState([])
  const lastQueryVariables = useRef({})
  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query NotificationGaisUsers(
        $cursor: String
        $subjectGroup: String
        $subjectGroupNotBooked: Boolean
        $subjectGroupBooked: Boolean
        $subjectGroupPhotographed: Boolean
        $jobSessions: Boolean
        $jobContacts: Boolean
        $jobEmployees: Boolean
        $organization: String
        $job: String
      ) {
        gaiaUsers(
          first: 250
          after: $cursor
          jobContacts: $jobContacts
          jobSessions: $jobSessions
          jobEmployees: $jobEmployees
          subjectGroup: $subjectGroup
          subjectGroupNotBooked: $subjectGroupNotBooked
          subjectGroupBooked: $subjectGroupBooked
          subjectGroupPhotographed: $subjectGroupPhotographed
          organization: $organization
          job: $job
          orderBy: "full_name"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              fullName
              email
              dummyUsername
              secondaryEmail
              phoneNumber
              secondaryPhoneNumber
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const queryVariables = (cursor = null) => {
    const variables = {
      cursor: cursor,
    }
    if (organizationId && !subjectGroupId) {
      variables.organization = organizationId
    }
    if (jobId) {
      variables.job = jobId
      if (selectedNotification.includes('Sessions')) {
        variables.jobSessions = true
      } else if (selectedNotification.includes('Contacts')) {
        variables.jobContacts = true
      } else if (selectedNotification.includes('Employees')) {
        variables.jobEmployees = true
      }
    }
    if (subjectGroupId) {
      variables.subjectGroup = subjectGroupId
      variables.organization = null
      if (selectedNotification.includes('Not Booked')) {
        variables.subjectGroupNotBooked = true
      } else if (selectedNotification.includes('Booked')) {
        variables.subjectGroupBooked = true
      } else if (selectedNotification.includes('Photographed')) {
        variables.subjectGroupPhotographed = true
      }
    }
    return variables
  }

  useEffect(() => {
    if (selectedNotification) {
      const variables = queryVariables()
      if (
        JSON.stringify(variables) !== JSON.stringify(lastQueryVariables.current)
      ) {
        lastQueryVariables.current = variables
        setLoading(true)
        setUsers([])
        setSubmitDisabled(true)
        setNotificationRecipientUsers([])
        query({ variables })
      }
    }
  }, [selectedNotification])

  useEffect(() => {
    if (!showModal) {
      setNotificationRecipientUsers([])
    }
  }, [showModal])

  useEffect(() => {
    if (queryData) {
      if (queryData.gaiaUsers.edges.length > 0) {
        setUsers((prevState) => [...prevState, ...queryData.gaiaUsers.edges])
        setNotificationRecipientUsers((prevIds) => [
          ...new Set([
            ...prevIds,
            ...queryData.gaiaUsers.edges.map((edge) => edge.node.id),
          ]),
        ])
      }
      if (queryData.gaiaUsers.pageInfo.hasNextPage) {
        fetchMoreUsers(queryData.gaiaUsers.pageInfo.endCursor)
      } else {
        setSubmitDisabled(false)
        setLoading(false)
      }
    }
  }, [queryData])

  const fetchMoreUsers = useCallback(
    (cursor) => {
      queryFetchMore({
        variables: queryVariables(cursor),
      })
    },
    [queryFetchMore]
  )

  const handleNodeCheck = (e, row) => {
    if (e.target.checked) {
      setNotificationRecipientUsers((prevState) => [...prevState, row.node.id])
    } else {
      setNotificationRecipientUsers((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  if (queryError) return <>Error loading clients</>

  const getEmail = (user) => {
    let email = ''
    if (!user.node.dummyUsername) {
      email = user.node.email
    }
    if (user.node.secondaryEmail) {
      if (email) {
        email = `${email}, ${user.node.secondaryEmail}`
      } else {
        email = user.node.secondaryEmail
      }
    }
    return email
  }

  const getPhone = (user) => {
    let phone = ''
    if (user.node.phoneNumber) {
      phone = user.node.phoneNumber
    }
    if (user.node.secondaryPhoneNumber) {
      if (phone) {
        phone = `${phone}, ${user.node.secondaryPhoneNumber}`
      } else {
        phone = user.node.secondaryPhoneNumber
      }
    }
    return phone
  }

  const RowRenderer = ({ index, style }) => {
    const user = users[index]

    return (
      <div style={style} key={user.node.id} className="table-row">
        <div className="table-cell">{user.node.fullName}</div>
        <div className="table-cell">{getEmail(user)}</div>
        <div className="table-cell">{getPhone(user)}</div>
        <div className="table-cell">
          <Form.Check
            type="checkbox"
            checked={notificationRecipientUsers.includes(user.node.id)}
            onChange={(e) => handleNodeCheck(e, user)}
          />
        </div>
      </div>
    )
  }

  return (
    <div id="send-notification-users-table">
      {loading && (
        <>
          <Loading />
          <Row className="justify-content-md-center">
            <Col xs={12} className="text-center d-flex justify-content-center">
              <p>Loading Notification Recipients...</p>
            </Col>
          </Row>
          {queryData?.gaiaUsers.nodeCount > 250 && (
            <Row className="mt-3">
              <Col>
                <ProgressBar
                  now={`${
                    (notificationRecipientUsers.length /
                      queryData.gaiaUsers.nodeCount) *
                    100
                  }`}
                  label={`${Math.round(
                    100 *
                      (notificationRecipientUsers.length /
                        queryData.gaiaUsers.nodeCount)
                  )}% Complete`}
                />
              </Col>
            </Row>
          )}
        </>
      )}
      {!loading && (
        <Row className="mt-4">
          <Col>
            <Form.Label style={{ fontSize: '15px' }}>
              Select Notification Recipients
            </Form.Label>
            {selectedNotification.includes('JobContacts') && (
              <>
                <br />
                <small>(Ad hoc contacts will be notified)</small>
              </>
            )}
          </Col>
        </Row>
      )}

      <Row className="mt-2 mb-3">
        <Col md="12">
          <div className="table-header">
            <div className="table-cell">Name</div>
            <div className="table-cell">Email</div>
            <div className="table-cell">Phone</div>
            <div className="table-cell">
              <Form.Group as={ButtonGroup} className="align-items-center">
                <Form.Check
                  className="ml-2 mt-2"
                  type="checkbox"
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked)
                    if (e.target.checked) {
                      const appendIds = []
                      queryData?.gaiaUsers?.edges.forEach((edge) => {
                        if (
                          !notificationRecipientUsers.includes(edge.node.id)
                        ) {
                          appendIds.push(edge.node.id)
                        }
                      })
                      setNotificationRecipientUsers((prevState) => {
                        return [...prevState, ...appendIds]
                      })
                    } else {
                      setNotificationRecipientUsers([])
                    }
                  }}
                />
                {notificationRecipientUsers.length > 0 && (
                  <span style={{ fontSize: '14px', marginTop: '5px' }}>
                    ({notificationRecipientUsers.length})
                  </span>
                )}
              </Form.Group>
            </div>
          </div>
          <FixedSizeList
            height={users.length < 10 ? 250 : users.length < 15 ? 350 : 500}
            itemCount={users.length}
            itemSize={35}
            width="100%"
          >
            {RowRenderer}
          </FixedSizeList>
        </Col>
      </Row>
    </div>
  )
}

export default SendNotificationUsersTable
