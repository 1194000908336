import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col, Button } from 'react-bootstrap'
import SortableInfiniteTable from '../../../common/SortableInfiniteTable'
import { Plug, CaretDown, CaretRight } from 'react-bootstrap-icons'
import { DateTime } from 'luxon'
import Loading from '../../../common/Loading'
import APIKeyForm from './APIKeyForm'
import { settingsVar } from '../../../../libs/apollo'
import { useReactiveVar } from '@apollo/client'

const APIKeys = () => {
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [editAPIKey, setEditAPIKey] = useState()
  const [showUsage, setShowUsage] = useState(true)
  const [view, setView] = useState('list')
  const settings = useReactiveVar(settingsVar)
  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query APIKeys($cursor: String) {
        apiKeys(first: 250, after: $cursor, orderBy: "name") {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              name
              apiKey
              enabled
              lastUsed
              created
              apiCallCount
              contentType {
                model
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      // pollInterval: 30000,
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query()
    }
  }, [initialQueryRun, setInitialQueryRun])

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.apiKeys?.pageInfo?.endCursor,
    }
    queryFetchMore({
      variables,
    })
  }

  const onRowClick = (row) => {
    setView('form')
    setEditAPIKey(row.original.node)
  }

  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading API Keys..." />
        </Col>
      </Row>
    )
  if (queryError) return <>Error loading</>
  return (
    <>
      {view === 'list' && (
        <>
          <Row className="mb-1">
            <Col className="d-flex align-items-center">
              <button
                type="button"
                onClick={() => setShowUsage(!showUsage)}
                className="px-0 btn-link mr-1"
              >
                <>
                  {showUsage ? (
                    <>
                      <CaretDown size={15} />
                    </>
                  ) : (
                    <>
                      <CaretRight size={15} />
                    </>
                  )}
                </>
              </button>
              API Details
            </Col>
          </Row>
          {showUsage && (
            <>
              <Row>
                <Col>
                  <p>
                    To interact with the Airstudio API, ensure your API calls
                    are accompanied by the correct HTTP headers to authenticate
                    your requests. Each request must include the following
                    headers: <code>Content-Type: application/json</code> to
                    specify the content type of your request,{' '}
                    <code>X-Airstudio-Tenant: {settings.tenantUuid}</code> to
                    identify your Airstudio account, and{' '}
                    <code>X-Airstudio-API-Key: API Key</code> to authenticate
                    your request. Send your API calls to{' '}
                    <code>{settings.apiDomain}</code>. For further guidance and
                    detailed information on utilizing the Airstudio API, refer
                    to the{' '}
                    <a
                      href="https://developer.airstudio.io"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      API Documenation
                    </a>
                    .
                  </p>
                  <p>
                    Since the Airstudio API leverages the GraphQL Relay
                    specification for optimizing data fetching, you may find it
                    beneficial to familiarize yourself with Relay concepts and
                    practices. For comprehensive understanding and best
                    practices on GraphQL Relay, refer to the official{' '}
                    <a
                      href="https://relay.dev/docs/tutorial/graphql/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Relay Documentation
                    </a>
                    . This resource provides in-depth knowledge on how to
                    efficiently structure your queries and mutations, utilize
                    connections, edges, and nodes, and leverage Relay powerful
                    client-side data management features.
                  </p>
                  <p>
                    To help you get started, below is an example of how to make
                    a request to the Airstudio API using a <code>curl</code>{' '}
                    command. This example includes setting the necessary headers
                    and a basic GraphQL query
                  </p>
                  <pre>
                    <code>
                      {`curl -X POST 
-H "Content-Type: application/json" 
-H "X-Airstudio-API-Key: API Key" 
-H "X-Airstudio-Tenant: ${settings.tenantUuid}" 
-d '{
  "query": "query SettingsQuery 
    { settings 
      { edges 
        { node 
          { id } 
        } 
      } 
    }"
  }' 
${settings.apiDomain}`}
                    </code>
                  </pre>
                  <p>
                    Upon successful execution of the above command, the API
                    responds with the following JSON data
                  </p>
                  <pre>
                    <code>
                      {`{
  "data": {
    "settings": {
      "edges": [
        {
          "node": {
            "id": "U2V0dGluZ3NOb2RlOjE="
          }
        }
      ]
    }
  }
}`}
                    </code>
                  </pre>
                  <p>
                    This response indicates that the query was successfully
                    processed, returning the ID of a settings node. Remember,
                    this is just a simple example to illustrate the request and
                    response format. Your actual queries and responses will vary
                    based on the specific data and operations your application
                    requires.
                  </p>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col>
              <div>
                <Button
                  variant="link"
                  onClick={() => {
                    setView('form')
                  }}
                >
                  <Plug className="mr-2" />
                  New API Key
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <SortableInfiniteTable
                tableData={
                  queryData?.apiKeys?.edges ? queryData?.apiKeys?.edges : []
                }
                loadingMessage="Loading API Keys..."
                tableColumns={[
                  {
                    Header: 'Name',
                    id: 'name',
                    accessor: (row) => {
                      return row.node.name
                    },
                  },
                  {
                    Header: 'Enabled',
                    id: 'enabled',
                    accessor: (row) => {
                      return row.node.enabled ? 'Yes' : 'No'
                    },
                  },
                  {
                    Header: 'Created',
                    id: 'created',
                    accessor: (row) => {
                      return DateTime.fromISO(row.node.created).toFormat(
                        'MMMM dd yyyy hh:mma'
                      )
                    },
                  },
                  {
                    Header: 'Used',
                    id: 'user',
                    accessor: (row) => {
                      return `${row.node.apiCallCount} Times`
                    },
                  },
                  {
                    Header: 'Last Used',
                    id: 'lastUsed',
                    accessor: (row) => {
                      if (row.node.lastUsed) {
                        return DateTime.fromISO(row.node.lastUsed).toFormat(
                          'MMMM dd yyyy hh:mma'
                        )
                      } else {
                        return <>Never</>
                      }
                    },
                  },
                ]}
                fetchMoreTableData={fetchMore}
                hasMoreTableData={queryData?.apiKeys?.pageInfo?.hasNextPage}
                onRowClick={(row) => onRowClick(row)}
                tableHeight={200}
                rowPointer
              />
            </Col>
          </Row>
        </>
      )}
      {view === 'form' && (
        <>
          <APIKeyForm
            apiKey={editAPIKey}
            setView={setView}
            setEditAPIKey={setEditAPIKey}
          />
        </>
      )}
    </>
  )
}
export default APIKeys
