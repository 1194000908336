import React, { useEffect } from 'react'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useMutation, gql } from '@apollo/client'
import { deserializeGraphQLErrors } from '../../../libs/apollo'
import toast from 'react-hot-toast'
import { PlusCircle, Trash } from 'react-bootstrap-icons'

const EditSessionResitReasonModal = (props) => {
  const { resitReason, setResitReason, showModal, toggleModal } = props
  const EditSessionResitReasonSchema = Yup.object().shape({
    reason: Yup.string()
      .min(2, 'Reason is too short')
      .max(100, 'Reason is too long')
      .required('Reason is required'),
    bill: Yup.bool().required('Required'),
  })
  const [updateResitReasonMutation] = useMutation(
    gql`
      mutation UpdateResitReason(
        $updateResitReasonInput: UpdateResitReasonInput!
      ) {
        updateResitReason(input: $updateResitReasonInput) {
          resitReason {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        hideModal()
        toast.success('Resit Reason Updated')
      },
      onError: (error) => {
        if (error.graphQLErrors) {
          const graphQLErrors = deserializeGraphQLErrors(error.graphQLErrors)
          if (graphQLErrors.reason) {
            formik.setErrors({
              reason: graphQLErrors.reason.detail,
            })
          }
        }
      },
      refetchQueries: ['ResitReasonsQuery'],
    }
  )
  const [deleteResitReason, ,] = useMutation(
    gql`
      mutation DeleteResitReasonMutations(
        $deleteResitReasonInput: DeleteResitReasonInput!
      ) {
        deleteResitReason(input: $deleteResitReasonInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        hideModal()
        toast.success('Resit Reason Deleted')
      },
      errorPolicy: 'all',
      refetchQueries: ['ResitReasonsQuery'],
    }
  )
  const formik = useFormik({
    initialValues: {
      reason: '',
      bill: '',
    },
    validationSchema: EditSessionResitReasonSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      updateResitReasonMutation({
        variables: {
          updateResitReasonInput: {
            resitReasonInput: {
              id: resitReason.id,
              reason: values.reason,
              bill: values.bill,
            },
          },
        },
      })
    },
  })
  useEffect(() => {
    if (showModal && resitReason) {
      formik.setValues({
        reason: resitReason.reason,
        bill: resitReason.bill,
      })
    }
  }, [showModal, resitReason])
  const hideModal = () => {
    toggleModal()
    setResitReason(null)
  }
  const handleDelete = () => {
    deleteResitReason({
      variables: {
        deleteResitReasonInput: {
          resitReasonIds: resitReason.id,
        },
      },
    })
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                Edit Session Resit Reason
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Session Resit Reason
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="reason"
                    value={formik.values.reason}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.reason}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.reason}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Row className="mt-2">
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Bill
                  </Form.Label>
                  <Col>
                    <Form.Check
                      inline
                      label=""
                      checked={formik.values.bill}
                      onChange={(e) =>
                        formik.setFieldValue('bill', e.target.checked)
                      }
                    />
                  </Col>
                </Form.Group>
              </Row>
              <Row>
                <Col md={{ span: 10, offset: 1 }}>
                  <Button
                    type="submit"
                    size="sm"
                    variant="outline-primary"
                    block
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    block
                    onClick={handleDelete}
                  >
                    <Trash className="mr-2" />
                    Delete
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default EditSessionResitReasonModal
