import React, { useState } from 'react'
import Bags from '../equipment/Bag/Bags'
import Items from '../equipment/Item/Items'
import { Tab } from 'react-bootstrap'
import Tabs from '../common/Tabs'
import { Briefcase, Camera } from 'react-bootstrap-icons'

const EmployeeDetailEquipment = (props) => {
  const { employeeId, employeeName } = props
  const [activeTab, setActiveTab] = useState('Bags')
  return (
    <>
      <Tabs
        onSelect={(selectedTab) => setActiveTab(selectedTab)}
        activeKey={activeTab}
      >
        <Tab
          eventKey="Bags"
          title={
            <b>
              <Briefcase className="mr-2" />
              Bags
            </b>
          }
        >
          <div className="mt-4">
            <Bags employeeId={employeeId} employeeName={employeeName} />
          </div>
        </Tab>
        <Tab
          eventKey="Items"
          title={
            <b>
              <Camera className="mr-2" />
              Items
            </b>
          }
        >
          <div className="mt-4">
            <Items employeeId={employeeId} employeeName={employeeName} />
          </div>
        </Tab>
      </Tabs>
    </>
  )
}

export default EmployeeDetailEquipment
