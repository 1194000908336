import React, { useState } from 'react'
import { Trash, PersonRolodex, PlusCircle } from 'react-bootstrap-icons'
import { Field, ErrorMessage, FieldArray } from 'formik'
import { Form, Col, Row, Button } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../../libs/apollo'

export default function AdHocContacts(props) {
  const [display, setDisplay] = useState(true)
  const { formik, schedulingAnalystCreating } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate =
    ['Scheduling Manager', 'Administrator', 'Scheduling Analyst'].includes(
      loggedInUser?.permissions?.group
    ) || schedulingAnalystCreating

  return (
    <>
      {formik.values.adHocContacts.length > 0 && (
        <Form.Row className="mt-2" style={{ marginBottom: '-10px' }}>
          <Form.Group as={Col} md={8}>
            <h5>Contacts</h5>
          </Form.Group>
        </Form.Row>
      )}
      <FieldArray
        name="adHocContacts"
        render={(arrayHelpers) => (
          <Row>
            <Col md="7">
              <div
                style={
                  formik.values.adHocContacts.length > 1
                    ? {
                        overflowY: 'scroll',
                        maxHeight: '350px',
                        overflowX: 'hidden',
                      }
                    : null
                }
                className={formik.values.adHocContacts.length > 1 ? 'px-5' : ''}
              >
                {formik.values.adHocContacts.map((adHocContact, index) => (
                  <div key={index}>
                    <Row>
                      <Col>
                        <div className="border border-secondary rounded my-3 p-3 bg-white">
                          <Form.Row>
                            <Form.Group as={Col} md={6}>
                              <Form.Label>First Name</Form.Label>
                              <Field
                                disabled={!canMutate}
                                name={`adHocContacts[${index}].firstName`}
                                className="form-control form-control-sm"
                              />
                              <ErrorMessage
                                name={`adHocContacts[${index}].firstName`}
                              >
                                {(msg) => (
                                  <span className="text-danger">{msg}</span>
                                )}
                              </ErrorMessage>
                            </Form.Group>

                            <Form.Group as={Col} md={6}>
                              <Form.Label>Last Name</Form.Label>
                              <Field
                                disabled={!canMutate}
                                name={`adHocContacts[${index}].lastName`}
                                className="form-control form-control-sm"
                              />
                              <ErrorMessage
                                name={`adHocContacts[${index}].lastName`}
                              >
                                {(msg) => (
                                  <span className="text-danger">{msg}</span>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md={6}>
                              <Form.Label>Primary Email</Form.Label>
                              <Field
                                disabled={!canMutate}
                                name={`adHocContacts[${index}].primaryEmail`}
                                className="form-control form-control-sm"
                              />
                              <ErrorMessage
                                name={`adHocContacts[${index}].primaryEmail`}
                              >
                                {(msg) => (
                                  <span className="text-danger">{msg}</span>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Form.Group as={Col} md={6}>
                              <Form.Label>Secondary Email</Form.Label>
                              <Field
                                disabled={!canMutate}
                                name={`adHocContacts[${index}].secondaryEmail`}
                                className="form-control form-control-sm"
                              />
                              <ErrorMessage
                                name={`adHocContacts[${index}].secondaryEmail`}
                              >
                                {(msg) => (
                                  <span className="text-danger">{msg}</span>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md={6}>
                              <Form.Label>Primary Phone</Form.Label>
                              <PhoneInput
                                inputClass="w-100"
                                country="us"
                                placeholder=""
                                disabled={!canMutate}
                                name="adHocContacts[${index}].primaryPhone"
                                regions={['north-america']}
                                value={
                                  formik.values.adHocContacts[index]
                                    .primaryPhone
                                }
                                onChange={(pValue) =>
                                  formik.setFieldValue(
                                    `adHocContacts[${index}].primaryPhone`,
                                    pValue
                                  )
                                }
                              />
                              <ErrorMessage
                                name={`adHocContacts[${index}].primaryPhone`}
                              >
                                {(msg) => (
                                  <span className="text-danger">{msg}</span>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Form.Group as={Col} md={6}>
                              <Form.Label>Secondary Phone</Form.Label>
                              <PhoneInput
                                inputClass="w-100"
                                country="us"
                                placeholder=""
                                disabled={!canMutate}
                                name="adHocContacts[${index}].secondaryPhone"
                                regions={['north-america']}
                                value={
                                  formik.values.adHocContacts[index]
                                    .secondaryPhone
                                }
                                onChange={(pValue) =>
                                  formik.setFieldValue(
                                    `adHocContacts[${index}].secondaryPhone`,
                                    pValue
                                  )
                                }
                              />
                              <ErrorMessage
                                name={`adHocContacts[${index}].secondaryPhone`}
                              >
                                {(msg) => (
                                  <span className="text-danger">{msg}</span>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md={12}>
                              <Form.Label>Notes</Form.Label>
                              <Field
                                disabled={!canMutate}
                                name={`adHocContacts[${index}].notes`}
                                as="textarea"
                                className="form-control form-control-sm"
                              />
                              <ErrorMessage
                                name={`adHocContacts[${index}].notes`}
                              >
                                {(msg) => (
                                  <span className="text-danger">{msg}</span>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                          </Form.Row>
                          {canMutate && (
                            <div
                              className="d-flex align-items-center hover btn-link"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <Trash className="mr-2" />
                              <small>Remove</small>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>

              {canMutate && (
                <Button
                  variant="link"
                  className={
                    formik.values.adHocContacts.length > 1
                      ? 'mt-2 p-0 btn-link'
                      : 'p-0 btn-link'
                  }
                  onClick={() => {
                    if (!display) {
                      setDisplay(true)
                    }
                    arrayHelpers.push({
                      firstName: '',
                      lastName: '',
                      primaryEmail: '',
                      secondaryEmail: '',
                      primaryPhone: '',
                      secondaryPhone: '',
                    })
                  }}
                >
                  <PlusCircle className="mr-1" /> Add Contact
                </Button>
              )}
            </Col>
          </Row>
        )}
      />
    </>
  )
}
