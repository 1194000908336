import React, { useState } from 'react'
import { gql, useMutation, useLazyQuery, useReactiveVar } from '@apollo/client'
import { settingsVar } from '../../../libs/apollo'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { useFormik } from 'formik'
import DatePicker from 'react-datepicker'
import { DateTime } from 'luxon'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  XCircle,
  CaretDown,
  CaretRight,
  Trash,
  PlusCircle,
} from 'react-bootstrap-icons'
import Loading from '../../common/Loading'
import QrReader from 'react-qr-reader'
import AuditLog from '../../audit_log/AuditLog'

const RepairForm = (props) => {
  const {
    repair,
    afterSubmit,
    prefilledItemDetailsByItemForm,
    prefilledDetail,
  } = props
  const settings = useReactiveVar(settingsVar)
  const [submitting, setSubmitting] = useState(false)
  const [displayItemResults, setDisplayItemResults] = useState(false)
  const [displayAuditLog, setDisplayAuditLog] = useState(false)

  const [deleteRepair] = useMutation(
    gql`
      mutation DeleteEquipmentRepair(
        $deleteRepairInput: DeleteEquipmentRepairInput!
      ) {
        deleteEquipmentRepair(input: $deleteRepairInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Repair Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['EquipmentRepairs'],
    }
  )

  const [searchItems, { data: itemsData, fetchMore: fetchMoreItems }] =
    useLazyQuery(
      gql`
        query EquipmentItems(
          $equipmentItemsAfter: String
          $equipmentItemsFirst: Int
          $nameIcontains: String
        ) {
          equipmentItems(
            after: $equipmentItemsAfter
            first: $equipmentItemsFirst
            name_Icontains: $nameIcontains
            retire: false
          ) {
            edges {
              node {
                id
                name
                serialNumber
                equipmentBag {
                  id
                  name
                }
                equipmentCategory {
                  id
                  name
                }
                equipmentItemType {
                  name
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )

  const [createEquipmentRepair] = useMutation(
    gql`
      mutation CreateEquipmentRepair($input: CreateEquipmentRepairInput!) {
        createEquipmentRepair(input: $input) {
          equipmentRepair {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Repair Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['EquipmentRepairs', 'EquipmentItems'],
    }
  )

  const [updateEquipmentRepair] = useMutation(
    gql`
      mutation UpdateEquipmentRepair($input: UpdateEquipmentRepairInput!) {
        updateEquipmentRepair(input: $input) {
          equipmentRepair {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Repair Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['EquipmentRepairs', 'EquipmentItems'],
    }
  )

  const [searchScanedItem] = useLazyQuery(
    gql`
      query SearchScannedEquipmentItem($equipmentItemId: [ID]) {
        equipmentItems(
          id: $equipmentItemId
          underRepair: false
          retire: false
        ) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted(data) {
        if (data.equipmentItems.edges) {
          const node = data?.equipmentItems.edges[0].node
          if (node.id != formik.values.itemId) {
            formik.setFieldValue('itemId', node.id)
            formik.setFieldValue('itemName', node.name)
            toast.success('Item Scanned')
          }
        }
      },
    }
  )

  const formik = useFormik({
    initialValues: repair
      ? {
          problem: repair.problem,
          notes: repair.notes,
          dateFixed: repair.dateFixed
            ? new Date(DateTime.fromISO(repair.dateFixed).toLocaleString())
            : null,
          itemName: repair.equipmentItem ? repair.equipmentItem.name : '',
          price: repair.price ? repair.price : '',
          invoiceNumber: repair.invoiceNumber ? repair.invoiceNumber : '',
          vendor: repair.vendor ? repair.vendor : '',
          itemId: repair.equipmentItem ? repair.equipmentItem.id : null,
        }
      : prefilledItemDetailsByItemForm
        ? {
            problem: '',
            notes: '',
            dateFixed: '',
            itemName: prefilledDetail ? prefilledDetail.name : '',
            price: '',
            vendor: '',
            invoiceNumber: '',
            itemId: prefilledDetail ? prefilledDetail.id : '',
          }
        : {
            problem: '',
            notes: '',
            dateFixed: null,
            itemName: '',
            price: '',
            vendor: '',
            invoiceNumber: '',
            itemId: '',
          },
    validationSchema: Yup.object().shape({
      problem: Yup.string().required('required'),
      itemName: Yup.string().required('required'),
      itemId: Yup.string().required('required'),
      notes: Yup.string().nullable(),
      vendor: Yup.string().nullable(),
      price: Yup.string().nullable(),
      invoiceNumber: Yup.string().nullable(),
    }),
    validateOnChange: true,
    onSubmit: (values) => {
      setSubmitting(true)
      let dateFixed
      if (values.dateFixed) {
        dateFixed = DateTime.utc(
          values.dateFixed.getFullYear(),
          values.dateFixed.getMonth() + 1,
          values.dateFixed.getDate(),
          0,
          0
        ).toISO()
      }
      if (repair) {
        updateEquipmentRepair({
          variables: {
            input: {
              equipmentRepairInput: {
                id: repair.id,
                problem: values.problem,
                notes: values.notes,
                vendor: values.vendor,
                invoiceNumber: values.invoiceNumber,
                price: Number(values.price),
                dateFixed,
                equipmentItemId: values.itemId,
              },
            },
          },
        })
      } else {
        createEquipmentRepair({
          variables: {
            input: {
              equipmentRepairInput: {
                problem: values.problem,
                notes: values.notes,
                vendor: values.vendor,
                invoiceNumber: values.invoiceNumber,
                price: Number(values.price),
                dateFixed,
                equipmentItemId: values.itemId,
              },
            },
          },
        })
      }
    },
  })

  const handleDelete = () => {
    setSubmitting(true)
    deleteRepair({
      variables: {
        deleteRepairInput: {
          equipmentRepairIds: repair.id,
        },
      },
    })
  }

  const handleItemBlur = () => {
    setDisplayItemResults(false)
    formik.setFieldTouched(`itemId`, true)
  }

  const handleItemChange = (e) => {
    if (!e.target.value) {
      setDisplayItemResults(false)
    } else {
      setDisplayItemResults(true)
    }
    formik.setFieldValue(`itemName`, e.target.value)
    searchItems({
      variables: {
        after: null,
        first: 10,
        nameIcontains: e.target.value,
      },
    })
  }

  const handleItemXClick = () => {
    formik.setFieldValue(`itemName`, '')
    formik.setFieldValue(`itemId`, '')
  }

  const handleItemClick = (node) => {
    setDisplayItemResults(false)
    formik.setFieldValue(`itemName`, node.name)
    formik.setFieldValue(`itemId`, node.id)
  }

  const handleScan = (value) => {
    if (value) {
      searchScanedItem({
        variables: {
          equipmentItemId: value,
        },
      })
    }
  }

  return (
    <>
      <div id="equipmentRepairForm">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Item
              </Form.Label>
            </Col>
            <Col sm="12" md={7}>
              <Form.Control
                placeholder="search"
                value={formik.values.itemName}
                onBlur={handleItemBlur}
                onChange={(e) => handleItemChange(e)}
                isInvalid={formik.errors.itemId}
                readOnly={Boolean(
                  prefilledItemDetailsByItemForm || formik.values.itemId
                )}
                className={
                  formik.values.itemId
                    ? ' border border-success form-control form-control-sm'
                    : 'form-control form-control-sm'
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.itemId}
              </Form.Control.Feedback>
              {itemsData && displayItemResults && (
                <InfiniteScroll
                  height={100}
                  dataLength={itemsData.equipmentItems.edges.length}
                  next={() => {
                    fetchMoreItems({
                      variables: {
                        after: itemsData.equipmentItems.pageInfo.endCursor,
                        first: 10,
                        nameIcontains: formik.values.itemName,
                      },
                    })
                  }}
                  hasMore={itemsData?.equipmentItems.pageInfo.hasNextPage}
                  loader={<Loading />}
                >
                  <Table size="sm" hover>
                    <tbody>
                      {itemsData.equipmentItems.edges.map((item) => {
                        const { node } = item
                        return (
                          <tr
                            onMouseDown={() => handleItemClick(node)}
                            key={node.id}
                            className="hover text-decoration-none"
                          >
                            <td>
                              <small>{node.name}</small>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </InfiniteScroll>
              )}
            </Col>
            <Col>
              {repair || prefilledItemDetailsByItemForm ? (
                <p></p>
              ) : (
                formik.values.itemId && (
                  <button
                    type="button"
                    className="p-0 mr-1 btn-link"
                    onClick={handleItemXClick}
                  >
                    <XCircle />
                  </button>
                )
              )}
            </Col>
          </Form.Group>
          {!prefilledItemDetailsByItemForm && !formik.values.itemId && (
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Item Scan
                </Form.Label>
              </Col>
              <Col md={6}>
                <QrReader
                  delay={300}
                  onScan={handleScan}
                  style={{ width: '100%' }}
                />
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Vendor
              </Form.Label>
            </Col>
            <Col sm="12" md={9}>
              <Form.Control
                as="input"
                name="vendor"
                value={formik.values.vendor}
                onChange={formik.handleChange}
                isInvalid={formik.errors.vendor}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.vendor}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Invoice Number
              </Form.Label>
            </Col>
            <Col sm="12" md={9}>
              <Form.Control
                as="input"
                name="invoiceNumber"
                value={formik.values.invoiceNumber}
                onChange={formik.handleChange}
                isInvalid={formik.errors.invoiceNumber}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.invoiceNumber}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Price
              </Form.Label>
            </Col>
            <Col sm="12" md={9}>
              <Form.Control
                as="input"
                name="price"
                type="number"
                min={0}
                step={0.01}
                value={formik.values.price}
                onChange={formik.handleChange}
                isInvalid={formik.errors.price}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.price}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}></Form.Group>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Problem
              </Form.Label>
            </Col>
            <Col sm="12" md={9}>
              <Form.Control
                as="textarea"
                name="problem"
                value={formik.values.problem}
                onChange={formik.handleChange}
                isInvalid={formik.errors.problem}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.problem}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Notes
              </Form.Label>
            </Col>
            <Col sm="12" md={9}>
              <Form.Control
                as="textarea"
                name="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
                isInvalid={formik.errors.notes}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.notes}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Date Fixed
              </Form.Label>
            </Col>
            <Col sm="12" md={9}>
              <DatePicker
                name="dateFixed"
                className="form-control"
                showPopperArrow={false}
                popperPlacement="auto"
                selected={formik.values.dateFixed}
                onChange={(date) => formik.setFieldValue('dateFixed', date)}
                popperModifiers={{
                  flip: {
                    behavior: ['bottom'],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
              />
              {formik.errors.dateFixed && (
                <small className="text-danger">{formik.errors.dateFixed}</small>
              )}
            </Col>
          </Form.Group>
          {repair && (
            <>
              <>
                <Form.Group as={Row} className="">
                  <Col md={3} className="d-flex align-items-center">
                    <button
                      type="button"
                      onClick={() => setDisplayAuditLog(!displayAuditLog)}
                      className="px-0 btn-link mr-1"
                    >
                      <>
                        {displayAuditLog ? (
                          <CaretDown size={17} />
                        ) : (
                          <CaretRight size={17} />
                        )}
                      </>
                    </button>
                    <Form.Label column sm="12" md="auto">
                      History
                    </Form.Label>
                  </Col>
                </Form.Group>
                {displayAuditLog && (
                  <Form.Group as={Row} className="">
                    <Col md={12}>
                      <AuditLog
                        contentType={repair.contentType.model}
                        id={repair.id}
                      />
                    </Col>
                  </Form.Group>
                )}
              </>
            </>
          )}
          <Form.Row className="mt-2">
            <Col md={3}>
              <Button
                type="submit"
                block
                variant="outline-primary"
                disabled={submitting}
              >
                <PlusCircle className="mr-2" />
                Save
              </Button>
            </Col>

            {repair && (
              <Col md={3}>
                <Button
                  variant="outline-danger"
                  block
                  onClick={handleDelete}
                  disabled={submitting}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            )}
          </Form.Row>
        </Form>
      </div>
    </>
  )
}

export default RepairForm
