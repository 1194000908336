import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import Loading from '../../common/Loading'
import { Plug } from 'react-bootstrap-icons'
import { FilePond } from 'react-filepond'

const EditEmailSetting = (props) => {
  const { showModal, toggleModal, emailSetting } = props

  const [submitting, setSubmitting] = useState(false)
  const [resetting, setResetting] = useState(false)
  const [customGmailAuthJson, setCustomGmailAuthJson] = useState()
  const [update] = useMutation(
    gql`
      mutation UpdateSmtp($updateSmtpInput: UpdateSMTPConnectorInput!) {
        updateSmtpConnection(input: $updateSmtpInput) {
          smtpConnector {
            id
            customValid
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        if (resetting) {
          toast.success('Reset to Default Email Server')
          setResetting(false)
        } else if (data.updateSmtpConnection.smtpConnector.customValid) {
          toast.success('Email Server Integration Enabled')
        } else {
          toast.error('Invalid Email Server Credentials')
        }
        innerToggle()
      },
      refetchQueries: ['SMTPConnector'],
      errorPolicy: 'all',
    }
  )

  const handleFileUpload = (credentials) => {
    try {
      const reader = new FileReader()
      reader.onload = () => {
        setCustomGmailAuthJson(reader.result)
      }
      reader.readAsText(credentials[0]?.file)
      formik.setFieldValue('customGmailAuth', credentials)
    } catch {
      //
    }
  }

  const formik = useFormik({
    initialValues: {
      customHost: '',
      customUser: '',
      customPort: '',
      customSendgridSender: '',
      customPassword: '',
      customTls: false,
      setEmailRateLimit: false,
      rateLimit: 10,
      customSsl: false,
      rateLimitSecondDelta: 10,
      customGmail: false,
      customGmailAuth: null,
    },
    validationSchema: Yup.object().shape({
      customHost: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value, context) => {
          let valid = true
          if (!context.parent.customGmail && !value) {
            valid = false
          }
          return valid
        }),
      customPort: Yup.number()
        .typeError('number Required')
        .nullable()
        .test('isRequired', 'Required', (value, context) => {
          let valid = true
          if (!context.parent.customGmail && !value) {
            valid = false
          }
          return valid
        }),
      customUser: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value, context) => {
          let valid = true
          if (!context.parent.customGmail && !value) {
            valid = false
          }
          return valid
        }),
      customSendgridSender: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value, context) => {
          let valid = true
          if (context.parent.customHost === 'smtp.sendgrid.net' && !value) {
            valid = false
          }
          return valid
        }),
      customPassword: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value, context) => {
          let valid = true
          if (!context.parent.customGmail && !value) {
            valid = false
          }
          return valid
        }),
      customTls: Yup.boolean().nullable(),
      customSsl: Yup.boolean().nullable(),
      setEmailRateLimit: Yup.boolean().nullable(),
      rateLimit: Yup.number()
        .typeError('number Required')
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        })
        .min(1, 'Rate limit too low')
        .max(100, 'Rate limit too high'),
      rateLimitSecondDelta: Yup.number()
        .typeError('number Required')
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        })
        .min(1, 'Rate limit too low')
        .max(100, 'Rate limit too high'),
      customGmail: Yup.boolean().nullable(),
      customGmailAuth: Yup.mixed()
        .nullable()
        .test('isRequired', 'Required', (value, context) => {
          let valid = true
          if (context.parent.customGmail && !value) {
            valid = false
          }
          return valid
        }),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      update({
        variables: {
          updateSmtpInput: {
            smtpConnectorInput: {
              customHost: values.customGmail ? null : values.customHost,
              customPort: values.customGmail ? null : values.customPort,
              customUser: values.customGmail ? null : values.customUser,
              customSendgridSender:
                values.customHost === 'smtp.sendgrid.net'
                  ? values.customGmail
                    ? null
                    : values.customSendgridSender
                  : null,
              customPassword: values.customGmail ? null : values.customPassword,
              customTls: values.customGmail ? null : values.customTls,
              customSsl: values.customGmail ? null : values.customSsl,
              setEmailRateLimit: values.setEmailRateLimit,
              rateLimit: values.rateLimit,
              rateLimitSecondDelta: values.rateLimitSecondDelta,
              customGmail: values.customGmail,
              customGmailAuth: customGmailAuthJson,
            },
          },
        },
      })
    },
  })
  const innerToggle = () => {
    toggleModal()
    setCustomGmailAuthJson()
    formik.resetForm()
    setSubmitting(false)
  }

  useEffect(() => {
    if (showModal && emailSetting) {
      formik.setValues({
        setEmailRateLimit: false,
        customHost: emailSetting.customHost,
        customUser: emailSetting.customUser,
        customPort: emailSetting.customPort,
        customPassword: emailSetting.customPassword,
        customTls: emailSetting.customTls,
        customSsl: emailSetting.customSsl,
        customSendgridSender: emailSetting.customSendgridSender,
        rateLimit: emailSetting.rateLimit,
        rateLimitSecondDelta: emailSetting.rateLimitSecondDelta,
        customGmail: emailSetting.customGmail,
      })
    }
  }, [showModal, emailSetting])

  return (
    <>
      <div className="newSessionResitReasonModal">
        <Modal
          size="lg"
          show={showModal}
          onHide={innerToggle}
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                <img
                  className="img-fluid mr-2"
                  src={window.location.origin + '/email.png'}
                  style={{ height: '25px' }}
                  alt="Organization logo"
                />
                Configure Email
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!formik.values.customGmail && (
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Email
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="customUser"
                        value={formik.values.customUser}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.customUser}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.customUser}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {formik.values.customHost === 'smtp.sendgrid.net' && (
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        SendGrid Email
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="customSendgridSender"
                          value={formik.values.customSendgridSender}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.customSendgridSender}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.customSendgridSender}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  )}
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Password
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="password"
                        name="customPassword"
                        value={formik.values.customPassword}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.customPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.customPassword}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Email Server Host
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="customHost"
                        value={formik.values.customHost}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.customHost}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.customHost}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Email Server Port
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="number"
                        name="customPort"
                        value={formik.values.customPort}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.customPort}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.customPort}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Use TLS
                    </Form.Label>
                    <Col>
                      <Form.Check
                        inline
                        label=""
                        checked={formik.values.customTls}
                        onChange={(e) => {
                          formik.setFieldValue('customTls', e.target.checked)
                          if (e.target.checked && formik.values.customSsl) {
                            formik.setFieldValue('customSsl', false)
                          }
                        }}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Use SSL
                    </Form.Label>
                    <Col>
                      <Form.Check
                        inline
                        label=""
                        checked={formik.values.customSsl}
                        onChange={(e) => {
                          formik.setFieldValue('customSsl', e.target.checked)
                          if (e.target.checked && formik.values.customTls) {
                            formik.setFieldValue('customTls', false)
                          }
                        }}
                      />
                    </Col>
                  </Form.Group>
                </>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Default Emails Per Batch
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    name="rateLimit"
                    value={formik.values.rateLimit}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.rateLimit}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.rateLimit}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Default Seconds Per Batch
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    name="rateLimitSecondDelta"
                    value={formik.values.rateLimitSecondDelta}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.rateLimitSecondDelta}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.rateLimitSecondDelta}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Set All Notifications to Default Batch
                </Form.Label>
                <Col>
                  <Form.Check
                    inline
                    label=""
                    checked={formik.values.setEmailRateLimit}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'setEmailRateLimit',
                        !formik.values.setEmailRateLimit
                      )
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Gmail
                </Form.Label>
                <Col>
                  <Form.Check
                    inline
                    label=""
                    checked={formik.values.customGmail}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'customGmail',
                        !formik.values.customGmail
                      )
                    }}
                  />
                </Col>
              </Form.Group>
              {formik.values.customGmail && (
                <Form.Group as={Row}>
                  <Form.Label column sm="8">
                    Google Workspace OAuth credentials
                  </Form.Label>
                  <Col xs={12}>
                    <FilePond
                      files={formik.values.customGmailAuth}
                      onupdatefiles={handleFileUpload}
                      acceptedFileTypes={['application/json']}
                      allowFileSizeValidation
                      maxFileSize="15MB"
                      labelMaxFileSize="Maximum preview image size is {filesize}"
                      maxFiles={1}
                      name="files"
                      labelIdle='Drag and drop a file or <span class="filepond--label-action">Browse</span>'
                    />
                    {formik.errors?.customGmailAuth && (
                      <span style={{ color: 'red' }}>
                        {formik.errors?.customGmailAuth}
                      </span>
                    )}
                  </Col>
                </Form.Group>
              )}
              <Row>
                <Col md={3}>
                  <Button
                    disabled={submitting}
                    type="submit"
                    size="sm"
                    block
                    variant="outline-primary"
                    className="mt-2"
                  >
                    <Plug className="mr-2" />
                    Save
                  </Button>
                </Col>
              </Row>

              {submitting && (
                <Loading
                  message={
                    !resetting && 'Validating Email Server Credentials...'
                  }
                />
              )}
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default EditEmailSetting
