import React, { useState } from 'react'
import { Modal, Card, Accordion, Button } from 'react-bootstrap'
import AuditLog from '../../AuditLog'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { coy as style } from 'react-syntax-highlighter/dist/esm/styles/prism'

const APICallModal = ({ showModal, toggleModal, apiCall }) => {
  const [openSections, setOpenSections] = useState(new Set())

  const toggleSection = (key) => {
    const newOpenSections = new Set(openSections)
    if (newOpenSections.has(key)) {
      newOpenSections.delete(key)
    } else {
      newOpenSections.add(key)
    }
    setOpenSections(newOpenSections)
  }

  const formatJSON = (jsonString) => {
    try {
      const json = JSON.parse(jsonString)
      const normalizedJson = JSON.stringify(json, (key, value) =>
        typeof value === 'string' ? value.replace(/\s+/g, ' ').trim() : value
      )
      return JSON.stringify(JSON.parse(normalizedJson), null, 2)
    } catch (e) {
      return `Error in formatting JSON: ${e}`
    }
  }

  return (
    <>
      <Modal size={'lg'} show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>API Call {apiCall.recordId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  onClick={() => toggleSection('1')}
                >
                  Request
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1" in={openSections.has('1')}>
                <Card.Body>
                  <SyntaxHighlighter language="json" style={style}>
                    {formatJSON(apiCall.apiRequest)}
                  </SyntaxHighlighter>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  onClick={() => toggleSection('2')}
                >
                  Response
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2" in={openSections.has('2')}>
                <Card.Body>
                  <SyntaxHighlighter language="json" style={style}>
                    {formatJSON(apiCall.apiResponse)}
                  </SyntaxHighlighter>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  onClick={() => toggleSection('3')}
                >
                  Record Changes
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3" in={openSections.has('3')}>
                <Card.Body>
                  <AuditLog apiCallId={apiCall.id} fetchPolicy={'no-cache'} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default APICallModal
