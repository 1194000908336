import React from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'

const DeleteOrganizaitonStageModal = (props) => {
  const { organizationStage, showModal, toggleModal } = props

  const [deleteOrganizationStage] = useMutation(
    gql`
      mutation DeleteJobTypeMutations(
        $deleteOrganizationStageInput: DeleteOrganizationStageInput!
      ) {
        deleteOrganizationStage(input: $deleteOrganizationStageInput) {
          deleted
        }
      }
    `,
    {
      refetchQueries: ['OrganizationStages'],
      onCompleted: () => {
        toast.success('Stage Deleted')
        toggleModal()
      },
    }
  )

  if (!showModal) return null

  return (
    <Modal show={showModal} onHide={toggleModal} size="sm">
      <Modal.Header closeButton>Delete Stage</Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>
              Are you sure you want to delete {organizationStage.name} stage?
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Button
              variant="link"
              size="sm"
              block
              style={{ color: 'red !important' }}
              onClick={() => {
                deleteOrganizationStage({
                  variables: {
                    deleteOrganizationStageInput: {
                      organizationStageIds: [organizationStage.id],
                    },
                  },
                })
              }}
            >
              <Trash className="mr-2" />
              Delete
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteOrganizaitonStageModal
