import React, { useState } from 'react'
import { Row, Col, Form, Table } from 'react-bootstrap'
import { gql, useLazyQuery } from '@apollo/client'
import { XCircle, Trash } from 'react-bootstrap-icons'
import Loading from '../../common/Loading'
import InfiniteScroll from 'react-infinite-scroll-component'

const ProductCard = (props) => {
  const { formik, index } = props
  const [displayProducts, setDisplayProducts] = useState(false)

  const [searchProducts, { data: productsData, fetchMore: fetchMoreProducts }] =
    useLazyQuery(
      gql`
        query Products($after: String, $first: Int, $nameIcontains: String) {
          products(
            after: $after
            first: $first
            name_Icontains: $nameIcontains
          ) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                id
                name
                chargeSalesTax
                salesTaxRate
                defaultPrice
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )

  return (
    <>
      <div
        className={
          formik.values.products.length > 1
            ? 'border border-secondary bg-white rounded my-3 px-3 pb-3'
            : ''
        }
      >
        <Row
          className={formik.values.products.length > 1 ? 'mb-3 mt-3' : 'mb-3'}
        >
          <Col md={4}>
            <Form.Label>
              Product
              {formik.values.products[index].productId && (
                <span
                  type="button"
                  className="p-0 ml-2 btn-link"
                  onClick={() => {
                    formik.setFieldValue(`products[${index}].productName`, '')
                    formik.setFieldValue(`products[${index}].productId`, '')
                  }}
                >
                  <XCircle />
                </span>
              )}
            </Form.Label>
            <Form.Control
              placeholder="search products"
              value={formik.values.products[index].productName}
              onBlur={() => {
                setDisplayProducts(false)
                formik.setFieldTouched(`products[${index}].productId`, true)
              }}
              onChange={(e) => {
                setDisplayProducts(true)
                formik.setFieldValue(
                  `products[${index}].productName`,
                  e.target.value
                )
                searchProducts({
                  variables: {
                    after: null,
                    first: 10,
                    nameIcontains: e.target.value,
                  },
                })
              }}
              readOnly={Boolean(formik.values.products[index].productId)}
              className={
                formik.values.productId
                  ? ' border border-success form-control form-control-sm'
                  : 'form-control form-control-sm'
              }
            />
            {productsData && displayProducts && (
              <InfiniteScroll
                height={100}
                dataLength={productsData.products.edges.length}
                next={() => {
                  fetchMoreProducts({
                    variables: {
                      after: productsData.products.pageInfo.endCursor,
                      first: 10,
                      nameIcontains: formik.values.productName,
                    },
                  })
                }}
                hasMore={productsData?.products.pageInfo.hasNextPage}
                loader={<Loading />}
              >
                <Table size="sm" hover>
                  <tbody>
                    {productsData.products.edges.map((product) => {
                      const { node } = product
                      return (
                        <tr
                          onMouseDown={() => {
                            setDisplayProducts(false)
                            formik.setFieldValue(
                              `products[${index}].productName`,
                              node.name
                            )
                            formik.setFieldValue(
                              `products[${index}].chargeSalesTax`,
                              node.chargeSalesTax
                            )
                            formik.setFieldValue(
                              `products[${index}].salesTaxRate`,
                              node.salesTaxRate ? node.salesTaxRate : 0
                            )
                            formik.setFieldValue(
                              `products[${index}].productId`,
                              node.id
                            )
                            formik.setFieldValue(
                              `products[${index}].price`,
                              node.defaultPrice ? node.defaultPrice : 0
                            )
                          }}
                          key={node.id}
                          className="hover text-decoration-none"
                        >
                          <td>
                            <small>{node.name}</small>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </InfiniteScroll>
            )}
          </Col>
          <Col md={2}>
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              className="form-control-sm"
              name={`products[${index}].quantity`}
              value={formik.values.products[index].quantity}
              onChange={formik.handleChange}
              isInvalid={
                formik.errors?.products?.length > index &&
                formik.errors.products[index]?.quantity
              }
            />
            {formik.errors?.products?.length > index &&
              formik.errors.products[index]?.quantity && (
                <small className="text-danger">
                  {formik.errors.products[index]?.quantity}
                </small>
              )}
          </Col>
          <Col md={2}>
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              min={1}
              className="form-control-sm"
              max={100000}
              step={0.01}
              name={`products[${index}].price`}
              value={formik.values.products[index].price}
              onChange={formik.handleChange}
              isInvalid={
                formik.errors?.products?.length > index &&
                formik.errors.products[index]?.price
              }
            />
            {formik.errors?.products?.length > index &&
              formik.errors.products[index]?.price && (
                <small className="text-danger">
                  {formik.errors.products[index]?.price}
                </small>
              )}
          </Col>
          {formik.values.products[index].productId && (
            <>
              <Col md={2}>
                <Form.Label md="auto">Charge Sales Tax</Form.Label>
                <Form.Check
                  name={`products[${index}].chargeSalesTax`}
                  value={formik.values.products[index].chargeSalesTax}
                  type="checkbox"
                  checked={formik.values.products[index].chargeSalesTax}
                  onChange={(e) => {
                    formik.setFieldValue(
                      `products[${index}].chargeSalesTax`,
                      e.target.checked
                    )
                  }}
                />
              </Col>
              {formik.values.products[index].chargeSalesTax && (
                <>
                  <Col md={2}>
                    <Form.Label md="auto">Sales Tax Percent</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control-sm"
                      max={100}
                      step={0.01}
                      min={0}
                      name={`products[${index}].salesTaxRate`}
                      value={formik.values.products[index].salesTaxRate}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.errors?.products?.length > index &&
                        formik.errors.products[index]?.salesTaxRate
                      }
                    />
                    {formik.errors?.products?.length > index &&
                      formik.errors.products[index]?.salesTaxRate && (
                        <small className="text-danger">
                          {formik.errors.products[index]?.salesTaxRate}
                        </small>
                      )}
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
        <Row>
          <Col>
            <div>
              <p size="sm">
                <small
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => {
                    const newProducts = [...formik.values.products]
                    newProducts.splice(index, 1)
                    formik.setFieldValue('products', newProducts)
                  }}
                >
                  <Trash className="me-1" /> Remove
                </small>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ProductCard
