import React, { useState } from 'react'
import { useMutation, gql, useLazyQuery, useApolloClient } from '@apollo/client'
import { Col, Button, Form as BootstrapForm } from 'react-bootstrap'
import toast from 'react-hot-toast'
import PhoneInput from 'react-phone-input-2'
import { setLoginVars } from '../../libs/apollo'
import { Formik, Field, ErrorMessage, Form } from 'formik'
import * as Yup from 'yup'
import { Envelope, Person } from 'react-bootstrap-icons'
import { setJwtLocalStorage } from '../../libs/utils'
import { BoxArrowInRight, FolderPlus } from 'react-bootstrap-icons'

export default function CreateAccount(props) {
  const { handleNextStage, handlePostLogin } = props
  const client = useApolloClient()
  const [email, setEmail] = useState('')
  const [userId, setUserId] = useState()
  const [password, setPassword] = useState('')
  const [createdAccount, setCreatedAccount] = useState(false)

  const EMAIL_CHECK = gql`
    query GaiaUsersEmailCheckQuery($emailIexact: String) {
      gaiaUsers(email_Iexact: $emailIexact) {
        nodeCount
      }
    }
  `

  const CREATE_GAIA_USER = gql`
    mutation CreateGaiaUserMutation($input: CreateGaiaUserInput!) {
      createGaiaUser(input: $input) {
        gaiaUser {
          id
          username
        }
      }
    }
  `

  const USER_MANAGED = gql`
    query GaiaUsers($id: ID!) {
      gaiaUser(id: $id) {
        emailConfirmed
      }
    }
  `

  const GET_TOKEN = gql`
    mutation CreateJwtTokenMutation($input: CreateJSONWebTokenInput!) {
      createJwtToken(input: $input) {
        token
        refreshExpiresIn
        gaiaUser {
          id
          email
          firstName
          lastName
          fullName
          subject {
            id
            studentId
            organization {
              id
              name
            }
          }
          organizationContacts {
            edges {
              node {
                id
              }
            }
          }
          employee {
            id
          }
          groups {
            edges {
              node {
                id
                name
              }
            }
          }
          firstName
          lastName
        }
      }
    }
  `

  const [getToken] = useMutation(GET_TOKEN, {
    errorPolicy: 'all',
    onCompleted(data) {
      if (data.createJwtToken) {
        const user = data.createJwtToken.gaiaUser
        setJwtLocalStorage(data.createJwtToken)
        setLoginVars(user)
        if (handleNextStage) {
          handleNextStage('createLogin')
        } else {
          handlePostLogin(
            data.createJwtToken.gaiaUser,
            data.createJwtToken.gaiaUser.subject
          )
        }
      }
    },
  })

  const [
    createGaiaUser,
    { loading: createGaiaUserLoading, data: createGaiaUserData },
  ] = useMutation(CREATE_GAIA_USER, {
    onCompleted(data) {
      setUserId(data.createGaiaUser.gaiaUser.id)
    },
  })

  const [confirmEmailCheck] = useLazyQuery(USER_MANAGED, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data.gaiaUser?.emailConfirmed) {
        getToken({
          variables: {
            input: {
              username: email,
              password: password,
            },
          },
        })
      } else {
        toast.error('Email Not Verified')
      }
    },
  })

  const handleVerifyNextClick = () => {
    confirmEmailCheck({
      variables: {
        id: userId,
      },
    })
  }

  return (
    <>
      {createdAccount ? (
        <>
          <p className="text-secondary mb-1 d-flex align-items-center">
            <Envelope className="mr-1" /> <span>Verify Email</span>
          </p>
          <small className="d-block">
            Please check your email inbox for a verification link. Click Next
            after verifting your email.
          </small>
          <Button
            variant="link"
            className="mx-0 mt-3"
            type="submit"
            onClick={handleVerifyNextClick}
          >
            <BoxArrowInRight className="mr-2" />
            Next
          </Button>
        </>
      ) : null}

      {!createGaiaUserData && !createGaiaUserLoading && !createdAccount ? (
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            password: '',
            confirmPassword: '',
            emailNotificationsEnabled: true,
            smsNotificationsEnabled: true,
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Invalid email address')
              .required('required')
              .test(
                'isUnique',
                'Account with this email already exists',
                async (value, context) => {
                  if (value) {
                    const { data } = await client.query({
                      query: EMAIL_CHECK,
                      fetchPolicy: 'network-only',
                      variables: {
                        emailIexact: value,
                      },
                    })
                    return data.gaiaUsers.nodeCount === 0
                  }

                  return false
                }
              ),
            firstName: Yup.string().required('required'),
            lastName: Yup.string().required('required'),
            password: Yup.string()
              .required('required')
              .min(8, 'Password must be at least 8 characters long')
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
                'Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
              ),
            emailNotificationsEnabled: Yup.bool().required('required'),
            smsNotificationsEnabled: Yup.bool().required('required'),
            confirmPassword: Yup.string()
              .required('required')
              .test(
                'confirmPassword',
                'Password and Confirm Password must match',
                (value, context) => {
                  if (context.parent.password) {
                    return context.parent.password === value
                  }
                  return false
                }
              ),
          })}
          onSubmit={(values) => {
            setEmail(values.email)
            setPassword(values.password)
            setCreatedAccount(true)
            createGaiaUser({
              variables: {
                input: {
                  gaiaUserInput: {
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phoneNumber: values.phoneNumber,
                    emailNotificationsEnabled: values.emailNotificationsEnabled,
                    smsNotificationsEnabled: values.smsNotificationsEnabled,
                    password: values.password,
                    groups: ['Subject'],
                    sendAccountCreatedEmail: true,
                  },
                },
              },
            })
          }}
          render={(formik) => (
            <Form>
              <BootstrapForm.Row>
                <BootstrapForm.Group as={Col} md={6}>
                  <BootstrapForm.Label className="small">
                    First Name
                  </BootstrapForm.Label>
                  <Field
                    name="firstName"
                    type="text"
                    className="form-control form-control-sm"
                  />
                  <ErrorMessage
                    name="firstName"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </BootstrapForm.Group>
                <BootstrapForm.Group as={Col} md={6}>
                  <BootstrapForm.Label className="small">
                    Last Name
                  </BootstrapForm.Label>

                  <Field
                    name="lastName"
                    type="text"
                    className="form-control form-control-sm"
                  />
                  <ErrorMessage
                    name="lastName"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </BootstrapForm.Group>
              </BootstrapForm.Row>

              <BootstrapForm.Row>
                <BootstrapForm.Group as={Col} md={6}>
                  <BootstrapForm.Label className="small">
                    Email
                  </BootstrapForm.Label>
                  <Field
                    name="email"
                    type="text"
                    className="form-control form-control-sm"
                  />
                  <ErrorMessage
                    name="email"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </BootstrapForm.Group>
                <BootstrapForm.Group as={Col} md={6}>
                  <BootstrapForm.Label className="small">
                    Phone Number
                  </BootstrapForm.Label>

                  <PhoneInput
                    inputClass="w-100"
                    country="us"
                    placeholder=""
                    name="phoneNumber"
                    regions={['north-america']}
                    value={formik.values.phoneNumber}
                    onChange={(phone) =>
                      formik.setFieldValue(`phoneNumber`, phone)
                    }
                  />

                  <ErrorMessage
                    name="phoneNumber"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </BootstrapForm.Group>
              </BootstrapForm.Row>

              <BootstrapForm.Row>
                <BootstrapForm.Group as={Col} md={6}>
                  <BootstrapForm.Label className="small">
                    Password
                  </BootstrapForm.Label>
                  <Field
                    name="password"
                    type="password"
                    className="form-control form-control-sm"
                  />
                  <ErrorMessage
                    name="password"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </BootstrapForm.Group>
                <BootstrapForm.Group as={Col} md={6}>
                  <BootstrapForm.Label className="small">
                    Confirm Password
                  </BootstrapForm.Label>

                  <Field
                    name="confirmPassword"
                    type="password"
                    className="form-control form-control-sm"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    render={(msg) => <div className="text-danger">{msg}</div>}
                  />
                </BootstrapForm.Group>
              </BootstrapForm.Row>

              <Button variant="link" className="mx-0" type="submit">
                <Person className="mr-2" />
                Create Account
              </Button>
            </Form>
          )}
        />
      ) : null}
    </>
  )
}
