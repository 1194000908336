import React, { useEffect } from 'react'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import { useMutation, gql } from '@apollo/client'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { client } from '../../libs/apollo'
import { PlusCircle, Trash, PersonBadge } from 'react-bootstrap-icons'

const EditEmployeeRoleModal = (props) => {
  const { role, showModal, toggleModal } = props
  const UPDATE_ROLE_MUTATION = gql`
    mutation UpdateRole($updateRoleInput: UpdateRoleInput!) {
      updateRole(input: $updateRoleInput) {
        role {
          id
        }
      }
    }
  `
  const ROLE_NAME_UNIQUE = gql`
    query RoleNameUniqueQuery($nameIexact: String) {
      roles(name_Iexact: $nameIexact) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `
  const EditEmployeeRoleSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Name is too short')
      .max(100, 'Name is too long')
      .required('Name is required')
      .test('isUnique', 'Name must be unique', async (value) => {
        let valid = true
        if (value) {
          const { data } = await client.query({
            query: ROLE_NAME_UNIQUE,
            fetchPolicy: 'network-only',
            variables: {
              nameIexact: value,
            },
          })
          data.roles.edges.forEach((node) => {
            if (node.node.name === value && node.node.id !== role.id) {
              valid = false
            }
          })
          return valid
        }
        return valid
      }),
    abbreviatedName: Yup.string()
      .max(100, 'Abbrevieted name  is too long')
      .nullable(),
  })
  const [updateRoleMutation] = useMutation(UPDATE_ROLE_MUTATION, {
    onCompleted: (data) => {
      toast.success(`Employee Role Updated`)
      toggleModal()
    },
    errorPolicy: 'all',
    refetchQueries: ['EmployeesQuery', 'RolesQuery'],
  })
  const [deleteRoleMutation] = useMutation(
    gql`
      mutation DeleteEmployeeRoleMutation($deleteRoleInput: DeleteRoleInput!) {
        deleteRole(input: $deleteRoleInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: (data) => {
        toast.success('Employee Role Deleted')
        formik.resetForm()
        toggleModal()
      },
      errorPolicy: 'all',
      refetchQueries: ['EmployeesQuery', 'RolesQuery'],
    }
  )
  const formik = useFormik({
    initialValues: {
      name: '',
      abbreviatedName: '',
    },
    validationSchema: EditEmployeeRoleSchema,
    onSubmit: (values) => {
      updateRoleMutation({
        variables: {
          updateRoleInput: {
            roleInput: {
              id: role.id,
              name: values.name,
              abbreviatedName: values.abbreviatedName,
            },
          },
        },
      })
    },
  })
  useEffect(() => {
    if (showModal && role) {
      formik.setValues({
        name: role.name,
        abbreviatedName: role.abbreviatedName,
      })
    }
  }, [showModal, role])
  const handleDelete = () => {
    deleteRoleMutation({
      variables: {
        deleteRoleInput: {
          roleIds: [role.id],
        },
      },
    })
  }
  if (showModal) {
    return (
      <>
        <div className="editEmployeeRoleModal">
          <Modal
            id="updateHourlyRate"
            size="md"
            show={showModal}
            onHide={toggleModal}
            aria-labelledby="new-title"
            className="invmodal detail"
          >
            <Form onSubmit={formik.handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title id="new-title">
                  <PersonBadge className="mr-2" />
                  Edit Employee Role
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Form.Group as={Col} xs={12} md={3}>
                    <Form.Label>Name</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={9}>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    xs={12}
                    md={3}
                    className="d-flex align-items-center"
                  >
                    <Form.Label className="mb-0">Abbreviated Name</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={9}>
                    <Form.Control
                      type="text"
                      name="abbreviatedName"
                      value={formik.values.abbreviatedName}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.abbreviatedName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.abbreviatedName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Col md={4}>
                    <Button type="submit" variant="outline-primary" block>
                      <PlusCircle className="mr-2" />
                      Save
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      variant="outline-danger"
                      className="ml-2"
                      block
                      onClick={handleDelete}
                    >
                      <Trash className="mr-2" />
                      Delete
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Form>
          </Modal>
        </div>
      </>
    )
  }
  return <></>
}

export default EditEmployeeRoleModal
