import React, { useEffect, useRef } from 'react'
import isEqual from 'lodash/isEqual'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { Briefcase, PlusCircle } from 'react-bootstrap-icons'
import JobEquipmentItemTypeCard from './JobEquipmentItemTypeCard'

const JobEquipmentItemTypes = (props) => {
  const { formik } = props
  const prevJobEquipmentItemTypes = useRef()

  useEffect(() => {
    const itemTypeQuantities = formik.values.employeeJobs.reduce(
      (acc, currentJob) => {
        currentJob.equipmentBags.forEach((bag) => {
          bag?.equipmentItemTypeIds?.forEach((id) => {
            if (!acc[id]) {
              acc[id] = 0
            }
            acc[id] += 1
          })
        })
        currentJob?.equipmentItems.forEach((item) => {
          if (item?.equipmentItemType?.id) {
            const { id } = item.equipmentItemType
            if (!acc[id]) {
              acc[id] = 0
            }
            acc[id] += 1
          }
        })
        return acc
      },
      {}
    )

    const updatedJobEquipmentItemTypes =
      formik.values.jobEquipmentItemTypes.map((itemType) => {
        const quantity = itemTypeQuantities[itemType.equipmentItemTypeId] || 0
        return {
          ...itemType,
          includedQuantity: quantity,
        }
      })
    if (
      !isEqual(prevJobEquipmentItemTypes.current, updatedJobEquipmentItemTypes)
    ) {
      formik.setFieldValue(
        'jobEquipmentItemTypes',
        updatedJobEquipmentItemTypes
      )
      prevJobEquipmentItemTypes.current = updatedJobEquipmentItemTypes
    }
  }, [formik.values.employeeJobs, formik.values.jobEquipmentItemTypes])

  return (
    <>
      {formik.values.jobEquipmentItemTypes.length > 0 && (
        <Form.Row className="mt-2" style={{ marginBottom: '-10px' }}>
          <Form.Group as={Col} md={8}>
            <h5>Required Item Types</h5>
          </Form.Group>
        </Form.Row>
      )}
      <Row>
        <Col md={7} sm={12}>
          <div
            className={'px-3'}
            style={{
              maxHeight: '600px',
              overflowY: 'auto',
            }}
          >
            {formik.values.jobEquipmentItemTypes.map((_, index) => (
              <JobEquipmentItemTypeCard
                key={index}
                formik={formik}
                index={index}
              />
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className={
              formik.values.jobEquipmentItemTypes.length > 0
                ? ' mt-3 ps-0'
                : 'ps-0 mt-1'
            }
            variant="link"
            size="sm"
            onClick={() => {
              formik.setFieldValue('jobEquipmentItemTypes', [
                ...formik.values.jobEquipmentItemTypes,
                {
                  requiredQuantity: 1,
                  includedQuantity: 0,
                  equipmentItemTypeId: null,
                  equipmentItemTypeName: null,
                },
              ])
            }}
          >
            <PlusCircle className="mr-1" /> Add Required Item Type
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default JobEquipmentItemTypes
