import React, { createContext, useState, useContext, useEffect } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import { v4 as uuidv4 } from 'uuid'
import Loading from '../common/Loading'
import ProgressBar from 'react-bootstrap/ProgressBar'
import './UploadContext.css'
import { Row, Col } from 'react-bootstrap'

const UploadContext = createContext()

export const useUpload = () => useContext(UploadContext)

export const UploadProvider = ({ children }) => {
  const [uploading, setUploading] = useState(false)
  const [fileUploadSession, setFileUploadSession] = useState(null)
  const [maxConcurrentUpload, setMaxConcurrentUpload] = useState(1)
  const [filesCount, setFilesCount] = useState(0)
  const [toastedSuccess, setToastedSuccess] = useState(false)

  const WEB_WORKER_PATH = `${process.env.PUBLIC_URL}/workers/fileChunkingWorker.js`
  const MAX_WEB_WORKERS = 2
  const CHUNK_SIZE = 25 * 1024 * 1024
  const workerPool = []
  const messageQueue = []

  const [getFileUploadSession, { data: fileUploadSessionData }] = useLazyQuery(
    gql`
      query FileUploadSession($id: ID!) {
        fileUploadSession(id: $id) {
          id
          totalSize
          currentSizeTransferred
          transferComplete
          currentSizeCreated
          createComplete
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      pollInterval: 1000,
    }
  )

  const [createFileUploadSession] = useMutation(
    gql`
      mutation CreateFileUploadSession($input: CreateFileUploadSessionInput!) {
        createFileUploadSession(input: $input) {
          fileUploadSession {
            id
            totalSize
            currentSizeTransferred
            transferComplete
            currentSizeCreated
            createComplete
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data.createFileUploadSession.fileUploadSession) {
          if (maxConcurrentUpload == 1) {
            setMaxConcurrentUpload(10)
          }
          const fileUploadSession =
            data.createFileUploadSession.fileUploadSession
          setFileUploadSession(fileUploadSession)
          if (fileUploadSession.transferComplete) {
            getFileUploadSession({
              variables: { id: fileUploadSession?.id },
            })
          }
        }
      },
    }
  )

  useEffect(() => {
    const fileUploadSessionId = localStorage.getItem('fileUploadSessionId')
    if (fileUploadSessionId) {
      setUploading(true)
      getFileUploadSession({
        variables: { id: fileUploadSessionId },
      })
    }
  }, [])

  useEffect(() => {
    if (fileUploadSessionData && uploading) {
      setFileUploadSession(fileUploadSessionData.fileUploadSession)
    }
  }, [fileUploadSessionData])

  useEffect(() => {
    if (fileUploadSession?.createComplete && !toastedSuccess) {
      setToastedSuccess(true)
      if (filesCount > 1) {
        toast.success(`Files Saved`)
      } else {
        toast.success(`File Saved`)
      }
      resetUploadState()
    }
  }, [fileUploadSession, toastedSuccess])

  const resetUploadState = () => {
    localStorage.removeItem('fileUploadSessionId')
    setFileUploadSession()
    setToastedSuccess(false)
    setFilesCount(0)
    setMaxConcurrentUpload(1)
    setUploading(false)
    workerPool.forEach((worker) => worker.terminate())
    workerPool.length = 0
    messageQueue.length = 0
    if (typeof global.gc === 'function') {
      global.gc()
    }
    setTimeout(() => {
      if (window.gc) {
        window.gc()
      }
    }, 0)
  }

  const sendMessageWithLimit = async (message) => {
    if (messageQueue.length < maxConcurrentUpload) {
      messageQueue.push(message)
      try {
        await createFileUploadSession({
          variables: {
            input: {
              fileUploadSessionInput: message,
            },
          },
        })
      } finally {
        messageQueue.shift()
      }
    } else {
      setTimeout(() => sendMessageWithLimit(message), 100)
    }
  }

  const uploadFileChunks = (file, fileUuid, variables, isLastFile) => {
    const tasks = []
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE)
    for (let i = 0; i < totalChunks; i++) {
      const startOffset = i * CHUNK_SIZE
      const endOffset = Math.min((i + 1) * CHUNK_SIZE, file.size)
      tasks.push(() =>
        uploadChunkWithWorker(
          file,
          fileUuid,
          variables,
          startOffset,
          endOffset,
          totalChunks,
          isLastFile
        )
      )
    }
    return Promise.all(tasks.map((task) => task()))
  }

  const uploadChunkWithWorker = (
    file,
    fileUuid,
    variables,
    startOffset,
    endOffset,
    totalChunks,
    isLastFile
  ) => {
    return new Promise((resolve, reject) => {
      if (workerPool.length < MAX_WEB_WORKERS) {
        const worker = new Worker(WEB_WORKER_PATH)
        workerPool.push(worker)
        worker.postMessage({ file, CHUNK_SIZE, startOffset, endOffset })
        worker.onmessage = async (e) => {
          const { type, data, error } = e.data
          if (type === 'chunk') {
            const { chunk, uncompressedSize, is_final_chunk, current_chunk } =
              data
            try {
              const message = {
                ...variables,
                relativePath: file._relativePath,
                fileUuid: fileUuid,
                fileName: file.name,
                fileContent: chunk,
                fileSize: file.size,
                fileChunkSize: uncompressedSize,
                isFinalChunk: is_final_chunk,
                isFinalFile: isLastFile,
                order: current_chunk,
                totalChunks: totalChunks,
              }
              await sendMessageWithLimit(message)
              if (is_final_chunk) {
                worker.terminate()
                workerPool.splice(workerPool.indexOf(worker), 1)
                resolve()
              }
            } catch (err) {
              worker.terminate()
              workerPool.splice(workerPool.indexOf(worker), 1)
              reject(err)
            }
          } else if (type === 'error' || type === 'complete') {
            worker.terminate()
            workerPool.splice(workerPool.indexOf(worker), 1)
            if (error) {
              reject(error)
            } else {
              resolve()
            }
          }
        }
      } else {
        let retryCount = 0
        const retryUpload = () => {
          if (workerPool.length < MAX_WEB_WORKERS) {
            uploadChunkWithWorker(
              file,
              fileUuid,
              variables,
              startOffset,
              endOffset,
              totalChunks,
              isLastFile
            )
              .then(resolve)
              .catch(reject)
          } else if (retryCount < 100) {
            setTimeout(retryUpload, 100 * (retryCount + 1))
            retryCount++
          } else {
            reject(new Error('Unable to acquire a worker.'))
          }
        }
        retryUpload()
      }
    })
  }

  const manageUploads = async (uploadTasks) => {
    let activeUploads = []
    let index = 0
    const executeTask = async () => {
      if (
        index < uploadTasks.length &&
        activeUploads.length < MAX_WEB_WORKERS
      ) {
        const task = uploadTasks[index++]
        const promise = task().catch((error) => {
          return null
        })
        activeUploads.push(promise)
        promise.finally(() => {
          activeUploads = activeUploads.filter((p) => p !== promise)
          if (index < uploadTasks.length) {
            executeTask()
          }
        })

        if (activeUploads.length < MAX_WEB_WORKERS) {
          executeTask()
        }
      }
    }
    for (let i = 0; i < Math.min(MAX_WEB_WORKERS, uploadTasks.length); i++) {
      executeTask()
    }
    await Promise.all(activeUploads)
  }

  const startUpload = async (files, variables) => {
    setFileUploadSession()
    setUploading(true)
    const totalSize = files.reduce(
      (acc, fileItem) => acc + fileItem.file.size,
      0
    )
    variables.uploadSessionSize = totalSize
    setFilesCount(files.length)
    const uploadTasks = files.map((file, index) => {
      const fileUuid = uuidv4()
      const isLastFile = index === files.length - 1
      return () => uploadFileChunks(file.file, fileUuid, variables, isLastFile)
    })
    await manageUploads(uploadTasks)
  }

  let transferVariant
  let createVariant
  if (fileUploadSession?.id) {
    const transferPercent = Math.round(
      100 *
        (fileUploadSession?.currentSizeTransferred /
          fileUploadSession?.totalSize)
    )
    if (transferPercent == 100) {
      localStorage.setItem('fileUploadSessionId', fileUploadSession.id)
    }
    if (transferPercent < 33) {
      transferVariant = 'danger'
    } else if (transferPercent < 66) {
      transferVariant = 'warning'
    } else if (transferPercent >= 66) {
      transferVariant = 'success'
    }
    const createPercent = Math.round(
      100 *
        (fileUploadSession?.currentSizeCreated / fileUploadSession?.totalSize)
    )
    if (createPercent < 33) {
      createVariant = 'danger'
    } else if (createPercent < 66) {
      createVariant = 'warning'
    } else if (createPercent >= 66) {
      createVariant = 'success'
    }
  }

  return (
    <UploadContext.Provider
      value={{
        uploading,
        startUpload,
      }}
    >
      {children}
      {uploading && (
        <div className="bottom-right">
          <div>
            <Loading />
            <p className="mt-2" style={{ textAlign: 'center' }}>
              Saving File{filesCount > 1 && <>s</>}...
            </p>
          </div>
          {fileUploadSession?.id && (
            <Row className="mt-2">
              <Col>
                <ProgressBar
                  animated
                  variant={transferVariant}
                  now={`${Math.round(
                    100 *
                      (fileUploadSession?.currentSizeTransferred /
                        fileUploadSession?.totalSize)
                  )}`}
                  label={`${Math.round(
                    100 *
                      (fileUploadSession?.currentSizeTransferred /
                        fileUploadSession?.totalSize)
                  )}% Complete Transferring File Data`}
                />
              </Col>
            </Row>
          )}
          {fileUploadSession?.currentSizeCreated > 0 && (
            <Row className="mt-2">
              <Col>
                <ProgressBar
                  animated
                  variant={createVariant}
                  now={` ${Math.round(
                    100 *
                      (fileUploadSession?.currentSizeCreated /
                        fileUploadSession?.totalSize)
                  )}`}
                  label={` ${Math.round(
                    100 *
                      (fileUploadSession?.currentSizeCreated /
                        fileUploadSession?.totalSize)
                  )}% Complete Creating Files`}
                />
              </Col>
            </Row>
          )}
        </div>
      )}
    </UploadContext.Provider>
  )
}
