import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { Trash, PlusCircle, CaretDown, CaretRight } from 'react-bootstrap-icons'
import * as Yup from 'yup'
import { client } from '../../../libs/apollo'
import AuditLog from '../../audit_log/AuditLog'
import InvoiceTable from '../InvoiceTable'
import { settingsVar } from '../../../libs/apollo'
import { useReactiveVar } from '@apollo/client'

const ProductForm = (props) => {
  const { product, afterSubmit } = props
  const settings = useReactiveVar(settingsVar)
  const [submitting, setSubmitting] = useState(false)
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const [displayInvoices, setDisplayInvoices] = useState(false)
  const [deleteProduct] = useMutation(
    gql`
      mutation DeleteProduct($deleteProductInput: DeleteProductInput!) {
        deleteProduct(input: $deleteProductInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Product Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Products'],
    }
  )

  const [createProduct] = useMutation(
    gql`
      mutation CreateProduct($input: CreateProductInput!) {
        createProduct(input: $input) {
          product {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Product Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Products'],
    }
  )

  const [updateProduct] = useMutation(
    gql`
      mutation UpdateProduct($input: UpdateProductInput!) {
        updateProduct(input: $input) {
          product {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Product Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Products'],
    }
  )

  const formik = useFormik({
    initialValues: product
      ? {
          name: product.name,
          description: product.description,
          defaultPrice: product.defaultPrice,
          salesTaxRate: product.salesTaxRate,
          chargeSalesTax: product.chargeSalesTax,
        }
      : {
          name: '',
          description: '',
          defaultPrice: 0,
          salesTaxRate: settings.productTaxRate,
          chargeSalesTax: settings.chargeProductStateSalesTax,
        },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('required')
        .test('nameUnique', 'Product name must be unique', async (value) => {
          const { data } = await client.query({
            query: gql`
              query ProductNameUnique($name: String) {
                products(name_Iexact: $name) {
                  nodeCount
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            `,
            variables: {
              name: value,
            },
            fetchPolicy: 'network-only',
          })

          let result = true
          if (!product && data?.products.nodeCount > 0) {
            result = false
          }
          let productIds = []
          if (data.products.nodeCount > 0) {
            productIds = data.products.edges.map((org) => org.node.id)
          }
          if (
            product &&
            data.products.nodeCount > 0 &&
            productIds.filter((id) => id !== product.id).length > 0
          ) {
            result = false
          }
          return result
        }),
      description: Yup.string().nullable(),
      defaultPrice: Yup.number()
        .nullable()
        .min(1, 'must be greater than 0')
        .test('priceRequired', 'required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      salesTaxRate: Yup.number()
        .nullable()
        .test('priceRequired', 'must be between 0 and 100', (value) => {
          let valid = true
          if (value && (value < 0 || value > 100)) {
            valid = false
          }
          return valid
        }),
      chargeSalesTax: Yup.bool().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      if (product) {
        updateProduct({
          variables: {
            input: {
              productInput: {
                id: product.id,
                name: values.name,
                salesTaxRate: values.salesTaxRate,
                chargeSalesTax: values.chargeSalesTax,
                description: values.description ? values.description : '',
                defaultPrice: values.defaultPrice,
              },
            },
          },
        })
      } else {
        createProduct({
          variables: {
            input: {
              productInput: {
                name: values.name,
                salesTaxRate: values.salesTaxRate,
                chargeSalesTax: values.chargeSalesTax,
                description: values.description ? values.description : '',
                defaultPrice: values.defaultPrice,
              },
            },
          },
        })
      }
    },
  })
  return (
    <>
      <div id="productForm">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Name
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                name="name"
                className="form-control-sm"
                value={formik.values.name}
                disabled={product?.system}
                onChange={formik.handleChange}
                isInvalid={formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {!product?.system && (
            <>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Default Price
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    name="defaultPrice"
                    type="number"
                    className="form-control-sm"
                    value={formik.values.defaultPrice}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.defaultPrice}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.defaultPrice}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Default Charge Sales Tax
                  </Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Check
                    name="chargeSalesTax"
                    type="checkbox"
                    className="form-control-sm"
                    checked={formik.values.chargeSalesTax}
                    isInvalid={formik.errors.chargeSalesTax}
                    onChange={(e) => {
                      formik.setFieldValue(`chargeSalesTax`, e.target.checked)
                    }}
                  />
                </Col>
              </Form.Group>
              {formik.values.chargeSalesTax && (
                <Form.Group as={Row}>
                  <Col md={3}>
                    <Form.Label column sm="12" md="auto">
                      Default Sales Tax Percent
                    </Form.Label>
                  </Col>
                  <Col md={6}>
                    <Form.Control
                      type="number"
                      name="salesTaxRate"
                      className="form-control-sm"
                      value={formik.values.salesTaxRate}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.salesTaxRate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.salesTaxRate}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Description
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    name="description"
                    style={{ height: '200px' }}
                    as="textarea"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.description}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </>
          )}

          {product && (
            <>
              <Form.Group as={Row} className="">
                <Col md={3} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setDisplayInvoices(!displayInvoices)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayInvoices ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label column sm="12" md="auto">
                    Invoices
                  </Form.Label>
                </Col>
              </Form.Group>
              {displayInvoices && (
                <Form.Group as={Row} className="">
                  <Col md={12}>
                    <InvoiceTable productId={product.id} productModal />
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} className="">
                <Col md={3} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setDisplayAuditLog(!displayAuditLog)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayAuditLog ? (
                        <CaretDown size={17} />
                      ) : (
                        <CaretRight size={17} />
                      )}
                    </>
                  </button>
                  <Form.Label column sm="12" md="auto">
                    History
                  </Form.Label>
                </Col>
              </Form.Group>
              {displayAuditLog && (
                <Form.Group as={Row} className="">
                  <Col md={12}>
                    <AuditLog
                      contentType={product.contentType.model}
                      id={product.id}
                    />
                  </Col>
                </Form.Group>
              )}
            </>
          )}
          {!product?.system && (
            <Form.Row className="mt-2">
              <Col md={3}>
                <Button
                  type="submit"
                  block
                  variant="outline-primary"
                  disabled={submitting}
                >
                  <PlusCircle className="mr-2" />
                  Save
                </Button>
              </Col>

              {product && (
                <Col md={3}>
                  <Button
                    block
                    variant="outline-danger"
                    onClick={() => {
                      setSubmitting(true)
                      deleteProduct({
                        variables: {
                          deleteProductInput: {
                            productIds: product.id,
                          },
                        },
                      })
                    }}
                    disabled={submitting}
                  >
                    <Trash className="mr-2" />
                    Delete
                  </Button>
                </Col>
              )}
            </Form.Row>
          )}
        </Form>
      </div>
    </>
  )
}

export default ProductForm
