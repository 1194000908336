import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import { useIntercom } from 'react-use-intercom'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import './Layout.css'

const OpenLayout = (props) => {
  const { boot } = useIntercom()
  let loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  useEffect(() => {
    if (settings && boot && settings.intercomAppId) {
      if (loggedInUser?.id) {
        boot({
          id: loggedInUser.id,
          email: loggedInUser.email,
          name: loggedInUser.firstName,
        })
      } else {
        boot()
      }
    }
  }, [boot, settings])

  return (
    <>
      <div className="openLayout">
        <span className="header">
          <Header />
        </span>
        <div className="container mb-5">{props.children}</div>
        <span className="footer">
          <Footer openLayout={true} />
        </span>
      </div>
    </>
  )
}

export default OpenLayout
