// IntercomIntegration.js
import React, { useState } from 'react'
import { Badge, Button, Row, Col } from 'react-bootstrap'
import { Plug } from 'react-bootstrap-icons'
import IntegrationCard from '../IntegrationCard'
import EditIntercom from './EditIntercom'

const IntercomIntegration = ({ settings }) => {
  const [showEditModal, setShowEditModal] = useState(false)

  return (
    <>
      <EditIntercom
        intercomAppId={settings.intercomAppId}
        settingsId={settings.id}
        showModal={showEditModal}
        toggleModal={() => setShowEditModal(!showEditModal)}
      />
      <IntegrationCard
        logo={`${window.location.origin}/intercom.png`}
        name="Intercom"
        description="Enhance customer communication with Intercom's real-time chat capabilities. Offer instant support and engage with your clients directly through your platform."
      >
        <Row>
          <Col>
            {settings.intercomAppId ? (
              <Badge variant="success">Enabled</Badge>
            ) : (
              <Badge variant="danger">Disabled</Badge>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="link" onClick={() => setShowEditModal(true)}>
              <Plug className="mr-2" />
              Configure
            </Button>
          </Col>
        </Row>
      </IntegrationCard>
    </>
  )
}

export default IntercomIntegration
