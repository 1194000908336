import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const NotificationTriggerSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    setAdditionalFields,
    error,
    multiple,
    dropdown,
    placeholder,
    variables,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="notificationTrigger"
      searchDescription={
        searchDescription ? searchDescription : 'Notification Triggers'
      }
      nodeNamePlural="notificationTriggers"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      multiple={multiple}
      dropdown={dropdown}
      placeholder={placeholder}
      setAdditionalFields={setAdditionalFields}
      variables={variables}
      gql={gql`
        query NotificationTriggers(
          $first: Int
          $after: String
          $search: String
          $custom: Boolean
          $organizationId: ID
          $subjectGroupId: ID
          $organizationIdIsNull: Boolean
          $subjectGroupIdIsNull: Boolean
        ) {
          notificationTriggers(
            first: $first
            after: $after
            orderBy: "name"
            organization_Id: $organizationId
            subjectGroup_Id: $subjectGroupId
            organization_Id_Isnull: $organizationIdIsNull
            subjectGroup_Id_Isnull: $subjectGroupIdIsNull
            name_Icontains: $search
            custom: $custom
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return node.name
      }}
    />
  )
}

export default NotificationTriggerSearchInput
