import React, { useEffect, useState } from 'react'
import SubjectNavigation from './navigation/SubjectNavigation'
import Header from './Header'
import Footer from './Footer'
import { Container } from 'react-bootstrap'
import { useIntercom } from 'react-use-intercom'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import './Layout.css'

const SubjectLayout = (props) => {
  const { boot } = useIntercom()
  const [navigationOpen, setNavigationOpen] = useState(false)
  let loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)

  useEffect(() => {
    if (loggedInUser && settings && boot && settings.intercomAppId) {
      const config = {
        name: loggedInUser.fullName,
        customAttributes: [loggedInUser.id],
      }
      if (loggedInUser.email.includes('@')) {
        config.customAttributes.push(loggedInUser.email)
      }
      boot(config)
    }
  }, [boot, settings, loggedInUser])

  return (
    <>
      <span className="header">
        <Header borderBottom />
      </span>
      <div style={{ display: 'flex', minHeight: '100vh', top: '30px' }}>
        <SubjectNavigation setNavigationOpen={setNavigationOpen} />
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Container fluid className="mb-5">
            {props.children}
          </Container>
          <span className={!navigationOpen && 'footer'}>
            <Footer />
          </span>
        </div>
      </div>
    </>
  )
}

export default SubjectLayout
