import React, { useState } from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { PlusCircle } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'

const NoShowModal = (props) => {
  const { showModal, toggleModal, id } = props
  const [submitting, setSubmitting] = useState(false)
  const [updateSessionMutation] = useMutation(
    gql`
      mutation UpdateSession($updateSessionInput: UpdateSessionInput!) {
        updateSession(input: $updateSessionInput) {
          session {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        toast.success('Session Saved')
        setSubmitting(false)
        toggleModal()
      },
      errorPolicy: 'all',
      refetchQueries: ['JobDetailSessionsQuery'],
    }
  )

  if (!showModal) return <></>
  return (
    <>
      <div className="deleteLocations">
        <Modal
          size="md"
          show={showModal}
          onHide={toggleModal}
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">Session No Show</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  Are you sure you want to mark the session no show? The subject
                  will be notified and billed if they are paying for the session
                  and there is a no show fee.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <Button
                  variant="outline-primary"
                  size="sm"
                  block
                  disabled={submitting}
                  onClick={() => {
                    setSubmitting(true)
                    updateSessionMutation({
                      variables: {
                        updateSessionInput: {
                          sessionInput: {
                            id: id,
                            noShow: true,
                          },
                        },
                      },
                    })
                  }}
                >
                  <PlusCircle className="mr-2" />
                  No Show
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default NoShowModal
