import React, { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useFormik } from 'formik'
import validator from 'validator'
import { Envelope } from 'react-bootstrap-icons'
import * as Yup from 'yup'
import toast from 'react-hot-toast'

const EmailNotificationTestForm = (props) => {
  const {
    notificationTrigger,
    subjectId,
    subjectGroupId,
    organizationId,
    sessionId,
  } = props
  const [notificationTriggerID, setNotificationTriggerID] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [notificationTemplateChannelID, setNotificationTemplateChannelID] =
    useState(null)

  const [testNotificationTriggerMutation] = useMutation(
    gql`
      mutation TestNotification(
        $testNotificationTriggerInput: TestNotificationTriggerInput!
      ) {
        testNotificationTrigger(input: $testNotificationTriggerInput) {
          sent
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Test ${notificationTrigger.name} Sent`)
      },
      errorPolicy: 'all',
    }
  )
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .min(2, 'Email is too short')
        .max(100, 'Email is too long')
        .required('Email is required')
        .test('isEmail', 'Invalid email', (value) => {
          let valid = false
          if (value && validator.isEmail(value)) {
            valid = true
          }
          return valid
        }),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      testNotificationTriggerMutation({
        variables: {
          testNotificationTriggerInput: {
            notificationTriggerTestInput: {
              id: notificationTriggerID,
              notificationTemplateChannelId: notificationTemplateChannelID,
              sendTo: values.email,
              subjectGroupId,
              subjectId,
              organizationId,
              sessionId,
            },
          },
        },
      })
    },
  })
  useEffect(() => {
    if (notificationTrigger) {
      setNotificationTriggerID(notificationTrigger.id)
      notificationTrigger.notificationTemplateChannels.edges.forEach(
        (notificationTemplateChannel) => {
          if (notificationTemplateChannel.node.channelType === 'Email') {
            setNotificationTemplateChannelID(
              notificationTemplateChannel.node.id
            )
          }
        }
      )
    }
  }, [notificationTrigger])
  return (
    <>
      <div className="testEmailNotificationForm mt-2">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Form.Group as={Col} xs={12} md={4} className="mb-0">
              <Form.Control
                type="text"
                name="email"
                placeholder="Email address"
                className="form-control-sm"
                value={formik.values.email}
                onChange={formik.handleChange}
                isInvalid={formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Col md="2">
              <div style={{ marginTop: '-9px' }}>
                <Button
                  type="submit"
                  variant="outline-primary"
                  size="sm"
                  block
                  disabled={submitting}
                  // className="d-flex align-items-center"
                >
                  <Envelope className="mr-2" /> Send Test Email
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default EmailNotificationTestForm
