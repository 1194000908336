import React, { useState } from 'react'
import { Badge, Button, Form, Row, Col } from 'react-bootstrap'
import { Plug, Cash, Trash } from 'react-bootstrap-icons'
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client'
import IntegrationCard from '../IntegrationCard'
import toast from 'react-hot-toast'
import DisableStripeModal from './DisableStripeModal'

const StripeIntegration = () => {
  const [connectedAccountId, setConnectedAccountId] = useState('')
  const [showDisableModal, setShowDisableModal] = useState(false)

  const [updateStripeConnector] = useMutation(
    gql`
      mutation UpdateStripeConnector($input: UpdateStripeConnectorInput!) {
        updateStripeConnector(input: $input) {
          stripeConnector {
            id
            connectedAccountId
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        const connector = data.updateStripeConnector.stripeConnector
        if (connector.connectedAccountId) {
          toast.success('Stripe Enabled')
          setConnectedAccountId('')
        } else {
          toast.success('Stripe Disabled')
        }
      },
      onError: () => {
        toast.error('An error occurred while configuring Stripe.')
      },
      refetchQueries: ['StripeConnectorQuery'],
    }
  )

  const [stripeConnectedAccountUrlQuery] = useLazyQuery(
    gql`
      query StripeConnectedAccountQuery {
        stripeConnectedAccountUrl {
          url
        }
      }
    `,
    {
      onCompleted: (data) => {
        window.open(data.stripeConnectedAccountUrl.url, '_blank')
      },
      fetchPolicy: 'network-only',
    }
  )

  const [stripeExpressDashboardUrlQuery] = useLazyQuery(
    gql`
      query StripeExpressDashboardQuery {
        stripeExpressDashboardUrl {
          url
        }
      }
    `,
    {
      onCompleted: (data) => {
        window.open(data.stripeExpressDashboardUrl.url, '_blank')
      },
      fetchPolicy: 'network-only',
    }
  )

  const { data, error } = useQuery(
    gql`
      query StripeConnectorQuery {
        stripeConnectors {
          edges {
            node {
              id
              connectedAccountId
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  if (!data) return null
  if (error) return <div>Error loading Stripe integration.</div>

  const stripeConnector = data.stripeConnectors.edges[0].node
  const stripeEnabled = !!stripeConnector.connectedAccountId

  const handleConnectStripeClick = () => {
    toast.success('Opening Stripe Connect')
    stripeConnectedAccountUrlQuery()
  }

  const handleStripeDashboardClick = () => {
    toast.success('Opening Stripe Connect')
    stripeExpressDashboardUrlQuery()
  }

  return (
    <IntegrationCard
      logo={`${window.location.origin}/stripe-logo.png`}
      name="Stripe"
      description="Integrate Stripe to securely process booking fees and invoices. Manage payments and financial transactions efficiently through Stripe's platform."
    >
      <Row>
        <Col>
          {stripeEnabled ? (
            <Badge variant="success">Enabled</Badge>
          ) : (
            <Badge variant="danger">Disabled</Badge>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          {stripeEnabled ? (
            <>
              <Button variant="link" onClick={handleStripeDashboardClick}>
                <Cash className="mr-2" /> Stripe Dashboard
              </Button>
              <Button variant="link" onClick={() => setShowDisableModal(true)}>
                <Trash className="mr-2" /> Disable Stripe
              </Button>
            </>
          ) : (
            <>
              <Button variant="link" onClick={handleConnectStripeClick}>
                <Cash className="mr-2" /> Connect Stripe
              </Button>
              <Form inline className="mt-2">
                <Form.Control
                  type="text"
                  placeholder="Stripe Connected Account ID"
                  value={connectedAccountId}
                  onChange={(e) => setConnectedAccountId(e.target.value)}
                  className="mr-2"
                />
                <Button
                  variant="link"
                  onClick={() => {
                    updateStripeConnector({
                      variables: {
                        input: {
                          stripeConnectorInput: {
                            id: stripeConnector.id,
                            connectedAccountId,
                          },
                        },
                      },
                    })
                  }}
                >
                  <Plug className="mr-2" /> Configure
                </Button>
              </Form>
            </>
          )}
        </Col>
      </Row>

      <DisableStripeModal
        show={showDisableModal}
        onHide={() => setShowDisableModal(false)}
        stripeConnectorId={stripeConnector.id}
      />
    </IntegrationCard>
  )
}

export default StripeIntegration
