import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import {
  Trash,
  CloudArrowDown,
  PlusCircle,
  Dot,
  FileEarmarkText,
  Folder,
  Search,
  Gear,
} from 'react-bootstrap-icons'
import { useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import FileModal from './FileModal'
import FolderModal from './FolderModal'
import Loading from '../common/Loading'
import DeleteFileModal from './DeleteFileModal'
import { loggedInUserVar } from '../../libs/apollo'
import { useParams } from 'react-router-dom'
import EditFolderConfigurationModal from './EditFolderConfigurationModal'
import { DateTime } from 'luxon'
import TagSearchInput from '../common/node_search_input/TagSearchInput'
import * as Yup from 'yup'
import { useDownload } from './DownloadContext'
import FilePreviewModal from './FilePreviewModal'
import toast from 'react-hot-toast'

const FilesTable = (props) => {
  const {
    jobId,
    jobDescription,
    organizationId,
    organizationDescription,
    subjectGroupId,
    subjectGroupDescription,
    taskId,
    sharedCanCreateFiles,
    sharedCanCreateFolders,
    organizationSubject,
    subjectId,
    sessionId,
    subjectDescription,
    employeeId,
    tableHeight,
    employeeDescription,
    fetchPolicy,
    folderId,
  } = props
  const { contact } = useParams()
  const history = useHistory()
  const location = useLocation()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const defaultOrderBy = 'name'
  const [folderId_, setFolderId] = useState(folderId)
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [loading, setLoading] = useState(false)
  const [cursor, setCursor] = useState()
  const [hasMoreSubjects, setHasMoreSubjects] = useState(true)
  const [folderItems, setFolderItems] = useState([])
  const [cantDelete, setCantDelete] = useState([])
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [switchFolders, setSwitchFolders] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showFileForm, setShowFileForm] = useState(false)
  const [showFolderForm, setShowFolderForm] = useState(false)
  const [downloadingItemId, setDownloadingItemId] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [filteredCheckedNodeIds, setFilteredCheckedNodeIds] = useState([])
  const [editNode, setEditNode] = useState()
  const [filePreview, setFilePreview] = useState()
  const [showConfigrationModal, setShowConfigrationModal] = useState(false)
  const [checkedNodeIds, setCheckedNodeIds] = useState([])
  const { downloading, startDownload } = useDownload()

  useEffect(() => {
    if (!downloading) {
      setDownloadingItemId(false)
    }
  }, [downloading])

  const formikTags = useFormik({
    initialValues: {
      tags: [],
    },
    validationSchema: Yup.object().shape({
      tags: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          description: Yup.string().nullable(),
        })
      ),
    }),
  })

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Folders(
        $cursor: String
        $search: String
        $orderBy: String
        $externallyVisible: Boolean
        $id: ID
        $root: Boolean
      ) {
        folders(id: $id, rootFolder: $root) {
          edges {
            node {
              id
              bytes
              name
              shared
              parentFolders {
                id
                name
              }
              folderItems(
                first: 40
                after: $cursor
                search: $search
                orderBy: $orderBy
                externallyVisible: $externallyVisible
              ) {
                pageInfo {
                  endCursor
                  hasNextPage
                }
                nodeCount
                edges {
                  node {
                    folder {
                      id
                      bytes
                      name
                      created
                      updated
                      contentType {
                        model
                      }
                      systemFolder
                      tags {
                        id
                        name
                      }
                      cantDelete
                      cantRename
                      externalCantRename
                      externalCantDelete
                      modifiedBy {
                        fullName
                        subject {
                          id
                        }
                        organizationContacts {
                          edges {
                            node {
                              id
                            }
                          }
                        }
                        employee {
                          id
                        }
                      }
                      externallyVisible
                    }
                    file {
                      id
                      bytes
                      cantDelete
                      cantRename
                      fileExtension
                      externalCantRename
                      externalCantDelete
                      externalCantUpdateFile
                      created
                      updated
                      fileName
                      displayName
                      contentType {
                        model
                      }
                      tags {
                        id
                        name
                      }
                      modifiedBy {
                        fullName
                        subject {
                          id
                        }
                        organizationContacts {
                          edges {
                            node {
                              id
                            }
                          }
                        }
                        employee {
                          id
                        }
                      }
                      externallyVisible
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: fetchPolicy || 'network-only',
      errorPolicy: 'all',
      pollInterval: 1000,
    }
  )

  const queryVariables = () => {
    const variables = {}
    if (contact) {
      variables.externallyVisible = true
    }
    if (searchTerm) {
      variables.search = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (subjectId) {
      variables.subject = subjectId
    }
    if (subjectGroupId) {
      variables.subjectGroup = subjectGroupId
    }
    if (subjectGroupId) {
      variables.subjectGroup = subjectGroupId
    }
    if (organizationId) {
      variables.organization = organizationId
    }
    if (taskId) {
      variables.task = taskId
    }
    if (employeeId) {
      variables.employee = employeeId
    }
    if (jobId) {
      variables.job = jobId
    }
    if (!folderId_) {
      variables.root = true
    } else {
      variables.id = folderId_
    }
    if (
      loggedInUser.permissions.isSubject ||
      loggedInUser.permissions.isContact
    ) {
      variables.externallyVisible = true
    }
    if (formikTags.values.tags.length > 0) {
      variables.tags = formikTags.values.tags
        .map((tag) => tag.description)
        .join(',')
    }

    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: queryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (switchFolders) {
      setSwitchFolders(false)
      query({ variables: queryVariables() })
    }
  }, [switchFolders])

  useEffect(() => {
    if (showDeleteModal) {
      const cantDelete_ = []
      const canDelete = []
      const items = queryData.folders.edges[0].node.folderItems.edges
      items.forEach((item) => {
        const itemType = item.node.folder ? 'folder' : 'file'
        const itemId = item.node.folder
          ? item.node.folder.id
          : item.node.file.id
        const match = checkedNodeIds.find(
          (checkedItem) =>
            checkedItem.type === itemType && checkedItem.id === itemId
        )
        if (match) {
          if (item.node.folder) {
            if (
              !item.node.folder.cantDelete &&
              !item.node.folder.systemFolder
            ) {
              canDelete.push({
                id: item.node.folder.id,
                type: 'folder',
              })
            } else {
              cantDelete_.push({
                name: item.node.folder.name,
                type: 'folder',
              })
            }
          }
          if (item.node.file) {
            if (!item.node.file.cantDelete) {
              canDelete.push({
                id: item.node.file.id,
                type: 'file',
              })
            } else {
              cantDelete_.push({
                name: item.node.file.name,
                type: 'file',
              })
            }
          }
        }
      })
      setFilteredCheckedNodeIds(canDelete)
      setCantDelete(cantDelete_)
    }
  }, [showDeleteModal])

  useEffect(() => {
    if (initialQueryRun) {
      setLoading(true)
      const variables = queryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [formikTags.values.tags])

  useEffect(() => {
    if (queryData?.folders && loading) {
      setLoading(false)
    }

    if (
      queryData?.folders?.edges.length > 0 &&
      queryData?.folders?.edges[0]?.node
    ) {
      const folder = queryData?.folders?.edges[0]?.node
      if (folder.folderItems) {
        setCursor(folder.folderItems.pageInfo.endCursor)
        setHasMoreSubjects(folder.folderItems.pageInfo.hasNextPage)
      }
      setFolderItems(folder.folderItems.edges)
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = queryVariables()
    if (cursor) {
      variables.cursor = cursor
    }
    queryFetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        if (
          fetchMoreResult?.folders?.edges.length > 0 &&
          fetchMoreResult?.folders?.edges[0]?.node
        ) {
          const folder = fetchMoreResult?.folders?.edges[0]?.node
          if (folder.folderItems) {
            setCursor(folder.folderItems.pageInfo.endCursor)
            setHasMoreSubjects(folder.folderItems.pageInfo.hasNextPage)
          }
          setFolderItems((prevState) => {
            return [...prevState, ...folder.folderItems.edges]
          })
        }
        return {}
      },
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    if (currentSearchTerm) {
      variables.search = currentSearchTerm
    } else {
      variables.search = null
    }
    setLoading(true)
    query({ variables })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return
    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = queryVariables()
    variables.orderBy = currentOrderBy
    query({ variables })
  }

  const onTdClick = (cell) => {
    if (cell.row.original.node.file) {
      toggleFileModal(cell.row.original.node.file)
    } else {
      toggleFolderForm(cell.row.original.node.folder)
    }
  }

  const toggleFileModal = (node = null) => {
    if (node) {
      setEditNode(node)
    } else {
      setEditNode()
    }
    if (showFileForm) {
      setShowFileForm(false)
    } else {
      setShowFileForm(true)
    }
  }

  const toggleFolderForm = (node = null) => {
    if (node) {
      setEditNode(node)
    } else {
      setEditNode()
    }
    if (showFolderForm) {
      setShowFolderForm(false)
    } else {
      setShowFolderForm(true)
    }
  }

  const formatBytes = (bytes) => {
    const units = ['Bytes', 'KB', 'MB', 'GB']
    let index = 0
    let value = bytes

    while (value >= 1024 && index < units.length - 1) {
      value /= 1024
      index++
    }
    const formattedValue = value.toFixed(1)
    return formattedValue.endsWith('.0')
      ? formattedValue.slice(0, -2) + ' ' + units[index]
      : formattedValue + ' ' + units[index]
  }

  const handleNodeCheck = (e, item) => {
    if (e.target.checked) {
      setCheckedNodeIds((prevState) => [...prevState, item])
    } else {
      setCheckedNodeIds((prevState) =>
        prevState.filter((existingItem) => {
          if (existingItem.type === item.type && existingItem.id === item.id) {
            return false
          } else {
            return true
          }
        })
      )
    }
  }
  const folder = queryData?.folders?.edges[0]?.node
  const tableColumns = [
    {
      Header: '',
      id: 'type',
      serverSort: false,
      accessor: (row) => {
        if (row.node.file) {
          return <FileEarmarkText size={20} />
        } else {
          return <Folder size={20} />
        }
      },
    },
    {
      Header: 'Name',
      id: 'name',
      serverSort: true,
      orderBy: 'name',
      accessor: (row) => {
        if (row.node?.file) {
          return (
            <div onClick={() => toggleFileModal(row.node.file)}>
              {row.node.file.displayName}
            </div>
          )
        } else {
          return (
            <div
              className="btn-link"
              onClick={() => {
                setFolderId(row?.node?.folder.id)
                setCheckedNodeIds([])
                setSwitchFolders(true)
              }}
            >
              {row.node?.folder?.name}
            </div>
          )
        }
      },
    },
    {
      Header: 'Last Modified',
      id: 'updated',
      serverSort: true,
      orderBy: 'updated',
      accessor: (row) => {
        const node = row.node.file ?? row.node.folder
        const dateTime = DateTime.fromISO(node.updated)
        if (loggedInUser.permissions.isEmployee && node.modifiedBy) {
          return `${node.modifiedBy.fullName} on ${dateTime.toFormat(
            'MMMM dd yyyy hh:mma'
          )}`
        }
        return dateTime.toFormat('MMMM dd yyyy hh:mma')
      },
    },
    {
      Header: folder ? (
        <>
          Size{' '}
          <span className="ml-2" style={{ fontSize: '11px' }}>
            ({formatBytes(folder.bytes)} Total)
          </span>
        </>
      ) : (
        'Size'
      ),
      id: 'size',
      serverSort: true,
      orderBy: 'bytes',
      accessor: (row) => {
        const node = row.node.file ?? row.node.folder
        if (typeof node.bytes === 'number') {
          return formatBytes(node.bytes)
        }
      },
    },
    {
      Header: 'Tags',
      id: 'tags',
      accessor: (row) => {
        const node = row.node.file ?? row.node.folder
        return (
          <>
            {node.tags.map((tag) => (
              <>
                <Dot className="mr-2" />
                {tag.name}
                <br />
              </>
            ))}
          </>
        )
      },
    },
    {
      Header: 'View',
      id: 'view',
      disableSortBy: true,
      accessor: (row) => {
        return (
          <>
            {row.node.file && (
              <Button
                variant="link"
                onClick={() => {
                  if (row.node.file) {
                    setFilePreview(row.node.file)
                  }
                }}
              >
                <Search />
              </Button>
            )}
          </>
        )
      },
    },
    {
      Header: 'Download',
      id: 'download',
      disableSortBy: true,
      accessor: (row) => {
        return (
          <>
            <Button
              variant="link"
              disabled={downloading}
              onClick={() => {
                if (row.node.file) {
                  setDownloadingItemId(row.node.file.id)
                  startDownload([
                    {
                      type: 'file',
                      id: row.node.file.id,
                    },
                  ])
                } else {
                  setDownloadingItemId(row.node.folder.id)
                  startDownload([
                    {
                      type: 'folder',
                      id: row.node.folder.id,
                    },
                  ])
                }
              }}
            >
              {downloadingItemId &&
              ((row.node.folder && row.node.folder.id === downloadingItemId) ||
                (row.node.file && row.node.file.id === downloadingItemId)) ? (
                <Loading height={'20'} />
              ) : (
                <span style={{ fontSize: '20px' }}>
                  <CloudArrowDown />
                </span>
              )}
            </Button>
          </>
        )
      },
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  folderItems.forEach((item) => {
                    const checkedItem = {
                      type: item.node.folder ? 'folder' : 'file',
                      id: item.node.folder
                        ? item.node.folder.id
                        : item.node.file.id,
                    }
                    if (!checkedNodeIds.includes(checkedItem)) {
                      appendIds.push(checkedItem)
                    }
                  })
                  setCheckedNodeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedNodeIds([])
                }
              }}
            />
            {checkedNodeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedNodeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        const node = row.node.file ?? row.node.folder
        const item = {
          type: row.node.folder ? 'folder' : 'file',
          id: node.id,
        }
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedNodeIds.some(
                  (node) => node.type === item.type && node.id === item.id
                )}
                onChange={(e) => handleNodeCheck(e, item)}
              />
            </Form.Group>
          </>
        )
      },
    },
  ]
  if (queryError) return <>Error loading files</>
  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Files..." />
        </Col>
      </Row>
    )

  if (
    folder?.shared &&
    !loggedInUser.permissions.isSubject &&
    !loggedInUser.permissions.isOrgContact
  ) {
    tableColumns.splice(tableColumns.length - 3, 0, {
      Header: 'Shared Visible',
      id: 'externallyVisible',
      serverSort: true,
      orderBy: 'externally_visible',
      accessor: (row) => {
        const node = row.node.file ?? row.node.folder
        return node.externallyVisible ? 'Yes' : 'No'
      },
    })
    tableColumns.splice(tableColumns.length - 3, 0, {
      Header: 'Shared Delete',
      id: 'externalCantDelete',
      serverSort: true,
      orderBy: 'external_cant_delete',
      accessor: (row) => {
        const node = row.node.file ?? row.node.folder
        return node.externalCantDelete ? 'No' : 'Yes'
      },
    })
    tableColumns.splice(tableColumns.length - 3, 0, {
      Header: 'Shared Rename',
      id: 'externalCantRename',
      serverSort: true,
      orderBy: 'external_cant_rename',
      accessor: (row) => {
        const node = row.node.file ?? row.node.folder
        return node.externalCantRename ? 'No' : 'Yes'
      },
    })
    tableColumns.splice(tableColumns.length - 3, 0, {
      Header: 'Shared Update File',
      id: 'externalCantUpdateFile',
      serverSort: true,
      orderBy: 'external_cant_update_file',
      accessor: (row) => {
        if (row.node.file) {
          return row.node.file.externalCantUpdateFile ? 'No' : 'Yes'
        }
      },
    })
  }
  const canShowDeleteButton = () => {
    const checkedMap = new Map(
      checkedNodeIds.map(({ type, id }) => [`${type}-${id}`, true])
    )

    return queryData.folders.edges[0].node.folderItems.edges.some((edge) => {
      const isFolder = !!edge.node.folder
      const item = isFolder ? edge.node.folder : edge.node.file
      const itemType = isFolder ? 'folder' : 'file'
      const itemKey = `${itemType}-${item.id}`

      return (
        checkedMap.has(itemKey) &&
        !item.cantDelete &&
        (isFolder ? !item.systemFolder : true)
      )
    })
  }
  return (
    <>
      <FilePreviewModal
        file={filePreview}
        showModal={filePreview}
        toggleModal={setFilePreview}
      />
      {showConfigrationModal && (
        <EditFolderConfigurationModal
          showModal
          toggleModal={() => setShowConfigrationModal(false)}
        />
      )}
      <Row
        style={
          loggedInUser.permissions.isEmployee ||
          sharedCanCreateFiles ||
          sharedCanCreateFolders ||
          checkedNodeIds.length > 0
            ? { marginTop: '-20px' }
            : { marginTop: '10px' }
        }
      >
        <Col>
          {(sharedCanCreateFiles || loggedInUser.permissions.isEmployee) && (
            <Button
              variant="link"
              onClick={() => {
                toggleFileModal()
              }}
            >
              <PlusCircle className="mr-2" />
              New Files
            </Button>
          )}
          {(sharedCanCreateFolders || loggedInUser.permissions.isEmployee) && (
            <Button
              variant="link"
              onClick={() => {
                toggleFolderForm()
              }}
            >
              <PlusCircle className="mr-2" />
              New Folder
            </Button>
          )}
          {history?.location.pathname == '/files' &&
            loggedInUser?.permissions.group == 'Administrator' && (
              <Button
                variant="link"
                onClick={() => {
                  setShowConfigrationModal(true)
                }}
              >
                <Gear className="mr-2" />
                Configure
              </Button>
            )}
          {checkedNodeIds.length > 0 && (
            <>
              {loggedInUser.permissions.isEmployee && canShowDeleteButton() && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              )}
              <Button
                variant="link"
                disabled={downloading}
                onClick={() => {
                  startDownload(checkedNodeIds)
                }}
              >
                {downloading && <Loading height={'20'} />}
                {!downloading && (
                  <>
                    <CloudArrowDown className="mr-2" />
                    Download
                  </>
                )}
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              name="searchTerm"
              className="form-control-sm"
              placeholder={'Search Files'}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <TagSearchInput
            formik={formikTags}
            multiple
            placeholder="Select Tags"
          />
        </Col>
      </Row>
      {folder?.parentFolders.length > 0 && (
        <Row className="mb-3">
          <Col>
            <nav>
              {folder.parentFolders.map((parentFolder, index) => {
                if (
                  (!employeeId || index > 1) &&
                  (!jobId ||
                    (loggedInUser.permissions.isEmployee && index > 1) ||
                    (!loggedInUser.permissions.isEmployee && index > 2)) &&
                  (!sessionId ||
                    (loggedInUser.permissions.isEmployee && index > 3) ||
                    (!loggedInUser.permissions.isEmployee && index > 4)) &&
                  (!subjectGroupId ||
                    (loggedInUser.permissions.isEmployee &&
                      loggedInUser.permissions.group !== 'General Staff' &&
                      index > 3) ||
                    (loggedInUser.permissions.isEmployee &&
                      loggedInUser.permissions.group === 'General Staff' &&
                      index > 4) ||
                    (!loggedInUser.permissions.isEmployee && index > 4)) &&
                  (!organizationId ||
                    (loggedInUser.permissions.isEmployee &&
                      loggedInUser.permissions.group !== 'General Staff' &&
                      index > 1) ||
                    (loggedInUser.permissions.isEmployee &&
                      loggedInUser.permissions.group === 'General Staff' &&
                      index > 2) ||
                    (!loggedInUser.permissions.isEmployee && index > 2)) &&
                  (!taskId ||
                    (loggedInUser.permissions.isEmployee && index > 3) ||
                    (!loggedInUser.permissions.isEmployee && index > 4)) &&
                  (!subjectId ||
                    (location?.pathname !== '/subject/settings' &&
                      organizationSubject &&
                      loggedInUser.permissions.group === 'General Staff' &&
                      loggedInUser.permissions.isEmployee &&
                      index > 4) ||
                    (location?.pathname !== '/subject/settings' &&
                      !organizationSubject &&
                      loggedInUser.permissions.isEmployee &&
                      loggedInUser.permissions.group === 'General Staff' &&
                      index > 2) ||
                    (location?.pathname !== '/subject/settings' &&
                      organizationSubject &&
                      loggedInUser.permissions.group === 'General Staff' &&
                      loggedInUser.permissions.isEmployee &&
                      index > 4) ||
                    (location?.pathname !== '/subject/settings' &&
                      !organizationSubject &&
                      loggedInUser.permissions.group !== 'General Staff' &&
                      loggedInUser.permissions.isEmployee &&
                      index > 1) ||
                    (location?.pathname !== '/subject/settings' &&
                      organizationSubject &&
                      !loggedInUser.permissions.isEmployee &&
                      index > 4) ||
                    (location?.pathname !== '/subject/settings' &&
                      !organizationSubject &&
                      !loggedInUser.permissions.isEmployee &&
                      index > 2) ||
                    (location?.pathname === '/subject/settings' &&
                      organizationSubject &&
                      index > 4) ||
                    (location?.pathname === '/subject/settings' &&
                      !organizationSubject &&
                      index > 2))
                ) {
                  let folderName = parentFolder.name
                  if (
                    (!loggedInUser.permissions.isEmployee &&
                      ((sessionId && index === 5) ||
                        (jobId && index === 3) ||
                        (subjectGroupId && index === 5) ||
                        (organizationId && index === 3) ||
                        (subjectId &&
                          ((organizationSubject && index === 5) ||
                            (!organizationSubject && index === 3))))) ||
                    (organizationId &&
                      loggedInUser.permissions.group === 'General Staff' &&
                      index == 3) ||
                    (subjectGroupId &&
                      loggedInUser.permissions.group === 'General Staff' &&
                      index == 5) ||
                    (subjectId &&
                      loggedInUser.permissions.group === 'General Staff' &&
                      organizationSubject &&
                      index == 5) ||
                    (subjectId &&
                      loggedInUser.permissions.group === 'General Staff' &&
                      !organizationSubject &&
                      index == 3)
                  ) {
                    folderName = 'Root'
                  }
                  return (
                    <span key={parentFolder.id}>
                      {' / '}
                      <span
                        className="btn-link"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setFolderId(parentFolder.id)
                          setSwitchFolders(true)
                        }}
                      >
                        {folderName}
                      </span>
                    </span>
                  )
                }
              })}
              {(!employeeId || folder.parentFolders.length > 2) &&
                (!organizationId ||
                  (loggedInUser.permissions.isEmployee &&
                    loggedInUser.permissions.group !== 'General Staff' &&
                    folder.parentFolders.length > 2) ||
                  (loggedInUser.permissions.isEmployee &&
                    loggedInUser.permissions.group === 'General Staff' &&
                    folder.parentFolders.length > 3) ||
                  (!loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 3)) &&
                (!taskId ||
                  (loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 4) ||
                  (!loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 5)) &&
                (!sessionId ||
                  (loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 4) ||
                  (!loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 5)) &&
                (!jobId ||
                  (loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 2) ||
                  (!loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 3)) &&
                (!subjectGroupId ||
                  (loggedInUser.permissions.isEmployee &&
                    loggedInUser.permissions.group !== 'General Staff' &&
                    folder.parentFolders.length > 4) ||
                  (loggedInUser.permissions.isEmployee &&
                    loggedInUser.permissions.group === 'General Staff' &&
                    folder.parentFolders.length > 5) ||
                  (!loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 5)) &&
                (!subjectId ||
                  (location?.pathname !== '/subject/settings' &&
                    organizationSubject &&
                    loggedInUser.permissions.group !== 'General Staff' &&
                    loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 4) ||
                  (location?.pathname !== '/subject/settings' &&
                    !organizationSubject &&
                    loggedInUser.permissions.group !== 'General Staff' &&
                    loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 2) ||
                  (location?.pathname !== '/subject/settings' &&
                    organizationSubject &&
                    loggedInUser.permissions.group === 'General Staff' &&
                    loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 5) ||
                  (location?.pathname !== '/subject/settings' &&
                    !organizationSubject &&
                    loggedInUser.permissions.group === 'General Staff' &&
                    loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 3) ||
                  (location?.pathname !== '/subject/settings' &&
                    organizationSubject &&
                    !loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 5) ||
                  (location?.pathname !== '/subject/settings' &&
                    !organizationSubject &&
                    !loggedInUser.permissions.isEmployee &&
                    folder.parentFolders.length > 3) ||
                  (location?.pathname === '/subject/settings' &&
                    organizationSubject &&
                    folder.parentFolders.length > 5) ||
                  (location?.pathname === '/subject/settings' &&
                    !organizationSubject &&
                    folder.parentFolders.length > 3)) && (
                  <>
                    {' / '}
                    {folder.name}
                  </>
                )}
            </nav>
          </Col>
        </Row>
      )}
      <Row className="mt-2 mb-3">
        <Col md="12">
          <SortableInfiniteTable
            loading={loading || !queryData}
            tableData={
              !folderId_ && !loggedInUser.permissions.isEmployee
                ? []
                : folderItems
            }
            loadingMessage="Loading Files..."
            tableColumns={tableColumns}
            fetchMoreTableData={fetchMore}
            tdStyleGenerator={(cell) => {
              let style = {
                cursor: 'pointer',
              }
              if (cell.column.id === 'tags') {
                style.width = '12%'
              }
              return style
            }}
            hasMoreTableData={hasMoreSubjects}
            onTdClicks={{
              associatedRecord: onTdClick,
              uploaded: onTdClick,
              tags: onTdClick,
              updated: onTdClick,
              externallyVisible: onTdClick,
              externalCantDelete: onTdClick,
              externalCantRename: onTdClick,
            }}
            tableHeight={tableHeight ? tableHeight : 800}
            rowPointer
            hideGlobalFilter
            handleSortByChange={handleSortByChange}
          />
        </Col>
      </Row>

      {showFileForm && (
        <FileModal
          folderId={folder?.id}
          showModal={showFileForm}
          toggleModal={toggleFileModal}
          file={editNode}
          jobId={jobId}
          parentShared={folder?.shared}
          jobDescription={jobDescription}
          organizationId={organizationId}
          taskId={taskId}
          organizationDescription={organizationDescription}
          subjectGroupDescription={subjectGroupDescription}
          subjectGroupId={subjectGroupId}
          subjectId={subjectId}
          subjectDescription={subjectDescription}
          employeeId={employeeId}
          employeeDescription={employeeDescription}
        />
      )}
      {showFolderForm && (
        <FolderModal
          folderId={folder?.id}
          showModal={showFolderForm}
          toggleModal={toggleFolderForm}
          folder={editNode}
          jobId={jobId}
          parentShared={folder?.shared}
          jobDescription={jobDescription}
          organizationId={organizationId}
          taskId={taskId}
          organizationDescription={organizationDescription}
          subjectGroupDescription={subjectGroupDescription}
          subjectGroupId={subjectGroupId}
          subjectId={subjectId}
          subjectDescription={subjectDescription}
          employeeId={employeeId}
          employeeDescription={employeeDescription}
        />
      )}
      {showDeleteModal && (
        <DeleteFileModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCantDelete={setCantDelete}
          cantDelete={cantDelete}
          setCheckedNodeIds={setCheckedNodeIds}
          ids={
            filteredCheckedNodeIds.length === 0
              ? []
              : filteredCheckedNodeIds.length > 1
                ? filteredCheckedNodeIds
                : null
          }
          id={
            filteredCheckedNodeIds.length === 1
              ? filteredCheckedNodeIds[0]
              : null
          }
        />
      )}
    </>
  )
}
export default FilesTable
