import React from 'react'
import { Row, Col, Collapse } from 'react-bootstrap'
import SubjectSessionsTable from '../subject_sessions/SubjectSessionsTable'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'

const AdminSubjectSessionsTable = (props) => {
  const {
    subjectId,
    setQueriesLoading,
    queriesLoading,
    setHideParentCardElement,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  return (
    <>
      <div>
        <Row>
          <Col>
            <Collapse in={true}>
              <div id="sessionsCollapse">
                <SubjectSessionsTable
                  techFlow
                  adminViewing={
                    !loggedInUser.permissions.isOrgContact ? true : false
                  }
                  subjectId={subjectId}
                  setQueriesLoading={setQueriesLoading}
                  queriesLoading={queriesLoading}
                  setHideParentCardElement={setHideParentCardElement}
                />
              </div>
            </Collapse>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AdminSubjectSessionsTable
