import React from 'react'
import { Row, Col } from 'react-bootstrap'

export default function Tracker(props) {
  const {
    isSchoolSubject,
    subjectName,
    orgName,
    subjectGroupName,
    sessionPackage,
    schedule,
    categoryName,
    stage,
  } = props

  const arrow = (
    <small className="text-success font-weight-bold mr-1">{'>'}</small>
  )

  const subjectGroupEl = (
    <>
      {stage === 'subjectGroup' ? arrow : null}
      <small className="font-weight-bold">Booking For</small>
      <small className="mb-2 d-block">
        {subjectGroupName ? subjectGroupName : ' - - -'}
      </small>
    </>
  )

  const sessionPackageEl = (
    <>
      {stage === 'package' ? arrow : null}
      <small className="font-weight-bold">Session Package</small>
      <small className="mb-2 d-block">
        {sessionPackage?.id ? (
          <>
            {`${sessionPackage.name}, $${sessionPackage.price}, ${sessionPackage.duration}`}
          </>
        ) : (
          '- - -'
        )}
      </small>
    </>
  )

  const dateTimeEl = (
    <>
      {stage === 'dateTime' ? arrow : null}
      <small className="font-weight-bold">Location and Time</small>
      <small className="mb-2 d-block">
        {schedule.dateTime ? (
          <>{`${schedule.dateTime}, ${schedule.location}`}</>
        ) : (
          '- - -'
        )}
      </small>
    </>
  )

  const sessionTypeEl = (
    <>
      <small className="font-weight-bold">Session Type</small>
      <small className="mb-2 d-block">
        {categoryName ? categoryName : '- - -'}
      </small>
    </>
  )

  let tracker = []

  if (subjectGroupName || isSchoolSubject) {
    tracker = [
      { component: subjectGroupEl, name: 'subjectGroupEl' },
      { component: sessionPackageEl, name: 'sessionPackageEl' },
      { component: dateTimeEl, name: 'dateTimeEl' },
    ]
  } else {
    tracker = [
      { component: sessionTypeEl, name: 'sessionTypeEl' },
      { component: sessionPackageEl, name: 'sessionPackageEl' },
      { component: dateTimeEl, name: 'dateTimeEl' },
    ]
  }

  return (
    <>
      <Row>
        <Col>
          <div className="border rounded">
            {subjectName ? (
              <>
                <div className="p-3">
                  <span>{subjectName && subjectName}</span>
                  <span>{orgName && ` - ${orgName}`}</span>
                </div>
                <hr style={{ margin: '0' }} />
              </>
            ) : null}
            <div className="p-3">
              {tracker.map((el) => (
                <div key={el.name}>{el.component}</div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}
