import React, { createContext, useCallback, useContext, useState } from 'react'
import { getJwtLocalStorage } from './utils'
import config from '../config'
import useWebSocket, { ReadyState } from 'react-use-websocket'

const getWsUrl = () => {
  const jwt = getJwtLocalStorage()
  let url
  if (config.ENVIRONMENT === 'development') {
    url = `ws://${window.location.hostname}:8000/ws/uploads/`
  } else if (config.ENVIRONMENT === 'qa') {
    url = 'wss://qa-api.airstudio.io/ws/uploads/'
  } else if (['development-deployed', 'srp'].includes(config.ENVIRONMENT)) {
    url = 'wss://api.airstudio.io/ws/uploads/'
  }

  if (jwt?.jwt && url) {
    return `${url}?token=${jwt.jwt}`
  }

  return url
}

export const WebSocketContext = createContext(null)

export const useWS = () => useContext(WebSocketContext)

const WebSocketProvider = ({ children }) => {
  const [shouldConnect, setShouldConnect] = useState(false)
  const [lastMessage, setLastMessage] = useState(null)

  const { sendMessage, readyState, getWebSocket } = useWebSocket(
    getWsUrl(),
    {
      shouldReconnect: () => false,
      share: true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      onOpen: () => {
        setShouldConnect(true)
      },
      onClose: () => {
        setShouldConnect(false)
      },
      onError: (error) => {
        //
      },
      onMessage: (message) => {
        setLastMessage(JSON.parse(message.data))
      },
    },
    shouldConnect
  )

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]

  const openConnection = useCallback(() => {
    setShouldConnect(true)
  }, [])

  const closeConnection = useCallback(() => {
    setShouldConnect(false)
  }, [])
  const wsUrl = getWsUrl()
  return (
    <WebSocketContext.Provider
      value={{
        openConnection,
        closeConnection,
        sendMessage,
        lastMessage,
        wsUrl,
        setLastMessage,
        connectionStatus,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  )
}

export default WebSocketProvider
