import React, { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useFormik } from 'formik'
import validator from 'validator'
import { PhoneVibrate } from 'react-bootstrap-icons'
import PhoneInput from 'react-phone-input-2'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import 'react-phone-input-2/lib/style.css'

const SMSNotificationTestForm = (props) => {
  const { notificationTrigger, subjectId, subjectGroupId, organizationId } =
    props
  const [notificationTriggerID, setNotificationTriggerID] = useState(null)
  const [notificationTemplateChannelID, setNotificationTemplateChannelID] =
    useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [testNotificationTriggerMutation] = useMutation(
    gql`
      mutation TestSMS(
        $testNotificationTriggerInput: TestNotificationTriggerInput!
      ) {
        testNotificationTrigger(input: $testNotificationTriggerInput) {
          sent
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Test ${notificationTrigger.name} SMS Sent`)
      },
      errorPolicy: 'all',
    }
  )
  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .min(2, 'Phone number is too short')
        .max(100, 'Phone number is too long')
        .required('Phone number is required')
        .test('isPhoneNumber', 'Invalid phone number', (value) => {
          let valid = true
          if (value && !validator.isMobilePhone(value, 'en-US')) {
            valid = false
          }
          return valid
        }),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      testNotificationTriggerMutation({
        variables: {
          testNotificationTriggerInput: {
            notificationTriggerTestInput: {
              id: notificationTriggerID,
              notificationTemplateChannelId: notificationTemplateChannelID,
              sendTo: `+${values.phoneNumber}`,
              subjectGroupId,
              subjectId,
              organizationId,
            },
          },
        },
      })
    },
  })
  useEffect(() => {
    if (notificationTrigger) {
      setNotificationTriggerID(notificationTrigger.id)
      notificationTrigger.notificationTemplateChannels.edges.forEach(
        (notificationTemplateChannel) => {
          if (notificationTemplateChannel.node.channelType === 'SMS') {
            setNotificationTemplateChannelID(
              notificationTemplateChannel.node.id
            )
          }
        }
      )
    }
  }, [notificationTrigger])
  return (
    <>
      <div className="testEmailNotificationForm mt-2">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Form.Group as={Col} xs={12} md={4} className="mb-0">
              <PhoneInput
                inputClass="w-100 form-control"
                country="us"
                placeholder=""
                name="phoneNumber"
                regions={['north-america']}
                value={formik.values.phoneNumber}
                onChange={(phone, country) =>
                  formik.setFieldValue('phoneNumber', phone)
                }
              />
              {formik.errors.phoneNumber ? (
                <span className="text-danger">{formik.errors.phoneNumber}</span>
              ) : null}
            </Form.Group>

            <Col md="2">
              <div style={{ marginTop: '-9px' }}>
                <Button
                  type="submit"
                  variant="outline-primary"
                  size="sm"
                  block
                  disabled={submitting}
                >
                  <PhoneVibrate className="mr-2" /> Send Test SMS
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default SMSNotificationTestForm
