import React, { useState } from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import toast from 'react-hot-toast'
import Loading from '../common/Loading'
import { Cash } from 'react-bootstrap-icons'

const ChargePaymentModal = (props) => {
  const {
    showModal,
    toggleModal,
    sessionId,
    stripePaymentMethodId,
    amount,
    gaiaUserId,
    description,
    chargeGaiaUserPaymentMethod,
    declinedStripePaymentIntentId,
  } = props
  const [loading, setLoading] = useState(false)
  const [mutation] = useMutation(
    gql`
      mutation CreateStripePaymentIntent(
        $createStripePaymentIntentInput: CreateStripePaymentIntentInput!
      ) {
        createStripePaymentIntent(input: $createStripePaymentIntentInput) {
          stripePaymentIntent {
            id
            declined
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setLoading(false)
        toggleModal()
        if (data.createStripePaymentIntent.stripePaymentIntent.declined) {
          toast.error('Payment Declined')
        } else {
          toast.success('Payment Charged')
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['StripePaymentIntentsQuery', 'SessionQuery'],
    }
  )

  if (!showModal) return <></>
  return (
    <>
      <Modal size={'md'} show={showModal} onHide={() => toggleModal()}>
        <Modal.Header closeButton>
          <Modal.Title id="new-title">Charge Session Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p style={{ fontSize: '16px' }} className="text-center">
                Are you sure you want to charge the{' '}
                {amount.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}{' '}
                session payment?
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={{ offset: 1, span: 10 }}>
              <Button
                variant="outline-primary"
                size="sm"
                block
                disabled={loading}
                onClick={() => {
                  setLoading(true)
                  mutation({
                    variables: {
                      createStripePaymentIntentInput: {
                        stripePaymentIntentInput: {
                          sessionId,
                          stripePaymentMethodId,
                          amount,
                          gaiaUserId,
                          description,
                          chargeGaiaUserPaymentMethod,
                          declinedStripePaymentIntentId,
                        },
                      },
                    },
                  })
                }}
              >
                <Cash className="mr-2" />
                Charge
              </Button>
            </Col>
          </Row>
          {loading && (
            <Row className="mt-3">
              <Col>
                <Loading />
              </Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ChargePaymentModal
