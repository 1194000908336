import React from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'

const DeleteTimesheetModal = (props) => {
  const {
    showModal,
    toggleModal,
    timesheetId,
    timesheetIds,
    setCheckedTimesheetIds,
    timesheetDeleted,
  } = props
  let _timesheetIds = [timesheetId]
  if (timesheetIds) {
    _timesheetIds = timesheetIds
  }
  const [mutation] = useMutation(
    gql`
      mutation DeleteTimesheet($input: DeleteTimesheetInput!) {
        deleteTimesheet(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        if (timesheetIds) {
          toast.success('Timesheets Deleted')
        } else {
          toast.success('Timesheet Deleted')
        }
        toggleModal()
        if (setCheckedTimesheetIds) {
          setCheckedTimesheetIds([])
        }
        if (timesheetDeleted) {
          timesheetDeleted()
        }
      },
      refetchQueries: ['TimesheetsQuery'],
      errorPolicy: 'all',
    }
  )
  const handleDeleteClick = () => {
    mutation({
      variables: {
        input: {
          inputIds: _timesheetIds,
        },
      },
    })
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="deleteLocations">
        <Modal
          size="md"
          show={showModal}
          onHide={toggleModal}
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              Delete Timesheet{timesheetIds && <>s</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  Are you sure you want to delete the Timesheet
                  {timesheetIds && <>s</>}?
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  onClick={handleDeleteClick}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DeleteTimesheetModal
