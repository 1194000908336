import React, { useRef, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useGoogleMaps } from '../../libs/googleMaps'

export default function AutocompleteAddress(props) {
  const { error, onSelect, value } = props
  const [error_, setError] = useState()
  const inputRef = useRef(null)
  const placesRef = useRef(null)
  const { googleMaps } = useGoogleMaps()

  useEffect(() => {
    if (googleMaps) {
      const options = {
        fields: ['address_components', 'geometry', 'name'],
        strictBounds: false,
        types: ['geocode'],
      }
      const Places = window.google.maps.places
      placesRef.current = new Places.Autocomplete(inputRef.current, options)
      placesRef.current.addListener('place_changed', onPlaceChange)
    }
  }, [googleMaps])

  useEffect(() => {
    setError(error)
  }, [error])

  const onPlaceChange = () => {
    const place = placesRef.current.getPlace()
    if (
      !place?.geometry?.location?.lat() ||
      !place?.geometry?.location?.lng()
    ) {
      setError('incomplete information for location')
      return
    }
    setError()
    const addressComponents = {}
    place.address_components.forEach((component) => {
      addressComponents[component.types[0]] = component.long_name
    })
    if (onSelect) {
      onSelect({
        name: place.name,
        address: addressComponents,
        location: place.geometry.location.toJSON(),
      })
    }
  }

  if (!googleMaps) return <></>
  return (
    <>
      <Form.Control
        name="autocomplete-address"
        className="form-control-sm"
        value={value}
        ref={inputRef}
        placeholder=""
        isInvalid={error_}
      />
      <Form.Control.Feedback type="invalid">{error_}</Form.Control.Feedback>
    </>
  )
}
