import React from 'react'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { PlusCircle } from 'react-bootstrap-icons'
import 'react-phone-input-2/lib/style.css'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useMutation, gql } from '@apollo/client'
import toast from 'react-hot-toast'
import Policies from '../../common/Policies'

const SettingsPoliciesModal = (props) => {
  const { settings, showModal, toggleModal } = props

  const [updateSettingsMutation] = useMutation(
    gql`
      mutation UpdateSettings($updateSettingsInput: UpdateSettingsInput!) {
        updateSettings(input: $updateSettingsInput) {
          settings {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        toast.success('Policies Updated')
        toggleModal()
      },
      errorPolicy: 'all',
      refetchQueries: ['PublicSettingsQuery', 'SettingsQuery'],
    }
  )
  const formik = useFormik({
    initialValues: {
      refundPolicy: settings.refundPolicy,
      applyNoShowPolicyFree: settings.applyNoShowPolicyFree,
      applyNoShowPolicyPaid: settings.applyNoShowPolicyPaid,
      applyPolicyFree: settings.applyPolicyFree,
      applyPolicyPaid: settings.applyPolicyPaid,
      timeRefundHours: settings.timeRefundHours,
      timeRefundFee: settings.timeRefundFee,
      resitFeeFreeSessions: settings.resitFeeFreeSessions,
      resitsIncludeFreeSessions: settings.resitsIncludeFreeSessions,
      timeRefundSessionPackageCost: settings.timeRefundSessionPackageCost,
      noShowFee: settings.noShowFee,
      resitFee: settings.resitFee,
      freePackageResitFee: settings.freePackageResitFee,
    },
    validationSchema: Yup.object().shape({
      refundPolicy: Yup.string().nullable(),
      applyNoShowPolicyFree: Yup.boolean().nullable(),
      applyNoShowPolicyPaid: Yup.boolean().nullable(),
      applyPolicyFree: Yup.boolean().nullable(),
      applyPolicyPaid: Yup.boolean().nullable(),
      timeRefundHours: Yup.number()
        .nullable()
        .test('required', 'required', (value, context) => {
          let valid = true
          if (
            context.parent.refundPolicy === 'TIME' &&
            !(typeof value === 'number')
          ) {
            valid = false
          }
          return valid
        }),
      timeRefundFee: Yup.number()
        .nullable()
        .test('required', 'required', (value, context) => {
          let valid = true
          if (
            context.parent.refundPolicy === 'TIME' &&
            !(typeof value === 'number')
          ) {
            valid = false
          } else if (
            context.parent.refundPolicy === 'NEVER' &&
            !(typeof value === 'number')
          ) {
            valid = false
          }
          return valid
        }),
      resitFeePaidSessions: Yup.boolean().nullable(),
      timeRefundSessionPackageCost: Yup.boolean().nullable(),
      resitsIncludeFreeSessions: Yup.boolean().nullable(),
      noShowFee: Yup.number()
        .nullable()
        .test('required', 'required', (value) => {
          let valid = true
          if (!(typeof value === 'number')) {
            valid = false
          }
          return valid
        }),
      resitFeeFreeSessions: Yup.boolean().nullable(),
      resitFee: Yup.number()
        .nullable()
        .test('required', 'required', (value) => {
          let valid = true
          if (!(typeof value === 'number')) {
            valid = false
          }
          return valid
        }),
      freePackageResitFee: Yup.number()
        .nullable()
        .test('required', 'required', (value, context) => {
          let valid = true
          if (
            context.parent.resitsIncludeFreeSessions &&
            !(typeof value === 'number')
          ) {
            valid = false
          }
          return valid
        }),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      updateSettingsMutation({
        variables: {
          updateSettingsInput: {
            settingsInput: {
              id: settings.id,
              refundPolicy: values.refundPolicy,
              applyNoShowPolicyFree: values.applyNoShowPolicyFree,
              applyNoShowPolicyPaid: values.applyNoShowPolicyPaid,
              applyPolicyFree: values.applyPolicyFree,
              applyPolicyPaid: values.applyPolicyPaid,
              timeRefundHours: values.timeRefundHours,
              timeRefundFee: values.timeRefundFee,
              resitFeeFreeSessions: values.resitFeeFreeSessions,
              resitFeePaidSessions: values.resitFeePaidSessions,
              resitFee: values.resitFee,
              noShowFee: values.noShowFee,
              freePackageResitFee: values.freePackageResitFee,
              resitsIncludeFreeSessions: values.resitsIncludeFreeSessions,
              timeRefundSessionPackageCost: values.timeRefundSessionPackageCost,
            },
          },
        },
      })
    },
  })

  const innerToggleModal = () => {
    toggleModal()
    formik.resetForm()
  }

  if (showModal) {
    return (
      <>
        <div className="editBusinessModal">
          <Modal
            size="lg"
            show={showModal}
            onHide={innerToggleModal}
            aria-labelledby="new-title"
          >
            <Form
              onSubmit={formik.handleSubmit}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Scheduling Policies</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Policies nodeType="settings" settingsModal formik={formik} />
                <Row className="mt-3">
                  <Col md={3}>
                    <Button type="submit" block variant="outline-primary">
                      <PlusCircle className="mr-2" />
                      Save
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Form>
          </Modal>
        </div>
      </>
    )
  }
  return <></>
}

export default SettingsPoliciesModal
