import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar, useMutation } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup, Table } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import {
  QrCode,
  Trash,
  Funnel,
  PlusCircle,
  Dot,
  CloudArrowDown,
} from 'react-bootstrap-icons'
import ItemModal from './ItemModal'
import toast from 'react-hot-toast'
import DeleteItemModal from './DeleteItemModal'
import { loggedInUserVar } from '../../../libs/apollo'
import { useDownloadFile } from '../../../libs/downloadFile'
import { formatDateTime } from '../../../libs/utils'
import ScanModal from '../ScanModal'

const Items = (props) => {
  const { employeeId, employeeName } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const defaultOrderBy = 'name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [hasMoreData, setHasMoreData] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editItem, setEditItem] = useState()
  const [checkedIds, setCheckedIds] = useState([])
  const [downloading, setDownloading] = useState(false)
  const [openScanModal, setOpenScanModel] = useState(false)
  const [scannedBags, setScannedBags] = useState([])
  const [scannedItems, setScannedItems] = useState([])
  const [filterReturned, setFilterReturned] = useState(
    employeeId ? 'checkedOut' : 'all'
  )

  const { downloadFile, downloadAndDeleteFile } = useDownloadFile()
  const [downloadItems] = useMutation(
    gql`
      mutation DownloadEquipmentItems($input: DownloadEquipmentItemInput!) {
        downloadEquipmentItem(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.downloadEquipmentItem?.file?.fileName) {
          downloadAndDeleteFile(
            data.downloadEquipmentItem.file.fileName,
            data.downloadEquipmentItem.file.displayName,
            data.downloadEquipmentItem.file.id,
            () => {
              setDownloading(false)
              toast.success('Items Downloaded')
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedIds((prevState) => prevState.filter((id) => id !== row.node.id))
    }
  }

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query EquipmentItems(
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $employeeId: String
        $returned: Boolean
      ) {
        equipmentItems(
          first: 20
          after: $cursor
          returned: $returned
          search_Icontains: $searchTerm
          orderBy: $orderBy
          employeeAssignments: $employeeId
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              equipmentBag {
                name
                id
                employee {
                  id
                }
              }
              notes
              serialNumber
              underRepair
              retire
              returned
              qrCodeFile {
                fileName
                displayName
                fileUrl
              }
              vendor
              contentType {
                model
                id
              }
              price
              employeeEmployeeJobs {
                job {
                  name
                }
                startDateTime
                endDateTime
              }
              invoiceNumber
              nextEmployeeJob {
                job {
                  name
                }
                startDateTime
                endDateTime
                employee {
                  gaiaUser {
                    fullName
                  }
                }
              }
              equipmentItemType {
                id
                name
                miscellaneous
              }
              employee {
                gaiaUser {
                  fullName
                }
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const determineReturnedVariable = () => {
    let returned
    if (filterReturned === 'checkedOut') {
      returned = false
    } else if (filterReturned === 'checkedIn') {
      returned = true
    }
    return returned
  }

  useEffect(() => {
    if (initialQueryRun) {
      const variables = constructQueryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [filterReturned])

  const constructQueryVariables = () => {
    const variables = {
      employeeId,
      returned: determineReturnedVariable(),
      cursor: queryData?.equipmentItems?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({ variables: constructQueryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.equipmentItems) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      setHasMoreData(queryData?.equipmentItems?.pageInfo?.hasNextPage)
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = constructQueryVariables()
    queryFetchMore({
      variables,
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = constructQueryVariables()
    variables.cursor = null
    variables.searchTerm = currentSearchTerm
    setLoadingSearch(true)
    query({ variables })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = constructQueryVariables()
    variables.cursor = null
    variables.orderBy = currentOrderBy
    query({ variables })
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditItem(node)
    } else {
      setEditItem()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const toggleScanModal = () => {
    setOpenScanModel((prevModal) => !prevModal)
    setScannedBags([])
    setScannedItems([])
  }

  const onTdClick = (cell) => {
    toggleModal(cell.row.original.node)
  }
  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'node.name',
      serverSort: true,
    },
    {
      Header: 'Serial Number',
      id: 'serialNumber',
      accessor: 'node.serialNumber',
      serverSort: true,
      orderBy: 'serial_number',
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: (row) => {
        let status = ''
        if (row.node.underRepair) {
          status = 'Under Repair'
        } else if (row.node.retire) {
          status = 'Retired'
        } else if (row.node.employee || row.node.equipmentBag?.employee?.id) {
          status = 'Assigned'
        } else if (
          !row.node.underRepair &&
          !row.node.retire &&
          row.node.returned
        ) {
          status = 'Available'
        } else {
          status = 'Out'
        }
        return status
      },
    },
    {
      Header: 'Checked In',
      id: 'returned',
      accessor: (row) => {
        return row.node.returned ? 'Yes' : 'No'
      },
      serverSort: true,
    },
    {
      Header: 'Long Term Assignment',
      id: 'longTermAssignment',
      accessor: (row) => {
        if (row.node.employee && !employeeId) {
          return row.node.employee.gaiaUser.fullName
        } else if (employeeId) {
          if (row.node.employee) {
            return 'Yes'
          } else {
            return 'No'
          }
        }
      },
      serverSort: true,
      orderBy: 'employee',
    },
    {
      Header: 'Next Job Assignment',
      id: 'nextJobAssignment',
      accessor: (row) => {
        if (row.node.nextEmployeeJob) {
          return (
            <div>
              <Dot className="mr-2" />
              {row.node.nextEmployeeJob.employee ? (
                <>{row.node.nextEmployeeJob.employee.gaiaUser.fullName}</>
              ) : (
                <>Unassigned</>
              )}
              <br />
              <Dot className="mr-2" />
              {row.node.nextEmployeeJob.job.name}
              <br />
              <Dot className="mr-2" />
              {formatDateTime(row.node.nextEmployeeJob.startDateTime)} -{' '}
              {formatDateTime(
                row.node.nextEmployeeJob.endDateTime,
                false,
                true
              )}
            </div>
          )
        }
      },
    },
    {
      Header: 'In Bag',
      id: 'inBag',
      accessor: (row) => {
        if (row.node.equipmentBag) {
          return row.node.equipmentBag.name
        }
      },
      serverSort: true,
      orderBy: 'equipment_bag__name',
    },
  ]
  if (canMutate) {
    tableColumns.splice(2, 0, {
      Header: 'Item Type',
      id: 'itemType',
      accessor: (row) => {
        if (row.node.equipmentItemType) {
          if (row.node.equipmentItemType.miscellaneous) {
            return `${row.node.equipmentItemType.name} (misc)`
          } else {
            return row.node.equipmentItemType.name
          }
        }
      },
      serverSort: true,
      orderBy: 'equipment_item_type__name',
    })
    tableColumns.splice(8, 0, {
      Header: 'Download',
      id: 'downloads',
      accessor: (row) => {
        return (
          <>
            <Button
              variant="link"
              disabled={downloading}
              onClick={() => {
                setDownloading(true)
                downloadFile(
                  row.node.qrCodeFile.fileName,
                  row.node.qrCodeFile.displayName,
                  () => {
                    setDownloading(false)
                    toast.success(`QR Code Downloaded`)
                  }
                )
              }}
            >
              <QrCode />
            </Button>
          </>
        )
      },
    })
    tableColumns.splice(9, 0, {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.equipmentItems?.edges.forEach((equipmentItem) => {
                    if (!checkedIds.includes(equipmentItem.node.id)) {
                      appendIds.push(equipmentItem.node.id)
                    }
                  })
                  setCheckedIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedIds([])
                }
              }}
            />
            {checkedIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedIds.includes(row.node.id)}
                onChange={(e) => handleCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }
  if (employeeId) {
    tableColumns.splice(7, 0, {
      Header: `${employeeName} Job Assignments`,
      id: 'jobAssignments',
      accessor: (row) => {
        if (row.node.employeeEmployeeJobs.length > 0) {
          return (
            <div
              style={{
                maxHeight: '75px',
                maxWidth: '300px',
                overflowY: 'scroll',
                overflowX: 'hidden',
                fontSize: '12px',
              }}
              className="mt-2"
            >
              <Table className="compact-table table-bordered" responsive>
                <thead>
                  <tr>
                    <th>Job</th>
                    <th>Shift</th>
                  </tr>
                </thead>
                <tbody>
                  {row.node.employeeEmployeeJobs.map((empJob, i) => (
                    <tr key={i}>
                      <td>{empJob.job.name}</td>
                      <td>
                        {formatDateTime(empJob.startDateTime)} -{' '}
                        {formatDateTime(empJob.endDateTime, false, true)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )
        }
      },
    })
  }

  const FilterButton = () => (
    <Button
      variant="link"
      onClick={() => {
        setFilterReturned((prevFilter) => {
          const filterStates = ['all', 'checkedOut', 'checkedIn']
          const currentIndex = filterStates.indexOf(prevFilter)
          return filterStates[(currentIndex + 1) % filterStates.length]
        })
      }}
    >
      <span>
        <Funnel className="mr-2" />
        {filterReturned === 'all' && 'All'}
        {filterReturned === 'checkedOut' && 'Checked Out'}
        {filterReturned === 'checkedIn' && 'Returned'}
      </span>
    </Button>
  )

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      <div>
        <>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="searchTerm"
                  className="form-control-sm"
                  placeholder={'Search Items'}
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
              </Form.Group>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              {employeeId && (
                <>
                  <Button variant="link" onClick={toggleScanModal}>
                    <PlusCircle className="mr-2" />
                    Check In/Out
                  </Button>
                  <FilterButton />
                </>
              )}
              {!employeeId && (
                <div>
                  <Button
                    variant="link"
                    onClick={() => {
                      toggleModal()
                    }}
                  >
                    <PlusCircle className="mr-2" />
                    New Item
                  </Button>
                  <FilterButton />
                  {checkedIds.length > 0 && (
                    <>
                      <Button
                        variant="link"
                        disabled={downloading}
                        onClick={() => {
                          setDownloading(true)
                          downloadItems({
                            variables: {
                              input: {
                                equipmentItemIds: checkedIds,
                                type: 'pdf',
                              },
                            },
                          })
                        }}
                      >
                        <>
                          <CloudArrowDown className="mr-2" />
                          Download PDF
                        </>
                      </Button>
                      <Button
                        variant="link"
                        disabled={downloading}
                        onClick={() => {
                          setDownloading(true)
                          downloadItems({
                            variables: {
                              input: {
                                equipmentItemIds: checkedIds,
                                type: 'xlsx',
                              },
                            },
                          })
                        }}
                      >
                        <>
                          <CloudArrowDown className="mr-2" />
                          Download Excel
                        </>
                      </Button>
                      <Button
                        variant="link"
                        onClick={() => {
                          setShowDeleteModal(true)
                        }}
                      >
                        <Trash className="mr-2" />
                        {checkedIds.length === 1 ? (
                          <>Delete Item</>
                        ) : (
                          <>Delete Items</>
                        )}
                      </Button>
                    </>
                  )}
                </div>
              )}
            </Col>
          </Row>
          <Row className="mt-2 mb-3">
            <Col md="12">
              <SortableInfiniteTable
                tableData={
                  queryData?.equipmentItems?.edges
                    ? queryData?.equipmentItems?.edges
                    : []
                }
                loadingMessage="Loading Items..."
                tableColumns={tableColumns}
                loading={loadingSearch || !queryData}
                fetchMoreTableData={fetchMore}
                hasMoreTableData={hasMoreData}
                onTdClicks={{
                  longTermAssignment: (cell) => onTdClick(cell),
                  returned: (cell) => onTdClick(cell),
                  name: (cell) => onTdClick(cell),
                  itemType: (cell) => onTdClick(cell),
                  category: (cell) => onTdClick(cell),
                  jobAssignments: (cell) => onTdClick(cell),
                  nextJobAssignment: (cell) => onTdClick(cell),
                  status: (cell) => onTdClick(cell),
                  serialNumber: (cell) => onTdClick(cell),
                }}
                tableHeight={700}
                rowPointer
                hideGlobalFilter
                handleSortByChange={handleSortByChange}
              />
            </Col>
          </Row>
        </>
        {showForm ? (
          <ItemModal
            showModal={showForm}
            item={editItem}
            employeeId={employeeId}
            employeeName={employeeName}
            toggleModal={toggleModal}
          />
        ) : null}
        <DeleteItemModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedIds={setCheckedIds}
          ids={checkedIds.length > 1 ? checkedIds : null}
          id={checkedIds.length === 1 ? checkedIds[0] : null}
        />
        {openScanModal && (
          <ScanModal
            showModal={openScanModal}
            employeeId={employeeId}
            hideDropdown={true}
            toggleModal={toggleScanModal}
            setScannedBags={setScannedBags}
            scannedBags={scannedBags}
            setScannedItems={setScannedItems}
            scannedItems={scannedItems}
          />
        )}
      </div>
    </>
  )
}
export default Items
