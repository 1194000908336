import React, { useState, useEffect } from 'react'
import { Form, Table, Alert, Col, Row } from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client'
import Loading from '../Loading'
import { Trash } from 'react-bootstrap-icons'
import InfiniteScroll from 'react-infinite-scroll-component'

function toTitleCase(str) {
  // Add a space before uppercase letters in camelCase strings
  const spacedString = str.replace(/([a-z])([A-Z])/g, '$1 $2')

  // Convert to title case
  return spacedString
    .split(' ') // Split by spaces
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter
    .join(' ') // Join back into a single string
}

const SearchInput = (props) => {
  const {
    formik,
    nodeNamePlural,
    nodeName,
    disabled,
    searchDescription,
    gql,
    create,
    formatDescription,
    multiple,
    formikValue,
    variables,
    setAdditionalFields,
    dropdown,
    fetchPolicy,
    discludeResults,
    formikMultipleValue,
    error,
    placeholder,
  } = props

  const [displayResults, setDisplayResults] = useState(false)
  const [readOnly, setReadOnly] = useState(false)
  const [showCreateInput, setShowCreateInput] = useState(false)
  const [createValue, setCreateValue] = useState('')

  const [query, { data, fetchMore, error: queryError }] = useLazyQuery(gql, {
    fetchPolicy: fetchPolicy ? fetchPolicy : 'network-only',
    variables,
  })

  const formikValueFromFieldName = (path) => {
    const parts = path.replace(/\[(\w+)\]/g, '.$1').split('.')
    let current = formik.values
    for (let part of parts) {
      if (current[part] === undefined) {
        return undefined
      }
      current = current[part]
    }
    return current ? current : ''
  }

  useEffect(() => {
    if (dropdown) {
      query()
    }
  }, [dropdown])

  if (queryError) {
    return <Alert variant="danger">Error Loading</Alert>
  }

  let _discludeResults = discludeResults || []
  let _formikIdValue
  let _formikDescriptionValue
  let _formikMultipleValue
  if (formikValue) {
    _formikIdValue = `${formikValue}Id`
    _formikDescriptionValue = `${formikValue}Description`
    _formikMultipleValue = formikMultipleValue
      ? formikMultipleValue
      : `${formikValue}s`
  } else {
    _formikDescriptionValue = `${nodeName}Description`
    _formikIdValue = `${nodeName}Id`
    _formikMultipleValue = formikMultipleValue
      ? formikMultipleValue
      : nodeNamePlural
  }
  return (
    <>
      <Row className="align-items-center" style={{ position: 'relative' }}>
        {dropdown && data && data[nodeNamePlural] && (
          <>
            <Col>
              <div className="position-relative">
                <select
                  className="form-control-sm form-select"
                  disabled={disabled || showCreateInput}
                  name={_formikIdValue}
                  value={formikValueFromFieldName(_formikIdValue)}
                  onChange={(e) => {
                    const selectedNode = data[nodeNamePlural].edges.find(
                      (edge) => edge.node.id === e.target.value
                    )?.node

                    if (e.target.value === 'create') {
                      setShowCreateInput(true)
                      return
                    }

                    if (multiple) {
                      if (selectedNode) {
                        setDisplayResults(false)
                        formik.setFieldValue(
                          _formikMultipleValue,
                          [
                            {
                              description: formatDescription(selectedNode),
                              id: selectedNode.id,
                            },
                          ].concat(
                            formikValueFromFieldName(_formikMultipleValue)
                          )
                        )
                        if (setAdditionalFields) {
                          setAdditionalFields(
                            selectedNode,
                            _formikMultipleValue
                          )
                        }
                      }
                    } else {
                      formik.setFieldValue(_formikIdValue, e.target.value || '')
                      if (setAdditionalFields) {
                        setAdditionalFields(
                          selectedNode || null,
                          _formikIdValue
                        )
                      }
                    }
                  }}
                >
                  <option value="">
                    {placeholder ? placeholder : '- - -'}
                  </option>
                  {create && (
                    <option value="create" className="btn-link">
                      Add {toTitleCase(nodeName)}
                    </option>
                  )}
                  {data[nodeNamePlural].edges
                    .filter((edge) => !_discludeResults.includes(edge.node.id))
                    .filter(
                      (edge) =>
                        !multiple ||
                        !formikValueFromFieldName(_formikMultipleValue)?.some(
                          (item) => item?.id === edge.node.id
                        )
                    )
                    .map((edge) => (
                      <option key={edge.node.id} value={edge.node.id}>
                        {formatDescription(edge.node)}
                      </option>
                    ))}
                </select>
              </div>
              {error && <small className="text-danger">{error}</small>}
            </Col>
            {showCreateInput && (
              <div className="mt-2">
                <div className="input-group input-group-sm">
                  <Form.Control
                    size="sm"
                    value={createValue}
                    onChange={(e) => setCreateValue(e.target.value)}
                    placeholder={`Enter ${toTitleCase(nodeName)}`}
                    autoFocus
                    readOnly={readOnly}
                  />
                  <Col xs={'auto'}>
                    {!readOnly && (
                      <button
                        type="button"
                        className="p-0 mr-2 btn-link"
                        onClick={() => {
                          if (createValue.trim()) {
                            if (multiple) {
                              const existingValues =
                                formikValueFromFieldName(
                                  _formikMultipleValue
                                ) || []
                              const isDuplicate = existingValues.some(
                                (item) =>
                                  item.description === createValue.trim()
                              )
                              if (!isDuplicate) {
                                formik.setFieldValue(
                                  _formikMultipleValue,
                                  [
                                    {
                                      description: createValue,
                                      id: null,
                                    },
                                  ].concat(
                                    formikValueFromFieldName(
                                      _formikMultipleValue
                                    )
                                  )
                                )
                              }
                              setCreateValue('')
                              formik.setFieldValue(_formikIdValue, '')
                              setShowCreateInput(false)
                            } else {
                              formik.setFieldValue(_formikIdValue, null)
                              formik.setFieldValue(
                                _formikDescriptionValue,
                                createValue
                              )
                              setReadOnly(true)
                            }
                          }
                        }}
                      >
                        <small>Create</small>
                      </button>
                    )}
                    <button
                      type="button"
                      className="p-0 mr-2 text-danger btn-link"
                      onClick={() => {
                        setShowCreateInput(false)
                        setCreateValue('')
                        formik.setFieldValue(_formikIdValue, '')
                        setReadOnly(false)
                      }}
                    >
                      {!readOnly ? <small>Cancel</small> : <Trash />}
                    </button>
                  </Col>
                </div>
              </div>
            )}
          </>
        )}
        {!dropdown && (
          <>
            <Col>
              <Form.Control
                placeholder={
                  placeholder ? placeholder : `Search ${searchDescription}`
                }
                value={formikValueFromFieldName(_formikDescriptionValue)}
                isInvalid={error}
                onBlur={() => {
                  setDisplayResults(false)
                  formik.setFieldTouched(_formikIdValue, true)
                }}
                onChange={(e) => {
                  formik.setFieldValue(_formikDescriptionValue, e.target.value)
                  if (e.target.value) {
                    setDisplayResults(true)
                    query({
                      variables: {
                        ...variables,
                        ...{
                          first: 10,
                          search: e.target.value,
                        },
                      },
                    })
                  } else {
                    setDisplayResults(false)
                  }
                }}
                disabled={disabled}
                readOnly={
                  Boolean(formikValueFromFieldName(_formikIdValue)) || readOnly
                }
                className={'form-control-sm'}
              />
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            </Col>
            {!disabled &&
              (formikValueFromFieldName(_formikIdValue) || readOnly) && (
                <Col xs="auto">
                  <button
                    type="button"
                    className="p-0 mr-1 btn-link"
                    onClick={() => {
                      formik.setFieldValue(_formikDescriptionValue, '')
                      formik.setFieldValue(_formikIdValue, '')
                      setReadOnly(false)
                    }}
                  >
                    <Trash />
                  </button>
                </Col>
              )}
            {create &&
              !readOnly &&
              !formikValueFromFieldName(_formikIdValue) &&
              formikValueFromFieldName(_formikDescriptionValue) &&
              data &&
              data[nodeNamePlural].edges.length === 0 && (
                <Col xs="auto">
                  <button
                    type="button"
                    className="p-0 mr-1 btn-link"
                    onClick={() => {
                      if (multiple) {
                        setDisplayResults(false)
                        formik.setFieldValue(_formikDescriptionValue, '')
                        formik.setFieldValue(
                          _formikMultipleValue,
                          [
                            {
                              description: formikValueFromFieldName(
                                _formikDescriptionValue
                              ),
                              id: null,
                            },
                          ].concat(formik.values[_formikMultipleValue])
                        )
                      } else {
                        setDisplayResults(false)
                        formik.setFieldValue(
                          _formikDescriptionValue,
                          formikValueFromFieldName(_formikDescriptionValue)
                        )
                        formik.setFieldValue(_formikIdValue, null)
                        setReadOnly(true)
                      }
                    }}
                  >
                    <small>Create</small>
                  </button>
                </Col>
              )}
          </>
        )}
      </Row>
      {data && displayResults && data[nodeNamePlural].edges.length > 0 && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            zIndex: 3000,
            width: '95%',
          }}
        >
          <InfiniteScroll
            height={100}
            dataLength={data[nodeNamePlural].edges.length}
            next={() => {
              fetchMore({
                variables: {
                  ...variables,
                  ...{
                    after: data[nodeNamePlural].pageInfo.cursor,
                    first: 10,
                    search: formik.values[_formikDescriptionValue],
                  },
                },
              })
            }}
            hasMore={data[nodeNamePlural].pageInfo.hasNextPage}
            loader={<Loading />}
          >
            <Table size="sm" hover>
              <tbody>
                {data[nodeNamePlural].edges
                  .filter((edge) => !_discludeResults.includes(edge.node.id))
                  .map((edge) => {
                    const { node } = edge
                    if (
                      !multiple ||
                      !formik.values[_formikMultipleValue].some(
                        (item) => item.id === node.id
                      )
                    ) {
                      return (
                        <tr
                          onMouseDown={() => {
                            if (multiple) {
                              setDisplayResults(false)
                              formik.setFieldValue(_formikDescriptionValue, '')
                              if (setAdditionalFields) {
                                setAdditionalFields(node, _formikMultipleValue)
                              }
                              formik.setFieldValue(
                                _formikMultipleValue,
                                [
                                  {
                                    description: formatDescription(node),
                                    id: node.id,
                                  },
                                ].concat(formik.values[_formikMultipleValue])
                              )
                            } else {
                              setDisplayResults(false)
                              formik.setFieldValue(
                                _formikDescriptionValue,
                                formatDescription(node)
                              )
                              formik.setFieldValue(_formikIdValue, node.id)
                              if (setAdditionalFields) {
                                setAdditionalFields(node, _formikIdValue)
                              }
                            }
                          }}
                          key={node.id}
                          className="hover text-decoration-none"
                        >
                          <td>
                            <small>{formatDescription(node)}</small>
                          </td>
                        </tr>
                      )
                    }
                  })}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>
      )}
      {multiple &&
        formikValueFromFieldName(_formikMultipleValue).length > 0 && (
          <div className="mt-2">
            {formikValueFromFieldName(_formikMultipleValue).map((node, i) => {
              return (
                <span
                  className={i > 0 ? 'ml-1' : ''}
                  style={{
                    fontSize: '14px',
                    border: '.3px solid',
                    padding: '2px',
                    margin: '5px',
                  }}
                  key={i}
                >
                  {!disabled && (
                    <Trash
                      className="mr-2 btn-link"
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const updatedNodes = []
                        formikValueFromFieldName(_formikMultipleValue).forEach(
                          (currentNode) => {
                            if (currentNode.description !== node.description) {
                              updatedNodes.push(currentNode)
                            }
                          }
                        )
                        formik.setFieldValue(_formikMultipleValue, updatedNodes)
                      }}
                    />
                  )}
                  {node.description}
                </span>
              )
            })}
          </div>
        )}
    </>
  )
}

export default SearchInput
