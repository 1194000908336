import React from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'

const DeleteEmployeeModal = (props) => {
  const {
    showModal,
    toggleModal,
    employeeId,
    employeeIds,
    setCheckedEmployeeIds,
  } = props
  let _employeeIds = [employeeId]
  if (employeeIds) {
    _employeeIds = employeeIds
  }
  const history = useHistory()
  const [mutation] = useMutation(
    gql`
      mutation DeleteEmployeeMutation(
        $deleteEmployeeInput: DeleteEmployeeInput!
      ) {
        deleteEmployee(input: $deleteEmployeeInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        if (employeeIds) {
          toast.success('Employees Deleted')
        } else {
          toast.success('Employee Deleted')
        }
        toggleModal()
        if (setCheckedEmployeeIds) {
          setCheckedEmployeeIds([])
        }
        history.push('/employees')
      },
      refetchQueries: ['EmployeesQuery'],
      errorPolicy: 'all',
    }
  )
  const handleDeleteClick = () => {
    mutation({
      variables: {
        deleteEmployeeInput: {
          employeeIds: _employeeIds,
        },
      },
    })
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              Delete Employee{employeeIds && <>s</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  Are you sure you want to delete the employee
                  {employeeIds && <>s</>}? They will be removed from equipment
                  and job assignement.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  onClick={handleDeleteClick}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DeleteEmployeeModal
