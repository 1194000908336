import React from 'react'
import FolderTree from 'react-folder-tree'
import 'react-folder-tree/dist/style.css'
import './FolderStructure.css'
import toast from 'react-hot-toast'

const FolderStructure = (props) => {
  const {
    folderStructure,
    formik,
    formikField,
    root,
    setCachedFolderStructure,
  } = props

  const validateNoDuplicateFolders = (fileSystem) => {
    const hasDuplicateNames = (children) => {
      const names = children.map((child) => child.name)
      const uniqueNames = new Set(names)
      return names.length !== uniqueNames.size
    }

    const validateFolder = (folder) => {
      if (hasDuplicateNames(folder.children)) {
        return false
      }
      for (let i = 0; i < folder.children.length; i++) {
        if (!validateFolder(folder.children[i])) {
          return false
        }
      }

      return true
    }

    return validateFolder(fileSystem) // Start the validation with the root folder
  }

  const onTreeStateChange = (state, event) => {
    if (event.type !== 'initialization') {
      let error = false
      if (!validateNoDuplicateFolders(state)) {
        error = true
        toast.error(
          'Cannot Include Two Folders With The Same Name In The Same Parent Folder'
        )
      } else if (
        (root === 'organization' && state.name !== 'Organization') ||
        (root === 'subjectGroup' && state.name !== 'Subject Group') ||
        (root === 'subject' && state.name !== 'Subject') ||
        (root === 'job' && state.name !== 'Job') ||
        (root === 'employee' && state.name !== 'Employee')
      ) {
        toast.error('Cannot Remove Root Folder')
        error = true
      } else if (root !== 'employee') {
        if (!state.children.some((child) => child.name === 'Shared')) {
          toast.error('Cannot Remove or Rename Shared Folder')
          error = true
        } else if (
          root === 'subject' &&
          !state.children.some((child) => child.name === 'Staff Shared')
        ) {
          toast.error('Cannot Remove or Rename Staff Shared Folder')
          error = true
        } else if (
          root === 'organization' &&
          !state.children.some((child) => child.name === 'Subject Groups')
        ) {
          toast.error('Cannot Remove or Rename Subject Groups Folder')
          error = true
        } else if (
          root === 'organization' &&
          !state.children.some((child) => child.name === 'Subjects')
        ) {
          toast.error('Cannot Remove or Rename Subjects Folder')
          error = true
        } else if (
          root === 'organization' &&
          !state.children.some((child) => child.name === 'Staff Shared')
        ) {
          toast.error('Cannot Remove or Rename Staff Shared Folder')
          error = true
        } else if (
          root === 'organization' &&
          !state.children.some((child) => child.name === 'Tasks')
        ) {
          toast.error('Cannot Remove or Rename Tasks Folder')
          error = true
        } else if (
          root === 'job' &&
          !state.children.some((child) => child.name === 'Tasks')
        ) {
          toast.error('Cannot Remove or Rename Tasks Folder')
          error = true
        } else if (
          root === 'job' &&
          !state.children.some((child) => child.name === 'Sessions')
        ) {
          toast.error('Cannot Remove or Rename Sessions Folder')
          error = true
        }
      }
      if (error) {
        formik.setFieldValue(formikField, JSON.stringify(folderStructure))
      } else {
        setCachedFolderStructure(formikField, state)
      }
    }
  }

  return (
    <FolderTree
      data={folderStructure}
      showCheckbox={false}
      onChange={onTreeStateChange}
    />
  )
}

export default FolderStructure
