import React from 'react'
import { useQuery, gql, useReactiveVar } from '@apollo/client'
import SubjectSettings from './SubjectSettings'
import { loggedInUserVar } from '../../libs/apollo'

const AllSubjectSettings = () => {
  const user = useReactiveVar(loggedInUserVar)
  const userId = user?.id
  const { loading, error, data } = useQuery(
    gql`
      query SubjectChildrenQuery($gaiaUserId: ID!) {
        gaiaUser(id: $gaiaUserId) {
          subject {
            id
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        gaiaUserId: userId,
      },
      errorPolicy: 'all',
    }
  )
  if (loading || !data) return <></>
  if (error) return <p>Error loading settings</p>
  return (
    <>
      <div className="allSubjectSettings">
        <SubjectSettings userId={userId} relation={'self'} />
      </div>
    </>
  )
}

export default AllSubjectSettings
