import React, { useState } from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { Bell } from 'react-bootstrap-icons'
import SendNotificationUsersTable from './SendNotificationUsersTable'

function SendNotificationModal(props) {
  const {
    showModal,
    toggleModal,
    options,
    notificationRecipientUsers,
    setNotificationRecipientUsers,
    organizationId,
    subjectGroupId,
    jobId,
  } = props
  const [selectedNotification, setSelectedNotification] = useState()
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const handleSendClick = () => {
    const action = options.find(
      (opt) => opt.label === selectedNotification
    ).action
    if (action) {
      action()
      setSelectedNotification()
    }
  }

  const selectUsers = options.some((option) =>
    [
      'Send Sessions SMS',
      'Send Sessions Email',
      'Send Sessions Email From Your Email',
      'Send Contacts SMS',
      'Send Contacts Email',
      'Send Contacts Email From Your Email',
      'Send Employees SMS',
      'Send Employees Email',
      'Send Employees Email From Your Email',
      'Send Subjects SMS',
      'Send Subjects Email',
      'Send Subjects Email From Your Email',
      'Send Not Booked Subjects SMS',
      'Send Booked Subjects SMS',
      'Send Photographed Subjects SMS',
      'Send Not Booked Subjects Email',
      'Send Booked Subjects Email',
      'Send Photographed Subjects Email',
      'Send Not Booked Subjects Email From Your Email',
      'Send Booked Subjects Email From Your Email',
      'Send Photographer Subjects Email From Your Email',
    ].includes(option.label)
  )

  return (
    <div className="sendNotificationModal">
      <Modal
        size={selectUsers && selectedNotification ? 'xl' : 'md'}
        show={showModal}
        onHide={() => {
          setSelectedNotification()
          toggleModal()
        }}
        aria-labelledby="new-title"
        className="invmodal detail"
        closeButton
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col
              xs={12}
              md={
                selectUsers && selectedNotification ? 4 : { span: 8, offset: 2 }
              }
            >
              <select
                className="form-control-sm form-select"
                value={selectedNotification}
                onChange={(e) => {
                  setSelectedNotification(e.target.value || undefined)
                }}
              >
                <option value="">- - -</option>
                {options.map((option, index) => (
                  <option key={index} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          {selectUsers && selectedNotification && (
            <SendNotificationUsersTable
              selectedNotification={selectedNotification}
              organizationId={organizationId}
              subjectGroupId={subjectGroupId}
              showModal={showModal}
              notificationRecipientUsers={notificationRecipientUsers}
              setNotificationRecipientUsers={setNotificationRecipientUsers}
              jobId={jobId}
              setSubmitDisabled={setSubmitDisabled}
            />
          )}
          <Row className="mt-4">
            <Col md={{ span: 8, offset: 2 }}>
              <Button
                type="submit"
                size="sm"
                variant="outline-primary"
                className="mt-2"
                block
                disabled={
                  !selectedNotification || (selectUsers && submitDisabled)
                }
                onClick={handleSendClick}
              >
                <Bell className="mr-2" />
                Send
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SendNotificationModal
