import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button, Table } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { setLoginVars } from '../../libs/apollo'
import { setJwtLocalStorage } from '../../libs/utils'
import { BoxArrowInRight, Camera, Trash } from 'react-bootstrap-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../common/Loading'

export default function StudentLogin(props) {
  const { handleLoggedInStudent, colMd, loginBox } = props
  const [confirmOrganization, setConfirmOrganization] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [displayOrganizationResults, setDisplayOrganizationResults] =
    useState(false)
  const [studentForm, setStudentForm] = useState({
    lastName: '',
    studentId: '',
    organizationName: '',
    organizationId: '',
  })

  useEffect(() => {
    if (confirmOrganization) {
      toast.success('Please Confirm Your School')
    }
  }, [confirmOrganization])

  const [
    searchOrganizations,
    { data: organizationData, fetchMore: fetchMoreOrganizations },
  ] = useLazyQuery(
    gql`
      query Organizations($name: String!) {
        schoolOrganizationNames(name: $name) {
          organizations {
            name
            id
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const organizationChange = (e) => {
    setDisplayOrganizationResults(true)
    searchOrganizations({
      variables: {
        name: e.target.value,
      },
      fetchPolicy: 'network-only',
    })
    setStudentForm({
      ...studentForm,
      organizationName: e.target.value,
    })
  }

  const handleFetchMoreOrganizations = () => {
    fetchMoreOrganizations({
      variables: {
        name: studentForm.organizationName,
      },
    })
  }

  const handleOrganizationXClick = () => {
    setSubmitting(true)
    setStudentForm({
      ...studentForm,
      organizationId: '',
      organizationName: '',
    })
  }

  const handleOrganizationClick = (node) => {
    setDisplayOrganizationResults(false)
    setSubmitting(false)
    setStudentForm({
      ...studentForm,
      organizationId: node.id,
      organizationName: node.name,
    })
  }

  const [querySubjects] = useLazyQuery(
    gql`
      query SubjectStudentNodeCount($lastName: String!, $studentId: String!) {
        subjectStudentNodeCount(lastName: $lastName, studentId: $studentId) {
          nodeCount
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.subjectStudentNodeCount.nodeCount > 1) {
          setConfirmOrganization(true)
        } else {
          getStudentUser({
            variables: {
              createJwtTokenStudentInput: {
                lastName: studentForm.lastName,
                studentId: studentForm.studentId,
                organizationId: null,
              },
            },
          })
        }
      },
    }
  )

  const [getStudentUser, { loading: getStudentUserLoading }] = useMutation(
    gql`
      mutation CreateJwtTokenStudentMutations(
        $createJwtTokenStudentInput: CreateJSONWebTokenStudentInput!
      ) {
        createJwtTokenStudent(input: $createJwtTokenStudentInput) {
          token
          refreshExpiresIn
          gaiaUser {
            id
            fullName
            email
            employee {
              id
            }
            organizationContacts {
              edges {
                node {
                  id
                }
              }
            }
            subject {
              id
              subjectGroupsNotBooked {
                subjectGroup {
                  id
                  name
                  startDateTime
                  endDateTime
                }
              }
              studentId
              organization {
                id
                name
              }
            }
            groups {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    {
      onCompleted(data) {
        if (data?.createJwtTokenStudent?.gaiaUser) {
          setJwtLocalStorage(data.createJwtTokenStudent)
          setLoginVars(data.createJwtTokenStudent.gaiaUser)
          handleLoggedInStudent(
            data.createJwtTokenStudent.gaiaUser,
            data.createJwtTokenStudent.gaiaUser.subject
          )
        }
        setSubmitting(false)
      },
      onError(data) {
        setSubmitting(false)
        toast.error('Invalid Login Credentials')
      },
    }
  )

  const handleFindStudentClick = () => {
    setSubmitting(true)
    if (confirmOrganization) {
      getStudentUser({
        variables: {
          createJwtTokenStudentInput: {
            lastName: studentForm.lastName,
            studentId: studentForm.studentId,
            organizationId: studentForm.organizationId,
          },
        },
      })
    } else {
      querySubjects({
        variables: {
          lastName: studentForm.lastName,
          studentId: studentForm.studentId,
        },
      })
    }
  }

  return (
    <>
      <Row>
        <Col md={colMd ? colMd : 12}>
          <Form.Group>
            <Form.Label className="small">Last Name</Form.Label>
            <Form.Control
              className="form-control-sm"
              onChange={(e) =>
                setStudentForm({
                  ...studentForm,
                  lastName: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="small">Student ID</Form.Label>
            <Form.Control
              className="form-control-sm"
              onChange={(e) =>
                setStudentForm({
                  ...studentForm,
                  studentId: e.target.value,
                })
              }
            />
          </Form.Group>
          {confirmOrganization && (
            <>
              <Form.Group>
                <Form.Label>
                  School
                  {studentForm.organizationId && (
                    <button
                      type="button"
                      className="p-0 mr-1 btn-link ml-1"
                      onClick={handleOrganizationXClick}
                    >
                      <Trash />
                    </button>
                  )}
                </Form.Label>
                <Form.Control
                  value={studentForm.organizationName}
                  onChange={organizationChange}
                  readOnly={Boolean(studentForm.organizationId)}
                  className={
                    studentForm.organizationId
                      ? ' border border-success form-control-sm'
                      : 'form-control-sm'
                  }
                />
                {organizationData?.schoolOrganizationNames?.organizations &&
                  displayOrganizationResults && (
                    <InfiniteScroll
                      height={100}
                      style={{
                        width: '91%',
                        backgroundColor: 'white',
                        position: 'absolute',
                        zIndex: 80,
                      }}
                      dataLength={
                        organizationData?.schoolOrganizationNames?.organizations
                          ?.length
                      }
                      next={handleFetchMoreOrganizations}
                      hasMore={false}
                      loader={<Loading />}
                    >
                      <Table size="sm" hover>
                        <tbody>
                          {organizationData?.schoolOrganizationNames?.organizations?.map(
                            (org) => {
                              return (
                                <tr
                                  onMouseDown={() =>
                                    handleOrganizationClick(org)
                                  }
                                  key={org.id}
                                  className="hover text-decoration-none"
                                >
                                  <td>
                                    <small>{org.name}</small>
                                  </td>
                                </tr>
                              )
                            }
                          )}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  )}
              </Form.Group>
            </>
          )}
          <Button
            disabled={getStudentUserLoading || submitting}
            variant="link"
            size="sm"
            onClick={handleFindStudentClick}
          >
            {loginBox && (
              <>
                <BoxArrowInRight className="mr-2" /> Login
              </>
            )}
            {!loginBox && (
              <>
                <Camera className="mr-2" /> Continue
              </>
            )}
          </Button>
        </Col>
      </Row>
    </>
  )
}
