import React, { useEffect, useState, useRef } from 'react'
import {
  Trash,
  QrCode,
  PersonWorkspace,
  ExclamationTriangle,
  PlusCircle,
} from 'react-bootstrap-icons'
import moment from 'moment'
import { Field, FieldArray } from 'formik'
import { Form, Col, Row, Table, Button } from 'react-bootstrap'
import { gql, useLazyQuery, useQuery, useMutation } from '@apollo/client'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import { v4 as uuidv4 } from 'uuid'
import Loading from '../../common/Loading'
import InfiniteScroll from 'react-infinite-scroll-component'
import { toast } from 'react-hot-toast'
import ScanModal from '../../equipment/ScanModal'
import { DateTime } from 'luxon'
import EmployeeSchedulingConflictsModal from './EmployeeSchedulingConflictsModal'
import './EmployeeAssignments.css'

export default function EmployeeAssignments(props) {
  const {
    formik,
    displayStaff,
    setDisplayStaff,
    employeeAssignments,
    jobId,
    schedulingAnalystCreating,
    copyJob,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const settings = useReactiveVar(settingsVar)
  const [openScanModal, setOpenScanModel] = useState()
  const [showEquipment, setShowEquipment] = useState(copyJob ? true : false)
  const [showSchedulingConflicts, setShowSchedulingConflicts] = useState(false)
  const [schedulingConflicts, setSchedulingConflicts] = useState()
  const [displayItemsResults, setDisplayItemsResults] = useState(false)
  const [displayBagResults, setDisplayBagsResults] = useState(false)
  const [scannedBags, setScannedBags] = useState([])
  const [scannedItems, setScannedItems] = useState([])
  const [scanningEmployeeIndex, setScanningEmployeeIndex] = useState()
  const [Bag, SetBag] = useState('')
  const [equipmentSearchIndex, setEquipmentSearchIndex] = useState()
  const [itemName, SetItemName] = useState('')
  const formikInitialValues = useRef(true)
  const validTimeRegex = new RegExp(
    /^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i
  )
  const canMutate =
    ['Scheduling Manager', 'Administrator'].includes(
      loggedInUser?.permissions?.group
    ) || schedulingAnalystCreating

  const getUtcDt = (jobDateDt, timeDt) => {
    return DateTime.utc(
      jobDateDt.getFullYear(),
      jobDateDt.getMonth() + 1,
      jobDateDt.getDate(),
      timeDt.hour,
      timeDt.minute
    )
  }

  useEffect(() => {
    if (formikInitialValues.current) {
      formikInitialValues.current = false
    } else {
      if (
        formik.values.jobStartTime &&
        validTimeRegex.test(formik.values.jobStartTime)
      ) {
        const jobStartTime = moment(formik.values.jobStartTime, 'h:mmA')
        const adjustedJobStartTime = jobStartTime
          .subtract(30, 'minutes')
          .format('h:mmA')
        formik.values.employeeJobs.forEach((_, index) => {
          formik.setFieldValue(
            `employeeJobs[${index}].empJobStartTime`,
            adjustedJobStartTime
          )
        })
      }
      if (
        formik.values.jobEndTime &&
        validTimeRegex.test(formik.values.jobEndTime)
      ) {
        const jobEndTime = moment(formik.values.jobEndTime, 'h:mmA')
        const adjustedJobEndTime = jobEndTime.add(30, 'minutes').format('h:mmA')
        formik.values.employeeJobs.forEach((_, index) => {
          formik.setFieldValue(
            `employeeJobs[${index}].empJobEndTime`,
            adjustedJobEndTime
          )
        })
      }
    }
  }, [formik.values.jobEndTime, formik.values.jobStartTime])

  const [getEmployeeJobsSchedules, { data: employeeJobsSchedules }] =
    useLazyQuery(
      gql`
        query EmployeeJobsAndSchedules(
          $employees: String
          $startDateTime: DateTime!
          $endDateTime: DateTime!
          $approvalStatus: String
        ) {
          employeeJobs(
            employees: $employees
            job_StartDateTime_Lte: $endDateTime
            job_EndDateTime_Gte: $startDateTime
          ) {
            edges {
              node {
                id
                startDateTime
                endDateTime
                employee {
                  id
                }
                job {
                  id
                  name
                  startDateTime
                  endDateTime
                  location {
                    name
                    addressLineOne
                  }
                }
              }
            }
          }
          employeeSchedules(
            employees: $employees
            startDateTime_Lte: $endDateTime
            endDateTime_Gte: $startDateTime
            approvalStatuses: $approvalStatus
          ) {
            edges {
              node {
                id
                startDateTime
                endDateTime
                employee {
                  id
                }
                location {
                  name
                  addressLineOne
                }
                eventType
                approvalStatus
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )

  useEffect(() => {
    if (employeeJobsSchedules) {
      const employeesJobsSchedulesConflicts = {}
      employeeJobsSchedules.employeeJobs.edges.forEach(({ node }) => {
        if ((!jobId || node.job.id != jobId) && node.employee?.id) {
          if (node.employee.id in employeesJobsSchedulesConflicts) {
            employeesJobsSchedulesConflicts[node.employee.id].push(node)
          } else {
            employeesJobsSchedulesConflicts[node.employee.id] = [node]
          }
        }
      })
      employeeJobsSchedules.employeeSchedules.edges.forEach(({ node }) => {
        if (node.employee.id in employeesJobsSchedulesConflicts) {
          employeesJobsSchedulesConflicts[node.employee.id].push(node)
        } else {
          employeesJobsSchedulesConflicts[node.employee.id] = [node]
        }
      })
      if (Object.keys(employeesJobsSchedulesConflicts).length > 0) {
        Object.keys(employeesJobsSchedulesConflicts).forEach((employeeId) => {
          const employeeJobIndex = formik.values.employeeJobs.findIndex(
            (employeeJob) => {
              return employeeJob.employeeId === employeeId
            }
          )
          if (employeeJobIndex >= 0) {
            if (employeesJobsSchedulesConflicts[employeeId].length > 0) {
              formik.setFieldValue(
                `employeeJobs[${employeeJobIndex}].conflictingJobs`,
                employeesJobsSchedulesConflicts[employeeId]
              )
            } else {
              formik.setFieldValue(
                `employeeJobs[${employeeJobIndex}].conflictingJobs`,
                null
              )
            }
          }
        })
      } else {
        formik.values.employeeJobs.forEach((_, i) => {
          formik.setFieldValue(`employeeJobs[${i}].conflictingJobs`, null)
        })
      }
    }
  }, [employeeJobsSchedules])

  useEffect(() => {
    if (
      formik.values?.employeeJobs?.length &&
      formik.values.jobDate &&
      formik.values.jobStartTime &&
      formik.values.jobEndTime &&
      validTimeRegex.test(formik.values.jobStartTime) &&
      validTimeRegex.test(formik.values.jobEndTime)
    ) {
      const jobStartTimeToLuxon = DateTime.fromFormat(
        `${formik.values.jobStartTime}`,
        'h:ma'
      )
      const jobStartDateTime = getUtcDt(
        formik.values.jobDate,
        jobStartTimeToLuxon
      ).toISO()
      const jobEndTimeToLuxon = DateTime.fromFormat(
        `${formik.values.jobEndTime}`,
        'h:ma'
      )
      const jobEndDateTime = getUtcDt(
        formik.values.jobDate,
        jobEndTimeToLuxon
      ).toISO()
      let employeeIds
      formik.values.employeeJobs.forEach((employeeJob, i) => {
        if (!employeeJob.employeeId) {
          formik.setFieldValue(`employeeJobs[${i}].conflictingJobs`, null)
        } else {
          if (!employeeIds) {
            employeeIds = employeeJob.employeeId
          } else {
            employeeIds = `${employeeIds},${employeeJob.employeeId}`
          }
        }
      })
      if (employeeIds) {
        getEmployeeJobsSchedules({
          variables: {
            employees: employeeIds,
            startDateTime: jobStartDateTime,
            endDateTime: jobEndDateTime,
            eventTypes: 'EMPLOYEE_SICK,EMPLOYEE_PTO',
            approvalStatus: 'PENDING,APPROVED',
          },
        })
      } else {
        getEmployeeJobsSchedules({
          variables: {
            startDateTime: jobStartDateTime,
            endDateTime: jobEndDateTime,
            eventTypes: 'EMPLOYEE_SICK,EMPLOYEE_PTO',
            approvalStatus: 'PENDING,APPROVED',
          },
        })
      }
    }
  }, [
    formik.errors,
    formik.values.employeeJobs,
    formik.values.jobDate,
    formik.values.jobStartTime,
    formik.values.jobEndTime,
  ])

  const { data: employeeData } = useQuery(
    gql`
      query GetEmployees {
        employees(orderBy: "gaia_user__full_name") {
          edges {
            node {
              roles {
                edges {
                  node {
                    name
                    id
                  }
                }
              }
              id
              hourlyPay
              equipmentBags {
                edges {
                  node {
                    id
                    name
                    returned
                    equipmentBagType {
                      name
                      id
                    }
                  }
                }
              }
              equipmentItems {
                edges {
                  node {
                    id
                    name
                    serialNumber
                    returned
                    equipmentItemType {
                      id
                      name
                    }
                  }
                }
              }
              gaiaUser {
                email
                fullName
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [updateItem] = useMutation(
    gql`
      mutation UpdateEquipmentItemMutations(
        $updateEquipmentItemInput: UpdateEquipmentItemInput!
      ) {
        updateEquipmentItem(input: $updateEquipmentItemInput) {
          equipmentItem {
            id
            name
            returned
          }
        }
      }
    `,
    {
      refetchQueries: ['Job'],
    }
  )

  const [updateBag] = useMutation(
    gql`
      mutation UpdateEquipmentBagMutations(
        $updateEquipmentBagInput: UpdateEquipmentBagInput!
      ) {
        updateEquipmentBag(input: $updateEquipmentBagInput) {
          equipmentBag {
            id
            name
            returned
          }
        }
      }
    `,
    {
      refetchQueries: ['Job'],
    }
  )

  const { data: roleData } = useQuery(
    gql`
      query Roles {
        roles(orderBy: "name") {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [searchBags, { data: bagData, fetchMore: fetchMoreBags }] =
    useLazyQuery(
      gql`
        query EquipmentBagsQuery(
          $idsNotIn: String
          $equipmentBagsAfter: String
          $equipmentBagsFirst: Int
          $nameIcontains: String
          $startDateTime: DateTime!
          $endDateTime: DateTime!
          $employeeId: ID
        ) {
          availableEquipmentBags(
            idsNotIn: $idsNotIn
            after: $equipmentBagsAfter
            first: $equipmentBagsFirst
            nameContains: $nameIcontains
            startDateTime: $startDateTime
            endDateTime: $endDateTime
            employeeId: $employeeId
          ) {
            edges {
              node {
                id
                name
                returned
                employee {
                  id
                }
                equipmentItemTypeIds
                equipmentBagType {
                  name
                  id
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )

  const [searchItems, { data: itemsData, fetchMore: fetchMoreItems }] =
    useLazyQuery(
      gql`
        query EquipmentItems(
          $idsNotIn: String
          $equipmentItemsAfter: String
          $equipmentItemsFirst: Int
          $nameIcontains: String
          $startDateTime: DateTime!
          $endDateTime: DateTime!
          $employeeId: ID
        ) {
          availableEquipmentItems(
            idsNotIn: $idsNotIn
            after: $equipmentItemsAfter
            first: $equipmentItemsFirst
            nameContains: $nameIcontains
            startDateTime: $startDateTime
            endDateTime: $endDateTime
            employeeId: $employeeId
          ) {
            edges {
              node {
                id
                name
                returned
                serialNumber
                equipmentBag {
                  id
                  name
                }
                employee {
                  id
                }
                equipmentCategory {
                  id
                  name
                }
                equipmentItemType {
                  id
                  name
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )

  if (
    (!displayStaff && formik.values?.employeeJobs?.length > 0) ||
    !employeeData ||
    !roleData
  ) {
    return (
      <div>
        <Loading />
      </div>
    )
  }

  let sendAllNotifications = false
  let showEquipmentToggle = false
  formik.values.employeeJobs.forEach((emp) => {
    if (!showEquipmentToggle) {
      showEquipmentToggle = true
    }
    if (!sendAllNotifications && emp.employeeId) {
      sendAllNotifications = true
    }
  })
  let md = '8'
  if (employeeAssignments) {
    md = {
      span: 10,
      offset: 1,
    }
  }
  let addStaffClass = 'p-0 btn-link'
  if (formik.values.employeeJobs?.length > 0) {
    addStaffClass = 'mt-2 p-0 btn-link'
  }
  if (employeeAssignments) {
    addStaffClass = `${addStaffClass} ml-5`
  }

  const handleItemChange = (e, index) => {
    if (!e.target.value) {
      setDisplayItemsResults(false)
    } else {
      setDisplayItemsResults(true)
    }
    setEquipmentSearchIndex(index)
    SetItemName(e.target.value)
    searchItems({
      variables: {
        after: null,
        first: 10,
        startDateTime: jobStartDateTime,
        endDateTime: jobEndDateTime,
        employeeId: formik.values.employeeJobs[index].employeeId,
        idsNotIn: formik.values.employeeJobs[index].equipmentItems
          .map((item) => item.id)
          .join(','),
        nameIcontains: e.target.value,
      },
    })
  }

  const handleBagChange = (e, index) => {
    if (!e.target.value) {
      setDisplayBagsResults(false)
    } else {
      setDisplayBagsResults(true)
    }
    setEquipmentSearchIndex(index)
    SetBag(e.target.value)
    searchBags({
      variables: {
        startDateTime: jobStartDateTime,
        endDateTime: jobEndDateTime,
        after: null,
        first: 25,
        employeeId: formik.values.employeeJobs[index].employeeId,
        idsNotIn: formik.values.employeeJobs[index].equipmentBags
          .map((item) => item.id)
          .join(','),
        nameIcontains: e.target.value,
      },
    })
  }

  const handleItemClick = (node, index) => {
    setDisplayItemsResults(false)
    let longTermAssignment = false
    if (
      formik.values.employeeJobs[index].employeeId &&
      node.employee?.id &&
      node.employee?.id === formik.values.employeeJobs[index].employeeId
    ) {
      longTermAssignment = true
    }
    formik.setFieldValue(`employeeJobs[${index}].equipmentItems`, [
      ...formik.values.employeeJobs[index].equipmentItems,
      {
        id: node.id,
        name: node.name,
        longTermAssignment,
        serialNumber: node.serialNumber,
        equipmentItemType: node.equipmentItemType,
        returned: node.returned,
      },
    ])
  }

  const handleBagClick = (node, index) => {
    setDisplayBagsResults(false)
    let longTermAssignment = false
    if (
      formik.values.employeeJobs[index].employeeId &&
      node.employee?.id &&
      node.employee?.id === formik.values.employeeJobs[index].employeeId
    ) {
      longTermAssignment = true
    }
    formik.setFieldValue(`employeeJobs[${index}].equipmentBags`, [
      ...formik.values.employeeJobs[index].equipmentBags,
      {
        id: node.id,
        name: node.name,
        returned: node.returned,
        longTermAssignment,
        equipmentBagType: node.equipmentBagType,
        equipmentItemTypeIds: node.equipmentItemTypeIds,
      },
    ])
  }

  const deleteItemClick = (id, index) => {
    if (
      formik.values.employeeJobs[index].equipmentItems.find(
        (item) => item.id === id
      )
    ) {
      formik.setFieldValue(
        `employeeJobs[${index}].equipmentItems`,
        formik.values.employeeJobs[index].equipmentItems.filter(
          (item) => item.id !== id
        )
      )
    }
  }
  const deleteBagClick = (id, index) => {
    if (
      formik.values.employeeJobs[index].equipmentBags.find(
        (item) => item.id === id
      )
    ) {
      formik.setFieldValue(
        `employeeJobs[${index}].equipmentBags`,
        formik.values.employeeJobs[index].equipmentBags.filter(
          (item) => item.id !== id
        )
      )
    }
  }

  const addScanBagValidation = (equipmentBagEdges) => {
    let addToEmployee = true
    equipmentBagEdges[0].node.employeeJobs?.edges.forEach((empJob) => {
      const { node } = empJob
      if (node.job.startDateTime && node.job.endDateTime) {
        const empJobStartDateTime = DateTime.fromISO(node.job.startDateTime)
          .toUTC()
          .toISO()
        const empJobEndDateTime = DateTime.fromISO(node.job.endDateTime)
          .toUTC()
          .toISO()
        if (
          jobStartDateTime < empJobEndDateTime &&
          jobEndDateTime > empJobStartDateTime
        ) {
          addToEmployee = false
          toast.error(`Bag Already Assigned to ${node.job.name} Job`)
        }
      }
    })
    if (addToEmployee) {
      formik.values.employeeJobs.forEach((empJob) => {
        empJob.equipmentBags.forEach((bag) => {
          if (bag.id == equipmentBagEdges[0].node.id) {
            toast.error('Bag Already Assigned on Job')
            addToEmployee = false
          }
        })
      })
    }
    return addToEmployee
  }
  const addScanItemValidation = (equipmentItemEdges) => {
    let addToEmployee = true
    equipmentItemEdges[0].node.employeeJobs?.edges.forEach((empJob) => {
      const { node } = empJob
      if (node.job.startDateTime && node.job.endDateTime) {
        const empJobStartDateTime = DateTime.fromISO(node.job.startDateTime)
          .toUTC()
          .toISO()
        const empJobEndDateTime = DateTime.fromISO(node.job.endDateTime)
          .toUTC()
          .toISO()
        if (
          jobStartDateTime < empJobEndDateTime &&
          jobEndDateTime > empJobStartDateTime
        ) {
          addToEmployee = false
          toast.error(`Item Already Assigned to ${node.job.name} Job`)
        }
      }
    })
    if (addToEmployee) {
      formik.values.employeeJobs.forEach((empJob) => {
        empJob.equipmentItems.forEach((item) => {
          if (item.id == equipmentItemEdges[0].node.id) {
            toast.error('Item Already Assigned on Job')
            addToEmployee = false
          }
        })
      })
    }
    return addToEmployee
  }
  const showScanSubmitValidation = () => {
    return scannedBags.length > 0 || scannedItems.length > 0 ? true : false
  }

  let equipmentDisabled =
    !formik.values.jobEndTime ||
    formik.errors.jobEndTime ||
    !formik.values.jobStartTime ||
    formik.errors.jobStartTime ||
    !formik.values.jobDate ||
    formik.errors.jobDate
      ? true
      : false
  let jobStartDateTime
  let jobEndDateTime
  if (!equipmentDisabled) {
    const jobStartTimeToLuxon = DateTime.fromFormat(
      `${formik.values.jobStartTime}`,
      'h:ma'
    )
    jobStartDateTime = getUtcDt(
      formik.values.jobDate,
      jobStartTimeToLuxon
    ).toISO()

    const jobEndTimeToLuxon = DateTime.fromFormat(
      `${formik.values.jobEndTime}`,
      'h:ma'
    )

    jobEndDateTime = getUtcDt(formik.values.jobDate, jobEndTimeToLuxon).toISO()
  }
  const checkoutBag = (
    empJobIndex,
    bag,
    bagIndex = null,
    toastSuccess = true
  ) => {
    updateBag({
      variables: {
        updateEquipmentBagInput: {
          equipmentBagInput: {
            id: bag.id,
            returned: false,
          },
        },
      },
    })
    if (bagIndex) {
      bag.returned = false
      formik.setFieldValue(
        `employeeJobs[${empJobIndex}].equipmentBags[${bagIndex}]`,
        bag
      )
    }
    if (toastSuccess) {
      toast.success('Bag Checked Out')
    }
  }

  const checkInItem = (empJobIndex, itemIndex, item, unassign = false) => {
    updateItem({
      variables: {
        updateEquipmentItemInput: {
          equipmentItemInput: {
            id: item.id,
            returned: true,
            unassign,
          },
        },
      },
    })
    if (unassign) {
      deleteItemClick(item.id, empJobIndex)
    } else {
      item.returned = true
      formik.setFieldValue(
        `employeeJobs[${empJobIndex}].equipmentItems[${itemIndex}]`,
        item
      )
    }
    toast.success('Item Checked In')
  }

  const checkoutItem = (
    empJobIndex,
    item,
    itemIndex = null,
    toastSuccess = true
  ) => {
    updateItem({
      variables: {
        updateEquipmentItemInput: {
          equipmentItemInput: {
            id: item.id,
            returned: false,
          },
        },
      },
    })
    if (itemIndex) {
      item.returned = false
      formik.setFieldValue(
        `employeeJobs[${empJobIndex}].equipmentItems[${itemIndex}]`,
        item
      )
    }
    if (toastSuccess) {
      toast.success('Item Checked Out')
    }
  }
  const checkInBag = (empJobIndex, bagIndex, bag, unassign = false) => {
    updateBag({
      variables: {
        updateEquipmentBagInput: {
          equipmentBagInput: {
            id: bag.id,
            returned: true,
            unassign,
          },
        },
      },
    })
    if (unassign) {
      deleteBagClick(bag.id, empJobIndex)
    } else {
      bag.returned = true
      formik.setFieldValue(
        `employeeJobs[${empJobIndex}].equipmentBags[${bagIndex}]`,
        bag
      )
    }
    toast.success('Bag Checked In')
  }
  return (
    <>
      {formik.values.employeeJobs.length > 0 && !employeeAssignments && (
        <Form.Row className="mt-2" style={{ marginBottom: '-10px' }}>
          <Form.Group as={Col} md={8}>
            <h5>Employees</h5>
          </Form.Group>
        </Form.Row>
      )}
      {canMutate && sendAllNotifications && (
        <Row>
          <Col md={md}>
            <div className="d-inline small mb-5 mt-2">
              <Form.Check
                type="switch"
                id={uuidv4()}
                label="Send All Assignment Notifications"
                onChange={(e) => {
                  formik.values.employeeJobs.forEach((_, i) => {
                    if (formik.values.employeeJobs[i].employeeId) {
                      formik.setFieldValue(
                        `employeeJobs[${i}].sendNotification`,
                        e.target.checked
                      )
                    }
                  })
                }}
              />
            </div>
          </Col>
        </Row>
      )}
      {canMutate && sendAllNotifications && (
        <Row>
          <Col md={md}>
            <div className="d-inline small mb-5 mt-2">
              <Form.Check
                type="switch"
                id={uuidv4()}
                label="Send All Equipment Notifications"
                onChange={(e) => {
                  formik.values.employeeJobs.forEach((_, i) => {
                    if (formik.values.employeeJobs[i].employeeId) {
                      formik.setFieldValue(
                        `employeeJobs[${i}].sendEquipmentNotification`,
                        e.target.checked
                      )
                    }
                  })
                }}
              />
            </div>
          </Col>
        </Row>
      )}
      {canMutate &&
        showEquipmentToggle &&
        !schedulingAnalystCreating &&
        settings.tenantSubscription !== 'Basic' && (
          <Row>
            <Col md={md}>
              <Form.Check
                className={
                  !sendAllNotifications ? 'small mb-2 mt-3' : 'small mb-2'
                }
                type="switch"
                id={uuidv4()}
                label="Show Equipment"
                checked={showEquipment}
                onChange={(e) => {
                  setShowEquipment(e.target.checked)
                }}
              />
            </Col>
          </Row>
        )}

      <FieldArray
        name="employeeJobs"
        render={(arrayHelpers) => (
          <div>
            {displayStaff && (
              <Row>
                <Col md={md}>
                  <div
                    style={
                      formik.values.employeeJobs.length > 1
                        ? {
                            maxHeight: '500px',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                          }
                        : null
                    }
                    className={
                      formik.values.employeeJobs.length > 1 ? 'px-3' : ''
                    }
                  >
                    {formik.values.employeeJobs?.map(
                      (employee, empJobIndex) => {
                        if (!employee.roleId) {
                          formik.setFieldValue(
                            `employeeJobs[${empJobIndex}].roleId`,
                            roleData?.roles?.edges[0]?.node.id
                          )
                        }
                        const { employees } = employeeData
                          ? employeeData
                          : { employees: [] }
                        const availableEmployees = []
                        employees.edges?.forEach((employee) => {
                          if (employee.node.roles.edges.length > 0) {
                            employee.node.roles.edges.map((role) => {
                              if (
                                role.node.id ===
                                formik.values.employeeJobs[empJobIndex].roleId
                              ) {
                                availableEmployees.push(employee)
                              }
                            })
                          }
                        })
                        return (
                          <Row key={empJobIndex}>
                            <Col md={12}>
                              <div className="border border-secondary rounded my-2 p-3 bg-white">
                                <Form.Row>
                                  <Form.Group as={Col} md={canMutate ? 4 : 6}>
                                    <Form.Label>
                                      Employee
                                      {formik.values.employeeJobs[empJobIndex]
                                        .conflictingJobs &&
                                        formik.values.employeeJobs[empJobIndex]
                                          .employeeId && (
                                          <>
                                            <span
                                              style={{
                                                fontSize: '16px',
                                                color: 'red',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => {
                                                setSchedulingConflicts(
                                                  formik.values.employeeJobs[
                                                    empJobIndex
                                                  ].conflictingJobs
                                                )
                                                setShowSchedulingConflicts(true)
                                              }}
                                            >
                                              <ExclamationTriangle className="ml-1 mr-2" />
                                              <small>scheduling conflict</small>
                                            </span>
                                          </>
                                        )}
                                    </Form.Label>
                                    <Field
                                      as="select"
                                      disabled={!canMutate}
                                      className="form-control form-control-sm form-select"
                                      name={`employeeJobs[${empJobIndex}].employeeId`}
                                      onChange={(event) => {
                                        formik.setFieldValue(
                                          `employeeJobs[${empJobIndex}].employeeId`,
                                          event.target.value
                                        )
                                        const selectedEmployee =
                                          availableEmployees.find(
                                            (employeeObj) =>
                                              employeeObj.node.id ===
                                              event.target.value
                                          )
                                        if (selectedEmployee) {
                                          formik.setFieldValue(
                                            `employeeJobs[${empJobIndex}].employeeName`,
                                            selectedEmployee.node.gaiaUser
                                              .fullName
                                          )
                                          formik.setFieldValue(
                                            `employeeJobs[${empJobIndex}].hourlyPay`,
                                            selectedEmployee.node.hourlyPay
                                          )
                                          if (
                                            !formik.values.employeeJobs[
                                              empJobIndex
                                            ].equipmentBags
                                          ) {
                                            formik.setFieldValue(
                                              `employeeJobs[${empJobIndex}].equipmentBags`,
                                              []
                                            )
                                          }

                                          const filteredEquipmentBags =
                                            formik.values.employeeJobs[
                                              empJobIndex
                                            ].equipmentBags.filter(
                                              (bag) => !bag.longTermAssignment
                                            )

                                          const employeeEquipmentBags =
                                            selectedEmployee.node.equipmentBags.edges.map(
                                              (edge) => ({
                                                id: edge.node.id,
                                                name: edge.node.name,
                                                returned: edge.node.returned,
                                                equipmentBagType:
                                                  edge.node.equipmentBagType,
                                                longTermAssignment: true,
                                              })
                                            )
                                          const uniqueEmployeeEquipmentBags =
                                            employeeEquipmentBags.filter(
                                              (bag) =>
                                                !formik.values.employeeJobs[
                                                  empJobIndex
                                                ].equipmentBags.some(
                                                  (existingBag) =>
                                                    existingBag.id === bag.id
                                                )
                                            )

                                          formik.setFieldValue(
                                            `employeeJobs[${empJobIndex}].equipmentBags`,
                                            [
                                              ...filteredEquipmentBags,
                                              ...uniqueEmployeeEquipmentBags,
                                            ]
                                          )
                                          if (
                                            !formik.values.employeeJobs[
                                              empJobIndex
                                            ].equipmentItems
                                          ) {
                                            formik.setFieldValue(
                                              `employeeJobs[${empJobIndex}].equipmentItems`,
                                              []
                                            )
                                          }

                                          const filteredEquipmentItems =
                                            formik.values.employeeJobs[
                                              empJobIndex
                                            ].equipmentItems.filter(
                                              (item) => !item.longTermAssignment
                                            )

                                          const employeeEquipmentItems =
                                            selectedEmployee.node.equipmentItems.edges.map(
                                              (edge) => ({
                                                id: edge.node.id,
                                                name: edge.node.name,
                                                serialNumber:
                                                  edge.node.serialNumber,
                                                equipmentItemType:
                                                  edge.node.equipmentItemType,
                                                longTermAssignment: true,
                                                returned: edge.node.returned,
                                              })
                                            )

                                          const uniqueEmployeeEquipmentItems =
                                            employeeEquipmentItems.filter(
                                              (item) =>
                                                !formik.values.employeeJobs[
                                                  empJobIndex
                                                ].equipmentItems.some(
                                                  (existingItem) =>
                                                    existingItem.id === item.id
                                                )
                                            )

                                          formik.setFieldValue(
                                            `employeeJobs[${empJobIndex}].equipmentItems`,
                                            [
                                              ...filteredEquipmentItems,
                                              ...uniqueEmployeeEquipmentItems,
                                            ]
                                          )
                                        } else {
                                          formik.setFieldValue(
                                            `employeeJobs[${empJobIndex}].employeeName`,
                                            ''
                                          )
                                          formik.setFieldValue(
                                            `employeeJobs[${empJobIndex}].hourlyPay`,
                                            null
                                          )
                                          if (
                                            formik.values.employeeJobs[
                                              empJobIndex
                                            ].equipmentBags
                                          ) {
                                            const filteredEquipmentBags =
                                              formik.values.employeeJobs[
                                                empJobIndex
                                              ].equipmentBags.filter(
                                                (bag) => !bag.longTermAssignment
                                              )
                                            formik.setFieldValue(
                                              `employeeJobs[${empJobIndex}].equipmentBags`,
                                              filteredEquipmentBags
                                            )
                                          }

                                          if (
                                            formik.values.employeeJobs[
                                              empJobIndex
                                            ].equipmentItems
                                          ) {
                                            const filteredEquipmentItems =
                                              formik.values.employeeJobs[
                                                empJobIndex
                                              ].equipmentItems.filter(
                                                (item) =>
                                                  !item.longTermAssignment
                                              )
                                            formik.setFieldValue(
                                              `employeeJobs[${empJobIndex}].equipmentItems`,
                                              filteredEquipmentItems
                                            )
                                          }
                                        }
                                      }}
                                    >
                                      <option value="">Unassigned</option>
                                      {}
                                      {availableEmployees &&
                                        availableEmployees.map(
                                          (employeeObj) => {
                                            return (
                                              <option
                                                value={employeeObj.node.id}
                                                key={employeeObj.node.id}
                                              >
                                                {
                                                  employeeObj.node.gaiaUser
                                                    .fullName
                                                }
                                              </option>
                                            )
                                          }
                                        )}
                                    </Field>
                                  </Form.Group>
                                  <Form.Group as={Col} md={canMutate ? 4 : 6}>
                                    <Form.Label>Role</Form.Label>
                                    <Field
                                      as="select"
                                      disabled={!canMutate}
                                      className="form-control form-control-sm form-select"
                                      name={`employeeJobs[${empJobIndex}].roleId`}
                                    >
                                      {roleData &&
                                        roleData.roles.edges.map((roleObj) => (
                                          <option
                                            value={roleObj.node.id}
                                            key={roleObj.node.id}
                                          >
                                            {roleObj.node.name}
                                          </option>
                                        ))}
                                    </Field>
                                  </Form.Group>
                                  {canMutate && !schedulingAnalystCreating && (
                                    <Form.Group as={Col} md={canMutate ? 4 : 6}>
                                      <Form.Label>Hourly Pay</Form.Label>
                                      <Field
                                        className="form-control form-control-sm"
                                        name={`employeeJobs[${empJobIndex}].hourlyPay`}
                                      />
                                    </Form.Group>
                                  )}
                                </Form.Row>
                                <Form.Row>
                                  <Form.Group as={Col} md={3}>
                                    <Form.Label>Start Time</Form.Label>
                                    <Field
                                      className="form-control form-control-sm"
                                      name={`employeeJobs[${empJobIndex}].empJobStartTime`}
                                      disabled={!canMutate}
                                      placeholder="9:00am"
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md={3}>
                                    <Form.Label>End Time</Form.Label>
                                    <Field
                                      className="form-control form-control-sm"
                                      name={`employeeJobs[${empJobIndex}].empJobEndTime`}
                                      disabled={!canMutate}
                                      placeholder="5:00pm"
                                    />
                                  </Form.Group>
                                  {canMutate && showEquipment && (
                                    <Form.Group
                                      as={Col}
                                      md={2}
                                      style={{ marginTop: '30px' }}
                                      className="ml-4"
                                    >
                                      <button
                                        type="button"
                                        disabled={equipmentDisabled}
                                        onClick={() => {
                                          setScanningEmployeeIndex(empJobIndex)
                                          setOpenScanModel(
                                            (prevModal) => !prevModal
                                          )
                                        }}
                                        className="px-0 btn-link"
                                      >
                                        <>
                                          <QrCode />
                                          <small className="ml-2">
                                            Scan Equipment
                                          </small>
                                        </>
                                      </button>
                                    </Form.Group>
                                  )}
                                </Form.Row>

                                {canMutate && showEquipment && (
                                  <>
                                    {equipmentDisabled && (
                                      <div className="mb-2">
                                        <small
                                          style={{
                                            color: 'red',
                                          }}
                                        >
                                          <ExclamationTriangle className="mr-1 mb-1" />
                                          Add Job Start And End Times To Assign
                                          Equipment
                                        </small>
                                      </div>
                                    )}
                                    <Form.Row>
                                      <Form.Group as={Col} md={4}>
                                        <Form.Label>Equipment Items</Form.Label>
                                        <Form.Control
                                          placeholder="search"
                                          value={
                                            equipmentSearchIndex === empJobIndex
                                              ? itemName
                                              : ''
                                          }
                                          disabled={equipmentDisabled}
                                          onChange={(e) =>
                                            handleItemChange(e, empJobIndex)
                                          }
                                          className={
                                            'form-control form-control-sm'
                                          }
                                        />
                                        {itemsData &&
                                          displayItemsResults &&
                                          equipmentSearchIndex ===
                                            empJobIndex && (
                                            <div className="overlay-top">
                                              <InfiniteScroll
                                                height={100}
                                                dataLength={
                                                  itemsData
                                                    .availableEquipmentItems
                                                    .edges.length
                                                }
                                                next={() => {
                                                  fetchMoreItems({
                                                    variables: {
                                                      after:
                                                        itemsData
                                                          .availableEquipmentItems
                                                          .pageInfo.endCursor,
                                                      first: 10,
                                                      nameIcontains: itemName,
                                                      startDateTime:
                                                        jobStartDateTime,
                                                      endDateTime:
                                                        jobEndDateTime,
                                                      idsNotIn:
                                                        formik.values.employeeJobs[
                                                          empJobIndex
                                                        ].equipmentItems
                                                          .map(
                                                            (item) => item.id
                                                          )
                                                          .join(','),
                                                    },
                                                  })
                                                }}
                                                hasMore={
                                                  itemsData
                                                    ?.availableEquipmentItems
                                                    .pageInfo.hasNextPage
                                                }
                                                loader={<Loading />}
                                              >
                                                <Table size="sm" hover>
                                                  <tbody>
                                                    {itemsData.availableEquipmentItems.edges.map(
                                                      (item) => {
                                                        const { node } = item
                                                        return (
                                                          <tr
                                                            onMouseDown={() =>
                                                              handleItemClick(
                                                                node,
                                                                empJobIndex
                                                              )
                                                            }
                                                            key={node.id}
                                                            className="hover text-decoration-none"
                                                          >
                                                            <td>
                                                              <small>
                                                                {node.name}{' '}
                                                                {node.serialNumber && (
                                                                  <>
                                                                    {' '}
                                                                    #
                                                                    {
                                                                      node.serialNumber
                                                                    }
                                                                  </>
                                                                )}{' '}
                                                                {node.equipmentItemType && (
                                                                  <>
                                                                    {' '}
                                                                    |{' '}
                                                                    {
                                                                      node
                                                                        .equipmentItemType
                                                                        .name
                                                                    }
                                                                  </>
                                                                )}
                                                              </small>
                                                            </td>
                                                          </tr>
                                                        )
                                                      }
                                                    )}
                                                  </tbody>
                                                </Table>
                                              </InfiniteScroll>
                                            </div>
                                          )}
                                        <Form.Group as={Row}>
                                          {formik.values.employeeJobs[
                                            empJobIndex
                                          ].equipmentItems.length > 0 && (
                                            <div
                                              style={{
                                                maxHeight: '100px',
                                                overflowY: 'scroll',
                                                overflowX: 'hidden',
                                                fontSize: '12px',
                                              }}
                                              className="mt-2"
                                            >
                                              <Table
                                                className="compact-table table-bordered"
                                                responsive
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Item</th>
                                                    <th>Check In / Out</th>
                                                    <th>Remove</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {formik.values.employeeJobs[
                                                    empJobIndex
                                                  ].equipmentItems.length >
                                                    0 && (
                                                    <>
                                                      {formik.values.employeeJobs[
                                                        empJobIndex
                                                      ].equipmentItems?.map(
                                                        (item, itemIndex) => (
                                                          <tr key={item.id}>
                                                            <td>
                                                              {item.longTermAssignment && (
                                                                <>
                                                                  {' '}
                                                                  <PersonWorkspace />{' '}
                                                                </>
                                                              )}
                                                              {item.name}
                                                            </td>
                                                            <td>
                                                              {item.returned ? (
                                                                <span
                                                                  className="ml-1 btn-link"
                                                                  style={{
                                                                    fontSize:
                                                                      '12px',
                                                                    cursor:
                                                                      'pointer',
                                                                  }}
                                                                  onClick={() =>
                                                                    checkoutItem(
                                                                      empJobIndex,
                                                                      item,
                                                                      itemIndex
                                                                    )
                                                                  }
                                                                >
                                                                  Check Out
                                                                </span>
                                                              ) : (
                                                                <>
                                                                  <span
                                                                    className="ml-1 btn-link"
                                                                    style={{
                                                                      fontSize:
                                                                        '11px',
                                                                      cursor:
                                                                        'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                      checkInItem(
                                                                        empJobIndex,
                                                                        itemIndex,
                                                                        item
                                                                      )
                                                                    }
                                                                  >
                                                                    Check In
                                                                  </span>
                                                                  <br />
                                                                  <span
                                                                    className="ml-1 btn-link"
                                                                    style={{
                                                                      fontSize:
                                                                        '11px',
                                                                      cursor:
                                                                        'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                      checkInItem(
                                                                        empJobIndex,
                                                                        itemIndex,
                                                                        item,
                                                                        true
                                                                      )
                                                                    }
                                                                  >
                                                                    Check In and
                                                                    Unassign
                                                                  </span>
                                                                </>
                                                              )}
                                                            </td>
                                                            <td>
                                                              <Trash
                                                                style={{
                                                                  cursor:
                                                                    'pointer',
                                                                }}
                                                                className="ml-2 btn-link"
                                                                onClick={() =>
                                                                  deleteItemClick(
                                                                    item.id,
                                                                    empJobIndex
                                                                  )
                                                                }
                                                              />
                                                            </td>
                                                          </tr>
                                                        )
                                                      )}
                                                    </>
                                                  )}
                                                </tbody>
                                              </Table>
                                            </div>
                                          )}
                                        </Form.Group>
                                      </Form.Group>
                                      <Form.Group as={Col} md={4}>
                                        <Form.Label>Equipment Bags</Form.Label>
                                        <Form.Control
                                          placeholder="search"
                                          value={
                                            equipmentSearchIndex === empJobIndex
                                              ? Bag
                                              : ''
                                          }
                                          disabled={equipmentDisabled}
                                          onChange={(e) =>
                                            handleBagChange(e, empJobIndex)
                                          }
                                          className={
                                            'form-control form-control-sm'
                                          }
                                        />
                                        {bagData &&
                                          displayBagResults &&
                                          equipmentSearchIndex ===
                                            empJobIndex && (
                                            <div className="overlay-top">
                                              <InfiniteScroll
                                                height={100}
                                                dataLength={
                                                  bagData.availableEquipmentBags
                                                    ?.edges.length
                                                }
                                                next={() => {
                                                  fetchMoreBags({
                                                    variables: {
                                                      after:
                                                        bagData
                                                          .availableEquipmentBags
                                                          .pageInfo.endCursor,
                                                      first: 25,
                                                      nameIcontains: Bag,
                                                      startDateTime:
                                                        jobStartDateTime,
                                                      endDateTime:
                                                        jobEndDateTime,
                                                      idsNotIn:
                                                        formik.values.employeeJobs[
                                                          empJobIndex
                                                        ].equipmentBags
                                                          .map(
                                                            (item) => item.id
                                                          )
                                                          .join(','),
                                                    },
                                                  })
                                                }}
                                                hasMore={
                                                  bagData
                                                    ?.availableEquipmentBags
                                                    ?.pageInfo.hasNextPage
                                                }
                                                loader={<Loading />}
                                              >
                                                <Table size="sm" hover>
                                                  <tbody>
                                                    {bagData.availableEquipmentBags.edges.map(
                                                      (bag) => {
                                                        const { node } = bag
                                                        return (
                                                          <tr
                                                            onMouseDown={() =>
                                                              handleBagClick(
                                                                node,
                                                                empJobIndex
                                                              )
                                                            }
                                                            key={node.id}
                                                            className="hover text-decoration-none"
                                                          >
                                                            <td>
                                                              <small>
                                                                {node.name}
                                                                {node.equipmentBagType && (
                                                                  <>
                                                                    {' '}
                                                                    |{' '}
                                                                    {
                                                                      node
                                                                        .equipmentBagType
                                                                        .name
                                                                    }
                                                                  </>
                                                                )}
                                                              </small>
                                                            </td>
                                                          </tr>
                                                        )
                                                      }
                                                    )}
                                                  </tbody>
                                                </Table>
                                              </InfiniteScroll>
                                            </div>
                                          )}
                                        <Form.Group as={Row}>
                                          {formik.values.employeeJobs[
                                            empJobIndex
                                          ].equipmentBags.length > 0 && (
                                            <div
                                              style={{
                                                maxHeight: '100px',
                                                overflowY: 'scroll',
                                                overflowX: 'hidden',
                                                fontSize: '12px',
                                              }}
                                              className="mt-2"
                                            >
                                              <Table
                                                className="compact-table table-bordered"
                                                responsive
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Bag</th>
                                                    <th>Long Term</th>
                                                    <th>Check In / Out</th>
                                                    <th>Remove</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {formik.values.employeeJobs[
                                                    empJobIndex
                                                  ].equipmentBags.length >
                                                    0 && (
                                                    <>
                                                      {formik.values.employeeJobs[
                                                        empJobIndex
                                                      ].equipmentBags?.map(
                                                        (bag, bagIndex) => (
                                                          <tr key={bag.id}>
                                                            <td>{bag.name}</td>
                                                            <td>
                                                              {bag.longTermAssignment &&
                                                                'Yes'}
                                                            </td>
                                                            <td>
                                                              {bag.returned ? (
                                                                <>
                                                                  <span
                                                                    className="ml-1 btn-link"
                                                                    style={{
                                                                      fontSize:
                                                                        '17px',
                                                                      cursor:
                                                                        'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                      checkoutBag(
                                                                        empJobIndex,
                                                                        bag,
                                                                        bagIndex
                                                                      )
                                                                    }
                                                                  >
                                                                    <small>
                                                                      Check Out
                                                                    </small>
                                                                  </span>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <span
                                                                    className="ml-1 btn-link"
                                                                    style={{
                                                                      fontSize:
                                                                        '11px',
                                                                      cursor:
                                                                        'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                      checkInBag(
                                                                        empJobIndex,
                                                                        bagIndex,
                                                                        bag
                                                                      )
                                                                    }
                                                                  >
                                                                    Check In
                                                                  </span>
                                                                  <br />
                                                                  <span
                                                                    className="ml-1 btn-link"
                                                                    style={{
                                                                      fontSize:
                                                                        '11px',
                                                                      cursor:
                                                                        'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                      checkInBag(
                                                                        empJobIndex,
                                                                        bagIndex,
                                                                        bag,
                                                                        true
                                                                      )
                                                                    }
                                                                  >
                                                                    Check In and
                                                                    Unassign
                                                                  </span>
                                                                </>
                                                              )}
                                                            </td>
                                                            <td>
                                                              <Trash
                                                                style={{
                                                                  cursor:
                                                                    'pointer',
                                                                }}
                                                                className="ml-2 btn-link"
                                                                onClick={() =>
                                                                  deleteBagClick(
                                                                    bag.id,
                                                                    empJobIndex
                                                                  )
                                                                }
                                                              />
                                                            </td>
                                                          </tr>
                                                        )
                                                      )}
                                                    </>
                                                  )}
                                                </tbody>
                                              </Table>
                                            </div>
                                          )}
                                        </Form.Group>
                                      </Form.Group>
                                      <Form.Group as={Col} md={3}>
                                        <Form.Label>
                                          Equipment Pickup Area
                                        </Form.Label>
                                        {formik.values.employeeJobs &&
                                        formik.values.employeeJobs[empJobIndex]
                                          .equipmentItems.length === 0 &&
                                        formik.values.employeeJobs[empJobIndex]
                                          .equipmentBags.length === 0 ? (
                                          <Form.Control
                                            readOnly
                                            defaultValue="no items or bags added"
                                          />
                                        ) : (
                                          <Field
                                            name={`employeeJobs[${empJobIndex}].equipmentPickupArea`}
                                            className="form-control form-control-sm"
                                          />
                                        )}
                                      </Form.Group>
                                    </Form.Row>
                                  </>
                                )}
                                <Form.Row>
                                  <Form.Group as={Col} className="mb-0">
                                    {canMutate && (
                                      <div
                                        className="d-inline-flex align-items-center btn-link hover"
                                        onClick={() => {
                                          arrayHelpers.remove(empJobIndex)
                                        }}
                                        role="presentation"
                                        onKeyDown={() => {
                                          arrayHelpers.remove(empJobIndex)
                                        }}
                                      >
                                        <Trash className="mr-1" />
                                        <small>Remove</small>
                                      </div>
                                    )}
                                    {canMutate && (
                                      <>
                                        <Form.Check
                                          type="checkbox"
                                          inline
                                          className="small label-bold mb-0 ms-3"
                                          id={uuidv4()}
                                          checked={
                                            formik.values.employeeJobs[
                                              empJobIndex
                                            ].confirmed
                                          }
                                          label="Confirmed"
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              `employeeJobs[${empJobIndex}].confirmed`,
                                              e.target.checked
                                            )
                                          }}
                                        />
                                        {formik.values.employeeJobs[empJobIndex]
                                          .employeeId && (
                                          <>
                                            <Form.Check
                                              inline
                                              className="small label-bold mb-0 ms-2"
                                              type="checkbox"
                                              id={uuidv4()}
                                              checked={
                                                formik.values.employeeJobs[
                                                  empJobIndex
                                                ].sendNotification
                                              }
                                              label="Send Assignment Notification"
                                              onChange={(e) => {
                                                formik.setFieldValue(
                                                  `employeeJobs[${empJobIndex}].sendNotification`,
                                                  e.target.checked
                                                )
                                              }}
                                            />
                                            <Form.Check
                                              inline
                                              className="small label-bold mb-0 ms-2"
                                              type="checkbox"
                                              id={uuidv4()}
                                              checked={
                                                formik.values.employeeJobs[
                                                  empJobIndex
                                                ].sendEquipmentNotification
                                              }
                                              label="Send Equipment Notification"
                                              onChange={(e) => {
                                                formik.setFieldValue(
                                                  `employeeJobs[${empJobIndex}].sendEquipmentNotification`,
                                                  e.target.checked
                                                )
                                              }}
                                            />
                                          </>
                                        )}
                                      </>
                                    )}
                                  </Form.Group>
                                </Form.Row>
                              </div>
                            </Col>
                          </Row>
                        )
                      }
                    )}
                  </div>
                </Col>
              </Row>
            )}

            {canMutate && (
              <>
                <Row>
                  <Col>
                    <Button
                      variant="link"
                      className={addStaffClass}
                      onClick={() => {
                        const jobStartTime = moment(
                          formik.values.jobStartTime,
                          'h:mmA'
                        )
                        const jobEndTime = moment(
                          formik.values.jobEndTime,
                          'h:mmA'
                        )
                        const adjustedJobStartTime = jobStartTime
                          .subtract(30, 'minutes')
                          .format('h:mmA')
                        const adjustedJobEndTime = jobEndTime
                          .add(30, 'minutes')
                          .format('h:mmA')
                        if (!displayStaff) {
                          setDisplayStaff(true)
                        }
                        arrayHelpers.push({
                          employeeId: '',
                          empJobEndTime: adjustedJobEndTime,
                          empJobStartTime: adjustedJobStartTime,
                          roleId: roleData?.roles?.edges[0]?.node?.id,
                          equipmentItems: [],
                          equipmentBags: [],
                          sendNotification: false,
                          sendEquipmentNotification: false,
                          equipmentPickupArea: '',
                          isNewEmpJob: true,
                        })
                      }}
                    >
                      <PlusCircle className="mr-2" />
                      Add Employee
                    </Button>
                  </Col>
                </Row>
                {formik.errors.employeeJobs && (
                  <Row>
                    <Col className="ml-1 mt-2 mb-2">
                      <div
                        style={{
                          color: 'red',
                          fontSize: '14px',
                        }}
                      >
                        <ExclamationTriangle className="mr-2" />
                        {formik.errors.employeeJobs}
                      </div>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </div>
        )}
      />
      <EmployeeSchedulingConflictsModal
        showModal={showSchedulingConflicts}
        toggleModal={setShowSchedulingConflicts}
        schedulingConflicts={schedulingConflicts}
      />
      {openScanModal && (
        <ScanModal
          jobForm
          jobId={formik.values.id}
          employeeJobId={formik.values.employeeJobs[scanningEmployeeIndex].id}
          showModal={openScanModal}
          validateBagNoEmployee={false}
          validateItemUnderRepair={true}
          validateItemRetire={true}
          validateItemNoBag={true}
          validateItemNoEmployee={false}
          hideDropdown={true}
          toggleModal={() => {
            setOpenScanModel((prevModal) => !prevModal)
            setScannedBags([])
            setScannedItems([])
            setScanningEmployeeIndex()
          }}
          submitModal={() => {
            const combinedItems = [
              ...formik.values.employeeJobs[scanningEmployeeIndex]
                .equipmentItems,
              ...scannedItems,
            ]
            const items = combinedItems.filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.id === item.id)
            )
            const combinedBags = [
              ...formik.values.employeeJobs[scanningEmployeeIndex]
                .equipmentBags,
              ...scannedBags,
            ]
            const bags = combinedBags.filter(
              (bag, index, self) =>
                index === self.findIndex((t) => t.id === bag.id)
            )
            formik.setFieldValue(
              `employeeJobs[${scanningEmployeeIndex}].equipmentItems`,
              items
            )
            formik.setFieldValue(
              `employeeJobs[${scanningEmployeeIndex}].equipmentBags`,
              bags
            )
            setOpenScanModel((prevModal) => !prevModal)
            setScannedBags([])
            setScannedItems([])
            setScanningEmployeeIndex()
            toast.success('Save Job To Assign Retain Equipment Assignments')
          }}
          setScannedBags={setScannedBags}
          scannedItems={scannedItems}
          scannedBags={scannedBags}
          showSubmitValidation={showScanSubmitValidation}
          submitButtonMessage={'Assign to Employee'}
          setScannedItems={setScannedItems}
          addBagValidation={addScanBagValidation}
          addItemValidation={addScanItemValidation}
          otherButtons={() => {
            if (showScanSubmitValidation()) {
              return (
                <>
                  <Row className="mt-3">
                    <Col md={{ span: 6, offset: 3 }}>
                      <Button
                        block
                        variant="outline-primary"
                        onClick={() => {
                          const combinedItems = [
                            ...formik.values.employeeJobs[scanningEmployeeIndex]
                              .equipmentItems,
                            ...scannedItems.map((item) => {
                              if (item.returned) {
                                checkoutItem(
                                  scanningEmployeeIndex,
                                  item,
                                  null,
                                  false
                                )
                                return { ...item, returned: false }
                              }
                              return item
                            }),
                          ]
                          const items = combinedItems.filter(
                            (item, index, self) =>
                              index === self.findIndex((t) => t.id === item.id)
                          )
                          const combinedBags = [
                            ...formik.values.employeeJobs[scanningEmployeeIndex]
                              .equipmentBags,
                            ...scannedBags.map((bag) => {
                              if (bag.returned) {
                                checkoutBag(
                                  scanningEmployeeIndex,
                                  bag,
                                  null,
                                  false
                                )
                                return { ...bag, returned: false }
                              }
                              return bag
                            }),
                          ]
                          const bags = combinedBags.filter(
                            (bag, index, self) =>
                              index === self.findIndex((t) => t.id === bag.id)
                          )
                          formik.setFieldValue(
                            `employeeJobs[${scanningEmployeeIndex}].equipmentItems`,
                            items
                          )
                          formik.setFieldValue(
                            `employeeJobs[${scanningEmployeeIndex}].equipmentBags`,
                            bags
                          )
                          setOpenScanModel((prevModal) => !prevModal)
                          setScannedBags([])
                          setScannedItems([])
                          setScanningEmployeeIndex()
                          toast.success(
                            'Save Job To Assign Retain Equipment Assignments'
                          )
                        }}
                      >
                        <PlusCircle className="mr-2" />
                        Assign to Employee and Checkout
                      </Button>
                    </Col>
                  </Row>
                </>
              )
            } else {
              return <></>
            }
          }}
        />
      )}
    </>
  )
}
