import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'

const JobDetailSubjectGroupsTable = (props) => {
  const { jobId, toggleModal } = props
  let hasMorePackageJobGroups = true
  let cursor
  const history = useHistory()

  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'subjectGroup',
      },
      {
        Header: 'Session Packages',
        accessor: 'sessionPackages',
      },
    ],
    []
  )
  const { loading, error, data, fetchMore } = useQuery(
    gql`
      query JobDetailPackageGroupJobsQuery($job: ID, $cursor: String) {
        packageGroupJobs(
          first: 100
          after: $cursor
          subjectGroup_Archived: false
          job: $job
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              subjectGroup {
                id
                name
              }
              sessionPackages {
                edges {
                  node {
                    id
                    title
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        job: jobId,
      },
    }
  )
  const handleQueryData = () => {
    hasMorePackageJobGroups = data.packageGroupJobs.pageInfo.hasNextPage
    if (data.packageGroupJobs.pageInfo.endCursor) {
      cursor = data.packageGroupJobs.pageInfo.endCursor
    }
    const currentPackageGroupJobs = []
    data.packageGroupJobs.edges.forEach((packageGroupJob) => {
      const packageGroupNode = packageGroupJob.node
      const subjectGroup = packageGroupNode.subjectGroup?.name
      const sessionPackages = []
      packageGroupNode.sessionPackages?.edges.forEach((sessionPackage) => {
        sessionPackages.push(sessionPackage.node.title)
      })
      const sessionPackageDisplay = (
        <>
          {sessionPackages.map((sessionPackage, index) => (
            <p key={index} style={{ margin: 0, padding: 0 }}>
              {sessionPackage}
            </p>
          ))}
        </>
      )
      currentPackageGroupJobs.push({
        subjectGroup,
        subjectGroupId: packageGroupNode.subjectGroup?.id,
        sessionPackages: sessionPackageDisplay,
      })
    })
    return currentPackageGroupJobs
  }
  const fetchMorePackageGroupJobs = () => {
    fetchMore({
      variables: {
        job: jobId,
        cursor,
      },
    })
  }
  const onRowClick = (row) => {
    history.push(`/subject-group/${row.original.subjectGroupId}`)
    toggleModal()
  }
  if (loading || !data) return <></>
  if (error) return <>Error loading subject groups</>
  const sessions = handleQueryData()
  return (
    <div className={'sessions mt-3'}>
      <Row className="mb-2">
        <Col md={12}>
          {sessions.length > 0 ? (
            <div className="mt-2">
              <SortableInfiniteTable
                tableData={sessions}
                tableColumns={tableColumns}
                fetchMoreTableData={fetchMorePackageGroupJobs}
                hasMoreTableData={hasMorePackageJobGroups}
                onRowClick={onRowClick}
                rowPointer
                tableHeight={550}
                hideGlobalFilter
              />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '200px',
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '20px',
                backgroundColor: '#f9f9f9',
                borderRadius: '10px',
                padding: '20px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                color: '#555',
              }}
            >
              No Bookable Subject Groups
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}
export default JobDetailSubjectGroupsTable
