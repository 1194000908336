// Blueprint.js
import React, { useState } from 'react'
import { Badge, Button, Row, Col } from 'react-bootstrap'
import { Plug } from 'react-bootstrap-icons'
import { useQuery, gql } from '@apollo/client'
import IntegrationCard from '../IntegrationCard'
import EditBlueprint from './EditBlueprint'

const Blueprint = () => {
  const [showEditModal, setShowEditModal] = useState(false)

  const { data } = useQuery(
    gql`
      query BlueprintConnector {
        imagequixConnectors {
          edges {
            node {
              id
              enabled
              clientId
              clientSecret
              authValid
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  if (!data) return null

  const blueprintConnector = data.imagequixConnectors.edges[0].node

  return (
    <>
      <EditBlueprint
        blueprintConnector={blueprintConnector}
        showModal={showEditModal}
        toggleModal={() => setShowEditModal(!showEditModal)}
      />
      <IntegrationCard
        logo={`${window.location.origin}/imagequix.png`}
        name="Blueprint"
        description="Connect with Blueprint to seamlessly upload your photos and offer them for sale online. This integration enables a smooth e-commerce experience for your clients, enhancing revenue opportunities."
      >
        <Row>
          <Col>
            {blueprintConnector.authValid ? (
              <Badge variant="success">Enabled</Badge>
            ) : (
              <Badge variant="danger">Disabled</Badge>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="link" onClick={() => setShowEditModal(true)}>
              <Plug className="mr-2" />
              Configure
            </Button>
          </Col>
        </Row>
      </IntegrationCard>
    </>
  )
}

export default Blueprint
