import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'draft-js/dist/Draft.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const RichTextEditor = (props) => {
  const { formik } = props

  const editor = React.useRef(null)
  function focusEditor() {
    editor.current.focusEditor()
  }

  return (
    <div onClick={focusEditor}>
      <Editor
        ref={editor}
        editorState={formik.values.plaintextEmailEditorState}
        onEditorStateChange={(editorState) => {
          formik.setFieldValue('plaintextEmailEditorState', editorState)
        }}
        editorStyle={{
          height: 'calc(1.5em * 12)',
          overflowY: 'auto',
          borderRadius: '.25rem',
        }}
        toolbarClassName="border border-dark rounded"
        editorClassName="p-2"
        wrapperClassName="border border-dark rounded"
      />
    </div>
  )
}

export default RichTextEditor
