import React from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import SessionHistory from './SessionHistory'

export default function SessionHistoryModal(props) {
  const { showSessionHistoryModal, toggleSessionHistoryModal, sessionId } =
    props
  return (
    <>
      <Modal
        size="lg"
        show={showSessionHistoryModal}
        onHide={toggleSessionHistoryModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Session History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <SessionHistory sessionId={sessionId} onlyTable={true} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}
