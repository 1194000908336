import React, { useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Form, Row, Col, Button, Modal, InputGroup } from 'react-bootstrap'
import { PlusCircle, Images } from 'react-bootstrap-icons'
import { CurrencyDollar } from 'react-bootstrap-icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { FilePond } from 'react-filepond'
import Loading from '../common/Loading'
import './NewSessionPackageModal.css'
import { client } from '../../libs/apollo'

const NewSessionPackageModal = (props) => {
  const { showModal, toggleModal } = props

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { data: getCategoryData } = useQuery(
    gql`
      query PackageCategoriesModal {
        packageCategories {
          edges {
            node {
              id
              name
              archived
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const [createSessionPackageMutation] = useMutation(
    gql`
      mutation CreateSessionPackage(
        $createSessionPackageInput: CreateSessionPackageInput!
      ) {
        createSessionPackage(input: $createSessionPackageInput) {
          sessionPackage {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        toggleModal()
        setIsSubmitting(false)
        toast.success(`Session Package Created`)
      },
      onError: () => {
        setIsSubmitting(false)
      },
      refetchQueries: ['SessionPackagesQuery'],
    }
  )

  const NewSessionPackageSchema = Yup.object().shape({
    packageCategoryId: Yup.string(),
    title: Yup.string()
      .min(2, 'Title is too short')
      .max(100, 'Title is too long')
      .required('Title is required')
      .test('isUnique', 'Title must be unique', async (value) => {
        let valid = true
        if (value) {
          const { data } = await client.query({
            query: gql`
              query SPTitle($titleIexact: String) {
                sessionPackages(title_Iexact: $titleIexact) {
                  nodeCount
                }
              }
            `,
            fetchPolicy: 'network-only',
            variables: {
              titleIexact: value,
            },
          })
          return data.sessionPackages.nodeCount === 0
        }
        return valid
      }),
    upsell: Yup.boolean().nullable(),
    upsellDescription: Yup.string().when('upsell', {
      is: true,
      then: Yup.string().required('Required'),
    }),
    customPriceAndDuration: Yup.boolean().nullable(),
    price: Yup.number()
      .min(0, 'Price is too low')
      .max(1000, 'Price is too high')
      .when('customPriceAndDuration', {
        is: false,
        then: Yup.number().required('Required'),
      }),
    description: Yup.string()
      .min(2, 'Description is too short')
      .required('Description is required'),
    durationLowMinutes: Yup.number()
      .min(0, 'Low duration is too low')
      .max(1000, 'Low duration is too high')
      .when('customPriceAndDuration', {
        is: false,
        then: Yup.number().required('Required'),
      }),
    durationHighMinutes: Yup.number()
      .min(0, 'High duration is too low')
      .max(1000, 'High duration is too high')
      .when('customPriceAndDuration', {
        is: false,
        then: Yup.number().required('Required'),
      })
      .test(
        'isHigherThanLowDuration',
        'High duration must exceed low duration',
        (value, context) => {
          let valid = true
          if (
            context.parent.durationLowMinutes &&
            context.parent.durationLowMinutes >= value
          ) {
            valid = false
          }
          return valid
        }
      )
      .test('modFive', 'Must be on the 0th or 5th minute', (value, context) => {
        if (context.parent.customPriceAndDuration) {
          return true
        }
        let valid = true
        if (value && !(value % 5 === 0)) {
          valid = false
        }
        return valid
      }),
    previewImages: Yup.mixed()
      .when('customPriceAndDuration', {
        is: false,
        then: Yup.mixed().required('Required'),
      })
      .test(
        'isUniqueFileName',
        'Preview image names must be unique',
        (value, context) => {
          if (context.parent.customPriceAndDuration) {
            return true
          }
          let valid = true
          if (!previewImageNamesUnique(formik.values.previewImages)) {
            valid = false
          }
          return valid
        }
      ),
    coverPreviewImage: Yup.string()
      .when('customPriceAndDuration', {
        is: false,
        then: Yup.string().required('Required'),
      })
      .test(
        'isPreviewImageName',
        'Invalid cover image choice',
        (value, context) => {
          if (context.parent.customPriceAndDuration) {
            return true
          }
          let valid = false
          formik.values.previewImages.forEach((previewImage) => {
            if (!valid && previewImage.filename === value) {
              valid = true
            }
          })
          return valid
        }
      ),
  })
  const formik = useFormik({
    initialValues: {
      title: '',
      price: '',
      upsell: false,
      upsellDescription: '',
      description: '',
      previewImages: [],
      durationHighMinutes: '',
      durationLowMinutes: '',
      packageCategoryId: '',
    },
    validationSchema: NewSessionPackageSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      setIsSubmitting(true)
      const previewImages = formik.values.previewImages.map((previewImage) => {
        const previewImageInput = {
          image: previewImage.file,
          nameOnUpload: previewImage.filename,
        }
        if (previewImage.filename === values.coverPreviewImage) {
          previewImageInput.coverImage = true
        }
        return previewImageInput
      })
      const sessionPackageInput = {
        title: values.title,
        customPriceAndDuration: values.customPriceAndDuration,
        description: values.description,
      }
      if (!values.customPriceAndDuration) {
        ;(sessionPackageInput.price = values.price),
          (sessionPackageInput.upsell = values.upsell),
          (sessionPackageInput.upsellDescription = values.upsellDescription),
          (sessionPackageInput.durationLowMinutes = values.durationLowMinutes),
          (sessionPackageInput.durationHighMinutes =
            values.durationHighMinutes),
          (sessionPackageInput.previewImages = previewImages),
          (sessionPackageInput.packageCategoryId = values.packageCategoryId)
      }
      createSessionPackageMutation({
        variables: {
          createSessionPackageInput: {
            sessionPackageInput,
          },
        },
      })
    },
  })

  useEffect(() => {
    if (!showModal) {
      formik.resetForm()
      setIsSubmitting(false)
    }
  }, [showModal])
  useEffect(() => {
    if (
      formik.values.previewImages.length > 0 &&
      !formik.values.coverPreviewImage
    ) {
      formik.setFieldValue(
        'coverPreviewImage',
        formik.values.previewImages[0].filename
      )
    }
    if (
      formik.values.previewImages.length > 0 &&
      !previewImageNamesUnique(formik.values.previewImages)
    ) {
      formik.setFieldError(
        'previewImages',
        'Preview image names must be unique'
      )
    } else if (formik.errors.previewImages) {
      formik.setFieldError('previewImages', null)
    }
  }, [formik.values.previewImages])
  const previewImageNamesUnique = (previewImages) => {
    let unique = true
    const fileNames = []
    previewImages.forEach((previewImage) => {
      fileNames.push(previewImage.filename)
    })
    if (fileNames.filter((e, i, a) => a.indexOf(e) !== i).length > 0) {
      unique = false
    }
    return unique
  }
  const handleFileUpload = (previewImages) => {
    formik.setFieldValue('previewImages', previewImages)
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="newSessionPackage">
        <Modal size="lg" show={showModal} onHide={() => toggleModal()}>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Images className="mr-2" />
                New Session Package
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Row>
                  <Col md={2} className="d-flex align-items-center">
                    <Form.Label className="mb-0">Title</Form.Label>
                  </Col>
                  <Col md={6}>
                    <Form.Control
                      type="text"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.title}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col md={2} className="d-flex align-items-center">
                    <Form.Label className="mb-0">
                      Custom Price and Duration
                    </Form.Label>
                  </Col>
                  <Col md={6}>
                    <Form.Check
                      type="checkbox"
                      name="customPriceAndDuration"
                      checked={formik.values.customPriceAndDuration}
                      onChange={() => {
                        formik.setFieldValue(
                          'customPriceAndDuration',
                          !formik.values.customPriceAndDuration
                        )
                      }}
                      isInvalid={formik.errors.customPriceAndDuration}
                      feedback={formik.errors.customPriceAndDuration}
                      id="upsell-checkbox"
                    />
                  </Col>
                </Row>
              </Form.Group>
              {!formik.values.customPriceAndDuration && (
                <>
                  <Form.Group>
                    <Row>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="mb-0">Price</Form.Label>
                      </Col>
                      <Col md={3}>
                        <InputGroup>
                          <InputGroup.Text id="inputGroupPrepend">
                            <CurrencyDollar />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            name="price"
                            value={formik.values.price}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.price}
                          />
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.price}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Row>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="mb-0">Duration</Form.Label>
                      </Col>
                      <Col md={2}>
                        <Form.Control
                          type="text"
                          name="durationLowMinutes"
                          value={formik.values.durationLowMinutes}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.durationLowMinutes}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.durationLowMinutes}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={1} className="d-flex align-items-center">
                        <span>to</span>
                      </Col>
                      <Col md={2}>
                        <Form.Control
                          type="text"
                          name="durationHighMinutes"
                          value={formik.values.durationHighMinutes}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.durationHighMinutes}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.durationHighMinutes}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={1} className="d-flex align-items-center">
                        <span>minutes</span>
                      </Col>
                    </Row>
                  </Form.Group>
                </>
              )}
              {!formik.values.customPriceAndDuration && (
                <Form.Group>
                  <Row>
                    <Col md={2} className="d-flex align-items-center">
                      Category
                    </Col>
                    <Col>
                      <Form.Control
                        as="select"
                        name="packageCategoryId"
                        value={formik.values.packageCategoryId}
                        onChange={formik.handleChange}
                      >
                        <option value={''}>- - -</option>
                        {getCategoryData?.packageCategories?.edges.map(
                          (catNode) => {
                            const { node } = catNode
                            return (
                              <option key={node.id} value={node.id}>
                                {node.name}
                              </option>
                            )
                          }
                        )}
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
              )}

              {!formik.values.customPriceAndDuration && (
                <>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      md={2}
                      className="d-flex align-items-center"
                    >
                      Upsell
                    </Form.Label>
                    <Col md={6}>
                      <Form.Check
                        type="checkbox"
                        name="upsell"
                        checked={formik.values.upsell}
                        onChange={() => {
                          formik.setFieldValue('upsell', !formik.values.upsell)
                        }}
                        isInvalid={formik.errors.upsell}
                        feedback={formik.errors.upsell}
                        id="upsell-checkbox"
                      />
                    </Col>
                  </Form.Group>
                  {formik.values.upsell ? (
                    <Form.Group>
                      <Row>
                        <Col md={2}>
                          <Form.Label>Upsell Description</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            style={{ height: '200px' }}
                            as="textarea"
                            name="upsellDescription"
                            value={formik.values.upsellDescription}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.upsellDescription}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.upsellDescription}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>
                  ) : (
                    ''
                  )}
                </>
              )}

              <Form.Group>
                <Row>
                  <Col md={2}>
                    <Form.Label>Description</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      style={{ height: '200px' }}
                      as="textarea"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.description}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
              {!formik.values.customPriceAndDuration && (
                <>
                  <Form.Group>
                    <Row>
                      <Col className="d-flex align-items-center">
                        <Form.Label className="mb-0">
                          Preview Images
                          <p className="mb-3 mt-1 text-secondary">
                            Upload up to three preview images
                          </p>
                          {formik.errors.previewImages ? (
                            <p style={{ color: '#dc3545' }}>
                              {formik.errors.previewImages}
                            </p>
                          ) : null}
                        </Form.Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={{ span: 10, offset: 1 }}>
                        <FilePond
                          files={formik.values.previewImages}
                          onupdatefiles={handleFileUpload}
                          allowMultiple
                          acceptedFileTypes={['image/png', 'image/jpeg']}
                          allowFileSizeValidation
                          maxFileSize="15MB"
                          labelMaxFileSize="Maximum preview image size is {filesize}"
                          maxFiles={3}
                          name="previewImages"
                          labelIdle='Drag and drop images or <span class="filepond--label-action">Browse</span>'
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  {formik.values.previewImages.length > 0 ? (
                    <Form.Group>
                      <Row>
                        <Col className="d-flex align-items-center" md={3}>
                          <Form.Label className="mb-0">Cover Image</Form.Label>
                        </Col>
                        <Col xs={12} md={9}>
                          <Form.Control
                            name="coverPreviewImage"
                            as="select"
                            value={formik.values.coverPreviewImage}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.coverPreviewImage}
                          >
                            {formik.values.previewImages.map(
                              (previewImage, index) => (
                                <option
                                  key={`${previewImage.filename}${index}`}
                                  value={previewImage.filename}
                                >
                                  {previewImage.filename}
                                </option>
                              )
                            )}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.coverPreviewImage}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>
                  ) : null}
                  {formik.errors.price ||
                  formik.errors.description ||
                  formik.errors.previewImages ||
                  formik.errors.durationHighMinutes ||
                  formik.errors.durationLowMinutes ||
                  formik.errors.coverPreviewImage ||
                  formik.errors.title ? (
                    <Row>
                      <Col>
                        <p className="mt-4 mb-4" style={{ color: '#dc3545' }}>
                          Please resolve errors before saving
                        </p>
                      </Col>
                    </Row>
                  ) : null}
                </>
              )}
              <Row>
                <Col md={3}>
                  <Button
                    type="submit"
                    variant="outline-primary"
                    className="mt-2"
                    block
                    disabled={isSubmitting}
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Col>
              </Row>

              {isSubmitting ? (
                <Row className="mt-2">
                  <Col>
                    <Loading message="Saving Session Package..." />
                  </Col>
                </Row>
              ) : null}
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default NewSessionPackageModal
