import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import Loading from '../../common/Loading'
import { FilePond } from 'react-filepond'
import { Plug, Trash, ArrowRepeat } from 'react-bootstrap-icons'

const EditGoogleCalendarConnector = (props) => {
  const { showModal, toggleModal, gCalConnector } = props

  const [submitting, setSubmitting] = useState(false)
  const [disabling, setDisabling] = useState(false)
  const [syncing, setSyncing] = useState(false)
  const [authJson, setAuthJson] = useState()
  const [update] = useMutation(
    gql`
      mutation UpdateGoogleCalednarConnector(
        $updateGoogleCalendarConnectorInput: UpdateGoogleCalendarConnectorInput!
      ) {
        updateGoogleCalendarConnector(
          input: $updateGoogleCalendarConnectorInput
        ) {
          googleCalendarConnector {
            id
            authValid
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        if (
          data.updateGoogleCalendarConnector.googleCalendarConnector.authValid
        ) {
          toast.success('Google Calendar Integration Enabled')
        } else if (disabling) {
          toast.success('Google Calendar Integration Disabled')
          setDisabling(false)
        } else if (syncing) {
          setSyncing(false)
          toast.success('Syncing Google Calendar')
        } else {
          toast.error('Invalid Google Calendar Credentials')
        }
        innerToggleModel()
      },
      refetchQueries: ['GoogleCalendarConnector'],
      errorPolicy: 'all',
    }
  )

  const innerToggleModel = () => {
    toggleModal()
    setSubmitting(false)
    setAuthJson()
    formik.resetForm()
  }

  const formik = useFormik({
    initialValues: {
      auth: '',
      adminCalendarId: '',
      serviceAccount: '',
      enabledForSubjectSessions: true,
      enabledForEmployeeJobs: true,
      enabledForOrganizationSessions: true,
    },
    validationSchema: Yup.object().shape({
      adminCalendarId: Yup.mixed().test('Required', 'Required', (value) => {
        let valid = true
        if (!value) {
          valid = false
        }
        return valid
      }),
      auth: Yup.mixed().test(
        'isUploaded',
        'OAuth Credentials are Required',
        (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }
      ),
      serviceAccount: Yup.mixed().test('Required', 'Required', (value) => {
        let valid = true
        if (!value) {
          valid = false
        }
        return valid
      }),
      enabledForSubjectSessions: Yup.boolean().nullable(),
      enabledForEmployeeJobs: Yup.boolean().nullable(),
      enabledForOrganizationSessions: Yup.boolean().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      update({
        variables: {
          updateGoogleCalendarConnectorInput: {
            googleCalendarConnectorInput: {
              id: gCalConnector.id,
              auth: authJson,
              serviceAccount: values.serviceAccount,
              adminCalendarId: values.adminCalendarId,
              enabledForSubjectSessions: values.enabledForSubjectSessions,
              enabledForEmployeeJobs: values.enabledForEmployeeJobs,
              enabledForOrganizationSessions:
                values.enabledForOrganizationSessions,
            },
          },
        },
      })
    },
  })

  const handleFileUpload = (credentials) => {
    try {
      const reader = new FileReader()
      reader.onload = () => {
        setAuthJson(reader.result)
      }
      reader.readAsText(credentials[0]?.file)
      formik.setFieldValue('auth', credentials)
    } catch {
      //
    }
  }

  useEffect(() => {
    if (showModal) {
      formik.setValues({
        serviceAccount: gCalConnector.serviceAccount,
        adminCalendarId: gCalConnector.adminCalendarId,
        enabledForSubjectSessions: gCalConnector.enabledForSubjectSessions,
        enabledForEmployeeJobs: gCalConnector.enabledForEmployeeJobs,
        enabledForOrganizationSessions:
          gCalConnector.enabledForOrganizationSessions,
      })
    }
  }, [showModal])

  return (
    <>
      <div className="updateFotomerchantConnector">
        <Modal
          size="lg"
          show={showModal}
          onHide={innerToggleModel}
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                <img
                  className="img-fluid mr-2"
                  src={window.location.origin + '/google-calendar.png'}
                  style={{ height: '25px' }}
                  alt="Organization logo"
                />
                Configure Google Calendar
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Service Account
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="serviceAccount"
                    value={formik.values.serviceAccount}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.serviceAccount}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.serviceAccount}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Shared Calendar Email
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="adminCalendarId"
                    value={formik.values.adminCalendarId}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.adminCalendarId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.adminCalendarId}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="6">
                  API Credentials
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Col xs={12}>
                  <FilePond
                    files={formik.values.auth}
                    onupdatefiles={handleFileUpload}
                    acceptedFileTypes={['application/json']}
                    allowFileSizeValidation
                    maxFileSize="15MB"
                    labelMaxFileSize="Maximum preview image size is {filesize}"
                    maxFiles={1}
                    name="files"
                    labelIdle='Drag and drop a file or <span class="filepond--label-action">Browse</span>'
                  />
                  {formik.errors?.auth && (
                    <span style={{ color: 'red' }}>{formik.errors?.auth}</span>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md={4}>
                  <Form.Check
                    name="enabledForSubjectSessions"
                    type="checkbox"
                    label="Default Enabled for Subject Sessions"
                    checked={formik.values.enabledForSubjectSessions}
                    onChange={(e) => {
                      formik?.setFieldValue(
                        `enabledForSubjectSessions`,
                        e.target.checked
                      )
                    }}
                  />
                </Col>
                <Col md={4}>
                  <Form.Check
                    name="enabledForOrganizationSessions"
                    type="checkbox"
                    label="Default Enabled for Organization Sessions"
                    checked={formik.values.sharedCanSeeFiles}
                    onChange={(e) => {
                      formik?.setFieldValue(
                        `enabledForOrganizationSessions`,
                        e.target.checked
                      )
                    }}
                  />
                </Col>
                <Col md={4}>
                  <Form.Check
                    name="enabledForEmployeeJobs"
                    type="checkbox"
                    label="Default Enabled for Employee Jobs"
                    checked={formik.values.enabledForEmployeeJobs}
                    onChange={(e) => {
                      formik?.setFieldValue(
                        `enabledForEmployeeJobs`,
                        e.target.checked
                      )
                    }}
                  />
                </Col>
              </Form.Group>
              <Row className="mb-2">
                <Col md={3}>
                  <Button
                    disabled={submitting}
                    type="submit"
                    size="sm"
                    block
                    variant="outline-primary"
                    className="mt-2"
                  >
                    <Plug className="mr-2" />
                    Save
                  </Button>
                </Col>
                {gCalConnector?.authValid && (
                  <>
                    <Col md={3}>
                      <Button
                        disabled={submitting}
                        block
                        onClick={() => {
                          setSubmitting(true)
                          setSyncing(true)
                          update({
                            variables: {
                              updateGoogleCalendarConnectorInput: {
                                googleCalendarConnectorInput: {
                                  id: gCalConnector.id,
                                  sync: true,
                                },
                              },
                            },
                          })
                        }}
                        size="sm"
                        variant="outline-primary"
                        className="mt-2"
                      >
                        <ArrowRepeat className="mr-2" />
                        Sync
                      </Button>
                    </Col>
                    <Col md={3}>
                      <Button
                        disabled={submitting}
                        block
                        onClick={() => {
                          setSubmitting(true)
                          setDisabling(true)
                          update({
                            variables: {
                              updateGoogleCalendarConnectorInput: {
                                googleCalendarConnectorInput: {
                                  id: gCalConnector.id,
                                  disable: true,
                                },
                              },
                            },
                          })
                        }}
                        size="sm"
                        variant="outline-danger"
                        className="mt-2"
                      >
                        <Trash className="mr-2" />
                        Disable
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
              {submitting && <Loading />}
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default EditGoogleCalendarConnector
