import React, { createContext, useState, useContext, useEffect } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import Loading from '../common/Loading'
import ProgressBar from 'react-bootstrap/ProgressBar'
import './DownloadContext.css'
import { Row, Col } from 'react-bootstrap'
import { useDownloadFile } from '../../libs/downloadFile'

const DownloadContext = createContext()

export const useDownload = () => useContext(DownloadContext)

export const DownloadProvider = ({ children }) => {
  const [downloading, setDownloading] = useState(false)
  const [fileDownloadSession, setFileDownloadSession] = useState(null)
  const [downloadingFiles, setdownloadingFiles] = useState(false)
  const { downloadAndDeleteFile } = useDownloadFile()

  const [getFileDownloadSession, { data: fileDownloadSessionData }] =
    useLazyQuery(
      gql`
        query FileDownloadSession($id: ID!) {
          fileDownloadSession(id: $id) {
            id
            totalSize
            totalSizeCreated
            zipCreationComplete
            file {
              id
              fileName
              displayName
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        pollInterval: 1000,
      }
    )

  const [createFileDownloadSession] = useMutation(
    gql`
      mutation CreateFileDownloadSession(
        $input: CreateFileDownloadSessionInput!
      ) {
        createFileDownloadSession(input: $input) {
          fileDownloadSession {
            id
            totalSize
            totalSizeCreated
            zipCreationComplete
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data.createFileDownloadSession.fileDownloadSession) {
          const fileDownloadSession =
            data.createFileDownloadSession.fileDownloadSession
          localStorage.setItem('fileDownloadSessionId', fileDownloadSession?.id)
          localStorage.setItem('downloadingFiles', downloadingFiles)
          setFileDownloadSession(fileDownloadSession)
          getFileDownloadSession({
            variables: { id: fileDownloadSession?.id },
          })
        }
      },
    }
  )

  useEffect(() => {
    const fileDownloadSessionId = localStorage.getItem('fileDownloadSessionId')
    if (fileDownloadSessionId) {
      setDownloading(true)
      setdownloadingFiles(localStorage.getItem('downloadingFiles'))
      getFileDownloadSession({
        variables: { id: fileDownloadSessionId },
      })
    }
  }, [])

  useEffect(() => {
    if (fileDownloadSessionData && downloading) {
      setFileDownloadSession(fileDownloadSessionData.fileDownloadSession)
    }
  }, [fileDownloadSessionData])

  useEffect(() => {
    if (fileDownloadSession?.zipCreationComplete && fileDownloadSession?.file) {
      downloadAndDeleteFile(
        fileDownloadSession.file.fileName,
        fileDownloadSession.file.displayName,
        fileDownloadSession.file.id,
        () => {
          toast.success(`File${downloadingFiles ? 's' : ''} Downloaded`)
          localStorage.removeItem('fileDownloadSessionId')
          localStorage.removeItem('downloadingFiles')
          setFileDownloadSession(null)
          setDownloading(false)
        }
      )
    }
  }, [fileDownloadSession])

  const startDownload = (items) => {
    setdownloadingFiles(items.length > 1 || items[0].type === 'folder')
    setDownloading(true)
    createFileDownloadSession({
      variables: {
        input: {
          fileDownloadSessionInput: { items },
        },
      },
    })
  }

  let transferVariant
  if (fileDownloadSession?.id) {
    const transferPercent = Math.round(
      100 *
        (fileDownloadSession?.totalSizeCreated / fileDownloadSession?.totalSize)
    )
    if (transferPercent < 33) {
      transferVariant = 'danger'
    } else if (transferPercent < 66) {
      transferVariant = 'warning'
    } else if (transferPercent >= 66) {
      transferVariant = 'success'
    }
  }

  return (
    <DownloadContext.Provider
      value={{
        downloading,
        startDownload,
      }}
    >
      {children}
      {downloading && (
        <div className="bottom-left">
          <div>
            <Loading />
            <p className="mt-2" style={{ textAlign: 'center' }}>
              Downloading File{downloadingFiles && <>s</>}...
            </p>
          </div>
          {fileDownloadSession?.id &&
            fileDownloadSession?.totalSizeCreated > 0 && (
              <Row className="mt-2">
                <Col>
                  <ProgressBar
                    animated
                    variant={transferVariant}
                    now={`${Math.round(
                      100 *
                        (fileDownloadSession?.totalSizeCreated /
                          fileDownloadSession?.totalSize)
                    )}`}
                    label={`${Math.round(
                      100 *
                        (fileDownloadSession?.totalSizeCreated /
                          fileDownloadSession?.totalSize)
                    )}% Complete Downloading File Data`}
                  />
                </Col>
              </Row>
            )}
        </div>
      )}
    </DownloadContext.Provider>
  )
}
