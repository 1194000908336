import React, { useEffect, useState } from 'react'
import EmployeeNavigation from './navigation/EmployeeNavigation'
import Footer from './Footer'
import Header from './Header'
import { Container } from 'react-bootstrap'
import { useIntercom } from 'react-use-intercom'
import './Layout.css'

const EmployeeLayout = (props) => {
  const { shutdown } = useIntercom()
  const [navigationOpen, setNavigationOpen] = useState(false)

  useEffect(() => {
    if (shutdown) {
      shutdown()
    }
  }, [shutdown])

  return (
    <>
      <span className="header">
        <Header borderBottom />
      </span>
      <div style={{ display: 'flex', minHeight: '100vh', top: '30px' }}>
        <EmployeeNavigation
          setNavigationOpen={setNavigationOpen}
          navigationOpen={navigationOpen}
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Container fluid style={{ flex: 1 }} className="mb-5">
            {props.children}
          </Container>
          <span className={!navigationOpen && 'footer'}>
            <Footer />
          </span>
        </div>
      </div>
    </>
  )
}

export default EmployeeLayout
