import React from 'react'
import { Modal } from 'react-bootstrap'
import FilePreview from './FilePreview'
import { FileEarmarkText } from 'react-bootstrap-icons'

const FilePreviewModal = (props) => {
  const { file, showModal, toggleModal } = props

  if (!showModal) return null
  return (
    <div id="bagTypeModal">
      <Modal show={showModal} onHide={toggleModal} size={'lg'}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FileEarmarkText className="mr-2" />
            View {file.displayName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FilePreview file={file} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default FilePreviewModal
