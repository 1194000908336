import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const EquipmentItemSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    setAdditionalFields,
    error,
    multiple,
    dropdown,
    variables,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="item"
      searchDescription={searchDescription ? searchDescription : 'Items'}
      nodeNamePlural="equipmentItems"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      setAdditionalFields={setAdditionalFields}
      multiple={multiple}
      dropdown={dropdown}
      variables={variables}
      gql={gql`
        query SearchItems(
          $first: Int
          $after: String
          $search: String
          $employeeIsNull: Boolean
          $excludeAssignedEmployeeJob: String
        ) {
          equipmentItems(
            first: $first
            after: $after
            orderBy: "name"
            search: $search
            employee_Isnull: $employeeIsNull
            excludeAssignedEmployeeJob: $excludeAssignedEmployeeJob
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                name
                serialNumber
                lastEmployeeJob {
                  job {
                    name
                  }
                  startDateTime
                  endDateTime
                  employee {
                    gaiaUser {
                      fullName
                    }
                  }
                }
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return `${node.name} #${node.serialNumber}`
      }}
    />
  )
}

export default EquipmentItemSearchInput
