import React from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowCounterclockwise } from 'react-bootstrap-icons'
import { useReactiveVar } from '@apollo/client'
import { Row, Col, Button, Card } from 'react-bootstrap'
import StudentLogin from './StudentLogin'
import TechLookup from './TechLookup'
import { useParams } from 'react-router-dom'
import { loggedInUserVar } from '../../libs/apollo'
import { isEmployee } from '../../libs/utils'

export default function SchoolSession() {
  const { book } = useParams()
  const history = useHistory()
  const user = useReactiveVar(loggedInUserVar)
  const isEmployeeUser = isEmployee(user?.groups.edges[0].node.name)

  const handleStudentLogin = (userNode, subjectNode) => {
    history.push({
      pathname: '/book',
      state: {
        initialStage: 'review',
        bookingType: 'schoolFlow',
        incommingSubject: {
          subjectGroupsNotBooked: subjectNode.subjectGroupsNotBooked,
          studentId: subjectNode.studentId,
          id: subjectNode.id,
          fullName: userNode.fullName,
          org: {
            name: subjectNode.organization ? subjectNode.organization.name : '',
            id: subjectNode.organization ? subjectNode.organization.id : '',
          },
        },
      },
    })
  }

  const handleResetClick = () => {
    if (isEmployeeUser) {
      history.push('/book-new-session')
    } else {
      history.push('subject/sessions')
    }
  }

  return (
    <>
      {book && (
        <Card
          className="p-4 shadow-lg border-0 mt-5"
          style={{ maxWidth: '600px', margin: '0 auto' }}
        >
          <Card.Body>
            <Row className={'mt-2 mb-3'}>
              <Col md={{ span: 8, offset: 2 }} className="text-center">
                <h1>Book Session</h1>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <StudentLogin handleLoggedInStudent={handleStudentLogin} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
      {!book && (
        <>
          <Row
            style={{ marginTop: '18px' }}
            className={book ? 'mt-4 mb-3' : ''}
          >
            <Col>
              <h1>
                Book Session
                <Button variant="link" onClick={handleResetClick}>
                  <ArrowCounterclockwise size={18} className="ml-3 mr-2" />
                  Reset
                </Button>
              </h1>
            </Col>
          </Row>
          {(!isEmployeeUser || book) && (
            <Row>
              <Col md="4">
                <StudentLogin handleLoggedInStudent={handleStudentLogin} />
              </Col>
            </Row>
          )}
          {isEmployeeUser && !book ? (
            <>
              <TechLookup colMd={4} handleUserFound={handleStudentLogin} />
            </>
          ) : null}
        </>
      )}
    </>
  )
}
