import React from 'react'
import Sound from 'react-sound'

const Sound_ = (props) => {
  const { sound } = props
  let sound_
  if (sound === 'success') {
    sound_ = '/success.wav'
  }
  return <Sound url={sound_} playStatus={Sound.status.PLAYING} />
}

export default Sound_
