import React from 'react'
import { Modal } from 'react-bootstrap'
import BagTypeForm from './BagTypeForm'
import { BriefcaseFill } from 'react-bootstrap-icons'

const BagTypeModal = (props) => {
  const { showModal, toggleModal, bagType } = props
  if (!showModal) return <></>
  return (
    <>
      <div id="bagTypeModal">
        <Modal size="lg" show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <BriefcaseFill className="mr-2" />
              {bagType ? <>Edit Bag Type</> : <>New Bag Type</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BagTypeForm bagType={bagType} afterSubmit={toggleModal} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default BagTypeModal
