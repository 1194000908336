import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { client } from '../../libs/apollo'
import { PlusCircle, PersonBadge } from 'react-bootstrap-icons'

const NewEmployeeRoleModal = (props) => {
  const { showModal, toggleModal } = props

  const ROLE_NAME_UNIQUE = gql`
    query RoleNameUniqueQuery($nameIexact: String) {
      roles(name_Iexact: $nameIexact) {
        nodeCount
      }
    }
  `
  const CREATE_ROLE_MUTATION = gql`
    mutation CreateRoleMutation($createRoleInput: CreateRoleInput!) {
      createRole(input: $createRoleInput) {
        role {
          id
        }
      }
    }
  `
  const [createRoleMutation] = useMutation(CREATE_ROLE_MUTATION, {
    onCompleted: (data) => {
      innerToggleModel()
      toast.success('Employee Role Saved')
    },
    errorPolicy: 'all',
    refetchQueries: ['RolesQuery'],
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      abbrevietedName: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(2, 'Name is too short')
        .max(100, 'Name is too long')
        .required('Name is required')
        .test('isUnique', 'Name must be unique', async (value) => {
          let valid = true
          if (value) {
            const { data } = await client.query({
              query: ROLE_NAME_UNIQUE,
              fetchPolicy: 'network-only',
              variables: {
                nameIexact: value,
              },
            })
            valid = data.roles.nodeCount === 0
          }
          return valid
        }),
      abbreviatedName: Yup.string()
        .max(100, 'Abbrevieted name  is too long')
        .nullable(),
    }),
    onSubmit: (values) => {
      createRoleMutation({
        variables: {
          createRoleInput: {
            roleInput: {
              name: values.name,
              abbreviatedName: values.abbreviatedName,
            },
          },
        },
      })
    },
  })
  const innerToggleModel = () => {
    formik.setValues({
      name: '',
      abbreviatedName: '',
    })
    formik.setErrors({
      name: null,
    })
    toggleModal()
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="newEmployeeRole">
        <Modal
          size="md"
          show={showModal}
          onHide={innerToggleModel}
          aria-labelledby="new-title"
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                <PersonBadge className="mr-2" />
                New Employee Role
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Form.Group
                  as={Col}
                  xs={12}
                  md={3}
                  className="d-flex align-items-center"
                >
                  <Form.Label className="mb-0">Name</Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={12} md={9}>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  xs={12}
                  md={3}
                  className="d-flex align-items-center"
                >
                  <Form.Label className="mb-0">Abbreviated Name</Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={12} md={9}>
                  <Form.Control
                    type="text"
                    name="abbreviatedName"
                    value={formik.values.abbreviatedName}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.abbreviatedName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.abbreviatedName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Col md={4}>
                  <Button
                    type="submit"
                    variant="outline-primary"
                    className="mt-2"
                    block
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default NewEmployeeRoleModal
