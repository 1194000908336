import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import Page from '../layout/Page'
import { CardChecklist, Collection } from 'react-bootstrap-icons'
import TaskCollections from './TaskCollections'
import Tasks from './Tasks'

function TaskPage(props) {
  const [activeTab, setActiveTab] = useState('Tasks')

  return (
    <Page title="Tasks">
      <Tabs
        className="mt-3"
        activeKey={activeTab}
        onSelect={(tab) => setActiveTab(tab)}
      >
        <Tab
          eventKey="Tasks"
          title={
            <b>
              <CardChecklist className="mr-1" />
              Tasks
            </b>
          }
        >
          <Tasks />
        </Tab>
        <Tab
          eventKey="Task Collections"
          title={
            <b>
              <Collection className="mr-1" />
              Task Collections
            </b>
          }
        >
          <TaskCollections />
        </Tab>
      </Tabs>
    </Page>
  )
}

export default TaskPage
