import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col } from 'react-bootstrap'
import SortableInfiniteTable from '../../../common/SortableInfiniteTable'
import DateFilter from '../../../common/DateFilter'
import Loading from '../../../common/Loading'
import { DateTime } from 'luxon'
import APICallModal from './APICallModal'

const APICalls = (props) => {
  const { apiKeyId } = props
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [showApiCall, setShowApiCall] = useState()

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query APICalls(
        $cursor: String
        $startDateTimeGte: DateTime
        $startDateTimeLte: DateTime
        $apiKey: ID
      ) {
        apiCalls(
          first: 10
          after: $cursor
          orderBy: "-created"
          created_Gte: $startDateTimeGte
          created_Lte: $startDateTimeLte
          apiKey: $apiKey
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              recordId
              created
              remoteAddress
              apiRequest
              apiResponse
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      // pollInterval: 30000,
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = {
        apiKey: apiKeyId,
      }
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  const fetchMore = () => {
    const variables = {
      cursor: queryData.apiCalls.pageInfo.endCursor,
      apiKey: apiKeyId,
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    queryFetchMore({
      variables,
    })
  }

  useEffect(() => {
    if (initialQueryRun) {
      const variables = {
        cursor: null,
        startDateTimeGte: startDateFilter,
        startDateTimeLte: endDateFilter,
        apiKey: apiKeyId,
      }
      query({
        variables,
      })
    }
  }, [startDateFilter, endDateFilter])

  if (!initialQueryRun || !queryData?.apiCalls)
    return <Loading message="Loading API Calls..." />
  if (queryError) return <>Error loading api calls</>
  return (
    <>
      {showApiCall && (
        <APICallModal
          apiCall={showApiCall}
          showModal={true}
          toggleModal={() => setShowApiCall()}
        />
      )}
      <Row>
        <Col style={{ marginTop: '-8px' }}>
          <DateFilter
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            endDateFilter={endDateFilter}
            setEndDateFilter={setEndDateFilter}
            placeholderStart="Sent From"
            placeholderEnd={'Sent Until'}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12} md={12}>
          <SortableInfiniteTable
            tableData={queryData.apiCalls.edges}
            onRowClick={(row) => {
              setShowApiCall(row.original.node)
            }}
            tableColumns={[
              {
                Header: 'ID',
                id: 'id',
                accessor: 'node.recordId',
              },
              {
                Header: 'Sent',
                id: 'sent',
                accessor: (row) => {
                  return DateTime.fromISO(row.node.created).toFormat(
                    'MMMM d yyyy h:mma'
                  )
                },
              },
              {
                Header: 'Remote Address',
                id: 'remoteAddress',
                accessor: 'node.remoteAddress',
              },
            ]}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={queryData.apiCalls.pageInfo.hasNextPage}
            loadingMessage="Loading API Calls..."
            tableHeight={queryData.apiCalls.edges.length < 20 ? 200 : 600}
            rowPointer
            hideGlobalFilter
          />
        </Col>
      </Row>
    </>
  )
}
export default APICalls
