import React, { useState } from 'react'
import Policies from '../../common/Policies'
import { Row, Col, Button } from 'react-bootstrap'
import PoliciesModal from './PoliciesModal'
import { PlusCircle } from 'react-bootstrap-icons'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../../libs/apollo'

const SettingsPolicies = (props) => {
  const { settings } = props
  const [showModal, setShowModal] = useState(false)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  return (
    <>
      {canMutate && (
        <Row>
          <Col className="mb-2">
            <div>
              <Button variant="link" onClick={() => setShowModal(true)}>
                <PlusCircle className="mr-2" /> Edit Scheduling Policies
              </Button>
            </div>
          </Col>
        </Row>
      )}
      <Policies nodeType="settings" node={settings} />
      <PoliciesModal
        settings={settings}
        toggleModal={setShowModal}
        showModal={showModal}
      />
    </>
  )
}

export default SettingsPolicies
