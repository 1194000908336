import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const OrganizationStageSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    setAdditionalFields,
    error,
    multiple,
    dropdown,
    placeholder,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="organizationStage"
      searchDescription={searchDescription ? searchDescription : 'stages'}
      nodeNamePlural="organizationStages"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      multiple={multiple}
      dropdown={dropdown}
      placeholder={placeholder}
      setAdditionalFields={setAdditionalFields}
      gql={gql`
        query OrganizationStages($first: Int, $after: String, $search: String) {
          organizationStages(
            first: $first
            after: $after
            orderBy: "name"
            name_Icontains: $search
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                name
                color
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return node.name
      }}
    />
  )
}

export default OrganizationStageSearchInput
