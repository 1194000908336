import React from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'

const DeleteProductModal = (props) => {
  const { showModal, toggleModal, id, ids, setCheckedProducts } = props
  let _ids = [id]
  if (ids) {
    _ids = ids
  }

  const [mutation] = useMutation(
    gql`
      mutation DeleteProductInput($deleteProductInput: DeleteProductInput!) {
        deleteProduct(input: $deleteProductInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        if (ids) {
          toast.success('Products Deleted')
        } else {
          toast.success('Product Deleted')
        }
        if (setCheckedProducts) {
          setCheckedProducts([])
        }
        toggleModal()
      },
      refetchQueries: ['Products'],
      errorPolicy: 'all',
    }
  )
  const handleDeleteClick = () => {
    mutation({
      variables: {
        deleteProductInput: {
          productIds: _ids,
        },
      },
    })
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              Delete Product Code{_ids && <>s</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  Are you sure you want to delete the Product Code
                  {ids && <>s</>}?
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  onClick={handleDeleteClick}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DeleteProductModal
