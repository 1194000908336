import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { loggedInUserVar } from '../../libs/apollo'
import toast from 'react-hot-toast'
import {
  CloudArrowDown,
  Cash,
  SlashCircle,
  ReceiptCutoff,
  CaretDown,
  CaretRight,
  XCircle,
  PlusCircle,
} from 'react-bootstrap-icons'
import SortableTable from '../common/SortableTable'
import Sessions from '../sessions/Sessions'
import AuditLog from '../audit_log/AuditLog'
import PaymentsTable from './PaymentsTable'
import NotificationsTable from '../notifications/NotificationsTable'
import Loading from '../common/Loading'

const InvoiceModal = (props) => {
  const { showModal, toggleModal, stripeInvoiceId, hideOrganization } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [displayHistory, setDispalyHistory] = useState(false)
  const [paidManuallyBy, setPaidManuallyBy] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [showInvoiceItems, setShowInvoiceItems] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const [showPaidMaunallyBy, setShowPaidManuallyBy] = useState(false)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)

  const [updateInvoice] = useMutation(
    gql`
      mutation UpdateStripeInvoice(
        $updateStripeInvoiceInput: UpdateStripeInvoiceInput!
      ) {
        updateStripeInvoice(input: $updateStripeInvoiceInput) {
          stripeInvoice {
            id
            paidManually
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        if (data.updateStripeInvoice.stripeInvoice.paidManually) {
          toast.success('Invoice Paid Manually')
        } else {
          toast.success('Invoice Voided')
        }
        toggleModal()
      },
      refetchQueries: [
        'StripeInvoices',
        'StripeInvoice',
        'StripePaymentIntentsQuery',
      ],
      errorPolicy: 'all',
    }
  )

  const [query, { data }] = useLazyQuery(
    gql`
      query StripeInvoice($stripeInvoiceId: ID!) {
        stripeInvoice(id: $stripeInvoiceId) {
          id
          paidManually
          paidManuallyBy
          paidOn
          stripeCustomer {
            organization {
              id
              name
            }
          }
          recordId
          contentType {
            model
          }
          amountDue
          amountPaid
          stripeInvoiceId
          fee
          recipientEmail
          status
          netAmount
          effectiveAt
          dueDate
          paidOn
          invoicePdfUrl
          invoicePaymentUrl
          stripePaymentIntents {
            edges {
              node {
                processing
              }
            }
          }
          stripeInvoiceItems {
            edges {
              node {
                session {
                  id
                }
                price
                stripeTaxRate {
                  taxRate
                }
                product {
                  id
                  name
                }
              }
            }
          }
          products {
            id
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (stripeInvoiceId) {
      query({
        variables: {
          stripeInvoiceId,
        },
      })
    }
  }, [stripeInvoiceId])

  useEffect(() => {
    if (
      data?.stripeInvoice?.paidManuallyBy &&
      showModal &&
      data?.stripeInvoice.id === stripeInvoiceId &&
      !paidManuallyBy
    ) {
      setPaidManuallyBy(data?.stripeInvoice.paidManuallyBy)
      setShowPaidManuallyBy(true)
    } else if (!showModal) {
      setPaidManuallyBy()
      setShowPaidManuallyBy(false)
      setShowInvoiceItems(false)
      setShowNotifications(false)
    }
  }, [data, showModal])

  if (!data?.stripeInvoice) return <></>
  const stripeInvoice = data.stripeInvoice
  let sessionIds = []
  let groupedProductInvoiceItems
  stripeInvoice.stripeInvoiceItems.edges.forEach(({ node }) => {
    if (node.session) {
      sessionIds.push(node.session.id)
    }
  })
  if (stripeInvoice.products.length > 0) {
    groupedProductInvoiceItems = stripeInvoice.stripeInvoiceItems.edges.reduce(
      (acc, { node }) => {
        if (!node.product) return acc

        const taxRate = node.stripeTaxRate ? node.stripeTaxRate.taxRate : 0
        const key = `${node.product.id}-${node.price}-${taxRate}`
        const existingItem = acc.find((item) => item.key === key)

        if (existingItem) {
          existingItem.quantity += 1
        } else {
          acc.push({
            key: key,
            productName: node.product.name,
            price: node.price,
            salesTaxRate: taxRate,
            chargeSalesTax: !!node.stripeTaxRate,
            quantity: 1,
          })
        }

        acc.forEach((item) => {
          const taxMultiplier = 1 + item.salesTaxRate / 100
          item.totalPrice = (
            item.price *
            item.quantity *
            taxMultiplier
          ).toFixed(2)
        })

        return acc
      },
      []
    )
  }
  return (
    <>
      <div className="invoiceModal">
        <Modal size={'xl'} show={showModal} onHide={() => toggleModal()}>
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              <Row>
                <Col>
                  <ReceiptCutoff className="mr-2" />
                  Invoice #{stripeInvoice.stripeInvoiceId}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showPaidMaunallyBy && (
              <Row className="mb-3">
                <Col md={4}>
                  <Form.Label>
                    Paid Manually With
                    {!stripeInvoice.paidManually && (
                      <span
                        type="button"
                        className="p-0 ml-2 btn-link"
                        onClick={() => {
                          setShowPaidManuallyBy(false)
                          setPaidManuallyBy()
                        }}
                      >
                        <XCircle />
                      </span>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={paidManuallyBy}
                    disabled={!canMutate}
                    onChange={(e) => setPaidManuallyBy(e.target.value)}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <p>Invoice Details</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <SortableTable
                  columns={[
                    {
                      Header: 'ID',
                      id: 'id',
                      accessor: () => {
                        return stripeInvoice.recordId
                      },
                    },
                    {
                      Header: 'Status',
                      id: 'status',
                      accessor: () => {
                        if (stripeInvoice.paidManually) {
                          return (
                            <span style={{ color: 'green' }}>
                              Paid Manually
                            </span>
                          )
                        } else if (stripeInvoice.paymentFailed) {
                          return (
                            <span style={{ color: 'red' }}>Payment Failed</span>
                          )
                        } else if (
                          stripeInvoice.stripePaymentIntents.edges.length > 0 &&
                          stripeInvoice.stripePaymentIntents.edges[0].node
                            .processing
                        ) {
                          return (
                            <span style={{ color: 'red' }}>
                              Processing Payment
                            </span>
                          )
                        }
                        const status = stripeInvoice.status
                        let color
                        if (
                          ['Open', 'Uncollectible', 'Past Due'].includes(status)
                        ) {
                          color = 'red'
                        } else if (status === 'Void') {
                          color = 'orange'
                        } else if (status === 'Paid') {
                          color = 'green'
                        }
                        return <span style={{ color }}>{status}</span>
                      },
                    },
                    {
                      Header: 'Amount Due',
                      id: 'due',
                      accessor: () => {
                        return `${(
                          stripeInvoice.amountDue / 100
                        ).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}`
                      },
                    },
                    {
                      Header: 'Amount Paid',
                      id: 'paid',
                      accessor: () => {
                        if (stripeInvoice.paidManually) {
                          return `${(
                            stripeInvoice.amountDue / 100
                          ).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}`
                        } else {
                          return `${(
                            stripeInvoice.amountPaid / 100
                          ).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}`
                        }
                      },
                    },
                    {
                      Header: 'Sent On',
                      id: 'sentOn',
                      accessor: () => {
                        return DateTime.fromISO(
                          stripeInvoice.effectiveAt
                        ).toFormat('MMMM dd, yyyy')
                      },
                    },
                    {
                      Header: 'Due On',
                      id: 'dueOn',
                      accessor: () => {
                        return DateTime.fromISO(stripeInvoice.dueDate).toFormat(
                          'MMMM dd, yyyy'
                        )
                      },
                    },
                    {
                      Header: 'Paid On',
                      id: 'paidOn',
                      accessor: () => {
                        if (stripeInvoice.paidOn) {
                          return DateTime.fromISO(
                            stripeInvoice.paidOn
                          ).toFormat('MMMM dd, yyyy')
                        }
                      },
                    },
                  ]}
                  hideGlobalFilter={true}
                  data={[stripeInvoice]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Payment</p>
              </Col>
            </Row>
            <PaymentsTable
              stripeInvoiceId={stripeInvoiceId}
              invoiceModal
              subjectViewing
            />
            {stripeInvoice.products.length > 0 && (
              <>
                <Row className="mt-3 mb-2">
                  <Col className="d-flex align-items-center">
                    <button
                      type="button"
                      onClick={() => setShowInvoiceItems(!showInvoiceItems)}
                      className="px-0 btn-link mr-1"
                      style={{ marginTop: '-10px' }}
                    >
                      <>
                        {showInvoiceItems ? (
                          <CaretDown size={17} />
                        ) : (
                          <CaretRight size={17} />
                        )}
                      </>
                    </button>
                    <Form.Label>Products</Form.Label>
                  </Col>
                </Row>
                {showInvoiceItems && (
                  <Row>
                    <Col>
                      <SortableTable
                        columns={[
                          {
                            Header: 'Product',
                            accessor: 'productName',
                          },
                          {
                            Header: 'Amount',
                            accessor: (item) =>
                              `$${parseFloat(item.price).toFixed(2)}`,
                          },
                          {
                            Header: 'Tax Rate',
                            accessor: (item) => `${item.salesTaxRate}%`,
                          },
                          {
                            Header: 'Quantity',
                            accessor: 'quantity',
                          },
                          {
                            Header: 'Total Amount',
                            accessor: (item) =>
                              `$${parseFloat(item.totalPrice).toFixed(2)}`,
                          },
                        ]}
                        hideGlobalFilter={true}
                        data={groupedProductInvoiceItems}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
            {sessionIds.length > 0 && (
              <Sessions
                sessionIds={sessionIds}
                invoiceModal
                hideOrganization={hideOrganization}
              />
            )}
            <Row className="mt-2 mb-2">
              <Col className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => setShowNotifications(!showNotifications)}
                  className="px-0 btn-link mr-1"
                  style={{ marginTop: '-10px' }}
                >
                  <>
                    {showNotifications ? (
                      <CaretDown size={17} />
                    ) : (
                      <CaretRight size={17} />
                    )}
                  </>
                </button>
                <Form.Label>Notifications</Form.Label>
              </Col>
            </Row>
            {showNotifications && (
              <NotificationsTable
                stripeInvoice={stripeInvoice.id}
                tableHeight={300}
              />
            )}
            {!loggedInUser.permissions.isOrgContact &&
              !loggedInUser.permissions.isSubject && (
                <>
                  <Row className="mt-2 mb-2">
                    <Col className="d-flex align-items-center">
                      <button
                        type="button"
                        onClick={() => setDispalyHistory(!displayHistory)}
                        className="px-0 btn-link mr-1"
                        style={{ marginTop: '-10px' }}
                      >
                        <>
                          {displayHistory ? (
                            <CaretDown size={17} />
                          ) : (
                            <CaretRight size={17} />
                          )}
                        </>
                      </button>
                      <Form.Label>History</Form.Label>
                    </Col>
                  </Row>
                  {displayHistory && (
                    <Row>
                      <Col md={12}>
                        <AuditLog
                          tableHeight={300}
                          contentType={stripeInvoice.contentType.model}
                          id={stripeInvoice?.id}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
            <Row>
              <Col md={3}>
                <Button
                  block
                  variant="outline-primary"
                  onClick={() => {
                    window.open(stripeInvoice.invoicePaymentUrl, '_blank')
                  }}
                >
                  <Cash /> Open Stripe
                </Button>
              </Col>
              <Col md={3}>
                <Button variant="outline-primary" block>
                  <a href={stripeInvoice.invoicePdfUrl} download>
                    <CloudArrowDown /> Download
                  </a>
                </Button>
              </Col>
              {canMutate &&
                !stripeInvoice.paidManually &&
                ['open', 'past due'].includes(
                  stripeInvoice.status?.toLowerCase()
                ) && (
                  <Col md={3}>
                    <Button
                      block
                      disabled={submitting}
                      variant="outline-primary"
                      onClick={() => {
                        if (!showPaidMaunallyBy) {
                          setShowPaidManuallyBy(true)
                        } else {
                          if (paidManuallyBy) {
                            setSubmitting(true)
                            updateInvoice({
                              variables: {
                                updateStripeInvoiceInput: {
                                  stripeInvoiceInput: {
                                    id: stripeInvoiceId,
                                    paidManually: true,
                                    paidManuallyBy,
                                  },
                                },
                              },
                            })
                          } else {
                            toast.error('Paid Manually With Required')
                          }
                        }
                      }}
                    >
                      <ReceiptCutoff /> Paid Manually
                    </Button>
                  </Col>
                )}
              {canMutate && stripeInvoice.paidManually && (
                <Col md={3}>
                  <Button
                    block
                    variant="outline-primary"
                    disabled={submitting}
                    onClick={() => {
                      if (paidManuallyBy) {
                        setSubmitting(true)
                        updateInvoice({
                          variables: {
                            updateStripeInvoiceInput: {
                              stripeInvoiceInput: {
                                id: stripeInvoiceId,
                                paidManually: true,
                                paidManuallyBy,
                              },
                            },
                          },
                        })
                      } else {
                        toast.error('Paid Manually With Required')
                      }
                    }}
                  >
                    <PlusCircle /> Paid Manually
                  </Button>
                </Col>
              )}
              {canMutate &&
                ['open', 'past due'].includes(
                  stripeInvoice.status?.toLowerCase()
                ) && (
                  <Col md={3}>
                    <Button
                      block
                      variant="outline-danger"
                      disabled={submitting}
                      onClick={() => {
                        setSubmitting(true)
                        updateInvoice({
                          variables: {
                            updateStripeInvoiceInput: {
                              stripeInvoiceInput: {
                                id: stripeInvoiceId,
                                void: true,
                              },
                            },
                          },
                        })
                      }}
                    >
                      <SlashCircle /> Void
                    </Button>
                  </Col>
                )}
            </Row>
            {submitting && (
              <div className="mt-3">
                <Loading />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default InvoiceModal
