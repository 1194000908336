import React from 'react'
import { Row, Modal, Col, Button } from 'react-bootstrap'

const UpsellDescriptionModal = ({
  upsellModalOpen,
  setUpsellOpenModal,
  sessionPackage,
  handleSessionPackageClick,
  setIsUpselling,
}) => {
  return (
    <>
      <Modal
        size={'lg'}
        show={upsellModalOpen}
        onHide={() => {
          setUpsellOpenModal(false)
          setIsUpselling(true)
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Upgrade Your Session!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-1">
            <Col md={{ span: 10, offset: 1 }}>
              {sessionPackage?.upsellDescription}
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              <div
                className="mb-4 mt-4"
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Button
                  type="submit"
                  variant="outline-primary btn-sm"
                  onClick={() => {
                    setIsUpselling(true)
                    setUpsellOpenModal(false)
                  }}
                >
                  Upgrade Session Package
                </Button>
                <Button
                  variant="outline-danger"
                  className="ml-2 btn-sm"
                  onClick={() => {
                    handleSessionPackageClick(sessionPackage)
                  }}
                >
                  Continue with {sessionPackage?.title}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UpsellDescriptionModal
