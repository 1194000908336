import React from 'react'
import { Modal } from 'react-bootstrap'
import SubjectForm from '../../subject/SubjectForm'
import { Person } from 'react-bootstrap-icons'

export default function ReviewSubjectModal(props) {
  const {
    subjectId,
    showReviewSubjectModal,
    reviewSubjectModalShowChange,
    onCompleted,
  } = props
  return (
    <Modal
      show={showReviewSubjectModal}
      onHide={reviewSubjectModalShowChange}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Person className="mr-2" />
          Subject Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SubjectForm
          onCompleted={onCompleted}
          showFormBorder={false}
          displayBilling
          studentViewing={false}
          displayBasic={false}
          subjectId={subjectId}
          subjectViewing={false}
          bookSessionFlow={false}
        />
      </Modal.Body>
    </Modal>
  )
}
