import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useMutation } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import {
  BriefcaseFill,
  CloudArrowDown,
  Dot,
  FileEarmarkText,
  PlusCircle,
  Trash,
} from 'react-bootstrap-icons'
import BagTypeModal from './BagTypeModal'
import DeleteBagTypeModal from './DeleteBagTypeModal'
import toast from 'react-hot-toast'
import { useDownloadFile } from '../../../libs/downloadFile'

const BagTypes = () => {
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [checkedIds, setCheckedIds] = useState([])
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [hasMoreData, setHasMoreData] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [editBagType, setEditBagType] = useState()
  const [downloadPdfLoading, setDownloadPdfLoading] = useState(false)
  const { downloadAndDeleteFile } = useDownloadFile()
  const defaultOrderBy = 'name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query EquipmentBagTypes(
        $cursor: String
        $searchTerm: String
        $orderBy: String
      ) {
        equipmentBagTypes(
          first: 20
          after: $cursor
          name_Icontains: $searchTerm
          orderBy: $orderBy
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              name
              contentType {
                model
                id
              }
              equipmentBagCount
              equipmentItemTypes {
                edges {
                  node {
                    id
                    name
                    miscellaneous
                  }
                }
              }
              equipmentItemTypesList {
                id
                name
                miscellaneous
              }
              equipmentCategory {
                name
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({
        variables: {
          orderBy: defaultOrderBy,
        },
      })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.equipmentBagTypes) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      setHasMoreData(queryData?.equipmentBagTypes?.pageInfo?.hasNextPage)
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.equipmentBagTypes?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    queryFetchMore({
      variables,
    })
  }

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedIds((prevState) => prevState.filter((id) => id !== row.node.id))
    }
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setLoadingSearch(true)
    query({
      variables: {
        searchTerm: currentSearchTerm,
      },
    })
    const variables = {
      searchTerm: currentSearchTerm,
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    query({ variables })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    query({ variables })
  }

  const onCellClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditBagType(node)
    } else {
      setEditBagType()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  const [downloadBagTypesPdf] = useMutation(
    gql`
      mutation EquipmentBagTypesPdf($input: EquipmentBagTypesPdfInput!) {
        equipmentBagTypesPdf(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.equipmentBagTypesPdf?.file?.fileName) {
          downloadAndDeleteFile(
            data.equipmentBagTypesPdf.file.fileName,
            data.equipmentBagTypesPdf.file.displayName,
            data.equipmentBagTypesPdf.file.id,
            () => {
              toast.success(
                `Bag ${checkedIds.length > 0 ? 'Types' : 'Type'} Downloaded`
              )
              setDownloadPdfLoading(false)
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      <div>
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={'Search Bag Types'}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <div>
              <Button
                variant="link"
                onClick={() => {
                  toggleModal()
                }}
              >
                <PlusCircle className="mr-2" />
                New Bag Type
              </Button>
              {checkedIds.length > 0 && (
                <>
                  <Button
                    variant="link"
                    disabled={downloadPdfLoading}
                    onClick={() => {
                      setDownloadPdfLoading(true)
                      downloadBagTypesPdf({
                        variables: {
                          input: {
                            equipmentBagTypeIds: checkedIds,
                          },
                        },
                      })
                    }}
                  >
                    <CloudArrowDown className="mr-2" />
                    Download PDF
                  </Button>

                  <Button
                    variant="link"
                    onClick={() => {
                      setShowDeleteModal(true)
                    }}
                  >
                    <Trash className="mr-2" />
                    {checkedIds.length === 1 ? (
                      <>Delete Bag Type</>
                    ) : (
                      <>Delete Bag Types</>
                    )}
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-2 mb-3">
          <Col>
            <SortableInfiniteTable
              loading={loadingSearch || !queryData}
              tableData={
                queryData?.equipmentBagTypes.edges
                  ? queryData?.equipmentBagTypes.edges
                  : []
              }
              loadingMessage="Loading Bag Types..."
              tableColumns={[
                {
                  Header: 'Name',
                  id: 'name',
                  accessor: 'node.name',
                  serverSort: true,
                },
                {
                  Header: 'Item Types',
                  id: 'itemTypes',
                  accessor: (row) => {
                    if (row.node.equipmentItemTypesList) {
                      let itemCounts = row.node.equipmentItemTypesList.reduce(
                        (acc, curr) => {
                          acc[curr.id] = acc[curr.id] || {
                            count: 0,
                            name: curr.name,
                          }
                          acc[curr.id].count++
                          return acc
                        },
                        {}
                      )

                      return (
                        <>
                          <div
                            style={{
                              maxHeight: '100px',
                              overflowY: 'scroll',
                              overflowX: 'hidden',
                              fontSize: '12px',
                            }}
                            className="mt-2"
                          >
                            {Object.entries(itemCounts).map(
                              ([id, { count, name }]) => (
                                <React.Fragment key={id}>
                                  <Dot className="mr-1" />
                                  <span
                                    style={{
                                      fontSize: '12px',
                                    }}
                                  >
                                    {name} ({count})
                                  </span>
                                  <br />
                                </React.Fragment>
                              )
                            )}
                          </div>
                        </>
                      )
                    }
                  },
                },
                {
                  Header: 'Bags',
                  accessor: 'node.equipmentBagCount',
                },
                {
                  disableSortBy: true,
                  Header: 'Download',
                  id: 'downloads',
                  accessor: (row) => {
                    return (
                      <Button
                        className="ml-1"
                        variant="link"
                        onClick={() => {
                          downloadBagTypesPdf({
                            variables: {
                              input: {
                                equipmentBagTypeIds: [row.node.id],
                              },
                            },
                          })
                        }}
                      >
                        <FileEarmarkText />
                      </Button>
                    )
                  },
                },
                {
                  disableSortBy: true,
                  Header: (
                    <>
                      <Form.Group
                        as={ButtonGroup}
                        className="align-items-center"
                      >
                        <Form.Check
                          className="ml-2 mt-2"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              const appendIds = []
                              queryData?.equipmentBagTypes.edges.forEach(
                                (equipmentBagType) => {
                                  if (
                                    !checkedIds.includes(
                                      equipmentBagType.node.id
                                    )
                                  ) {
                                    appendIds.push(equipmentBagType.node.id)
                                  }
                                }
                              )
                              setCheckedIds((prevState) => {
                                return [...prevState, ...appendIds]
                              })
                            } else {
                              setCheckedIds([])
                            }
                          }}
                        />
                        {checkedIds.length > 0 && (
                          <span style={{ fontSize: '14px', marginTop: '5px' }}>
                            ({checkedIds.length})
                          </span>
                        )}
                      </Form.Group>
                    </>
                  ),
                  id: 'actions',
                  accessor: (row) => {
                    return (
                      <>
                        <Form.Group
                          as={ButtonGroup}
                          className="align-items-center"
                        >
                          <Form.Check
                            className="ml-2 mt-2"
                            type="checkbox"
                            checked={checkedIds.includes(row.node.id)}
                            onChange={(e) => handleCheck(e, row)}
                          />
                        </Form.Group>
                      </>
                    )
                  },
                },
              ]}
              fetchMoreTableData={fetchMore}
              hasMoreTableData={hasMoreData}
              onTdClicks={{
                name: (cell) => onCellClick(cell),
                itemTypes: (cell) => onCellClick(cell),
              }}
              tableHeight={700}
              rowPointer
              hideGlobalFilter
              handleSortByChange={handleSortByChange}
            />
          </Col>
        </Row>
        {showForm ? (
          <BagTypeModal
            showModal={showForm}
            bagType={editBagType}
            toggleModal={toggleModal}
          />
        ) : null}
        <DeleteBagTypeModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedIds={setCheckedIds}
          ids={checkedIds.length > 1 ? checkedIds : null}
          id={checkedIds.length === 1 ? checkedIds[0] : null}
        />
      </div>
    </>
  )
}
export default BagTypes
