import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Form, Button } from 'react-bootstrap'
import Report from '../chart/LineChart'
import DateFilter from '../common/DateFilter'
import { useLazyQuery, gql } from '@apollo/client'
import Loading from '../common/Loading'
import { Funnel, ReceiptCutoff } from 'react-bootstrap-icons'

const InvoiceChartModal = (props) => {
  const {
    showModal,
    toggleModal,
    stripeCustomerId,
    organizationId,
    subjectGroupId,
    productId,
    jobId,
    gaiaUserId,
  } = props

  const [chartSeries, setChartSeries] = useState([])
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [totalPaidRevenue, setTotalPaidRevenue] = useState(0)
  const [averageRevenue, setAverageRevenue] = useState(0)
  const [chartCategories, setChartCategories] = useState([])
  const [invoiceCount, setInvoiceCount] = useState(0)
  const [averageDays, setAverageDays] = useState(1)
  const [startDateFilter, setStartDateFilter] = useState()
  const [stripeInvoices, setStripeInvoices] = useState([])
  const [revenueType, setRevenueType] = useState('Total Revenue')
  const [fetchedAllStripeInvoices, setFetchedAllStripeInvoices] =
    useState(false)
  const [endDateFilter, setEndDateFilter] = useState()
  const [filterPaid, setFilterPaid] = useState(false)
  const [filterVoid, setFilterVoid] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)

  const generateDateRange = (start, end) => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const dates = []
    let currentDate = startDate
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split('T')[0])
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
    }
    return dates
  }

  const [query, { data: stripeInvoiceData }] = useLazyQuery(
    gql`
      query StripeInvoices(
        $cursor: String
        $searchTerm: String
        $stripeCustomerId: ID
        $organizationId: ID
        $productId: ID
        $job: String
        $gaiaUserId: ID
        $subjectGroup: ID
        $startDateGte: DateTime
        $startDateLte: DateTime
        $paid: Boolean
        $void: Boolean
        $status: String
      ) {
        stripeInvoices(
          first: 250
          after: $cursor
          paid: $paid
          void: $void
          job: $job
          status: $status
          stripeCustomer: $stripeCustomerId
          stripeCustomer_Organization: $organizationId
          created_Gte: $startDateGte
          created_Lte: $startDateLte
          stripeInvoiceItems_Session_SubjectGroup: $subjectGroup
          stripeCustomer_Organization_Name_Icontains: $searchTerm
          stripeCustomer_GaiaUser: $gaiaUserId
          stripeInvoiceItems_Product: $productId
          orderBy: "-created"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              created
              price
              voided
              paidAmount
              paidManually
              netAmount
              fee
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const queryVariables = () => {
    const variables = {
      startDateGte: startDateFilter,
      startDateLte: endDateFilter,
    }
    if (stripeCustomerId) {
      variables.stripeCustomerId = stripeCustomerId
    }
    if (organizationId) {
      variables.organizationId = organizationId
    }
    if (subjectGroupId) {
      variables.subjectGroup = subjectGroupId
    }
    if (productId) {
      variables.productId = productId
    }
    if (jobId) {
      variables.job = jobId
    }
    if (gaiaUserId) {
      variables.gaiaUserId = gaiaUserId
    }
    if (filterOpen) {
      variables.status = 'Open'
    }
    if (filterPaid) {
      variables.paid = true
    }
    if (filterVoid) {
      variables.void = true
    }
    return variables
  }

  useEffect(() => {
    if (startDateFilter && endDateFilter) {
      const daysDiff = Math.max(
        1,
        Math.ceil(
          (new Date(endDateFilter) - new Date(startDateFilter)) /
            (1000 * 60 * 60 * 24)
        ) + 1
      )
      setAverageDays(daysDiff)
      query({ variables: queryVariables() })
    }
    setFetchedAllStripeInvoices(false)
    setChartSeries([])
    setStripeInvoices([])
    setChartCategories([])
    setInvoiceCount(0)
  }, [startDateFilter, endDateFilter, filterOpen, filterPaid, filterVoid])

  useEffect(() => {
    if (stripeInvoiceData?.stripeInvoices) {
      setStripeInvoices((prevState) => [
        ...prevState,
        ...stripeInvoiceData.stripeInvoices.edges,
      ])
      if (stripeInvoiceData.stripeInvoices.pageInfo.hasNextPage) {
        const variables = queryVariables()
        variables.cursor = stripeInvoiceData.stripeInvoices.pageInfo.endCursor
        query({ variables })
      } else {
        setFetchedAllStripeInvoices(true)
      }
    }
  }, [stripeInvoiceData])

  useEffect(() => {
    if (fetchedAllStripeInvoices) {
      const dateRange = generateDateRange(startDateFilter, endDateFilter)
      const invoices = stripeInvoices.map((edge) => ({
        netAmount: edge.node.netAmount,
        fee: edge.node.fee,
        amountDue: edge.node.price,
        paidAmount: edge.node.paidAmount,
        date: new Date(edge.node.created).toISOString().split('T')[0],
      }))

      const aggregatedAmount = invoices.reduce((acc, { date, amountDue }) => {
        acc[date] = (acc[date] || 0) + amountDue
        return acc
      }, {})
      const aggregatedNetAmount = invoices.reduce(
        (acc, { date, netAmount }) => {
          acc[date] = (acc[date] || 0) + netAmount
          return acc
        },
        {}
      )
      const aggregatedFeeAmount = invoices.reduce((acc, { date, fee }) => {
        acc[date] = (acc[date] || 0) + fee
        return acc
      }, {})
      const aggregatedPaidAmount = invoices.reduce(
        (acc, { date, paidAmount }) => {
          acc[date] = (acc[date] || 0) + paidAmount
          return acc
        },
        {}
      )

      setChartCategories(dateRange)

      const totalRevenue_ = invoices.reduce(
        (acc, invoice) => acc + invoice.amountDue,
        0
      )
      const totalPaidRevenue_ = invoices.reduce(
        (acc, invoice) => acc + invoice.paidAmount,
        0
      )

      setTotalRevenue(
        totalRevenue_.toLocaleString('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      )

      setTotalPaidRevenue(
        totalPaidRevenue_.toLocaleString('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      )

      const avgInvoice =
        invoices.length > 0
          ? (parseFloat(totalRevenue_) / invoices.length).toLocaleString(
              'en-US',
              {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )
          : '0.00'

      setAverageRevenue(avgInvoice)
      setInvoiceCount(invoices.length)

      let total = []
      let net = []
      let fee = []
      let paid = []

      if (revenueType === 'Daily Revenue') {
        total = dateRange.map((date) => aggregatedAmount[date] || 0)
        net = dateRange.map((date) => aggregatedNetAmount[date] || 0)
        fee = dateRange.map((date) => aggregatedFeeAmount[date] || 0)
        paid = dateRange.map((date) => aggregatedPaidAmount[date] || 0)
      } else {
        let runningTotal = 0
        total = dateRange.map((date) => {
          runningTotal += aggregatedAmount[date] || 0
          return runningTotal
        })

        let runningNetTotal = 0
        net = dateRange.map((date) => {
          runningNetTotal += aggregatedNetAmount[date] || 0
          return runningNetTotal
        })

        let runningFeeTotal = 0
        fee = dateRange.map((date) => {
          runningFeeTotal += aggregatedFeeAmount[date] || 0
          return runningFeeTotal
        })

        let runningPaidTotal = 0
        paid = dateRange.map((date) => {
          runningPaidTotal += aggregatedPaidAmount[date] || 0
          return runningPaidTotal
        })
      }

      setChartSeries([
        { name: 'Total Revenue Billed', data: total },
        { name: 'Total Revenue Received', data: paid },
        { name: 'Net Revenue', data: net },
        { name: 'Stripe Fees', data: fee },
      ])
    }
  }, [fetchedAllStripeInvoices, startDateFilter, endDateFilter, revenueType])

  const innerToggle = () => {
    setChartCategories([])
    setChartSeries([])
    toggleModal()
  }

  return (
    <>
      <div className="invoiceModal">
        <Modal size={'xl'} show={showModal} onHide={innerToggle}>
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              <ReceiptCutoff className="mr-2" />
              Invoice Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2">
              <Col md={5}>
                <DateFilter
                  startDateFilter={startDateFilter}
                  setStartDateFilter={setStartDateFilter}
                  endDateFilter={endDateFilter}
                  setEndDateFilter={setEndDateFilter}
                  placeholderStart="Invoices From"
                  placeholderEnd={'Invoices Until'}
                />
              </Col>
              <Col className="mt-2" md={3}>
                <Form.Control
                  as="select"
                  className="form-control-sm"
                  value={revenueType}
                  onChange={(e) => setRevenueType(e.target.value)}
                >
                  <option value="Daily Revenue">Daily Revenue</option>
                  <option value="Total Revenue">Total Revenue</option>
                </Form.Control>
              </Col>
            </Row>
            {startDateFilter && endDateFilter && (
              <Row className="mb-4">
                <Col>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilterPaid(!filterPaid)
                    }}
                  >
                    <span>
                      <Funnel className="mr-2" />
                      {!filterPaid ? <>Paid</> : <>All</>}
                    </span>
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilterOpen(!filterOpen)
                    }}
                  >
                    <span>
                      <Funnel className="mr-2" />
                      {!filterOpen ? <>Open</> : <>All</>}
                    </span>
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilterVoid(!filterVoid)
                    }}
                  >
                    <span>
                      <Funnel className="mr-2" />
                      {!filterVoid ? <>Void</> : <>All</>}
                    </span>
                  </Button>
                </Col>
              </Row>
            )}
            {startDateFilter && endDateFilter && !fetchedAllStripeInvoices && (
              <Row className="mt-4">
                <Col>
                  <Loading message="Loading Invoice Chart..." />
                </Col>
              </Row>
            )}
            {fetchedAllStripeInvoices && (
              <>
                <div className="statistics-summary">
                  <Row className="mb-4 justify-content-md-center">
                    {/* For Total Revenue */}
                    <Col md={2} className="statistic">
                      <p style={{ fontSize: '18px' }}>Average Invoice</p>
                      <p>${averageRevenue}</p>
                    </Col>

                    <Col md={3} className="statistic">
                      <p style={{ fontSize: '18px' }}>
                        {revenueType === 'Daily Revenue'
                          ? 'Average Revenue Billed Per Day'
                          : 'Total Revenue Billed'}
                      </p>
                      <p>
                        $
                        {revenueType === 'Daily Revenue'
                          ? (totalRevenue / averageDays).toLocaleString(
                              'en-US',
                              {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : totalRevenue}
                      </p>
                    </Col>

                    {/* For Total Paid Revenue */}
                    <Col md={3} className="statistic">
                      <p style={{ fontSize: '18px' }}>
                        {revenueType === 'Daily Revenue'
                          ? 'Average Revenue Paid Per Day'
                          : 'Total Revenue Paid'}
                      </p>
                      <p>
                        $
                        {revenueType === 'Daily Revenue'
                          ? (totalPaidRevenue / averageDays).toLocaleString(
                              'en-US',
                              {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : totalPaidRevenue}
                      </p>
                    </Col>
                    {/* For Number of Invoices */}
                    <Col md={3} className="statistic">
                      <p style={{ fontSize: '18px' }}>
                        {revenueType === 'Daily Revenue'
                          ? 'Average Number of Invoices Per Day'
                          : 'Total Invoices'}
                      </p>
                      <p>
                        {revenueType === 'Daily Revenue'
                          ? (invoiceCount / averageDays).toLocaleString(
                              'en-US',
                              {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : invoiceCount.toLocaleString('en-US', {
                              style: 'decimal',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                      </p>
                    </Col>
                  </Row>
                </div>
                {chartSeries.length > 0 && (
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Report
                        series={chartSeries}
                        categories={chartCategories}
                        yAxisFormatter={(value) => {
                          if (value < 0.01 && value > -0.01) {
                            return '$0'
                          }
                          return `$${value}`
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default InvoiceChartModal
