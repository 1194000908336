import React from 'react'
import { useState, useEffect } from 'react'
import { Nav, Navbar, Image, Button, Container } from 'react-bootstrap'
import { useAWSS3 } from '../../libs/aws'
import GlobalSearch from './GlobalSearch'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'

const Header = (props) => {
  const { signUp, borderBottom } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [logoImage, setLogoImage] = useState()
  let awsS3
  if (!signUp) {
    awsS3 = useAWSS3()
  }
  let settings = useReactiveVar(settingsVar)

  useEffect(() => {
    if (!signUp && settings && awsS3?.client) {
      getS3Object(settings.thumbnailImageName)
    }
  }, [signUp, settings, awsS3?.client])

  async function getS3Object(Key) {
    await awsS3.client.getObject(
      { Bucket: awsS3.bucket, Key },
      (error, data) => {
        if (!error) {
          setLogoImage(awsS3.encodeS3ImageData(data.Body))
        }
      }
    )
  }

  if (!signUp && !settings) return <></>
  return (
    <>
      <div
        style={{
          backgroundColor: signUp ? '#ffffff' : settings.colorScheme,
          borderBottom: borderBottom ? '1px solid #dee2e6' : '',
        }}
        className="header"
      >
        <Navbar expand="lg" className="ps-md-0">
          <Container fluid className="ps-md-1 pr-0 mr-0">
            <Navbar.Brand href="/">
              {signUp && (
                <Image
                  style={{ height: '35px' }}
                  src={`${process.env.PUBLIC_URL}/airstudio.png`}
                  alt="Studio logo"
                />
              )}
              {!signUp && (
                <>
                  {settings.displayLogoInNav && logoImage ? (
                    <Image
                      style={{ height: '35px' }}
                      src={`data:image/jpeg;base64,${logoImage}`}
                      alt="Studio logo"
                    />
                  ) : settings.displayNameInNav ? (
                    <span className="">{settings.name}</span>
                  ) : null}
                </>
              )}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto d-flex align-items-center">
                {loggedInUser?.permissions.isEmployee &&
                  loggedInUser.permissions.group !== 'General Staff' && (
                    <GlobalSearch />
                  )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  )
}

export default Header
