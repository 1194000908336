import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import {
  Dot,
  Funnel,
  PlusCircle,
  Trash,
  CheckSquare,
} from 'react-bootstrap-icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Loading from '../common/Loading'
import TaskModal from './TaskModal'
import { loggedInUserVar } from '../../libs/apollo'
import AddTaskCollectionsModal from './AddTaskCollectionsModal'
import DeleteTaskModal from './DeleteTaskModal'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import JobDetailModal from '../schedule/JobDetailModal'
import TagSearchInput from '../common/node_search_input/TagSearchInput'
import { DateTime } from 'luxon'
import CompleteTasksModal from './CompleteTasksModal'
import { useLocation } from 'react-router-dom'

const Tasks = (props) => {
  const {
    organizationId,
    organizationName,
    subjectGroupId,
    subjectGroupName,
    employeeId,
    jobId,
    jobName,
    fetchPolicy,
  } = props
  const { contact } = useParams()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const history = useHistory()
  const location = useLocation()
  const defaultOrderBy = '-due_date'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [archive, setArchive] = useState(false)
  const [myTasks, setMyTasks] = useState(false)
  const [jobModalId, setJobModalId] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showCompleteModal, setShowCompleteModal] = useState(false)
  const [pastDueTasks, setPastDueTasks] = useState(false)
  const [fetchMoreData, setFetchMoreData] = useState(false)
  const [checkedIds, setCheckedIds] = useState([])
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [hasMoreData, setHasMoreData] = useState(false)
  const [showTaskModal, setShowTaskModal] = useState(false)
  const [showCollectionsModal, setShowCollectionsModal] = useState(false)
  const [taskData, setTaskData] = useState('')
  const [tasks, setTasks] = useState([])

  const isPastDue = (row) => {
    const today = new Date()
    const dueDate = row.dueDate ? new Date(row.dueDate) : null
    return dueDate && dueDate < today && row.status !== 'Complete'
  }

  const toggleModalShow = () => {
    setShowTaskModal(!showTaskModal)
    if (taskData) {
      setTaskData({})
    }
  }

  const handleTaskCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedIds((prevState) => [...prevState, row.id])
    } else {
      setCheckedIds((prevState) => prevState.filter((id) => id !== row.id))
    }
  }

  const formikTags = useFormik({
    initialValues: {
      tags: [],
    },
    validationSchema: Yup.object().shape({
      tags: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          description: Yup.string().nullable(),
        })
      ),
    }),
  })

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (row) => (
        <span style={isPastDue(row) ? { color: 'red' } : {}}>
          {row.description}
        </span>
      ),
      serverSort: true,
      orderBy: 'description',
    },
    {
      Header: 'Organization',
      id: 'organization',
      accessor: (row) => {
        if (row.organization) {
          return <span className="btn-link">{row.organization.name}</span>
        }
      },
      serverSort: true,
      orderBy: 'organization__name',
    },
    {
      Header: 'Job',
      id: 'job',
      accessor: (row) => {
        if (row.job) {
          return <span className="btn-link">{row.job.name}</span>
        }
      },
      serverSort: true,
      orderBy: 'job__name',
    },
    {
      Header: 'Subject Group',
      id: 'subjectGroup',
      accessor: (row) => {
        if (row.subjectGroup) {
          return <span className="btn-link">{row.subjectGroup.name}</span>
        }
      },
      serverSort: true,
      orderBy: 'subject_group__name',
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: (row) => {
        let status
        if (row.status) {
          status = row.status.name
        }
        return (
          <span style={isPastDue(row) ? { color: 'red' } : {}}>{status}</span>
        )
      },
      serverSort: true,
      orderBy: 'status__name',
    },
    {
      Header: 'Event On',
      id: 'eventDate',
      accessor: (row) => {
        let eventDate
        if (row.eventDate) {
          eventDate = DateTime.fromISO(row.eventDate).toFormat('LLL dd yyyy')
        }
        return (
          <span style={isPastDue(row) ? { color: 'red' } : {}}>
            {eventDate}
          </span>
        )
      },
      serverSort: true,
      orderBy: 'event_date',
    },
    {
      Header: 'Due On',
      id: 'dueDate',
      accessor: (row) => {
        let dueDate
        if (row.dueDate) {
          dueDate = DateTime.fromISO(row.dueDate).toFormat('LLL dd yyyy')
        }
        return (
          <span style={isPastDue(row) ? { color: 'red' } : {}}>{dueDate}</span>
        )
      },
      serverSort: true,
      orderBy: 'due_date',
    },
    {
      Header: 'Completed On',
      id: 'finishedDate',
      accessor: (row) => {
        let finishedDate
        if (row.finishedDate) {
          finishedDate = DateTime.fromISO(row.finishedDate).toFormat(
            'LLL dd yyyy'
          )
        }
        return (
          <span style={isPastDue(row) ? { color: 'red' } : {}}>
            {finishedDate}
          </span>
        )
      },
      serverSort: true,
      orderBy: 'finished_date',
    },
    {
      Header: 'Completed By',
      id: 'completedBy',
      accessor: (row) => (
        <span style={isPastDue(row) ? { color: 'red' } : {}}>
          {row.completedByEmployee
            ? row.completedByEmployee.gaiaUser.abbreviatedName ||
              row.completedByEmployee.gaiaUser.fullName
            : ''}
        </span>
      ),
      serverSort: true,
      orderBy: 'completed_by_employee__gaia_user__full_name',
    },
    {
      Header: 'Assigned To',
      id: 'employees',
      accessor: (row) => (
        <div
          style={
            isPastDue(row)
              ? {
                  maxHeight: '50px',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  fontSize: '12px',
                  color: 'red',
                }
              : {
                  maxHeight: '50px',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  fontSize: '12px',
                }
          }
          className="mt-2"
        >
          {row.employees.edges.map((employee) => (
            <>
              <span key={employee.node.id}>
                <Dot className="mr-1" />
                {employee.node.gaiaUser.abbreviatedName ||
                  employee.node.gaiaUser.fullName}
              </span>
              <br />
            </>
          ))}
        </div>
      ),
    },
    {
      Header: 'Contacts',
      id: 'contacts',
      accessor: (row) => (
        <div style={isPastDue(row) ? { color: 'red' } : {}}>
          {row.contacts.edges.map((contact) => (
            <p key={contact.node.id}>
              <Dot className="mr-1" />
              <span style={{ fontSize: '12px' }}>
                {contact.node.fullName}, {contact.node.email}
              </span>
            </p>
          ))}
        </div>
      ),
    },
    {
      Header: 'Tags',
      id: 'tags',
      accessor: (row) => (
        <div style={isPastDue(row) ? { color: 'red' } : {}}>
          {row.tags.map((tag, index) => (
            <div
              key={index}
              className="mt-2"
              style={{
                maxHeight: '50px',
                overflowY: 'scroll',
                overflowX: 'hidden',
                fontSize: '12px',
              }}
            >
              <Dot className="mr-2" />
              {tag.name}
              <br />
            </div>
          ))}
        </div>
      ),
    },
  ]

  if (
    ['Administrator', 'Scheduling Manager'].includes(
      loggedInUser?.permissions?.group
    )
  ) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  tasks.forEach((task) => {
                    if (!checkedIds.includes(task.id)) {
                      appendIds.push(task.id)
                    }
                  })
                  setCheckedIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedIds([])
                }
              }}
            />
            {checkedIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedIds.includes(row.id)}
                onChange={(e) => handleTaskCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }

  if (organizationId) {
    tableColumns.splice(1, 1)
  }
  if (jobId) {
    tableColumns.splice(2, 1)
  }
  if (subjectGroupId) {
    tableColumns.splice(3, 1)
  }

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Tasks(
        $organizationId: ID
        $subjectGroupId: ID
        $jobId: ID
        $myTasks: Boolean
        $pastDueTasks: Boolean
        $employeeId: ID
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $archive: Boolean
        $tags: String
      ) {
        tasks(
          first: 50
          myTasks: $myTasks
          pastDueTasks: $pastDueTasks
          after: $cursor
          tags: $tags
          orderBy: $orderBy
          search: $searchTerm
          organization_Id: $organizationId
          subjectGroup_Id: $subjectGroupId
          job_Id: $jobId
          employees_Id: $employeeId
          archive: $archive
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              description
              notes
              dueDate
              archive
              sharedCanSeeFiles
              contentType {
                model
              }
              folder {
                id
              }
              sharedFolder {
                id
              }
              sharedCanCreateFiles
              sharedCanCreateFolders
              completedByEmployee {
                id
                gaiaUser {
                  abbreviatedName
                  fullName
                }
              }
              eventDate
              finishedDate
              status {
                id
                name
              }
              organization {
                id
                name
              }
              subjectGroup {
                id
                name
              }
              taskCollection {
                name
                id
              }
              job {
                id
                name
              }
              tags {
                id
                name
              }
              employees {
                edges {
                  node {
                    id
                    gaiaUser {
                      id
                      abbreviatedName
                      fullName
                      email
                    }
                  }
                }
              }
              contacts {
                edges {
                  node {
                    id
                    fullName
                    email
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: fetchPolicy || 'network-only',
      errorPolicy: 'all',
      pollInterval: 10000,
    }
  )

  const queryVariables = () => {
    const variables = {
      searchTerm,
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (myTasks) {
      variables.myTasks = myTasks
    }
    if (!archive) {
      variables.archive = archive
    }
    if (organizationId) {
      variables.organizationId = organizationId
    }
    if (employeeId) {
      variables.employeeId = employeeId
    }
    if (jobId) {
      variables.jobId = jobId
    }
    if (subjectGroupId) {
      variables.subjectGroupId = subjectGroupId
    }
    if (myTasks) {
      variables.myTasks = myTasks
    }
    if (archive) {
      variables.archive = null
    } else {
      variables.archive = false
    }
    if (pastDueTasks) {
      variables.pastDueTasks = pastDueTasks
    }
    if (formikTags.values.tags.length > 0) {
      variables.tags = formikTags.values.tags
        .map((tag) => tag.description)
        .join(',')
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = queryVariables()
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  const onTdClick = (cell) => {
    toggleModalShow()
    setTaskData(cell.row.original)
  }

  const handleQueryData = (queryData) => {
    if (queryData?.tasks) {
      setHasMoreData(queryData?.tasks?.pageInfo?.hasNextPage)
      const currentTasks = []
      queryData.tasks.edges.map((task) => {
        const taskNode = task?.node
        if (
          !fetchMoreData ||
          !tasks.some((tasks) => tasks.id === taskNode.id)
        ) {
          currentTasks.push(taskNode)
        }
      })
      if (fetchMoreData) {
        setTasks((prevState) => {
          const currentIds = new Set(currentTasks.map((item) => item.id))
          const filteredPrevState = prevState.filter(
            (item) => !currentIds.has(item.id)
          )
          return [...filteredPrevState, ...currentTasks]
        })
        setFetchMoreData(false)
      } else {
        setTasks(currentTasks)
      }
    }
  }

  useEffect(() => {
    if (queryData?.tasks) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      handleQueryData(queryData)
    }
  }, [queryData])

  useEffect(() => {
    if (formikTags?.values?.tags) {
      setLoadingSearch(true)
      const variables = queryVariables()
      if (formikTags.values.tags.length > 0) {
        variables.tags = formikTags.values.tags
          .map((tag) => tag.description)
          .join(',')
      }
      query({ variables })
    }
  }, [formikTags.values.tags])

  const fetchMore = () => {
    setFetchMoreData(true)
    const variables = queryVariables()
    variables.cursor = queryData?.tasks?.pageInfo?.endCursor
    queryFetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        handleQueryData(fetchMoreResult)
        return fetchMoreResult
      },
    })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = queryVariables()
    variables.orderBy = currentOrderBy
    query({ variables })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setLoadingSearch(true)
    const variables = queryVariables()
    variables.searchTerm = currentSearchTerm
    query({ variables })
  }

  const handleIncludeDiscludesArchiveTask = () => {
    const currentArchive = !archive
    setArchive(currentArchive)
    const variables = queryVariables()
    if (currentArchive) {
      variables.archive = null
    } else {
      variables.archive = false
    }
    query({ variables })
  }

  const handleMyTasks = () => {
    const currentMyTasks = !myTasks
    setMyTasks(currentMyTasks)
    const variables = queryVariables()
    variables.myTasks = currentMyTasks
    query({ variables })
  }

  const handlePastDueTasks = () => {
    const currentPastDueTasks = !pastDueTasks
    setPastDueTasks(currentPastDueTasks)
    const variables = queryVariables()
    variables.pastDueTasks = currentPastDueTasks
    query({ variables })
  }

  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Tasks..." />
        </Col>
      </Row>
    )
  if (queryError) return <>Error loading</>
  return (
    <>
      {jobModalId && (
        <JobDetailModal
          jobId={jobModalId}
          showModal={true}
          toggleModal={setJobModalId}
        />
      )}
      <div className="mt-4">
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={'Search Tasks'}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <TagSearchInput
              formik={formikTags}
              multiple
              placeholder="Search Tags"
            />
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            {['Administrator', 'Scheduling Manager'].includes(
              loggedInUser?.permissions?.group
            ) && (
              <>
                {(organizationId || jobId || subjectGroupId) && (
                  <Button
                    variant="link"
                    onClick={() => setShowCollectionsModal(true)}
                  >
                    <PlusCircle className="mr-2" /> Add Collection
                  </Button>
                )}
                <Button variant="link" onClick={toggleModalShow}>
                  <PlusCircle className="mr-2" /> New Task
                </Button>
              </>
            )}
            {!location.pathname.includes('employee') && (
              <Button variant="link" onClick={handleMyTasks}>
                {myTasks ? (
                  <Trash className="mr-2" />
                ) : (
                  <Funnel className="mr-2" />
                )}
                My Tasks
              </Button>
            )}
            <Button variant="link" onClick={handlePastDueTasks}>
              {pastDueTasks ? (
                <Trash className="mr-2" />
              ) : (
                <Funnel className="mr-2" />
              )}
              Past Due
            </Button>
            <Button variant="link" onClick={handleIncludeDiscludesArchiveTask}>
              {!archive ? (
                <Trash className="mr-2" />
              ) : (
                <Funnel className="mr-2" />
              )}
              Active
            </Button>
            {checkedIds.length > 0 && (
              <Button variant="link" onClick={() => setShowCompleteModal(true)}>
                <CheckSquare size={14} className="mr-2" />
                {checkedIds.length === 1 ? 'Complete Task' : 'Complete Tasks'}
              </Button>
            )}
            {checkedIds.length > 0 && (
              <Button variant="link" onClick={() => setShowDeleteModal(true)}>
                <Trash className="mr-2" />
                {checkedIds.length === 1 ? 'Delete Task' : 'Delete Tasks'}
              </Button>
            )}
          </Col>
        </Row>
        <Row
          className={
            formikTags?.values?.tags?.length > 0 ? 'mb-3 mt-2' : 'mb-3'
          }
        >
          <Col md="12">
            <SortableInfiniteTable
              rowPointer
              loading={loadingSearch || !queryData}
              hideGlobalFilter
              fetchMoreTableData={fetchMore}
              loadingMessage="Loading Tasks..."
              onTdClicks={{
                name: onTdClick,
                status: onTdClick,
                eventDate: onTdClick,
                dueDate: onTdClick,
                finishedDate: onTdClick,
                completedBy: onTdClick,
                employees: onTdClick,
                contacts: onTdClick,
                organization: (cell) => {
                  if (cell.row.original.organization) {
                    if (contact) {
                      history.push(
                        `/organization/${cell.row.original.organization.id}/contact`
                      )
                    } else {
                      history.push(
                        `/organization/${cell.row.original.organization.id}`
                      )
                    }
                  } else {
                    toggleModalShow()
                    setTaskData(cell.row.original)
                  }
                },
                job: (cell) => {
                  if (cell.row.original.job) {
                    setJobModalId(cell.row.original.job.id)
                  } else {
                    toggleModalShow()
                    setTaskData(cell.row.original)
                  }
                },
                subjectGroup: (cell) => {
                  if (cell.row.original.subjectGroup) {
                    history.push(
                      `/subject-group/${cell.row.original.subjectGroup.id}`
                    )
                  } else {
                    toggleModalShow()
                    setTaskData(cell.row.original)
                  }
                },
                tags: onTdClick,
              }}
              tableData={tasks}
              tableColumns={tableColumns}
              hasMoreTableData={hasMoreData}
              tableHeight={600}
              handleSortByChange={handleSortByChange}
            />
          </Col>
        </Row>
      </div>
      {showTaskModal && (
        <TaskModal
          employeeDetail={employeeId ? true : false}
          showModal={showTaskModal}
          toggleModal={toggleModalShow}
          updateTask={taskData}
          organizationId={organizationId}
          organizationName={organizationName}
          subjectGroupId={subjectGroupId}
          subjectGroupName={subjectGroupName}
          jobId={jobId}
          jobName={jobName}
        />
      )}

      {(organizationId || jobId || subjectGroupId) && (
        <AddTaskCollectionsModal
          show={showCollectionsModal}
          organizationId={organizationId}
          subjectGroupId={subjectGroupId}
          jobId={jobId}
          handleClose={() => setShowCollectionsModal(false)}
        />
      )}
      {showDeleteModal && (
        <DeleteTaskModal
          showModal={showDeleteModal}
          toggleModal={setShowDeleteModal}
          taskIds={checkedIds.length > 1 ? checkedIds : null}
          taskId={checkedIds.length === 1 ? checkedIds[0] : null}
          onComplete={() => setCheckedIds([])}
        />
      )}
      {showCompleteModal && (
        <CompleteTasksModal
          showModal={showCompleteModal}
          toggleModal={setShowCompleteModal}
          taskIds={checkedIds.length > 1 ? checkedIds : null}
          taskId={checkedIds.length === 1 ? checkedIds[0] : null}
          onComplete={() => setCheckedIds([])}
        />
      )}
    </>
  )
}
export default Tasks
