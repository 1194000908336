import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client'
import { Form, Row, Col, Button, Modal, Image, Carousel } from 'react-bootstrap'
import {
  CaretDown,
  CaretRight,
  PlusCircle,
  Trash,
  Images,
} from 'react-bootstrap-icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import produce from 'immer'
import { FilePond } from 'react-filepond'
import { useAWSS3 } from '../../libs/aws'
import Loading from '../common/Loading'
import './EditSessionPackageModal.css'
import { client } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import toast from 'react-hot-toast'
import AuditLog from '../studio_settings/AuditLog'
import DeleteSessionPackageModal from './DeleteSessionPackageModal'
import Sessions from '../sessions/Sessions'

const EditSessionPackageModal = (props) => {
  const { sessionPackageId, showModal, toggleModal } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Scheduling Manager', 'Administrator'].includes(
    loggedInUser?.permissions?.group
  )
  const [previewImages, setPreviewImages] = useState([])
  const [previewImagesCount, setPreviewImagesCount] = useState(0)
  const [submitting, setSubmitting] = useState(null)
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const [displaySessions, setDisplaySessions] = useState(false)

  const [deletePackageId, setDeletePackageId] = useState(false)
  const awsS3 = useAWSS3()
  const { data: getCategoryData } = useQuery(
    gql`
      query PackageCategories {
        packageCategories {
          edges {
            node {
              id
              name
              archived
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [sessionPackageQuery, { data: queryData }] = useLazyQuery(
    gql`
      query SessionPackageQuery($sessionPackageId: ID!) {
        sessionPackage(id: $sessionPackageId) {
          id
          description
          title
          price
          upsell
          contentType {
            model
          }
          customPriceAndDuration
          upsellDescription
          durationLowMinutes
          durationHighMinutes
          packageCategory {
            id
            name
          }
          previewImages {
            edges {
              node {
                id
                defaultImageName
                coverImage
                nameOnUpload
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const sessionPackageNode = data.sessionPackage
        let coverPreviewImage
        let currentPreviewImagesCount = 0
        sessionPackageNode.previewImages.edges.forEach((previewImage) => {
          currentPreviewImagesCount += 1
          getS3Object(previewImage.node.defaultImageName, previewImage.node)
          if (previewImage.node.coverImage) {
            coverPreviewImage = previewImage.node.nameOnUpload
          }
        })
        setPreviewImagesCount(currentPreviewImagesCount)
        formik.setValues({
          title: sessionPackageNode.title,
          price: sessionPackageNode.price,
          upsell: sessionPackageNode.upsell,
          upsellDescription: sessionPackageNode.upsellDescription,
          description: sessionPackageNode.description,
          durationHighMinutes: sessionPackageNode.durationHighMinutes,
          removePreviewImages: sessionPackageNode.removePreviewImages,
          durationLowMinutes: sessionPackageNode.durationLowMinutes,
          packageCategoryId: sessionPackageNode.packageCategory
            ? sessionPackageNode.packageCategory.id
            : null,
          coverPreviewImage,
          customPriceAndDuration: sessionPackageNode.customPriceAndDuration,
        })
      },
    }
  )
  const [updateSessionPackageMutation] = useMutation(
    gql`
      mutation UpdateSessionPackage(
        $updateSessionPackageInput: UpdateSessionPackageInput!
      ) {
        updateSessionPackage(input: $updateSessionPackageInput) {
          sessionPackage {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        toggleModal()
        toast.success('Session Package Saved')
        setSubmitting(false)
      },
      errorPolicy: 'all',
      refetchQueries: ['SessionPackagesQuery'],
    }
  )
  const formik = useFormik({
    initialValues: {
      title: '',
      price: '',
      description: '',
      upsell: false,
      upsellDescription: '',
      addPreviewImages: '',
      removePreviewImages: '',
      durationHighMinutes: '',
      durationLowMinutes: '',
      coverPreviewImage: '',
      packageCategoryId: '',
    },
    validationSchema: Yup.object().shape({
      packageCategoryId: Yup.string().nullable(),
      customPriceAndDuration: Yup.boolean().nullable(),
      title: Yup.string()
        .min(2, 'Title is too short')
        .max(100, 'Title is too long')
        .required('Title is required')
        .test('isUnique', 'Title must be unique', async (value) => {
          let valid = true
          if (value) {
            const { data: completeSessionList } = await client.query({
              query: gql`
                query SPTitle($titleIexact: String) {
                  sessionPackages(title_Iexact: $titleIexact) {
                    nodeCount
                    edges {
                      node {
                        title
                        id
                      }
                    }
                  }
                }
              `,
              fetchPolicy: 'network-only',
            })

            let filteredSession =
              completeSessionList.sessionPackages.edges.filter((ed) => {
                return ed.node.title === value
              })
            filteredSession.forEach((node) => {
              if (
                node.node.title === value &&
                node.node.id !== sessionPackageId
              ) {
                valid = false
              }
            })
            return valid
          }
          return valid
        }),
      upsell: Yup.boolean().nullable(),
      upsellDescription: Yup.string()
        .when('upsell', {
          is: true,
          then: Yup.string().required('Upsell details are required'),
        })
        .nullable(),
      price: Yup.number()
        .min(0, 'Price is too low')
        .max(1000, 'Price is too high')
        .nullable()
        .when('customPriceAndDuration', {
          is: false,
          then: Yup.number().required('Required'),
        }),
      description: Yup.string()
        .min(2, 'Description is too short')
        .when('customPriceAndDuration', {
          is: false,
          then: Yup.string().required('Required'),
        }),
      durationLowMinutes: Yup.number()
        .min(0, 'Low duration is too low')
        .max(1000, 'Low duration is too high')
        .nullable()
        .when('customPriceAndDuration', {
          is: false,
          then: Yup.number().required('Required'),
        }),
      durationHighMinutes: Yup.number()
        .min(0, 'High duration is too low')
        .max(1000, 'High duration is too high')
        .nullable()
        .when('customPriceAndDuration', {
          is: false,
          then: Yup.number().required('Required'),
        })
        .test(
          'isHigherThanLowDuration',
          'High duration must exceed low duration',
          (value, context) => {
            if (context.parent.customPriceAndDuration) {
              return true
            }
            let valid = true
            if (
              context.parent.durationLowMinutes &&
              context.parent.durationLowMinutes >= value
            ) {
              valid = false
            }
            return valid
          }
        )
        .test(
          'modFive',
          'High duration must be divisible by 5',
          (value, context) => {
            if (context.parent.customPriceAndDuration) {
              return true
            }
            let valid = true
            if (value && !(value % 5 === 0)) {
              valid = false
            }
            return valid
          }
        ),
      coverPreviewImage: Yup.string()
        .when('customPriceAndDuration', {
          is: false,
          then: Yup.string().required('Required'),
        })
        .test(
          'isPreviewImageName',
          'Cover preview image is required',
          (value, context) => {
            if (context.parent.customPriceAndDuration) {
              return true
            }
            let valid = false
            if (formik.values.addPreviewImages) {
              formik.values.addPreviewImages.forEach((addPreviewImage) => {
                if (!valid && addPreviewImage.filename === value) {
                  valid = true
                }
              })
            }
            if (!valid) {
              previewImages.forEach((previewImage) => {
                if (
                  !valid &&
                  previewImage.previewImageNode.nameOnUpload === value
                ) {
                  valid = true
                }
              })
            }
            return valid
          }
        ),
      addPreviewImages: Yup.mixed()
        .test(
          'doesntExceedThreePreviewImages',
          'Three preview images exceeded. Please remove a preview image.',
          (value, context) => {
            if (context.parent.customPriceAndDuration) {
              return true
            }
            let valid = true
            if (previewImagesCount > 3) {
              valid = false
            }
            return valid
          }
        )
        .test(
          'isUniqueFileName',
          'Preview image names must be unique',
          (value, context) => {
            if (context.parent.customPriceAndDuration) {
              return true
            }
            let valid = true
            if (
              formik.values.addPreviewImages &&
              !previewImageNamesUnique(formik.values.addPreviewImages)
            ) {
              valid = false
            }
            return valid
          }
        ),
      removePreviewImages: Yup.mixed()
        .test(
          'doesntExceedThreePreviewImages',
          'Three preview images exceeded. Please remove a preview image.',
          (value, context) => {
            if (context.parent.customPriceAndDuration) {
              return true
            }
            let valid = true
            if (previewImagesCount > 3) {
              valid = false
            }
            return valid
          }
        )
        .test(
          'previewImageExists',
          'At least one preview image is required',
          (value, context) => {
            if (context.parent.customPriceAndDuration) {
              return true
            }
            let valid = false
            if (
              formik.values.addPreviewImages &&
              formik.values.addPreviewImages.length > 0
            ) {
              valid = true
            }
            if (!valid) {
              previewImages.forEach((previewImage) => {
                if (!valid && !previewImage.remove) {
                  valid = true
                }
              })
            }
            return valid
          }
        ),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting('update')
      let addPreviewImages
      if (formik.values.addPreviewImages) {
        addPreviewImages = formik.values.addPreviewImages.map(
          (addPreviewImage) => ({
            image: addPreviewImage.file,
            nameOnUpload: addPreviewImage.filename,
          })
        )
      }
      const sessionPackageInput = {
        id: sessionPackageId,
        title: values.title,
        description: values.description,
        packageCategoryId: values.packageCategoryId,
      }
      if (!values.customPriceAndDuration) {
        ;(sessionPackageInput.price = values.price),
          (sessionPackageInput.upsell = values.upsell),
          (sessionPackageInput.upsellDescription = values.upsellDescription),
          (sessionPackageInput.durationLowMinutes = values.durationLowMinutes),
          (sessionPackageInput.durationHighMinutes =
            values.durationHighMinutes),
          (sessionPackageInput.removePreviewImages =
            values.removePreviewImages),
          (sessionPackageInput.coverPreviewImage = values.coverPreviewImage),
          (sessionPackageInput.addPreviewImages = addPreviewImages)
      }
      updateSessionPackageMutation({
        variables: {
          updateSessionPackageInput: {
            sessionPackageInput,
          },
        },
      })
    },
  })

  useEffect(() => {
    if (showModal && awsS3.client) {
      sessionPackageQuery({
        variables: {
          sessionPackageId,
        },
      })
    } else {
      formik.resetForm()
      setPreviewImages([])
      setPreviewImagesCount(0)
      setSubmitting(false)
    }
  }, [showModal, awsS3.client])
  useEffect(() => {
    if (formik.values.addPreviewImages) {
      let currentPreviewImagesCount = 0
      previewImages.forEach((previewImage) => {
        if (!previewImage.remove) {
          currentPreviewImagesCount += 1
        }
      })
      formik.values.addPreviewImages.forEach((addPreviewImage) => {
        currentPreviewImagesCount += 1
      })
      setPreviewImagesCount(currentPreviewImagesCount)
      if (currentPreviewImagesCount <= 3 && currentPreviewImagesCount > 0) {
        if (formik.errors.removePreviewImages) {
          formik.setFieldError('removePreviewImages', null)
        }
        if (
          formik.errors.addPreviewImages &&
          formik.errors.addPreviewImages &&
          formik.errors.addPreviewImages.includes('exceeded')
        ) {
          formik.setFieldError('addPreviewImages', null)
        }
      } else if (formik.values.addPreviewImages.length > 0) {
        formik.setFieldError(
          'addPreviewImages',
          'Three preview images exceeded'
        )
        formik.setFieldError(
          'removePreviewImages',
          'Three preview images exceeded'
        )
      }
      if (currentPreviewImagesCount === 0) {
        formik.setFieldError(
          'removePreviewImages',
          'At least one preview image is required'
        )
      } else if (
        formik.errors.removePreviewImages &&
        formik.errors.removePreviewImages.includes('least')
      ) {
        formik.setFieldError('removePreviewImages', null)
      }
      if (!previewImageNamesUnique(formik.values.addPreviewImages)) {
        formik.setFieldError(
          'removePreviewImages',
          'Preview image names must be unique'
        )
        formik.setFieldError(
          'addPreviewImages',
          'Preview image names must be unique'
        )
      } else {
        if (
          formik.errors.removePreviewImages &&
          formik.errors.removePreviewImages.includes('unique')
        ) {
          formik.setFieldError('removePreviewImages', null)
        }
        if (
          formik.errors.addPreviewImages &&
          formik.errors.addPreviewImages.includes('unique')
        ) {
          formik.setFieldError('addPreviewImages', null)
        }
      }
    }
  }, [formik.values.addPreviewImages])
  useEffect(() => {
    if (
      formik.values.coverPreviewImage &&
      formik.values.coverPreviewImage !== 'selectCoverImage' &&
      formik.errors.coverPreviewImage
    ) {
      formik.setFieldError('coverPreviewImage', null)
    }
  }, [formik.values.coverPreviewImage])
  async function getS3Object(Key, previewImageNode) {
    let coverImage = false
    if (previewImageNode.coverImage) {
      coverImage = true
    }
    await awsS3.client.getObject(
      { Bucket: awsS3.bucket, Key },
      (error, data) => {
        if (!error) {
          setPreviewImages((currentPreviewImages) => [
            ...currentPreviewImages,
            {
              s3ImageData: awsS3.encodeS3ImageData(data.Body),
              previewImageNode,
              remove: false,
              coverImage,
            },
          ])
        }
      }
    )
  }
  const handleFileUpload = (currentAddPreviewImages) => {
    formik.setFieldValue('addPreviewImages', currentAddPreviewImages)
    if (
      !formik.values.coverPreviewImage &&
      currentAddPreviewImages &&
      currentAddPreviewImages.length > 0
    ) {
      formik.setFieldValue(
        'coverPreviewImage',
        currentAddPreviewImages[0].filename
      )
    }
  }
  const handleRemovePreviewImageClick = (previewImage) => {
    const nextPreviewImages = produce(previewImages, (draftState) => {
      draftState.forEach((prevPreviewImage) => {
        if (
          previewImage.previewImageNode.id ===
          prevPreviewImage.previewImageNode.id
        ) {
          prevPreviewImage.remove = true
        }
      })
      return draftState
    })
    setPreviewImages(nextPreviewImages)
    let currentRemovePreviewImages
    if (formik.values.removePreviewImages) {
      currentRemovePreviewImages = formik.values.removePreviewImages
      currentRemovePreviewImages.push(previewImage.previewImageNode.id)
    } else {
      currentRemovePreviewImages = [previewImage.previewImageNode.id]
    }
    formik.setFieldValue('removePreviewImages', currentRemovePreviewImages)
    if (previewImagesCount === 4) {
      if (formik.errors.removePreviewImages) {
        formik.setFieldError('removePreviewImages', null)
        formik.setFieldError('addPreviewImages', null)
      }
    }
    const currentPreviewImagesCount = previewImagesCount - 1
    setPreviewImagesCount(currentPreviewImagesCount)
    if (
      formik.values.coverPreviewImage ===
      previewImage.previewImageNode.nameOnUpload
    ) {
      formik.setFieldValue('coverPreviewImage', 'selectCoverImage')
    }
    if (currentPreviewImagesCount === 0) {
      formik.setFieldError(
        'removePreviewImages',
        'At least one preview image is required'
      )
    }
    if (
      !previewImageNamesUnique(
        formik.values.addPreviewImages,
        nextPreviewImages
      )
    ) {
      formik.setFieldError(
        'removePreviewImages',
        'Preview image names must be unique'
      )
      formik.setFieldError(
        'addPreviewImages',
        'Preview image names must be unique'
      )
    } else {
      if (
        formik.errors.removePreviewImages &&
        formik.errors.removePreviewImages.includes('unique')
      ) {
        formik.setFieldError('removePreviewImages', null)
      }
      if (
        formik.errors.addPreviewImages &&
        formik.errors.addPreviewImages.includes('unique')
      ) {
        formik.setFieldError('addPreviewImages', null)
      }
    }
  }
  const handleIncludePreviewImageClick = (previewImage) => {
    if (previewImagesCount >= 3) {
      formik.setFieldError('addPreviewImages', 'Three preview images exceeded')
      formik.setFieldError(
        'removePreviewImages',
        'Three preview images exceeded'
      )
    } else if (formik.errors.removePreviewImages) {
      formik.setFieldError('removePreviewImages', null)
      formik.setFieldError('addPreviewImages', null)
    }
    const nextPreviewImages = produce(previewImages, (draftState) => {
      draftState.forEach((prevPreviewImage) => {
        if (
          previewImage.previewImageNode.id ===
          prevPreviewImage.previewImageNode.id
        ) {
          prevPreviewImage.remove = false
        }
      })
      return draftState
    })
    setPreviewImages(nextPreviewImages)
    const currentRemovePreviewImages = formik.values.removePreviewImages
    currentRemovePreviewImages.forEach((removePreviewImageId) => {
      if (removePreviewImageId === previewImage.previewImageNode.id) {
        const removeIndex =
          currentRemovePreviewImages.indexOf(removePreviewImageId)
        currentRemovePreviewImages.splice(removeIndex, 1)
      }
    })
    currentRemovePreviewImages.push(previewImage.previewImageNode.id)
    const currentPreviewImagesCount = previewImagesCount + 1
    setPreviewImagesCount(currentPreviewImagesCount)
    if (
      formik.errors.removePreviewImages &&
      formik.errors.removePreviewImages.includes('least')
    ) {
      formik.setFieldError('removePreviewImages', null)
    }
    if (
      !previewImageNamesUnique(
        formik.values.addPreviewImages,
        nextPreviewImages
      )
    ) {
      formik.setFieldError(
        'removePreviewImages',
        'Preview image names must be unique'
      )
      formik.setFieldError(
        'addPreviewImages',
        'Preview image names must be unique'
      )
    } else {
      if (
        formik.errors.removePreviewImages &&
        formik.errors.removePreviewImages.includes('unique')
      ) {
        formik.setFieldError('removePreviewImages', null)
      }
      if (
        formik.errors.addPreviewImages &&
        formik.errors.addPreviewImages.includes('unique')
      ) {
        formik.setFieldError('addPreviewImages', null)
      }
    }
  }
  const previewImageNamesUnique = (addPreviewImages, nextPreviewImages) => {
    let unique = true
    const fileNames = []
    if (addPreviewImages) {
      addPreviewImages.forEach((addPreviewImage) => {
        fileNames.push(addPreviewImage.filename)
      })
    }
    if (nextPreviewImages) {
      nextPreviewImages.forEach((previewImage) => {
        if (!previewImage.remove) {
          fileNames.push(previewImage.previewImageNode.nameOnUpload)
        }
      })
    } else {
      previewImages.forEach((previewImage) => {
        if (!previewImage.remove) {
          fileNames.push(previewImage.previewImageNode.nameOnUpload)
        }
      })
    }
    if (fileNames.filter((e, i, a) => a.indexOf(e) !== i).length > 0) {
      unique = false
    }
    return unique
  }

  if (!showModal || !queryData || !awsS3?.client) return <></>
  return (
    <>
      <div className="newSessionPackage">
        <Modal
          size={displaySessions ? 'xl' : 'lg'}
          show={showModal}
          onHide={() => {
            toggleModal()
            setDeletePackageId()
            setDisplayAuditLog(false)
            setDisplaySessions(false)
          }}
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Images className="mr-2" />
                {canMutate && <>Edit</>} Session Package
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Row>
                  <Col md={2} className="d-flex align-items-center">
                    <Form.Label className="mb-0">Title</Form.Label>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Control
                      type="text"
                      name="title"
                      disabled={!canMutate}
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.title}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
              {formik.values.customPriceAndDuration && (
                <Form.Group>
                  <Row>
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="mb-0">
                        Custom Price and Duration
                      </Form.Label>
                    </Col>
                    <Col md={6}>
                      <Form.Check
                        type="checkbox"
                        disabled={true}
                        name="customPriceAndDuration"
                        checked={formik.values.customPriceAndDuration}
                        onChange={() => {
                          formik.setFieldValue(
                            'customPriceAndDuration',
                            !formik.values.customPriceAndDuration
                          )
                        }}
                        isInvalid={formik.errors.customPriceAndDuration}
                        feedback={formik.errors.customPriceAndDuration}
                        id="upsell-checkbox"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              )}
              {!formik.values.customPriceAndDuration && (
                <>
                  <Form.Group>
                    <Row>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="mb-0">Price</Form.Label>
                      </Col>
                      <Col md={2}>
                        <Form.Control
                          type="text"
                          name="price"
                          disabled={!canMutate}
                          value={formik.values.price}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.price}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.price}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Row>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="mb-0">Duration</Form.Label>
                      </Col>
                      <Col md={2}>
                        <Form.Control
                          type="text"
                          name="durationLowMinutes"
                          disabled={!canMutate}
                          value={formik.values.durationLowMinutes}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.durationLowMinutes}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.durationLowMinutes}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={1} className="d-flex align-items-center">
                        <span>to</span>
                      </Col>
                      <Col md={2}>
                        <Form.Control
                          type="text"
                          name="durationHighMinutes"
                          disabled={!canMutate}
                          value={formik.values.durationHighMinutes}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.durationHighMinutes}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.durationHighMinutes}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={1} className="d-flex align-items-center">
                        <span>minutes</span>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group></Form.Group>
                  <Form.Group>
                    <Row>
                      <Col md={2} className="d-flex align-items-center">
                        Category
                      </Col>
                      <Col md={6}>
                        <Form.Control
                          as="select"
                          disabled={!canMutate}
                          name="packageCategoryId"
                          value={formik.values.packageCategoryId}
                          onChange={formik.handleChange}
                        >
                          <option value={''}>- - -</option>
                          {getCategoryData?.packageCategories?.edges.map(
                            (catNode) => {
                              const { node } = catNode
                              return (
                                <option key={node.id} value={node.id}>
                                  {node.name}
                                </option>
                              )
                            }
                          )}
                        </Form.Control>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      md={2}
                      className="d-flex align-items-center"
                    >
                      Upsell
                    </Form.Label>
                    <Col md={6}>
                      <Form.Check
                        type="checkbox"
                        name="upsell"
                        disabled={!canMutate}
                        checked={formik.values.upsell}
                        onChange={() => {
                          formik.setFieldValue('upsell', !formik.values.upsell)
                        }}
                        isInvalid={formik.errors.upsell}
                        feedback={formik.errors.upsell}
                        id="upsell-checkbox"
                      />
                    </Col>
                  </Form.Group>
                  {formik.values.upsell ? (
                    <Form.Group>
                      <Row>
                        <Col md={2}>
                          <Form.Label>Upsell Description</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            style={{ height: '200px' }}
                            as="textarea"
                            name="upsellDescription"
                            value={formik.values.upsellDescription}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.upsellDescription}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.upsellDescription}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>
                  ) : (
                    ''
                  )}
                </>
              )}
              <Form.Group>
                <Row>
                  <Col md={2}>
                    <Form.Label>Description</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      style={{ height: '200px' }}
                      as="textarea"
                      disabled={!canMutate}
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.description}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
              {!formik.values.customPriceAndDuration && (
                <>
                  <Row className="mb-3">
                    <Col className="d-flex align-items-center">
                      <Form.Label className="mb-0">
                        Preview Images
                        {canMutate && (
                          <p className="mb-3 mt-1 text-secondary">
                            {previewImages.length === 3 ? (
                              <>
                                Session packages support up to three preview
                                images. Remove an existing preview image to add
                                a new one.
                              </>
                            ) : (
                              <>Upload up to three preview images</>
                            )}
                          </p>
                        )}
                        {formik.errors.removePreviewImages ? (
                          <p style={{ color: '#dc3545' }}>
                            {formik.errors.removePreviewImages}
                          </p>
                        ) : null}
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    {previewImages.length > 0 ? (
                      <Col xs={{ span: 4, offset: 4 }}>
                        <Carousel
                          interval={2000}
                          indicators={false}
                          controls={previewImagesCount > 1}
                        >
                          {previewImages.map((previewImage, index) => {
                            return (
                              <Carousel.Item key={index}>
                                <Image
                                  fluid
                                  src={`data:image/jpeg;base64,${previewImage.s3ImageData}`}
                                  alt="Session package preview"
                                />
                                <small className="d-block">
                                  {previewImage.previewImageNode.nameOnUpload}
                                </small>
                                <div className="mt-3 mb-3 d-flex justify-content-center">
                                  {!previewImage.remove && canMutate ? (
                                    <>
                                      {canMutate && (
                                        <Button
                                          variant="outline-danger"
                                          size="sm"
                                          onClick={() =>
                                            handleRemovePreviewImageClick(
                                              previewImage
                                            )
                                          }
                                        >
                                          <Trash className="mr-2" />
                                          Remove
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {canMutate && (
                                        <Button
                                          variant="outline-primary"
                                          size="sm"
                                          onClick={() =>
                                            handleIncludePreviewImageClick(
                                              previewImage
                                            )
                                          }
                                        >
                                          Include
                                        </Button>
                                      )}
                                    </>
                                  )}
                                </div>
                              </Carousel.Item>
                            )
                          })}
                        </Carousel>
                      </Col>
                    ) : null}
                  </Row>
                  {previewImages.length < 3 ? (
                    <>
                      {canMutate && (
                        <Form.Group>
                          <Row className="mb-3">
                            <Col>
                              <Form.Label>
                                Add Preview Images
                                {formik.errors.addPreviewImages ? (
                                  <p
                                    className="mt-3"
                                    style={{
                                      color: '#dc3545',
                                    }}
                                  >
                                    {formik.errors.addPreviewImages}
                                  </p>
                                ) : null}
                              </Form.Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={{ span: 10, offset: 1 }}>
                              <FilePond
                                files={formik.values.addPreviewImages}
                                onupdatefiles={handleFileUpload}
                                allowMultiple
                                allowFileSizeValidation
                                acceptedFileTypes={['image/png', 'image/jpeg']}
                                maxFileSize="15MB"
                                labelMaxFileSize="Maximum preview image size is {filesize}"
                                maxFiles={3}
                                name="addPreviewImages"
                                labelIdle='Drag and drop images or <span class="filepond--label-action">Browse</span>'
                              />
                              {formik.errors.addPreviewImages ? (
                                <small
                                  style={{
                                    color: '#dc3545',
                                  }}
                                >
                                  {formik.errors.previewImages}
                                </small>
                              ) : null}
                            </Col>
                          </Row>
                        </Form.Group>
                      )}
                    </>
                  ) : null}
                  <Form.Group>
                    <Row>
                      <Col md={2} className="d-flex align-items-center">
                        <Form.Label className="mb-0">Cover Image</Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          name="coverPreviewImage"
                          as="select"
                          disabled={!canMutate}
                          value={formik.values.coverPreviewImage}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.coverPreviewImage}
                        >
                          {formik.values.coverPreviewImage ===
                          'selectCoverImage' ? (
                            <option
                              key="selectCoverImage"
                              value="selectCoverImage"
                            >
                              Select cover image
                            </option>
                          ) : null}
                          {previewImages.length > 0 ? (
                            <>
                              {previewImages.map((previewImage, index) => {
                                let option
                                if (!previewImage.remove) {
                                  option = (
                                    <option
                                      key={`${previewImage.previewImageNode.nameOnUpload}${index}`}
                                      value={
                                        previewImage.previewImageNode
                                          .nameOnUpload
                                      }
                                    >
                                      {
                                        previewImage.previewImageNode
                                          .nameOnUpload
                                      }
                                    </option>
                                  )
                                }
                                return option
                              })}
                            </>
                          ) : null}
                          {formik.values.addPreviewImages ? (
                            <>
                              {formik.values.addPreviewImages.map(
                                (addPreviewImage, index) => (
                                  <option
                                    key={`${addPreviewImage.filename}${index}`}
                                    value={addPreviewImage.filename}
                                  >
                                    {addPreviewImage.filename}
                                  </option>
                                )
                              )}
                            </>
                          ) : null}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.coverPreviewImage}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form.Group>
                </>
              )}
              <Row className="mt-3 mb-2">
                <Col md={12} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setDisplaySessions(!displaySessions)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displaySessions ? (
                        <CaretDown size={17} />
                      ) : (
                        <CaretRight size={17} />
                      )}
                    </>
                  </button>
                  <Form.Label className="mb-0">Sessions</Form.Label>
                </Col>
              </Row>
              {displaySessions && (
                <Row>
                  <Col md={12}>
                    <Sessions
                      detailComponent
                      sessionPackageId={sessionPackageId}
                    />
                  </Col>
                </Row>
              )}
              <Row className="mt-3 mb-2">
                <Col md={12} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setDisplayAuditLog(!displayAuditLog)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayAuditLog ? (
                        <CaretDown size={17} />
                      ) : (
                        <CaretRight size={17} />
                      )}
                    </>
                  </button>
                  <Form.Label className="mb-0">History</Form.Label>
                </Col>
              </Row>
              {displayAuditLog && (
                <Row>
                  <Col md={12}>
                    <AuditLog
                      contentType={queryData.sessionPackage.contentType.model}
                      id={queryData.sessionPackage.id}
                    />
                  </Col>
                </Row>
              )}
              {formik.errors.price ||
              formik.errors.description ||
              formik.errors.addPreviewImages ||
              formik.errors.removePreviewImages ||
              formik.errors.durationHighMinutes ||
              formik.errors.durationLowMinutes ||
              formik.errors.coverPreviewImage ||
              formik.errors.title ? (
                <Row>
                  <Col>
                    <p className="mt-4 mb-4" style={{ color: '#dc3545' }}>
                      Please resolve errors before saving
                    </p>
                  </Col>
                </Row>
              ) : null}
              {canMutate && (
                <>
                  <Row>
                    <Col md={3}>
                      <Button
                        type="submit"
                        block
                        variant="outline-primary"
                        disabled={submitting}
                      >
                        <PlusCircle className="mr-2" />
                        Save
                      </Button>
                    </Col>
                    <Col md={3}>
                      <Button
                        block
                        variant="outline-danger"
                        className="ml-2"
                        onClick={() => {
                          setDeletePackageId(sessionPackageId)
                        }}
                        disabled={submitting}
                      >
                        <Trash className="mr-2" />
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
              {submitting ? (
                <Row className="mt-2">
                  <Col>
                    <Loading message="Saving Session Package..." />
                  </Col>
                </Row>
              ) : null}
            </Modal.Body>
          </Form>
        </Modal>
        <DeleteSessionPackageModal
          sessionPackageId={deletePackageId}
          showModal={deletePackageId}
          toggleModal={() => {
            setDeletePackageId()
            toggleModal()
            setDisplayAuditLog(false)
            setDisplaySessions(false)
          }}
        />
      </div>
    </>
  )
}

export default EditSessionPackageModal
