import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { gql, useLazyQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import JobDetailModal from '../schedule/JobDetailModal'
import { useHistory } from 'react-router-dom'
import Loading from '../common/Loading'
import DateFilter from '../common/DateFilter'

const EquipmentJobsTable = (props) => {
  const { bagId, itemId, tableHeight } = props
  const [showJobDetailModal, setShowJobDetailModal] = useState(false)
  const [jobDetailId, setJobDetailId] = useState()
  const [startDateFilter, setStartDateFilter] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [endDateFilter, setEndDateFilter] = useState()
  const history = useHistory()

  const [fetchEmpJobs, { data: empJobsData, fetchMore: fetchMoreEmpJobs }] =
    useLazyQuery(
      gql`
        query EquipmentJobQuery(
          $employeeJobsEquipmentBags: [ID]
          $employeeJobsEquipmentItems: [ID]
          $employeeJobsAfter: String
          $startDateTimeGte: DateTime
          $startDateTimeLte: DateTime
        ) {
          employeeJobs(
            equipmentBags: $employeeJobsEquipmentBags
            equipmentItems: $employeeJobsEquipmentItems
            job_StartDateTime_Gte: $startDateTimeGte
            job_StartDateTime_Lte: $startDateTimeLte
            first: 20
            after: $employeeJobsAfter
            orderBy: "job__start_date_time"
          ) {
            edges {
              node {
                employee {
                  id
                  gaiaUser {
                    fullName
                  }
                }
                startDateTime
                endDateTime
                job {
                  id
                  name
                  startDateTime
                  endDateTime
                  location {
                    name
                    archived
                    fullAddress
                  }
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )

  useEffect(() => {
    if (bagId || itemId) {
      const variables = {}
      if (bagId) {
        variables.employeeJobsEquipmentBags = [bagId]
      }
      if (itemId) {
        variables.employeeJobsEquipmentItems = [itemId]
      }
      if (startDateFilter) {
        variables.startDateGte = startDateFilter
      }
      if (endDateFilter) {
        variables.startDateLte = endDateFilter
      }
      fetchEmpJobs({
        variables,
      })
      setInitialQueryRun(true)
    }
  }, [bagId, itemId])

  useEffect(() => {
    if (initialQueryRun) {
      const variables = {}
      if (bagId) {
        variables.employeeJobsEquipmentBags = [bagId]
      }
      if (itemId) {
        variables.employeeJobsEquipmentItems = [itemId]
      }
      if (startDateFilter) {
        variables.startDateGte = startDateFilter
      }
      if (endDateFilter) {
        variables.startDateLte = endDateFilter
      }
      fetchEmpJobs({
        variables,
      })
    }
  }, [startDateFilter, endDateFilter])

  const fetchMore = () => {
    const variables = {
      employeeJobsAfter: empJobsData.employeeJobs.pageInfo.endCursor,
    }
    if (bagId) {
      variables.employeeJobsEquipmentBags = [bagId]
    }
    if (itemId) {
      variables.employeeJobsEquipmentItems = [itemId]
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    fetchMoreEmpJobs({ variables })
  }

  const toggleJobDetailModal = (jobId = null) => {
    if (jobId) {
      setJobDetailId(jobId)
      setShowJobDetailModal(true)
    } else {
      setJobDetailId()
      setShowJobDetailModal(false)
    }
  }

  if (!empJobsData)
    return (
      <Row>
        <Col>
          <Loading message="Loading Jobs..." />
        </Col>
      </Row>
    )

  const onTdClicks = {
    job: (row) => {
      toggleJobDetailModal(row.row.original.node.job.id)
    },
    location: (row) => {
      toggleJobDetailModal(row.row.original.node.job.id)
    },
    jobTime: (row) => {
      toggleJobDetailModal(row.row.original.node.job.id)
    },
    employee: (row) => {
      if (row.row.original.node.employee) {
        history.push(`/employee/${row.row.original.node.employee.id}`)
      }
    },
    employeeTime: (row) => {
      toggleJobDetailModal(row.row.original.node.job.id)
    },
  }
  return (
    <>
      <Row className="mb-3">
        <Col style={{ marginTop: '-8px' }}>
          <DateFilter
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            endDateFilter={endDateFilter}
            setEndDateFilter={setEndDateFilter}
            placeholderStart="Jobs From"
            placeholderEnd="Jobs Until"
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ maxHeight: '220px', overflowY: 'auto' }}>
          <SortableInfiniteTable
            tableData={
              empJobsData?.employeeJobs ? empJobsData?.employeeJobs.edges : []
            }
            tableColumns={[
              {
                Header: 'Job',
                id: 'job',
                accessor: 'node.job.name',
              },
              {
                Header: 'Location',
                id: 'location',
                accessor: (row) => {
                  let location = ''
                  if (!row.node.job.location.archived) {
                    if (row.node.job.location.name) {
                      location = `${row.node.job.location.name}, ${row.node.job.location.fullAddress}`
                    } else {
                      location = row.node.job.location.fullAddress
                    }
                  }
                  return location
                },
              },
              {
                Header: 'Job Time',
                id: 'jobTime',
                accessor: (row) => {
                  return `${DateTime.fromISO(
                    row.node.job.startDateTime
                  ).toFormat("LLLL d' 'yyyy")} ${DateTime.fromISO(
                    row.node.job.startDateTime
                  ).toFormat('h:mma')} - ${DateTime.fromISO(
                    row.node.job.endDateTime
                  ).toFormat('h:mma')}`
                },
              },
              {
                Header: 'Employee',
                id: 'employee',
                accessor: (row) => {
                  if (row.node.employee) {
                    return row.node.employee.gaiaUser.fullName
                  }
                },
              },
              {
                Header: 'Employee Assignment Time',
                id: 'employeeTime',
                accessor: (row) => {
                  let time = ''
                  if (row.node.startDateTime) {
                    time = `${DateTime.fromISO(row.node.startDateTime).toFormat(
                      "LLLL d' 'yyyy"
                    )} ${DateTime.fromISO(row.node.startDateTime).toFormat(
                      'h:mma'
                    )}`
                    if (row.node.endDateTime) {
                      time = `${time} - ${DateTime.fromISO(
                        row.node.endDateTime
                      ).toFormat('h:mma')}`
                    }
                  }
                  return time
                },
              },
            ]}
            loadingMessage="Loading Jobs..."
            fetchMoreTableData={fetchMore}
            hasMoreTableData={
              empJobsData
                ? empJobsData.employeeJobs.pageInfo?.hasNextPage
                : false
            }
            onTdClicks={onTdClicks}
            tableHeight={tableHeight ? tableHeight : 300}
            rowPointer
            hideGlobalFilter
          />
        </Col>
      </Row>
      <JobDetailModal
        jobId={jobDetailId}
        showModal={showJobDetailModal}
        toggleModal={toggleJobDetailModal}
      />
    </>
  )
}

export default EquipmentJobsTable
