import { gql, useQuery } from '@apollo/client'

export const useImagequixEnabled = () => {
  const { data } = useQuery(
    gql`
      query ImagequixConnector {
        imagequixConnectors {
          edges {
            node {
              id
              enabled
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  if (!data) return
  return data.imagequixConnectors.edges[0].node.enabled
}
