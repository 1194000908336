import React from 'react'
import { Modal } from 'react-bootstrap'
import AuditLog from './AuditLog'

const AuditLogModal = (props) => {
  const {
    showModal,
    toggleModal,
    nodeContentType,
    nodeId,
    nodeContentTypesRelayIds,
    nodeName,
  } = props
  if (!showModal) return <></>
  return (
    <>
      <div id="historyModal">
        <Modal size={'xl'} show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>{nodeName} History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AuditLog
              contentType={nodeContentType}
              id={nodeId}
              contentTypesRelayIds={nodeContentTypesRelayIds}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default AuditLogModal
