import React from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { SlashCircle, Cloud } from 'react-bootstrap-icons'
import { useMutation, gql, useLazyQuery } from '@apollo/client'
import toast from 'react-hot-toast'
import { settingsVar } from '../../libs/apollo'

const ChangeSubscriptionModal = (props) => {
  const {
    showModal,
    toggleModal,
    subscription,
    setSelectedSubscription,
    setChangeSubscription,
    setTenantSubscriptionUsage,
  } = props

  const [getTenantSubscriptionUsage] = useLazyQuery(
    gql`
      query TenantSubscriptionUsageQuery {
        tenantSubscriptionUsage {
          subscription
          usedUsers
          includedUsers
          usedSessionPackages
          includedSessionPackages
          usedNotifications
          includedNotifications
          usedFileStorage
          includedFileStorage
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted(data) {
        setTenantSubscriptionUsage(data.tenantSubscriptionUsage)
        toast.success('Subscription Changed')
        setChangeSubscription(false)
        toggleModal()
      },
    }
  )

  const [getSettings] = useLazyQuery(
    gql`
      query PublicSettingsQuery {
        publicSettings {
          id
          name
          airstudioInvoiceRecipient
          chargeProductStateSalesTax
          productTaxRate
          displayLogoInNav
          displayNameInNav
          websiteUrl
          phoneNumber
          openAiEnabled
          tenantSubscription
          thumbnailImageName
          timezone
          intercomAppId
          showLastNameIdLogin
          tenantActive
          anonymousLogin
          tenantId
          chargeStateSalesTax
          email
          openAiEnabled
          colorScheme
          facebookUrl
          twitterUrl
          tenantUuid
          linkedinUrl
          instagramUrl
          instagramInFooter
          facebookInFooter
          twitterInFooter
          linkedinInFooter
          tenantDomain
          apiDomain
          sessionStages
          jobStages
          googleCalendarConnectorEnabled
          googleCalendarEnabledForSubjectSessions
          googleCalendarEnabledForEmployeeJobs
          googleCalendarEnabledForOrganizationSessions
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted(data) {
        settingsVar(data.publicSettings)
        setSelectedSubscription(data.publicSettings.tenantSubscription)
        getTenantSubscriptionUsage()
      },
    }
  )

  const [changeTenantSubscription] = useMutation(
    gql`
      mutation ChangeTenantSubscription(
        $input: ChangeTenantSubscriptionInput!
      ) {
        changeTenantSubscription(input: $input) {
          changed
        }
      }
    `,
    {
      onCompleted: () => {
        getSettings()
      },
      refetchQueries: [
        'SettingsQuery',
        'TenantSubscriptionUsageQuery',
        'PublicSettingsQuery',
      ],
    }
  )

  return (
    <>
      <Modal size={'md'} show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3 mt-3">
            <Col className="text-center">
              <p>
                Are you sure you want to change to the {subscription}{' '}
                subscription? Your monthly invoice will reflect the new
                subscription from the date you change until then end of the
                month.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Button
                variant="outline-primary"
                block
                onClick={() =>
                  changeTenantSubscription({
                    variables: {
                      input: {
                        subscription: subscription,
                      },
                    },
                  })
                }
              >
                <Cloud className="mr-2" />
                Change Subscription
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Button variant="outline-primary" block onClick={toggleModal}>
                <SlashCircle className="mr-2" />
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ChangeSubscriptionModal
