import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const EmployeeScheduleSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    setAdditionalFields,
    error,
    variables,
    dropdown,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="employeeSchedule"
      searchDescription={
        searchDescription ? searchDescription : 'employee schedules'
      }
      nodeNamePlural="employeeSchedules"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      setAdditionalFields={setAdditionalFields}
      variables={variables}
      dropdown={dropdown}
      gql={gql`
        query EmployeeSchedules($first: Int, $after: String, $search: String) {
          employeeSchedules(
            first: $first
            after: $after
            search: $search
            orderBy: "-start_date_time"
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                employee {
                  id
                  gaiaUser {
                    fullName
                  }
                }
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return node.employee.gaiaUser.fullName
      }}
    />
  )
}

export default EmployeeScheduleSearchInput
