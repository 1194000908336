import React, { useState } from 'react'
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ExclamationCircle, Trash } from 'react-bootstrap-icons'

const DeleteSubjectGroupModal = (props) => {
  const {
    showModal,
    toggleModal,
    subjectGroupId,
    subjectGroupIds,
    setCheckedSubjectGroupIds,
  } = props

  let _subjectGroupIds = [subjectGroupId]
  if (subjectGroupIds) {
    _subjectGroupIds = subjectGroupIds
  }
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)

  const [deleteSubjectGroup] = useMutation(
    gql`
      mutation DeleteSubjectGroup(
        $deleteSubjectGroupInput: DeleteSubjectGroupInput!
      ) {
        deleteSubjectGroup(input: $deleteSubjectGroupInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        if (formik.values.recordType === 'subject') {
          toast.success('Deleting Subjects')
        } else if (subjectGroupIds) {
          toast.success('Subject Groups Deleted')
        } else {
          toast.success('Subject Group Deleted')
        }
        if (setCheckedSubjectGroupIds) {
          setCheckedSubjectGroupIds([])
        }
        toggleModal()
        if (formik.values.recordType === 'subjectGroup') {
          history.push('/subject-groups')
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['SubjectGroupsQuery'],
    }
  )

  const formik = useFormik({
    initialValues: {
      deleteSubjects: false,
      deleteSessions: false,
      recordType: '- - -',
    },
    validationSchema: Yup.object().shape({
      recordType: Yup.string().test('isEmail', 'Required', (value) => {
        return !(value === '- - -')
      }),
      deleteSubjects: Yup.bool().nullable(),
      deleteSessions: Yup.bool().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      deleteSubjectGroup({
        variables: {
          deleteSubjectGroupInput: {
            subjectGroupInput: {
              ids: _subjectGroupIds,
              recordType: values.recordType,
              deleteSubjects: values.deleteSubjects,
              deleteSessions: values.deleteSessions,
            },
          },
        },
      })
    },
  })

  if (!showModal) return <></>

  return (
    <div className="deleteSubjectGroup">
      <Modal
        size="md"
        show={showModal}
        onHide={() => toggleModal()}
        aria-labelledby="newResitReason"
        className="invmodal detail"
      >
        <Modal.Header closeButton>
          <Modal.Title id="new-title">
            Delete Subject Group{subjectGroupIds && <>s</>} Records
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md={6} className="mb-0 pb-0">
                <Form.Control
                  name="recordType"
                  as="select"
                  className="form-control form-control-sm"
                  value={formik.values.recordType}
                  onChange={formik?.handleChange}
                  isInvalid={formik?.errors?.recordType}
                >
                  <option value="- - -">Select Record Type</option>
                  <option value="subjectGroup">Subject Group</option>
                  <option value="subject">Subjects</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik?.errors?.recordType}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            {formik.values.recordType === 'subjectGroup' && (
              <Row className="mb-3 align-items-center">
                <Col sm={4}>
                  <Form.Group className="mb-0">
                    <Form.Check
                      name="deleteSubjects"
                      type="checkbox"
                      label="Delete Subjects"
                      checked={formik.values.deleteSubjects}
                      onChange={(e) => {
                        formik.setFieldValue(`deleteSubjects`, e.target.checked)
                      }}
                    />
                  </Form.Group>
                </Col>
                {formik.values.deleteSubjects && (
                  <Col className="text-danger d-flex align-items-center" sm={8}>
                    <ExclamationCircle
                      height={50}
                      width={50}
                      className="mr-2"
                    />
                    <span style={{ fontSize: '0.85rem' }}>
                      Subject records will be deleted. Uncheck this box to
                      delete the subject group and retain subject records.
                    </span>
                  </Col>
                )}
              </Row>
            )}
            {formik.values.recordType !== '- - -' && (
              <Row className="mb-3">
                <Col>
                  <Form.Group>
                    <Form.Check
                      name="deleteSessions"
                      type="checkbox"
                      label="Delete Sessions"
                      checked={formik.values.deleteSessions}
                      onChange={(e) => {
                        formik.setFieldValue(`deleteSessions`, e.target.checked)
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  disabled={submitting}
                  type="submit"
                >
                  <Trash className="mr-2" />
                  Delete{' '}
                  {formik.values.recordType === 'subjectGroup' && (
                    <>Subject Group</>
                  )}
                  {formik.values.recordType === 'subject' && <>Subjects</>}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DeleteSubjectGroupModal
