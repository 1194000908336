import React, { useState, useEffect } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import BoxFolder from './BoxFolder'

const FilesModal = (props) => {
  const { toggleModal, showModal, boxFolderId, header } = props

  if (!showModal) return <></>

  return (
    <>
      <div className="reviewEsignRequest">
        <Modal
          size="xl"
          show={showModal}
          onHide={toggleModal}
          aria-labelledby="reviewEsignRequest"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              {header ? header : 'Files'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BoxFolder rootBoxFolderId={boxFolderId} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default FilesModal
