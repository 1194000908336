import React from 'react'
import { Modal } from 'react-bootstrap'
import SubjectGroup from './SubjectGroup'
import { useParams } from 'react-router-dom'
import { People } from 'react-bootstrap-icons'

export default function SubjectGroupModal(props) {
  const { subjectGroupId, organization, showModal, toggleModal } = props
  const { contact } = useParams()
  let header
  if (contact) {
    header = 'Update Subjects'
  } else if (subjectGroupId) {
    header = 'Edit Subject Group'
  } else if (organization) {
    header = `New ${organization.name} Subject Group`
  } else {
    header = 'New Subject Group'
  }

  return (
    <Modal show={showModal} onHide={toggleModal} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <People className="mr-2" />
          {header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SubjectGroup
          subjectGroupId={subjectGroupId}
          organization={organization}
          toggleModal={toggleModal}
        />
      </Modal.Body>
    </Modal>
  )
}
