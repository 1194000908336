import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { useFormik } from 'formik'
import { useQuery } from '@apollo/client'
import toast from 'react-hot-toast'
import {
  XCircle,
  Trash,
  PlusCircle,
  CaretDown,
  CaretRight,
} from 'react-bootstrap-icons'
import * as Yup from 'yup'
import AuditLog from '../audit_log/AuditLog'
import AddressAutocomplete from './AddressAutocomplete'
import InfiniteScroll from 'react-infinite-scroll-component'
import Schedule from '../schedule/Schedule'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import Loading from '../common/Loading'
import Map from './Map'

const LocationForm = (props) => {
  const {
    location,
    afterSubmit,
    organizationId,
    subjectId,
    displaySchedule,
    setDisplaySchedule,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canModify = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)

  const [submitting, setSubmitting] = useState(false)
  const [displayHistory, setDisplayHistory] = useState(false)
  const [displayMap, setDisplayMap] = useState(false)
  const [hasLocation, setHasLocation] = useState(location ? true : false)
  const [displayOrganizationResults, setDisplayOrganizationResults] =
    useState(false)

  const [displaySubjectsResults, setDisplaySubjectsResults] = useState(false)

  const { data: settingsData } = useQuery(
    gql`
      query Settings {
        settings {
          edges {
            node {
              id
              chargeStateSalesTax
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )

  const [deleteLocation] = useMutation(
    gql`
      mutation DeleteLocations($deleteLocationInput: DeleteLocationInput!) {
        deleteLocation(input: $deleteLocationInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Location Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Locations', 'SubjectDetailQuery', 'OrganizationDetail'],
    }
  )

  const [createLocation] = useMutation(
    gql`
      mutation createLocation($input: CreateLocationInput!) {
        createLocation(input: $input) {
          location {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Location Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: ['Locations', 'SubjectDetailQuery', 'OrganizationDetail'],
    }
  )

  const [updateLocation] = useMutation(
    gql`
      mutation UpdateLocation($input: UpdateLocationInput!) {
        updateLocation(input: $input) {
          location {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Location Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'Locations',
        'SubjectDetailQuery',
        'OrganizationDetail',
        'InvoiceSessionsQuery',
        'SessionsQuery',
      ],
    }
  )

  let subjectName = null
  if (location?.subject) {
    if (location.subject.gaiaUser?.firstName) {
      subjectName = location.subject.gaiaUser.firstName
    }
    if (location.subject.gaiaUser?.lastName) {
      if (subjectName) {
        subjectName = `${subjectName} ${location.subject.gaiaUser.lastName}`
      }
    }
    if (!subjectName) {
      subjectName = 'Unnamed'
    }
    if (location.subject.organization) {
      subjectName = `${subjectName} - ${location.subject.organization.name}`
    }
  }

  const formik = useFormik({
    initialValues: location
      ? {
          name: location.name,
          addressLineOne: location.addressLineOne,
          addressLineTwo: location.addressLineTwo,
          studio: location.studio,
          city: location.city,
          state: location.state,
          zipCode: location.zipCode,
          shippingAddress: location.shippingAddress,
          billingAddress: location.billingAddress,
          organizationId: location.organization?.id,
          organizationName: location.organization?.name,
          subjectId: location.subject?.id,
          subjectName: subjectName ? subjectName : '',
          mapDefault: location.mapDefault,
        }
      : {
          name: '',
          addressLineOne: '',
          addressLineTwo: '',
          city: '',
          state: '',
          zipCode: '',
          mapDefault: false,
          shippingAddress: false,
          billingAddress: false,
          studio: false,
          organizationId: organizationId ? organizationId : '',
          organizationName: '',
          subjectId: subjectId ? subjectId : '',
          subjectName: '',
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().nullable(),
      addressLineOne: Yup.string().nullable(),
      addressLineTwo: Yup.string().nullable(),
      city: Yup.string().nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
      studio: Yup.bool().required('required'),
      shippingAddress: Yup.bool().nullable(),
      mapDefault: Yup.bool().nullable(),
      billingAddress: Yup.bool().nullable(),
      organizationId: Yup.string().nullable(),
      organizationName: Yup.string().nullable(),
      subjectId: Yup.string().nullable(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (!hasLocation && !location) {
        formik.setFieldError('location', 'required')
      } else {
        setSubmitting(true)
        if (location) {
          updateLocation({
            variables: {
              input: {
                locationInput: {
                  id: location.id,
                  name: values.name,
                  addressLineOne: values.addressLineOne,
                  addressLineTwo: values.addressLineTwo,
                  city: values.city,
                  state: values.state,
                  zipCode: values.zipCode,
                  studio: values.studio,
                  shippingAddress: values.shippingAddress,
                  billingAddress: values.billingAddress,
                  organizationId: values.organizationId,
                  subjectId: values.subjectId,
                  mapDefault: values.mapDefault,
                },
              },
            },
          })
        } else {
          createLocation({
            variables: {
              input: {
                locationInput: {
                  name: values.name,
                  addressLineOne: values.addressLineOne,
                  addressLineTwo: values.addressLineTwo,
                  city: values.city,
                  state: values.state,
                  zipCode: values.zipCode,
                  studio: values.studio,
                  shippingAddress: values.shippingAddress,
                  billingAddress: values.billingAddress,
                  organizationId: values.organizationId,
                  subjectId: values.subjectId,
                  mapDefault: values.mapDefault,
                },
              },
            },
          })
        }
      }
    },
  })

  useEffect(() => {
    if (hasLocation && formik.errors.location) {
      formik.setFieldError('location', null)
    }
  }, [hasLocation])

  const handleDelete = () => {
    setSubmitting(true)
    deleteLocation({
      variables: {
        deleteLocationInput: {
          locationIds: location.id,
        },
      },
    })
  }

  const handleResetValues = () => {
    formik.setFieldValue('zipCode', '')
    formik.setFieldValue('city', '')
    formik.setFieldValue('addressName', '')
    formik.setFieldValue('state', '')
    formik.setFieldValue('addressLineOne', '')
    formik.setFieldValue('addressLineTwo', '')
    setHasLocation(false)
  }

  const handleAddressSelect = (addressData) => {
    formik.setFieldValue(
      'zipCode',
      addressData.address?.postal_code ? addressData?.address?.postal_code : ''
    )
    formik.setFieldValue(
      'city',
      addressData.address?.locality ? addressData?.address?.locality : ''
    )
    formik.setFieldValue(
      'addressName',
      addressData?.name ? addressData?.name : ''
    )
    formik.setFieldValue(
      'state',
      addressData?.address
        ? addressData?.address?.administrative_area_level_1
        : ''
    )
    formik.setFieldValue(
      'addressLineOne',
      addressData?.name ? addressData?.name : ''
    )
    formik.setFieldValue(
      'addressLineTwo',
      addressData?.neighborhood ? addressData?.neighborhood : ''
    )
    setHasLocation(true)
  }

  const [
    searchOrganizations,
    { data: organizationsData, fetchMore: fetchMoreSubjects },
  ] = useLazyQuery(
    gql`
      query Subjects($after: String, $first: Int, $nameIcontains: String) {
        organizations(
          after: $after
          first: $first
          name_Icontains: $nameIcontains
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const handleOrganizationChange = (e) => {
    setDisplayOrganizationResults(true)
    formik.setFieldValue(`organizationName`, e.target.value)
    searchOrganizations({
      variables: {
        after: null,
        first: 10,
        nameIcontains: e.target.value,
      },
    })
  }

  const handleOrganizationXClick = () => {
    formik.setFieldValue(`organizationName`, '')
    formik.setFieldValue(`organizationId`, '')
  }

  const handleFetchMoreOrganizations = () => {
    fetchMoreOrganizations({
      variables: {
        after: organizationsData.organizations.pageInfo.endCursor,
        first: 10,
        nameIcontains: formik.values.organizationName,
      },
    })
  }

  const handleOrganizationBlur = () => {
    setDisplayOrganizationResults(false)
    formik.setFieldTouched(`organizationId`, true)
  }

  const handleOrganizationClick = (node) => {
    setDisplayOrganizationResults(false)
    formik.setFieldValue(`organizationName`, node.name)
    formik.setFieldValue(`organizationId`, node.id)
  }

  const [
    searchSubjects,
    { data: subjectsData, fetchMore: fetchMoreOrganizations },
  ] = useLazyQuery(
    gql`
      query Subjects($after: String, $first: Int, $nameIcontains: String) {
        subjects(
          after: $after
          first: $first
          gaiaUser_FullName_Icontains: $nameIcontains
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              gaiaUser {
                firstName
                lastName
              }
              organization {
                name
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const handleSubjectChange = (e) => {
    setDisplaySubjectsResults(true)
    formik.setFieldValue(`subjectName`, e.target.value)
    searchSubjects({
      variables: {
        after: null,
        first: 10,
        nameIcontains: e.target.value,
      },
    })
  }

  const handleSubjectXClick = () => {
    formik.setFieldValue(`subjectName`, '')
    formik.setFieldValue(`subjectId`, '')
  }

  const handleFetchMoreSubjects = () => {
    fetchMoreSubjects({
      variables: {
        after: subjectsData.subjects.pageInfo.endCursor,
        first: 10,
        nameIcontains: formik.values.subjectName,
      },
    })
  }

  const handleSubjectBlur = () => {
    setDisplaySubjectsResults(false)
    formik.setFieldTouched(`subjectId`, true)
  }

  const handleSubjectClick = (node) => {
    setDisplaySubjectsResults(false)
    let subjectName = null
    if (node.gaiaUser.firstName) {
      subjectName = node.gaiaUser.firstName
    }
    if (node.gaiaUser.lastName) {
      if (subjectName) {
        subjectName = `${subjectName} ${node.gaiaUser.lastName}`
      }
    }
    if (!subjectName) {
      subjectName = 'Unnamed'
    }
    if (node.organization) {
      subjectName = `${subjectName} - ${node.organization.name}`
    }
    formik.setFieldValue(`subjectName`, subjectName)
    formik.setFieldValue(`subjectId`, node.id)
  }

  if (!settingsData) return <></>
  const settings = settingsData.settings.edges[0].node
  return (
    <>
      <div id="locationForm">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Name
            </Form.Label>
            <Col md={6}>
              <Form.Control
                type="text"
                name="name"
                className="form-control-sm"
                disabled={!canModify}
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Location
            </Form.Label>

            {hasLocation ? (
              <>
                {' '}
                <Col md={6} className="d-flex">
                  <Form.Control
                    disabled={hasLocation || !canModify}
                    placeholder="search locations"
                    className="form-control-sm"
                    value={
                      formik.values.addressLineOne +
                      ' ' +
                      formik.values.city +
                      ' ' +
                      formik.values.state +
                      ' ' +
                      formik.values.zipCode
                    }
                  />
                </Col>
                {canModify && (
                  <Col>
                    <button
                      type="button"
                      className="btn-link mb-1"
                      onClick={() => handleResetValues()}
                    >
                      <XCircle />
                    </button>
                  </Col>
                )}
              </>
            ) : (
              <Col md={6}>
                <AddressAutocomplete
                  onSelect={handleAddressSelect}
                  error={formik.errors.location}
                />
              </Col>
            )}
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Address Line Two
            </Form.Label>
            <Col md={6}>
              <Form.Control
                type="text"
                name="addressLineTwo"
                className="form-control-sm"
                disabled={!canModify}
                value={formik.values.addressLineTwo}
                onChange={formik.handleChange}
                isInvalid={formik.errors.addressLineTwo}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.addressLineTwo}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          {!formik.values.studio && !subjectId && !organizationId && (
            <>
              {!formik.values.subjectId && (
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Organization
                  </Form.Label>
                  <Col sm="12" md={6}>
                    <Form.Control
                      placeholder="search organizations"
                      value={formik.values.organizationName}
                      onBlur={handleOrganizationBlur}
                      onChange={(e) => handleOrganizationChange(e)}
                      disabled={!canModify}
                      readOnly={Boolean(formik.values.organizationId)}
                      className={
                        formik.values.organizationId
                          ? ' border border-success form-control form-control-sm'
                          : 'form-control form-control-sm'
                      }
                    />
                    {organizationsData && displayOrganizationResults && (
                      <div
                        style={{
                          position: 'absolute',
                          backgroundColor: 'white',
                          zIndex: 3000,
                          width: '95%',
                          top: '100%',
                        }}
                      >
                        <InfiniteScroll
                          height={100}
                          dataLength={
                            organizationsData.organizations.edges.length
                          }
                          next={handleFetchMoreOrganizations}
                          hasMore={
                            organizationsData?.organizations.pageInfo
                              .hasNextPage
                          }
                          loader={<Loading />}
                        >
                          <Table size="sm" hover>
                            <tbody>
                              {organizationsData.organizations.edges.map(
                                (org) => {
                                  const { node } = org
                                  return (
                                    <tr
                                      onMouseDown={() =>
                                        handleOrganizationClick(node)
                                      }
                                      key={node.id}
                                      className="hover text-decoration-none"
                                    >
                                      <td>
                                        <small>{node.name}</small>
                                      </td>
                                    </tr>
                                  )
                                }
                              )}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                      </div>
                    )}
                  </Col>
                  {canModify && (
                    <Col>
                      {formik.values.organizationId && (
                        <button
                          type="button"
                          className="p-0 mr-1 btn-link"
                          onClick={handleOrganizationXClick}
                        >
                          <Trash />
                        </button>
                      )}
                    </Col>
                  )}
                </Form.Group>
              )}
              {!formik.values.organizationId && (
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Subject
                  </Form.Label>
                  <Col sm="12" md={6}>
                    <Form.Control
                      placeholder="search subjects"
                      value={formik.values.subjectName}
                      onBlur={handleSubjectBlur}
                      disabled={!canModify}
                      onChange={(e) => handleSubjectChange(e)}
                      readOnly={Boolean(formik.values.subjectId)}
                      className={
                        formik.values.subjectId
                          ? ' border border-success form-control form-control-sm'
                          : 'form-control form-control-sm'
                      }
                    />
                    {subjectsData && displaySubjectsResults && (
                      <div
                        style={{
                          position: 'absolute',
                          backgroundColor: 'white',
                          zIndex: 3000,
                          width: '95%',
                          top: '100%',
                        }}
                      >
                        <InfiniteScroll
                          height={100}
                          dataLength={subjectsData.subjects.edges.length}
                          next={handleFetchMoreSubjects}
                          hasMore={subjectsData?.subjects.pageInfo.hasNextPage}
                          loader={<Loading />}
                        >
                          <Table size="sm" hover>
                            <tbody>
                              {subjectsData.subjects.edges.map((subject) => {
                                const { node } = subject
                                let subjectName = null
                                if (node.gaiaUser.firstName) {
                                  subjectName = node.gaiaUser.firstName
                                }
                                if (node.gaiaUser.lastName) {
                                  if (subjectName) {
                                    subjectName = `${subjectName} ${node.gaiaUser.lastName}`
                                  }
                                }
                                return (
                                  <tr
                                    onMouseDown={() => handleSubjectClick(node)}
                                    key={node.id}
                                    className="hover text-decoration-none"
                                  >
                                    <td>
                                      <small>{subjectName}</small>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                      </div>
                    )}
                  </Col>
                  {canModify && (
                    <Col>
                      {formik.values.subjectId && (
                        <button
                          type="button"
                          className="p-0 mr-1 btn-link"
                          onClick={handleSubjectXClick}
                        >
                          <Trash />
                        </button>
                      )}
                    </Col>
                  )}
                </Form.Group>
              )}
            </>
          )}
          <Form.Group as={Row}>
            {!formik.values.organizationId && !formik.values.subjectId && (
              <>
                <Col md={location ? 2 : 3}>
                  <Form.Check
                    name="studio"
                    type="checkbox"
                    disabled={!canModify}
                    label={
                      <span style={{ fontSize: '14px', fontWeight: '500' }}>
                        Studio Location
                      </span>
                    }
                    checked={formik.values.studio}
                    onChange={(e) => {
                      formik.setFieldValue(`studio`, e.target.checked)
                    }}
                  />
                </Col>
                <Col md={location ? 2 : 3}>
                  <Form.Check
                    name="mapDefault"
                    type="checkbox"
                    disabled={!canModify}
                    label={
                      <span style={{ fontSize: '14px', fontWeight: '500' }}>
                        Map Default Location
                      </span>
                    }
                    checked={formik.values.mapDefault}
                    onChange={(e) => {
                      formik.setFieldValue(`mapDefault`, e.target.checked)
                    }}
                  />
                </Col>
              </>
            )}

            {(formik.values.organizationId || formik.values.subjectId) && (
              <>
                <Col md={location ? 2 : 3}>
                  <Form.Check
                    name="shippingAddress"
                    type="checkbox"
                    disabled={!canModify}
                    label={
                      <span style={{ fontSize: '14px', fontWeight: '500' }}>
                        Shipping Location
                      </span>
                    }
                    checked={formik.values.shippingAddress}
                    onChange={(e) => {
                      formik.setFieldValue(`shippingAddress`, e.target.checked)
                    }}
                  />
                </Col>
                <Col md={location ? 2 : 3}>
                  <Form.Check
                    name="billingAddress"
                    type="checkbox"
                    disabled={!canModify}
                    label={
                      <span style={{ fontSize: '14px', fontWeight: '500' }}>
                        Billing Location
                      </span>
                    }
                    checked={formik.values.billingAddress}
                    onChange={(e) => {
                      formik.setFieldValue(`billingAddress`, e.target.checked)
                    }}
                  />
                </Col>
              </>
            )}
          </Form.Group>
          {settings.chargeStateSalesTax && canModify && (
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                State Sales Tax
              </Form.Label>
              <Col md={2}>
                <Form.Control
                  type="number"
                  className="form-control-sm"
                  disabled={true}
                  value={location?.salesTax}
                />
              </Col>
            </Form.Group>
          )}
          {location && (
            <>
              <Row>
                <Col className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setDisplaySchedule(!displaySchedule)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displaySchedule ? (
                        <CaretDown size={17} />
                      ) : (
                        <CaretRight size={17} />
                      )}
                    </>
                  </button>
                  <Form.Label className="mb-0" style={{ marginLeft: '3px' }}>
                    Schedule
                  </Form.Label>
                </Col>
              </Row>
              {displaySchedule && <Schedule locationId={location.id} />}
              <Row className="mt-3 mb-3">
                <Col>
                  <button
                    type="button"
                    onClick={() => setDisplayMap(!displayMap)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayMap ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label className="mb-0">Map</Form.Label>
                </Col>
              </Row>
              {displayMap && (
                <Row className="mt-3">
                  <Col>
                    <Map location={location} />
                  </Col>
                </Row>
              )}
              {canModify && (
                <>
                  <Row className="mt-3 mb-4">
                    <Col>
                      <button
                        type="button"
                        onClick={() => setDisplayHistory(!displayHistory)}
                        className="px-0 btn-link mr-1"
                      >
                        <>
                          {displayHistory ? (
                            <>
                              <CaretDown size={17} />
                            </>
                          ) : (
                            <>
                              <CaretRight size={17} />
                            </>
                          )}
                        </>
                      </button>
                      <Form.Label className="mb-0">History</Form.Label>
                    </Col>
                  </Row>
                  {displayHistory && (
                    <Row className="mt-3">
                      <Col>
                        <AuditLog
                          contentType={location.contentType.model}
                          id={location.id}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </>
          )}
          {canModify && (
            <Form.Row className="mt-2">
              <Col md={3}>
                <Button
                  type="submit"
                  block
                  variant="outline-primary"
                  disabled={submitting}
                >
                  <PlusCircle className="mr-2" />
                  Save
                </Button>
              </Col>

              {location && (
                <>
                  <Col md={3}>
                    <Button
                      block
                      variant="outline-danger"
                      onClick={handleDelete}
                      disabled={submitting}
                    >
                      <Trash className="mr-2" />
                      Delete
                    </Button>
                  </Col>
                </>
              )}
            </Form.Row>
          )}

          {submitting && <div className='mt-2'><Loading message="Saving Location..." /></div>}
        </Form>
      </div>
    </>
  )
}

export default LocationForm
