import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Form, Dropdown } from 'react-bootstrap'
import { useMutation, gql, useLazyQuery } from '@apollo/client'
import { VectorPen, ExclamationCircle } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Loading from '../../common/Loading'
import validator from 'validator'

const SendEsignRequestModal = (props) => {
  const {
    showModal,
    toggleModal,
    boxFolderId,
    organizationId,
    subjectId,
    subjectGroupId,
  } = props
  const [submitting, setSubmitting] = useState(false)
  const [boxFolder, setBoxFolder] = useState()
  const [selectedBoxItem, setSelectedBoxItem] = useState()

  const [getBoxFolder, { data: boxFolderData }] = useLazyQuery(
    gql`
      query BoxFolder($boxFolderId: String!) {
        boxFolder(boxFolderId: $boxFolderId) {
          boxFolder
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      // pollInterval: 30000,
    }
  )

  useEffect(() => {
    if (boxFolderId || showModal) {
      getBoxFolder({
        variables: {
          boxFolderId,
        },
      })
    }
  }, [boxFolderId, showModal])

  useEffect(() => {
    if (boxFolderData) {
      const boxFolder = JSON.parse(boxFolderData.boxFolder.boxFolder)
      boxFolder.folder_items = boxFolder.folder_items.filter(
        (folderItem) =>
          folderItem.type == 'file' && folderItem.name.includes('.pdf')
      )
      const pattern =
        /Airstudio Tenant [0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
      if (pattern.test(boxFolder.name)) {
        boxFolder.name = 'Root'
      }
      setBoxFolder(boxFolder)
    }
  }, [boxFolderData])

  const [createBoxSignRequest] = useMutation(
    gql`
      mutation CreateBoxSignRequest(
        $createBoxSignRequestInput: CreateBoxSignRequestInput!
      ) {
        createBoxSignRequest(input: $createBoxSignRequestInput) {
          boxSignRequest {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        innerToggle()
        toast.success('Sign Request Sent')
      },
      errorPolicy: 'all',
      refetchQueries: ['BoxSignRequests'],
    }
  )

  const formik = useFormik({
    initialValues: {
      boxFileId: '',
      recipientEmail: '',
      recipientName: '',
      signedRecipientEmail: '',
      signedRecipientName: '',
      emailMessage: '',
      emailSubject: '',
      description: '',
    },
    validationSchema: Yup.object().shape({
      boxFileId: Yup.string().required('required'),
      description: Yup.string().nullable(),
      recipientEmail: Yup.string()
        .required('required')
        .test('isEmail', 'invalid email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
      recipientName: Yup.string().required('required'),
      emailMessage: Yup.string().nullable(),
      emailSubject: Yup.string().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      const signers = [
        {
          name: values.recipientName,
          email: values.recipientEmail,
          role: 'signer',
        },
      ]
      if (values.signedRecipientEmail) {
        signers.push({
          name: values.signedRecipientName,
          email: values.signedRecipientEmail,
          role: 'final_copy_reader',
        })
      }
      createBoxSignRequest({
        variables: {
          createBoxSignRequestInput: {
            boxSignRequestInput: {
              organizationId,
              subjectGroupId,
              subjectId,
              boxFileId: values.boxFileId,
              boxFolderId,
              emailSubject: values.emailSubject,
              description: values.description,
              emailBody: values.emailBody,
              signers,
            },
          },
        },
      })
    },
  })

  const innerToggle = () => {
    toggleModal()
    setBoxFolder([])
    setSelectedBoxItem()
    formik.resetForm()
    setSubmitting(false)
  }

  if (!showModal || !boxFolder) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => innerToggle()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">Send Sign Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
              {boxFolder?.folder_items?.length == 0 && (
                <Row>
                  <Col>
                    <div className="text-danger">
                      <ExclamationCircle className="mr-2" />
                      No PDFs in the {boxFolder.name} folder. To send a sign
                      request, upload a PDF to the {boxFolder.name} folder or
                      navigate to a folder containing a PDF.
                    </div>
                  </Col>
                </Row>
              )}
              {boxFolder?.folder_items?.length > 0 && (
                <>
                  <Row className="mb-3">
                    <Col md={12}>
                      <Form.Label>Signer Email</Form.Label>
                      <Form.Control
                        name="recipientEmail"
                        value={formik.values.recipientEmail}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.recipientEmail}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.recipientEmail}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12}>
                      <Form.Label>Signer Name</Form.Label>
                      <Form.Control
                        name="recipientName"
                        value={formik.values.recipientName}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.recipientName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.recipientName}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12}>
                      <Form.Label>Signed Recipient Email</Form.Label>
                      <Form.Control
                        name="signedRecipientEmail"
                        value={formik.values.signedRecipientEmail}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.signedRecipientEmail}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.signedRecipientEmail}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12}>
                      <Form.Label>Signed Recipient Name</Form.Label>
                      <Form.Control
                        name="signedRecipientName"
                        value={formik.values.signedRecipientName}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.signedRecipientName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.signedRecipientName}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12}>
                      <Form.Label>Email Subject</Form.Label>
                      <Form.Control
                        name="emailSubject"
                        value={formik.values.emailSubject}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.emailSubject}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.emailSubject}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Form.Label>Email Body</Form.Label>
                      <Form.Control
                        style={{ height: '100px' }}
                        as="textarea"
                        name="emailBody"
                        value={formik.values.emailBody}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.emailBody}
                      />
                      {formik.errors.emailBody && (
                        <small className="text-danger">
                          {formik.errors.emailBody}
                        </small>
                      )}
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.emailBody}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12}>
                      <Form.Label>Sign Request Description</Form.Label>
                      <Form.Control
                        name="description"
                        as="textarea"
                        style={{ height: '100px' }}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.description}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Dropdown>
                          <Dropdown.Toggle variant="link" id="dropdown-basic">
                            Select File
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {boxFolder.folder_items.map((boxItem, i) => {
                              return (
                                <Dropdown.Item
                                  key={i}
                                  onClick={() => {
                                    formik.setFieldValue(
                                      'boxFileId',
                                      boxItem.id
                                    )
                                    setSelectedBoxItem(boxItem)
                                  }}
                                >
                                  {boxItem.name}
                                </Dropdown.Item>
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        {formik.errors.boxFileId && (
                          <small className="text-danger">
                            {formik.errors.boxFileId}
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {selectedBoxItem && (
                    <Row className="ml-1 mb-3">
                      <Col>{selectedBoxItem.name}</Col>
                    </Row>
                  )}

                  <Row>
                    <Col md={4}>
                      <Button
                        block
                        variant="outline-primary"
                        size="sm"
                        disabled={submitting}
                        type="submit"
                      >
                        <VectorPen className="mr-2" />
                        Send
                      </Button>
                    </Col>
                  </Row>
                  {submitting && <Loading message="Sending Sign Request..." />}
                </>
              )}
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default SendEsignRequestModal
