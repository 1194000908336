import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'

const SelectResitReason = (props) => {
  const { setResitReason, setBookingStage } = props
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(
    gql`
      query ResitReasonsQuery {
        resitReasons {
          edges {
            node {
              reason
              bill
              id
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
    }
  )
  const handleResitReasonClick = (resitReason) => {
    setResitReason({
      id: resitReason.id,
      reason: resitReason.reason,
      bill: resitReason.bill,
    })
    setBookingStage('sessionPackage')
  }
  if (queryError) return <p>Error loading resit reasons</p>
  if (queryLoading) return <></>
  const resitReasons = queryData.resitReasons.edges
  return (
    <>
      <div className="selectResitReason">
        {resitReasons.length === 0 ? (
          <Row>
            <Col>
              <h4 style={{ fontSize: '18px' }}>No Sessions Available</h4>
            </Col>
          </Row>
        ) : (
          <>
            <Row className="mb-3">
              <Col>
                <p>Select Resit Reason</p>
              </Col>
            </Row>
            {resitReasons.map((resitReason) => (
              <Row key={resitReason.node.id} className="mb-2">
                <Col xs={12} md={{ span: 10, offset: 1 }}>
                  <Button
                    variant="outline-primary"
                    block
                    className="text-left"
                    onClick={() => handleResitReasonClick(resitReason.node)}
                  >
                    <small>{resitReason.node.reason}</small>
                  </Button>
                </Col>
              </Row>
            ))}
            <Row className="mb-3" />
          </>
        )}
      </div>
    </>
  )
}

export default SelectResitReason
