import React, { useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Row, Col, Button, Accordion, Card } from 'react-bootstrap'
import { GeoAlt, PlusCircle } from 'react-bootstrap-icons'
import { DateTime } from 'luxon'
import { formatDateToStringOmmitYear } from '../../libs/utils'
import Loading from '../common/Loading'
import { loggedInUserVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import { Calendar2Plus } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'
import BookInquiry from './BookInquiry'
import MapModal from '../location/MapModal'

const ScheduleSession = (props) => {
  const {
    subjectId,
    sessionPackageNode,
    handleTimeClick,
    packageCategoryId,
    subjectGroupId,
  } = props
  const history = useHistory()
  const [showMapModal, setShowMapModal] = useState()
  const [showBookInquiry, setShoowBookInquiry] = useState(false)
  let loggedInUser = useReactiveVar(loggedInUserVar)
  const canCreateJob = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [availableSessionsTransformed, setAvailableSessionsTransformed] =
    useState({})
  const GET_AVAILABLE_TIMES = gql`
    query ScheduleSessionAvailableSessionsQuery(
      $sessionPackageId: ID!
      $packageCategoryId: ID
      $subjectGroupId: ID
    ) {
      availableSessions(
        sessionPackageId: $sessionPackageId
        packageCategoryId: $packageCategoryId
        subjectGroupId: $subjectGroupId
      ) {
        startDateTime
        job {
          id
          location {
            id
            name
            latitude
            longitude
            addressLineOne
            addressLineTwo
            city
            state
            zipCode
          }
        }
      }
    }
  `

  const { data: availableSessionsQueryData, error } = useQuery(
    GET_AVAILABLE_TIMES,
    {
      fetchPolicy: 'network-only',
      variables: {
        sessionPackageId: sessionPackageNode.id,
        packageCategoryId: packageCategoryId,
        subjectGroupId: subjectGroupId,
      },
    }
  )

  useEffect(() => {
    if (availableSessionsQueryData?.availableSessions) {
      let currentLocationsSessions = {}
      availableSessionsQueryData.availableSessions.forEach((session) => {
        const startDateTime = new Date(session.startDateTime)
        const startDate = formatDateToStringOmmitYear(startDateTime)
        const currentSession = {
          jobNode: session.job,
          startDateTime: session.startDateTime,
          formattedStartTime: DateTime.fromISO(session.startDateTime).toFormat(
            'h:mma'
          ),
        }
        const locationId = session.job.location.id
        if (currentLocationsSessions[locationId]) {
          if (!currentLocationsSessions[locationId].sessions[startDate]) {
            currentLocationsSessions[locationId].sessions[startDate] = []
          }
          currentLocationsSessions[locationId].sessions[startDate].push(
            currentSession
          )
        } else {
          let address = session.job.location.addressLineOne
          if (session.job.location.addressLineTwo) {
            address += ` ${session.job.location.addressLineTwo}`
          }
          currentLocationsSessions[locationId] = {
            location: {
              address,
              addressLineOne: session.job.location.addressLineOne,
              longitude: session.job.location.longitude,
              latitude: session.job.location.latitude,
              city: session.job.location.city,
              name: session.job.location.name || '',
              zipCode: session.job.location.zipCode,
            },
            sessions: {
              [startDate]: [currentSession],
            },
          }
        }
      })
      Object.keys(currentLocationsSessions).forEach((locationId) => {
        const sortedSessions = {}
        const dates = Object.keys(currentLocationsSessions[locationId].sessions)
        dates.sort(
          (a, b) =>
            DateTime.fromFormat(a, 'EEE LLL d yyyy').toJSDate() -
            DateTime.fromFormat(b, 'EEE LLL d yyyy').toJSDate()
        )
        dates.forEach((date) => {
          sortedSessions[date] =
            currentLocationsSessions[locationId].sessions[date]
        })
        currentLocationsSessions[locationId].sessions = sortedSessions
      })
      setAvailableSessionsTransformed(currentLocationsSessions)
    }
  }, [availableSessionsQueryData])

  const handleBookInquiryChange = () => {
    setShoowBookInquiry((prevState) => !prevState)
  }

  if (!availableSessionsQueryData) {
    return (
      <Row>
        <Col>
          <Loading />
        </Col>
      </Row>
    )
  }

  return (
    <>
      <MapModal
        showModal={showMapModal}
        location={showMapModal}
        toggleModal={setShowMapModal}
      />
      <BookInquiry
        showBookInquiry={showBookInquiry}
        handleBookInquiryChange={handleBookInquiryChange}
      />
      <Row className="mt-2">
        <Col>
          {availableSessionsQueryData?.availableSessions.length > 0 && (
            <>
              {canCreateJob && (
                <Row className="mb-4 mt-2">
                  <Col>
                    <h4 style={{ fontSize: '18px' }}>
                      <Button
                        size="sm"
                        variant="link"
                        style={{ marginTop: '1px' }}
                        onClick={() => {
                          history.push({
                            pathname: `/job`,
                            state: {
                              jobFormType: 'subject',
                              bookingFlowSubjectId: subjectId,
                              bookingFlowPackageId: sessionPackageNode.id,
                              bookingFlowSubjectGroupId: subjectGroupId,
                              bookingFlowPackageCategoryId: packageCategoryId,
                            },
                          })
                        }}
                        className="ml-2"
                      >
                        <PlusCircle className="mr-2" />
                        New Job
                      </Button>
                      or select from existing jobs
                    </h4>
                  </Col>
                </Row>
              )}

              {Object.entries(availableSessionsTransformed).map(
                (locationIdSessions) => {
                  const locationSessions = locationIdSessions[1]
                  return (
                    <>
                      <Row>
                        <Col>
                          <span
                            className="btn-link"
                            onClick={() => {
                              setShowMapModal(locationSessions.location)
                            }}
                          >
                            <p className="d-flex align-items-center mb-2">
                              <GeoAlt className="mr-2" />
                              {locationSessions.location.name}{' '}
                              {locationSessions.location.address},{' '}
                              {locationSessions.location.city}{' '}
                              {locationSessions.location.zipCode}
                            </p>
                          </span>
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Col md={12}>
                          <Card.Body className="p-0 mt-2">
                            <Accordion flush>
                              {Object.entries(locationSessions.sessions).map(
                                (sessions, index) => (
                                  <Card key={index}>
                                    <Card.Header className="m-n2">
                                      <Accordion.Toggle
                                        as={Button}
                                        eventKey={`${index}`}
                                        className="w-100 btn-link"
                                        type="button"
                                      >
                                        <div className="d-flex align-items-center justify-content-between">
                                          <span>{sessions[0]}</span>
                                          <small className="title-space text-secondary">
                                            {sessions[1].length} Openings
                                          </small>
                                        </div>
                                      </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={`${index}`}>
                                      <Card.Body
                                        style={{
                                          maxHeight: '200px',
                                          overflowY: 'scroll',
                                        }}
                                      >
                                        <Row>
                                          <Col className="d-flex flex-wrap justify-content-between">
                                            {sessions[1].map(
                                              (session, sessionIndex) => (
                                                <Button
                                                  style={{
                                                    width: '85px',
                                                  }}
                                                  key={sessionIndex}
                                                  onClick={() =>
                                                    handleTimeClick(
                                                      session.jobNode,
                                                      session.startDateTime
                                                    )
                                                  }
                                                  variant="outline-primary"
                                                  className=" mt-2 mb-1 mx-1"
                                                  size="sm"
                                                >
                                                  <small>
                                                    {session.formattedStartTime}
                                                  </small>
                                                </Button>
                                              )
                                            )}
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                )
                              )}
                            </Accordion>
                          </Card.Body>
                        </Col>
                      </Row>
                    </>
                  )
                }
              )}
            </>
          )}

          {availableSessionsQueryData?.availableSessions.length === 0 && (
            <>
              {loggedInUser?.permissions?.isEmployee && (
                <>
                  <Row className="mt-2">
                    <Col>
                      <h4 style={{ fontSize: '18px' }}>
                        No Sessions Available
                      </h4>
                    </Col>
                  </Row>

                  {canCreateJob && (
                    <Row style={{ marginLeft: '-22px' }}>
                      <Col>
                        <Button
                          size="sm"
                          variant="link"
                          onClick={() => {
                            history.push({
                              pathname: `/job`,
                              state: {
                                jobFormType: 'subject',
                                bookingFlowSubjectId: subjectId,
                                bookingFlowPackageId: sessionPackageNode.id,
                                bookingFlowSubjectGroupId: subjectGroupId,
                                bookingFlowPackageCategoryId: packageCategoryId,
                              },
                            })
                          }}
                          className="ml-2"
                        >
                          <PlusCircle className="mr-2" />
                          New Job
                        </Button>
                      </Col>
                    </Row>
                  )}
                </>
              )}
              {!loggedInUser?.permissions?.isEmployee && (
                <>
                  <span>No sessions available. To request a session,</span>
                  <button
                    onClick={handleBookInquiryChange}
                    className="ml-1 border-bottom border-primary text-primary btn-link"
                  >
                    fill out this form
                  </button>
                  .
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  )
}

export default ScheduleSession
