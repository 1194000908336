import React from 'react'
import { Row, Col, Form, InputGroup } from 'react-bootstrap'
import { CurrencyDollar } from 'react-bootstrap-icons'
import { formatCurrency } from '../../libs/utils'

const Policies = (props) => {
  const { formik, nodeType, node, settingsModal } = props

  let values = node
  if (formik) {
    values = formik.values
  }
  return (
    <div style={{ marginLeft: '-13px' }}>
      <div className={settingsModal ? 'col-md-12' : 'col-md-6'}>
        <div
          className={
            nodeType === 'settings' ||
            (nodeType === 'organization' &&
              values.usingNoShowPolicy === 'ORGANIZATION') ||
            (nodeType === 'subjectGroup' &&
              values.usingNoShowPolicy === 'SUBJECT_GROUP')
              ? 'border border-secondary px-2 pt-1'
              : ''
          }
        >
          {nodeType !== 'settings' && (
            <>
              <Form.Row>
                <Col md={4}>
                  <Form.Label>Using No Show Policy</Form.Label>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm="12" md={4}>
                  <Form.Control
                    name="usingNoShowPolicy"
                    as="select"
                    className="form-control form-control-sm"
                    disabled={node}
                    value={values.usingNoShowPolicy}
                    onChange={formik?.handleChange}
                    isInvalid={formik?.errors?.usingNoShowPolicy}
                  >
                    <option value={'SETTINGS'}>Settings</option>
                    <option value={'ORGANIZATION'}>Organization</option>
                    {nodeType === 'subjectGroup' && (
                      <option value={'SUBJECT_GROUP'}>Subject Group</option>
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formik?.errors?.usingNoShowPolicy}
                  </Form.Control.Feedback>
                </Col>
              </Form.Row>
            </>
          )}

          {(nodeType === 'settings' ||
            (nodeType === 'organization' &&
              values.usingNoShowPolicy === 'ORGANIZATION') ||
            (nodeType === 'subjectGroup' &&
              values.usingNoShowPolicy === 'SUBJECT_GROUP')) && (
            <>
              <Form.Row className="mt-3">
                <Form.Label column sm="3" className="pt-0">
                  No Show Policy
                </Form.Label>
                <Col md={6}>
                  {values.noShowFee ? (
                    <p>
                      Charge a {formatCurrency(values.noShowFee)} fee if a
                      subject fails to show up to a session
                    </p>
                  ) : (
                    <p>
                      Do not charge an extra fee if a subject fails to show up
                      to a session
                    </p>
                  )}
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label
                  column
                  sm="3"
                  className={values.noShowFee === 0 ? 'pt-0 mb-4' : 'pt-0'}
                >
                  No Show Fee
                </Form.Label>
                <Col md={6}>
                  <InputGroup>
                    <InputGroup.Text id="inputGroupPrepend">
                      <CurrencyDollar />
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      name="noShowFee"
                      disabled={node}
                      value={values.noShowFee}
                      onChange={formik?.handleChange}
                      isInvalid={formik?.errors?.noShowFee}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik?.errors?.noShowFee}
                  </Form.Control.Feedback>
                </Col>
              </Form.Row>
              {values.noShowFee !== 0 && (
                <>
                  <Form.Row className="mt-2">
                    <Col md={4}>
                      <Form.Label>Apply For Paid Sessions</Form.Label>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="applyNoShowPolicyPaid"
                          type="checkbox"
                          disabled={node}
                          checked={values.applyNoShowPolicyPaid}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `applyNoShowPolicyPaid`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md={4}>
                      <Form.Label>Apply For Free Sessions</Form.Label>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="applyNoShowPolicyFree"
                          type="checkbox"
                          checked={values.applyNoShowPolicyFree}
                          disabled={node}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `applyNoShowPolicyFree`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className={settingsModal ? 'col-md-12' : 'col-md-6'}>
        <div
          className={
            nodeType === 'settings' ||
            (nodeType === 'organization' &&
              values.usingCancellationPolicy === 'ORGANIZATION') ||
            (nodeType === 'subjectGroup' &&
              values.usingCancellationPolicy === 'SUBJECT_GROUP')
              ? 'border border-secondary px-2 pt-1 mt-3'
              : 'mt-3'
          }
        >
          {nodeType !== 'settings' && (
            <>
              <Form.Row className="mt-2">
                <Col md={6}>
                  <Form.Label>Using Reschedule Cancellation Policy</Form.Label>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm="12" md={4}>
                  <Form.Control
                    name="usingCancellationPolicy"
                    as="select"
                    className="form-control form-control-sm"
                    value={values.usingCancellationPolicy}
                    disabled={node}
                    onChange={formik?.handleChange}
                    isInvalid={formik?.errors?.usingCancellationPolicy}
                  >
                    <option value={'SETTINGS'}>Settings</option>
                    <option value={'ORGANIZATION'}>Organization</option>
                    {nodeType === 'subjectGroup' && (
                      <option value={'SUBJECT_GROUP'}>Subject Group</option>
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formik?.errors?.usingCancellationPolicy}
                  </Form.Control.Feedback>
                </Col>
              </Form.Row>
            </>
          )}
          {(nodeType === 'settings' ||
            (nodeType === 'organization' &&
              values.usingCancellationPolicy === 'ORGANIZATION') ||
            (nodeType === 'subjectGroup' &&
              values.usingCancellationPolicy === 'SUBJECT_GROUP')) && (
            <>
              <Form.Row className="mt-3">
                <Col md={8}>
                  <Form.Label>Reschedule Cancellation Policy</Form.Label>
                </Col>
              </Form.Row>
              <Form.Row
                className={values.refundPolicy === 'ALWAYS' ? 'mb-4' : ''}
              >
                <Col md={10}>
                  <Form.Control
                    name="refundPolicy"
                    as="select"
                    className="form-control-sm"
                    value={values.refundPolicy}
                    disabled={node}
                    onChange={formik?.handleChange}
                    isInvalid={formik?.errors?.refundPolicy}
                  >
                    <option key="ALWAYS" value="ALWAYS">
                      Refund all rescheduled or canceled sessions
                    </option>
                    <option key="TIME" value="TIME">
                      Refund depends on timing of reschedule or cancellation
                    </option>
                    <option key="NEVER" value="NEVER">
                      Never refund rescheduled or canceled session
                    </option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formik?.errors?.refundPolicy}
                  </Form.Control.Feedback>
                </Col>
              </Form.Row>

              {values.refundPolicy === 'TIME' ? (
                <>
                  <Form.Row className="mt-2">
                    <Col md={8}>
                      <Form.Label>Cancellation Policy Detail</Form.Label>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md={8}>
                      {values.timeRefundSessionPackageCost ? (
                        <p>
                          Do not refund the session package cost when a session
                          is rescheduled or canceled less than{' '}
                          {values.timeRefundHours} hours before it starts. If a
                          free session is canceled less than{' '}
                          {values.timeRefundHours} hours before it starts,
                          charge a {formatCurrency(values.timeRefundFee)} fee.
                        </p>
                      ) : (
                        <p>
                          Charge a {formatCurrency(values.timeRefundFee)} fee
                          when a session is rescheduled or canceled less than{' '}
                          {values.timeRefundHours} hours before it starts
                        </p>
                      )}
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md={4}>
                      <Form.Label>Hours Before Session</Form.Label>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md={4}>
                      <Form.Control
                        type="number"
                        name="timeRefundHours"
                        className="form-control-sm"
                        disabled={node}
                        value={values.timeRefundHours}
                        onChange={formik?.handleChange}
                        isInvalid={formik?.errors?.timeRefundHours}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik?.errors?.timeRefundHours}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Row>
                  <Form.Row className="mt-3">
                    <Col md={4}>
                      <Form.Label>
                        Cancellation Fee Is Session Package Cost
                      </Form.Label>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="timeRefundSessionPackageCost"
                          type="checkbox"
                          disabled={node}
                          checked={values.timeRefundSessionPackageCost}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `timeRefundSessionPackageCost`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Group as={Row}>
                    {values.timeRefundSessionPackageCost ? (
                      <Col md={4}>
                        <Form.Label>Free session cancellation fee</Form.Label>
                      </Col>
                    ) : (
                      <Col md={4}>
                        <Form.Label>Cancellation Fee</Form.Label>
                      </Col>
                    )}
                    <Col md={4}>
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend">
                          <CurrencyDollar />
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          disabled={node}
                          name="timeRefundFee"
                          value={values.timeRefundFee}
                          onChange={formik?.handleChange}
                          isInvalid={formik?.errors?.timeRefundFee}
                        />
                      </InputGroup>
                      <Form.Control.Feedback type="invalid">
                        {formik?.errors?.timeRefundFee}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </>
              ) : null}
              {values.refundPolicy === 'NEVER' ? (
                <>
                  <Form.Group as={Row} className="mt-3">
                    <Form.Label column sm="2" className="pt-0">
                      Policy Detail
                    </Form.Label>
                    <Col md={8}>
                      {values.timeRefundSessionPackageCost ? (
                        <p>
                          Do not refund the session package cost when a session
                          is rescheduled or canceled. If a free session is
                          canceled, charge a{' '}
                          {formatCurrency(values.timeRefundFee)} fee.
                        </p>
                      ) : (
                        <p>
                          Charge a {formatCurrency(values.timeRefundFee)} fee
                          when a session is rescheduled or canceled.
                        </p>
                      )}
                    </Col>
                  </Form.Group>
                  <Row style={{ marginLeft: '-10px' }}>
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="timeRefundSessionPackageCost"
                          type="checkbox"
                          disabled={node}
                          label="Cancellation fee is the session cost"
                          checked={values.timeRefundSessionPackageCost}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `timeRefundSessionPackageCost`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group as={Row}>
                    {values.timeRefundSessionPackageCost ? (
                      <Form.Label column sm="4">
                        Free session cancellation fee
                      </Form.Label>
                    ) : (
                      <Form.Label column sm="4">
                        Cancellation Fee
                      </Form.Label>
                    )}
                    <Col md={4}>
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend">
                          <CurrencyDollar />
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="timeRefundFee"
                          disabled={node}
                          value={values.timeRefundFee}
                          onChange={formik?.handleChange}
                          isInvalid={formik?.errors?.timeRefundFee}
                        />
                      </InputGroup>
                      <Form.Control.Feedback type="invalid">
                        {formik?.errors?.timeRefundFee}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </>
              ) : null}
              {values.refundPolicy !== 'ALWAYS' && (
                <>
                  <Form.Row className="mt-3">
                    <Col md={4}>
                      <Form.Label>Apply For Paid Sessions</Form.Label>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="applyPolicyPaid"
                          type="checkbox"
                          disabled={node}
                          checked={values.applyPolicyPaid}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `applyPolicyPaid`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md={4}>
                      <Form.Label>Apply For Free Sessions</Form.Label>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="applyPolicyFree"
                          type="checkbox"
                          disabled={node}
                          checked={values.applyPolicyFree}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `applyPolicyFree`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className={settingsModal ? 'col-md-12' : 'col-md-6'}>
        <div
          className={
            nodeType === 'settings' ||
            (nodeType === 'organization' &&
              values.usingResitPolicy === 'ORGANIZATION') ||
            (nodeType === 'subjectGroup' &&
              values.usingResitPolicy === 'SUBJECT_GROUP')
              ? 'border border-secondary px-2 pt-1 pb-3 mt-3'
              : 'mt-3'
          }
        >
          {nodeType !== 'settings' && (
            <>
              <Form.Row className="mt-3">
                <Col md={4}>
                  <Form.Label>Using Resit Policy</Form.Label>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm="12" md={4}>
                  <Form.Control
                    name="usingResitPolicy"
                    as="select"
                    disabled={node}
                    className="form-control-sm"
                    value={values.usingResitPolicy}
                    onChange={formik?.handleChange}
                    isInvalid={formik?.errors?.usingResitPolicy}
                  >
                    <option value={'SETTINGS'}>Settings</option>
                    <option value={'ORGANIZATION'}>Organization</option>
                    {nodeType === 'subjectGroup' && (
                      <option value={'SUBJECT_GROUP'}>Subject Group</option>
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formik?.errors?.usingResitPolicy}
                  </Form.Control.Feedback>
                </Col>
              </Form.Row>
            </>
          )}
          {nodeType === 'settings' && (
            <Form.Row className="mt-3">
              <Col md={4}>
                <Form.Label>Resit Policy</Form.Label>
              </Col>
            </Form.Row>
          )}
          {(nodeType === 'settings' ||
            (nodeType === 'organization' &&
              values.usingResitPolicy === 'ORGANIZATION') ||
            (nodeType === 'subjectGroup' &&
              values.usingResitPolicy === 'SUBJECT_GROUP')) && (
            <>
              <Form.Row className="mt-3">
                <Col md={5}>
                  <Form.Label>Charge Resit Fee for Free Sessions</Form.Label>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Check
                      name="resitFeeFreeSessions"
                      type="checkbox"
                      disabled={node}
                      checked={values.resitFeeFreeSessions}
                      onChange={(e) => {
                        formik?.setFieldValue(
                          `resitFeeFreeSessions`,
                          e.target.checked
                        )
                      }}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-3">
                <Col md={5}>
                  <Form.Label>Charge Resit Fee for Paid Sessions</Form.Label>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Check
                      name="resitFeePaidSessions"
                      type="checkbox"
                      disabled={node}
                      checked={values.resitFeePaidSessions}
                      onChange={(e) => {
                        formik?.setFieldValue(
                          `resitFeePaidSessions`,
                          e.target.checked
                        )
                      }}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-3">
                <Col md={5}>
                  <Form.Label>Free Package Resits Available</Form.Label>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Check
                      name="resitsIncludeFreeSessions"
                      type="checkbox"
                      disabled={node}
                      checked={values.resitsIncludeFreeSessions}
                      onChange={(e) => {
                        formik?.setFieldValue(
                          `resitsIncludeFreeSessions`,
                          e.target.checked
                        )
                      }}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4">
                <Form.Label column sm="4" className="pt-0">
                  Resit Fee
                </Form.Label>
                <Col md={4}>
                  <InputGroup>
                    <InputGroup.Text id="inputGroupPrepend">
                      <CurrencyDollar />
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      disabled={node}
                      className="form-control-sm"
                      name="resitFee"
                      value={values.resitFee}
                      onChange={formik?.handleChange}
                      isInvalid={formik?.errors?.resitFee}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik?.errors?.resitFee}
                  </Form.Control.Feedback>
                </Col>
              </Form.Row>
              {values.resitsIncludeFreeSessions && (
                <Form.Row className="mt-4">
                  <Form.Label column sm="4" className="pt-0">
                    Free Package Resit Cost
                  </Form.Label>
                  <Col md={4}>
                    <InputGroup>
                      <InputGroup.Text id="inputGroupPrepend">
                        <CurrencyDollar />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        disabled={node}
                        className="form-control-sm"
                        name="freePackageResitFee"
                        value={values.freePackageResitFee}
                        onChange={formik?.handleChange}
                        isInvalid={formik?.errors?.freePackageResitFee}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {formik?.errors?.freePackageResitFee}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Row>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Policies
