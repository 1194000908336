import { Field, useFormik } from 'formik'
import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { FilePond } from 'react-filepond'
import { CloudArrowUp } from 'react-bootstrap-icons'
import { gql, useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import JSZip from 'jszip'
import ProgressBar from 'react-bootstrap/ProgressBar'
import * as Yup from 'yup'
import Loading from '../../common/Loading'

const FTPUpload = (props) => {
  const [totalSize, setTotalSize] = useState(0)
  const [totalSizeTransferred, setTotalSizeTransferred] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleCloseModal = () => {
    setTotalSizeTransferred(0)
    setTotalSize(0)
    if (!isSubmitting) {
      formik.resetForm()
    }
  }

  const [ftpUpload] = useMutation(
    gql`
      mutation FtpUpload($input: FtpUploadInput!) {
        ftpUpload(input: $input) {
          upload
        }
      }
    `,
    {
      errorPolicy: 'all',
    }
  )

  const formik = useFormik({
    initialValues: {
      files: '',
      uploadLocation: '',
      uploadAsZip: false,
    },
    validationSchema: Yup.object().shape({
      files: Yup.array().test(
        'files-required',
        'At least one file is required',
        (files) => files && files.length > 0
      ),
    }),
    validateOnChange: false,
    onSubmit: async (values) => {
      setIsSubmitting(true)
      const MAX_SIZE = (1024 * 1024 * 1024) / 10 / 4 // 25MB in bytes
      let zip = new JSZip()
      let _totalSizeTransferred = 0
      let currentTotalSize = 0
      setTotalSize(values.files.reduce((acc, file) => acc + file.fileSize, 0))
      const generateAndUploadZip = async (
        content = null,
        uploadAsZip = null
      ) => {
        try {
          let uploadContent = content
          if (!uploadContent) {
            uploadContent = await zip.generateAsync({ type: 'blob' })
          }
          await ftpUpload({
            variables: {
              input: {
                uploadAsZip: uploadAsZip ? uploadAsZip : values.uploadAsZip,
                zipFile: {
                  image: uploadContent,
                  nameOnUpload: 'upload.zip',
                },
                uploadLocation: values.uploadLocation,
              },
            },
          })
          if (content) {
            zip = new JSZip()
            currentTotalSize = 0
          }
        } catch (error) {
          setIsSubmitting(false)
        }
      }
      for (const [index, file] of values.files.entries()) {
        if (file.fileExtension === 'zip') {
          await generateAndUploadZip(file.file, true)
        } else if (currentTotalSize + file.fileSize > MAX_SIZE) {
          await generateAndUploadZip()
          zip.file(file.filename, file.file)
          currentTotalSize += file.fileSize
          _totalSizeTransferred += file.fileSize
          if (index === values.files.length - 1) {
            await generateAndUploadZip()
          }
        } else if (index === values.files.length - 1) {
          zip.file(file.filename, file.file)
          currentTotalSize += file.fileSize
          _totalSizeTransferred += file.fileSize
          await generateAndUploadZip()
        } else {
          zip.file(file.filename, file.file)
          currentTotalSize += file.fileSize
          _totalSizeTransferred += file.fileSize
        }
        setTotalSizeTransferred(_totalSizeTransferred)
      }
      toast.success(`Bulk Upload Will Complete Shortly`)
      setIsSubmitting(false)
      handleCloseModal()
    },
  })

  const handleFileUpload = (updateFile) => {
    formik.setFieldValue('files', updateFile)
  }

  return (
    <>
      <div className="FtpUpload">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col xs={{ span: 10, offset: 1 }}>
              <FilePond
                allowMultiple
                maxFileSize="100MB"
                allowFileSizeValidation
                files={formik.values.files}
                onupdatefiles={handleFileUpload}
                name="addPreviewImages"
                labelIdle='Drag and drop images or <span class="filepond--label-action">Browse</span>'
              />
              {formik.errors?.files && (
                <span style={{ color: 'red' }}>{formik.errors?.files}</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mb-3" md={6}>
              <Form.Group>
                <label htmlFor="uploadLocation">Upload Location</label>
                <Form.Control
                  type="text"
                  value={formik.values.uploadLocation}
                  onChange={(e) => {
                    formik.setFieldValue('uploadLocation', e.target.value)
                  }}
                />
              </Form.Group>
            </Col>
            <Col className="mb-3" md={6}>
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                label="Upload as Zip Files"
                checked={formik.values.uploadAsZip}
                onChange={(e) => {
                  formik.setFieldValue('uploadAsZip', e.target.checked)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Button
                type="submit"
                variant="outline-primary"
                className="mt-2"
                block
                disabled={isSubmitting}
              >
                <CloudArrowUp className="mr-2" />
                Upload
              </Button>
            </Col>
          </Row>

          {isSubmitting && (
            <>
              <Row className="mt-3">
                <Col>
                  <ProgressBar
                    now={`${Math.round(
                      100 * (totalSizeTransferred / totalSize)
                    )}`}
                    label={`${Math.round(
                      100 * (totalSizeTransferred / totalSize)
                    )}% Complete Starting Bulk Upload`}
                  />
                </Col>
              </Row>
              <div className="mt-3">
                <Loading message="Starting Bulk Upload..." />
              </div>
            </>
          )}
        </Form>
      </div>
    </>
  )
}

export default FTPUpload
