import React from 'react'
import { Modal } from 'react-bootstrap'
import ProductForm from './ProductForm'
import { Cart3 } from 'react-bootstrap-icons'

const ProductModal = (props) => {
  const { showModal, toggleModal, product } = props
  if (!showModal) return <></>
  return (
    <>
      <div id="productModal">
        <Modal
          size={product ? 'xl' : 'lg'}
          show={showModal}
          onHide={toggleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Cart3 className="mr-2" />
              {product ? <>Edit Product</> : <>New Product</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProductForm product={product} afterSubmit={toggleModal} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default ProductModal
