import React from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import { Key } from 'react-bootstrap-icons'

const FotomerchantPasswordModal = (props) => {
  const { showModal, toggleModal, fotomerchantPassword, hideFotomerchantLogo } =
    props

  if (!showModal) return <></>
  return (
    <>
      <div className="deleteSubjectGroup">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton className="text-center">
            <Modal.Title id="new-title" className="w-100">
              {!hideFotomerchantLogo && (
                <img
                  className="mr-2"
                  src={window.location.origin + '/fotomerchant.svg'}
                  style={{ height: '30px' }}
                  alt="Fotomerchant logo"
                />
              )}
              Gallery Password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center">
              <Col className="text-center mt-3">
                <p style={{ fontSize: '16px' }}>
                  <Key className="mr-2" size={25} />
                  {fotomerchantPassword}
                </p>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default FotomerchantPasswordModal
