import React from 'react'
import { Modal } from 'react-bootstrap'
import ItemTypeForm from './ItemTypeForm'
import { CameraFill } from 'react-bootstrap-icons'

const ItemTypeModal = (props) => {
  const { showModal, toggleModal, itemType } = props
  if (!showModal) return <></>
  return (
    <>
      <div id="itemTypeModal">
        <Modal
          size={itemType ? 'lg' : 'md'}
          show={showModal}
          onHide={toggleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <CameraFill className="mr-2" />
              {itemType ? <>Edit Item Type</> : <>New Item Type</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ItemTypeForm itemType={itemType} afterSubmit={toggleModal} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default ItemTypeModal
