import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Card, Button, Table } from 'react-bootstrap'
import { settingsVar } from '../../libs/apollo'
import { useReactiveVar, gql, useQuery } from '@apollo/client'
import Loading from '../common/Loading'
import { Cloud, SlashCircle } from 'react-bootstrap-icons'
import ProgressBar from 'react-bootstrap/ProgressBar'
import EnterpriseSubscriptionModal from './EnterpriseSubscriptionModal'
import ChangeSubscriptionModal from './ChangeSubscriptionModal'

const Subscription = () => {
  const settings = useReactiveVar(settingsVar)
  const [changeSubscription, setChangeSubscription] = useState(false)
  const [tenantSubscriptionUsage, setTenantSubscriptionUsage] = useState()
  const [showEnterpriseSubscriptionModal, setShowEnterpriseSubscriptionModal] =
    useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState(
    settings.tenantSubscription
  )
  const [showChangeSubscriptionModal, setShowChangeSubscriptionModal] =
    useState(false)

  const { loading, data } = useQuery(
    gql`
      query TenantSubscriptionUsageQuery {
        tenantSubscriptionUsage {
          subscription
          usedUsers
          includedUsers
          usedSessionPackages
          includedSessionPackages
          usedNotifications
          includedNotifications
          usedFileStorage
          includedFileStorage
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    if (data) {
      setSelectedSubscription(data.tenantSubscriptionUsage.subscription)
      setTenantSubscriptionUsage(data.tenantSubscriptionUsage)
    }
  }, [data])

  if (loading || !data || !tenantSubscriptionUsage) return <Loading />
  const userPercent = Math.round(
    100 *
      (tenantSubscriptionUsage?.usedUsers /
        tenantSubscriptionUsage?.includedUsers)
  )
  const sessionPackagePercent = ['Premium', 'Enterprise'].includes(
    tenantSubscriptionUsage.subscription
  )
    ? null
    : Math.round(
        100 *
          (tenantSubscriptionUsage?.usedSessionPackages /
            tenantSubscriptionUsage?.includedSessionPackages)
      )
  const notificationPercent = Math.round(
    100 *
      (tenantSubscriptionUsage?.usedNotifications /
        tenantSubscriptionUsage?.includedNotifications)
  )
  const fileStoragePercent = Math.round(
    100 *
      (tenantSubscriptionUsage?.usedFileStorage /
        tenantSubscriptionUsage?.includedFileStorage)
  )

  const subscriptionTableData = [
    [
      'Monthly Subscription / Annual Subscription',
      '$180 / $1,900',
      '$300 / $3,200',
      '$550 / $5,900',
      'Variable',
    ],
    ['Users', '1-5', '1-10', '1-30', '1-30+'],
    ['Employee Management & Scheduling', 'x', 'x', 'x', 'x'],
    ['Photo Day Management & Organization', 'x', 'x', 'x', 'x'],
    ['Photo Day Management', 'x', 'x', 'x', 'x'],
    ['Customer Service Management', 'x', 'x', 'x', 'x'],
    ['Client History / Audit Log', '-', 'x', 'x', 'x'],
    ['CRM Management', 'x', 'x', 'x', 'x'],
    ['Online Scheduling', 'x', 'x', 'x', 'x'],
    ['Subject Group Management', 'x', 'x', 'x', 'x'],
    ['Session Packages (add $3ea)', '1-10', '1-30', 'Unlimited', 'Unlimited'],
    ['Advanced Schedule Management Client Portal', '-', 'x', 'x', 'x'],
    ['Invoicing & Financial Reporting', 'x', 'x', 'x', 'x'],
    ['Payment Storage - No Show Protection', 'x', 'x', 'x', 'x'],
    ['Data Security', 'x', 'x', 'x', 'x'],
    [
      'Email & SMS Notifications (add .02 per)',
      '2,000',
      '10,000',
      '50,000',
      '50,000+',
    ],
    ['Recurring Automated Notifications', '-', 'x', 'x', 'x'],
    ['Targeted Marketing', 'x', 'x', 'x', 'x'],
    ['Coupons', 'x', 'x', 'x', 'x'],
    ['Equipment Management', '-', 'x', 'x', 'x'],
    ['File Storage (add $5 per 100GB)', '1 GB', '10 GB', '500 GB', '500 GB+'],
    ['Workflow & Task Management', '-', 'x', 'x', 'x'],
    ['Integrations - Chat GPT', 'x', 'x', 'x', 'x'],
    ['Integrations - Intercom', 'x', 'x', 'x', 'x'],
    ['Integrations - Twilio', 'x', 'x', 'x', 'x'],
    ['Integrations - Stripe', 'x', 'x', 'x', 'x'],
    ['Integrations - Blueprint', 'x', 'x', 'x', 'x'],
    ['Integrations - Google Calendar', '-', 'x', 'x', 'x'],
  ]

  const getTdStyle = (subscription, columnIndex) => {
    if (
      (subscription === 'Basic' && columnIndex === 1) ||
      (subscription === 'Essential' && columnIndex === 2) ||
      (subscription === 'Premium' && columnIndex === 3) ||
      (subscription === 'Enterprise' && columnIndex === 4)
    ) {
      return { backgroundColor: '#f0f0f0' }
    }
    return {}
  }

  return (
    <>
      {showEnterpriseSubscriptionModal && (
        <EnterpriseSubscriptionModal
          showModal={showEnterpriseSubscriptionModal}
          toggleModal={() => setShowEnterpriseSubscriptionModal(false)}
        />
      )}
      {showChangeSubscriptionModal && (
        <ChangeSubscriptionModal
          setTenantSubscriptionUsage={setTenantSubscriptionUsage}
          showModal={showChangeSubscriptionModal}
          toggleModal={() => setShowChangeSubscriptionModal(false)}
          subscription={selectedSubscription}
          setSelectedSubscription={setSelectedSubscription}
          setChangeSubscription={setChangeSubscription}
        />
      )}
      <Container
        fluid
        className="d-flex justify-content-center"
        style={{ minHeight: '80vh', paddingTop: '10vh' }}
      >
        <Card
          className="p-4 shadow"
          style={{ maxWidth: '800px', width: '100%' }}
        >
          <Card.Body>
            {!changeSubscription && (
              <>
                <Row className="mt-3 text-center">
                  <Col>
                    <h5>{settings.tenantSubscription} Subscription</h5>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    {settings.tenantSubscription === 'Basic' && (
                      <p>
                        The Basic Subscription is ideal for smaller businesses
                        with a limited number of users and a focused range of
                        services. This affordable solution fits seamlessly into
                        your current operations, and scaling up is easy when
                        your business grows. It is perfectly suited for those
                        who specialize in portrait and wedding photography,
                        along with managing a few school accounts.
                      </p>
                    )}
                    {settings.tenantSubscription === 'Essential' && (
                      <p>
                        Our Essential Subscription provides full access to all
                        features, equipping you with the tools to effectively
                        manage every aspect of your operations. It is best
                        suited for businesses serving 30-50 organizations and
                        offering a diverse range of services. With the Advanced
                        Schedule Management Portal, you empower clients to
                        manage their bookings, while the Workflow/Task feature
                        helps you stay on top of key service components. The
                        Client History Audit tracks every interaction with your
                        clients, ensuring smooth communication and personalized
                        service. Additionally, you can manage and track
                        inventory and supplies issued to staff, safeguarding
                        your valuable equipment. With increased file storage,
                        user notifications, and robust support, your business is
                        fully covered.
                      </p>
                    )}
                    {settings.tenantSubscription === 'Premium' && (
                      <p>
                        The Premium Plan offers everything you need for
                        large-scale operations. With unlimited service types
                        (session packages), your offerings to clients are
                        limitless. Peak-level notifications, file storage, and
                        support provide you with the highest level of
                        functionality with the least amount of distractions.
                        Ideal for businesses serving 70-100 organizations with a
                        high number of users, this subscription ensures you are
                        equipped for growth. Our flexible feature add-ons allow
                        for seamless scaling, and if you require a custom
                        subscription, we are re here to assist with an easy
                        transition.
                      </p>
                    )}
                  </Col>
                </Row>

                <hr />

                {[
                  {
                    label: 'Users',
                    used: tenantSubscriptionUsage?.usedUsers,
                    total: tenantSubscriptionUsage?.includedUsers,
                    percent: userPercent,
                  },
                  {
                    label: 'Session Packages',
                    used: tenantSubscriptionUsage?.usedSessionPackages,
                    total: tenantSubscriptionUsage?.includedSessionPackages,
                    percent: sessionPackagePercent,
                  },
                  {
                    label: 'Notifications',
                    used: tenantSubscriptionUsage?.usedNotifications,
                    total: tenantSubscriptionUsage?.includedNotifications,
                    percent: notificationPercent,
                  },
                  {
                    label: 'GB File Storage',
                    used: tenantSubscriptionUsage?.usedFileStorage,
                    total: tenantSubscriptionUsage?.includedFileStorage,
                    percent: fileStoragePercent,
                  },
                ].map((item, index) => {
                  if (
                    item.label === 'Session Packages' &&
                    ['Premium', 'Enterprise'].includes(
                      settings.tenantSubscription
                    )
                  ) {
                    return
                  }
                  return (
                    <Row className="mt-3" key={index}>
                      <Col>
                        <p style={{ fontSize: '1rem' }}>
                          {item.used?.toLocaleString()} /{' '}
                          {item.total?.toLocaleString()} {item.label}
                        </p>
                        <ProgressBar
                          animated
                          variant={
                            item.percent <= 66
                              ? 'success'
                              : item.percent <= 90
                                ? 'warning'
                                : 'danger'
                          }
                          now={item.percent}
                          label={`${item.percent}%`}
                          className="mb-3"
                        />
                      </Col>
                    </Row>
                  )
                })}
                <Row>
                  <Col md={{ span: 6, offset: 3 }} className="text-center">
                    <Button
                      block
                      variant="outline-primary"
                      onClick={() => {
                        setChangeSubscription(true)
                        setSelectedSubscription(settings.tenantSubscription)
                      }}
                    >
                      <Cloud className="mr-2" />
                      Change Subscription
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {changeSubscription && (
              <>
                <Row>
                  <Col>
                    <h2 className="text-center mb-4">Select Subscription</h2>
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>Features</th>
                          <th>
                            <span
                              className={
                                selectedSubscription !== 'Basic'
                                  ? 'btn-link'
                                  : ''
                              }
                              onClick={() => setSelectedSubscription('Basic')}
                            >
                              Basic
                            </span>
                          </th>
                          <th>
                            <span
                              className={
                                selectedSubscription !== 'Essential'
                                  ? 'btn-link'
                                  : ''
                              }
                              onClick={() =>
                                setSelectedSubscription('Essential')
                              }
                            >
                              Essential
                            </span>
                          </th>
                          <th>
                            <span
                              className={
                                selectedSubscription !== 'Premium'
                                  ? 'btn-link'
                                  : ''
                              }
                              onClick={() => setSelectedSubscription('Premium')}
                            >
                              Premium
                            </span>
                          </th>
                          <th>
                            <span
                              className={
                                selectedSubscription !== 'Enterprise'
                                  ? 'btn-link'
                                  : ''
                              }
                              onClick={() =>
                                setSelectedSubscription('Enterprise')
                              }
                            >
                              Enterprise
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscriptionTableData.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {row.map((cell, colIndex) => (
                              <td
                                key={colIndex}
                                style={getTdStyle(
                                  selectedSubscription,
                                  colIndex
                                )}
                              >
                                {cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                {selectedSubscription !== settings.tenantSubscription && (
                  <Row>
                    <Col md={{ span: 6, offset: 3 }} className="text-center">
                      <Button
                        block
                        variant="outline-primary"
                        onClick={() => {
                          if (selectedSubscription === 'Enterprise') {
                            setShowEnterpriseSubscriptionModal(true)
                          } else {
                            setShowChangeSubscriptionModal(true)
                          }
                        }}
                      >
                        <Cloud className="mr-2" />
                        Change To {selectedSubscription} Subscription
                      </Button>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={{ span: 6, offset: 3 }} className="text-center">
                    <Button
                      block
                      variant="outline-primary"
                      onClick={() => {
                        setChangeSubscription(false)
                        setSelectedSubscription(settings.tenantSubscription)
                      }}
                    >
                      <SlashCircle className="mr-2" />
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}

export default Subscription
