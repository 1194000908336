import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const TaskStatusSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    multiple,
    create,
    placeholder,
    setAdditionalFields,
    error,
    variables,
    dropdown,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="taskStatus"
      placeholder={placeholder}
      searchDescription={searchDescription ? searchDescription : 'Status'}
      nodeNamePlural="taskStatuses"
      formikValue={formikValue}
      disabled={disabled}
      multiple={multiple}
      error={error}
      create={create}
      setAdditionalFields={setAdditionalFields}
      variables={variables}
      dropdown={dropdown}
      gql={gql`
        query TaskStatuses($after: String, $search: String) {
          taskStatuses(
            first: 250
            after: $after
            orderBy: "name"
            name_Icontains: $search
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return node.name
      }}
    />
  )
}

export default TaskStatusSearchInput
