import { ErrorMessage, FieldArray } from 'formik'
import React from 'react'
import { Form, Col, Row, Button } from 'react-bootstrap'
import { Trash, CalendarDate, PlusCircle } from 'react-bootstrap-icons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../../libs/apollo'
import { dayOrdinal } from '../../../libs/utils'

export default function MultiDayJob(props) {
  const { formik } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Scheduling Manager', 'Administrator'].includes(
    loggedInUser?.permissions?.group
  )
  return (
    <>
      {formik.values.multiDay && (
        <FieldArray
          name="multiDayJobDates"
          render={(arrayHelpers) => (
            <>
              <Row>
                <Col md={8}>
                  <div
                    style={
                      formik.values.multiDayJobDates.length > 0
                        ? {
                            overflowY: 'scroll',
                            maxHeight: '200px',
                            overflowX: 'hidden',
                            marginLeft: '-5px',
                          }
                        : null
                    }
                    className={
                      formik.values.multiDayJobDates.length > 0
                        ? 'px-3 pt-2'
                        : ''
                    }
                  >
                    <div>
                      <Row>
                        {formik.values.multiDayJobDates.map(
                          (multiDayJobDate, index) => (
                            <Col
                              lg={3}
                              md={4}
                              sm={12}
                              key={index}
                              className={
                                formik.values.multiDayJobDates.length > 0
                                  ? 'px-1 mb-2'
                                  : ''
                              }
                            >
                              <div>
                                <Form.Group>
                                  <div className="d-flex align-items-center">
                                    {canMutate && (
                                      <div className="btn-link d-flex align-items-center mr-2">
                                        <Trash
                                          size={14}
                                          className="btn-link"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        />
                                      </div>
                                    )}
                                    <Form.Label className="mb-0">
                                      {dayOrdinal(index + 2)} Date
                                    </Form.Label>
                                  </div>
                                  <DatePicker
                                    portalId="datepicker-portal"
                                    name={`multiDayJobDates[${index}].date`}
                                    className="form-control form-control-sm"
                                    disabled={!canMutate}
                                    selected={multiDayJobDate.date}
                                    popperPlacement="bottom-end"
                                    popperModifiers={{
                                      preventOverflow: {
                                        enabled: true,
                                        escapeWithReference: false,
                                        boundariesElement: 'viewport',
                                      },
                                    }}
                                    onChange={(date) =>
                                      formik.setFieldValue(
                                        `multiDayJobDates[${index}].date`,
                                        date
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name={`multiDayJobDates[${index}].date`}
                                  >
                                    {(msg) => (
                                      <span className="text-danger">{msg}</span>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </div>
                            </Col>
                          )
                        )}
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>

              {canMutate && formik.values.multiDay && (
                <Button
                  variant="link"
                  style={{ marginTop: '-10px' }}
                  className={'p-0 btn-link mb-3'}
                  onClick={() => {
                    arrayHelpers.push({
                      date: '',
                    })
                  }}
                >
                  <PlusCircle className="mr-2" />
                  Add Date
                </Button>
              )}
            </>
          )}
        />
      )}
    </>
  )
}
