import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  InputGroup,
  Table,
} from 'react-bootstrap'
import { CaretDown, CaretRight, Trash, PlusCircle } from 'react-bootstrap-icons'
import DatePicker from 'react-datepicker'
import * as Yup from 'yup'
import produce from 'immer'
import { useFormik } from 'formik'
import { objEmpty } from '../../libs/utils'
import toast from 'react-hot-toast'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import AuditLog from '../audit_log/AuditLog'
import { Gift } from 'react-bootstrap-icons'
import Sessions from '../sessions/Sessions'

const PromoCodeModal = (props) => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Scheduling Manager', 'Administrator'].includes(
    loggedInUser?.permissions?.group
  )
  const { showModal, toggleModal, editCoupon, setEditCoupon } = props
  const [packageSearch, setPackageSearch] = useState('')
  const [organizationSearch, setOrganizationSearch] = useState('')
  const [subjectGroupSearch, setSubjectGroupSearch] = useState('')
  const [removeSessionPackages, setRemoveSessionPackages] = useState([])
  const [removeOrganizations, setRemoveOrganizations] = useState([])
  const [sessionPackages, setSessionPackages] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [subjectGroups, setSubjectGroups] = useState([])
  const [removeSubjectGroups, setRemoveSubjectGroups] = useState([])
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const [displaySessions, setDisplaySessions] = useState(false)

  const [deleteCoupon] = useMutation(
    gql`
      mutation deleteCoupon($deleteCouponInput: DeleteCouponInput!) {
        deleteCoupon(input: $deleteCouponInput) {
          deleted
        }
      }
    `,
    {
      errorPolicy: 'all',
      variables: {
        deleteCouponInput: {
          couponIds: editCoupon.id,
        },
      },
      onCompleted(data) {
        toast.success('Coupon Deleted')
        toggleModal()
      },
      refetchQueries: ['CouponsQuery'],
    }
  )

  const [createCoupon] = useMutation(
    gql`
      mutation createCoupon($createCouponInput: CreateCouponInput!) {
        createCoupon(input: $createCouponInput) {
          coupon {
            id
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted(data) {
        toast.success('Coupon Added')
        toggleModal()
      },
      refetchQueries: ['CouponsQuery'],
    }
  )

  const [updateCoupon] = useMutation(
    gql`
      mutation updateCoupon($updateCoupon: UpdateCouponInput!) {
        updateCoupon(input: $updateCoupon) {
          coupon {
            id
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted(data) {
        toast.success('Coupon Updated')
        toggleModal()
      },
      refetchQueries: ['CouponsQuery'],
    }
  )

  const [validateUniquePromoCode] = useLazyQuery(
    gql`
      query ValidateUniquePromoCodeQuery($code: String) {
        coupons(code_Iexact: $code) {
          edges {
            node {
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted(data) {
        let valid = true
        if (
          (objEmpty(editCoupon) && data.coupons.edges.length > 0) ||
          (!objEmpty(editCoupon) &&
            data.coupons.edges.length > 0 &&
            data.coupons.edges[0].node.id != editCoupon.id)
        ) {
          valid = false
        }
        if (!valid) {
          formik.setErrors({
            ...formik.errors,
            ...{
              code: 'Promo code already exists',
            },
          })
        }
      },
    }
  )

  const [searchPackages] = useLazyQuery(
    gql`
      query SearchPackagesQuery(
        $after: String
        $first: Int
        $titleIstartswith: String
      ) {
        sessionPackages(
          after: $after
          first: $first
          title_Istartswith: $titleIstartswith
        ) {
          edges {
            node {
              id
              title
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted(data) {
        setSessionPackages(data?.sessionPackages.edges)
      },
    }
  )

  const [searchOrganizations] = useLazyQuery(
    gql`
      query SearchOrganizationsQuery(
        $after: String
        $first: Int
        $nameIstartswith: String
      ) {
        organizations(
          after: $after
          first: $first
          name_Icontains: $nameIstartswith
        ) {
          edges {
            node {
              id
              name
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted(data) {
        setOrganizations(data?.organizations.edges)
      },
    }
  )

  const [searchSubjectGroups] = useLazyQuery(
    gql`
      query searchSubjectGroups(
        $after: String
        $first: Int
        $nameIstartswith: String
      ) {
        subjectGroups(
          after: $after
          first: $first
          name_Icontains: $nameIstartswith
        ) {
          edges {
            node {
              id
              name
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted(data) {
        setSubjectGroups(data?.subjectGroups.edges)
      },
    }
  )

  const [
    promoCodeSessions,
    { data: couponSessionsData, fetchMore: fetchMoreCouponSessions },
  ] = useLazyQuery(
    gql`
      query PromoCodeSessionsQuery($after: String!, $couponId: ID!) {
        sessions(after: $after, first: 5, coupon_Id: $couponId) {
          nodeCount
          edges {
            node {
              subject {
                id
                gaiaUser {
                  fullName
                }
              }
              subjectGroup {
                name
                id
                organization {
                  name
                  id
                }
              }
              organization {
                name
                id
              }
              job {
                name
                id
              }
              sessionPackage {
                title
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        after: 0,
        couponId: editCoupon.id,
      },
    }
  )

  const formik = useFormik({
    initialValues: {
      id: '',
      code: '',
      savingsType: '%',
      savings: 0,
      enabled: true,
      limitUseage: 'Unlimited',
      usableCount: 0,
      expires: 'No',
      expiresOn: null,
      sessionPackages: [],
      organizations: [],
      subjectGroups: [],
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      code: Yup.string()
        .required('This field is required')
        .test('nameUnique', 'Promo code already exists', (value, context) => {
          validateUniquePromoCode({
            variables: {
              code: value,
            },
          })
          if (!formik.errors.code) {
            return true
          }
          return false
        }),
      limitUseage: Yup.string()
        .required('This field is required')
        .test(
          'limitUseageTest',
          'Must be either limited or unlimited',
          (value, context) => {
            if (value !== 'Limited' && value !== 'Unlimited') {
              return false
            } else {
              return true
            }
          }
        ),
      usableCount: Yup.number().test(
        'usableCountTest',
        'Must be greater than zero',
        (value, context) => {
          if (context.parent.limitUseage === 'Limited' && value < 1) {
            return false
          } else {
            return true
          }
        }
      ),
      savingsType: Yup.string()
        .required()
        .test('savingsTypeTest', 'Must be either % or $', (value, context) => {
          if (value !== '$' && value !== '%') {
            return false
          } else {
            return true
          }
        }),
      savings: Yup.number()
        .required('This field is required')
        .test('savingsTest', 'Invalid savings', (value, context) => {
          if (
            context.parent.savingsType === '%' &&
            (value < 1 || value > 100)
          ) {
            return false
          } else if (context.parent.savingsType === '$' && value < 1) {
            return false
          } else {
            return true
          }
        }),
      expires: Yup.string()
        .required()
        .test('expiresTest', 'Must be either Yes or No', (value, context) => {
          if (value !== 'Yes' && value !== 'No') {
            return false
          } else {
            return true
          }
        }),
      expiresOn: Yup.mixed().test(
        'expiresOnTest',
        'Required',
        (value, context) => {
          if (context.parent.expires === 'Yes' && !value) {
            return false
          } else {
            return true
          }
        }
      ),
      sessionPackages: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          name: Yup.string(),
        })
      ),
      organizations: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          name: Yup.string(),
        })
      ),
      subjectGroups: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          name: Yup.string(),
        })
      ),
    }),
    onSubmit: (values) => {
      let dollarSavings
      let percentSavings
      let usableCount
      if (values.savingsType == '%') {
        dollarSavings = null
        percentSavings = values.savings
      } else {
        dollarSavings = values.savings
        percentSavings = null
      }
      if (values.usableCount > 0) {
        usableCount = values.usableCount
      }
      const addSessionPackages = values.sessionPackages.map(
        (sessionPackage) => {
          return sessionPackage.id
        }
      )
      const addOrganizations = values.organizations.map((organization) => {
        return organization.id
      })
      const addSubjectGroups = values.subjectGroups.map((subjectGroup) => {
        return subjectGroup.id
      })
      if (!objEmpty(editCoupon)) {
        updateCoupon({
          variables: {
            updateCoupon: {
              couponInput: {
                dollarSavings,
                percentSavings,
                usableCount,
                addSessionPackages,
                addOrganizations,
                id: editCoupon.id,
                enabled: values.enabled,
                code: values.code,
                expiresOn: values.expiresOn,
                addSubjectGroups,
                removeSessionPackages: removeSessionPackages,
                removeOrganizations: removeOrganizations,
                removeSubjectGroups: removeSubjectGroups,
              },
            },
          },
        })
      } else {
        createCoupon({
          variables: {
            createCouponInput: {
              couponInput: {
                dollarSavings,
                percentSavings,
                usableCount,
                code: values.code,
                enabled: values.enabled,
                expiresOn: values.expiresOn,
                sessionPackages: addSessionPackages,
                organizations: addOrganizations,
                subjectGroups: addSubjectGroups,
              },
            },
          },
        })
      }
    },
  })
  const handleSessionSessionPackageInput = (e) => {
    if (e.target.value) {
      searchPackages({
        variables: {
          first: 250,
          titleIstartswith: e.target.value,
        },
      })
    } else {
      setSessionPackages(null)
    }
    setPackageSearch(e.target.value)
  }

  const handleOrganizationInput = (e) => {
    if (e.target.value) {
      searchOrganizations({
        variables: {
          first: 250,
          nameIstartswith: e.target.value,
        },
      })
    } else {
      setOrganizations([])
    }
    setOrganizationSearch(e.target.value)
  }

  const handleSubjectGroupInput = (e) => {
    if (e.target.value) {
      searchSubjectGroups({
        variables: {
          first: 250,
          nameIstartswith: e.target.value,
        },
      })
    } else {
      setSubjectGroups([])
    }
    setSubjectGroupSearch(e.target.value)
  }

  const handleSelectSessionPackage = (sessionPackage) => {
    formik.setFieldValue('sessionPackages', [
      ...formik.values.sessionPackages,
      { id: sessionPackage.id, name: sessionPackage.title },
    ])
    setPackageSearch('')
    setSessionPackages([])
  }

  const handleRemoveSessionPackage = (sessionIndex) => {
    let tempArray = [...removeSessionPackages]
    const nextState = produce(formik.values.sessionPackages, (draftState) => {
      draftState.splice(sessionIndex, 1)
    })
    const removaData = formik.values.sessionPackages[sessionIndex]
    tempArray.push(removaData.id)
    setRemoveSessionPackages(tempArray)
    formik.setFieldValue('sessionPackages', nextState)
  }

  const handleSelectOrganization = (organization) => {
    formik.setFieldValue('organizations', [
      ...formik.values.organizations,
      { id: organization.id, name: organization.name },
    ])
    setOrganizationSearch('')
    setOrganizations([])
  }

  const handleRemoveOrganization = (organizationIndex) => {
    let tempArray = [...removeOrganizations]
    const nextState = produce(formik.values.organizations, (draftState) => {
      draftState.splice(organizationIndex, 1)
    })
    const removaData = formik.values.organizations[organizationIndex]
    tempArray.push(removaData.id)
    setRemoveOrganizations(tempArray)
    formik.setFieldValue('organizations', nextState)
  }

  const handleRemoveSubjectGroup = (sgIndex) => {
    let tempArray = [...removeSubjectGroups]
    const nextState = produce(formik.values.subjectGroups, (draftState) => {
      draftState.splice(sgIndex, 1)
    })
    const removaData = formik.values.subjectGroups[sgIndex]
    tempArray.push(removaData.id)
    setRemoveSubjectGroups(tempArray)
    formik.setFieldValue('subjectGroups', nextState)
  }

  const handleSelectSubjectGroup = (subjectGroup) => {
    formik.setFieldValue('subjectGroups', [
      ...formik.values.subjectGroups,
      { id: subjectGroup.id, name: subjectGroup.name },
    ])
    setSubjectGroupSearch('')
    setSubjectGroups([])
  }

  useEffect(() => {
    if (!showModal) {
      setOrganizations([])
      setSessionPackages([])
      setPackageSearch('')
      setOrganizationSearch('')
      setSubjectGroupSearch('')
      setRemoveOrganizations([])
      setRemoveSessionPackages([])
      setRemoveSubjectGroups([])
      setSubjectGroups([])
      setEditCoupon({})
      formik.resetForm()
    }
  }, [showModal])

  useEffect(() => {
    if (!objEmpty(editCoupon)) {
      promoCodeSessions({
        variables: {
          couponId: editCoupon.id,
        },
      })
      const sessionPackages = []
      if (editCoupon.sessionPackages.edges.length > 0) {
        editCoupon.sessionPackages.edges.forEach((sessionPack) => {
          sessionPackages.push({
            id: sessionPack.node.id,
            name: sessionPack.node.title,
          })
        })
      }
      const organizations = []
      if (editCoupon.organizations.edges.length > 0) {
        editCoupon.organizations.edges.forEach((organization) => {
          organizations.push({
            id: organization.node.id,
            name: organization.node.name,
          })
        })
      }
      const subjectGroups = []
      if (editCoupon.subjectGroups.edges.length > 0) {
        editCoupon.subjectGroups.edges.forEach((subjectGroup) => {
          subjectGroups.push({
            id: subjectGroup.node.id,
            name: subjectGroup.node.name,
          })
        })
      }
      let { code, usableCount, percentSavings, dollarSavings } = editCoupon
      let limitUseage = 'Unlimited'
      if (usableCount) {
        limitUseage = 'Limited'
      } else {
        usableCount = 0
      }
      let savingsType = '%'
      let savings = percentSavings
      if (dollarSavings) {
        savingsType = '$'
        savings = dollarSavings
      }
      let expires = 'No'
      let expiresOn
      if (editCoupon.expiresOn) {
        expires = 'Yes'
        expiresOn = new Date(editCoupon.expiresOn)
      }
      formik.setValues({
        code,
        savingsType,
        limitUseage,
        savings,
        expires,
        usableCount,
        expiresOn,
        enabled: editCoupon.enabled,
        sessionPackages,
        organizations,
        subjectGroups,
      })
    }
  }, [editCoupon])
  if (!showModal) return <></>
  return (
    <Modal
      size={displaySessions ? 'xl' : 'lg'}
      show={showModal}
      onHide={() => toggleModal()}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Gift className="mr-2" />
            {!objEmpty(editCoupon) && <>{canMutate && <>Edit</>} Coupon</>}
            {objEmpty(editCoupon) && <>New Coupon</>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Row>
              <Col md={2} className="d-flex align-items-center">
                <Form.Label className="mb-0">Code</Form.Label>
              </Col>
              <Col
                md={{ offset: 4, span: 2 }}
                className="d-flex align-items-center"
              >
                <Form.Label className="mb-0">Enabled</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Control
                  type="text"
                  name="code"
                  className="form-control-sm"
                  disabled={!canMutate}
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.code}
                />
                {formik.errors.code ? (
                  <p className="text-danger">{formik.errors.code}</p>
                ) : null}
              </Col>
              <Col sm="12" md={2}>
                <Form.Check
                  type="checkbox"
                  name="enabled"
                  checked={formik.values.enabled}
                  onChange={() => {
                    formik.setFieldValue('enabled', !formik.values.enabled)
                  }}
                  isInvalid={formik.errors.enabled}
                  feedback={formik.errors.enabled}
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col md={6} className="d-flex align-items-center">
                <Form.Label className="mb-0">Savings</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Control
                  as="select"
                  name="savingsType"
                  className="form-control-sm"
                  disabled={!canMutate}
                  value={formik.values.savingsType}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.savingsType}
                >
                  <option value={'%'}>%</option>
                  <option value={'$'}>$</option>
                </Form.Control>
              </Col>
              <Col md={6}>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="savings"
                    className="form-control-sm"
                    disabled={!canMutate}
                    value={formik.values.savings}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.savings}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col md={6} className="d-flex align-items-center">
                <Form.Label className="mb-0">Usage</Form.Label>
              </Col>
              {formik.values.limitUseage == 'Limited' ? (
                <Col md={6} className="d-flex align-items-center">
                  <Form.Label className="mb-0">
                    Usable {formik.values.usableCount} Times
                  </Form.Label>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col md={6}>
                <Form.Control
                  as="select"
                  name="limitUseage"
                  className="form-control-sm"
                  disabled={!canMutate}
                  value={formik.values.limitUseage}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.limitUseage}
                >
                  <option value="Unlimited">Unlimited</option>
                  <option value="Limited">Limited</option>
                </Form.Control>
              </Col>
              {formik.values.limitUseage == 'Limited' ? (
                <Col md={6}>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="usableCount"
                      className="form-control-sm"
                      disabled={!canMutate}
                      value={formik.values.usableCount}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.usableCount}
                    />
                  </InputGroup>
                </Col>
              ) : null}
            </Row>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col md={6} className="d-flex align-items-center">
                <Form.Label className="mb-0">Expires</Form.Label>
              </Col>
              {formik.values.expires == 'Yes' ? (
                <Col md={6} className="d-flex align-items-center">
                  <Form.Label className="mb-0">On</Form.Label>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col md={6}>
                <Form.Control
                  as="select"
                  name="expires"
                  className="form-control-sm"
                  disabled={!canMutate}
                  value={formik.values.expires}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.expires}
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </Form.Control>
              </Col>
              {formik.values.expires == 'Yes' ? (
                <Col md={6}>
                  <DatePicker
                    name="expiresOn"
                    disabled={!canMutate}
                    className="form-control"
                    showPopperArrow={false}
                    popperPlacement="auto"
                    selected={formik.values.expiresOn}
                    onChange={(date) => formik.setFieldValue('expiresOn', date)}
                    popperModifiers={{
                      flip: {
                        behavior: ['bottom'],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                  />
                  {formik.errors.expiresOn ? (
                    <p className="text-danger">{formik.errors.expiresOn}</p>
                  ) : null}
                </Col>
              ) : null}
            </Row>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col className="d-flex align-items-center">
                <Form.Label className="mb-0">Organizations</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    className="form-control-sm"
                    disabled={!canMutate}
                    value={organizationSearch}
                    onChange={handleOrganizationInput}
                  />
                  {organizations ? (
                    <div style={{ zIndex: 500 }}>
                      <Table size="lg" hover>
                        <tbody>
                          {organizations.map((organization) => {
                            const { node } = organization
                            if (
                              !formik.values.organizations.some(
                                (org) => org.id === node.id
                              )
                            ) {
                              return (
                                <tr
                                  onMouseDown={() => {
                                    handleSelectOrganization(node)
                                  }}
                                  key={node.id}
                                  className="hover text-decoration-none"
                                >
                                  <td>
                                    <small
                                      className="text-truncate d-block"
                                      style={{
                                        width: '280px',
                                      }}
                                    >{`${node.name}`}</small>
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </tbody>
                      </Table>
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                {formik.values.organizations.map((organization, idx) => {
                  return (
                    <span
                      style={{
                        padding: '5px',
                        border: '.5px solid #6c757d',
                        borderRadius: '0.25rem',
                        marginLeft: idx > 0 ? '0.5rem' : '0',
                      }}
                      key={idx}
                    >
                      {canMutate && (
                        <Trash
                          className="mr-2 btn-link hover"
                          role="presentation"
                          onClick={() => handleRemoveOrganization(idx)}
                          size={14}
                        />
                      )}

                      <span style={{ fontSize: '14px' }}>
                        {organization.name}
                      </span>
                    </span>
                  )
                })}
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col className="d-flex align-items-center">
                <Form.Label className="mb-0">Subject Groups</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    className="form-control-sm"
                    disabled={!canMutate}
                    value={subjectGroupSearch}
                    onChange={handleSubjectGroupInput}
                  />
                  {subjectGroups ? (
                    <div style={{ zIndex: 500 }}>
                      <Table size="lg" hover>
                        <tbody>
                          {subjectGroups.map((sg) => {
                            const { node } = sg
                            if (
                              !formik.values.subjectGroups.some(
                                (sg) => sg.id === node.id
                              )
                            ) {
                              return (
                                <tr
                                  onMouseDown={() => {
                                    handleSelectSubjectGroup(node)
                                  }}
                                  key={node.id}
                                  className="hover text-decoration-none"
                                >
                                  <td>
                                    <small
                                      className="text-truncate d-block"
                                      style={{
                                        width: '280px',
                                      }}
                                    >{`${node.name}`}</small>
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </tbody>
                      </Table>
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                {formik.values.subjectGroups.map((subjectGroup, idx) => {
                  return (
                    <span
                      style={{
                        padding: '5px',
                        border: '.5px solid #6c757d',
                        borderRadius: '0.25rem',
                        marginLeft: idx > 0 ? '0.5rem' : '0',
                      }}
                      key={idx}
                    >
                      {canMutate && (
                        <Trash
                          className="mr-2 btn-link hover"
                          role="presentation"
                          onClick={() => handleRemoveSubjectGroup(idx)}
                          size={14}
                        />
                      )}

                      <span style={{ fontSize: '14px' }}>
                        {subjectGroup.name}
                      </span>
                    </span>
                  )
                })}
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col className="d-flex align-items-center">
                <Form.Label className="mb-0">Session Packages</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    className="form-control-sm"
                    disabled={!canMutate}
                    value={packageSearch}
                    onChange={handleSessionSessionPackageInput}
                  />
                  {sessionPackages ? (
                    <div style={{ zIndex: 500 }}>
                      <Table size="lg" hover>
                        <tbody>
                          {sessionPackages.map((sessionPackage) => {
                            const { node } = sessionPackage
                            if (
                              !formik.values.sessionPackages.some(
                                (sp) => sp.id === node.id
                              )
                            ) {
                              return (
                                <tr
                                  onMouseDown={() => {
                                    handleSelectSessionPackage(node)
                                  }}
                                  key={node.id}
                                  className="hover text-decoration-none"
                                >
                                  <td>
                                    <small
                                      className="text-truncate d-block"
                                      style={{
                                        width: '280px',
                                      }}
                                    >{`${node.title}`}</small>
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </tbody>
                      </Table>
                    </div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                {formik.values.sessionPackages.map((sessionPackage, idx) => {
                  return (
                    <span
                      style={{
                        padding: '5px',
                        border: '.5px solid #6c757d',
                        borderRadius: '0.25rem',
                        marginLeft: idx > 0 ? '0.5rem' : '0',
                      }}
                      key={idx}
                    >
                      {canMutate && (
                        <Trash
                          className="mr-2 btn-link hover"
                          role="presentation"
                          onClick={() => handleRemoveSessionPackage(idx)}
                          size={14}
                        />
                      )}
                      <span style={{ fontSize: '14px' }}>
                        {sessionPackage.name}
                      </span>
                    </span>
                  )
                })}
              </Col>
            </Row>
          </Form.Group>
          {!objEmpty(editCoupon) && couponSessionsData && (
            <>
              <Row>
                <Col md={4} className="d-flex align-items-center">
                  <h4>Usage</h4>
                </Col>
              </Row>
              <Row>
                <Col md={3} className="d-flex align-items-center">
                  <p className="mb-1">Applied to</p>
                </Col>
                <Col md={3} className="d-flex align-items-center">
                  <p className="mb-1">Pre Coupon Revenue</p>
                </Col>
                <Col md={3} className="d-flex align-items-center">
                  <p className="mb-1">Coupon Savings</p>
                </Col>
                <Col md={3} className="d-flex align-items-center">
                  <p className="mb-1">Post Coupon Revenue</p>
                </Col>
              </Row>
              <Row>
                <Col md={3} className="d-flex align-items-center">
                  <p className="mb-1">
                    {couponSessionsData.sessions.nodeCount} Sessions
                  </p>
                </Col>
                <Col md={3} className="d-flex align-items-center">
                  <p>${editCoupon.totalSessionRevenue}</p>
                </Col>
                <Col md={3} className="d-flex align-items-center">
                  <p>${editCoupon.totalSessionSavings}</p>
                </Col>
                <Col md={3} className="d-flex align-items-center">
                  <p>${editCoupon.totalSessionRevenueAfterSavings}</p>
                </Col>
              </Row>
            </>
          )}
          {!objEmpty(editCoupon) && couponSessionsData && (
            <>
              <Row className="mt-3 mb-4">
                <Col md={12} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setDisplaySessions(!displaySessions)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displaySessions ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label className="mb-0">Sessions</Form.Label>
                </Col>
              </Row>
              {displaySessions && (
                <Sessions couponId={editCoupon.id} detailComponent />
              )}
            </>
          )}
          {!objEmpty(editCoupon) && canMutate && (
            <>
              <Row className="mt-3 mb-4">
                <Col md={12} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setDisplayAuditLog(!displayAuditLog)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayAuditLog ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label className="mb-0">History</Form.Label>
                </Col>
              </Row>
              {displayAuditLog && (
                <Row>
                  <Col md={12}>
                    <AuditLog
                      contentType={editCoupon.contentType.model}
                      id={editCoupon.id}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}

          {canMutate && (
            <Form.Group>
              <Row className="mt-3">
                <Col md={3}>
                  <Button type="submit" variant="outline-primary" block>
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Col>
                {!objEmpty(editCoupon) && (
                  <Col md={3} block>
                    <Button
                      variant="outline-danger"
                      block
                      onClick={deleteCoupon}
                    >
                      <Trash className="mr-2" />
                      Delete
                    </Button>
                  </Col>
                )}
              </Row>
            </Form.Group>
          )}
        </Modal.Body>
      </Form>
    </Modal>
  )
}

export default PromoCodeModal
