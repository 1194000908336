import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Calendar, Trash } from 'react-bootstrap-icons'
import { DateTime } from 'luxon'

const DateFilter = (props) => {
  const {
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    placeholderStart,
    placeholderEnd,
    oneDate,
  } = props
  let jsStartDateFilter = ''
  if (startDateFilter instanceof Date) {
    jsStartDateFilter = startDateFilter
  } else if (startDateFilter?.c) {
    jsStartDateFilter = new Date(
      startDateFilter.c.year,
      startDateFilter.c.month - 1,
      startDateFilter.c.day
    )
  }
  let jsEndDateFilter = ''
  if (endDateFilter) {
    if (endDateFilter instanceof Date) {
      jsEndDateFilter = endDateFilter
    } else if (endDateFilter?.c) {
      jsEndDateFilter = new Date(
        endDateFilter.c.year,
        endDateFilter.c.month - 1,
        endDateFilter.c.day
      )
    }
  }

  const formik = useFormik({
    initialValues: {
      startDateFilter: jsStartDateFilter,
      endDateFilter: jsEndDateFilter,
    },
    validationSchema: Yup.object().shape({
      startDateFilter: Yup.date().test(
        'isLessThanEndDate',
        'Start date must not exceed end date',
        (value, context) => {
          let valid = true
          if (!oneDate && value && context.parent.endDateFilter) {
            const startDate = new Date(value)
            const endDate = new Date(context.parent.endDateFilter)
            endDate.setHours(23)
            endDate.setMinutes(59)
            endDate.setSeconds(59)
            if (endDate < startDate) {
              valid = false
            }
          }
          return valid
        }
      ),
      endDateFilter: Yup.date(),
    }),
  })

  useEffect(() => {
    let startDate
    if (formik.values.startDateFilter) {
      startDate = DateTime.fromJSDate(formik.values.startDateFilter)
    }
    let endDate
    if (!oneDate) {
      if (formik.values.endDateFilter) {
        endDate = DateTime.fromJSDate(formik.values.endDateFilter).set({
          hour: 23,
          minute: 59,
          second: 59,
        })
      }
    }
    if (
      startDate &&
      (!endDate || startDate < endDate) &&
      startDate !== startDateFilter
    ) {
      setStartDateFilter(startDate)
    }
    if (
      endDate &&
      (!startDate || startDate < endDate) &&
      endDate !== endDateFilter
    ) {
      setEndDateFilter(endDate)
    }
  }, [formik.values])

  const handleRemoveDateFilterClick = (dateType) => {
    if (dateType === 'start') {
      formik.setFieldValue('startDateFilter', '')
      setStartDateFilter(null)
    } else if (dateType === 'end') {
      formik.setFieldValue('endDateFilter', '')
      setEndDateFilter(null)
    }
  }
  return (
    <div className="mt-2">
      <Row>
        <Col
          md="auto"
          className="d-flex align-items-center pr-0"
          style={{ zIndex: 2 }}
        >
          {formik.values.startDateFilter ? (
            <div
              className="btn-link"
              style={{ cursor: 'pointer' }}
              onClick={() => handleRemoveDateFilterClick('start')}
            >
              <Trash className="mr-2" />
            </div>
          ) : null}
          <DatePicker
            placeholderText={placeholderStart}
            name="startDateFilter"
            className="form-control form-control-sm"
            showPopperArrow={false}
            popperPlacement="bottom"
            selected={formik.values.startDateFilter}
            onChange={(date) => formik.setFieldValue('startDateFilter', date)}
            popperModifiers={{
              flip: {
                behavior: ['bottom'],
              },
              preventOverflow: {
                enabled: false,
              },
              hide: {
                enabled: false,
              },
            }}
          />
          <Calendar
            className="position-absolute mr-2 small text-secondary"
            style={{ right: 0 }}
          />
        </Col>
        {!oneDate && (
          <Col
            md="auto"
            className="d-flex align-items-center px-0 ml-3"
            style={{ zIndex: 2 }}
          >
            {formik.values.endDateFilter ? (
              <div
                style={{ cursor: 'pointer' }}
                className="btn-link"
                onClick={() => handleRemoveDateFilterClick('end')}
              >
                <Trash className="mr-2" />
              </div>
            ) : (
              <></>
            )}
            <DatePicker
              name="endDateFilter"
              className="form-control form-control-sm"
              showPopperArrow={false}
              placeholderText={placeholderEnd}
              selected={formik.values.endDateFilter}
              onChange={(date) => formik.setFieldValue('endDateFilter', date)}
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'],
                },
                preventOverflow: {
                  enabled: false,
                },
                hide: {
                  enabled: false,
                },
              }}
            />
            <Calendar
              className="position-absolute mr-2 small text-secondary"
              style={{ right: 0 }}
            />
          </Col>
        )}
      </Row>
      <Row className="d-flex align-items-center p-0">
        <Col>
          {formik.errors.startDateFilter ? (
            <Col xs={12} md={3}>
              <br />
              <span>
                <small className="text-danger">
                  {formik.errors.startDateFilter}
                </small>
              </span>
              <span className="mt-5" />
            </Col>
          ) : null}
        </Col>
      </Row>
    </div>
  )
}

export default DateFilter
