import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { Trash } from 'react-bootstrap-icons'

const SubjectGroupRemoveSubjectModal = (props) => {
  const {
    subjectId,
    subjectGroupId,
    subjectName,
    subjectGroupName,
    showModal,
    toggleModal,
    subjects,
    setSubjects,
  } = props

  const [updateSubjectGroupMutation] = useMutation(
    gql`
      mutation UpdateSubjectGroup(
        $updateSubjectGroupInput: UpdateSubjectGroupInput!
      ) {
        updateSubjectGroup(input: $updateSubjectGroupInput) {
          subjectGroup {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        if (subjects && setSubjects) {
          let currentSubjects = [...subjects]
          let updatedSubjects = currentSubjects.filter((subject) => {
            return subject.id !== subjectId
          })
          setSubjects(updatedSubjects)
        }
        toggleModal()
        toast.success('Subject Removed')
      },
      refetchQueries: ['SubjectsQuery'],
    }
  )

  const handleRemoveSubjectClick = () => {
    updateSubjectGroupMutation({
      variables: {
        updateSubjectGroupInput: {
          subjectGroupInput: {
            id: subjectGroupId,
            removeSubjects: subjectId,
          },
        },
      },
    })
  }

  if (!showModal) return <></>
  return (
    <>
      <div className="subjectGroupRemoveSubjectModal">
        <Modal size="md" show={showModal} onHide={() => toggleModal()}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>Remove Subject</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                Are you sure you want to remove {subjectName} from{' '}
                {subjectGroupName}?
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={{ span: 8, offset: 2 }}>
                <Button
                  type="submit"
                  variant="outline-danger"
                  onClick={handleRemoveSubjectClick}
                  block
                >
                  <Trash className="mr-2" />
                  Remove Subject
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default SubjectGroupRemoveSubjectModal
