import React from 'react'
import { useState, useEffect } from 'react'
import { Row, Col, Tab } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import SubjectForm from './SubjectForm'
import './SubjectSettings.css'
import SubjectPaymentsTable from '../payments/SubjectPaymentsTable'
import {
  Person,
  Cash,
  ReceiptCutoff,
  FileEarmarkText,
  PersonBoundingBox,
} from 'react-bootstrap-icons'
import InvoiceTable from '../payments/InvoiceTable'
import BoxFolder from '../files/box/BoxFolder'
import Tabs from '../common/Tabs'
import { useBoxEnabled } from '../../libs/box'
import FilesTable from '../files/FilesTable'

const SubjectSettings = (props) => {
  const { userId } = props
  const boxEnabled = useBoxEnabled()
  const [subjectTempId, setTempSubjectId] = useState('')
  const [activeTab, setActiveTab] = useState('profile')
  const [count, setCount] = useState(0)
  let managedSubjects = []
  let subject_id

  const {
    loading: gaiaUserQueryLoading,
    error: gaiaUserQueryError,
    data: gaiaUserQueryData,
  } = useQuery(
    gql`
      query GaiaUserQuery($gaiaUserId: ID!) {
        gaiaUser(id: $gaiaUserId) {
          id
          firstName
          lastName
          fullName
          phoneNumber
          email
          secondaryEmail
          secondaryPhoneNumber
          emailConfirmed
          emailNotificationsEnabled
          smsNotificationsEnabled
          subject {
            id
            organization {
              id
            }
            sharedCanCreateFiles
            sharedCanCreateFolders
            sharedCanSeeFiles
            studentId
            notes
            sharedBoxFolderId
            sharedFolder {
              id
            }
            children {
              edges {
                node {
                  id
                  organization {
                    id
                  }
                  city
                  state
                  zipCode
                  addressLineOne
                  addressLineTwo
                  notes
                  sharedBoxFolderId
                  gaiaUser {
                    id
                    firstName
                    lastName
                    fullName
                    phoneNumber
                    email
                    secondaryEmail
                    secondaryPhoneNumber
                    emailConfirmed
                    emailNotificationsEnabled
                    smsNotificationsEnabled
                  }
                  locations {
                    edges {
                      node {
                        city
                        state
                        zipCode
                        addressLineOne
                        addressLineTwo
                      }
                    }
                  }
                }
              }
            }
            city
            state
            zipCode
            addressLineOne
            addressLineTwo
            notes
            locations {
              edges {
                node {
                  city
                  state
                  zipCode
                  addressLineOne
                  addressLineTwo
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        gaiaUserId: userId,
      },
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (managedSubjects && managedSubjects.length > 0 && count == 0) {
      setTempSubjectId(managedSubjects[0].id)
    }
  }, [managedSubjects])

  const handleClick = (e) => {
    setCount(1)
    setTempSubjectId(e)
  }

  const handleQueryData = () => {
    let managedSubjects = []
    if (gaiaUserQueryData && gaiaUserQueryData.gaiaUser.subject) {
      subject_id = gaiaUserQueryData.gaiaUser.subject.id
      managedSubjects.push({
        studentViewing: false,
        id: gaiaUserQueryData.gaiaUser.subject.id,
        gaiaUserId: gaiaUserQueryData.gaiaUser.id,
        name: gaiaUserQueryData.gaiaUser.fullName,
        notes: gaiaUserQueryData.gaiaUser.subject.notes,
        sharedBoxFolderId: gaiaUserQueryData.gaiaUser.subject.sharedBoxFolderId,
      })
    }
    if (
      gaiaUserQueryData &&
      gaiaUserQueryData.gaiaUser.subject.children &&
      gaiaUserQueryData.gaiaUser.subject.children.edges.length
    ) {
      gaiaUserQueryData.gaiaUser.subject.children.edges.forEach((subject) => {
        managedSubjects.push({
          studentViewing: true,
          id: subject.node.id,
          gaiaUserId: subject.node.gaiaUser.id,
          name: subject.node.gaiaUser.fullName,
          notes: subject.node.notes,
          sharedBoxFolderId: subject.node.sharedBoxFolderId,
        })
      })
    }
    return managedSubjects
  }
  if (gaiaUserQueryLoading || !gaiaUserQueryData) return <></>
  managedSubjects = handleQueryData()
  if (gaiaUserQueryError) return <p>Error loading settings</p>
  const { gaiaUser } = gaiaUserQueryData
  const { subject } = gaiaUser
  return (
    <>
      <div className="mt-4">
        {managedSubjects.length > 1 ? (
          <>
            <Tabs
              defaultActiveKey={managedSubjects[0].id}
              className="mb-3"
              onSelect={handleClick}
            >
              {managedSubjects.map((manageSubject) => {
                return (
                  <Tab
                    key={manageSubject.id}
                    eventKey={manageSubject.id}
                    title={
                      <b>
                        <Person className="mr-2" />
                        {manageSubject.name}
                      </b>
                    }
                  >
                    <>
                      {subjectTempId == manageSubject.id && (
                        <>
                          <Tabs
                            defaultActiveKey={activeTab}
                            className="mb-3"
                            onSelect={setActiveTab}
                          >
                            <Tab
                              key={'profile'}
                              eventKey={'profile'}
                              title={
                                <b>
                                  <PersonBoundingBox className="mr-1" />
                                  Profile
                                </b>
                              }
                            >
                              <SubjectForm
                                displayBilling
                                subjectId={manageSubject.id}
                                studentViewing={manageSubject.studentViewing}
                                subjectViewing
                              />
                              {subject.notes && (
                                <Row className="mt-5">
                                  <Col>
                                    <h5 className="d-block">Studio Notes</h5>
                                    {manageSubject.notes}
                                  </Col>
                                </Row>
                              )}
                            </Tab>
                            <Tab
                              key={'payments'}
                              eventKey={'payments'}
                              title={
                                <b>
                                  <Cash className="mr-1" />
                                  Payments
                                </b>
                              }
                            >
                              <SubjectPaymentsTable
                                subjectId={manageSubject.id}
                              />
                            </Tab>
                            <Tab
                              key={'invoices'}
                              eventKey={'invoices'}
                              title={
                                <b>
                                  <ReceiptCutoff className="mr-1" />
                                  Invoices
                                </b>
                              }
                            >
                              <InvoiceTable
                                gaiaUserId={manageSubject.gaiaUserId}
                              />
                            </Tab>
                            {subject.sharedCanSeeFiles && (
                              <Tab
                                key={'files'}
                                eventKey={'files'}
                                title={
                                  <b>
                                    <FileEarmarkText className="mr-1" />
                                    Files
                                  </b>
                                }
                              >
                                {boxEnabled && (
                                  <BoxFolder
                                    rootBoxFolderId={
                                      manageSubject.sharedBoxFolderId
                                    }
                                  />
                                )}
                                {!boxEnabled && (
                                  <FilesTable
                                    folderId={manageSubject?.sharedFolder?.id}
                                    subjectId={manageSubject?.id}
                                    sharedCanCreateFiles={
                                      manageSubject?.sharecCanCreateFiles
                                    }
                                    sharecCanCreateFolders={
                                      manageSubject?.sharecCanCreateFolders
                                    }
                                    organizationSubject={
                                      manageSubject.organization
                                    }
                                  />
                                )}
                              </Tab>
                            )}
                          </Tabs>
                        </>
                      )}
                    </>
                  </Tab>
                )
              })}
            </Tabs>
          </>
        ) : (
          <>
            <div className="mt-3" />
            <Tabs
              defaultActiveKey={activeTab}
              className="mb-3"
              onSelect={setActiveTab}
            >
              <Tab
                key={'profile'}
                eventKey={'profile'}
                title={
                  <b>
                    <PersonBoundingBox className="mr-1" />
                    Profile
                  </b>
                }
              >
                <SubjectForm
                  subjectId={subject_id}
                  studentViewing={subject.studentId !== null}
                  subjectViewing
                  displayBilling
                />
                {subject.notes && (
                  <Row className="mt-5">
                    <Col>
                      <h5 className="d-block">Studio Notes</h5>
                      {subject.notes}
                    </Col>
                  </Row>
                )}
              </Tab>
              <Tab
                key={'payments'}
                eventKey={'payments'}
                title={
                  <b>
                    <Cash className="mr-1" />
                    Payments
                  </b>
                }
              >
                <SubjectPaymentsTable subjectId={subject_id} />
              </Tab>
              <Tab
                key={'invoices'}
                eventKey={'invoices'}
                title={
                  <b>
                    <ReceiptCutoff className="mr-1" />
                    Invoices
                  </b>
                }
              >
                <InvoiceTable gaiaUserId={gaiaUser.id} />
              </Tab>
              {subject.sharedCanSeeFiles && (
                <Tab
                  key={'files'}
                  eventKey={'files'}
                  title={
                    <b>
                      <FileEarmarkText className="mr-1" />
                      Files
                    </b>
                  }
                >
                  {boxEnabled && (
                    <BoxFolder rootBoxFolderId={subject.sharedBoxFolderId} />
                  )}
                  {!boxEnabled && (
                    <FilesTable
                      folderId={subject?.sharedFolder.id}
                      subjectId={subject?.id}
                      sharedCanCreateFiles={subject.sharedCanCreateFiles}
                      sharedCanCreateFolders={subject.sharedCanCreateFolders}
                      organizationSubject={subject.organization}
                    />
                  )}
                </Tab>
              )}
            </Tabs>
          </>
        )}
      </div>
    </>
  )
}

export default SubjectSettings
