import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import SortableTable from '../../common/SortableTable'
import { DateTime } from 'luxon'
import { ExclamationTriangle } from 'react-bootstrap-icons'

const EmployeeSchedulingConflictsModal = (props) => {
  let { showModal, toggleModal, schedulingConflicts } = props
  if (!showModal) return <></>
  const hasEmployeeWorkingEvent = schedulingConflicts.some(
    (item) => item.eventType === 'EMPLOYEE_WORKING'
  )
  schedulingConflicts = schedulingConflicts.filter(
    (item) => item.eventType !== 'EMPLOYEE_WORKING'
  )
  return (
    <>
      <div id="employeeSchedulingConflictModal">
        <Modal className="mt-4" size="lg" show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Employee Scheduling Conflicts</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!hasEmployeeWorkingEvent && (
              <Row className="mb-3">
                <Col>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'red',
                    }}
                  >
                    <ExclamationTriangle className="ml-1 mr-2" />
                    <small>No Working Work Events During Job Time</small>
                  </span>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <SortableTable
                  columns={[
                    {
                      Header: 'Conflict',
                      accessor: (row) => {
                        let conflict
                        if (row.__typename === 'EmployeeScheduleNode') {
                          conflict = 'Work Event'
                        } else {
                          conflict = 'Job'
                        }
                        return conflict
                      },
                    },
                    {
                      Header: 'Job Time',
                      accessor: (row) => {
                        if (row.__typename === 'EmployeeJobNode') {
                          const startDateTime = DateTime.fromISO(
                            row.job.startDateTime
                          )
                          const endDateTime = DateTime.fromISO(
                            row.job.endDateTime
                          )
                          return `${startDateTime.toFormat(
                            'MMMM dd, yyyy'
                          )} ${startDateTime.toFormat(
                            'hh:mma'
                          )} - ${endDateTime.toFormat('hh:mma')}`
                        }
                      },
                    },
                    {
                      Header: 'Shift',
                      accessor: (row) => {
                        if (row.__typename === 'EmployeeJobNode') {
                          let startDateTime
                          if (row.startDateTime) {
                            startDateTime = DateTime.fromISO(
                              row.startDateTime
                            ).toFormat('hh:mma')
                          }
                          let endDateTime
                          if (row.endDateTime) {
                            endDateTime = DateTime.fromISO(
                              row.endDateTime
                            ).toFormat('hh:mma')
                          }
                          if (startDateTime || endDateTime) {
                            let time
                            if (startDateTime) {
                              time = startDateTime
                            } else {
                              time = 'no start time'
                            }
                            if (endDateTime) {
                              time = `${time} - ${endDateTime}`
                            } else {
                              time = `${time} - no end time`
                            }
                            return time
                          } else {
                            return 'no start or end time'
                          }
                        }
                      },
                    },
                    {
                      Header: 'Work Event Time',
                      accessor: (row) => {
                        if (row.__typename === 'EmployeeScheduleNode') {
                          const startDateTime = DateTime.fromISO(
                            row.startDateTime
                          )
                          let startTime
                          if (row.startDateTime) {
                            startTime = DateTime.fromISO(
                              row.startDateTime
                            ).toFormat('hh:mma')
                          }
                          let endTime
                          if (row.endDateTime) {
                            endTime = DateTime.fromISO(
                              row.endDateTime
                            ).toFormat('hh:mma')
                          }
                          return `${startDateTime.toFormat(
                            'MMMM dd, yyyy'
                          )} ${startTime} - ${endTime}`
                        }
                      },
                    },
                    {
                      Header: 'Work Event Type',
                      accessor: (row) => {
                        if (row.__typename === 'EmployeeScheduleNode') {
                          let eventType
                          if (row.eventType === 'EMPLOYEE_PTO') {
                            eventType = 'PTO'
                          } else if (row.eventType === 'EMPLOYEE_SICK') {
                            eventType = 'Sick'
                          }
                          return eventType
                        }
                      },
                    },
                    {
                      Header: 'Work Event Status',
                      accessor: (row) => {
                        if (row.__typename === 'EmployeeScheduleNode') {
                          let status
                          if (row.approvalStatus === 'PENDING') {
                            status = 'Pending'
                          } else if (row.approvalStatus === 'APPROVED') {
                            status = 'Approved'
                          }
                          let color
                          if (status === 'Pending') {
                            color = 'orange'
                          } else if (status === 'Approved') {
                            color = 'red'
                          }
                          return <span style={{ color }}>{status}</span>
                        }
                      },
                    },
                    {
                      Header: 'Location',
                      accessor: (row) => {
                        let location
                        if (row.__typename === 'EmployeeScheduleNode') {
                          if (row.location?.name) {
                            location = row.location?.name
                          } else if (row.location?.addressLineOne) {
                            location = row.location?.addressLineOne
                          }
                        } else {
                          if (row.job.location.name) {
                            location = row.job.location.name
                          } else if (row.job.location.addressLineOne) {
                            location = row.job.location.addressLineOne
                          }
                        }
                        return location
                      },
                    },
                  ]}
                  hideGlobalFilter={true}
                  data={schedulingConflicts}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default EmployeeSchedulingConflictsModal
