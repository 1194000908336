import React from 'react'
import { Switch } from 'react-router-dom'
import OpenRoute from './OpenRoute'
import EmployeeRoute from './EmployeeRoute'
import SubjectRoute from './SubjectRoute'
import NotFound from '../components/NotFound'
import NewPassword from '../components/user/NewPassword'
import JobPage from '../components/schedule/job/Job'
import PublicUserNotificationSettings from '../components/notifications/PublicUserNotificationSettings'
import AllSubjectSessions from '../components/subject_sessions/AllSubjectSessions'
import AllSubjectSettings from '../components/subject/AllSubjectSettings'
import StudioSettings from '../components/studio_settings/StudioSettings'
import Notifications from '../components/notifications/Notifications'
import Employees from '../components/employee/Employees'
import EmployeeDetail from '../components/employee/EmployeeDetail'
import Schedule from '../components/schedule/Schedule'
import SubjectDetail from '../components/subject/SubjectDetail'
import Locations from '../components/location/Locations'
import SubjectGroupDetail from '../components/subject_group/SubjectGroupDetail'
import OrganizationDetail from '../components/organization/OrganizationDetail'
import Organizations from '../components/organization/Organizations'
import SubjectGroups from '../components/subject_group/SubjectGroups'
import SubjectGroup from '../components/subject_group/SubjectGroup'
import Subjects from '../components/subject/Subjects'
import SessionPackages from '../components/session_package/SessionPackages'
import Equipment from '../components/equipment/Equipment'
import Billing from '../components/payments/Billing'
import ConfirmEmail from '../components/user/ConfirmEmail'
import ConfirmEmployee from '../components/employee/ConfirmEmployee'
import OrganizationContactRoute from './OrganizationContactRoute'
import SessionList from '../components/sessions/Sessions'
import Homepage from '../components/book_view_home/Homepage'
import SchoolSubjectGroupSession from '../components/book_view_home/SchoolSubjectGroupSession'
import OtherSubjectGroupSession from '../components/book_view_home/OtherSubjectGroupSession'
import BookFlow from '../components/book_view_home/BookFlow'
import BookNewSession from '../components/book_view_home/BookNewSession'
import { loggedInUserVar } from '../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import { isEmployee } from '../libs/utils'
import BookSessionHomepage from '../components/book_view_home/BookSessionHomepage'
import Files from '../components/files/Files'
import SignUp from '../components/SignUp'
import InactiveTenant from '../components/InactiveTenant'
import AirstudioRoute from './AirstudioRoute'
import AnonymousLogin from '../components/book_view_home/AnonymousLogin'
import FormBuilder from '../components/Form'
import Forms from '../components/form/Forms'
import Form from '../components/form/Form'
import SubmitForm from '../components/form/SubmitForm'
import TaskPage from '../components/task/TaskPage'

const Routes = () => {
  const user = useReactiveVar(loggedInUserVar)
  const isEmployeeUser = isEmployee(user?.groups.edges[0].node.name)
  if (window.location.origin === 'https://signup.airstudio.io') {
    return (
      <Switch>
        <OpenRoute exact path="/" component={SignUp} />
        <OpenRoute path="*" component={NotFound} />
      </Switch>
    )
  }
  return (
    <Switch>
      <OpenRoute exact path="/" component={Homepage} />
      <OpenRoute
        exact
        path="/confirm-email/:confirmationToken/:resetPasswordToken"
        component={ConfirmEmail}
      />
      <AirstudioRoute
        exact
        path="/sign-up"
        component={
          window.location.origin == 'http://srp.localhost:3000'
            ? SignUp
            : NotFound
        }
      />
      <AirstudioRoute exact path="/inactive" component={InactiveTenant} />
      <OpenRoute exact path="/reset-password/:token" component={NewPassword} />
      <OpenRoute
        exact
        path="/notification/:userId"
        component={PublicUserNotificationSettings}
      />
      <EmployeeRoute exact path="/job/:jobId?" component={JobPage} />
      <SubjectRoute
        exact
        path="/subject/sessions"
        component={AllSubjectSessions}
      />
      <SubjectRoute
        exact
        path="/subject/settings"
        component={AllSubjectSettings}
      />
      <EmployeeRoute
        exact
        path="/session-packages"
        component={SessionPackages}
      />
      <OpenRoute exact path="/form/submit/:formId" component={SubmitForm} />
      <EmployeeRoute exact path="/form/:formId" component={Form} />
      <EmployeeRoute exact path="/form" component={Form} />
      <EmployeeRoute exact path="/form" component={FormBuilder} />
      <EmployeeRoute exact path="/forms" component={Forms} />
      <EmployeeRoute exact path="/files" component={Files} />
      <EmployeeRoute exact path="/settings" component={StudioSettings} />
      <EmployeeRoute exact path="/notifications" component={Notifications} />
      <EmployeeRoute exact path="/employees" component={Employees} />
      <EmployeeRoute exact path="/schedule" component={Schedule} />
      <EmployeeRoute exact path="/equipment" component={Equipment} />
      <EmployeeRoute
        exact
        path="/subject-group/:subjectGroupId/edit"
        component={SubjectGroup}
      />
      <EmployeeRoute
        exact
        path="/employee/:employeeId"
        component={EmployeeDetail}
      />
      <EmployeeRoute exact path="/subject-groups" component={SubjectGroups} />
      <EmployeeRoute
        exact
        path="/subject-group/:subjectGroupId"
        component={SubjectGroupDetail}
      />
      <EmployeeRoute exact path="/subjects" component={Subjects} />
      <EmployeeRoute
        exact
        path="/subject/:subjectId"
        component={SubjectDetail}
      />
      <OrganizationContactRoute
        exact
        path="/subject/:subjectId/:contact?"
        component={SubjectDetail}
      />
      <EmployeeRoute
        exact
        path="/organization/:organizationId"
        component={OrganizationDetail}
      />
      <EmployeeRoute exact path="/organizations" component={Organizations} />
      <EmployeeRoute exact path="/billing" component={Billing} />
      <EmployeeRoute exact path="/sessions" component={SessionList} />
      <EmployeeRoute exact path="/tasks" component={TaskPage} />
      <EmployeeRoute exact path="/locations" component={Locations} />
      <EmployeeRoute
        exact
        path="/book-new-session"
        component={BookNewSession}
      />
      <SubjectRoute exact path="/session-type" component={BookNewSession} />
      <OrganizationContactRoute
        exact
        path="/session-type/contact"
        component={BookNewSession}
      />
      <OrganizationContactRoute
        exact
        path="/organizations/:contact?"
        component={Organizations}
      />
      <OrganizationContactRoute
        exact
        path="/organization/:organizationId/:contact?"
        component={OrganizationDetail}
      />
      <OrganizationContactRoute
        exact
        path="/subject-group/:subjectGroupId/:contact?"
        component={SubjectGroupDetail}
      />
      <OpenRoute
        exact
        path="/confirm-email/:confirmationToken/:resetPasswordToken"
        component={ConfirmEmail}
      />
      <OpenRoute
        exact
        path="/confim-employee/:confirmationToken/"
        component={ConfirmEmployee}
      />
      <OrganizationContactRoute
        exact
        path="/organizations/:contact?"
        component={Organizations}
      />
      <OrganizationContactRoute
        exact
        path="/organization/:organizationId/:contact?"
        component={OrganizationDetail}
      />
      <OrganizationContactRoute
        exact
        path="/subject-group/:subjectGroupId/:contact?"
        component={SubjectGroupDetail}
      />
      <OpenRoute
        exact
        path="/confirm-email/:confirmationToken/:resetPasswordToken"
        component={ConfirmEmail}
      />
      <OpenRoute exact path="/anon/:loginToken" component={AnonymousLogin} />
      <OpenRoute
        exact
        path="/anon/:loginToken/:booking"
        component={AnonymousLogin}
      />
      <OpenRoute exact path="/reset-password/:token" component={NewPassword} />
      <OpenRoute exact path="/book-session" component={BookSessionHomepage} />
      {isEmployeeUser ? (
        <EmployeeRoute
          exact
          path="/school-session"
          component={SchoolSubjectGroupSession}
        />
      ) : (
        <OpenRoute
          exact
          path="/school-session"
          component={SchoolSubjectGroupSession}
        />
      )}
      <OpenRoute
        exact
        path="/school-session/:book"
        component={SchoolSubjectGroupSession}
      />
      <OpenRoute
        exact
        path="/session/:book"
        component={OtherSubjectGroupSession}
      />
      {isEmployeeUser ? (
        <EmployeeRoute exact path="/book" component={BookFlow} />
      ) : (
        <OpenRoute exact path="/book" component={BookFlow} />
      )}
      <OpenRoute path="/not-found" component={NotFound} />
      <OpenRoute path="*" component={NotFound} />
    </Switch>
  )
}

export default Routes
