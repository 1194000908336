import React from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowCounterclockwise } from 'react-bootstrap-icons'
import { useReactiveVar } from '@apollo/client'
import { Row, Col, Card } from 'react-bootstrap'
import EmailLogin from './EmailLogin'
import { loggedInUserVar } from '../../libs/apollo'
import { isEmployee } from '../../libs/utils'
import { useParams } from 'react-router-dom'

export default function OtherSession() {
  const { book } = useParams()
  const history = useHistory()
  const user = useReactiveVar(loggedInUserVar)
  const isEmployeeUser = isEmployee(user?.groups.edges[0].node.name)

  const handleLogin = (userNode, subjectNode) => {
    if (subjectNode?.subjectGroupsNotBooked.length === 0) {
      history.push('/subject/sessions')
    } else {
      history.push({
        pathname: '/book',
        state: {
          initialStage: 'review',
          bookingType: 'schoolFlow',
          incommingSubject: {
            subjectGroupsNotBooked: subjectNode.subjectGroupsNotBooked,
            studentId: subjectNode.studentId,
            id: subjectNode.id,
            fullName: userNode.fullName,
            org: {
              name: subjectNode.organization
                ? subjectNode.organization.name
                : '',
              id: subjectNode.organization ? subjectNode.organization.id : '',
            },
          },
        },
      })
    }
  }

  const handleResetClick = () => {
    if (isEmployeeUser) {
      history.push('/book-new-session')
    } else {
      history.push('subject/sessions')
    }
  }

  return (
    <>
      <Card
        className="p-4 shadow-lg border-0 mt-5"
        style={{ maxWidth: '600px', margin: '0 auto' }}
      >
        <Card.Body>
          <Row className="mb-3">
            <Col md={{ span: 8, offset: 2 }} className="text-center">
              <h1>
                Book Session
                {!book && (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={handleResetClick}
                  >
                    <ArrowCounterclockwise size={18} className="ml-3" />
                    <small style={{ position: 'relative', bottom: '4px' }}>
                      Reset
                    </small>
                  </span>
                )}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <EmailLogin handlePostLogin={handleLogin} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}
