import React from 'react'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import { ArrowLeft, PlusCircle } from 'react-bootstrap-icons'

const ConfirmCreateSubjectModal = (props) => {
  const { showModal, toggleModal, handleSubmit, subjectGroupName } = props

  return (
    <>
      <div className="cancelSessionModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="new-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Subject</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p className="ml-2">
                  Are you sure want to save the subject without associating them
                  to a subject group like {subjectGroupName}?
                </p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={{ span: 10, offset: 1 }}>
                <Button
                  variant="outline-primary"
                  size="small"
                  onClick={toggleModal}
                  block
                >
                  <ArrowLeft className="mr-2" />
                  Add Subject Group
                </Button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={{ span: 10, offset: 1 }}>
                <Button block onClick={handleSubmit} variant="outline-primary">
                  <PlusCircle className="mr-2" />
                  Save Subject
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default ConfirmCreateSubjectModal
