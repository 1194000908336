import React from 'react'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import validator from 'validator'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { Lock } from 'react-bootstrap-icons'
import { useMutation, gql } from '@apollo/client'

const ResetPasswordModal = (props) => {
  const { accountEmail } = useParams()
  const { showModal, toggleModal } = props
  const [mutation] = useMutation(
    gql`
      mutation ResetPassword(
        $resetGaiaUserPasswordInput: ResetGaiaUserPasswordInput!
      ) {
        resetGaiaUserPassword(input: $resetGaiaUserPasswordInput) {
          resetEmailSent
        }
      }
    `,
    {
      onCompleted: () => {
        toast.success('Password Reset Email Sent If Account Exists')
        toggleModal()
      },
      onError: () => {
        toast.success('Password Reset Email Sent If Account Exists')
      },
    }
  )
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: accountEmail ? accountEmail : '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Required')
        .test('isEmail', 'Invalid Email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      mutation({
        variables: {
          resetGaiaUserPasswordInput: {
            email: values.email,
          },
        },
      })
    },
  })
  const innerToggle = () => {
    formik.resetForm()
    toggleModal()
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="sendConfirmationEmailModal">
        <Modal
          size="md"
          show={showModal}
          onHide={innerToggle}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Col md={{ span: 8, offset: 2 }}>
                  <Form.Label className="ml-1">Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Row>
                <Col sm={{ span: 8, offset: 2 }}>
                  <Button type="submit" variant="outline-primary" block>
                    <Lock className="mr-2" />
                    Send Reset Email
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default ResetPasswordModal
