// FormBuilder.js
import React, { useState, useEffect } from 'react'
import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react'
import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'

const FormBuilder = ({ onSave }) => {
  const [creator, setCreator] = useState(null)

  useEffect(() => {
    const options = {
      showLogicTab: true,
    }
    const creatorInstance = new SurveyCreator(options)
    creatorInstance.saveSurveyFunc = (saveNo, callback) => {
      const formJson = creatorInstance.JSON
      // if (onSave) {
      //   onSave(formJson);
      // }
      callback(saveNo, true)
    }

    setCreator(creatorInstance)
  }, [onSave])

  if (!creator) {
    return <div>Loading form builder...</div>
  }

  return <SurveyCreatorComponent creator={creator} />
}

export default FormBuilder
