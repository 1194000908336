import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { CameraFill, PlusCircle, Trash } from 'react-bootstrap-icons'
import ItemTypeModal from './ItemTypeModal'
import DeleteItemTypeModal from './DeleteItemTypeModal'
import Loading from '../../common/Loading'

const ItemTypes = () => {
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [checkedIds, setCheckedIds] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editItemType, setEditItemType] = useState()
  const defaultOrderBy = 'name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedIds((prevState) => prevState.filter((id) => id !== row.node.id))
    }
  }

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query EquipmentItemTypes(
        $cursor: String
        $searchTerm: String
        $orderBy: String
      ) {
        equipmentItemTypes(
          first: 100
          after: $cursor
          name_Icontains: $searchTerm
          orderBy: $orderBy
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              contentType {
                model
                id
              }
              miscellaneous
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      // pollInterval: 30000,
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({
        variables: {
          orderBy: defaultOrderBy,
        },
      })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.equipmentItemTypes) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.equipmentItemTypes?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    queryFetchMore({
      variables,
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setLoadingSearch(true)
    const variables = {
      searchTerm: currentSearchTerm,
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    query({
      variables,
    })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    query({ variables })
  }

  const onCellClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setEditItemType(node)
    } else {
      setEditItemType()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      <div>
        <>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="searchTerm"
                  className="form-control-sm"
                  placeholder={'Search Item Types'}
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
              </Form.Group>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <div>
                <Button
                  variant="link"
                  onClick={() => {
                    toggleModal()
                  }}
                >
                  <PlusCircle className="mr-2" />
                  New Item Type
                </Button>
                {checkedIds.length > 0 && (
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowDeleteModal(true)
                    }}
                  >
                    <Trash className="mr-2" />
                    {checkedIds.length === 1 ? (
                      <>Delete Item Type</>
                    ) : (
                      <>Delete Item Types</>
                    )}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row className="mt-2 mb-3">
            <Col md="6">
              <SortableInfiniteTable
                loading={loadingSearch || !queryData}
                tableData={
                  queryData?.equipmentItemTypes?.edges
                    ? queryData.equipmentItemTypes.edges
                    : []
                }
                loadingMessage="Loading Item Types..."
                tableColumns={[
                  {
                    Header: 'Name',
                    id: 'name',
                    accessor: 'node.name',
                    serverSort: true,
                  },
                  {
                    Header: 'Miscellaneous',
                    id: 'misc',
                    serverSort: true,
                    orderBy: 'miscellaneous',
                    accessor: (row) => (row.node.miscellaneous ? 'Yes' : 'No'),
                  },
                  {
                    disableSortBy: true,
                    Header: (
                      <>
                        <Form.Group
                          as={ButtonGroup}
                          className="align-items-center"
                        >
                          <Form.Check
                            className="ml-2 mt-2"
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                const appendIds = []
                                queryData.equipmentItemTypes.edges.forEach(
                                  (equipmentItemType) => {
                                    if (
                                      !checkedIds.includes(
                                        equipmentItemType.node.id
                                      )
                                    ) {
                                      appendIds.push(equipmentItemType.node.id)
                                    }
                                  }
                                )
                                setCheckedIds((prevState) => {
                                  return [...prevState, ...appendIds]
                                })
                              } else {
                                setCheckedIds([])
                              }
                            }}
                          />
                          {checkedIds.length > 0 && (
                            <span
                              style={{ fontSize: '14px', marginTop: '5px' }}
                            >
                              ({checkedIds.length})
                            </span>
                          )}
                        </Form.Group>
                      </>
                    ),
                    id: 'actions',
                    accessor: (row) => {
                      return (
                        <>
                          <Form.Group
                            as={ButtonGroup}
                            className="align-items-center"
                          >
                            <Form.Check
                              className="ml-2 mt-2"
                              type="checkbox"
                              checked={checkedIds.includes(row.node.id)}
                              onChange={(e) => handleCheck(e, row)}
                            />
                          </Form.Group>
                        </>
                      )
                    },
                  },
                ]}
                fetchMoreTableData={fetchMore}
                hasMoreTableData={
                  queryData?.equipmentItemTypes?.pageInfo?.hasNextPage
                }
                onTdClicks={{
                  name: (cell) => onCellClick(cell),
                  misc: (cell) => onCellClick(cell),
                }}
                tableHeight={700}
                rowPointer
                hideGlobalFilter
                handleSortByChange={handleSortByChange}
              />
            </Col>
          </Row>
        </>
        {showForm ? (
          <ItemTypeModal
            showModal={showForm}
            itemType={editItemType}
            toggleModal={toggleModal}
          />
        ) : null}
        <DeleteItemTypeModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedIds={setCheckedIds}
          ids={checkedIds.length > 1 ? checkedIds : null}
          id={checkedIds.length === 1 ? checkedIds[0] : null}
        />
      </div>
    </>
  )
}
export default ItemTypes
