import { gql, useQuery } from '@apollo/client'

export const useFtpEnabled = () => {
  const { data } = useQuery(
    gql`
      query FTPConnector {
        ftpConnectors {
          edges {
            node {
              valid
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  if (!data) return
  return data.ftpConnectors.edges[0].node.valid
}
