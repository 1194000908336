import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Image, Carousel } from 'react-bootstrap'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import {
  CurrencyDollar,
  Clock,
  Camera,
  PlusCircle,
} from 'react-bootstrap-icons'
import { useAWSS3 } from '../../libs/aws'
import Loading from '../common/Loading'
import { ArrowLeftShort, Calendar2Plus } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'
import { loggedInUserVar } from '../../libs/apollo'
import UpsellDescriptionModal from './UpsellDescriptionModal'

const SelectSessionPackage = (props) => {
  const {
    subjectGroupId,
    setSessionPackage,
    setBookingStage,
    resitReason,
    setResitReason,
    currentSessionPackageId,
    setIsUpsellSelected,
    bookSessionFlow,
    packageCategoryId,
    subjectId,
    changeSessionPackageSessionId,
    resitSessionId,
    resitsIncludeFreeSessions,
    freePackageResitFee,
    rescheduleSessionId,
    reschedulingNoShow,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const history = useHistory()
  const canCreateJob = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)

  const awsS3 = useAWSS3()
  const [sessionPackages, setSessionPackages] = useState(null)
  const [sessionPackageMatch, setSessionPackageMatch] = useState(false)
  const [selectedSessionPackage, setSelectSessionPackage] = useState()
  const [prevSelectedSessionPackage, setPrevSelectSessionPackage] = useState()
  const [upsellModalOpen, setUpsellOpenModal] = useState(false)
  const [isUpselling, setIsUpselling] = useState(false)
  const [
    subjectGroupSessionPackagesQuery,
    { error: subjectGroupsQueryError, data: subjectGroupsQueryData },
  ] = useLazyQuery(
    gql`
      query SubjectGroupSessionPackages($subjectGroupId: ID!) {
        subjectGroup(id: $subjectGroupId) {
          subjectsPaying
          sessionPackages(orderBy: "-price") {
            edges {
              node {
                id
                title
                price
                description
                upsell
                upsellDescription
                durationHighMinutes
                durationLowMinutes
                previewImages {
                  edges {
                    node {
                      id
                      defaultImageName
                      coverImage
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  const [
    packageCategorySessionPackages,
    {
      error: packageCategorySessionError,
      data: packageCategorySessionPackagesData,
    },
  ] = useLazyQuery(
    gql`
      query PackageCategorySessionPackaes($packageCategoryId: ID!) {
        packageCategory(id: $packageCategoryId) {
          sessionPackages(orderBy: "-price", customPriceAndDuration: false) {
            edges {
              node {
                id
                title
                price
                description
                upsell
                upsellDescription
                durationLowMinutes
                durationHighMinutes
                previewImages {
                  edges {
                    node {
                      id
                      defaultImageName
                      coverImage
                    }
                  }
                }
              }
            }
          }
          id
          name
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (subjectGroupId) {
      subjectGroupSessionPackagesQuery({
        variables: {
          subjectGroupId,
        },
      })
    }
  }, [subjectGroupId])

  useEffect(() => {
    if (packageCategoryId) {
      packageCategorySessionPackages({
        variables: {
          packageCategoryId,
        },
      })
    }
  }, [packageCategoryId])

  const checkHandleUpsell = (sp) => {
    if (selectedSessionPackage) {
      setPrevSelectSessionPackage(selectedSessionPackage)
    } else {
      setPrevSelectSessionPackage(sp)
    }
    setSelectSessionPackage(sp)
    if (sp.upsell) {
      setUpsellOpenModal(true)
    } else {
      handleSessionPackageClick(sp)
    }
  }

  const handleSessionPackageClick = (sp) => {
    if (
      isUpselling &&
      prevSelectedSessionPackage?.upsell &&
      parseFloat(sp.price) > parseFloat(prevSelectedSessionPackage.price)
    ) {
      setIsUpsellSelected(true)
    } else {
      setIsUpsellSelected(false)
    }
    setSessionPackage(sp)
    if (bookSessionFlow) {
      setBookingStage('dateTime')
    } else {
      setBookingStage('session')
    }
  }

  const handleChangeResitReasonClick = () => {
    setResitReason()
    setBookingStage('resitReason')
  }

  useEffect(() => {
    if (subjectGroupsQueryData?.subjectGroup && awsS3?.client) {
      if (
        subjectGroupsQueryData.subjectGroup.sessionPackages.edges.length === 0
      ) {
        setSessionPackages([])
      } else {
        let _packageAvailable = false
        subjectGroupsQueryData.subjectGroup.sessionPackages.edges.forEach(
          (sessionPackage) => {
            const sessionPackageNode = sessionPackage.node
            if (
              !currentSessionPackageId ||
              currentSessionPackageId !== sessionPackageNode.id
            ) {
              if (!_packageAvailable) {
                _packageAvailable = true
              }
              let coverImageAWSS3Key
              const previewImageAWSS3Keys = []
              sessionPackageNode.previewImages.edges.forEach((previewImage) => {
                const previewImageNode = previewImage.node
                if (!previewImageNode.deleted) {
                  if (previewImageNode.coverImage) {
                    coverImageAWSS3Key = previewImageNode.defaultImageName
                  } else {
                    previewImageAWSS3Keys.push(
                      previewImageNode.defaultImageName
                    )
                  }
                }
              })
              previewImageAWSS3Keys.unshift(coverImageAWSS3Key)
              let showCarouselThumbs = true
              if (previewImageAWSS3Keys.length < 2) {
                showCarouselThumbs = false
              }
              const sessionPackageData = {
                id: sessionPackageNode.id,
                title: sessionPackageNode.title,
                price: `${sessionPackageNode.price}`,
                description: sessionPackageNode.description,
                upsell: sessionPackageNode.upsell,
                upsellDescription: sessionPackageNode.upsellDescription,
                durationHighMinutes: sessionPackageNode.durationHighMinutes,
                duration: `${sessionPackageNode.durationLowMinutes} - ${sessionPackageNode.durationHighMinutes} minutes`,
                previewImages: [],
                subjectGroupPackage: true,
                showCarouselThumbs,
              }
              if (
                !resitReason ||
                (resitReason &&
                  (sessionPackageNode.price > 0 || resitsIncludeFreeSessions))
              ) {
                if (!sessionPackageMatch) {
                  setSessionPackageMatch(true)
                }
                if (
                  sessionPackageNode.price == 0 &&
                  resitReason &&
                  freePackageResitFee
                ) {
                  sessionPackageData.price = `${freePackageResitFee}`
                }
                previewImageAWSS3Keys.forEach((previewImageAWSS3Key) => {
                  getS3Object(previewImageAWSS3Key, sessionPackageData)
                })
              }
            }
          }
        )
        if (!sessionPackageMatch && sessionPackages === null) {
          setSessionPackages([])
        }
      }
    }
  }, [subjectGroupsQueryData, awsS3?.client])

  useEffect(() => {
    if (packageCategorySessionPackagesData?.packageCategory && awsS3?.client) {
      if (
        packageCategorySessionPackagesData.packageCategory.sessionPackages.edges
          .length === 0
      ) {
        setSessionPackages([])
      } else {
        let _packageAvailable = false
        packageCategorySessionPackagesData.packageCategory.sessionPackages.edges.forEach(
          (sessionPackage) => {
            const sessionPackageNode = sessionPackage.node
            if (
              !currentSessionPackageId ||
              currentSessionPackageId !== sessionPackageNode.id
            ) {
              let coverImageAWSS3Key
              const previewImageAWSS3Keys = []
              if (!_packageAvailable) {
                _packageAvailable = true
              }
              sessionPackageNode.previewImages.edges.forEach((previewImage) => {
                const previewImageNode = previewImage.node
                if (!previewImageNode.deleted) {
                  if (previewImageNode.coverImage) {
                    coverImageAWSS3Key = previewImageNode.defaultImageName
                  } else {
                    previewImageAWSS3Keys.push(
                      previewImageNode.defaultImageName
                    )
                  }
                }
              })
              previewImageAWSS3Keys.unshift(coverImageAWSS3Key)
              let showCarouselThumbs = true
              if (previewImageAWSS3Keys.length < 2) {
                showCarouselThumbs = false
              }
              const sessionPackageData = {
                id: sessionPackageNode.id,
                title: sessionPackageNode.title,
                price: `${sessionPackageNode.price}`,
                description: sessionPackageNode.description,
                upsell: sessionPackageNode.upsell,
                upsellDescription: sessionPackageNode.upsellDescription,
                durationHighMinutes: sessionPackageNode.durationHighMinutes,
                duration: `${sessionPackageNode.durationLowMinutes} - ${sessionPackageNode.durationHighMinutes} minutes`,
                previewImages: [],
                showCarouselThumbs,
              }
              if (
                !resitReason ||
                (resitReason &&
                  (sessionPackageNode.price > 0 || resitsIncludeFreeSessions))
              ) {
                if (!sessionPackageMatch) {
                  setSessionPackageMatch(true)
                }
                if (
                  sessionPackageNode.price == 0 &&
                  resitReason &&
                  freePackageResitFee
                ) {
                  sessionPackageData.price = `${freePackageResitFee}`
                }
                previewImageAWSS3Keys.forEach((previewImageAWSS3Key) => {
                  getS3Object(previewImageAWSS3Key, sessionPackageData)
                })
              }
            }
          }
        )
        if (!sessionPackageMatch && sessionPackages === null) {
          setSessionPackages([])
        }
      }
    }
  }, [packageCategorySessionPackagesData, awsS3?.client])

  async function getS3Object(Key, sessionPackageData) {
    await awsS3.client.getObject(
      { Bucket: awsS3.bucket, Key },
      (error, data) => {
        if (!error) {
          sessionPackageData.previewImages.push(
            awsS3.encodeS3ImageData(data.Body)
          )
          setSessionPackages((prevState) => {
            if (prevState !== null) {
              const existingIndex = prevState.findIndex(
                (item) => item.id === sessionPackageData.id
              )
              if (existingIndex !== -1) {
                return prevState.map((item, index) =>
                  index === existingIndex ? sessionPackageData : item
                )
              } else {
                return [...prevState, sessionPackageData]
              }
            } else {
              return [sessionPackageData]
            }
          })
        }
      }
    )
  }

  if (subjectGroupsQueryError || packageCategorySessionError)
    return <p>Error loading session packages</p>
  if (
    (!subjectGroupsQueryData?.subjectGroup &&
      !packageCategorySessionPackagesData) ||
    !awsS3?.client
  )
    return <></>

  return (
    <>
      <div className="selectSessionPackage">
        {resitReason && (
          <Row className="mb-2">
            <Col>
              <button
                type="button"
                onClick={() => handleChangeResitReasonClick()}
                // variant="link"
                className="p-0 m-0 d-flex align-items-center mb-3 btn-link"
              >
                <ArrowLeftShort /> Back
              </button>
            </Col>
          </Row>
        )}
        {sessionPackages === null ? (
          <Loading />
        ) : (
          <>
            {!sessionPackageMatch ? (
              <>
                <Row className="mt-3 mb-3 ml-1">
                  <Col>
                    <h4 style={{ fontSize: '18px' }}>No Sessions Available</h4>
                  </Col>
                </Row>

                {canCreateJob && (
                  <Row>
                    <Col>
                      <Button
                        size="sm"
                        variant="link"
                        onClick={() => {
                          history.push({
                            pathname: `/job`,
                            state: {
                              jobFormType: 'subject',
                              bookingFlowSubjectId: subjectId,
                              bookingFlowSubjectGroupId: subjectGroupId,
                              bookingFlowPackageCategoryId: packageCategoryId,
                              bookingFlowChangePackageSessionId:
                                changeSessionPackageSessionId,
                              bookingFlowResitSessionId: resitSessionId,
                              bookingFlowResitReasonId: resitReason?.id,
                              bookingFlowRescheduleSessionId:
                                rescheduleSessionId,
                              bookingFlowReschedulingNoShow: reschedulingNoShow,
                            },
                          })
                        }}
                        className="ml-3"
                      >
                        <PlusCircle className="mr-2" />
                        New Job
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <>
                <Row className={!canCreateJob ? 'mb-2' : ''}>
                  <Col>
                    <h4>Select Session</h4>
                  </Col>
                </Row>
                {canCreateJob && (
                  <Row>
                    <Col>
                      <h4 style={{ fontSize: '18px' }}>
                        Need to book a different package?
                        <Button
                          size="sm"
                          variant="link"
                          onClick={() => {
                            history.push({
                              pathname: `/job`,
                              state: {
                                jobFormType: 'subject',
                                bookingFlowSubjectId: subjectId,
                                bookingFlowSubjectGroupId: subjectGroupId,
                                bookingFlowPackageCategoryId: packageCategoryId,
                                bookingFlowChangePackageSessionId:
                                  changeSessionPackageSessionId,
                                bookingFlowResitSessionId: resitSessionId,
                                bookingFlowResitReasonId: resitReason?.id,
                                bookingFlowRescheduleSessionId:
                                  rescheduleSessionId,
                                bookingFlowReschedulingNoShow:
                                  reschedulingNoShow,
                              },
                            })
                          }}
                          className="ml-3"
                        >
                          <PlusCircle className="mr-2" />
                          New Job
                        </Button>
                      </h4>
                    </Col>
                  </Row>
                )}
                {sessionPackages.map((sessionPackage) => (
                  <div
                    key={sessionPackage.id}
                    className="border rounded p-3 mb-4"
                  >
                    <>
                      <Row>
                        <Col>
                          <p className="mb-0">{sessionPackage.title}</p>

                          <Row>
                            {(loggedInUser?.permissions?.isEmployee ||
                              !subjectGroupsQueryData?.subjectGroup ||
                              subjectGroupsQueryData?.subjectGroup
                                .subjectsPaying) && (
                              <Col xs="auto" className="pr-0 ml-n1">
                                <div className="d-flex align-items-center small">
                                  <CurrencyDollar />
                                  <small>{sessionPackage.price}</small>
                                </div>
                              </Col>
                            )}
                            <Col>
                              <div className="d-flex align-items-center small">
                                <Clock className="mr-1" />
                                <small>{sessionPackage.duration}</small>
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="d-md-flex justify-content-md-end">
                          <div>
                            <Button
                              onClick={() => checkHandleUpsell(sessionPackage)}
                              variant="outline-primary"
                              size="sm"
                              className="px-4"
                            >
                              <Camera className="mr-2" />
                              Book
                            </Button>
                          </div>
                        </Col>
                      </Row>

                      <p className="mt-3">{sessionPackage.description}</p>

                      {sessionPackage.previewImages.length > 0 ? (
                        <Row className="mt-3">
                          <Col
                            md={{
                              span: 4,
                              offset: 4,
                            }}
                          >
                            <Carousel
                              indicators={false}
                              controls={
                                !sessionPackage.previewImages.length === 1
                              }
                            >
                              {sessionPackage.previewImages.map(
                                (previewImage, index) => {
                                  return (
                                    <Carousel.Item key={index}>
                                      <Image
                                        fluid
                                        src={`data:image/jpeg;base64,${previewImage}`}
                                        alt="Session package preview"
                                      />
                                    </Carousel.Item>
                                  )
                                }
                              )}
                            </Carousel>
                          </Col>
                        </Row>
                      ) : null}
                    </>
                  </div>
                ))}
              </>
            )}
          </>
        )}
        <UpsellDescriptionModal
          setIsUpselling={setIsUpselling}
          bookSessionFlow={bookSessionFlow}
          upsellModalOpen={upsellModalOpen}
          setUpsellOpenModal={setUpsellOpenModal}
          sessionPackage={selectedSessionPackage}
          handleSessionPackageClick={handleSessionPackageClick}
        />
      </div>
    </>
  )
}
export default SelectSessionPackage
