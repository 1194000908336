import React, { useState } from 'react'
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import { SignMergeLeft } from 'react-bootstrap-icons'
import SubjectSearchInput from '../common/node_search_input/SubjectSearchInput'
import { Formik } from 'formik'
import Loading from '../common/Loading'
import * as Yup from 'yup'

const MergeIntoSubjectModal = (props) => {
  const location = useLocation()
  const { showModal, toggleModal, mergeSubjectId } = props
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)

  const [mergeSubjectMutation] = useMutation(
    gql`
      mutation MergeSubject($mergeSubjectInput: MergeSubjectInput!) {
        mergeSubject(input: $mergeSubjectInput) {
          merged
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success('Subject Merged')
        if (location.pathname.includes('subject')) {
          history.push('/subjects')
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['Subjects'],
    }
  )

  const fields = [
    {
      display: 'First Name',
      fieldName: 'firstName',
    },
    {
      display: 'Last Name',
      fieldName: 'lastName',
    },
    {
      display: 'Student ID',
      fieldName: 'studentId',
    },
    {
      display: 'Job Title',
      fieldName: 'jobTitle',
    },
    {
      display: 'Email',
      fieldName: 'email',
    },
    {
      display: 'Secondary Email',
      fieldName: 'secondaryEmail',
    },
    {
      display: 'Phone',
      fieldName: 'phoneNumber',
    },
    {
      display: 'Secondary Phone',
      fieldName: 'secondaryPhoneNumber',
    },
    {
      display: 'Password',
      fieldName: 'password',
    },
    {
      display: 'Address',
      fieldName: 'address',
    },
    {
      display: 'Notes',
      fieldName: 'notes',
    },
    {
      display: 'Metadata',
      fieldName: 'metadata',
    },
    {
      display: 'Organization',
      fieldName: 'organization',
    },
    {
      display: 'Billing',
      fieldName: 'billing',
    },
    {
      display: 'Notifications',
      fieldName: 'notifications',
    },
    {
      display: 'Files',
      fieldName: 'files',
    },
  ]

  const dropdown = (displayFieldName, fieldName, selectedValue, formik) => {
    return (
      <Form.Group as={Col} md={3}>
        <Form.Label>{displayFieldName}</Form.Label>
        <select
          className="form-control-sm form-select"
          value={selectedValue}
          onChange={(e) => formik.setFieldValue(fieldName, e.target.value)}
        >
          {['Target Subject', 'Merged Subject'].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </Form.Group>
    )
  }

  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal size="xl" show={showModal} onHide={() => toggleModal()}>
          <Modal.Header closeButton>
            <Modal.Title id="new-title">Merge Subject</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                targetSubjectId: '',
                firstName: 'Target Subject',
                lastName: 'Target Subject',
                studentId: 'Target Subject',
                jobTitle: 'Target Subject',
                notes: 'Target Subject',
                metadata: 'Target Subject',
                email: 'Target Subject',
                secondaryEmail: 'Target Subject',
                phoneNumber: 'Target Subject',
                secondaryPhoneNumber: 'Target Subject',
                password: 'Target Subject',
                address: 'Target Subject',
                organization: 'Target Subject',
                billing: 'Target Subject',
                notifications: 'Target Subject',
                files: 'Target Subject',
              }}
              validationSchema={Yup.object().shape({
                targetSubjectId: Yup.string()
                  .required('required')
                  .test(
                    'isNotSame',
                    'The merged subject and target subject must be different.',
                    (value, context) => {
                      let valid = true
                      if (value === mergeSubjectId) {
                        valid = false
                      }
                      return valid
                    }
                  ),
              })}
              onSubmit={async (values) => {
                const mergeSubjectFields = []
                fields.map(({ display, fieldName }) => {
                  if (values[fieldName] === 'Merged Subject') {
                    mergeSubjectFields.push(fieldName)
                  }
                })
                mergeSubjectMutation({
                  variables: {
                    mergeSubjectInput: {
                      mergeIntoSubjectInput: {
                        mergeSubjectId: mergeSubjectId,
                        targetSubjectId: values.targetSubjectId,
                        mergeSubjectFields: mergeSubjectFields,
                      },
                    },
                  },
                })
              }}
            >
              {(formik) => {
                return (
                  <Form onSubmit={formik.handleSubmit}>
                    <Form.Row>
                      <Form.Group as={Col} md={4}>
                        <Form.Label>Target Subject</Form.Label>
                        <SubjectSearchInput
                          formik={formik}
                          formikValue={`targetSubject`}
                          error={formik.errors.targetSubjectId}
                          discludeResults={[mergeSubjectId]}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="mt-2">
                      {fields.map(({ display, fieldName }) =>
                        dropdown(
                          display,
                          fieldName,
                          formik.values[fieldName],
                          formik
                        )
                      )}
                    </Form.Row>
                    <Row>
                      <Col md={3}>
                        <Button
                          variant="outline-primary"
                          className="my-4"
                          type="submit"
                          block
                          disabled={submitting}
                        >
                          <SignMergeLeft className="mr-2" />
                          Merge
                        </Button>
                      </Col>
                    </Row>
                    {submitting && (
                      <Row>
                        <Col>
                          <Loading message={'Merging Into Subject...'} />
                        </Col>
                      </Row>
                    )}
                  </Form>
                )
              }}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default MergeIntoSubjectModal
