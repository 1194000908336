import React from 'react'
import { Modal } from 'react-bootstrap'
import Map from './Map'

const MapModal = (props) => {
  const { showModal, toggleModal, location } = props
  if (!showModal) return <></>
  let label
  if (!location) return <></>
  if (location.fullAddress) {
    label = location.fullAddress
  } else if (location.name) {
    label = location.name
  } else if (location.addressLineOne) {
    label = location.addressLineOne
  } else {
    label = 'Location'
  }

  return (
    <>
      <div id="locationModal">
        <Modal
          size={location ? 'xl' : 'lg'}
          show={showModal}
          onHide={toggleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>{label}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Map location={location} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default MapModal
