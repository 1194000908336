import * as Sentry from '@sentry/react'
import config from '../config'

export const initSentry = () => {
  if (config.ENVIRONMENT !== 'development') {
    Sentry.init({
      dsn: config.SENTRY_DNS,
      environment: config.ENVIRONMENT,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay(),
        new Sentry.BrowserProfilingIntegration(),
      ],
      profilesSampleRate: 1.0,
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      release: config.VERSION,
    })
  }
}

export const setSentryUser = (id, email) => {
  if (config.ENVIRONMENT !== 'development') {
    Sentry.setUser({ id, email })
  }
}

export const clearSentryUser = () => {
  if (config.ENVIRONMENT !== 'development') {
    Sentry.configureScope((scope) => {
      scope.setUser(null)
    })
  }
}
