import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const SubjectSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    setAdditionalFields,
    error,
    discludeResults,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="subject"
      searchDescription={searchDescription ? searchDescription : 'subjects'}
      nodeNamePlural="subjects"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      discludeResults={discludeResults}
      setAdditionalFields={setAdditionalFields}
      gql={gql`
        query Subjects($first: Int, $after: String, $search: String) {
          subjects(
            first: $first
            after: $after
            orderBy: "gaia_user__full_name"
            gaiaUser_FullName_Icontains: $search
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                gaiaUser {
                  fullName
                }
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return node.gaiaUser.fullName
      }}
    />
  )
}

export default SubjectSearchInput
