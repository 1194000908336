import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ArrowCounterclockwise } from 'react-bootstrap-icons'
import { Row, Col, Button } from 'react-bootstrap'
import { Dot } from 'react-bootstrap-icons'
import { useReactiveVar } from '@apollo/client'
import Tracker from './Tracker'
import SelectSessionPackage from '../subject_sessions/SelectSessionPackage'
import ScheduleSession from './ScheduleSession'
import SessionConfirmation from '../subject_sessions/SessionConfirmation'
import SubjectForm from '../subject/SubjectForm'
import { loggedInUserVar } from '../../libs/apollo'
import { DateTime } from 'luxon'
import TechLookup from './TechLookup'
import CreateLogin from './CreateLogin'
import BookInquiry from './BookInquiry'
import { isEmployee } from '../../libs/utils'

export default function BookFlow() {
  const location = useLocation()
  const history = useHistory()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  if (!location) return <></>
  const [showBookInquiry, setShoowBookInquiry] = useState(false)
  const isEmployeeUser = isEmployee(loggedInUser?.groups.edges[0].node.name)
  const [isUpsellSelected, setIsUpsellSelected] = useState(false)
  const bookingType = location?.state?.bookingType
  const incommingSubject = location?.state?.incommingSubject
  const incommingGroup = location?.state?.incommingGroup
  const incommingCategory = location?.state?.category
  const initialStage = location?.state?.initialStage
  const incommingPackage = location?.state?.sessionPackage
  const [subject, setSubject] = useState({
    id: incommingSubject?.id ? incommingSubject.id : '',
    fullName: incommingSubject?.fullName ? incommingSubject.fullName : '',
    org: incommingSubject?.org ? incommingSubject.org : { id: '', name: '' },
    subjectGroupsNotBooked: incommingSubject?.subjectGroupsNotBooked,
  })
  const [subjectGroup, setSubjectGroup] = useState(
    incommingGroup
      ? { id: incommingGroup.id, name: incommingGroup.name }
      : { id: '', name: '' }
  )
  const [sessionPackage, setSessionPackage] = useState({
    id: incommingPackage ? incommingPackage.id : '',
    name: incommingPackage ? incommingPackage.title : '',
    duration: incommingPackage
      ? `${incommingPackage.durationLowMinutes}-${incommingPackage.durationHighMinutes} minutes`
      : '',
    price: incommingPackage ? incommingPackage.price : 0,
  })
  const [category, setCategory] = useState({
    id: incommingCategory ? incommingCategory.id : '',
    name: incommingCategory ? incommingCategory.name : '',
  })
  const [schedule, setSchedule] = useState({
    jobId: '',
    dateTime: '',
    location: '',
  })
  const [stage, setStage] = useState(initialStage)
  const [bookingComponent, setBookingComponent] = useState(null)
  const [hideTracker, setHideTracker] = useState(false)
  const [stripeCard, setStripeCard] = useState()

  const handleBookInquiryChange = () => {
    setShoowBookInquiry((prevState) => !prevState)
  }

  useEffect(() => {
    if (
      loggedInUser &&
      !isEmployeeUser &&
      !loggedInUser.permissions.isOrgContact
    ) {
      setSubject({
        id: loggedInUser.subject.id,
        fullName: loggedInUser.fullName,
        subjectGroupsNotBooked: loggedInUser.subject.subjectGroupsNotBooked,
        org: loggedInUser.subject.organization
          ? {
              id: loggedInUser.subject.organization.id,
              name: loggedInUser.subject.organization.name,
            }
          : { id: '', name: '' },
      })
    }
  }, [loggedInUser])

  const schoolFlow = [
    'review',
    'subjectGroup',
    'package',
    'dateTime',
    'checkout',
  ]

  const categoryFlow = [
    'review',
    'package',
    'dateTime',
    'createLogin',
    'checkout',
  ]

  const categoryFlowAdmin = ['review', 'package', 'dateTime', 'checkout']

  const categoryFlowAdminFromSubject = [
    'package',
    'dateTime',
    'review',
    'checkout',
  ]

  const categoryFlowLoggedIn = ['package', 'dateTime', 'review', 'checkout']

  const handleNextStage = (currentStage) => {
    if (bookingType === 'schoolFlow') {
      const nextStage = schoolFlow[schoolFlow.indexOf(currentStage) + 1]
      setStage(nextStage)
    } else if (bookingType === 'categoryFlow') {
      let nextStage
      if (isEmployeeUser) {
        if (!location.state.subjectNonSchoolSession) {
          nextStage =
            categoryFlowAdmin[categoryFlowAdmin.indexOf(currentStage) + 1]
        } else {
          nextStage =
            categoryFlowAdminFromSubject[
              categoryFlowAdminFromSubject.indexOf(currentStage) + 1
            ]
        }
      } else if (loggedInUser) {
        nextStage =
          categoryFlowLoggedIn[categoryFlowLoggedIn.indexOf(currentStage) + 1]
      } else {
        nextStage = categoryFlow[categoryFlow.indexOf(currentStage) + 1]
      }
      setStage(nextStage)
    }
  }

  useEffect(() => {
    if (location?.state?.subject) {
      setSubject({
        id: location.state.subject.id,
        fullName: location.state.subject.gaiaUser.fullName,
      })
    }
  }, [location.state])

  const handleSubjectGroupClick = (subGroup) => {
    setSubjectGroup({ id: subGroup.id, name: subGroup.name })
    handleNextStage('subjectGroup')
  }

  const handleSetSessionPackage = (sessionPackage) => {
    setSessionPackage({
      id: sessionPackage.id,
      name: sessionPackage.title,
      duration: sessionPackage.duration,
      price: sessionPackage.price,
    })
  }

  const handleTimeClick = (jobNode, startDateTime) => {
    const formattedTime = DateTime.fromISO(startDateTime).toFormat('h:mma')
    const name = jobNode.location.name
    const lineOne = jobNode.location.addressLineOne
    const lineTwo = jobNode.location.addressLineTwo
    const city = jobNode.location.city
    const zipCode = jobNode.location.zipCode

    let location
    if (name && lineTwo) {
      location = `${name}, ${lineOne}, ${lineTwo}, ${city}, ${zipCode}`
    } else if (name) {
      location = `${name}, ${lineOne}, ${city}, ${zipCode}`
    } else {
      location = `${lineOne}, ${city}, ${zipCode}`
    }
    setSchedule({
      jobId: jobNode.id,
      dateTime: formattedTime,
      location,
      unformattedDateTime: startDateTime,
    })
    handleNextStage('dateTime')
  }
  const handleUserFound = (user, subject) => {
    setSubject({
      id: subject.id,
      fullName: user.fullName,
      org: {
        id: subject.organization?.id,
        name: subject.organization?.name,
      },
    })
    handleNextStage('techLookup')
  }
  useEffect(() => {
    switch (stage) {
      case 'subjectGroup':
        setBookingComponent(
          <>
            <h4>Select a Booking Option</h4>
            {subject?.id &&
            subject.subjectGroupsNotBooked &&
            subject.subjectGroupsNotBooked.length > 0 ? (
              subject.subjectGroupsNotBooked.map((subGroup) => (
                <div className="d-block" key={subGroup.subjectGroup.id}>
                  <Button
                    className="m-0"
                    variant="link"
                    key={subGroup.subjectGroup.id}
                    onClick={() =>
                      handleSubjectGroupClick(subGroup.subjectGroup)
                    }
                  >
                    <div style={{ fontSize: 20 }}>
                      <Dot className="text-dark" />
                      <span>{subGroup.subjectGroup.name}</span>
                    </div>
                  </Button>
                </div>
              ))
            ) : (
              <>
                {loggedInUser?.permissions?.isEmployee && (
                  <>
                    <Row>
                      <Col>
                        <h4 style={{ fontSize: '18px' }}>
                          Subject has no bookable subject groups
                        </h4>
                      </Col>
                    </Row>
                  </>
                )}
                {!loggedInUser?.permissions?.isEmployee &&
                  ['categoryFlow', 'schoolFlow'].includes(bookingType) && (
                    <>
                      <span>No sessions available. To request a session,</span>
                      <button
                        onClick={handleBookInquiryChange}
                        className="ml-1 border-bottom border-primary text-primary btn-link"
                      >
                        fill out this form
                      </button>
                      .
                    </>
                  )}
              </>
            )}
          </>
        )
        break
      case 'package':
        setBookingComponent(
          <SelectSessionPackage
            setBookingStage={setStage}
            setIsUpsellSelected={setIsUpsellSelected}
            isUpsellSelected={isUpsellSelected}
            subjectGroupId={subjectGroup.id}
            subjectId={subject?.id}
            packageCategoryId={category.id}
            bookSessionFlow
            setSessionPackage={handleSetSessionPackage}
          />
        )
        break
      case 'dateTime':
        if (!subjectGroup.id && !category.id) {
          setHideTracker(true)
        }
        setBookingComponent(
          <>
            <h4>Schedule Session</h4>
            {!subjectGroup.id && !category.id ? (
              <p className="text-secondary">
                It seems we don&apos;t have any online openings. Please contact
                the studio.
              </p>
            ) : (
              <ScheduleSession
                subjectId={subject.id}
                subjectGroupId={subjectGroup.id}
                packageCategoryId={category.id}
                handleTimeClick={handleTimeClick}
                sessionPackageNode={sessionPackage}
              />
            )}
          </>
        )
        break
      case 'techLookup':
        setBookingComponent(
          <>
            <TechLookup colMd={8} handleUserFound={handleUserFound} />
          </>
        )
        break
      case 'review':
        if (!subject?.id) {
          setBookingComponent(<p>no subject to book...</p>)
        } else {
          setBookingComponent(
            <>
              <h4 className="mb-3">Review Your Information</h4>
              <SubjectForm
                setStripeCard={setStripeCard}
                showFormBorder={true}
                displayBilling={false}
                studentViewing={loggedInUser?.subject?.studentId}
                displayBasic={false}
                handleStageChange={handleNextStage}
                subjectId={subject.id}
                subjectViewing={!isEmployeeUser}
                bookSessionFlow
              />
            </>
          )
        }
        break
      case 'createLogin':
        setBookingComponent(
          <>
            <h4 className="mb-3">Login or Create an Account</h4>
            <CreateLogin
              handleNextStage={handleNextStage}
              setSubject={setSubject}
            />
          </>
        )
        break
      case 'checkout':
        setBookingComponent(
          <>
            <div
              className={
                loggedInUser.permissions.isEmployee ? '' : 'border rounded p-3'
              }
            >
              <SessionConfirmation
                showModal
                isUpsellSelected={isUpsellSelected}
                stripeCard={stripeCard}
                subjectGroupId={subjectGroup.id}
                setJobId={() => {}}
                setSessionStartDateTime={() => {}}
                setBookingStage={() => {}}
                jobId={schedule.jobId}
                sessionPackageId={sessionPackage.id}
                setSessionPackage={() => {}}
                sessionStartDateTime={schedule.unformattedDateTime}
                toggleModal={() => {}}
                subjectId={subject.id}
                bookSessionFlow
                adminViewing={isEmployeeUser}
                packageCategoryId={category.id}
              />
            </div>
          </>
        )
    }
  }, [stage, subject, stripeCard])

  const handleResetClick = () => {
    if (!loggedInUser) {
      history.push('/')
    } else if (isEmployeeUser) {
      history.push({
        pathname: '/book-new-session',
        state: {
          subjectNonSchoolSession: location.state.subjectNonSchoolSession,
          subject: location.state.subject,
        },
      })
    } else if (loggedInUser.permissions.isOrgContact) {
      history.push(`subject/${subject.id}/contact`)
    } else {
      history.push('subject/sessions')
    }
  }

  return (
    <>
      <Row style={{ marginTop: '18px' }}>
        <Col>
          <h1>
            Book Session
            <Button variant="link" onClick={handleResetClick}>
              <ArrowCounterclockwise size={18} className="ml-3 mr-2" />
              Reset
            </Button>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md={stage === 'review' ? 9 : 6}>{bookingComponent}</Col>

        <Col
          md={{ span: 3, offset: stage === 'review' ? 0 : 3 }}
          className={
            stage === 'checkout' || hideTracker ? 'd-none' : 'd-none d-md-block'
          }
          style={{ marginTop: '47px' }}
        >
          <Tracker
            stage={stage}
            isSchoolSubject={bookingType === 'schoolFlow'}
            orgName={subject.org?.name}
            subjectName={subject.fullName}
            subjectGroupName={subjectGroup.name}
            sessionPackage={sessionPackage}
            schedule={schedule}
            categoryName={category.name}
          />
        </Col>
      </Row>
      <BookInquiry
        showBookInquiry={showBookInquiry}
        handleBookInquiryChange={handleBookInquiryChange}
      />
    </>
  )
}
