import React from 'react'
import { Modal } from 'react-bootstrap'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../../libs/apollo'
import BagForm from './BagForm'
import { Briefcase } from 'react-bootstrap-icons'

const BagModal = (props) => {
  const { showModal, toggleModal, bag, employeeId, employeeName } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  if (!showModal) return <></>
  return (
    <>
      <div id="bagTypeModal">
        <Modal size="xl" show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Briefcase className="mr-2" />
              {bag ? (
                <>
                  {canMutate ? <>Edit {bag.name} Bag </> : <>{bag.name} Bag</>}
                </>
              ) : (
                <>New Bag</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BagForm
              bag={bag}
              afterSubmit={toggleModal}
              employeeId={employeeId}
              employeeName={employeeName}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default BagModal
