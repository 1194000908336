import React, { useEffect, useState } from 'react'
import EmailEditor from 'react-email-editor'
import { Row, Col, Form } from 'react-bootstrap'
import './EmailNotificationTemplateBuilder.css'
import { CaretDown, CaretRight } from 'react-bootstrap-icons'
import { EditorState, convertFromRaw } from 'draft-js'
import RichTextEditor from '../common/RichTextEditor'

const EmailNotificationTemplateBuilder = (props) => {
  const {
    notificationTemplate,
    emailEditorRef,
    notificationSender,
    formik,
    reloadEmailEditorRef,
    setReloadEmailEditorRef,
    show,
  } = props
  const [emailEditorLoaded, setEmailEditorLoaded] = useState(false)
  const [display, setDisplay] = useState(false)
  useEffect(() => {
    if (notificationTemplate) {
      if (notificationTemplate.plaintextEmailBuilder) {
        const templateDesign = JSON.parse(notificationTemplate.plaintextEmail)
        if (templateDesign && Object.keys(templateDesign || {}).length) {
          const contentState = convertFromRaw(templateDesign)
          formik.setFieldValue(
            `plaintextEmailEditorState`,
            EditorState.createWithContent(contentState)
          )
        }
      } else if (emailEditorRef) {
        const templateDesign = JSON.parse(
          notificationTemplate.emailTemplateDesign
        )
        emailEditorRef.current.editor?.loadDesign(templateDesign)
      }
    }
    if (reloadEmailEditorRef) {
      setReloadEmailEditorRef(false)
    }
    if (emailEditorLoaded) {
      setEmailEditorLoaded(false)
    }
  }, [
    emailEditorLoaded,
    notificationTemplate,
    emailEditorRef,
    reloadEmailEditorRef,
  ])

  const onLoad = () => {
    setEmailEditorLoaded(true)
  }

  if (!notificationTemplate) return <></>
  const availableTemplateImages = []
  if (notificationTemplate.images) {
    if (notificationTemplate.images.includes('studio_logo')) {
      availableTemplateImages.push('Studio')
    }
    if (notificationTemplate.images.includes('organization_logo')) {
      availableTemplateImages.push('Org')
    }
    if (notificationTemplate.images.includes('session_package_preview')) {
      availableTemplateImages.push('Package')
    }
  }
  return (
    <div className="emailNotificationTemplateBuilder">
      <Row className="mt-3">
        <Col className="d-flex align-items-center">
          {!show && (
            <button
              type="button"
              onClick={() => setDisplay(!display)}
              className="px-0 btn-link mr-1"
              style={{ marginTop: '-10px' }}
            >
              <>
                {display ? <CaretDown size={20} /> : <CaretRight size={20} />}
              </>
            </button>
          )}

          <h5>
            {notificationTemplate?.name?.includes('Ticket') ? (
              <>Template</>
            ) : (
              <>Email Template</>
            )}
          </h5>
        </Col>
      </Row>
      <div style={display || show ? {} : { display: 'none' }}>
        {!notificationTemplate?.name?.includes('Ticket') && (
          <Form.Group className="mt-4 mb-3">
            <Row>
              <Col xs={12} md="2">
                <Form.Label>Email Subject</Form.Label>
              </Col>
              <Col xs={12} md={6} className="d-flex align-items-center">
                <Form.Control
                  type="text"
                  className="form-control-sm"
                  name="emailSubject"
                  value={formik.values.emailSubject}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.values.emailSubject}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
        )}
        {notificationSender && (
          <>
            <Form.Group className="mt-4 mb-3">
              <Row>
                <Col xs={12} md="2">
                  <Form.Label>Email CCs</Form.Label>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Control
                    type="text"
                    className="form-control-sm"
                    name="ccEmails"
                    value={formik.values.ccEmails}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.ccEmails}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.values.ccEmails}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-4 mb-3">
              <Row>
                <Col xs={12} md="2" className="d-flex align-items-center">
                  <Form.Label>Email BCCs</Form.Label>
                </Col>
                <Col xs={12} md={6} className="d-flex align-items-center">
                  <Form.Control
                    type="text"
                    className="form-control-sm"
                    name="bccEmails"
                    value={formik.values.emailSubbccEmailsject}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.bccEmails}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.values.bccEmails}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
          </>
        )}
        <Row>
          <Col xs={12}>
            {notificationTemplate.plaintextEmailBuilder && (
              <RichTextEditor formik={formik} />
            )}
            <div
              style={
                notificationTemplate.plaintextEmailBuilder
                  ? { display: 'none' }
                  : {}
              }
            >
              <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
                projectId={36622}
                options={{
                  version: 'latest',
                  appearance: {
                    theme: 'modern_light',
                  },
                  features: {
                    ai: true,
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default EmailNotificationTemplateBuilder
