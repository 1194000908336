import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'

export default function JobFormExplainedModal(props) {
  const { showModal, toggleModal } = props

  return (
    <Modal size="xl" show={showModal} onHide={toggleModal}>
      <Modal.Header closeButton>
        <h1>Job Form Overview</h1>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p className="mb-0">
              What follows is a detailed overview of jobs in Airstudio,
              specifically creating and editing jobs via the job form. A job is
              a container for one or more sessions. Jobs have start and end
              times. It&apos;s important to understand the difference between
              jobs and sessions. A job has a name, date, location, assigned
              staff, start and end times, etc. A session involves a person or
              organization, a session package (e.g., deluxe senior portrait),
              start and end times, and a payment method. For example, Billy is
              coming in on Saturday for a portrait. This is illustrated in
              figure 1. Another example: the mayor of the city is coming in for
              a portrait. This job will require a lot of preparation as well as
              work after the actual picture-taking is done. The job&apos;s start
              and end times will have some padding around the session&apos;s
              start and end times. This is illustrated in figure 2. Lastly, jobs
              can have multiple sessions. You can set a date, start, and end
              time, and open the job up to bookings (figure 3).
            </p>
            <div className="mt-5">
              <div className="d-inline-block">
                <div
                  className="d-flex justify-content-center align-items-center position-relative border border-success"
                  style={{
                    height: '150px',
                    width: '200px',
                    backgroundColor: 'rgba(0,255,0,0.25)',
                  }}
                >
                  <div
                    className="position-absolute small pt-1 px-1 w-100 d-flex justify-content-between"
                    style={{ top: 0 }}
                  >
                    <span>JOB</span>
                    <span className="text-secondary">1:00pm - 1:30pm</span>
                  </div>
                  <div
                    className="border border-success mx-2 small"
                    style={{
                      height: '50px',
                      width: '125px',
                      backgroundColor: 'rgba(0,255,0,0.75)',
                    }}
                  >
                    <div className="text-center">SESSION</div>
                    <div className="text-center text-secondary">
                      1:00pm - 1:30pm
                    </div>
                  </div>
                </div>
                <small className="position-absolute">figure 1</small>
              </div>

              <div className="d-inline-block ms-4">
                <div
                  className="d-flex justify-content-center align-items-center position-relative border border-success"
                  style={{
                    height: '150px',
                    width: '200px',
                    backgroundColor: 'rgba(0,255,0,0.25)',
                  }}
                >
                  <div
                    className="position-absolute small pt-1 px-1 w-100 d-flex justify-content-between"
                    style={{ top: 0 }}
                  >
                    <span>JOB</span>
                    <span className="text-secondary text-center">
                      11:00am - 2:30pm
                    </span>
                  </div>
                  <div
                    className="border border-success mx-2 small"
                    style={{
                      height: '50px',
                      width: '125px',
                      backgroundColor: 'rgba(0,255,0,0.75)',
                    }}
                  >
                    <div className="text-center">SESSION</div>
                    <div className="text-center text-secondary">
                      1:00pm - 1:30pm
                    </div>
                  </div>
                </div>
                <small className="position-absolute">figure 2</small>
              </div>

              <div className="d-inline-block ms-4">
                <div
                  className="d-flex justify-content-center align-items-center position-relative border border-success"
                  style={{
                    height: '150px',
                    width: '400px',
                    backgroundColor: 'rgba(0,255,0,0.25)',
                  }}
                >
                  <div
                    className="position-absolute small pt-1 px-1 w-100 d-flex justify-content-between"
                    style={{ top: 0 }}
                  >
                    <span>JOB</span>
                    <span className="text-secondary text-center">
                      12:00pm - 5:30pm
                    </span>
                  </div>
                  <div
                    className="border border-success mx-2 small"
                    style={{
                      height: '50px',
                      width: '125px',
                      backgroundColor: 'rgba(0,255,0,0.75)',
                    }}
                  >
                    <div className="text-center">SESSION</div>
                    <div className="text-center text-secondary">
                      1:00pm - 1:30pm
                    </div>
                  </div>
                  <div
                    className="border border-success mx-2 small"
                    style={{
                      height: '50px',
                      width: '125px',
                      backgroundColor: 'rgba(0,255,0,0.75)',
                    }}
                  >
                    <div className="text-center">SESSION</div>
                    <div className="text-center text-secondary">
                      2:30pm - 3:30pm
                    </div>
                  </div>
                  <div
                    className="border border-success mx-2 small"
                    style={{
                      height: '50px',
                      width: '125px',
                      backgroundColor: 'rgba(0,255,0,0.75)',
                    }}
                  >
                    <div className="text-center">SESSION</div>
                    <div className="text-center text-secondary">
                      4:00pm - 5:30pm
                    </div>
                  </div>
                </div>
                <small className="position-absolute">figure 3</small>
              </div>
            </div>
            <h4 className="mt-5">Job Details</h4>
            <ul>
              <li>
                <div className="fw-bold">Name</div>
                <span>
                  The name of the job is the internal name used for it.
                </span>
              </li>
              <li>
                <div className="fw-bold">Job Type</div>
                <span>
                  Job types help organize jobs. They are color-coded, which will
                  be apparent when viewing the jobs in the calendar/list view.
                  Job types can be created or edited in the job form. A few
                  examples of job types are Portrait, Family Portrait, and
                  Wedding.
                </span>
              </li>
              <li>
                <div className="fw-bold">Setups</div>
                <span>
                  The number of setups determines how many sessions can be
                  facilitated at one time. For example, two photographers with
                  two backdrops can handle two shoots happening simultaneously.
                  Setups are taken into account when Airstudio calculates open
                  booking times.
                </span>
              </li>
              <li>
                <div className="fw-bold">Date, and Start - End Times</div>
              </li>
              <li>
                <div className="fw-bold">Location</div>
              </li>
            </ul>

            <h4>Sessions</h4>
            <span>
              Sessions only exist within jobs. There is no session without a
              job. Sessions can be associated with a subject or an organization.
            </span>
            <ul>
              <li>
                <div className="fw-bold">Subject or Organization</div>
                <span>The subject or organization for this session.</span>
              </li>
              <li>
                <div className="fw-bold">Session Package</div>
                <span>
                  The session package is the product or type of session taking
                  place. Is it a portrait or a sports event? Session packages
                  have a minimum duration, maximum duration, and a price.
                </span>
              </li>
              <li>
                <div className="fw-bold">Start Time</div>
                <span>
                  When the session is scheduled to begin. The end time will be
                  the start time plus the maximum duration of the session
                  package.
                </span>
              </li>
              <li>
                <div className="fw-bold">Organization</div>
                <span>
                  Select an organization that&apos;s affiliated with the
                  subject. The organization can be charged for a session.
                </span>
              </li>
              <li>
                <div className="fw-bold">Subject Group</div>
                <span>
                  Select a subject group that&apos;s affiliated with the
                  subject.
                </span>
              </li>
            </ul>
            <h4>Subject Groups and Package Categories</h4>
            <span>
              When there is a subject group and/or package category on a job,
              you can select the Booking Start Date, which is the date when
              people can start booking sessions for the job.
            </span>
            <ul>
              <li>
                <div className="fw-bold">Subject Groups</div>
                <span>
                  Assign one or more subject groups to a job. The subjects
                  included in a subject group on a job will be able to book
                  sessions for the job. You are able to refine the session
                  packages that are bookable on a per-job basis. If a subject
                  group has standard, premium, and ultra-premium session
                  packages, you can select the subject group and then only the
                  standard session package for a specific job.
                </span>
              </li>
              <li>
                <div className="fw-bold">Package Categories</div>
                <span>
                  Package categories enable customers to book certain sessions
                  on a given job. Package categories have a name, description,
                  and associated session packages. Let&apos;s say you are
                  available for commercial portraits (i.e., professional
                  headshots) from 1 p.m. to 5 p.m. You can search and select the
                  Commercial Portrait from the package category field in the job
                  form and select the session packages you want to allow
                  customers to book (maybe you don&apos;t have the equipment
                  that day for your Premium Headshot session package).
                </span>
              </li>
            </ul>

            <h4>Employees</h4>
            <ul>
              <li>
                <div className="fw-bold">Employee</div>
                <span>
                  Select an employee or select &quot;Unknown&quot; when you
                  don&apos;t know the assignment.
                </span>
              </li>
              <li>
                <div className="fw-bold">Role</div>
                <span>
                  The role for the employee. What is the employee&apos;s
                  responsibility for this job?
                </span>
              </li>
              <li>
                <div className="fw-bold">Start and End</div>
                <span>
                  The time window the employee is expected to be present for the
                  job.
                </span>
              </li>
              <li>
                <div className="fw-bold">Send Job Assignment Notification</div>
                <span>
                  Whether or not you want to send a notification to the employee
                  when the job is created or edited.
                </span>
              </li>
              <li>
                <div className="fw-bold">Confirmed</div>
                <span>
                  If checked, this indicates the employee has agreed or
                  acknowledged the job assignment via email or the Airstudio
                  app.
                </span>
              </li>
            </ul>
            <h4 className="mt-3">Buffers</h4>
            <span>
              Subjects cannot book over buffer windows. Maybe you have a few
              buffers for employee breaks or for other reasons.
            </span>
            <h4 className="mt-3">Organizational Contacts</h4>
            <span>
              Search and select organizational contacts. They will then receive
              basic updates about the job: time, place, and location.
            </span>
            <h4 className="mt-3">Contacts</h4>
            <span>
              Search and select contacts. They will then receive basic updates
              about the job: time, place, and location.
            </span>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
