import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col, Button } from 'react-bootstrap'
import SortableInfiniteTable from '../../../common/SortableInfiniteTable'
import { Plug } from 'react-bootstrap-icons'
import { DateTime } from 'luxon'
import Loading from '../../../common/Loading'
import WebhookForm from './WebhookForm'
import { settingsVar } from '../../../../libs/apollo'
import { useReactiveVar } from '@apollo/client'

const Webhooks = () => {
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [editWebhook, setEditWebhook] = useState()
  const [view, setView] = useState('list')
  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Webhooks($cursor: String) {
        webhooks(first: 250, after: $cursor, orderBy: "name") {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              name
              webhookUrl
              enabled
              requestHeader
              contentType {
                model
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      // pollInterval: 30000,
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query()
    }
  }, [initialQueryRun, setInitialQueryRun])

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.webhooks?.pageInfo?.endCursor,
    }
    queryFetchMore({
      variables,
    })
  }

  const onRowClick = (row) => {
    setView('form')
    setEditWebhook(row.original.node)
  }

  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Webhooks Keys..." />
        </Col>
      </Row>
    )
  if (queryError) return <>Error loading</>
  return (
    <>
      {view === 'list' && (
        <>
          <Row>
            <Col>
              <div>
                <Button
                  variant="link"
                  onClick={() => {
                    setView('form')
                  }}
                >
                  <Plug className="mr-2" />
                  New Webhook
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <SortableInfiniteTable
                tableData={
                  queryData?.webhooks?.edges ? queryData?.webhooks?.edges : []
                }
                loadingMessage="Loading API Keys..."
                tableColumns={[
                  {
                    Header: 'Name',
                    id: 'name',
                    accessor: (row) => {
                      return row.node.name
                    },
                  },
                  {
                    Header: 'URL',
                    id: 'url',
                    accessor: (row) => {
                      return row.node.webhookUrl
                    },
                  },
                  {
                    Header: 'Enabled',
                    id: 'enabled',
                    accessor: (row) => {
                      return row.node.enabled ? 'Yes' : 'No'
                    },
                  },
                ]}
                fetchMoreTableData={fetchMore}
                hasMoreTableData={queryData?.webhooks?.pageInfo?.hasNextPage}
                onRowClick={(row) => onRowClick(row)}
                tableHeight={200}
                rowPointer
              />
            </Col>
          </Row>
        </>
      )}
      {view === 'form' && (
        <>
          <WebhookForm
            webhook={editWebhook}
            setView={setView}
            setEditWebhook={setEditWebhook}
          />
        </>
      )}
    </>
  )
}
export default Webhooks
