import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { ArrowUpCircle, ArrowDownCircle } from 'react-bootstrap-icons'
import { formatDateTimeToString } from '../../libs/utils'
import { fotomerchantOrderAdminUrl } from '../../libs/fotomerchant'

const FotomerchantOrders = (props) => {
  const { fotomerchantSubjectId, subjectId, onlyTable } = props
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [display, setDisplay] = useState(false)

  const [query, { data: queryData, fetchMore: queryFetchMore }] = useLazyQuery(
    gql`
      query FotomerchantOrders(
        $fotomerchantSubject: [ID]
        $subject: [ID]
        $cursor: String
      ) {
        fotomerchantOrders(
          fotomerchantSubject: $fotomerchantSubject
          fotomerchantSubject_Subject: $subject
          after: $cursor
          orderBy: "-ordered_at"
        ) {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              fotomerchantId
              orderReference
              recipientName
              recipientEmail
              directFulfillmentStatus
              fotomerchantSubject {
                id
                fotomerchantClientSession {
                  subjectGroup {
                    name
                  }
                  packageCategory {
                    name
                  }
                }
              }
              total
              totalSpent
              state
              orderItemsCount
              orderedAt
              shippedAt
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = {}
      if (fotomerchantSubjectId) {
        variables.fotomerchantSubject
      }
      if (subjectId) {
        variables.subject = subjectId
      }
      query({ variables })
    }
  }, [initialQueryRun])

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.equipmentItemTypes?.pageInfo?.endCursor,
    }
    if (fotomerchantSubjectId) {
      variables.fotomerchantSubject
    }
    if (subjectId) {
      variables.subject = subjectId
    }
    queryFetchMore({ variables })
  }

  const formatStatus = (input) => {
    return input
      .replace(/^STATE_/, '')
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/\b\w/g, function (char) {
        return char.toUpperCase()
      })
  }

  const formatDate = (date) => {
    const startDateTime = new Date(date)
    return formatDateTimeToString(startDateTime)
  }

  const tableColumns = [
    {
      Header: 'Ordered On',
      id: 'orderedOn',
      accessor: (row) => {
        if (row.node.orderedAt) {
          return formatDate(row.node.orderedAt)
        }
      },
    },
    {
      Header: 'Shipped On',
      id: 'shippedOn',
      accessor: (row) => {
        if (row.node.shippedAt) {
          return formatDate(row.node.shippedAt)
        }
      },
    },
    {
      Header: 'Recipient',
      id: 'recipient',
      accessor: (row) => {
        let recipient
        if (row.node.recipientName) {
          recipient = row.node.recipientName
        }
        if (row.node.recipientEmail) {
          if (recipient) {
            recipient = `${recipient} ${row.node.recipientEmail}`
          } else {
            recipient = row.node.recipientEmail
          }
        }
        return recipient
      },
    },
    {
      Header: 'Order Items',
      id: 'orderItems',
      accessor: 'node.orderItemsCount',
    },
    {
      Header: 'Price',
      id: 'total',
      accessor: (row) => {
        if (row.node.total) {
          return `$${row.node.total}`
        }
      },
    },
    {
      Header: 'Order Status',
      id: 'order',
      accessor: (row) => {
        if (row.node.state) {
          const status = formatStatus(row.node.state)
          return (
            <span
              style={
                status === 'Complete' ? { color: 'green' } : { color: 'orange' }
              }
            >
              {status}
            </span>
          )
        }
      },
    },
    {
      Header: 'Fulfillment Status',
      id: 'fulfillmentStatus',
      accessor: (row) => {
        if (row.node.directFulfillmentStatus) {
          const status = formatStatus(row.node.directFulfillmentStatus)
          return (
            <span
              style={
                status === 'Shipped' ? { color: 'green' } : { color: 'orange' }
              }
            >
              {status}
            </span>
          )
        }
      },
    },
  ]

  if (subjectId) {
    tableColumns.splice(0, 0, {
      Header: 'Booking',
      id: 'booking',
      accessor: (row) => {
        let booking
        if (
          row.node.fotomerchantSubject?.fotomerchantClientSession?.subjectGroup
        ) {
          booking =
            row.node.fotomerchantSubject.fotomerchantClientSession.subjectGroup
              .name
        } else if (
          row.node.fotomerchantSubject?.fotomerchantClientSession
            ?.packageCategory
        ) {
          booking =
            row.node.fotomerchantSubject.fotomerchantClientSession
              .packageCategory.name
        }
        return booking
      },
    })
  }

  if (!queryData) return <></>
  return (
    <>
      <div>
        <>
          {!onlyTable && (
            <Row className="mb-1 mt-4">
              <Col className="d-flex align-items-center">
                <h4 className="mb-0">Fotomerchant Orders</h4>
                <button
                  type="button"
                  onClick={() => setDisplay(!display)}
                  className="px-0 btn-link ml-2"
                >
                  <>
                    {display ? (
                      <>
                        <ArrowUpCircle size={15} />
                      </>
                    ) : (
                      <>
                        <ArrowDownCircle size={15} />
                      </>
                    )}
                  </>
                </button>
              </Col>
            </Row>
          )}
          {(onlyTable || display) && (
            <Row className="mt-4 mb-3">
              <Col>
                <SortableInfiniteTable
                  tableData={
                    queryData?.fotomerchantOrders?.edges
                      ? queryData?.fotomerchantOrders.edges
                      : []
                  }
                  tableColumns={tableColumns}
                  fetchMoreTableData={fetchMore}
                  hasMoreTableData={
                    queryData?.fotomerchantOrders?.pageInfo.hasNextPage
                  }
                  tableHeight={600}
                  loadingMessage="Loading Fotomerchant Orders..."
                  onRowClick={(row) => {
                    window.location.href = fotomerchantOrderAdminUrl(
                      row.original.node.fotomerchantId
                    )
                  }}
                  rowPointer
                  hideGlobalFilter
                />
              </Col>
            </Row>
          )}
        </>
      </div>
    </>
  )
}
export default FotomerchantOrders
