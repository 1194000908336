import React, { useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Form, Row, Col, Button, Modal, Tab } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import {
  FileEarmarkText,
  PlusCircle,
  ArrowRepeat,
  Building,
  CalendarDate,
  Person,
  PersonWorkspace,
  Camera,
  People,
  CardChecklist,
} from 'react-bootstrap-icons'
import Tabs from '../common/Tabs'
import FolderStructure from './FolderStructure'

const EditFolderConfiguration = (props) => {
  const { showModal, toggleModal } = props
  const [folderConfiguration, setFolderConfiguration] = useState()
  const { data } = useQuery(
    gql`
      query FolderConfiguration {
        folderConfigurations {
          edges {
            node {
              id
              sessionSharedCanSeeFiles
              jobSharedCanSeeFiles
              organizationSharedCanSeeFiles
              subjectSharedCanSeeFiles
              subjectGroupSharedCanSeeFiles
              taskSharedCanSeeFiles
              sessionSharedCanCreateFiles
              sessionSharedCanCreateFolders
              jobSharedCanCreateFiles
              jobSharedCanCreateFolders
              organizationSharedCanCreateFiles
              organizationSharedCanCreateFolders
              subjectGroupSharedCanCreateFiles
              subjectGroupSharedCanCreateFolders
              subjectSharedCanCreateFiles
              subjectSharedCanCreateFolders
              taskSharedCanCreateFiles
              taskSharedCanCreateFolders
              subjectFolderStructure
              organizationFolderStructure
              createForOrganizations
              jobFolderStructure
              createForJobs
              employeeFolderStructure
              createForEmployees
              subjectGroupFolderStructure
              createForSubjectGroups
              createForSessions
              sessionFolderStructure
              createForSubjects
              taskFolderStructure
              createForTasks
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) =>
        setFolderConfiguration(data.folderConfigurations.edges[0].node),
    }
  )

  useEffect(() => {
    if (!showModal && folderConfiguration) {
      setFolderConfiguration()
    }
  }, [showModal])

  const [submitting, setSubmitting] = useState(false)
  const [activeTab, setActiveTab] = useState('Organizations')
  const [toggleAfterSubmit, setToggleAfterSubmit] = useState(true)
  const [updateToast, setUpdateToast] = useState('Files Saved')
  const [update] = useMutation(
    gql`
      mutation UpdateFolderConfiguration(
        $updateFolderConfigurationInput: UpdateFolderConfigurationInput!
      ) {
        updateFolderConfiguration(input: $updateFolderConfigurationInput) {
          folderConfiguration {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(updateToast)
        setUpdateToast('Files Saved')
      },
      refetchQueries: ['FolderConfiguration'],
      errorPolicy: 'all',
    }
  )

  const innerToggleModel = () => {
    toggleModal()
    setSubmitting(false)
    setActiveTab('Organizations')
    formik.resetForm()
  }

  const removeIds = (folderStructure) => {
    if (Array.isArray(folderStructure)) {
      folderStructure.forEach((element) => {
        removeIds(element)
      })
    } else if (
      typeof folderStructure === 'object' &&
      folderStructure !== null
    ) {
      delete folderStructure._id
      Object.keys(folderStructure).forEach((key) => {
        removeIds(folderStructure[key])
      })
    }
    return folderStructure
  }

  const formik = useFormik({
    initialValues: {
      organizationFolderStructure: null,
      createforOrganizations: null,
      createForSubjects: null,
      jobFolderStructure: null,
      createForJobs: null,
      createForSession: null,
      sessionFolderStructure: null,
      employeeFolderStructure: null,
      createForEmployees: null,
      subjectGroupFolderStructure: null,
      createForSubjectGroups: null,
      sessionSharedCanCreateFiles: null,
      sessionSharedCanCreateFolders: null,
      jobSharedCanCreateFiles: null,
      jobSharedCanCreateFolders: null,
      organizationSharedCanCreateFiles: null,
      organizationSharedCanCreateFolders: null,
      subjectGroupSharedCanCreateFiles: null,
      subjectGroupSharedCanCreateFolders: null,
      subjectSharedCanCreateFiles: null,
      subjectSharedCanCreateFolders: null,
      taskSharedCanCreateFiles: null,
      taskSharedCanCreateFolders: null,
      taskFolderStructure: null,
      createForTasks: null,
    },
    validationSchema: Yup.object().shape({
      organizationFolderStructure: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      createForOrganizations: Yup.boolean().required('Required'),
      createForSubjects: Yup.boolean().required('Required'),
      jobFolderStructure: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      subjectFolderStructure: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      createForJobs: Yup.boolean().required('Required'),
      employeeFolderStructure: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      sessionSharedCanCreateFiles: Yup.boolean().required('Required'),
      sessionSharedCanCreateFolders: Yup.boolean().required('Required'),
      jobSharedCanCreateFiles: Yup.boolean().required('Required'),
      jobSharedCanCreateFolders: Yup.boolean().required('Required'),
      organizationSharedCanCreateFiles: Yup.boolean().required('Required'),
      organizationSharedCanCreateFolders: Yup.boolean().required('Required'),
      subjectGroupSharedCanCreateFiles: Yup.boolean().required('Required'),
      subjectGroupSharedCanCreateFolders: Yup.boolean().required('Required'),
      subjectSharedCanCreateFiles: Yup.boolean().required('Required'),
      subjectSharedCanCreateFolders: Yup.boolean().required('Required'),
      createForSessions: Yup.boolean().required('Required'),
      sessionSharedCanSeeFiles: Yup.boolean().required('Required'),
      jobSharedCanSeeFiles: Yup.boolean().required('Required'),
      organizationSharedCanSeeFiles: Yup.boolean().required('Required'),
      subjectSharedCanSeeFiles: Yup.boolean().required('Required'),
      subjectGroupSharedCanSeeFiles: Yup.boolean().required('Required'),
      taskSharedCanSeeFiles: Yup.boolean().required('Required'),
      taskSharedCanCreateFiles: Yup.boolean().required('Required'),
      taskSharedCanCreateFolders: Yup.boolean().required('Required'),
      createForTasks: Yup.boolean().required('Required'),
      taskFolderStructure: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      sessionFolderStructure: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      createForEmployees: Yup.boolean().required('Required'),
      subjectGroupFolderStructure: Yup.string()
        .nullable()
        .test('isRequired', 'Required', (value) => {
          let valid = true
          if (!value) {
            valid = false
          }
          return valid
        }),
      createForSubjectGroups: Yup.boolean().required('Required'),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setSubmitting(true)
      const folderStructures = getCachedFolderStructures()
      const organizationFolderStructure = removeIds(
        JSON.parse(folderStructures.organizationFolderStructure)
      )
      const subjectFolderStructure = removeIds(
        JSON.parse(folderStructures.subjectFolderStructure)
      )
      const jobFolderStructure = removeIds(
        JSON.parse(folderStructures.jobFolderStructure)
      )
      const employeeFolderStructure = removeIds(
        JSON.parse(folderStructures.employeeFolderStructure)
      )
      const subjectGroupFolderStructure = removeIds(
        JSON.parse(folderStructures.subjectGroupFolderStructure)
      )
      const sessionFolderStructure = removeIds(
        JSON.parse(folderStructures.sessionFolderStructure)
      )
      const taskFolderStructure = removeIds(
        JSON.parse(folderStructures.taskFolderStructure)
      )
      update({
        variables: {
          updateFolderConfigurationInput: {
            folderConfigurationInput: {
              id: folderConfiguration.id,
              subjectFolderStructure: JSON.stringify(subjectFolderStructure),
              organizationFolderStructure: JSON.stringify(
                organizationFolderStructure
              ),
              sessionSharedCanSeeFiles: values.sessionSharedCanSeeFiles,
              jobSharedCanSeeFiles: values.jobSharedCanSeeFiles,
              organizationSharedCanSeeFiles:
                values.organizationSharedCanSeeFiles,
              subjectSharedCanSeeFiles: values.subjectSharedCanSeeFiles,
              subjectGroupSharedCanSeeFiles:
                values.subjectGroupSharedCanSeeFiles,
              taskSharedCanSeeFiles: values.taskSharedCanSeeFiles,
              createForTasks: values.createForTasks,
              createForOrganizations: values.createForOrganizations,
              createForSubjects: values.createForSubjects,
              jobFolderStructure: JSON.stringify(jobFolderStructure),
              sessionFolderStructure: JSON.stringify(sessionFolderStructure),
              taskFolderStructure: JSON.stringify(taskFolderStructure),
              createForSessions: values.createForSessions,
              createForJobs: values.createForJobs,
              sessionSharedCanCreateFiles: values.sessionSharedCanCreateFiles,
              sessionSharedCanCreateFolders:
                values.sessionSharedCanCreateFolders,
              jobSharedCanCreateFiles: values.jobSharedCanCreateFiles,
              jobSharedCanCreateFolders: values.jobSharedCanCreateFolders,
              organizationSharedCanCreateFiles:
                values.organizationSharedCanCreateFiles,
              organizationSharedCanCreateFolders:
                values.organizationSharedCanCreateFolders,
              subjectGroupSharedCanCreateFiles:
                values.subjectGroupSharedCanCreateFiles,
              subjectGroupSharedCanCreateFolders:
                values.subjectGroupSharedCanCreateFolders,
              subjectSharedCanCreateFiles: values.subjectSharedCanCreateFiles,
              subjectSharedCanCreateFolders:
                values.subjectSharedCanCreateFolders,
              employeeFolderStructure: JSON.stringify(employeeFolderStructure),
              createForEmployees: values.createForEmployees,
              subjectGroupFolderStructure: JSON.stringify(
                subjectGroupFolderStructure
              ),
              createForSubjectGroups: values.createForSubjectGroups,
              taskSharedCanCreateFiles: values.taskSharedCanCreateFiles,
              taskSharedCanCreateFolders: values.taskSharedCanCreateFolders,
            },
          },
        },
      })
    },
  })

  useEffect(() => {
    if (showModal && folderConfiguration) {
      setCachedFoldersFromNode()
      formik.setValues({
        sessionSharedCanSeeFiles: folderConfiguration.sessionSharedCanSeeFiles,
        jobSharedCanSeeFiles: folderConfiguration.jobSharedCanSeeFiles,
        organizationSharedCanSeeFiles:
          folderConfiguration.organizationSharedCanSeeFiles,
        subjectSharedCanSeeFiles: folderConfiguration.subjectSharedCanSeeFiles,
        subjectGroupSharedCanSeeFiles:
          folderConfiguration.subjectGroupSharedCanSeeFiles,
        taskSharedCanSeeFiles: folderConfiguration.taskSharedCanSeeFiles,
        sessionSharedCanCreateFiles:
          folderConfiguration.sessionSharedCanCreateFiles,
        sessionSharedCanCreateFolders:
          folderConfiguration.sessionSharedCanCreateFolders,
        jobSharedCanCreateFiles: folderConfiguration.jobSharedCanCreateFiles,
        jobSharedCanCreateFolders:
          folderConfiguration.jobSharedCanCreateFolders,
        organizationSharedCanCreateFiles:
          folderConfiguration.organizationSharedCanCreateFiles,
        organizationSharedCanCreateFolders:
          folderConfiguration.organizationSharedCanCreateFolders,
        subjectGroupSharedCanCreateFiles:
          folderConfiguration.subjectGroupSharedCanCreateFiles,
        subjectGroupSharedCanCreateFolders:
          folderConfiguration.subjectGroupSharedCanCreateFolders,
        subjectSharedCanCreateFiles:
          folderConfiguration.subjectSharedCanCreateFiles,
        taskSharedCanCreateFiles: folderConfiguration.taskSharedCanCreateFiles,
        subjectSharedCanCreateFolders:
          folderConfiguration.subjectSharedCanCreateFolders,
        taskSharedCanCreateFolders:
          folderConfiguration.taskSharedCanCreateFolders,
        subjectFolderStructure: folderConfiguration.subjectFolderStructure,
        organizationFolderStructure:
          folderConfiguration.organizationFolderStructure,
        createForOrganizations: folderConfiguration.createForOrganizations,
        createForTasks: folderConfiguration.createForTasks,
        jobFolderStructure: folderConfiguration.jobFolderStructure,
        createForJobs: folderConfiguration.createForJobs,
        createForSubjects: folderConfiguration.createForSubjects,
        employeeFolderStructure: folderConfiguration.employeeFolderStructure,
        createForEmployees: folderConfiguration.createForEmployees,
        subjectGroupFolderStructure:
          folderConfiguration.subjectGroupFolderStructure,
        createForSubjectGroups: folderConfiguration.createForSubjectGroups,
        createForSessions: folderConfiguration.createForSessions,
        sessionFolderStructure: folderConfiguration.sessionFolderStructure,
        taskFolderStructure: folderConfiguration.taskFolderStructure,
      })
    }
  }, [showModal, folderConfiguration])

  const getCachedFolderStructures = () => {
    return JSON.parse(localStorage.getItem('folderStructures'))
  }

  const getCachedFolderStructure = (formikFieldName) => {
    const folderStructures = getCachedFolderStructures()
    return folderStructures[formikFieldName]
  }

  const setCachedFolderStructure = (formikFieldName, state) => {
    const folderStructures = getCachedFolderStructures()
    folderStructures[formikFieldName] = JSON.stringify(state)
    localStorage.setItem('folderStructures', JSON.stringify(folderStructures))
  }

  const setCachedFoldersFromNode = () => {
    localStorage.setItem(
      'folderStructures',
      JSON.stringify({
        sessionFolderStructure: folderConfiguration.sessionFolderStructure,
        taskFolderStructure: folderConfiguration.taskFolderStructure,
        subjectFolderStructure: folderConfiguration.subjectFolderStructure,
        organizationFolderStructure:
          folderConfiguration.organizationFolderStructure,
        jobFolderStructure: folderConfiguration.jobFolderStructure,
        employeeFolderStructure: folderConfiguration.employeeFolderStructure,
        subjectGroupFolderStructure:
          folderConfiguration.subjectGroupFolderStructure,
      })
    )
  }
  if (!data || !showModal) return <></>

  if (
    !formik.values.subjectFolderStructure ||
    !formik.values.sessionFolderStructure ||
    !formik.values.organizationFolderStructure ||
    !formik.values.jobFolderStructure ||
    !formik.values.employeeFolderStructure ||
    !formik.values.subjectGroupFolderStructure ||
    !formik.values.taskFolderStructure
  )
    return <></>

  return (
    <>
      <div className="updateFotomerchantConnector">
        <Modal
          size="xl"
          show={showModal}
          onHide={innerToggleModel}
          className="invmodal detail"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">
                <FileEarmarkText className="mr-2" />
                <span>Configure Files</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
                <Tab
                  key="Organizations"
                  eventKey="Organizations"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <Building className="mr-2" />
                      Organizations
                    </b>
                  }
                >
                  <Row className="ml-2 mt-3">
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="organizationSharedCanSeeFiles"
                          type="checkbox"
                          label="Contact Can See Files"
                          checked={formik.values.organizationSharedCanSeeFiles}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `organizationSharedCanSeeFiles`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {formik.values.organizationSharedCanSeeFiles && (
                      <>
                        <Col>
                          <Form.Group>
                            <Form.Check
                              name="organizationSharedCanCreateFiles"
                              type="checkbox"
                              label="Contacts Can Create Files"
                              checked={
                                formik.values.organizationSharedCanCreateFiles
                              }
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  `organizationSharedCanCreateFiles`,
                                  e.target.checked
                                )
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Check
                              name="organizationSharedCanCreateFolders"
                              type="checkbox"
                              label="Contacts Can Create Folders"
                              checked={
                                formik.values.organizationSharedCanCreateFolders
                              }
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  `organizationSharedCanCreateFolders`,
                                  e.target.checked
                                )
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}
                  </Row>
                  {formik.values.createForOrganizations && (
                    <Row className="ml-2 mb-5">
                      <Col md={12}>
                        <FolderStructure
                          folderStructure={JSON.parse(
                            getCachedFolderStructure(
                              'organizationFolderStructure'
                            )
                          )}
                          formik={formik}
                          setCachedFolderStructure={setCachedFolderStructure}
                          root={'organization'}
                          formikField={'organizationFolderStructure'}
                        />
                      </Col>
                    </Row>
                  )}
                </Tab>
                <Tab
                  key="Subject Groups"
                  eventKey="Subject Groups"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <People className="mr-2" />
                      Subject Groups
                    </b>
                  }
                >
                  <Row className="ml-2 mt-3">
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="subjectGroupSharedCanSeeFiles"
                          type="checkbox"
                          label="Contacts Can See Files"
                          checked={formik.values.subjectGroupSharedCanSeeFiles}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `subjectGroupSharedCanSeeFiles`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {formik.values.subjectGroupSharedCanSeeFiles && (
                      <>
                        <Col>
                          <Form.Group>
                            <Form.Check
                              name="subjectGroupSharedCanCreateFiles"
                              type="checkbox"
                              label="Contacts Can Create Files"
                              checked={
                                formik.values.subjectGroupSharedCanCreateFiles
                              }
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  `subjectGroupSharedCanCreateFiles`,
                                  e.target.checked
                                )
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Check
                              name="subjectGroupSharedCanCreateFolders"
                              type="checkbox"
                              label="Contacts Can Create Folders"
                              checked={
                                formik.values.subjectGroupSharedCanCreateFolders
                              }
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  `subjectGroupSharedCanCreateFolders`,
                                  e.target.checked
                                )
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}
                  </Row>
                  {formik.values.createForSubjectGroups && (
                    <Row className="ml-2 mb-5">
                      <Col md={12}>
                        <FolderStructure
                          setCachedFolderStructure={setCachedFolderStructure}
                          getCachedFolderStructure={getCachedFolderStructure}
                          folderStructure={JSON.parse(
                            getCachedFolderStructure(
                              'subjectGroupFolderStructure'
                            )
                          )}
                          formik={formik}
                          root={'subjectGroup'}
                          formikField={'subjectGroupFolderStructure'}
                        />
                      </Col>
                    </Row>
                  )}
                </Tab>
                <Tab
                  key="Subjects"
                  eventKey="Subjects"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <Person className="mr-2" />
                      Subjects
                    </b>
                  }
                >
                  <Row className="ml-2 mt-3">
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="subjectSharedCanSeeFiles"
                          type="checkbox"
                          label="Subject Can See Files"
                          checked={formik.values.subjectSharedCanSeeFiles}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `subjectSharedCanSeeFiles`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {formik.values.subjectSharedCanSeeFiles && (
                      <>
                        <Col>
                          <Form.Group>
                            <Form.Check
                              name="subjectSharedCanCreateFiles"
                              type="checkbox"
                              label="Subject Can Create Files"
                              checked={
                                formik.values.subjectSharedCanCreateFiles
                              }
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  `subjectSharedCanCreateFiles`,
                                  e.target.checked
                                )
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Check
                              name="subjectSharedCanCreateFolders"
                              type="checkbox"
                              label="Subject Can Create Folders"
                              checked={
                                formik.values.subjectSharedCanCreateFolders
                              }
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  `subjectSharedCanCreateFolders`,
                                  e.target.checked
                                )
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}
                  </Row>
                  {formik.values.createForSubjects && (
                    <Row className="ml-2 mb-5">
                      <Col md={12}>
                        <FolderStructure
                          setCachedFolderStructure={setCachedFolderStructure}
                          getCachedFolderStructure={getCachedFolderStructure}
                          folderStructure={JSON.parse(
                            getCachedFolderStructure('subjectFolderStructure')
                          )}
                          formik={formik}
                          root={'subject'}
                          formikField={'subjectFolderStructure'}
                        />
                      </Col>
                    </Row>
                  )}
                </Tab>
                <Tab
                  key="Jobs"
                  eventKey="Jobs"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <CalendarDate className="mr-2" />
                      Jobs
                    </b>
                  }
                >
                  <Row className="ml-2 mt-3">
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="jobSharedCanSeeFiles"
                          type="checkbox"
                          label="Contacts Can See Files"
                          checked={formik.values.jobSharedCanSeeFiles}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `jobSharedCanSeeFiles`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {formik.values.jobSharedCanSeeFiles && (
                      <>
                        <Col>
                          <Form.Group>
                            <Form.Check
                              name="jobSharedCanCreateFiles"
                              type="checkbox"
                              label="Contacts Can Create Files"
                              checked={formik.values.jobSharedCanCreateFiles}
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  `jobSharedCanCreateFiles`,
                                  e.target.checked
                                )
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Check
                              name="jobSharedCanCreateFolders"
                              type="checkbox"
                              label="Contacts Can Create Folders"
                              checked={formik.values.jobSharedCanCreateFolders}
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  `jobSharedCanCreateFolders`,
                                  e.target.checked
                                )
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}
                  </Row>
                  {formik.values.createForJobs && (
                    <Row className="ml-2 mb-5">
                      <Col md={12}>
                        <FolderStructure
                          setCachedFolderStructure={setCachedFolderStructure}
                          getCachedFolderStructure={getCachedFolderStructure}
                          folderStructure={JSON.parse(
                            getCachedFolderStructure('jobFolderStructure')
                          )}
                          formik={formik}
                          root={'job'}
                          formikField={'jobFolderStructure'}
                        />
                      </Col>
                    </Row>
                  )}
                </Tab>
                <Tab
                  key="Sessions"
                  eventKey="Sessions"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <Camera className="mr-2" />
                      Sessions
                    </b>
                  }
                >
                  <Row className="ml-2 mt-3">
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="sessionSharedCanSeeFiles"
                          type="checkbox"
                          label="Subjects Can See Files"
                          checked={formik.values.sessionSharedCanSeeFiles}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `sessionSharedCanSeeFiles`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {formik.values.sessionSharedCanSeeFiles && (
                      <>
                        <Col>
                          <Form.Group>
                            <Form.Check
                              name="sessionSharedCanCreateFiles"
                              type="checkbox"
                              label="Subjects Can Create Files"
                              checked={
                                formik.values.sessionSharedCanCreateFiles
                              }
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  `sessionSharedCanCreateFiles`,
                                  e.target.checked
                                )
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Check
                              name="sessionSharedCanCreateFolders"
                              type="checkbox"
                              label="Subjects Can Create Folders"
                              checked={
                                formik.values.sessionSharedCanCreateFolders
                              }
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  `sessionSharedCanCreateFolders`,
                                  e.target.checked
                                )
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}
                  </Row>
                  {formik.values.createForSessions && (
                    <Row className="ml-2 mb-5">
                      <Col md={12}>
                        <FolderStructure
                          setCachedFolderStructure={setCachedFolderStructure}
                          getCachedFolderStructure={getCachedFolderStructure}
                          folderStructure={JSON.parse(
                            getCachedFolderStructure('sessionFolderStructure')
                          )}
                          formik={formik}
                          root={'session'}
                          formikField={'sessionFolderStructure'}
                        />
                      </Col>
                    </Row>
                  )}
                </Tab>
                <Tab
                  key="Employees"
                  eventKey="Employees"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <PersonWorkspace className="mr-2" />
                      Employees
                    </b>
                  }
                >
                  {formik.values.createForEmployees && (
                    <Row className="ml-2 mb-5 mt-2">
                      <Col md={12}>
                        <FolderStructure
                          setCachedFolderStructure={setCachedFolderStructure}
                          getCachedFolderStructure={getCachedFolderStructure}
                          folderStructure={JSON.parse(
                            getCachedFolderStructure('employeeFolderStructure')
                          )}
                          formik={formik}
                          root={'employee'}
                          formikField={'employeeFolderStructure'}
                        />
                      </Col>
                    </Row>
                  )}
                </Tab>
                <Tab
                  key="Tasks"
                  eventKey="Tasks"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <CardChecklist className="mr-2" />
                      Tasks
                    </b>
                  }
                >
                  <Row className="ml-2 mt-3">
                    <Col>
                      <Form.Group>
                        <Form.Check
                          name="taskSharedCanSeeFiles"
                          type="checkbox"
                          label="Contacts Can See Files"
                          checked={formik.values.taskSharedCanSeeFiles}
                          onChange={(e) => {
                            formik?.setFieldValue(
                              `taskSharedCanSeeFiles`,
                              e.target.checked
                            )
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {formik.values.taskSharedCanSeeFiles && (
                      <>
                        <Col>
                          <Form.Group>
                            <Form.Check
                              name="taskSharedCanCreateFiles"
                              type="checkbox"
                              label="Contacts Can Create Files"
                              checked={formik.values.taskSharedCanCreateFiles}
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  `taskSharedCanCreateFiles`,
                                  e.target.checked
                                )
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Check
                              name="taskSharedCanCreateFolders"
                              type="checkbox"
                              label="Contacts Can Create Folders"
                              checked={formik.values.taskSharedCanCreateFolders}
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  `taskSharedCanCreateFolders`,
                                  e.target.checked
                                )
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}
                  </Row>
                  {formik.values.createForTasks && (
                    <Row className="ml-2 mb-5">
                      <Col md={12}>
                        <FolderStructure
                          setCachedFolderStructure={setCachedFolderStructure}
                          getCachedFolderStructure={getCachedFolderStructure}
                          folderStructure={JSON.parse(
                            getCachedFolderStructure('taskFolderStructure')
                          )}
                          formik={formik}
                          root={'task'}
                          formikField={'taskFolderStructure'}
                        />
                      </Col>
                    </Row>
                  )}
                </Tab>
              </Tabs>
              <Row>
                <Col sm={4}>
                  <Button
                    disabled={submitting}
                    type="submit"
                    size="sm"
                    block
                    variant="outline-primary"
                    className="mt-2"
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Col>
                {activeTab !== 'Employees' && (
                  <Col sm={4}>
                    <Button
                      size="sm"
                      block
                      variant="outline-primary"
                      className="mt-2"
                      onClick={() => {
                        setToggleAfterSubmit(false)
                        setUpdateToast(`Syncing All ${activeTab} Permissions`)
                        update({
                          variables: {
                            updateFolderConfigurationInput: {
                              folderConfigurationInput: {
                                id: folderConfiguration.id,
                                bulkSyncSubjects: activeTab === 'Subjects',
                                bulkSyncSubjectGroups:
                                  activeTab === 'Subject Groups',
                                bulkSyncSessions: activeTab === 'Sessions',
                                bulkSyncJobs: activeTab === 'Jobs',
                                bulkSyncOrganizations:
                                  activeTab === 'Organizations',
                              },
                            },
                          },
                        })
                      }}
                    >
                      <ArrowRepeat className="mr-2" />
                      Sync All {activeTab} Permissions
                    </Button>
                  </Col>
                )}
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default EditFolderConfiguration
