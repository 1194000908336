import React, { useState, useRef, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { loggedInUserVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import { isEmployee } from '../../libs/utils'
import { Camera } from 'react-bootstrap-icons'

export default function SesisonType(props) {
  const history = useHistory()
  const location = useLocation()
  const [sessionType, setSessionType] = useState('schoolFlow')
  const [categoryName, setCategoryName] = useState('')
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const isEmployeeUser = isEmployee(loggedInUser?.groups.edges[0].node.name)
  let md = 12
  let outline = 'link'
  if (
    ['/book-new-session', '/session-type', '/session-type/contact'].includes(
      location.pathname
    )
  ) {
    md = 4
    outline = 'outline-primary'
  }

  const { data: packageCategorydata } = useQuery(
    gql`
      query UserTypePackageCategories($internal: Boolean) {
        packageCategories(internal: $internal) {
          edges {
            node {
              id
              name
              internal
              sessionPackages {
                nodeCount
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const handleTypeChange = (e) => {
    const value = e.target.value
    setSessionType(e.target.value)
    if (value !== 'schoolFlow' && packageCategorydata) {
      const selectedCat = packageCategorydata.packageCategories.edges.find(
        (p) => p.node.id === value
      )
      setCategoryName(selectedCat.node.name)
    }
  }

  useEffect(() => {
    if (
      location.state?.subjectNonSchoolSession &&
      packageCategorydata &&
      packageCategorydata.packageCategories.edges.length > 0
    ) {
      const selectedCat = packageCategorydata.packageCategories.edges[0].node
      setCategoryName(selectedCat.name)
      setSessionType(selectedCat.id)
    }
  }, [location.state, packageCategorydata])

  const handleSubmit = () => {
    if (sessionType === 'schoolFlow') {
      history.push('/school-session')
    } else {
      if (isEmployeeUser && !location?.state?.subjectNonSchoolSession) {
        history.push({
          pathname: '/book',
          state: {
            initialStage: 'techLookup',
            bookingType: 'categoryFlow',
            category: {
              name: categoryName,
              id: sessionType,
            },
          },
        })
      } else if (isEmployeeUser) {
        history.push({
          pathname: '/book',
          state: {
            initialStage: 'package',
            bookingType: 'categoryFlow',
            subjectNonSchoolSession: true,
            subject: location.state?.subject,
            category: {
              name: categoryName,
              id: sessionType,
            },
          },
        })
      } else {
        history.push({
          pathname: '/book',
          state: {
            initialStage: 'package',
            bookingType: 'categoryFlow',
            subjectNonSchoolSession: true,
            subject: location?.state?.subject,
            category: {
              name: categoryName,
              id: sessionType,
            },
          },
        })
      }
    }
  }

  return (
    <Row>
      <Col md={md}>
        <Form.Group className="mt-3">
          <Form.Control
            as="select"
            name="bookSession"
            className="form-control form-control-sm"
            onChange={handleTypeChange}
          >
            {location?.state?.subjectNonSchoolSession ? null : (
              <option value="schoolFlow">School Session</option>
            )}
            {packageCategorydata &&
              packageCategorydata.packageCategories?.edges.map((catNode) => {
                const { node } = catNode
                if (node.sessionPackages.nodeCount > 0) {
                  if (isEmployeeUser || !node.internal) {
                    return (
                      <option key={node.id} value={node.id}>
                        {node.name}
                      </option>
                    )
                  }
                }
              })}
          </Form.Control>
        </Form.Group>
        <Button size="sm" variant="link" onClick={handleSubmit}>
          <Camera className="mr-2" />
          Next
        </Button>
      </Col>
    </Row>
  )
}
