import React, { useEffect } from 'react'
import { Form, Button, Modal, Row, Col } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { useFormik } from 'formik'

const SubjectGroupSubjectCustomFields = (props) => {
  const { sgs, toggleModal, showModal, refetch } = props
  const [update] = useMutation(
    gql`
      mutation UpdateSubjectGroupSubject(
        $updateSubjectGroupSubjectInput: UpdateSubjectGroupSubjectInput!
      ) {
        updateSubjectGroupSubject(input: $updateSubjectGroupSubjectInput) {
          subjectGroupSubject {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        toast.success('Custom Fields Saved')
        innerToggle()
      },
      errorPolicy: 'all',
      refetchQueries: [refetch],
    }
  )
  const formik = useFormik({
    initialValues: {
      customOne: '',
      customTwo: '',
      customThree: '',
      customFour: '',
      customFive: '',
    },
    validationSchema: Yup.object().shape({
      customOne: Yup.string().nullable(),
      customTwo: Yup.string().nullable(),
      customThree: Yup.string().nullable(),
      customFour: Yup.string().nullable(),
      customFive: Yup.string().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      update({
        variables: {
          updateSubjectGroupSubjectInput: {
            subjectGroupSubjectInput: {
              id: sgs.id,
              customOne: values.customOne,
              customTwo: values.customTwo,
              customThree: values.customThree,
              customFour: values.customFour,
              customFive: values.customFive,
            },
          },
        },
      })
    },
  })
  useEffect(() => {
    if (sgs) {
      formik.setValues({
        customOne: sgs.customOne,
        customTwo: sgs.customTwo,
        customThree: sgs.customThree,
        customFour: sgs.customFour,
        customFive: sgs.customFive,
      })
    }
  }, [sgs])
  const innerToggle = () => {
    formik.resetForm()
    toggleModal()
  }
  if (showModal) {
    return (
      <>
        <div className="editSessionNoShowPolicyModal">
          <Modal
            id="editSessionNoShowPolicyModal"
            size="lg"
            show={showModal}
            onSubmit={formik.handleSubmit}
            onHide={innerToggle}
            aria-labelledby="new-title"
            className="invmodal detail"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Custom Fields</Modal.Title>
            </Modal.Header>
            <Form>
              <Modal.Body>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Custom One
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      name="customOne"
                      value={formik.values.customOne}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.customOne}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.customOne}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Custom Two
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      name="customTwo"
                      value={formik.values.customTwo}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.customTwo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.customTwo}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Custom Three
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      name="customThree"
                      value={formik.values.customThree}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.customThree}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.customThree}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Custom Four
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      name="customFour"
                      value={formik.values.customFour}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.customFour}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.customFour}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Custom Five
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      name="customFive"
                      value={formik.values.customFive}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.customFive}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.customFive}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Row>
                  <Col>
                    <Button
                      variant="outline-primary"
                      className="mt-2"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Form>
          </Modal>
        </div>
      </>
    )
  }
  return <></>
}

export default SubjectGroupSubjectCustomFields
