import React, { useState } from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import { Trash } from 'react-bootstrap-icons'

const DeleteSubjectModal = (props) => {
  const location = useLocation()
  const { showModal, toggleModal, subjectId, subjectIds, onComplete } = props
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)

  const [deleteSubjectMutation] = useMutation(
    gql`
      mutation DeleteSubject($deleteSubjectInput: DeleteSubjectInput!) {
        deleteSubject(input: $deleteSubjectInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        if (subjectIds) {
          toast.success('Subjects Deleted')
        } else {
          toast.success('Subject Deleted')
        }
        toggleModal()
        if (location.pathname.includes('subject')) {
          history.push('/subjects')
        }
        if (onComplete) {
          onComplete()
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['Subjects'],
    }
  )

  const handleDeleteClick = () => {
    setSubmitting(true)
    deleteSubjectMutation({
      variables: {
        deleteSubjectInput: {
          subjectIds: subjectId ? [subjectId] : subjectIds,
        },
      },
    })
  }

  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              Delete Subject{subjectIds && <>s</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  Are you sure you want to delete the subject
                  {subjectIds && <>s</>}? Their association to an organization
                  and subject groups will be removed. Their upcoming sessions
                  will canceled and refunded. They will also lose login access.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  disabled={submitting}
                  onClick={handleDeleteClick}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DeleteSubjectModal
