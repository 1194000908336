import React from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'

const DeleteESignRequestsModal = (props) => {
  const {
    showModal,
    toggleModal,
    boxSignRequestId,
    boxSignRequestIds,
    setCheckedIds,
  } = props
  let _boxSignRequestIds = [boxSignRequestId]
  if (_boxSignRequestIds) {
    _boxSignRequestIds = boxSignRequestIds
  }
  const [mutation] = useMutation(
    gql`
      mutation DeleteBoxSignRequest(
        $deleteBoxSignRequestInput: DeleteBoxSignRequestInput!
      ) {
        deleteBoxSignRequest(input: $deleteBoxSignRequestInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        if (_boxSignRequestIds) {
          toast.success('Sign Requests Deleted')
        } else {
          toast.success('Sign Request Deleted')
        }
        if (setCheckedIds) {
          setCheckedIds([])
        }
        toggleModal()
      },
      refetchQueries: ['BoxSignRequests'],
      errorPolicy: 'all',
    }
  )
  const handleDeleteClick = () => {
    mutation({
      variables: {
        deleteBoxSignRequestInput: {
          boxSignRequestIds: _boxSignRequestIds,
        },
      },
    })
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              Delete Sign Request{boxSignRequestIds && <>s</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  Are you sure you want to delete the Sign request
                  {boxSignRequestIds && <>s</>}?
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  onClick={handleDeleteClick}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DeleteESignRequestsModal
