import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const SubjectGroupSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    setAdditionalFields,
    error,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="subjectGroup"
      searchDescription={
        searchDescription ? searchDescription : 'Subject Groups'
      }
      nodeNamePlural="subjectGroups"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      setAdditionalFields={setAdditionalFields}
      gql={gql`
        query SubjectGroups($first: Int, $after: String, $search: String) {
          subjectGroups(
            first: $first
            after: $after
            orderBy: "name"
            name_Icontains: $search
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        return node.name
      }}
    />
  )
}

export default SubjectGroupSearchInput
