import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import { loggedInUserVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'

const ConfirmEmployee = () => {
  const history = useHistory()
  let loggedInUser = useReactiveVar(loggedInUserVar)
  const { confirmationToken } = useParams()
  const [confirmed, setConfirmed] = useState(null)
  const [confirmEmployee] = useMutation(
    gql`
      mutation ConfirmEmployee($input: ConfirmEmployeeJobInput!) {
        confirmEmployeeJob(input: $input) {
          confirmed
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted(data) {
        if (data.confirmEmployeeJob) {
          setConfirmed(true)
          toast.success('Job Assignment Confirmed')
        } else {
          setConfirmed(false)
          toast.error('Invalid Job Assignment Confirmation Link')
        }
      },
    }
  )
  useEffect(() => {
    if (confirmationToken) {
      confirmEmployee({
        variables: {
          input: {
            confirmationToken: confirmationToken,
          },
        },
      })
    }
  }, [confirmationToken])
  useEffect(() => {
    if (loggedInUser && loggedInUser.permissions.isEmployee) {
      history.push('/schedule')
    }
  })
  if (confirmed === null) return <></>
  return (
    <>
      {confirmed === false && <h1>Invalid Job Confirmation Token</h1>}
      {confirmed && (
        <>
          <Row className="mt-4">
            <Col md={12}>
              <h1>Job Assignment Confirmed</h1>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}
export default ConfirmEmployee
