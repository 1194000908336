import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'
import { useMutation, gql, useLazyQuery } from '@apollo/client'
import toast from 'react-hot-toast'
import Loading from '../common/Loading'
import {
  ArrowCounterclockwise,
  CaretDown,
  CaretRight,
  Cash,
} from 'react-bootstrap-icons'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { formatDateTimeToString } from '../../libs/utils'

const RefundPaymentModal = (props) => {
  const {
    showModal,
    toggleModal,
    sessionId,
    description,
    stripePaymentId,
    maxAmountCanRefund,
  } = props
  let mSize = 'md'
  const [loading, setLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [displayRefunds, setDispalyRefunds] = useState(true)
  const [cursor, setCursor] = useState()
  const [hasMoreStripeRefundIntents, setHasMoreStripeRefundIntents] =
    useState(true)
  const [refundAmount, setRefundAmount] = useState(
    maxAmountCanRefund ? maxAmountCanRefund / 100 : 0.5
  )

  const tableColumns = [
    {
      Header: 'Amount',
      id: 'amount',
      accessor: (row) => {
        let amount
        if (row.node.amount) {
          amount = (row.node.amount / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })
        }

        return <>{amount}</>
      },
    },
    {
      Header: 'On',
      id: 'on',
      accessor: (row) => {
        return <>{formatDateTimeToString(new Date(row.node.created))}</>
      },
    },
    {
      Header: 'Refunded By',
      id: 'refundedBy',
      accessor: (row) => {
        let refundedBy
        if (row.node.refundedBy?.fullName) {
          refundedBy = row.node.refundedBy?.fullName
        } else if (row.node.refundedBySystem) {
          refundedBy = 'Airstudio'
        }

        return <>{refundedBy}</>
      },
    },
  ]

  const [mutation] = useMutation(
    gql`
      mutation RefundPayment(
        $refundPaymentInput: RefundStripePaymentIntentInput!
      ) {
        refundStripePaymentIntent(input: $refundPaymentInput) {
          refunded
        }
      }
    `,
    {
      onCompleted: () => {
        setLoading(false)
        toggleModal()
        toast.success('Payment Refunded')
      },
      errorPolicy: 'all',
      refetchQueries: [
        'StripePaymentIntentsQuery',
        'SessionQuery',
        'StripeRefundIntents',
      ],
    }
  )

  const [query, { data, error }] = useLazyQuery(
    gql`
      query StripeRefundIntents(
        $stripePaymentIntentId: ID
        $sessionId: String
        $cursor: String
      ) {
        stripeRefundIntents(
          first: 25
          after: $cursor
          stripePaymentIntentId: $stripePaymentIntentId
          sessionId: $sessionId
          orderBy: "-created"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              recordId
              amount
              created
              refundedBySystem
              refundedBy {
                fullName
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (stripePaymentId || sessionId) {
      setTableLoading(true)
      query({
        variables: {
          stripePaymentIntentId: stripePaymentId,
          sessionId: sessionId,
          cursor: null,
        },
      })
    }
  }, [stripePaymentId, sessionId])

  useEffect(() => {
    if (data?.stripeRefundIntents) {
      setTableLoading(false)
      if (data.stripeRefundIntents.pageInfo.endCursor) {
        setCursor(data.stripeRefundIntents.pageInfo.endCursor)
      }
      setHasMoreStripeRefundIntents(
        data.stripeRefundIntents.pageInfo.hasNextPage
      )
    }
  }, [data])

  const fetchMore = () => {
    query(
      query({
        variables: {
          stripePaymentIntentId: stripePaymentId,
          cursor: cursor,
        },
      })
    )
  }

  const handleRefundClick = () => {
    setLoading(true)
    mutation({
      variables: {
        refundPaymentInput: {
          sessionId,
          paymentIntentId: stripePaymentId,
          refundAmount: parseInt(refundAmount * 100),
          description,
        },
      },
    })
  }

  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size={mSize}
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              <Cash className="mr-2" />
              {maxAmountCanRefund > 0 && <>Refund Payment</>}
              {!maxAmountCanRefund > 0 && (
                <>
                  Payment Refund
                  {data?.stripeRefundIntents?.nodeCount > 1 && <>s</>}
                </>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {maxAmountCanRefund > 0 && (
              <>
                <Row className="mb-2">
                  <Col sm="5">
                    <p style={{ fontSize: '16px' }}>
                      Refund up to{' '}
                      {(maxAmountCanRefund / 100).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </p>
                  </Col>
                  <Form.Group as={Col} sm={4}>
                    <Form.Control
                      className="form-control-sm"
                      type="number"
                      step="0.01"
                      value={refundAmount}
                      onChange={(e) => {
                        setRefundAmount(e.target.value)
                      }}
                      onBlur={(e) => {
                        const value = parseFloat(e.target.value).toFixed(2)
                        setRefundAmount(value)
                      }}
                    />
                    {refundAmount > 0 &&
                      parseFloat(refundAmount) >
                        parseFloat(maxAmountCanRefund / 100) && (
                        <div
                          className="text-danger"
                          style={{ fontSize: '12px' }}
                        >
                          Refund cannot exceed{' '}
                          {(maxAmountCanRefund / 100).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </div>
                      )}
                    {refundAmount < 0.5 && (
                      <div className="text-danger" style={{ fontSize: '12px' }}>
                        Refund must be $0.50 or more
                      </div>
                    )}
                  </Form.Group>
                </Row>
              </>
            )}
            {data?.stripeRefundIntents?.nodeCount > 0 && (
              <>
                {maxAmountCanRefund > 0 && (
                  <Row className="mt-2 mb-2">
                    <Col className="d-flex align-items-center">
                      <button
                        type="button"
                        onClick={() => setDispalyRefunds(!displayRefunds)}
                        className="px-0 btn-link mr-1"
                        style={{ marginTop: '-10px' }}
                      >
                        <>
                          {displayRefunds ? (
                            <CaretDown size={17} />
                          ) : (
                            <CaretRight size={17} />
                          )}
                        </>
                      </button>

                      <Form.Label>Refunds</Form.Label>
                    </Col>
                  </Row>
                )}
                {displayRefunds && (
                  <Row>
                    <Col md={12}>
                      <SortableInfiniteTable
                        tableData={
                          data?.stripeRefundIntents?.edges
                            ? data?.stripeRefundIntents?.edges
                            : []
                        }
                        tableColumns={tableColumns}
                        loading={tableLoading}
                        fetchMoreTableData={fetchMore}
                        hasMoreTableData={hasMoreStripeRefundIntents}
                        hideGlobalFilter
                        tableHeight={130}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
            {maxAmountCanRefund > 0 && (
              <>
                <Row>
                  <Col md={{ offset: 1, span: 10 }}>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      block
                      disabled={
                        loading ||
                        !(refundAmount > 0) ||
                        refundAmount > maxAmountCanRefund / 100
                      }
                      onClick={handleRefundClick}
                    >
                      <ArrowCounterclockwise className="mr-2" />
                      Refund
                    </Button>
                  </Col>
                </Row>
                {loading && (
                  <Row className="mt-3">
                    <Col>
                      <Loading message="Refunding Payment..." />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default RefundPaymentModal
