import React, { useState } from 'react'
import { Row, Col, Button, ButtonGroup, Form } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import NewSessionResitReasonModal from './NewSessionResitReasonModal'
import EditSessionResitReasonModal from './EditSessionResitReasonModal'
import SortableTable from '../../common/SortableTable'
import { PlusCircle, Trash } from 'react-bootstrap-icons'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../../libs/apollo'
import Loading from '../../common/Loading'
import DeleteResitReasonModal from './DeleteResitReasonModal'

const SessionResitReasons = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const [editResitReason, setEditResitReason] = useState(null)
  const [showNewResitReasonModal, setShowNewResitReasonModal] = useState(false)
  const [checkedResitReasons, setCheckedResitReasons] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditResitReasonModal, setShowEditResitReasonModal] =
    useState(false)

  const { loading, error, data } = useQuery(
    gql`
      query ResitReasonsQuery {
        resitReasons {
          edges {
            node {
              id
              reason
              bill
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  const toggleNewResitReasonModal = () => {
    setShowNewResitReasonModal((prevState) => !prevState)
  }
  const toggleEditResitReasonModal = () => {
    setShowEditResitReasonModal((prevState) => !prevState)
  }
  const onTdClick = (row) => {
    if (canMutate) {
      setEditResitReason(row.original.node)
      toggleEditResitReasonModal()
    }
  }

  const handleResitReasonCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedResitReasons((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedResitReasons((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }
  if (loading)
    return (
      <Row className="mt-4">
        <Col>
          <Loading />
        </Col>
      </Row>
    )
  if (error) return <p>Error loading resit reasons</p>
  const resetReasons = data.resitReasons.edges
  return (
    <>
      <div className="sessionResitReasons">
        <Row className="mb-2 mt-3">
          <Col className="d-flex align-items-center">
            <h4 className="mb-0">Session Resit Reasons</h4>
          </Col>
        </Row>
        {canMutate && (
          <>
            <Row>
              <Col className="mb-2">
                <div>
                  <Button variant="link" onClick={toggleNewResitReasonModal}>
                    <PlusCircle className="mr-2" /> New Resit Reason
                  </Button>
                </div>
              </Col>
              {checkedResitReasons.length > 0 && (
                <Col className="mb-2">
                  {' '}
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowDeleteModal(true)
                    }}
                  >
                    <Trash className="mr-2" />
                    {checkedResitReasons.length === 1 ? (
                      <>Delete Resit Reason</>
                    ) : (
                      <>Delete Resit Reasons</>
                    )}
                  </Button>
                </Col>
              )}
            </Row>
          </>
        )}
        <Row className="mt-3">
          <Col sm={4}>
            <SortableTable
              data={resetReasons}
              columns={[
                {
                  Header: 'Reason',
                  accessor: 'node.reason',
                  id: 'reason',
                },
                {
                  Header: 'Bill',
                  accessor: (row) => (row.node.bill ? 'Yes' : 'No'),
                  id: 'bill',
                },
                {
                  disableSortBy: true,
                  Header: (
                    <>
                      <Form.Group
                        as={ButtonGroup}
                        className="align-items-center"
                      >
                        <Form.Check
                          className="ml-2 mt-2"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              const appendIds = []
                              resetReasons.forEach((resetReason) => {
                                if (
                                  !checkedResitReasons.includes(
                                    resetReason.node.id
                                  )
                                ) {
                                  appendIds.push(resetReason.node.id)
                                }
                              })
                              setCheckedResitReasons((prevState) => {
                                return [...prevState, ...appendIds]
                              })
                            } else {
                              setCheckedResitReasons([])
                            }
                          }}
                        />
                        {checkedResitReasons.length > 0 && (
                          <span style={{ fontSize: '14px', marginTop: '5px' }}>
                            ({checkedResitReasons.length})
                          </span>
                        )}
                      </Form.Group>
                    </>
                  ),
                  id: 'delete',
                  accessor: (row) => {
                    return (
                      <>
                        <Form.Group
                          as={ButtonGroup}
                          className="align-items-center"
                        >
                          <Form.Check
                            className="ml-2 mt-2"
                            type="checkbox"
                            checked={checkedResitReasons.includes(row.node.id)}
                            onChange={(e) => handleResitReasonCheck(e, row)}
                          />
                        </Form.Group>
                      </>
                    )
                  },
                },
              ]}
              hasMoreTableData={false}
              rowPointer={canMutate}
              searchWidth={12}
              onTdClicks={{
                reason: (cell) => onTdClick(cell.row),
                bill: (cell) => onTdClick(cell.row),
              }}
            />
          </Col>
        </Row>
        <NewSessionResitReasonModal
          showModal={showNewResitReasonModal}
          toggleModal={toggleNewResitReasonModal}
        />
        <DeleteResitReasonModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setCheckedResitReasons={setCheckedResitReasons}
          ids={checkedResitReasons.length > 1 ? checkedResitReasons : null}
          id={checkedResitReasons.length === 1 ? checkedResitReasons[0] : null}
        />
        <EditSessionResitReasonModal
          resitReason={editResitReason}
          setResitReason={setEditResitReason}
          showModal={showEditResitReasonModal}
          toggleModal={toggleEditResitReasonModal}
        />
      </div>
    </>
  )
}

export default SessionResitReasons
