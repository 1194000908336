import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import PieChart from '../chart/PieChart'

const SubjectGroupReportModal = (props) => {
  const { showModal, toggleModal, subjectGroup } = props
  if (!showModal) return <></>
  return (
    <>
      <div id="locationModal">
        <Modal size={'lg'} show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>{subjectGroup.name} Booking Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <PieChart
                  hideLedgend
                  colors={['#FF9999', '#FFCC66', '#99CC99']}
                  tooltipLabel="Subjects"
                  labels={[
                    'Subjects Not Booked',
                    'Subjects Booked',
                    'Subjects Photographed',
                  ]}
                  series={[
                    subjectGroup.subjectsNotBookedCount,
                    subjectGroup.subjectsBookedCount,
                    subjectGroup.subjectsSessionCompleteCount,
                  ]}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default SubjectGroupReportModal
