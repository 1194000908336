import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client'
import toast from 'react-hot-toast'
import { Dot, Envelope, PhoneVibrate } from 'react-bootstrap-icons'

const SendEmployeeScheduleModal = (props) => {
  const { showModal, toggleModal, filteredEmployees, startDate, endDate } =
    props

  const [submitting, setSubmitting] = useState(false)

  const [getEmployees, { data: employees }] = useLazyQuery(
    gql`
      query EmployeesQuery($employeeIds: [ID]) {
        employees(id: $employeeIds) {
          edges {
            node {
              id
              gaiaUser {
                fullName
              }
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (showModal) {
      getEmployees({
        variables: {
          employeeIds: filteredEmployees,
        },
      })
    }
  }, [showModal])

  const { data: notificationTrigger } = useQuery(
    gql`
      query EmployeeScheduleNotificationTrigger {
        notificationTriggers(name_Iexact: "Employee Schedule") {
          edges {
            node {
              id
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
    }
  )

  const [sendNotificationTrigger] = useMutation(
    gql`
      mutation SendNotificationTrigger(
        $sendNotificationTriggerInput: SendNotificationTriggerInput!
      ) {
        sendNotificationTrigger(input: $sendNotificationTriggerInput) {
          sent
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success('Employee Schedule Sent')
        toggleModal()
      },
    }
  )

  if (!showModal || !employees || !notificationTrigger) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="lg"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title id="new-title">Email Employee Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  Employee schedule for dates from{' '}
                  {startDate.toFormat('MM/dd/yyyy')} to{' '}
                  {endDate.toFormat('MM/dd/yyyy')} for the following employees
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  {employees.employees.edges.map((node) => {
                    if (filteredEmployees.includes(node.node.id)) {
                      return (
                        <>
                          <Dot className="text-dark" />{' '}
                          {node.node.gaiaUser.fullName}
                        </>
                      )
                    }
                  })}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Button
                    disabled={submitting}
                    variant="outline-primary"
                    onClick={() => {
                      setSubmitting(true)
                      sendNotificationTrigger({
                        variables: {
                          sendNotificationTriggerInput: {
                            notificationTriggerInput: {
                              id: notificationTrigger.notificationTriggers
                                .edges[0].node.id,
                              channel: 'Email',
                              employeeIds: filteredEmployees,
                              scheduleStartDate: startDate,
                              scheduleEndDate: endDate,
                            },
                          },
                        },
                      })
                    }}
                  >
                    <Envelope className="mr-2" />
                    Send
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default SendEmployeeScheduleModal
