import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import './JobsCalendar.css'
import { jobTypeToRGBA } from '../../libs/utils'
import Loading from '../common/Loading'

const JobsCalendar = (props) => {
  const {
    jobs,
    jobsQueryData,
    startDateFilter,
    endDateFilter,
    calendarStartDateFilter,
    calendarEndDateFilter,
    setCalendarStartDateFilter,
    setCalendarEndDateFilter,
    setCalendarDefaultDate,
    calendarDefaultDate,
    calendarDefaultView,
    setCalendarDefaultView,
    setJobDetailId,
    toggleJobDetailModal,
  } = props

  const monthMilliseconds = 2592000000
  const DragAndDropCalendar = withDragAndDrop(Calendar)
  const localizer = momentLocalizer(moment)
  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: jobTypeToRGBA(event.jobTypeColor),
      color: 'black',
      borderRadius: '0px',
      fontSize: '.7em',
      border: 'none',
    }
    return {
      className: '',
      style,
    }
  }

  const handleRangeChange = (rangeEvent) => {
    let startRange = rangeEvent.start
    let endRange = rangeEvent.end
    if (!startRange) {
      startRange = rangeEvent[0]
      endRange = rangeEvent[rangeEvent.length - 1]
    }
    if (
      !startDateFilter &&
      startRange - calendarStartDateFilter < monthMilliseconds
    ) {
      const calendarStartDateDayFilter = calendarStartDateFilter.getDay()
      const calendarStartDateMonthFilter = calendarStartDateFilter.getMonth()
      const calendarStartDateYearFilter = calendarStartDateFilter.getFullYear()
      const nextCalendarDateStartFilter = new Date(
        calendarStartDateYearFilter,
        calendarStartDateMonthFilter - 1,
        calendarStartDateDayFilter
      )
      const calendarEndDateDayFilter = calendarEndDateFilter.getDay()
      const calendarEndDateMonthFilter = calendarEndDateFilter.getMonth()
      const calendarEndDateYearFilter = calendarEndDateFilter.getFullYear()
      const nextCalendarEndDateFilter = new Date(
        calendarEndDateYearFilter,
        calendarEndDateMonthFilter - 1,
        calendarEndDateDayFilter
      )
      setCalendarStartDateFilter(nextCalendarDateStartFilter)
      setCalendarEndDateFilter(nextCalendarEndDateFilter)
    }
    if (
      !endDateFilter &&
      calendarEndDateFilter - endRange < monthMilliseconds
    ) {
      const calendarStartDateDayFilter = calendarStartDateFilter.getDay()
      const calendarStartDateMonthFilter = calendarStartDateFilter.getMonth()
      const calendarStartDateYearFilter = calendarStartDateFilter.getFullYear()
      const nextCalendarDateStartFilter = new Date(
        calendarStartDateYearFilter,
        calendarStartDateMonthFilter + 1,
        calendarStartDateDayFilter
      )
      const calendarEndDateDayFilter = calendarEndDateFilter.getDay()
      const calendarEndDateMonthFilter = calendarEndDateFilter.getMonth()
      const calendarEndDateYearFilter = calendarEndDateFilter.getFullYear()
      const nextCalendarEndDateFilter = new Date(
        calendarEndDateYearFilter,
        calendarEndDateMonthFilter + 1,
        calendarEndDateDayFilter
      )
      setCalendarStartDateFilter(nextCalendarDateStartFilter)
      setCalendarEndDateFilter(nextCalendarEndDateFilter)
    }
  }
  const handleEventClick = (event) => {
    setJobDetailId(event.id)
    toggleJobDetailModal()
  }
  const handleNavigate = (defaultDate) => {
    setCalendarDefaultDate(defaultDate)
  }
  const handleViewChange = (view) => {
    setCalendarDefaultView(view)
  }
  const transformedData = []
  jobs.forEach((job) => {
    job.data.forEach((oneJob) => {
      if (oneJob.cancelled && !oneJob.name.includes('CANCELLED')) {
        oneJob.name = `${oneJob.name} CANCELLED`
      }
      transformedData.push(oneJob)
    })
  })
  if (!jobsQueryData)
    return (
      <Row className="mt-4">
        <Col>
          <Loading message="Loading Schedule..." />
        </Col>
      </Row>
    )
  return (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <DragAndDropCalendar
            popup
            localizer={localizer}
            step={5}
            dayLayoutAlgorithm="no-overlap"
            timeslots={12}
            defaultDate={calendarDefaultDate}
            views={['month', 'week', 'day']}
            defaultView={calendarDefaultView}
            events={transformedData}
            style={{ height: 600 }}
            titleAccessor="name"
            showMultiDayTimes
            startAccessor="startDateTime"
            endAccessor="endDateTime"
            onRangeChange={(rangeEvent) => handleRangeChange(rangeEvent)}
            eventPropGetter={eventStyleGetter}
            onSelectEvent={handleEventClick}
            onNavigate={(defaultDate) => handleNavigate(defaultDate)}
            onView={(view) => handleViewChange(view)}
          />
        </Col>
      </Row>
    </>
  )
}

export default JobsCalendar
