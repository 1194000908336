import React from 'react'
import { useState, useEffect } from 'react'
import { Row, Col, Button, Table, Image } from 'react-bootstrap'
import {
  PlusCircle,
  Linkedin,
  Facebook,
  Instagram,
  Twitter,
} from 'react-bootstrap-icons'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import EditBusinessInfoModal from './EditBusinessInfoModal'
import { formatTimezone } from '../../../libs/utils'
import { useAWSS3 } from '../../../libs/aws'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../../libs/apollo'
import SessionResitReasons from '../resit_reason/SessionResitReasons'
import Policies from '../policies/Policies'

const BusinessInfo = (props) => {
  const { settings } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator'].includes(loggedInUser?.permissions?.group)
  const awsS3 = useAWSS3()
  const [showEditBusinessInfoModal, setShowEditBusinessInfoModal] =
    useState(false)

  const [logoImage, setLogoImage] = useState()
  const toggleEditBusinessInfoModal = () => {
    setShowEditBusinessInfoModal((prevState) => !prevState)
  }
  useEffect(() => {
    if (settings && awsS3.client) {
      getS3Object(settings.logoImage.defaultImageName)
    }
  }, [settings, awsS3.client])
  async function getS3Object(Key) {
    await awsS3.client.getObject(
      { Bucket: awsS3.bucket, Key },
      (error, data) => {
        if (!error) {
          setLogoImage(awsS3.encodeS3ImageData(data.Body))
        }
      }
    )
  }
  if ((!settings.logoImage && !logoImage) || !awsS3?.client) return <></>
  return (
    <>
      <div className="studioInfo">
        {canMutate && (
          <Row className="mb-3">
            <Col className="mt-2">
              <div>
                <Button variant="link" onClick={toggleEditBusinessInfoModal}>
                  <PlusCircle className="mr-2" />
                  Edit Studio Details
                </Button>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={6}>
            <Table size="sm">
              <tbody>
                <tr>
                  <td>Logo</td>
                  <td>
                    <Image
                      style={{ width: '80%' }}
                      fluid
                      src={`data:image/jpeg;base64,${logoImage}`}
                      alt="Studio logo"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{settings.name}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{settings.email}</td>
                </tr>
                <tr>
                  <td>Primary Contact Name</td>
                  <td>{settings.primaryContactName}</td>
                </tr>
                <tr>
                  <td>Primary Contact Email</td>
                  <td>{settings.primaryContactEmail}</td>
                </tr>
                <tr>
                  <td>
                    <span className="d-inline-block pt-1">Phone Number</span>
                  </td>
                  <td>
                    <div>
                      <PhoneInput
                        country="us"
                        placeholder=""
                        regions={['north-america']}
                        name="phoneNumber"
                        value={settings.phoneNumber}
                        disabled
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Website</td>
                  <td>{settings.website}</td>
                </tr>
                <tr>
                  <td>Timezone</td>
                  <td>{formatTimezone(settings.timezone)}</td>
                </tr>
                <tr>
                  <td>Location Format</td>
                  <td>{settings.locationFormat}</td>
                </tr>
                <tr>
                  <td>Require Location During Booking</td>
                  <td>{settings.showLocationBooking ? <>Yes</> : <>No</>}</td>
                </tr>
                <tr>
                  <td>Job Booking Ends</td>
                  <td>
                    {settings.jobBookingEndsEndOfJob ? (
                      <>When Job Ends</>
                    ) : (
                      <>{settings.jobBookingEndHours} Hours Before Job Start</>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Anonymous Login</td>
                  <td>{settings.anonymousLogin ? <>Yes</> : <>No</>}</td>
                </tr>
                <tr>
                  <td>Auto Complete Jobs</td>
                  <td>{settings.autoCompleteJobs ? <>Yes</> : <>No</>}</td>
                </tr>
                <tr>
                  <td>Auto Complete Sessions</td>
                  <td>{settings.autoCompleteSessions ? <>Yes</> : <>No</>}</td>
                </tr>
                <tr>
                  <td>Show Last Name ID Login</td>
                  <td>{settings.showLastNameIdLogin ? <>Yes</> : <>No</>}</td>
                </tr>
                <tr>
                  <td>Charge Sessions Sales Tax</td>
                  <td>{settings.chargeStateSalesTax ? <>Yes</> : <>No</>}</td>
                </tr>
                <tr>
                  <td>Default Charge Product Sales Tax</td>
                  <td>
                    {settings.chargeProductStateSalesTax ? <>Yes</> : <>No</>}
                  </td>
                </tr>
                {settings.chargeProductStateSalesTax && (
                  <tr>
                    <td>Default Product Sales Tax Rate</td>
                    <td>{settings.productTaxRate}%</td>
                  </tr>
                )}
                <tr>
                  <td>Navigation Includes Logo</td>
                  <td>{settings.displayLogoInNav ? <>Yes</> : <>No</>}</td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Navigation Includes Name</td>
                  <td>{settings.displayNameInNav ? <>Yes</> : <>No</>}</td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Display Session Stages</td>
                  <td>{settings.sessionStages ? <>Yes</> : <>No</>}</td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Display Job Stages</td>
                  <td>{settings.jobStages ? <>Yes</> : <>No</>}</td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>
                    <Facebook className="mr-2" />
                    Facebook
                  </td>
                  <td>
                    {settings.facebookUrl && (
                      <>
                        <p>
                          {settings.facebookUrl}{' '}
                          {settings.facebookInFooter ? (
                            <>(in footer)</>
                          ) : (
                            <>(not in footer)</>
                          )}
                        </p>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>
                    <Instagram className="mr-2" />
                    Instagram
                  </td>
                  <td>
                    {settings.instagramUrl && (
                      <>
                        <p>
                          {settings.instagramUrl}{' '}
                          {settings.instagramInFooter ? (
                            <>(in footer)</>
                          ) : (
                            <>(not in footer)</>
                          )}
                        </p>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>
                    <Linkedin className="mr-2" />
                    LinkedIn
                  </td>
                  <td>
                    {settings.linkedinUrl && (
                      <>
                        <p>
                          {settings.linkedinUrl}{' '}
                          {settings.linkedinInFooter ? (
                            <>(in footer)</>
                          ) : (
                            <>(not in footer)</>
                          )}
                        </p>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>
                    <Twitter className="mr-2" />
                    Twitter
                  </td>
                  <td>
                    {settings.twitterUrl && (
                      <>
                        <p>
                          {settings.twitterUrl}{' '}
                          {settings.twitterInFooter ? (
                            <>(in footer)</>
                          ) : (
                            <>(not in footer)</>
                          )}
                        </p>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Color Scheme</td>
                  <td>
                    <div style={{ backgroundColor: settings.colorScheme }}>
                      {settings.colorScheme}
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <div className="mt-3">
          <Row className="mb-2 mt-3">
            <Col className="d-flex align-items-center">
              <h4 className="mb-0">Scheduling Policies</h4>
            </Col>
          </Row>
          <Policies settings={settings} />
        </div>
        <SessionResitReasons />
        <EditBusinessInfoModal
          settings={settings}
          logoImage={logoImage}
          showModal={showEditBusinessInfoModal}
          toggleModal={toggleEditBusinessInfoModal}
        />
      </div>
    </>
  )
}

export default BusinessInfo
