import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import SubjectLayout from '../components/layout/SubjectLayout'
import { SUBJECT_GROUPS } from '../libs/constant'
import { loggedInUserVar } from '../libs/apollo'

const SubjectRoute = ({ component: Component, ...rest }) => {
  let user = useReactiveVar(loggedInUserVar)
  if (user) {
    let userGroup = user?.groups.edges[0].node.name
    if (SUBJECT_GROUPS.includes(userGroup)) {
      return (
        <SubjectLayout>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </SubjectLayout>
      )
    } else {
      return <Redirect to="/" />
    }
  }
  return <Redirect to="/" />
}

export default SubjectRoute
