import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Oval, DNA } from 'react-loader-spinner'

const Loading = (props) => {
  const { dna, height, width } = props
  let height_
  let width_
  if (dna) {
    height_ = height ? height : '100'
    width_ = width ? width : '100'
  } else {
    height_ = height ? height : '35'
    width_ = width ? width : '35'
  }
  return (
    <Row className="justify-content-md-center">
      <Col xs={12} className="text-center d-flex justify-content-center">
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: '100%' }}
        >
          {dna && (
            <DNA
              visible={true}
              height={height_}
              width={width_}
              ariaLabel="dna-loading"
            />
          )}
          {!dna && (
            <Oval
              visible={true}
              height={height_}
              width={width_}
              color="#000000"
              strokeWidth={4}
              strokeWidthSecondary={4}
              secondaryColor="#D3D3D3"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          )}
        </div>
      </Col>
    </Row>
  )
}

export default Loading
