import React from 'react'
import { Modal } from 'react-bootstrap'
import RepairForm from './RepairForm'
import { Tools } from 'react-bootstrap-icons'

const RepairModal = (props) => {
  const {
    showModal,
    toggleModal,
    repair,
    prefilledItemDetailsByItemForm,
    prefilledDetail,
  } = props
  if (!showModal) return <></>
  return (
    <>
      <div id="itemTypeModal">
        <Modal size="lg" show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Tools className="mr-2" />
              {repair ? <>Edit Repair</> : <>New Repair</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RepairForm
              repair={repair}
              afterSubmit={toggleModal}
              prefilledItemDetailsByItemForm={prefilledItemDetailsByItemForm}
              prefilledDetail={prefilledDetail}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default RepairModal
