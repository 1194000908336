import React from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'

const DeleteSessionStageModal = (props) => {
  const { sessionStage, showModal, toggleModal } = props

  const [deleteSessionStage] = useMutation(
    gql`
      mutation DeleteSessionStageMutations(
        $deleteSessionStageInput: DeleteSessionStageInput!
      ) {
        deleteSessionStage(input: $deleteSessionStageInput) {
          deleted
        }
      }
    `,
    {
      refetchQueries: ['SessionStages'],
      onCompleted: () => {
        toast.success('Session Stage Deleted')
        toggleModal()
      },
    }
  )

  if (!showModal) return null

  return (
    <Modal show={showModal} onHide={toggleModal} size="sm">
      <Modal.Header closeButton>Delete Session Stage</Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>Are you sure you want to delete {sessionStage.name} stage?</p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Button
              variant="link"
              size="sm"
              block
              style={{ color: 'red !important' }}
              onClick={() => {
                deleteSessionStage({
                  variables: {
                    deleteSessionStageInput: {
                      sessionStageIds: [sessionStage.id],
                    },
                  },
                })
              }}
            >
              <Trash className="mr-2" />
              Delete
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteSessionStageModal
