import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import {
  loggedInUserVar,
  clearLoginVars,
  settingsVar,
} from '../../../libs/apollo'
import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar'
import {
  Unlock,
  Lock,
  CalendarDate,
  Person,
  Buildings,
  People,
  Camera,
  PencilSquare,
  PersonWorkspace,
  Images,
  Bell,
  Briefcase,
  Cash,
  Gear,
  BoxArrowInLeft,
  PersonBoundingBox,
  GeoAlt,
  FileEarmarkText,
  CardChecklist,
} from 'react-bootstrap-icons'
import './Navigation.css'
import { useLocation } from 'react-router-dom'

const EmployeeNavigation = ({ setNavigationOpen }) => {
  let isCollapsed = true
  let locked = false
  let navState = localStorage.getItem('navState')
  if (navState) {
    navState = JSON.parse(navState)
    isCollapsed = navState.collapsed
    locked = navState.locked
  }
  const settings = useReactiveVar(settingsVar)
  const location = useLocation()
  const [navLocked, setNavLocked] = useState(locked)
  const { collapsed, collapseSidebar } = useProSidebar()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const history = useHistory()
  const handleLogout = () => {
    clearLoginVars()
    localStorage.clear()
    history.push('/')
  }

  useEffect(() => {
    setNavigationOpen(!collapsed)
  }, [setNavigationOpen, collapsed])

  useEffect(() => {
    if (!collapsed) {
      setNavigationOpen(true)
    } else {
      setNavigationOpen(false)
    }
  }, [setNavigationOpen, collapsed])

  const toggleSidebar = (open) => {
    if (!navLocked) {
      localStorage.setItem(
        'navState',
        JSON.stringify({ collapsed: open, locked: navLocked })
      )
      collapseSidebar(open)
    }
  }

  if (!settings || !loggedInUser) return <></>

  return (
    <>
      <div
        style={{ backgroundColor: settings.colorScheme, flex: 1 }}
        className="sidebar-container"
      >
        <Sidebar defaultCollapsed={isCollapsed} width="143px">
          <Menu>
            <>
              <MenuItem
                onMouseEnter={() => {
                  toggleSidebar(false)
                }}
                onMouseLeave={() => {
                  toggleSidebar(true)
                }}
                className={
                  location.pathname.includes('schedule') ||
                  location.pathname === '/book-session' ||
                  location.pathname === '/book' ||
                  location.pathname === '/school-session'
                    ? 'menu-item-active'
                    : ''
                }
                onClick={() => history.push('/schedule')}
                active={location.pathname === '/schedule'}
              >
                {collapsed ? (
                  <CalendarDate />
                ) : (
                  <>
                    <CalendarDate className="mr-2" />
                    <span style={{ fontSize: '11.5px' }}>Schedule</span>
                  </>
                )}
              </MenuItem>
              <MenuItem
                onMouseEnter={() => {
                  toggleSidebar(false)
                }}
                onMouseLeave={() => {
                  toggleSidebar(true)
                }}
                className={
                  location.pathname.includes('organization')
                    ? 'menu-item-active'
                    : ''
                }
                onClick={() => history.push('/organizations')}
              >
                {collapsed ? (
                  <Buildings />
                ) : (
                  <>
                    <Buildings className="mr-2" />
                    <span style={{ fontSize: '11.5px' }}>Organizations</span>
                  </>
                )}
              </MenuItem>
              <MenuItem
                onMouseEnter={() => {
                  toggleSidebar(false)
                }}
                onMouseLeave={() => {
                  toggleSidebar(true)
                }}
                className={
                  location.pathname.includes('subject-group')
                    ? 'menu-item-active'
                    : ''
                }
                onClick={() => history.push('/subject-groups')}
              >
                {collapsed ? (
                  <People />
                ) : (
                  <>
                    <People className="mr-2" />
                    <span style={{ fontSize: '11.5px' }}>Subject Groups</span>
                  </>
                )}
              </MenuItem>
              <MenuItem
                onMouseEnter={() => {
                  toggleSidebar(false)
                }}
                onMouseLeave={() => {
                  toggleSidebar(true)
                }}
                className={
                  location.pathname.includes('/subject/') ||
                  location.pathname.includes('subjects')
                    ? 'menu-item-active'
                    : ''
                }
                onClick={() => history.push('/subjects')}
              >
                {collapsed ? (
                  <Person />
                ) : (
                  <>
                    <Person className="mr-2" />
                    <span style={{ fontSize: '11.5px' }}>Subjects</span>
                  </>
                )}
              </MenuItem>
              <MenuItem
                onMouseEnter={() => {
                  toggleSidebar(false)
                }}
                onMouseLeave={() => {
                  toggleSidebar(true)
                }}
                className={
                  location.pathname === '/sessions' ? 'menu-item-active' : ''
                }
                onClick={() => history.push('/sessions')}
                active={location.pathname === '/sessions'}
              >
                {collapsed ? (
                  <Camera />
                ) : (
                  <>
                    <Camera className="mr-2" />
                    <span style={{ fontSize: '11.5px' }}>Sessions</span>
                  </>
                )}
              </MenuItem>
              {settings.tenantSubscription !== 'Basic' && (
                <MenuItem
                  onMouseEnter={() => {
                    toggleSidebar(false)
                  }}
                  onMouseLeave={() => {
                    toggleSidebar(true)
                  }}
                  className={
                    location.pathname === '/tasks' ? 'menu-item-active' : ''
                  }
                  onClick={() => history.push('/tasks')}
                  active={location.pathname === '/tasks'}
                >
                  {collapsed ? (
                    <CardChecklist />
                  ) : (
                    <>
                      <CardChecklist className="mr-2" />
                      <span style={{ fontSize: '11.5px' }}>Tasks</span>
                    </>
                  )}
                </MenuItem>
              )}
              <MenuItem
                onMouseEnter={() => {
                  toggleSidebar(false)
                }}
                onMouseLeave={() => {
                  toggleSidebar(true)
                }}
                className={
                  location.pathname === '/session-packages'
                    ? 'menu-item-active'
                    : ''
                }
                onClick={() => history.push('/session-packages')}
                active={location.pathname === '/session-packages'}
              >
                {collapsed ? (
                  <Images />
                ) : (
                  <>
                    <Images className="mr-2" />
                    <span style={{ fontSize: '11.5px' }}>Packages</span>
                  </>
                )}
              </MenuItem>
              <MenuItem
                onMouseEnter={() => {
                  toggleSidebar(false)
                }}
                onMouseLeave={() => {
                  toggleSidebar(true)
                }}
                className={
                  location.pathname.includes('employee') &&
                  location.pathname !==
                    `/employee/${loggedInUser?.employee?.id}`
                    ? 'menu-item-active'
                    : ''
                }
                onClick={() => history.push('/employees')}
                active={location.pathname === '/employees'}
              >
                {collapsed ? (
                  <PersonWorkspace />
                ) : (
                  <>
                    <PersonWorkspace className="mr-2" />
                    <span style={{ fontSize: '11.5px' }}>Employees</span>
                  </>
                )}
              </MenuItem>
              {settings.tenantSubscription !== 'Basic' &&
                [
                  'Scheduling Manager',
                  'Administrator',
                  'Equipment Manager',
                ].includes(loggedInUser?.permissions?.group) && (
                  <MenuItem
                    onMouseEnter={() => {
                      toggleSidebar(false)
                    }}
                    onMouseLeave={() => {
                      toggleSidebar(true)
                    }}
                    className={
                      location.pathname === '/equipment'
                        ? 'menu-item-active'
                        : ''
                    }
                    onClick={() => history.push('/equipment')}
                    active={location.pathname === '/equipment'}
                  >
                    {collapsed ? (
                      <Briefcase />
                    ) : (
                      <>
                        <Briefcase className="mr-2" />
                        <span style={{ fontSize: '11.5px' }}>Equipment</span>
                      </>
                    )}
                  </MenuItem>
                )}
              <MenuItem
                onMouseEnter={() => {
                  toggleSidebar(false)
                }}
                onMouseLeave={() => {
                  toggleSidebar(true)
                }}
                className={
                  location.pathname === '/locations' ? 'menu-item-active' : ''
                }
                onClick={() => history.push('/locations')}
                active={location.pathname === '/locations'}
              >
                {collapsed ? (
                  <GeoAlt />
                ) : (
                  <>
                    <GeoAlt className="mr-2" />
                    <span style={{ fontSize: '11.5px' }}>Locations</span>
                  </>
                )}
              </MenuItem>
              {['Administrator'].includes(loggedInUser?.permissions?.group) && (
                <MenuItem
                  onMouseEnter={() => {
                    toggleSidebar(false)
                  }}
                  onMouseLeave={() => {
                    toggleSidebar(true)
                  }}
                  onClick={() => history.push('/notifications')}
                  className={
                    location.pathname === '/notifications'
                      ? 'menu-item-active'
                      : ''
                  }
                  active={location.pathname === '/notifications'}
                >
                  {collapsed ? (
                    <Bell />
                  ) : (
                    <>
                      <Bell className="mr-2" />
                      <span style={{ fontSize: '11.5px' }}>Notifications</span>
                    </>
                  )}
                </MenuItem>
              )}
              {!['General Staff', 'Equipment Manager'].includes(
                loggedInUser?.permissions?.group
              ) && (
                <MenuItem
                  onMouseEnter={() => {
                    toggleSidebar(false)
                  }}
                  onMouseLeave={() => {
                    toggleSidebar(true)
                  }}
                  onClick={() => history.push('/billing')}
                  className={
                    location.pathname === '/billing' ? 'menu-item-active' : ''
                  }
                  active={location.pathname === '/billing'}
                >
                  {collapsed ? (
                    <Cash />
                  ) : (
                    <>
                      <Cash className="mr-2" />
                      <span style={{ fontSize: '11.5px' }}>Billing</span>
                    </>
                  )}
                </MenuItem>
              )}
              {!['General Staff', 'Equipment Manager'].includes(
                loggedInUser?.permissions?.group
              ) && (
                <MenuItem
                  onMouseEnter={() => {
                    toggleSidebar(false)
                  }}
                  onMouseLeave={() => {
                    toggleSidebar(true)
                  }}
                  onClick={() => history.push('/files')}
                  className={
                    location.pathname === '/files' ? 'menu-item-active' : ''
                  }
                  active={location.pathname === '/files'}
                >
                  {collapsed ? (
                    <FileEarmarkText />
                  ) : (
                    <>
                      <FileEarmarkText className="mr-2" />
                      <span style={{ fontSize: '11.5px' }}>Files</span>
                    </>
                  )}
                </MenuItem>
              )}
              {!['General Staff', 'Equipment Manager'].includes(
                loggedInUser?.permissions?.group
              ) && (
                <MenuItem
                  onMouseEnter={() => {
                    toggleSidebar(false)
                  }}
                  onMouseLeave={() => {
                    toggleSidebar(true)
                  }}
                  onClick={() => history.push('/forms')}
                  className={
                    location.pathname === '/forms' ? 'menu-item-active' : ''
                  }
                  active={location.pathname === '/forms'}
                >
                  {collapsed ? (
                    <PencilSquare />
                  ) : (
                    <>
                      <PencilSquare className="mr-2" />
                      <span style={{ fontSize: '11.5px' }}>Forms</span>
                    </>
                  )}
                </MenuItem>
              )}
              {!['General Staff', 'Equipment Manager'].includes(
                loggedInUser?.permissions?.group
              ) && (
                <MenuItem
                  onMouseEnter={() => {
                    toggleSidebar(false)
                  }}
                  onMouseLeave={() => {
                    toggleSidebar(true)
                  }}
                  onClick={() => history.push('/settings')}
                  className={
                    location.pathname === '/settings' ? 'menu-item-active' : ''
                  }
                  active={location.pathname === '/settings'}
                >
                  {collapsed ? (
                    <Gear />
                  ) : (
                    <>
                      <Gear className="mr-2" />
                      <span style={{ fontSize: '11.5px' }}>Settings</span>
                    </>
                  )}
                </MenuItem>
              )}
              <MenuItem
                onMouseEnter={() => {
                  toggleSidebar(false)
                }}
                onMouseLeave={() => {
                  toggleSidebar(true)
                }}
                onClick={() =>
                  history.push(`/employee/${loggedInUser?.employee?.id}`)
                }
                className={
                  location.pathname ===
                  `/employee/${loggedInUser?.employee?.id}`
                    ? 'menu-item-active'
                    : ''
                }
              >
                {collapsed ? (
                  <PersonBoundingBox />
                ) : (
                  <>
                    <PersonBoundingBox className="mr-2" />
                    <span style={{ fontSize: '11.5px' }}>Profile</span>
                  </>
                )}
              </MenuItem>
            </>
            <MenuItem
              onClick={handleLogout}
              className="title-space"
              onMouseEnter={() => {
                toggleSidebar(false)
              }}
              onMouseLeave={() => {
                toggleSidebar(true)
              }}
            >
              {collapsed ? (
                <BoxArrowInLeft />
              ) : (
                <>
                  <BoxArrowInLeft className="mr-2" />
                  <span style={{ fontSize: '11.5px' }}>Logout</span>
                </>
              )}
            </MenuItem>
            <MenuItem
              onClick={() => {
                localStorage.setItem(
                  'navState',
                  JSON.stringify({ collapsed, locked: !navLocked })
                )
                setNavLocked(!navLocked)
              }}
              onMouseEnter={() => {
                if (!collapsed) {
                  toggleSidebar(false)
                }
              }}
              onMouseLeave={() => {
                toggleSidebar(true)
              }}
            >
              {navLocked && <Lock style={{ fontSize: '14px' }} />}
              {!navLocked && <Unlock style={{ fontSize: '14px' }} />}
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </>
  )
}

export default EmployeeNavigation
