import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import LocationForm from './LocationForm'
import { GeoAlt } from 'react-bootstrap-icons'

const LocationModal = (props) => {
  const { showModal, toggleModal, location, organizationId, subjectId } = props
  if (!showModal) return <></>
  const [displaySchedule, setDisplaySchedule] = useState(false)
  let label = 'New Location'
  if (location) {
    if (location.name) {
      label = location.name
    }
    if (location.addressLineOne) {
      label = location.addressLineOne
    } else {
      label = 'Unnamed Location'
    }
  }

  return (
    <>
      <div id="locationModal">
        <Modal
          size={location ? 'xl' : 'lg'}
          show={showModal}
          className={displaySchedule && 'full-screen-modal'}
          onHide={toggleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <GeoAlt className="mr-2" />
              {label}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LocationForm
              displaySchedule={displaySchedule}
              setDisplaySchedule={setDisplaySchedule}
              location={location}
              afterSubmit={toggleModal}
              organizationId={organizationId}
              subjectId={subjectId}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default LocationModal
