import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useLazyQuery, gql } from '@apollo/client'
import FormForm from './FormForm'
import { Row, Col } from 'react-bootstrap'
import Loading from '../common/Loading'
import { useHistory } from 'react-router-dom'

const Form = () => {
  const { formId } = useParams()
  const history = useHistory()

  const [query, { loading, data }] = useLazyQuery(
    gql`
      query Form($id: ID!) {
        form(id: $id) {
          id
          name
          form
          public
          subject {
            id
            gaiaUser {
              fullName
            }
          }
          organization {
            id
            name
          }
          job {
            id
            name
          }
          subjectGroup {
            id
            name
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const afterSubmit = () => {
    history.push(`/forms`)
  }

  useEffect(() => {
    if (formId) {
      query({ variables: { id: formId } })
    }
  }, [formId, query])

  const form = data?.form
  if (loading) return <Loading />

  return (
    <>
      <Row className="mt-3 mb-3">
        <Col>
          <div className="d-flex align-items-center">
            <h1 className="mb-0 d-inline">
              {form ? <>Edit Form</> : <>New Form</>}
            </h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormForm form={form} formPage afterSubmit={afterSubmit} />
        </Col>
      </Row>
    </>
  )
}

export default Form
