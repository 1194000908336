// API.js
import React, { useState } from 'react'
import { Button, Badge, Row, Col } from 'react-bootstrap'
import { Plug } from 'react-bootstrap-icons'
import IntegrationCard from '../IntegrationCard'
import APIKeysModal from './api_key/APIKeysModal'
import WebhooksModal from './webhook/WebhooksModal'

const API = () => {
  const [showAPIKeysModal, setShowAPIKeysModal] = useState(false)
  const [showWebhooksModal, setShowWebhooksModal] = useState(false)

  return (
    <>
      {showAPIKeysModal && (
        <APIKeysModal
          showModal={showAPIKeysModal}
          toggleModal={() => setShowAPIKeysModal(!showAPIKeysModal)}
        />
      )}
      {showWebhooksModal && (
        <WebhooksModal
          showModal={showWebhooksModal}
          toggleModal={() => setShowWebhooksModal(!showWebhooksModal)}
        />
      )}
      <IntegrationCard
        logo={`${window.location.origin}/api.png`}
        name="Airstudio API"
        description="Integrate with Airstudio's API to extend the functionality of your photography studio scheduling software. Access data programmatically and automate workflows using API keys and webhooks."
      >
        <Row>
          <Col>
            <Badge variant="success">Enabled</Badge>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="link" onClick={() => setShowAPIKeysModal(true)}>
              <Plug className="mr-2" />
              Configure
            </Button>
            {/* <Button variant="link" onClick={() => setShowWebhooksModal(true)}>
          <Plug className="mr-2" />
          Webhooks
        </Button> */}
          </Col>
        </Row>
      </IntegrationCard>
    </>
  )
}

export default API
