import React from 'react'
import { Form, Modal, Row, Col } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

const NotificationModal = (props) => {
  const { notification, showModal, toggleModal } = props
  if (showModal && notification) {
    return (
      <>
        <div
          className="notificationModal"
          style={{ maxWidth: '100%', overflow: 'auto' }}
        >
          <Modal size="xl" show={showModal} onHide={toggleModal}>
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Row>
                    <Col>
                      <h4>
                        {notification.notificationTemplate}{' '}
                        {notification.sentTo && <> to {notification.sentTo}</>}{' '}
                        on {notification.on}
                      </h4>
                    </Col>
                  </Row>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    {notification.channel == 'Email' && (
                      <div style={{ maxWidth: '100%', overflow: 'auto' }}>
                        {ReactHtmlParser(notification.content)}
                      </div>
                    )}
                    {notification.channel == 'SMS' && (
                      <>
                        <div className="ml-3 mt-3 mb-4">
                          <Form.Control
                            as="textarea"
                            style={{
                              height: '500px',
                              backgroundColor: 'white',
                            }}
                            disabled={true}
                            value={notification.content}
                          />
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Modal.Body>
            </Form>
          </Modal>
        </div>
      </>
    )
  }
  return <></>
}

export default NotificationModal
