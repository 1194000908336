import React, { useState } from 'react'
import { Trash, PersonRolodex, PlusCircle } from 'react-bootstrap-icons'
import { Field, FieldArray } from 'formik'
import { Form, Col, Row, Button } from 'react-bootstrap'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../../libs/apollo'

export default function OrgContacts(props) {
  const { formik, jobGuide } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Scheduling Manager',
    'Administrator',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)

  const [display, setDisplay] = useState(true)

  let allContacts = []

  formik.values.sessions.forEach((session) => {
    if (!jobGuide && session.orgContacts && session.orgContacts.length > 0) {
      const combinedContacts = [...allContacts, ...session.orgContacts]
      allContacts = combinedContacts.filter(
        (el, index, array) =>
          array.findIndex((contact) => contact.id === el.id) === index
      )
    } else if (
      jobGuide &&
      session.organization &&
      session.organization.contacts &&
      session.organization.contacts.edges.length > 0
    ) {
      const combinedContacts = [
        ...allContacts,
        ...session.organization.contacts.edges.map(({ node }) => node),
      ]
      allContacts = combinedContacts.filter(
        (el, index, array) =>
          array.findIndex((contact) => contact.id === el.id) === index
      )
    } else if (
      jobGuide &&
      session.subjectOrganization &&
      session.subjectOrganization.contacts &&
      session.subjectOrganization.contacts.edges.length > 0
    ) {
      const combinedContacts = [
        ...allContacts,
        ...session.subjectOrganization.contacts.edges.map(({ node }) => node),
      ]
      allContacts = combinedContacts.filter(
        (el, index, array) =>
          array.findIndex((contact) => contact.id === el.id) === index
      )
    }
  })
  formik.values.subjectGroups.forEach((subGroup) => {
    if (subGroup.orgContacts && subGroup.orgContacts.length > 0) {
      const combinedContacts = [...allContacts, ...subGroup.orgContacts]
      allContacts = combinedContacts.filter(
        (el, index, array) =>
          array.findIndex((contact) => contact.id === el.id) === index
      )
    }
  })

  allContacts.sort((contactA, contactB) => {
    const fullNameA = contactA.fullName ? contactA.fullName.toLowerCase() : ''
    const fullNameB = contactB.fullName ? contactB.fullName.toLowerCase() : ''

    if (fullNameA < fullNameB) {
      return -1
    } else if (fullNameA > fullNameB) {
      return 1
    } else {
      return 0
    }
  })

  return (
    <>
      {formik.values.organizationContacts.length > 0 && (
        <Form.Row className="mt-2" style={{ marginBottom: '-10px' }}>
          <Form.Group as={Col} md={8}>
            <h5>Organization Contacts</h5>
          </Form.Group>
        </Form.Row>
      )}
      <FieldArray
        name="organizationContacts"
        render={(arrayHelpers) => (
          <div>
            {display && (
              <Row>
                <Col md="4">
                  <div
                    style={
                      formik.values.organizationContacts.length > 1
                        ? {
                            overflowY: 'scroll',
                            maxHeight: '350px',
                            overflowX: 'hidden',
                          }
                        : null
                    }
                    className={
                      formik.values.organizationContacts.length > 1
                        ? 'border p-3 mt-2'
                        : ''
                    }
                  >
                    {formik.values.organizationContacts &&
                      formik.values.organizationContacts.map(
                        (orgContact, orgContactIndex) => {
                          return (
                            <Row key={orgContactIndex}>
                              <Col md={12}>
                                <div className="border border-secondary border-rounded p-3 rounded mb-3">
                                  <Form.Row>
                                    <Form.Group as={Col} md={12}>
                                      <Form.Label>Contact</Form.Label>
                                      <Field
                                        as="select"
                                        disabled={!canMutate}
                                        className="form-control form-control-sm form-select"
                                        name={`organizationContacts[${orgContactIndex}]`}
                                      >
                                        {allContacts.map((contact) => (
                                          <option
                                            value={contact.id}
                                            key={contact.id}
                                          >{`${contact.fullName}, ${contact.email}, ${contact.phoneNumber}`}</option>
                                        ))}
                                      </Field>
                                    </Form.Group>
                                  </Form.Row>
                                  {canMutate && (
                                    <div
                                      className="d-flex align-items-center btn-link hover"
                                      onClick={() =>
                                        arrayHelpers.remove(orgContactIndex)
                                      }
                                      role="presentation"
                                      onKeyDown={() =>
                                        arrayHelpers.remove(orgContactIndex)
                                      }
                                    >
                                      <Trash className="mr-1" />
                                      <small>Remove</small>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          )
                        }
                      )}
                  </div>
                </Col>
              </Row>
            )}
            {allContacts.length > 0 && canMutate && (
              <>
                <Button
                  type="button"
                  className={
                    formik.values.organizationContacts.length > 1
                      ? 'mt-2 p-0 btn-link'
                      : 'mt-1 p-0 btn-link'
                  }
                  onClick={() => {
                    if (!display) {
                      setDisplay(true)
                    }
                    arrayHelpers.push(allContacts[0].id)
                  }}
                >
                  <PlusCircle className="mr-1" /> Add Organization Contact
                </Button>
              </>
            )}
          </div>
        )}
      />
    </>
  )
}
