import React from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { SlashCircle } from 'react-bootstrap-icons'

const EnterpriseSubscriptionModal = (props) => {
  const { showModal, toggleModal } = props

  return (
    <>
      <Modal size={'md'} show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Enterprise Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3 mt-3">
            <Col className="text-center">
              <p>
                Please contact info@airstudio.io to change to the Enterprise
                Plan
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Button variant="outline-primary" block onClick={toggleModal}>
                <SlashCircle className="mr-2" />
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EnterpriseSubscriptionModal
