import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useMutation, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import {
  VectorPen,
  SlashCircle,
  Bell,
  FileEarmarkText,
  Lock,
  Trash,
  ClockHistory,
} from 'react-bootstrap-icons'
import SendESignRequestModal from './SendESignRequestModal'
import { DateTime } from 'luxon'
import Loading from '../../common/Loading'
import toast from 'react-hot-toast'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import DeleteESignRequestsModal from './DeleteESignRequestsModal'
import ReviewESignRequestModal from './ReviewESignRequestModal'
import AuditLogModal from '../../audit_log/AuditLogModal'
import { useHistory } from 'react-router-dom'
import { useDownloadFile } from '../../../libs/downloadFile'

const ESignRequests = (props) => {
  const {
    currentBoxFolderId,
    organizationId,
    subjectId,
    subjectGroupId,
    contact,
  } = props
  const settings = useReactiveVar(settingsVar)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [boxSignRequests, setBoxSignRequests] = useState([])
  const [showSendESignRequest, setShowSendESignRequest] = useState()
  const [cancelingSignRequest, setCancelingSignRequest] = useState(false)
  const [downloadingEsignFile, setDownloadingEsignFile] = useState(false)
  const [embedUrl, setEmbedUrl] = useState()
  const [embedSignRequestId, setEmbedSignRequestId] = useState()
  const [downloadingSignLogFile, setDownloadingSignLogFile] = useState(false)
  const [resendingSignRequest, setResendingSignRequest] = useState(false)
  const [checkedSignRequestIds, setCheckedSignRequestIds] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [boxSignRequestHistory, setBoxSignRequestHistory] = useState()
  const [displayHistory, setDisplayHistory] = useState(false)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const { downloadAndDeleteFile } = useDownloadFile()
  const history = useHistory()

  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)

  const [updateBoxSignRequest] = useMutation(
    gql`
      mutation UpdateBoxSignRequest(
        $updateBoxSignRequestInput: UpdateBoxSignRequestInput!
      ) {
        updateBoxSignRequest(input: $updateBoxSignRequestInput) {
          boxSignRequest {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        if (cancelingSignRequest) {
          toast.success('Sign Request Cancelled')
          setCancelingSignRequest(false)
        }
        if (resendingSignRequest) {
          toast.success('Sign Request Resent')
          setResendingSignRequest(false)
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['BoxSignRequests'],
    }
  )

  const [createBoxSignRequestFile] = useMutation(
    gql`
      mutation CreateBoxSignRequestFile(
        $createBoxSignRequestFileInput: CreateBoxSignRequestFileInput!
      ) {
        createBoxSignRequestFile(input: $createBoxSignRequestFileInput) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.createBoxSignRequestFile?.file?.fileName) {
          downloadAndDeleteFile(
            data.createBoxSignRequestFile.file.fileName,
            data.createBoxSignRequestFile.file.displayName,
            data.createBoxSignRequestFile.file.id,
            () => {
              if (downloadingEsignFile) {
                setDownloadingEsignFile(false)
                toast.success(`Sign File Downloaded`)
              } else if (downloadingSignLogFile) {
                setDownloadingSignLogFile(false)
                toast.success(`Sign Log File Downloaded`)
              }
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query BoxSignRequests(
        $cursor: String
        $organizationId: [ID]
        $subjectId: [ID]
        $subjectGroupId: [ID]
      ) {
        boxSignRequests(
          first: 10
          after: $cursor
          orderBy: "-created"
          organization: $organizationId
          subject: $subjectId
          subjectGroup: $subjectGroupId
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              boxResource
              description
              created
              signStatus
              subject {
                id
                gaiaUser {
                  firstName
                  lastName
                }
              }
              userBoxSignRequests {
                edges {
                  node {
                    signOrder
                    embedUrl
                    role
                  }
                }
              }
              contentType {
                model
                id
              }
              organization {
                name
                id
              }
              subjectGroup {
                name
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({
        variables: {
          organizationId: organizationId ? [organizationId] : null,
          subjectId: subjectId ? [subjectId] : null,
          subjectGroupId: subjectGroupId ? [subjectGroupId] : null,
        },
      })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.boxSignRequests) {
      const boxSignRequests_ = []
      queryData.boxSignRequests?.edges.forEach((edge) => {
        const boxResource = JSON.parse(edge.node.boxResource)
        if (boxResource?.status !== 'error_converting') {
          boxSignRequests_.push(edge)
        }
      })
      setBoxSignRequests(boxSignRequests_)
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.boxSignRequests?.pageInfo?.endCursor,
      organizationId: organizationId ? [organizationId] : null,
      subjectId: subjectId ? [subjectId] : null,
    }
    queryFetchMore({
      variables,
    })
  }

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedSignRequestIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedSignRequestIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const tableColumns = [
    {
      Header: 'Status',
      accessor: (row) => {
        const boxResource = JSON.parse(row.node.boxResource)
        let color
        if (
          ['viewed', 'sent', 'converting', 'created'].includes(
            boxResource.status
          )
        ) {
          color = 'red'
        } else if (boxResource.status === 'cancelled') {
          color = 'orange'
        } else if (['signed', 'finalizing'].includes(boxResource.status)) {
          color = 'green'
        }
        if (boxResource.status == 'converting') {
          boxResource.status = 'sent'
        }
        return (
          <span style={{ color }}>
            {boxResource.status.charAt(0).toUpperCase() +
              boxResource.status.slice(1)}
          </span>
        )
      },
    },
    {
      Header: 'Sent',
      accessor: (row) => {
        return (
          <>
            {DateTime.fromISO(row.node.created).toFormat('MMMM d yyyy h:mma')}
          </>
        )
      },
    },
    {
      Header: 'Signer',
      accessor: (row) => {
        const boxResource = JSON.parse(row.node.boxResource)
        return boxResource.signers[1].email
      },
    },
    {
      Header: 'Finalized Recipient',
      accessor: (row) => {
        const boxResource = JSON.parse(row.node.boxResource)
        if (boxResource.signers.length > 2) {
          return boxResource.signers[2].email
        }
      },
    },
    {
      Header: 'File Name',
      accessor: (row) => {
        const boxResource = JSON.parse(row.node.boxResource)
        return boxResource.sign_files.files[0].name
      },
    },
    {
      Header: 'Description',
      accessor: 'node.description',
    },
    {
      Header: 'Sign',
      accessor: (row) => {
        const boxResource = JSON.parse(row.node.boxResource)
        if (
          ['viewed', 'sent', 'converting', 'created'].includes(
            boxResource.status
          ) &&
          row.node.userBoxSignRequests.edges
        ) {
          const signer = row.node.userBoxSignRequests.edges.find(
            (edge) => edge.node.role === 'signer'
          )
          if (signer?.node?.embedUrl) {
            return (
              <>
                <Form.Group as={ButtonGroup} className="align-items-center">
                  <Button
                    className="ml-3"
                    variant="link"
                    onClick={() => {
                      setEmbedSignRequestId(row.node.id)
                      setEmbedUrl(signer?.node?.embedUrl)
                    }}
                  >
                    <VectorPen />
                  </Button>
                </Form.Group>
              </>
            )
          }
        }
      },
    },
    {
      Header: 'Download',
      disableSortBy: true,
      id: 'downloads',
      accessor: (row) => {
        const boxResource = JSON.parse(row.node.boxResource)
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Button
                className="ml-3"
                variant="link"
                disabled={downloadingEsignFile || downloadingSignLogFile}
                onClick={() => {
                  setDownloadingEsignFile(true)
                  createBoxSignRequestFile({
                    variables: {
                      createBoxSignRequestFileInput: {
                        boxSignRequestInput: {
                          id: row.node.id,
                          file: 'esign',
                        },
                      },
                    },
                  })
                }}
              >
                <FileEarmarkText />
              </Button>
              {boxResource.status === 'signed' && (
                <Button
                  className="ml-3"
                  variant="link"
                  disabled={downloadingEsignFile || downloadingSignLogFile}
                  onClick={() => {
                    setDownloadingSignLogFile(true)
                    createBoxSignRequestFile({
                      variables: {
                        createBoxSignRequestFileInput: {
                          boxSignRequestInput: {
                            id: row.node.id,
                            file: 'sign_log',
                          },
                        },
                      },
                    })
                  }}
                >
                  <Lock />
                </Button>
              )}
            </Form.Group>
          </>
        )
      },
    },
  ]
  if (canMutate) {
    tableColumns.push({
      Header: 'Cancel',
      disableSortBy: true,
      id: 'cancel',
      accessor: (row) => {
        if (JSON.parse(row.node.boxResource).status == 'sent') {
          return (
            <>
              <Form.Group as={ButtonGroup} className="align-items-center">
                <Button
                  className="ml-3"
                  variant="link"
                  disabled={cancelingSignRequest}
                  onClick={() => {
                    setCancelingSignRequest(true)
                    updateBoxSignRequest({
                      variables: {
                        updateBoxSignRequestInput: {
                          boxSignRequestInput: {
                            id: row.node.id,
                            cancel: true,
                          },
                        },
                      },
                    })
                  }}
                >
                  <SlashCircle />
                </Button>
              </Form.Group>
            </>
          )
        }
      },
    })
    tableColumns.push({
      Header: 'Resend',
      disableSortBy: true,
      id: 'resend',
      accessor: (row) => {
        if (JSON.parse(row.node.boxResource).status == 'sent') {
          return (
            <>
              <Form.Group as={ButtonGroup} className="align-items-center">
                <Button
                  className="ml-3"
                  variant="link"
                  disabled={resendingSignRequest}
                  onClick={() => {
                    setResendingSignRequest(true)
                    updateBoxSignRequest({
                      variables: {
                        updateBoxSignRequestInput: {
                          boxSignRequestInput: {
                            id: row.node.id,
                            resend: true,
                          },
                        },
                      },
                    })
                  }}
                >
                  <Bell />
                </Button>
              </Form.Group>
            </>
          )
        }
      },
    })
    tableColumns.push({
      Header: 'History',
      accessor: (row) => {
        return (
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Button
              variant="link"
              onClick={() => {
                setBoxSignRequestHistory(row.node)
                setDisplayHistory(true)
              }}
            >
              <ClockHistory />
            </Button>
          </Form.Group>
        )
      },
    })
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  boxSignRequests.forEach((boxSignRequest) => {
                    if (
                      !checkedSignRequestIds.includes(boxSignRequest.node.id)
                    ) {
                      appendIds.push(boxSignRequest.node.id)
                    }
                  })
                  setCheckedSignRequestIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedSignRequestIds([])
                }
              }}
            />
            {checkedSignRequestIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedSignRequestIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedSignRequestIds.includes(row.node.id)}
                onChange={(e) => handleCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }
  if (!subjectGroupId && !organizationId && !subjectId) {
    tableColumns.splice(4, 0, {
      Header: 'Subject',
      id: 'subject',
      accessor: (row) => {
        if (row.node.subject) {
          let subjectName
          if (row.node.subject.gaiaUser.firstName) {
            subjectName = row.node.subject.gaiaUser.firstName
          }
          if (row.node.subject.gaiaUser.lastName) {
            if (subjectName) {
              subjectName = `${subjectName} ${row.node.subject.gaiaUser.lastName}`
            } else {
              subjectName = row.node.subject.gaiaUser.lastName
            }
          }
          if (!subjectName) {
            subjectName = 'Unnamed'
          }
          return subjectName
        }
      },
    })
    tableColumns.splice(5, 0, {
      Header: 'Subject Group',
      id: 'subjectGroup',
      accessor: (row) => {
        if (row.node.subjectGroup) {
          return row.node.subjectGroup.name
        }
      },
    })
    tableColumns.splice(6, 0, {
      Header: 'Organization',
      id: 'organization',
      accessor: (row) => {
        if (row.node.organization) {
          return row.node.organization.name
        }
      },
    })
  }
  if (organizationId && !subjectGroupId) {
    tableColumns.splice(4, 0, {
      Header: 'Subject Group',
      id: 'subjectGroup',
      accessor: (row) => {
        if (row.node.subjectGroup) {
          return row.node.subjectGroup.name
        }
      },
    })
  }
  let loading
  if (downloadingEsignFile) {
    loading = <Loading message="Downloading Sign File..." />
  } else if (downloadingSignLogFile) {
    loading = <Loading message="Downloading Signing Log File..." />
  } else if (cancelingSignRequest) {
    loading = <Loading message="Cancelling Sign Request..." />
  } else if (resendingSignRequest) {
    loading = <Loading message="Resending Sign Request..." />
  }
  if (!initialQueryRun) return <></>
  if (initialQueryRun && !queryData) {
    return (
      <Row className="mt-4">
        <Col>
          <Loading message="Loading Sign Requests..." />
        </Col>
      </Row>
    )
  }

  if (queryError) return <>Error loading</>
  return (
    <>
      <div>
        <>
          <Row className="d-flex justify-content-end align-items-center">
            {canMutate && (
              <>
                <Col md={2}>
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowSendESignRequest(true)
                    }}
                  >
                    <VectorPen className="mr-2" />
                    Send Sign Request
                  </Button>
                </Col>
                {checkedSignRequestIds.length > 0 && (
                  <Col md={2}>
                    <Button
                      variant="link"
                      onClick={() => {
                        setShowDeleteModal(true)
                      }}
                    >
                      <Trash className="mr-2" />
                      Delete Sign Requests
                    </Button>
                  </Col>
                )}
              </>
            )}

            {loading && <Col md={2}>{loading}</Col>}
          </Row>

          <Row className="mb-2">
            <Col md="12">
              <SortableInfiniteTable
                tableData={boxSignRequests}
                tableColumns={tableColumns}
                fetchMoreTableData={fetchMore}
                loadingMessage="Loading Sign Requests..."
                hasMoreTableData={
                  queryData?.boxSignRequests?.pageInfo?.hasNextPage
                }
                onTdClicks={{
                  subjectGroup: (cell) => {
                    if (cell.row.original.node.subjectGroup) {
                      if (!contact) {
                        history.push(
                          `/subject-group/${cell.row.original.node.subjectGroup.id}`
                        )
                      } else {
                        history.push(
                          `/subject-group/${cell.row.original.node.subjectGroup.id}/contact`
                        )
                      }
                    }
                  },
                  subject: (cell) => {
                    if (cell.row.original.node.subject) {
                      if (!contact) {
                        history.push(
                          `/subject/${cell.row.original.node.subject.id}`
                        )
                      } else {
                        history.push(
                          `/subject/${cell.row.original.node.subject.id}/contact`
                        )
                      }
                    }
                  },
                  organization: (cell) => {
                    if (cell.row.original.node.organization) {
                      if (!contact) {
                        history.push(
                          `/organization/${cell.row.original.node.organization.id}`
                        )
                      } else {
                        history.push(
                          `/organization/${cell.row.original.node.organization.id}/contact`
                        )
                      }
                    }
                  },
                }}
                tableHeight={700}
                searchWidth={4}
              />
            </Col>
          </Row>
          <SendESignRequestModal
            showModal={showSendESignRequest}
            toggleModal={setShowSendESignRequest}
            boxFolderId={currentBoxFolderId}
            organizationId={organizationId}
            subjectGroupId={subjectGroupId}
            subjectId={subjectId}
          />
          <DeleteESignRequestsModal
            showModal={showDeleteModal}
            toggleModal={setShowDeleteModal}
            boxSignRequestIds={checkedSignRequestIds}
            setCheckedIds={setCheckedSignRequestIds}
          />
          <AuditLogModal
            showModal={displayHistory}
            toggleModal={() => {
              setDisplayHistory(false)
              setBoxSignRequestHistory()
            }}
            nodeId={boxSignRequestHistory?.id}
            nodeContentType={boxSignRequestHistory?.contentType?.model}
            nodeName="Sign Request"
          />
          <ReviewESignRequestModal
            embedUrl={embedUrl}
            showModal={embedUrl}
            boxSignRequestId={embedSignRequestId}
            toggleModal={() => {
              setEmbedSignRequestId()
              setEmbedUrl()
            }}
          />
        </>
      </div>
    </>
  )
}
export default ESignRequests
