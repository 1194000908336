import React from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import { Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'

const DeleteLocationModal = (props) => {
  const {
    showModal,
    toggleModal,
    locationId,
    locationIds,
    setCheckedLocationIds,
  } = props
  let _locationIds = [locationId]
  if (locationIds) {
    _locationIds = locationIds
  }
  const [mutation] = useMutation(
    gql`
      mutation DeleteLocation($deleteLocationInput: DeleteLocationInput!) {
        deleteLocation(input: $deleteLocationInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        if (locationIds) {
          toast.success('Locations Deleted')
        } else {
          toast.success('Location Deleted')
        }
        if (setCheckedLocationIds) {
          setCheckedLocationIds([])
        }
        toggleModal()
      },
      refetchQueries: ['Locations'],
      errorPolicy: 'all',
    }
  )
  const handleDeleteClick = () => {
    mutation({
      variables: {
        deleteLocationInput: {
          locationIds: _locationIds,
        },
      },
    })
  }
  if (!showModal) return <></>
  return (
    <>
      <div className="deleteLocations">
        <Modal
          size="md"
          show={showModal}
          onHide={toggleModal}
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              Delete Location{locationIds && <>s</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  Are you sure you want to delete the location
                  {locationIds && <>s</>}?
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  onClick={handleDeleteClick}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DeleteLocationModal
