// TwilioConnector.js
import React, { useState } from 'react'
import { Badge, Button, Row, Col } from 'react-bootstrap'
import { Plug } from 'react-bootstrap-icons'
import { useQuery, gql } from '@apollo/client'
import IntegrationCard from '../IntegrationCard'
import EditTwilioConnector from './EditTwilioConnector'

const TwilioConnector = () => {
  const [showEditModal, setShowEditModal] = useState(false)

  const { data } = useQuery(
    gql`
      query TwilioConnector {
        twilioConnectors {
          edges {
            node {
              customValid
              customAccountSid
              customAuthToken
              customSender
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  if (!data) return null

  const twilio = data.twilioConnectors.edges[0].node

  return (
    <>
      <EditTwilioConnector
        twilio={twilio}
        showModal={showEditModal}
        toggleModal={() => setShowEditModal(!showEditModal)}
      />
      <IntegrationCard
        logo={`${window.location.origin}/twilio-logo.png`}
        name="Twilio"
        description="Use Twilio to send SMS notifications and reminders to your clients. Improve communication and reduce no-shows with automated messaging."
      >
        <Row>
          <Col>
            {twilio.customValid ? (
              <Badge variant="success">Enabled</Badge>
            ) : (
              <Badge variant="danger">Disabled</Badge>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="link" onClick={() => setShowEditModal(true)}>
              <Plug className="mr-2" />
              Configure
            </Button>
          </Col>
        </Row>
      </IntegrationCard>
    </>
  )
}

export default TwilioConnector
