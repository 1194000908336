// EmailSetting.js
import React, { useState } from 'react'
import { Button, Badge, Row, Col } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import { Plug } from 'react-bootstrap-icons'
import IntegrationCard from '../IntegrationCard'
import EditEmailSetting from './EditEmailSettings'

const EmailSetting = () => {
  const { data } = useQuery(
    gql`
      query SMTPConnector {
        smtpConnectors {
          edges {
            node {
              customHost
              customPort
              customUser
              customTls
              customSsl
              customPassword
              customValid
              customSendgridSender
              rateLimit
              rateLimitSecondDelta
              customGmail
              customGmailAuth
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [showEditModal, setShowEditModal] = useState(false)

  if (!data) return null

  const emailSetting = data.smtpConnectors.edges[0].node
  const isConfigured = !!(
    emailSetting.customHost ||
    emailSetting.customPort ||
    emailSetting.customUser ||
    emailSetting.customPassword ||
    emailSetting.customGmail
  )

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal)
  }

  return (
    <>
      <EditEmailSetting
        emailSetting={emailSetting}
        showModal={showEditModal}
        toggleModal={toggleEditModal}
      />
      <IntegrationCard
        logo={`${window.location.origin}/email.png`}
        name="Email"
        description="Set up your email server to send booking confirmations, reminders, and other notifications directly from your domain. Ensure reliable communication with clients."
      >
        {/* Status Badge */}
        <Row>
          <Col>
            {emailSetting.customValid ? (
              <Badge variant="success">Enabled</Badge>
            ) : isConfigured ? (
              <Badge variant="danger">Invalid Credentials</Badge>
            ) : (
              <Badge variant="danger">Disabled</Badge>
            )}
          </Col>
        </Row>

        {/* Configure Button */}
        <Row>
          <Col>
            <Button variant="link" onClick={toggleEditModal}>
              <Plug className="mr-2" />
              Configure
            </Button>
          </Col>
        </Row>
      </IntegrationCard>
    </>
  )
}

export default EmailSetting
