import React, { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { BoxArrowInRight, Lock, FileEarmarkPerson } from 'react-bootstrap-icons'
import { Row, Col, Button, Form as BootstrapForm } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import SendConfirmationEmailModal from '../user/SendConfirmationEmailModal'
import ResetPasswordModal from '../user/ResetPasswordModal'
import { setLoginVars } from '../../libs/apollo'
import { setJwtLocalStorage } from '../../libs/utils'

export default function EmailLogin(props) {
  const { handlePostLogin, colMd } = props
  const [openSendConfirmationEmailModal, setOpenSendConfirmationEmailModal] =
    useState(false)
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const toggleSendConfirmationEmailModal = () => {
    setOpenSendConfirmationEmailModal(!openSendConfirmationEmailModal)
  }

  const toggleResetPasswordModal = () => {
    setOpenResetPasswordModal(!openResetPasswordModal)
  }

  const [getUser] = useMutation(
    gql`
      mutation CreateJwtTokenMutation(
        $createJwtTokenInput: CreateJSONWebTokenInput!
      ) {
        createJwtToken(input: $createJwtTokenInput) {
          token
          refreshExpiresIn
          gaiaUser {
            id
            fullName
            email
            emailConfirmed
            subject {
              id
              studentId
              organization {
                id
                name
              }
            }
            organizationContacts {
              edges {
                node {
                  id
                  category
                }
              }
            }
            subject {
              id
              subjectGroupsNotBooked {
                subjectGroup {
                  id
                  name
                  startDateTime
                  endDateTime
                }
              }
              studentId
              organization {
                id
                name
              }
            }
            employee {
              id
            }
            groups {
              edges {
                node {
                  id
                  name
                }
              }
            }
            firstName
            lastName
          }
        }
      }
    `,
    {
      onCompleted(data) {
        if (data.createJwtToken) {
          setJwtLocalStorage(data.createJwtToken)
          setLoginVars(data.createJwtToken.gaiaUser)
          handlePostLogin(
            data.createJwtToken.gaiaUser,
            data.createJwtToken.gaiaUser.subject
          )
        }
        setSubmitting(false)
      },
      onError(data) {
        setSubmitting(false)
        toast.error('Invalid Login Credentials')
      },
    }
  )

  return (
    <>
      <Row>
        <Col md={colMd ? colMd : 12}>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object({
              email: Yup.string().required('Required'),
              password: Yup.string().required('Required'),
            })}
            onSubmit={(values) => {
              setSubmitting(true)
              getUser({
                variables: {
                  createJwtTokenInput: {
                    username: values.email,
                    password: values.password,
                  },
                },
              })
            }}
          >
            {(formik) => (
              <Form>
                <BootstrapForm.Group>
                  <BootstrapForm.Label className="small">
                    Email
                  </BootstrapForm.Label>
                  <BootstrapForm.Control
                    type="text"
                    name="email"
                    className="form-control-sm"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    isInvalid={!!formik.errors.email && formik.touched.email}
                  />
                  <BootstrapForm.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </BootstrapForm.Control.Feedback>
                </BootstrapForm.Group>

                <BootstrapForm.Group>
                  <BootstrapForm.Label className="small">
                    Password
                  </BootstrapForm.Label>
                  <BootstrapForm.Control
                    type="password"
                    name="password"
                    className="form-control-sm"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    isInvalid={
                      !!formik.errors.password && formik.touched.password
                    }
                  />
                  <BootstrapForm.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </BootstrapForm.Control.Feedback>
                </BootstrapForm.Group>

                <Button
                  variant="link"
                  size="sm"
                  type="submit"
                  disabled={submitting}
                >
                  <BoxArrowInRight className="mr-2" />
                  Login
                </Button>
              </Form>
            )}
          </Formik>

          <div style={{ marginBottom: '-18px', marginTop: '-18px' }}>
            <Button
              size="sm"
              variant="link"
              className="p-0"
              type="Button"
              disabled={submitting}
              onClick={() => setOpenResetPasswordModal(true)}
            >
              <Lock className="mr-2" />
              Reset Password
            </Button>
          </div>
          <div>
            <Button
              className="p-0"
              size="sm"
              variant="link"
              disabled={submitting}
              onClick={() => setOpenSendConfirmationEmailModal(true)}
            >
              <FileEarmarkPerson className="mr-2" />
              Resend Verification Email
            </Button>
          </div>
        </Col>
      </Row>
      <SendConfirmationEmailModal
        showModal={openSendConfirmationEmailModal}
        toggleModal={toggleSendConfirmationEmailModal}
      />
      <ResetPasswordModal
        showModal={openResetPasswordModal}
        toggleModal={toggleResetPasswordModal}
      />
    </>
  )
}
