import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { Button, Col, Form, Modal } from 'react-bootstrap'
import { Field, Formik } from 'formik'
import { PlusCircle } from 'react-bootstrap-icons'
import Loading from '../common/Loading'
import toast from 'react-hot-toast'
import EmployeeSearchInput from '../common/node_search_input/EmployeeSearchInput'

function SessionDetailFormModal(props) {
  const { jobId, sessionId, showModal, toggleModal } = props
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [query, { data }] = useLazyQuery(
    gql`
      query SessionQuery($sessionId: ID!) {
        session(id: $sessionId) {
          frameNumber
          subjectDescription
          notes
          employee {
            id
            gaiaUser {
              fullName
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const [updateSession] = useMutation(
    gql`
      mutation UpdateSession($input: UpdateSessionInput!) {
        updateSession(input: $input) {
          session {
            id
          }
        }
      }
    `,
    {
      onCompleted(data) {
        setIsSubmitting(false)
        toggleModal()
        toast.success('Session Saved')
      },
    }
  )

  useEffect(() => {
    if (sessionId) {
      query({
        variables: {
          sessionId,
        },
      })
    }
  }, [sessionId])

  if (!data) return <></>

  const initialValues = data
    ? {
        employeeId: data?.session?.employee?.id,
        employeeDescription: data?.session?.employee?.gaiaUser?.fullName,
        frameNumber: data?.session?.frameNumber,
        subjectDescription: data?.session?.subjectDescription,
        notes: data?.session?.notes,
      }
    : {}

  return (
    <>
      <div className="SessionDetailFormModal">
        <Modal show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton className="pb-0">
            <Modal.Title>
              <h4>Employee Details</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={(values) => {
                setIsSubmitting(true)
                updateSession({
                  variables: {
                    input: {
                      sessionInput: {
                        id: sessionId,
                        employeeId: values.employeeId,
                        frameNumber: values.frameNumber,
                        subjectDescription: values.subjectDescription,
                        notes: values.notes,
                      },
                    },
                  },
                })
              }}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit} className="mt-1">
                  <Form.Row>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>Photographer</Form.Label>
                      <EmployeeSearchInput
                        formik={formik}
                        dropdown={true}
                        variables={{ jobId: jobId }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>Frame Number</Form.Label>
                      <Field
                        name={'frameNumber'}
                        type="text"
                        className="form-control form-control-sm"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>Subject Description</Form.Label>
                      <Field
                        style={{ height: '100px' }}
                        name={'subjectDescription'}
                        rows={1}
                        as="textarea"
                        className="form-control form-control-sm"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>Session Notes</Form.Label>
                      <Field
                        style={{ height: '100px' }}
                        name={'notes'}
                        rows={1}
                        as="textarea"
                        className="form-control form-control-sm"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Col md={4}>
                      <Button
                        className="mt-4"
                        type="submit"
                        block
                        variant="outline-primary"
                      >
                        <PlusCircle className="mr-2" />
                        Save
                      </Button>
                    </Col>
                  </Form.Row>
                  {isSubmitting ? (
                    <Form.Row className="mt-3">
                      <Col>
                        <Loading message="Saving Session..." />
                      </Col>
                    </Form.Row>
                  ) : null}
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default SessionDetailFormModal
