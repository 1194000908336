import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Accordion, Card } from 'react-bootstrap'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { DateTime } from 'luxon'
import { GeoAlt, PlusCircle } from 'react-bootstrap-icons'
import { formatDateToStringOmmitYear, objEmpty } from '../../libs/utils'
import { useHistory } from 'react-router-dom'
import { loggedInUserVar } from '../../libs/apollo'
import Loading from '../common/Loading'
import { Calendar2Plus } from 'react-bootstrap-icons'
import MapModal from '../location/MapModal'

const SelectSession = (props) => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const history = useHistory()
  const [showMapModal, setShowMapModal] = useState()
  const {
    previousSessionId,
    subjectGroupId,
    sessionPackageId,
    setJobId,
    setSessionStartDateTime,
    setBookingStage,
    reschedule,
    setLocationInfo,
    packageCategoryId,
    subjectId,
    rescheduleSessionId,
    changeSessionPackageSessionId,
    reschedulingNoShow,
    resitSessionId,
    resitReasonId,
  } = props

  const canCreateJob = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)

  const [
    availableSessionsQuery,
    { error: availableSessionsQueryError, data: availableSessionsQueryData },
  ] = useLazyQuery(
    gql`
      query AvailableSessionsQuery(
        $sessionPackageId: ID!
        $subjectGroupId: ID
        $packageCategoryId: ID
        $previousSessionId: ID
      ) {
        availableSessions(
          sessionPackageId: $sessionPackageId
          subjectGroupId: $subjectGroupId
          packageCategoryId: $packageCategoryId
          previousSessionId: $previousSessionId
        ) {
          startDateTime
          endDateTime
          job {
            id
            location {
              id
              latitude
              longitude
              name
              fullAddress
              addressLineOne
              addressLineTwo
              city
              zipCode
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const handleAvailableSessionsQueryData = () => {
    const currentLocationsSessions = {}
    if (availableSessionsQueryData?.availableSessions) {
      availableSessionsQueryData.availableSessions.forEach((session) => {
        const startDateTime = new Date(session.startDateTime)
        const startDate = formatDateToStringOmmitYear(startDateTime)
        const currentSession = {
          jobId: session.job.id,
          startDateTime: session.startDateTime,
          formattedStartTime: DateTime.fromISO(session.startDateTime).toFormat(
            'h:mma'
          ),
        }
        const locationId = session.job.location.id
        if (currentLocationsSessions[locationId]) {
          if (!currentLocationsSessions[locationId].sessions[startDate]) {
            currentLocationsSessions[locationId].sessions[startDate] = []
          }
          currentLocationsSessions[locationId].sessions[startDate].push(
            currentSession
          )
        } else {
          let address = session.job.location.addressLineOne
          if (session.job.location.addressLineTwo) {
            address += ` ${session.job.location.addressLineTwo}`
          }
          currentLocationsSessions[locationId] = {
            location: {
              address,
              addressLineOne: session.job.location.addressLineOne,
              longitude: session.job.location.longitude,
              latitude: session.job.location.latitude,
              city: session.job.location.city,
              name: session.job.location.name || '',
              zipCode: session.job.location.zipCode,
            },
            sessions: {
              [startDate]: [currentSession],
            },
          }
        }
      })
      Object.keys(currentLocationsSessions).forEach((locationId) => {
        const sortedSessions = {}
        const dates = Object.keys(currentLocationsSessions[locationId].sessions)
        dates.sort(
          (a, b) =>
            DateTime.fromFormat(a, 'EEE LLL d yyyy').toJSDate() -
            DateTime.fromFormat(b, 'EEE LLL d yyyy').toJSDate()
        )
        dates.forEach((date) => {
          sortedSessions[date] =
            currentLocationsSessions[locationId].sessions[date]
        })
        currentLocationsSessions[locationId].sessions = sortedSessions
      })
    }
    return currentLocationsSessions
  }

  useEffect(() => {
    if (sessionPackageId) {
      availableSessionsQuery({
        variables: {
          sessionPackageId,
          subjectGroupId,
          previousSessionId,
          packageCategoryId,
        },
      })
    }
  }, [subjectGroupId, sessionPackageId, previousSessionId])

  const handleSessionClick = (jobId, startDateTime, locationSessions) => {
    setJobId(jobId)
    setSessionStartDateTime(startDateTime)
    if (setLocationInfo) {
      setLocationInfo(locationSessions.location)
    }
    if (loggedInUser) {
      let sessionType = sessionStorage.getItem('session_type')
      if (sessionType !== null && sessionType !== 'School Session') {
        setBookingStage('loginCreateForm')
      } else {
        if (props.showModal) {
          setBookingStage('confirmation')
        } else {
          setBookingStage('studentInfo')
        }
      }
    } else {
      setBookingStage('loginCreateForm')
    }
  }

  if (!availableSessionsQueryData)
    return (
      <Row className="mt-2">
        <Col>
          <Loading />
        </Col>
      </Row>
    )

  if (availableSessionsQueryError) return <p>Error loading sessions</p>

  const locationsSessions = handleAvailableSessionsQueryData()
  let divCls = 'selectSession'

  if (reschedule) {
    divCls = 'selectSession mt-1'
  }

  if (objEmpty(locationsSessions)) {
    return (
      <>
        <Row className="mt-3 ml-1">
          <Col>
            <h4 style={{ fontSize: '18px' }}>No upcoming sessions available</h4>
          </Col>
        </Row>
        {canCreateJob && (
          <Row>
            <Col style={{ marginLeft: '10px' }}>
              <Button
                size="sm"
                variant="link"
                onClick={() => {
                  history.push({
                    pathname: `/job`,
                    state: {
                      jobFormType: 'subject',
                      bookingFlowSubjectId: subjectId,
                      bookingFlowPackageId: sessionPackageId,
                      bookingFlowSubjectGroupId: subjectGroupId,
                      bookingFlowPackageCategoryId: packageCategoryId,
                      bookingFlowRescheduleSessionId: rescheduleSessionId,
                      bookingFlowChangePackageSessionId:
                        changeSessionPackageSessionId,
                      bookingFlowResitSessionId: resitSessionId,
                      bookingFlowResitReasonId: resitReasonId,
                      bookingFlowReschedulingNoShow: reschedulingNoShow,
                    },
                  })
                }}
                className="ml-2"
              >
                <PlusCircle className="mr-2" />
                New Job
              </Button>
            </Col>
          </Row>
        )}
      </>
    )
  }

  return (
    <>
      <MapModal
        showModal={showMapModal}
        location={showMapModal}
        toggleModal={setShowMapModal}
      />
      <div className={divCls}>
        <Row>
          <Col>
            <h5>Select Location and Time</h5>
          </Col>
        </Row>

        {canCreateJob && (
          <Row>
            <Col>
              <h4 style={{ fontSize: '18px' }}>
                Need to book a different time?
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => {
                    history.push({
                      pathname: `/job`,
                      state: {
                        jobFormType: 'subject',
                        bookingFlowSubjectId: subjectId,
                        bookingFlowPackageId: sessionPackageId,
                        bookingFlowSubjectGroupId: subjectGroupId,
                        bookingFlowPackageCategoryId: packageCategoryId,
                        bookingFlowRescheduleSessionId: rescheduleSessionId,
                        bookingFlowReschedulingNoShow: reschedulingNoShow,
                        bookingFlowResitSessionId: resitSessionId,
                        bookingFlowResitReasonId: resitReasonId,
                        bookingFlowChangeSessionPackageSessionId:
                          changeSessionPackageSessionId,
                      },
                    })
                  }}
                  className="mr-3"
                >
                  <PlusCircle className="mr-2" />
                  New Job
                </Button>
              </h4>
            </Col>
          </Row>
        )}

        <Row className="mb-4 mt-3">
          <Col>
            {Object.entries(locationsSessions).map(
              (locationIdSessions, index) => {
                const locationSessions = locationIdSessions[1]
                return (
                  <span key={index}>
                    <Row>
                      <Col className="d-flex align-items-center ml-1">
                        <span
                          style={{ cursor: 'pointer' }}
                          className="btn-link"
                          onClick={() => {
                            setShowMapModal(locationSessions.location)
                          }}
                        >
                          <p className="m-0 p-0" style={{ cursor: 'pointer' }}>
                            <GeoAlt className="mr-2" />
                            {locationSessions.location.name}{' '}
                            {locationSessions.location.address},{' '}
                            {locationSessions.location.city}{' '}
                            {locationSessions.location.zipCode}
                          </p>
                        </span>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={9}>
                        <Card.Body className="p-0 mt-2">
                          <Accordion flush="true">
                            {Object.entries(locationSessions.sessions).map(
                              (sessions, index) => (
                                <Card key={index}>
                                  <Card.Header className="m-n2">
                                    <Accordion.Toggle
                                      as={Button}
                                      variant="link"
                                      eventKey={`${index}`}
                                    >
                                      <Row>
                                        <Col>
                                          <span>{sessions[0]}</span>
                                        </Col>
                                      </Row>
                                    </Accordion.Toggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey={`${index}`}>
                                    <Card.Body
                                      style={{
                                        maxHeight: '200px',
                                        overflowY: 'scroll',
                                      }}
                                    >
                                      <Row>
                                        <Col className="d-flex flex-wrap">
                                          {sessions[1].map(
                                            (session, sessionIndex) => (
                                              <Button
                                                style={{
                                                  width: '110px',
                                                }}
                                                key={sessionIndex}
                                                onClick={() =>
                                                  handleSessionClick(
                                                    session.jobId,
                                                    session.startDateTime,
                                                    locationSessions
                                                  )
                                                }
                                                variant="outline-primary"
                                                className="m-1"
                                              >
                                                {session.formattedStartTime}
                                              </Button>
                                            )
                                          )}
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              )
                            )}
                          </Accordion>
                        </Card.Body>
                      </Col>
                    </Row>
                  </span>
                )
              }
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default SelectSession
