// OpenAIIntegration.js
import React from 'react'
import { Badge, Button, Row, Col } from 'react-bootstrap'
import { Plug } from 'react-bootstrap-icons'
import IntegrationCard from './IntegrationCard'
import { useMutation, gql } from '@apollo/client'

const OpenAIIntegration = ({ settings }) => {
  const [updateSettings] = useMutation(
    gql`
      mutation UpdateSettings($updateSettingsInput: UpdateSettingsInput!) {
        updateSettings(input: $updateSettingsInput) {
          settings {
            id
            openAiEnabled
          }
        }
      }
    `,
    {
      refetchQueries: ['SettingsQuery', 'PublicSettingsQuery'],
    }
  )

  const toggleOpenAI = () => {
    updateSettings({
      variables: {
        updateSettingsInput: {
          settingsInput: {
            id: settings.id,
            openAiEnabled: !settings.openAiEnabled,
          },
        },
      },
    })
  }

  return (
    <IntegrationCard
      logo={`${window.location.origin}/openai.png`}
      name="ChatGPT"
      description="Integrate ChatGPT to provide AI-powered assistance within your platform. Enhance user experience with intelligent chat capabilities and automated responses."
    >
      <Row>
        <Col>
          {settings.openAiEnabled ? (
            <Badge variant="success" style={{ cursor: 'pointer' }}>
              Enabled
            </Badge>
          ) : (
            <Badge variant="danger" style={{ cursor: 'pointer' }}>
              Disabled
            </Badge>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <Button variant="link" onClick={toggleOpenAI}>
            <Plug className="mr-2" />
            {settings.openAiEnabled ? 'Disable' : 'Enable'}
          </Button>
        </Col>
      </Row>
    </IntegrationCard>
  )
}

export default OpenAIIntegration
