import React from 'react'
import { Modal } from 'react-bootstrap'
import FolderForm from './FolderForm'
import { FileEarmarkText, Folder } from 'react-bootstrap-icons'

const FolderModal = (props) => {
  const {
    folderId,
    showModal,
    toggleModal,
    folder,
    jobId,
    jobDescription,
    organizationId,
    taskId,
    organizationDescription,
    subjectId,
    subjectGroupId,
    subjectGroupDescription,
    subjectDescription,
    employeeId,
    employeeDescription,
    parentShared,
  } = props

  if (!showModal) return <></>
  return (
    <>
      <div id="bagTypeModal">
        <Modal size={'lg'} show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Folder className="mr-2" />
              {folder ? <>{folder.name}</> : <>New Folder</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FolderForm
              folderId={folderId}
              folder={folder}
              afterSubmit={toggleModal}
              jobId={jobId}
              parentShared={parentShared}
              jobDescription={jobDescription}
              organizationId={organizationId}
              taskId={taskId}
              organizationDescription={organizationDescription}
              subjectId={subjectId}
              subjectDescription={subjectDescription}
              employeeId={employeeId}
              subjectGroupId={subjectGroupId}
              subjectGroupDescription={subjectGroupDescription}
              employeeDescription={employeeDescription}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default FolderModal
